import produce from 'immer';
import { isEmpty } from 'lodash-es';
import { BackOfficeUserDTO, UserSetting } from '../../../services/types/BoApiTypes';
import { BaseSearch, BaseSearchWithTableFilters, UserSettingName, Restriction } from '../../../services/types/ApiTypes';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../common/utils/baseSearchHelpers';
import api from '../../../services/ApiServices';
import { notify } from '../../../common/utils/notify';
import i18nInstance from '../../../i18n';

export type ViewPageSize = UserSettingName;
export type ViewSortColumn = UserSettingName;
export type ViewSortDirection = UserSettingName;
export type ViewFilters = UserSettingName;

export interface ListViewUserConfig {
    pageSize: ViewPageSize;
    sortCol: ViewSortColumn;
    sortDir: ViewSortDirection;
    filters?: ViewFilters;
}

export interface ListViewBaseSearch {
    searchParams: BaseSearch | BaseSearchWithTableFilters<any, any>;
    listViewConfig: ListViewUserConfig;
}

export function getBoUserSettingValue(setting: UserSettingName, boUser: BackOfficeUserDTO): string {
    if (!boUser || (boUser && isEmpty(boUser.Settings))) {
        return undefined;
    }

    const value: undefined | UserSetting = boUser.Settings.find((s) => s.Name === setting);
    if (setting === UserSettingName.IS_EMAIL_NOTIFICATIONS_ENABLED) {
        // if setting is not present, the value is 'true';
        if (!value) {
            return 'true';
        }
        return value.Value;
    }

    return !!value && value.Value;
}

export function setBoUserSettingValue(setting: UserSettingName, value: Restriction | string | number, settings: UserSetting[]): UserSetting[] {
    return produce(settings, (draft) => {
        const userSetting = draft.find((s) => s.Name === setting);
        if (userSetting) {
            userSetting.Value = value.toString();
        } else {
            draft.push({
                Name: setting,
                Value: value.toString(),
            });
        }
    });
}

export function deleteBoUserSettingValue(setting: UserSettingName, settings: UserSetting[]): UserSetting[] {
    return produce(settings, (draft) => {
        return draft.filter((s: UserSetting) => s.Name !== setting);
    });
}

export function getViewBoUserSettings(viewConfig: ListViewUserConfig, boUser: BackOfficeUserDTO) {
    const userViewConfig: {
        pageSize: string;
        sortCol: string;
        sortDir: string;
        filters?: string;
    } = {
        pageSize: getBoUserSettingValue(viewConfig.pageSize, boUser),
        sortCol: getBoUserSettingValue(viewConfig.sortCol, boUser),
        sortDir: getBoUserSettingValue(viewConfig.sortDir, boUser),
    };
    if (viewConfig.filters) {
        userViewConfig.filters = getBoUserSettingValue(viewConfig.filters, boUser);
    }
    return userViewConfig;
}

export function getViewBoUserPaging(searchParams: BaseSearchWithTableFilters<any, any>, viewConfig: ListViewUserConfig, boUser: BackOfficeUserDTO): BaseSearchWithTableFilters<any, any> {
    const viewConf = getViewBoUserSettings(viewConfig, boUser);
    if (!!viewConf.pageSize && searchParams && searchParams.hasOwnProperty('PagingOptions')) {
        searchParams.PagingOptions = validateAndFixPagingOptions({
            ...searchParams.PagingOptions,
            Count: parseInt(viewConf.pageSize, 10),
        });
    }

    return searchParams;
}

export function getViewBoUserFilters(searchParams: BaseSearchWithTableFilters<any, any>, viewConfig: ListViewUserConfig, boUser: BackOfficeUserDTO): BaseSearchWithTableFilters<any, any> {
    const viewConf = getViewBoUserSettings(viewConfig, boUser);
    if (!!viewConf.filters && searchParams && searchParams.hasOwnProperty('filters')) {
        const filterValues: Restriction[] = JSON.parse(viewConf.filters);

        // TODO: parse the Restrictions from filters, something like the following
        // const restrictions: Restriction[] = filterValues.map(
        //     (filter: { columnName: string; values: any[]; value: any }): Restriction => {
        //         return {
        //             Field: filter.columnName,
        //             Value: filter.value,
        //             Values: filter.values,
        //             FieldSearchType: SearchType.NotSelected,
        //             // ...filter,
        //         };
        //     },
        // );
        searchParams.Restrictions = [...searchParams.Restrictions, ...filterValues];
    }

    return searchParams;
}

export function getViewBoUserSorting(searchParams: BaseSearchWithTableFilters<any, any>, viewConfig: ListViewUserConfig, boUser: BackOfficeUserDTO): BaseSearchWithTableFilters<any, any> {
    const viewConf = getViewBoUserSettings(viewConfig, boUser);
    if (!!viewConf.sortCol && searchParams && searchParams.hasOwnProperty('SortItems')) {
        searchParams.SortItems[0] = {
            ...searchParams.SortItems[0],
            SortColumn: viewConf.sortCol,
        };
    }
    if (!!viewConf.sortDir && searchParams && searchParams.hasOwnProperty('SortItems')) {
        searchParams.SortItems[0] = {
            ...searchParams.SortItems[0],
            SortDirection: parseInt(viewConf.sortDir, 10),
        };
    }

    searchParams.SortItems = validateAndFixSortItems(searchParams.SortItems);

    return searchParams;
}

export function getViewBoUserSearchParams(searchParams: BaseSearchWithTableFilters<any, any>, viewConfig: ListViewUserConfig, boUser: BackOfficeUserDTO): BaseSearchWithTableFilters<any, any> {
    if (isEmpty(searchParams.PagingOptions)) {
        searchParams = getViewBoUserPaging(searchParams, viewConfig, boUser);
    }
    if (isEmpty(searchParams.SortItems)) {
        searchParams = getViewBoUserSorting(searchParams, viewConfig, boUser);
    }
    // TODO: Add this feature here from list view (see Companies list useEffect)
    // if (viewConfig.hasOwnProperty('filters') && viewConfig.filters) {
    // searchParams = getViewBoUserFilters(searchParams, viewConfig, boUser);
    // }

    return searchParams;
}

/**
 * A separate request for simply updating the boCurrentUser Settings with list views sorting and paging preferences
 * @param boUser: BackOfficeUserDTO - the boCurrentUser object with already updated Settings
 */
export function updateBoUserSettings(boUser: BackOfficeUserDTO) {
    return async () => {
        try {
            await api.boUsers.saveUser(boUser);
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
}
