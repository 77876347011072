import { createAction } from 'redux-actions';

import { InvoiceTypeDTO } from '../../../../services/types/ApiTypes';
import { loadableDataActions } from '../../../../common/utils/LoadableData';
import { DispatchThunk } from '../../../../storeConfig';
import api from '../../../../services/ApiServices';
import { notify } from '../../../../common/utils/notify';
import i18nInstance from '../../../../i18n';
import { getInvoiceTypesList } from '../InvoiceTypesListActions';

const ns = 'new-InvoiceType-modal/';

export const setActiveInvoiceType = createAction<InvoiceTypeDTO>(`${ns}SET_ACTIVE_InvoiceType`);
export const saveInvoiceTypeActions = loadableDataActions<InvoiceTypeDTO, InvoiceTypeDTO>(`${ns}SAVE_InvoiceType`);

export const saveInvoiceType = (invoiceType: InvoiceTypeDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(saveInvoiceTypeActions.request(invoiceType));
            const response = await api.invoiceTypes.saveInvoiceType(invoiceType);
            if (response?.status === 200) {
                dispatch(saveInvoiceTypeActions.success(response.data));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
            dispatch(getInvoiceTypesList());
        } catch (e) {
            console.error(e);
            dispatch(saveInvoiceTypeActions.error(e));
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const editInvoiceType = (invoiceType: InvoiceTypeDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(saveInvoiceTypeActions.request(invoiceType));
            const response = await api.invoiceTypes.editInvoiceType(invoiceType);
            if (response?.status === 200) {
                dispatch(saveInvoiceTypeActions.success(response.data));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
            dispatch(getInvoiceTypesList());
        } catch (e) {
            console.error(e);
            dispatch(saveInvoiceTypeActions.error(e));
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const showEditInvoiceTypeModal = createAction<boolean>(`${ns}SHOW_NEW_InvoiceType_MODAL`);

export function showAddOrEditInvoiceTypeModal(invoiceType?: InvoiceTypeDTO) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(setActiveInvoiceType(invoiceType || undefined));
            dispatch(showEditInvoiceTypeModal(true));
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileSendingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
}
