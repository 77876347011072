import { createAction } from 'redux-actions';

import { BackOfficeCompanyDTO } from '../../../../../services/types/BoApiTypes';
import { loadableDataActions } from '../../../../../common/utils/LoadableData';
import { DispatchThunk } from '../../../../../storeConfig';
import api from '../../../../../services/ApiServices';
import { notify } from '../../../../../common/utils/notify';
import i18nInstance from '../../../../../i18n';

const ns = 'new-BoCompany-modal/';

export const setActiveBoCompany = createAction<BackOfficeCompanyDTO>(`${ns}SET_ACTIVE_COMPANY`);
export const addBoCompanyActions = loadableDataActions<BackOfficeCompanyDTO, BackOfficeCompanyDTO>(`${ns}ADD_OR_EDIT_BO_COMPANY`);

// TODO @tonister: Add this in 20.5.1
// export const getCompanyUsersLoadableActions = loadableDataActions<string, BackOfficeUserDTO[]>(`${ns}GET_COMPANY_USERS`);

// export const getCompanyUsers = (companyGuid: string) => {
//     return async (dispatch: DispatchThunk) => {
//         try {
//             dispatch(getCompanyUsersLoadableActions.request(companyGuid));
//             const response = await api.boUserCompanies.getCompanyUsers(companyGuid);
//             dispatch(getCompanyUsersLoadableActions.success(response.data));
//         } catch (e) {
//             console.error(e);
//             dispatch(getCompanyUsersLoadableActions.error(e));
//         }
//     };
// };

export const addOrUpdateBoCompany = (boCompany: BackOfficeCompanyDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(addBoCompanyActions.request(boCompany));
            const response = await api.boCompanies.saveCompany(boCompany);
            dispatch(addBoCompanyActions.success(response.data));
            // import the actions lazily, otherwise it seems we get circular dependencies and page crashes, probably the solution would be to move GlobalState out of the rootReducer file
            import('../../BOCompanyListViewActions').then((actions) => {
                dispatch(actions.setPagingOptions(1));
            });
        } catch (e) {
            console.error(e);
            dispatch(addBoCompanyActions.error(e));
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const showEditBoCompanyModal = createAction<boolean>(`${ns}SHOW_NEW_BO_COMPANY_MODAL`);

export function showAddOrEditBoCompanyModal(boCompany?: BackOfficeCompanyDTO) {
    return async (dispatch: DispatchThunk) => {
        try {
            // load the boCompany data if Id is provided. If it is not provided, then we are creating from scratch
            if (boCompany) {
                dispatch(setActiveBoCompany(boCompany));
            } else {
                dispatch(setActiveBoCompany(undefined));
            }
            dispatch(showEditBoCompanyModal(true));
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileSendingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
}
