'use strict';

import api from '../../../src/services/ApiServices';
import {encodeUriFilterObject} from "../../../src/views/invoice-details/components/invoice-slider/InvoiceSliderHelpers";
import { CompanySettingStatus } from '../../../src/views/settings/company-settings/components/Settings/CompanySettingsListHelper';
import { eventTrack } from '../../../src/common/analytics/gtm';

angular
  .module('dstreamApp.components')
  .component('dsAccountantDashboardInvoices', {
    templateUrl: 'app/components/accountantDashboardInvoices/ds-accountant-dashboard-invoices.html',
    controller: DsAccountantDashboardInvoicesController
  });

function DsAccountantDashboardInvoicesController($scope, webServices, invoiceService, $rootScope, constants, companyDataService, invoiceRegisterService, $location, $ngRedux) {
  var ctrl = this;
  ctrl.DATE_EE_FORMAT = constants.DATE_EE_FORMAT;
  ctrl.localStoragePrefix = 'accountant';
  $scope.invoices = [];
  $scope.unConfirmedInvoices = 0;
  $scope.invoicesTotalCount = 0;
  $scope.invoicesLoading = true;
  $scope.quickConfirmAllowed = true;
  $scope.allConfirmed = false; // has clicked confirm but actual approval hasn't happened
  $scope.allApproved = false; // actual approval has happened
  $scope.confirmTime = 5000; // time when invoice confirm undo is possible
  $scope.isNewSupplierVisible = false;

  ctrl.defaultFilterObject = {
    pagingOptions: {
      page: 1,
      count: 15,
      total: 0
    },
    isIncludeWorkflows: false,
    statusFilter: [{ Name: 'New', Value: 0 }],
    timeType: 'forever',
    invoiceType: 0,
    customRestrictions: [],
    sortItems: [{
      SortColumn: "InvoiceDate",
      SortDirection: "desc"
    }]
  };
  // filterObject that ensures that we get correct invoices in the new invoices table.
  // Because we have three tables in the same view that use the filter service,
  // then we need to override all the params other tables might set
  ctrl.filterObject = invoiceRegisterService.getFilterFromLocalStorage(ctrl.localStoragePrefix) || ctrl.defaultFilterObject;

  // event and variable watchers
  var authorizationDataLoadedEvent = $rootScope.$on("authorizationDataLoaded", function () {
    ctrl.filterObject.isIncludeWorkflows = false;
    loadInvoices();
  });

  /*
      Save filter before navigation to invoice detail view
   */
  $scope.openInvoice = function (invoice, e) {
    e.preventDefault();
    eventTrack({
      event: 'dashboard_new_invoices',
      label: 'Open invoice',
    });
    invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 1, 1, ctrl.localStoragePrefix);
    invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 0, 1);
    $location.path("/invoiceconfirmation/" + invoice.Id);
  };

  /*
      Pagination page change
   */
  ctrl.pageChanged = function (value) {
    ctrl.filterObject.pagingOptions.page = value;
    ctrl.filterObject.isIncludeWorkflows = false;
    invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 0, 1, ctrl.localStoragePrefix);
    loadInvoices();
  };

  /*
      Load and init invoices
   */
    function loadInvoices() {
        if ($rootScope.userData) {
            $scope.invoicesLoading = true;
            ctrl.filterObject.isIncludeWorkflows = false;
            const filterObject = invoiceRegisterService
                .saveFilterToLocalStorage(
                    ctrl.filterObject,
                    true,
                    true,
                    ctrl.localStoragePrefix
                );
            api.invoice.getDashboardInvoicesMin(invoiceRegisterService.createApiRequestObject(filterObject))
                .then(function ({data}) {
                if (data && data.Items) {
                    for (var i = 0; i < data.Items.length; i++) {
                        data.Items[i].currentStatus = invoiceService.setInvoiceStatus(data.Items[i]);
                    }

                    $scope.invoices = data.Items;
                    $scope.unConfirmedInvoices = data.Items.length;
                }

                // update the filterObject
                if (filterObject) {
                    ctrl.filterObject.pagingOptions = {
                        page: data.Skip / data.Take + 1,
                        count: data.Take,
                        total: data.TotalCount,
                    };
                    $scope.invoicesTotalCount = ctrl.filterObject.pagingOptions.total;
                }
                $scope.invoicesLoading = false;
            }); // request for invoices that match current filter
        }
    }

    ctrl.getUriFilterObject = encodeUriFilterObject(invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 0, 1));

  function setSettings (currentCompanySettings = []) {
    const companySetting = _.find(currentCompanySettings, function (setting) { return setting.Name === 'IsExportWithUnregisteredSupplierDenied' });
    if (companySetting) {
      $scope.isNewSupplierVisible = companySetting.Value == CompanySettingStatus.Enabled ? true : false;
    } else {
      $scope.isNewSupplierVisible = false;
    }
  }

  var companySettingsLoadEventListener = $rootScope.$on('ds.currentCompany.companySettingsLoaded', function (event, value) {
    const {company : {currentCompanySettings}} = $ngRedux.getState();
    setSettings(currentCompanySettings);
  });

  /*
    ============= ANGULAR FUNCTIONS ================
  */

  ctrl.$onInit = function () {
    ctrl.filterObject.isIncludeWorkflows = false;
    loadInvoices();

    const {company : {isCurrentCompanySettingsLoading, currentCompanySettings}} = $ngRedux.getState();
    if (!isCurrentCompanySettingsLoading) {
      setSettings(currentCompanySettings);
    }
  };

  ctrl.$onDestroy = function () {
    authorizationDataLoadedEvent();
    companySettingsLoadEventListener();
  };
}
