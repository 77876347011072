export function showNewVersionDialog(apiVersion: string, appVersion: string) {
    const mainApiVersion = apiVersion
        .split('.')
        .slice(0, 3)
        .join('.');
    const mainAppVersion = appVersion
        .split('.')
        .slice(0, 3)
        .join('.');

    return mainApiVersion !== mainAppVersion;
}
