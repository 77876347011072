import * as React from 'react';
import CustomScrollbars, { ScrollbarProps } from 'react-custom-scrollbars';

import './Scrollbars.scss';

export interface ScrollbarsProps extends ScrollbarProps {
    verticalLeft?: boolean;
    isScrollTrackHidden?: boolean;
}

const Scrollbars: React.StatelessComponent<ScrollbarsProps> = ({ verticalLeft, isScrollTrackHidden, ...props }: ScrollbarsProps) => {
    const renderVerticalThumb = () => {
        return <div className={'custom-vertical-scroll-thumb' + (isScrollTrackHidden ? ' is-hidden' : '')} />;
    };

    const renderVerticalLeftThumb = () => {
        return <div className={'custom-vertical-scroll-thumb is-left' + (isScrollTrackHidden ? ' is-hidden' : '')} />;
    };

    const renderVerticalTrack = () => {
        return <div className={'custom-vertical-scroll-track' + (isScrollTrackHidden ? ' is-hidden' : '')} />;
    };
    const renderVerticalLeftTrack = () => {
        return <div className={'custom-vertical-scroll-track is-left' + (isScrollTrackHidden ? ' is-hidden' : '')} />;
    };

    const renderHorizontalThumb = () => {
        return <div className="custom-horizontal-scroll-thumb" />;
    };

    const renderHorizontalTrack = () => {
        return <div className="custom-horizontal-scroll-track" />;
    };

    return (
        <CustomScrollbars
            className="scroll-bar"
            renderTrackVertical={verticalLeft ? renderVerticalLeftTrack : renderVerticalTrack}
            renderTrackHorizontal={renderHorizontalTrack}
            renderThumbVertical={verticalLeft ? renderVerticalLeftThumb : renderVerticalThumb}
            renderThumbHorizontal={renderHorizontalThumb}
            autoHeight={true}
            autoHeightMax={340}
            {...props}
        />
    );
};

export default Scrollbars;
