'use strict';

angular
    .module('dstreamApp.components.fileViewer', ['ng.deviceDetector'])
    .component('dsFileViewer', {
        templateUrl: 'app/components/fileViewer/ds-file-viewer.html',
        controller: DsFileViewer,
        bindings: {
            width: '@', // ignore, not found
            right: '@',  // ignore - not found anywhere 
            inline: '=', // boolean, in detailed-transactions component
            dashboard: '=' // pass true for dashboard
        }
    });

/* @ngInject */
function DsFileViewer($scope, $rootScope, webServices, $timeout, $routeParams, authenticationInterceptor, $location, deviceDetector) {
    const VIEWER_URL = `${window.publicUrl}/app/pdf/web/viewer.html?`;
    var ctrl = this;
    ctrl.iframeLoaded = iframeLoaded;
    $scope.openedInvoice = null;
    // $scope.confirmTime = 5000;  // is it used anywhere ?
    $scope.currentFileUrl = $rootScope.isNewInvoice ? VIEWER_URL + CurrentVersion : null; // when creating new invoice it's okay to give iframe the initial url, since we use pdfviewers internal methods to upen a file
    $scope.position = undefined;

    

    var cleanupOpen = $rootScope.$on("file.open", function (e, file, invoice) {
        $scope.openedInvoice = invoice ? invoice : null;
        $scope.openFile(file);
    });
    var cleanupClose = $rootScope.$on("file.close", function (e, file) {
        $scope.openedInvoice = null;
        $scope.closeFile(file);
    });

    $scope.$on('PDFjs.show', function () {
        $rootScope.isPDFVisible = true;
        $timeout(function () {
            calculatePosition();
        });
    });

    var isNewInvoiceWatcher = $rootScope.$watch('isNewInvoice', function (newVal, oldVal) {
        if (newVal !== oldVal) {
            $scope.currentFileUrl = newVal ? VIEWER_URL + CurrentVersion : null;
        }
    });

    $scope.$watch("openedInvoice.pendingApproval", function (newValue, oldValue) {
        if ($scope.openedInvoice && $scope.openedInvoice.approved && !$scope.openedInvoice.pendingApproval) {
            $scope.closeFile();
        }
    });

    $scope.confirmInvoice = function () {
        $rootScope.$emit('confirmDashboardInvoice', $scope.openedInvoice);
        $scope.closeFile();
    };
    $scope.cancelApproval = function () {
        $rootScope.$emit('cancelDashboardApproval', $scope.openedInvoice);
    };
    $scope.closeFile = function () {

        $('.file-viewer').attr('style', 'width: 0; height: 100vh;');

        setTimeout(() => {
            if ($scope.currentFile) {
                $scope.currentFile = null;
                $scope.currentFileUrl = null;
            }
            $rootScope.isPDFVisible = false;
            $rootScope.$emit("application.restoreMenuCollapse");
            if (deviceDetector.isMobile() && $location.path() === '/dashboard') {
                window.history.back(); // remove the search value from history
                window.removeEventListener('popstate', $scope.handleMobilePdfFromDashboard  );
            }
        });
    };

    $scope.handleMobilePdfFromDashboard = function () {
        $rootScope.isPDFVisible = false;
        window.removeEventListener('popstate', $scope.handleMobilePdfFromDashboard);
    }

    $scope.openFile = function (file) {
        if (file.IsPdf) {
            if (!file.FileUrl) {
                if (file.Base64Content) {
                    $rootScope.isPDFVisible = true;
                    var pdfData = base64ToUint8Array(file.Base64Content);
                    var pdfjsframe = document.getElementById('file-viewer__frame');
                    pdfjsframe.contentWindow.PDFViewerApplication.open(pdfData);
                    $rootScope.$emit("application.collapseMenu");
                    $timeout(function () {
                        calculatePosition();
                    });
                }
                else {
                    console.warn("File URL is missing!");
                }
                return;
            }
            var fromArchive = $location.path().indexOf('archivedetails') == 1 ? "_true" : "";

            // compare protocols of server and file url. because of Load Balancer, those might be different
            if (file.FileUrl.indexOf('http') == 0) {
                var arrX = file.FileUrl.split(':');
                var protocolX = location.protocol.split(':');
                if (arrX[0] != protocolX[0]) {
                    file.currentFileUrl = protocolX[0] + ':' + arrX[1];
                }
            }
            var fileNameEncoded = encodeURIComponent(file.FileName);
            file.currentFileUrl = file.FileUrl.indexOf(`${VIEWER_URL}file=`) === -1 ? `${VIEWER_URL}file=` + file.FileUrl + fromArchive + '?' + fileNameEncoded : file.FileUrl + fromArchive + '?' + fileNameEncoded;
            $scope.currentFileUrl = file.currentFileUrl;
            $scope.currentFile = file;

            setTimeout(() => {
                $rootScope.isPDFVisible = true;
            })
            if (deviceDetector.isMobile() && $location.path() === '/dashboard') {
                // A trick to handle issue click mobile back button https://fitekgroup.atlassian.net/browse/EMR-4137
                $location.search('pdfView', 'open');
                
                // needs to be in timeout so it doesn't get fired when location.search fires, since it's async
                setTimeout(() => {
                    window.addEventListener('popstate', $scope.handleMobilePdfFromDashboard);
                })
            }
            $rootScope.$emit("application.collapseMenu");
            $timeout(function () {
                calculatePosition();
            });
        }
        else if (file.currentFileUrl && file.Content === "") {
            //its link
            window.open(file.currentFileUrl);
        }
        else {
            //else its a file that needs to be downloaded
            if (file.DownloadUrl) {
                var fromArchive = $location.path().indexOf('archivedetails') > -1 ? true : false;
                webServices.downloadInvoiceFileByUrl(file.DownloadUrl, fromArchive).then(function (response) {
                    let mimeType = ('type' in response.data) ? response.data.type : 'text/xml;charset=utf-8';
                    var blob = new Blob([response.data], { type: mimeType });
                    saveAs(blob, file.FileName);
                });
            } else {
                console.warn("File download url is missing");
            }
        }
    };

    function iframeLoaded(element) {
        // so we would still hear mouseevents that happen on the iframe
        bubbleIframeMouseEvent(element.element, "onmouseup");
        bubbleIframeMouseEvent(element.element, "onmousemove");
        bubbleIframeMouseEvent(element.element, "onclick");
    }

    function bubbleIframeMouseEvent(iframe, eventType) {
        // Save any previous onmousemove handler
        var existingOnMouseEvent = iframe.contentWindow[eventType];

        // Attach a new onmousemove listener
        iframe.contentWindow[eventType] = function (e) {
            // Fire any existing onmousemove listener
            if (existingOnMouseEvent) existingOnMouseEvent(e);
            var mouseEvent = '';
            switch (eventType) {
                case 'onmousemove':
                    mouseEvent = 'mousemove';
                    break;
                case 'onclick':
                    mouseEvent = 'click';
                    break;
                case 'onmouseup':
                    mouseEvent = 'mouseup';
                    break;
            }

            // We'll need this to offset the mouse move appropriately
            var boundingClientRect = iframe.getBoundingClientRect();

            // Initialize the event, copying exiting event values
            // for the most part
            var evt = null;
            try {
                evt = new MouseEvent(mouseEvent, {
                    bubbles: true,
                    cancelable: false,
                    view: window,
                    detail: e.detail,
                    screenX: e.screenX,
                    screenY: e.screenY,
                    clientX: e.clientX + boundingClientRect.left,
                    clientY: e.clientY + boundingClientRect.top,
                    ctrlKey: e.ctrlKey,
                    altKey: e.altKey,
                    shiftKey: e.shiftKey,
                    metaKey: e.metaKey,
                    button: e.button,
                    relatedTarget: null
                });
            } catch (e) {
                // polyfill
                evt = document.createEvent('MouseEvent');
                evt.initMouseEvent(mouseEvent, true, false, window, e.detail, e.screenX, e.screenY, e.clientX + boundingClientRect.left, e.clientY + boundingClientRect.top, e.ctrlKey, e.altKey, e.shiftKey, e.metaKey, e.button, e.relatedTarget);
            }

            // Dispatch the mousemove event on the iframe element
            iframe.dispatchEvent(evt);
        };
    }

    function calculatePosition() {
        const warningElement = document.getElementById('top-warning');
        setTimeout(function() {
            const $pdf = ctrl.inline ? $(".file-viewer") : ctrl.dashboard ? $(".approvers-dash") : $(".invoice-information");
            const position = {};
            const leftOffset = ctrl.inline ? $pdf.offset().left : $pdf.offset().left + $pdf.outerWidth();

            if (window.innerWidth < 767) {
                position.left = 0;
                position.right = 0;
                position.width = '100vw';
            } else {
                position.left = leftOffset + 'px';
                position.width = 'auto';
            }

            position.height = '100%';
            if (ctrl.right) {
                position.right = ctrl.right + "px";
            }

            if (warningElement) {
                const topOffset = warningElement.clientHeight;
                position.top = topOffset + 'px';
            }

            $scope.position = position;

            $('.file-viewer').attr('style', Object.keys(position).map((key)=> `${key}: ${position[key]};`).join(' '));

            // delay position calculation because of the ongoing menu animation
        }, 500);
    }

    function base64ToUint8Array(base64) {
        var raw = atob(base64);
        var uint8Array = new Uint8Array(raw.length);
        for (var i = 0; i < raw.length; i++) {
            uint8Array[i] = raw.charCodeAt(i);
        }
        return uint8Array;
    }

    $scope.$on('$destroy', function () {
        $scope.closeFile();
        if (cleanupClose) cleanupClose();
        if (cleanupOpen) cleanupOpen();
        if (isNewInvoiceWatcher) isNewInvoiceWatcher();
    });
}
