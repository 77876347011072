import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { WithDataId } from 'src/common/utils/dataId';

import Icon, { ICONS } from '../Icon/Icon';

import './Tag.scss';

export interface TagProps extends WithDataId {
    children: React.ReactNode;
    onClick?: () => void;
    onRemove?: () => void;
    className?: string;
    hasArrow?: boolean;
    hasError?: boolean;
}

const Tag: React.FunctionComponent<TagProps> = ({ children, onClick, onRemove, className, hasArrow, hasError, dataId }: TagProps) => {
    const [hoveringRemove, setHoveringRemove] = useState(false);

    function handleMouseEnterRemove() {
        setHoveringRemove(true);
    }
    function handleMouseLeaveRemove() {
        setHoveringRemove(false);
    }

    function handleClickRemove(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();
        if (onRemove) {
            onRemove();
        }
    }

    function handleClick() {
        if (onClick) {
            onClick();
        }
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
        if (onClick && e.key === 'Enter') {
            onClick();
        }
    }

    const classes = classNames('tag', 'tag--active', className, { 'tag--warning': hoveringRemove || hasError }, { 'tag--has-arrow': hasArrow });
    return (
        <span className={classes} onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={0} data-id={`${dataId ? dataId : 'tag'}`}>
            <span className="tag__content">{children}</span>
            {hasArrow && <Icon className="tag__arrow" name={ICONS.ARROW_DOWN_SMALL} />}
            <button
                type="button"
                data-id={`${dataId ? dataId : 'tag'}.remove`}
                className="tag__remove"
                onMouseEnter={handleMouseEnterRemove}
                onBlur={handleMouseLeaveRemove}
                onMouseLeave={handleMouseLeaveRemove}
                onFocus={handleMouseEnterRemove}
                onClick={handleClickRemove}
                onKeyDown={(e) => {
                    e.stopPropagation();
                }}
            >
                <Icon name={ICONS.CLOSE_SMALL} data-id={`${dataId ? dataId : 'tag'}.remove.icon`} />
            </button>
        </span>
    );
};

export default Tag;
