import { HistoryAction } from '../../../../services/types/ApiTypes';

export const historyActionTranslations = {
    [HistoryAction.InsertedInvoice]: 'component.history.InvoiceInserted',
    [HistoryAction.ImportedInvoice]: 'component.history.InvoiceImported',
    [HistoryAction.DeletedInvoice]: 'component.history.InvoiceDeleted',
    [HistoryAction.RejectInvoice]: 'component.history.InvoiceRejected',
    [HistoryAction.DuplicateInvoice]: 'component.history.InvoiceDuplicated',
    [HistoryAction.ExportedInvoice]: 'component.history.InvoiceExported',
    [HistoryAction.ImportedTransactionRows]: 'component.history.ImportedTransactionRows',
    [HistoryAction.CopyInvoice]: 'component.history.Copy',
    [HistoryAction.DisableExport]: 'component.history.DisableExport',
    [HistoryAction.EnableExport]: 'component.history.EnableExport',
    [HistoryAction.AutotransactionApplied]: 'component.history.AutotransactionApplied',
    [HistoryAction.ResendToExport]: 'component.history.ResendToExport',
    [HistoryAction.DynamicWorkflowTemplateApplied]: 'component.history.DynamicWorkflowTemplateApplied',
    [HistoryAction.DeletedUserFromWorkflowDynamically]: 'component.history.DeletedUserFromWorkflowDynamically',
    [HistoryAction.AssignedInvoice]: 'component.history.InvoiceAssigned',
    [HistoryAction.ApprovedInvoice]: 'component.history.InvoiceApproved',
    [HistoryAction.ReopenedInvoice]: 'component.history.InvoiceReopened',
    [HistoryAction.TransferedToOtherCompany]: 'component.history.TransferedToOtherCompany',
    [HistoryAction.RestoredInvoice]: 'component.history.InvoiceRestored',
    [HistoryAction.FileDigitizedInMobileApp]: 'component.history.FileDigitizedInMobileApp',
    [HistoryAction.SetAsNewInvoice]: 'component.history.SetAsNewInvoice',
};
