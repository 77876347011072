import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import withSuspense from '../../common/hocs/withSuspense';

export interface DropdownMenuProps extends WithTranslation {
    children?: React.ReactNode;
}

const DropdownMenuLoader: React.FunctionComponent<DropdownMenuProps> = ({ t }: DropdownMenuProps) => {
    return <span className="dropdown__loader">{t('views.global.Loading')}</span>;
};

export default withSuspense(withTranslation()(DropdownMenuLoader));
