import React from 'react';
import Icon from '../../../../../components/Icon/Icon';
import { getIconProgressBar, showSubstituteName } from '../InvoiceConfirmationsHelper';
import { ConfirmerName } from './ConfirmerName';
import { TaskDTO, UserDataDTO } from '../../../../../services/types/ApiTypes';
import { useTranslation } from 'react-i18next';
import TaskItemContentRight from './TaskItemContentRight';
import { TaskType } from '../InvoiceConfirmations';
import { WithDataId } from 'src/common/utils/dataId';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import classNames from 'classnames';

export interface TaskItemProps extends WithDataId {
    taskItem: TaskDTO;
    userData: UserDataDTO;
    hasAddBefore: boolean;
    hasAddAfter: boolean;
    isShowIcon?: boolean;
    addApprover: (taskItem: TaskDTO, type: TaskType) => void;
}

/**
 * Render the individual Task item for InvoiceConfirmations
 * IF the GroupTask has parallel approvers/confirmers then also render the Icon for these steps
 * IF the TaskItem was completed by a substitute rather than the assignee then render the appropriate comment below the confirmers Name
 */
const TaskItem = (props: TaskItemProps) => {
    const { dataId, isShowIcon, taskItem, userData, hasAddAfter, hasAddBefore, addApprover } = props;
    const { t } = useTranslation();

    const renderIcon = () => {
        const iconName = getIconProgressBar(taskItem);
        const isAutoSkip = iconName === 'ico-auto-skip';
        return isAutoSkip ? (
            <Tooltip content={t('component.confirmationFlow.autoSkipIconTooltip')}>
                <span>
                    <Icon iconName={iconName} className="confirmation__item-group-icon" />
                </span>
            </Tooltip>
        ) : (
            <Icon iconName={iconName} className="confirmation__item-group-icon" />
        );
    };

    return (
        <div className="confirmation__group-tasks" key={taskItem.Id}>
            <div data-id={dataId} className="confirmation__task-item">
                <span className="confirmation__confirmer">
                    {isShowIcon && renderIcon()}
                    <ConfirmerName className={'confirmation__confirmer-name'} taskItem={taskItem} userData={userData} />
                </span>
                <span className="confirmation__complete-date">
                    <TaskItemContentRight taskItem={taskItem} hasAddBefore={hasAddBefore} hasAddAfter={hasAddAfter} userData={userData} addApprover={addApprover} />
                </span>
            </div>
            {showSubstituteName(taskItem, userData) && (
                <span className="confirmation__complete-date">
                    {t('component.transactionRows.onbehalf')} {taskItem.GroupMember.Name}
                </span>
            )}
            {taskItem.Comment && (
                <div className="confirmation__comment">
                    <span className={classNames({ italic: !taskItem.Completed })}>{t(taskItem.Comment)}</span>
                    <span className="confirmation__comment-by">
                        <TaskItemContentRight byWhom={true} t={t} taskItem={taskItem} hasAddBefore={hasAddBefore} hasAddAfter={hasAddAfter} userData={userData} addApprover={addApprover} />
                    </span>
                </div>
            )}
        </div>
    );
};

export default TaskItem;
