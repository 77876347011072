import { LOCATION_CHANGE } from 'connected-react-router';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { WorkflowTemplateDTO } from '../../../services/types/ApiTypes';
import { addOrUpdateWorkflowTemplateLoadableActions, workflowLoadableDataActions } from './WorkflowDetailsViewActions';
import { createSelector } from 'reselect';
import { WorkflowFields, WorkflowItemFields } from './workflowDetailsViewFields';
import { groupBy } from 'lodash-es';

class State {
    workflowLoadable = new LoadableData<WorkflowTemplateDTO, number>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        workflowLoadableDataActions.success,
        (state, action): State => {
            return {
                ...state,
                workflowLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        addOrUpdateWorkflowTemplateLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                workflowLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'accounting/vatCodes',
    whitelist: ['vatCodesSearchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as WorkflowDetailsViewState };

export const selectWorkflowLoadable = (state: GlobalState) => state.workflow.workflowLoadable;

export const selectInitialValues = createSelector(
    selectWorkflowLoadable,
    (workflowLoadable): WorkflowFields => {
        if (!workflowLoadable.loading && workflowLoadable.payload) {
            const { payload: workflow } = workflowLoadable;
            const items: Array<WorkflowItemFields[]> = [];
            if (workflow.WorkflowTemplateItems) {
                const groupedItems = groupBy(
                    workflow.WorkflowTemplateItems.map(
                        (item): WorkflowItemFields => ({
                            id: item.Id,
                            workflowTemplateId: item.WorkflowTemplateId,
                            orderNo: item.OrderNo,
                            maxApproveAmount: item.MaxApproveAmount,
                            approveCount: item.ApproveCount,
                            gmId: item.GroupMemberId,
                            gmName: item.GroupMemberName,
                            isNew: item.IsNew,
                            mustApprove: item.MustApprove,
                            isCompleter: item.IsWorkflowCompleter,
                        }),
                    ),
                    'orderNo',
                );
                Object.keys(groupedItems).forEach((groupKey) => {
                    items.push(groupedItems[groupKey]);
                });
            }
            const initialValues: WorkflowFields = {
                id: workflow.Id,
                name: workflow.Name,
                description: workflow.Description,
                items,
                isNew: workflow.IsNew,
                isDynamic: workflow.IsDynamic,
                isActive: workflow.IsActive,
                inUse: workflow.InUse,
                itemsThresholds: workflow.ApproversPerSteps,
            };
            return initialValues;
        }
        return {
            description: '',
            name: '',
            id: undefined,
            inUse: undefined,
            isDynamic: undefined,
            isActive: undefined,
            isNew: undefined,
            items: undefined,
            itemsThresholds: undefined,
        };
    },
);
