import { LOCATION_CHANGE } from 'connected-react-router';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { BackOfficeResellerDTO } from '../../../../../services/types/BoApiTypes';
import { LoadableData } from '../../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../../rootReducer';

import { saveResellerActions, setActiveBoReseller, showEditBoResellerModal } from './EditBoResellerModalActions';

class State {
    activeBoReseller: BackOfficeResellerDTO | null;
    isEditBoResellerModalOpen = false;
    saveResellerActionLoadable = new LoadableData<BackOfficeResellerDTO, BackOfficeResellerDTO>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        setActiveBoReseller,
        (state, action): State => {
            return {
                ...state,
                activeBoReseller: action.payload,
            };
        },
    )
    .addReducer(
        showEditBoResellerModal,
        (state, action): State => {
            return {
                ...state,
                isEditBoResellerModalOpen: action.payload,
            };
        },
    )
    .addReducer(
        saveResellerActions.request,
        (state): State => {
            return {
                ...state,
                saveResellerActionLoadable: state.saveResellerActionLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        saveResellerActions.success,
        (state, action): State => {
            return {
                ...state,
                saveResellerActionLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        saveResellerActions.error,
        (state, action): State => {
            return {
                ...state,
                saveResellerActionLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'new-BoReseller-modal',
    whitelist: [],
};

export default persistReducer(persistConfig, reducer);

export { State as EditBoResellerModalState };

export const selectActiveBoReseller = (state: GlobalState) => state.editBoResellerModal.activeBoReseller;
export const selectIsEditBoResellerModalOpen = (state: GlobalState) => state.editBoResellerModal.isEditBoResellerModalOpen;
export const selectSaveResellerLoadable = (state: GlobalState) => state.editBoResellerModal.saveResellerActionLoadable;
