import {setExternalLinkToOpen} from "../../../src/components/OpenExternalLinkConfirm/OpenExternalLinkConfirmModalActions";

"use strict";
angular
    .module("dstreamApp.components")
    .component("dsRelatedDocuments", {
        templateUrl: "app/components/relatedDocuments/ds-related-documents.html",
        controller: DsRelatedDocumentsController,
        bindings: {
            invoice: "<",
            invoiceId: "<",
            reloadInvoiceData: "&",
            readonly: "<"
        }
    });

/* @ngInject */
function DsRelatedDocumentsController(
    $scope,
    $rootScope,
    webServices,
    $timeout,
    authenticationService,
    $location,
    LocaleService,
    constants,
    $analytics,
    $ngRedux
) {
    const ctrl = this;

    /**
     * Controller variables
     */
    ctrl.invoiceLoading = true;
    ctrl.isNewInvoice = $rootScope.isNewInvoice;
    ctrl.FileUrl = constants.FileUrl;
    ctrl.DownloadUrl = constants.DownloadUrl;

    /**
     * Scope variables
     */
    $scope.visible = true;
    $scope.position = {};
    $scope.selectedRelatedDocument = null;
    $scope.popover = {
        template: "confirm-template.html",
        select: function (file, idx, evt) {
            evt.stopPropagation();
            $scope.popover.isOpen = $scope.popover.isOpen.map((item, index) => index === idx);
            $scope.selectedRelatedDocument = file;
        },
        cancel: function () {
            $scope.popover.isOpen = $scope.popover.isOpen.map(() => false);
            // $scope.selectedRelatedDocument.popoverOpen = false;
            $scope.selectedRelatedDocument = null;
        },
        confirm: function () {
            $scope.removeFile($scope.selectedRelatedDocument);
            $scope.selectedRelatedDocument = null;

            $analytics.eventTrack('Delete', {
                category: 'Related Documents',
                label: 'Delete related document',
            });
        },
        isOpen: []
    };

    /**
     * Controller functions declarations
     */
    ctrl.openModal = openModal;
    ctrl.$onDestroy = onDestroy;
    ctrl.fileOpenDialog = fileOpenDialog;

    /**
     * Scope functions declarations
     */
    $scope.canAddInvoiceFiles = canAddInvoiceFiles;
    $scope.toggleComponent = toggleComponent;
    $scope.closeFile = closeFile;
    $scope.openFile = openFile;
    $scope.removeFile = removeFile;

    /**
     * Event Watchers
     */
    const newInvoiceEvent = $rootScope.$on("newInvoice", function () {
        ctrl.isNewInvoice = $rootScope.isNewInvoice;
        if (ctrl.isNewInvoice) {
            ctrl.invoice.DocumentFiles = [];
        }
        ctrl.invoiceLoading = false;
    });

    const relatedDocumentsLoadingEvent = $rootScope.$on("relatedDocumentsLoading", function () {
        ctrl.invoiceLoading = true;
    });

    const relatedDocumentsLoadedEvent = $rootScope.$on("relatedDocumentsLoaded", function () {
        ctrl.invoiceLoading = false;
    });

    const invoiceLoadedEvent = $rootScope.$on("invoiceLoaded", function (evt, invoice) {
        if (invoice) {
            ctrl.invoice = angular.copy(invoice);
            if (!!invoice.DocumentFiles) {
                $scope.popover.isOpen = invoice.DocumentFiles.map(() => false);
            }
        }
        ctrl.invoiceLoading = false;
    });

    /**
     * Local functions
     */
    function toggleComponent() {
        $scope.visible = !$scope.visible;
    }

    function closeFile(file) {
        $rootScope.$emit("file.close", file);
    }

    /**
     * openFile
     * @param file: File
     * @param download: boolean
     * @param withFrontPage: boolean
     * @param language: string
     */
    function openFile(file, download, withFrontPage, language) {
        if (download) {
            // hack until popup with language selection is not ready
            if (language === "xxx") {
                language = LocaleService.getCurrentLocale();
                if (!language) {
                    language = "et_EE";
                }
            }
            // end of hack
            var fromArchive = $location.path().indexOf("archivedetails") > -1;
            ctrl.invoiceLoading = true;
            webServices.downloadFileById(file.Id, fromArchive, withFrontPage, language).then(function (response) {
                var blob = new Blob([response.data], {type: "application/pdf"});
                saveAs(blob, file.FileName);
                ctrl.invoiceLoading = false;
            });
            return;
        }
        if (file.IsPdf === true) {
            file.FileUrl = ctrl.FileUrl.concat(file.Id);
            file.DownloadUrl = ctrl.FileUrl.concat(file.Id);
            setTimeout(function () {
                $rootScope.$emit("file.open", file, ctrl.invoice);
            });
            return;
        }
        if (!file.FileUrl) {
            const fileOpen = { ...file, FileUrl: ctrl.DownloadUrl + file.Id, DownloadUrl: ctrl.DownloadUrl + file.Id }
            $rootScope.$emit('file.open', fileOpen, ctrl.invoice);
            return;
        } else {
            var fileUrl = file.FileUrl;
            if (!fileUrl.match(/^https?:\/\//i)) {
                fileUrl = "http://" + fileUrl;
            }
        }
        $ngRedux.dispatch(setExternalLinkToOpen(fileUrl));
    }

    function removeFile(file) {
        ctrl.invoiceLoading = true;
        webServices.deleteInvoiceFile(file).then(function (response) {
            if (response && response.data === "OK") {
                ctrl.reloadInvoiceData({
                    id: ctrl.invoice.Id,
                    callbackEmit: "relatedDocumentsLoaded",
                    invoiceRowPage: 1
                });
            }
        }, function (data) {
            console.log(data);
        });
    }

    function openModal() {
        $rootScope.modalInstance.open({
            templateUrl: "app/components/relatedDocuments/ds-related-documents-modal.html",
            controller: "DsRelatedDocumentsModalController",
            windowClass: "related-documents-modal",
            scope: $scope
        });
    }

    function onDestroy() {
        invoiceLoadedEvent();
        newInvoiceEvent();
        relatedDocumentsLoadingEvent();
        relatedDocumentsLoadedEvent();
    }

    function fileOpenDialog() {
        if ($rootScope.isNewInvoice) {
            // Imitate the built in "Open file" button press in PDFJS
            var documentInIFrame = document.getElementsByClassName("pdfIframe")[0].contentWindow.document;
            documentInIFrame.getElementById("fileInput").click();
            // Show the PDFJS viewer
            $scope.$broadcast("PDFjs.show");
            $analytics.eventTrack('New Invoice', {
                category: 'Invoice Register',
                label: 'Open the invoice',
            });
        } else {
            ctrl.openModal();
        }
    }

    function canAddInvoiceFiles() {
        return authenticationService.isAuthorized("CanAddInvoiceFiles");
    }
}
