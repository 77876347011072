import { AxiosResponse } from 'axios';
import { createAction } from 'redux-actions';
import { parseFilters } from './InvoiceSliderHelpers';
import { BaseSearch, InvoiceDTO, InvoiceSliderMinDTO, PagedListContainerOf, SearchType } from '../../../../services/types/ApiTypes';
import { loadableDataActions } from '../../../../common/utils/LoadableData';
import { DispatchThunk } from '../../../../storeConfig';
import api from '../../../../services/ApiServices';

const ns = 'invoiceSlider/';

export const getSliderInvoicesLoadable = loadableDataActions<BaseSearch, PagedListContainerOf<InvoiceSliderMinDTO>>(`${ns}GET_INVOICE_DATA`);
export const updateInvoice = createAction<InvoiceDTO>(`${ns}UPDATE_INVOICE`);
export const setSliderInvoices = createAction<InvoiceDTO[]>(`${ns}SET_SLIDER_INVOICES`);

export function loadInvoice(status: number) {
    return async (dispatch: DispatchThunk) => {
        let response: AxiosResponse<PagedListContainerOf<InvoiceSliderMinDTO>>;
        try {
            const storedInvoiceFilter = JSON.parse(localStorage.getItem('ls.filterObject'));
            const searchParams: BaseSearch = {
                PagingOptions: {
                    Count: 100,
                    Page: 1,
                },
                Restrictions: (storedInvoiceFilter && parseFilters(storedInvoiceFilter)) || [],
                SortItems: (storedInvoiceFilter && storedInvoiceFilter.sortItems) || [],
            };
            if (status) {
                searchParams.Restrictions = [{ Field: 'Status', Value: '', Values: [status], FieldSearchType: SearchType.Exact }];
            }
            dispatch(getSliderInvoicesLoadable.request(searchParams));
            response = await api.invoice.getSliderInvoicesMin(searchParams);
            dispatch(getSliderInvoicesLoadable.success(response.data));
        } catch (e) {
            console.error('error', e);
            dispatch(getSliderInvoicesLoadable.error(e));
        }
    };
}
