import { isEmpty } from 'lodash-es';

import i18nInstance from '../../../i18n';
import api from '../../../services/ApiServices';
import { DispatchThunk } from '../../../storeConfig';
import { GlobalState } from '../../../rootReducer';
import { notify } from '../../../common/utils/notify';
import { loadableDataActionsWithRequest } from '../../../common/utils/LoadableData';
import { ListViewUserConfig, getViewUserSearchParams, getViewUserSettings, setUserSettingValue } from '../../../common/user/userSettingUtil';
import { getCurrentUserGroupMember, updateUserSettings } from '../../../common/user/UserActions';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../common/utils/baseSearchHelpers';
import { InvoiceCustomFieldDTO, UserSettingName, SortDirection, PagedListContainer, SearchType } from '../../../services/types/ApiTypes';
import { HeaderExtensionsSearchParams, selectHeaderExtensionsListSearchParams } from './HeaderExtensionsReducer';
import { DEFAULT_RESTRICTION, createRequest } from './HeaderExtensionsHelpers';

const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.SETTINGS_HEADER_EXTENSIONS_SORT_DIRECTION,
    sortCol: UserSettingName.SETTINGS_HEADER_EXTENSIONS_SORT_COLUMN,
    pageSize: UserSettingName.SETTINGS_HEADER_EXTENSIONS_PAGE_SIZE,
};
const ns = 'settings/header-extensions/';

export const getHeaderExtensionsListLoadable = loadableDataActionsWithRequest<HeaderExtensionsSearchParams, PagedListContainer<InvoiceCustomFieldDTO>>(`${ns}HEADER_EXTENSIONS_LIST`);

export const getHeaderExtensionsList = (searchParams?: HeaderExtensionsSearchParams) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }

        const headerExtensionsSearchParams = selectHeaderExtensionsListSearchParams(getState());
        const groupMember = getState().user.groupMemberCommonLoadable.payload;
        searchParams = searchParams || headerExtensionsSearchParams || createRequest('');
        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Code', SortDirection.Asc);

        const apiSearchParams = { ...searchParams };
        delete apiSearchParams.filters;
        let response;
        try {
            dispatch(getHeaderExtensionsListLoadable.request(searchParams));
            response = await api.headerExtensions.getInvoiceCustomFieldsWithoutItems(apiSearchParams);
            if (response?.status === 200) {
                dispatch(
                    getHeaderExtensionsListLoadable.success({
                        request: searchParams,
                        result: response.data,
                    }),
                );
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            dispatch(
                getHeaderExtensionsListLoadable.error({
                    request: searchParams,
                    result: e,
                }),
            );
        }
    };
};

export const setHeadrExtensionsListPagingOptions = (page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            headerExtensions,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const paging = headerExtensions.searchParams.PagingOptions;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(listViewConfig, groupMember);
            if (!viewConfig.pageSize || parseInt(viewConfig.pageSize, 10) !== pageSize) {
                groupMember.UserSettings = setUserSettingValue(listViewConfig.pageSize, pageSize, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: HeaderExtensionsSearchParams = {
            ...headerExtensions.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        dispatch(getHeaderExtensionsList(searchParams));
    };
};

export const sortHeadrExtensionsList = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            headerExtensions,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const sorting = headerExtensions.searchParams.SortItems[0];
        const sortingDirection = sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(listViewConfig, groupMember);

            if (!viewConfig.sortCol || viewConfig.sortCol !== columnName) {
                groupMember.UserSettings = setUserSettingValue(listViewConfig.sortCol, columnName, groupMember.UserSettings);
            }
            if (!viewConfig.sortDir || parseInt(viewConfig.sortDir, 10) !== sortingDirection) {
                groupMember.UserSettings = setUserSettingValue(listViewConfig.sortDir, sortingDirection, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: HeaderExtensionsSearchParams = {
            ...headerExtensions.searchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };
        dispatch(getHeaderExtensionsList(searchParams));
    };
};

export const searchHeadrExtensionsList = (searchString: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.headerExtensions.searchParams.PagingOptions;
        let searchRestriction = state.headerExtensions.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        const otherRestrictions = state.headerExtensions.searchParams.Restrictions.filter((restriction, index) => index !== 0);
        let restrictions = [];

        if (!isEmpty(searchString)) {
            if (!searchRestriction) {
                searchRestriction = {
                    Field: DEFAULT_RESTRICTION,
                    Value: searchString,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                };
            } else {
                searchRestriction.Value = searchString;
            }
            restrictions.push(searchRestriction);
        }

        if (!isEmpty(otherRestrictions)) {
            restrictions = [...restrictions, ...otherRestrictions];
        }
        const searchParams: HeaderExtensionsSearchParams = {
            ...state.headerExtensions.searchParams,
            PagingOptions: {
                ...paging,
                Page: 1,
            },
            Restrictions: [...restrictions],
        };
        dispatch(getHeaderExtensionsList(searchParams));
    };
};

export const deleteInvoiceCustomField = (field: InvoiceCustomFieldDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            if (field?.Id) {
                await api.headerExtensions.deleteInvoiceCustomField(field?.Id);
                notify.success(i18nInstance.t('views.headerExtensions.editModal.deleted.successfully'));
                dispatch(getHeaderExtensionsList());
            }
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.message) {
                notify.error(i18nInstance.t(e.response.data.message), i18nInstance.t('interceptorsFactory.Error'));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        }
    };
};
