import classNames from 'classnames';
import * as React from 'react';

import { BaseComponent } from '../BaseComponent';

export interface MainComponentMessageProps {
    children?: React.ReactNode;
    className?: string;
}

export class MainComponentMessage extends BaseComponent<MainComponentMessageProps> {
    render() {
        const { children, className, ...rest } = this.props;
        const classes = classNames('main-component__message', className);
        return (
            <div className={classes} {...rest}>
                {children}
            </div>
        );
    }
}

export default MainComponentMessage;
