import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { cloneDeep } from 'lodash-es';
import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';

import { LoadableData } from '../../common/utils/LoadableData';
import { GlobalState } from '../../rootReducer';
import { AutoTransactionDTO, BaseSearch, PagedListContainer } from '../../services/types/ApiTypes';

import { deleteAutoTransactionActions, searchAutoTransactionsActions, toggleRuleActivityActions } from './AutoTransactionsViewActions';
import { createRequest } from './AutoTransactionsViewHelper';

class State {
    searchParams: BaseSearch = createRequest('');
    autoTransactionsLoadable = new LoadableData<PagedListContainer<AutoTransactionDTO>, BaseSearch>();
    deleteAutoTransactionLoadable = new LoadableData<AutoTransactionDTO, any>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        searchAutoTransactionsActions.request,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload,
                autoTransactionsLoadable: state.autoTransactionsLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        searchAutoTransactionsActions.success,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                autoTransactionsLoadable: state.autoTransactionsLoadable.withPayloadIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        searchAutoTransactionsActions.error,
        (state, action): State => {
            return {
                ...state,
                autoTransactionsLoadable: state.autoTransactionsLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        deleteAutoTransactionActions.request,
        (state, action): State => {
            return {
                ...state,
                deleteAutoTransactionLoadable: LoadableData.loading(action.payload),
            };
        },
    )
    .addReducer(
        deleteAutoTransactionActions.error,
        (state, action): State => {
            return {
                ...state,
                deleteAutoTransactionLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        toggleRuleActivityActions.request,
        (state, action): State => {
            const result = state.autoTransactionsLoadable.payload;
            result.Items = result.Items.map((item) => {
                if (item.Id !== action.payload) {
                    return item;
                }

                return {
                    ...item,
                    IsActive: !item.IsActive,
                };
            });
            return {
                ...state,
                autoTransactionsLoadable: LoadableData.payload({ ...result }, state.autoTransactionsLoadable.request),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state, action: Action<RouterState>): State => {
            if (/\/automation|\/details|\/add/.test(action.payload.location.pathname)) {
                return {
                    ...state,
                };
            }
            const searchRestriction = cloneDeep(state.searchParams.Restrictions[0]);
            searchRestriction.Value = '';
            return {
                ...new State(),
                searchParams: {
                    ...state.searchParams,
                    Restrictions: [searchRestriction],
                },
            };
        },
    )
    .toReducer();

export default reducer;

export { State as AutoTransactionsViewState };

export const selectDeleteAutoTransactionLoadable = (state: GlobalState) => state.autoTransactions.deleteAutoTransactionLoadable;
export const selectAutoTransactionsLoadable = (state: GlobalState) => state.autoTransactions.autoTransactionsLoadable;
export const selectSearchParams = (state: GlobalState) => state.autoTransactions.searchParams;
