import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-aria-menubutton';

import Icon, { ICONS } from '../Icon/Icon';
import { noop } from 'lodash-es';

export interface SelectButtonProps {
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean; // default true
    dataId: string;
    icon?: string;
    isCompact?: boolean;
}

export const SelectTrigger = ({ icon, isCompact, children, className, dataId, ...rest }: SelectButtonProps) => {
    const classes = classNames('select__trigger', { 'select__trigger--compact': isCompact }, className);
    const iconClasses = classNames('select__trigger-arrow', { 'select__trigger-arrow--compact': isCompact });
    return (
        <Button className={classes} {...rest} tag={'div'} data-id={dataId} onSelect={noop}>
            {children}
            <Icon className={iconClasses} name={icon || ICONS.CHEVRON_DOWN_24} />
        </Button>
    );
};
