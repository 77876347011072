import { LOCATION_CHANGE } from 'connected-react-router';
import { cloneDeep } from 'lodash-es';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { BaseSearch, SupplierDTO, PagedListContainer, SearchType, SortDirection } from '../../../services/types/ApiTypes';

import { loadSuppliersLoadableActions } from './SuppliersViewActions';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

class State {
    suppliersLoadable = new LoadableData<PagedListContainer<SupplierDTO>, BaseSearch>();
    suppliersSearchParams: BaseSearch = {
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: '',
                Values: undefined,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        SortItems: [
            {
                SortColumn: 'Name',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 15,
        },
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        loadSuppliersLoadableActions.request,
        (state, action): State => {
            return {
                ...state,
                suppliersSearchParams: action.payload,
                suppliersLoadable: state.suppliersLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        loadSuppliersLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                suppliersSearchParams: action.payload.request,
                suppliersLoadable: state.suppliersLoadable.withPayloadIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        loadSuppliersLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                suppliersLoadable: state.suppliersLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state): State => {
            const searchRestriction = cloneDeep(state.suppliersSearchParams.Restrictions[0]);
            searchRestriction.Value = '';
            return {
                ...new State(),
                suppliersSearchParams: {
                    ...state.suppliersSearchParams,
                    Restrictions: [searchRestriction],
                },
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'settings/suppliers',
    whitelist: ['suppliersSearchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as SuppliersViewState };

export const selectSuppliersLoadable = (state: GlobalState) => state.suppliers.suppliersLoadable;
export const selectSuppliersSearchParams = (state: GlobalState) => state.suppliers.suppliersSearchParams;
