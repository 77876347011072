'use strict';

angular.module('dstreamApp.controllers').controller('chatController', [
    '$scope', '$rootScope', 'webServices', 'signalrSvc', function ($scope, $rootScope, webServices, signalrSvc) {
    	$scope.msg = '';
    	$scope.currentUserPersonalCode = sessionStorage.UserPersonalCode;
    	$scope.widgets = new Array();

    	var addWidget = function (data) {
    		$scope.widgets.push(data);
    	};

    	$rootScope.$on("newWidget", function (e, data) {
    		if (data.To == sessionStorage.UserPersonalCode) {
    			webServices.getAllUnread(sessionStorage.UserPersonalCode).then(function (response) {
    				$scope.widgets.push.apply($scope.widgets, response.data);
    				if (!$scope.$parent.header.chat)
    					$scope.$parent.hasNewMessages = true;
    			}, function (data) {
    				console.log(data);
    			});
    		}
    	});

    	$scope.sendChatMessage = function (message) {
    		var data = {
    			Message: message,
    			ToUser: sessionStorage.SuperUserPersonalCode,
    			FromUser: sessionStorage.UserPersonalCode,
    			ConnectionId: $scope.currentConnectionId,
    			DateSent: new Date(),
    			IsUnread: true
    		};
    		addWidget(data);
    		webServices.sendChatMessage(data)
			.then(function (response) {
			}, function (data) {
				console.log(data);
			});
    	}

    	signalrSvc.initialize();

    	$scope.currentConnectionId = function () {
    		return signalrSvc.proxy.connection.id;
    	}

    	webServices.getAllRecentOrUnreadFrom(sessionStorage.UserPersonalCode).then(function (response) {
    		$scope.widgets.push.apply($scope.widgets, response.data);
    		var filtered = _.filter($scope.widgets, { IsUnread: true });
    		if (filtered && filtered.length) // Show the new message indicator only if there are unread messages
    			$scope.$parent.hasNewMessages = true;
    	}, function (data) {
    		console.log(data);
    	});

    }])