import { cloneDeep } from 'lodash-es';
import {SortDirection, UserSettingName} from "../../../../../src/services/types/ApiTypes";
import {getViewUserSearchParams} from "../../../../../src/common/user/userSettingUtil";
import {getCurrentUserGroupMember} from "../../../../../src/common/user/UserActions";
import {updateAppUserSettingsAction} from "../../../../../src/common/middlewares/userSettings";

(function() {
    'use strict';

    angular
        .module('dstreamApp.views.archive-register')
        .component('dsArchiveRegisterTable', {
            templateUrl: 'app/views/archive-register/components/archive-register-table/archive-register-table.html',
            controller: ArchiveRegisterTable
        });

    ArchiveRegisterTable.$inject = ['$rootScope', '$scope', 'invoiceRegisterService', 'utils', 'authenticationService', 'constants', '$location', '$q', '$ngRedux'];

    function ArchiveRegisterTable($rootScope, $scope, invoiceRegisterService, utils, authenticationService, constants, $location, $q, $ngRedux) {
        var ctrl = this;
        /* CONTROLLER VARIABLES */
        ctrl.pageSizes = constants.DEFAULT_PAGE_SIZES;
        ctrl.listViewConfig = {
            sortDir: UserSettingName.ARCHIVE_SORT_DIRECTION,
            sortCol: UserSettingName.ARCHIVE_SORT_COLUMN,
            pageSize: UserSettingName.ARCHIVE_PAGE_SIZE,
        };
        ctrl.searchParams = {
            PagingOptions: {
                Page: 0,
                Count: 0,
            },
            SortItems: [
                {
                    SortDirection: 0,
                    SortColumn: '',
                },
            ],
            Restrictions: [],
        };
        ctrl.localStoragePrefix = 'archive';
        ctrl.invoiceArray = [];
        ctrl.loading = false;
        ctrl.dateRangeVisible = false;
        // get intial filter object from service, needs to be morphed for archive register table, since archive and reguler invoice register default filter is different
        ctrl.filterObject = invoiceRegisterService.getFilterFromLocalStorage(ctrl.localStoragePrefix) || morphForArchive(invoiceRegisterService.createDefaultFilterObject(true));


        /* CONTROLLER METHODS */
        ctrl.toggleDateRangeFilter = toggleDateRangeFilter;
        ctrl.shiftDateRange = shiftDateRange;
        ctrl.doSearch = doSearch;
        ctrl.openInvoice = openInvoice;
        ctrl.changePageSize = changePageSize;
        ctrl.changeSorting = changeSorting;

        ctrl.$onInit = function() {
            $ngRedux.dispatch(getCurrentUserGroupMember()).then((groupMember) => {
                ctrl.searchParams = getViewUserSearchParams(ctrl.searchParams, ctrl.listViewConfig, groupMember);
                const filterObject = {
                    ...ctrl.filterObject,
                    pagingOptions: {
                        ...ctrl.filterObject.pagingOptions,
                        count: ctrl.searchParams.PagingOptions.Count,
                    },
                    sortItems: [
                        {
                            SortColumn: ctrl.searchParams.SortItems[0].SortColumn,
                            SortDirection: ctrl.searchParams.SortItems[0].SortDirection,
                        }
                    ],
                };
                doSearch(filterObject, true, false);
            });
        };

        /* EVENTS & WATHCERS */
        var currentPageWatcher = $scope.$watch(function() {return ctrl.filterObject.pagingOptions.page}, function(newVal, oldVal) {
            if(newVal !== oldVal) {
                ctrl.filterObject.pagingOptions.page = newVal;
                doSearch(ctrl.filterObject, false);
            }
        });

        var invoiceRegisterLoadingEvent = $rootScope.$on('invoiceRegisterLoading', function() {
            ctrl.loading = true;
        });
        var invoiceRegisterLoadedEvent = $rootScope.$on('invoiceRegisterLoaded', function(event, data) {
            ctrl.invoiceArray = data.result;
            ctrl.loading = false;
            ctrl.filterObject = cloneDeep(data.filterObject); // get a copy so we wouldn't change the actual value in the service
        });
        var invoiceRegisterFilterClearedEvent = $rootScope.$on('invoiceRegisterFilterCleared', function(event, defaultFilterObject) {
            ctrl.filterObject = morphForArchive(invoiceRegisterService.createDefaultFilterObject(true));
            invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 0, 1, ctrl.localStoragePrefix);
        });

        /*
            Toggle date range filter visibility
         */
        function toggleDateRangeFilter(e) {
            e.preventDefault();
            $rootScope.$emit('toggleDateRangeVisibility');
        }

        /*
            Shift date rage filter back by how many days are in the range
        */
        function shiftDateRange(direction) {
            var originalFromModel = cloneDeep(ctrl.filterObject.fromModel);
            var originalToModel = cloneDeep(ctrl.filterObject.toModel);
            if (ctrl.filterObject.timeType === 'currentMonth' || ctrl.filterObject.timeType === 'lastMonth' || ctrl.filterObject.timeType === 'shiftedCustomMonth') {
                // if we are shifting by months
                ctrl.filterObject.fromModel = direction === 'forward' ? utils.getFirstDateOfNextMonth(originalToModel) : utils.getFirstDateOfPreviousMonth(originalToModel);
                ctrl.filterObject.toModel = direction === 'forward' ? utils.getLastDateOfNextMonth(originalToModel) : utils.getLastDateOfPreviousMonth(originalToModel);
                ctrl.filterObject.timeType = 'shiftedCustomMonth';
            } else if (ctrl.filterObject.timeType === 'thisYear' || ctrl.filterObject.timeType === 'shiftedCustomYear') {
                // if we are shifting by years
                ctrl.filterObject.fromModel = direction === 'forward' ? utils.getFirstDateOfNextYear(originalToModel)  : utils.getFirstDateOfPreviousYear(originalToModel);
                ctrl.filterObject.toModel = direction === 'forward' ? utils.getLastDateOfNextYear(originalToModel) : utils.getLastDateOfPreviousYear(originalToModel);
                ctrl.filterObject.timeType = 'shiftedCustomYear';
            } else {
                // if we are not shifting by months or years
                ctrl.filterObject.fromModel = direction === 'forward' ? utils.getNextDay(originalToModel) : utils.shiftBack(originalFromModel, originalToModel);
                ctrl.filterObject.toModel =  direction === 'forward' ? utils.shiftForward(originalFromModel, originalToModel) : utils.getPreviousDay(originalFromModel);
                ctrl.filterObject.timeType = 'customDates';
            }

            ctrl.filterObject.pagingOptions.page = 1; // set page pack to beginning
            doSearch(ctrl.filterObject, false);
        }

        /*
            Open invoice details and if the invoice is under a related company then do company switch first
         */
        function openInvoice(invoice, e) {
            e.preventDefault();
            if (authenticationService.isAuthorized('CanAddInvoice') && invoice.CompanyGuid !== $rootScope.companyData.CompanyGuid && invoice.Status != 6) {
                //its related Company invoice
                authenticationService.changeCompany(invoice.CompanyGuid).then(function () {
                    $location.path("/archivedetails/" + invoice.Id);
                });
            }
            else {
                //its the same company invoice
                $location.path("/archivedetails/" + invoice.Id);
            }
        }

        /*
            Change the max row count in the table and also set pagination to the first page and fill table with new data
         */
        function changePageSize(size, e) {
            e.preventDefault();
            ctrl.filterObject.pagingOptions.count = size;
            ctrl.filterObject.pagingOptions.page = 1;

            const {company: {groupMembers}, user: {currentUser: {GroupMemberId}}} = $ngRedux.getState();

            $ngRedux.dispatch(updateAppUserSettingsAction({
                listViewConfig: ctrl.listViewConfig,
                searchParams: {
                    ...ctrl.searchParams,
                    PagingOptions: {
                        ...ctrl.searchParams.PagingOptions,
                        Count: size,
                    }
                },
            })).then((response) => {
                ctrl.searchParams = {
                    ...response.payload.searchParams
                };
                doSearch(ctrl.filterObject, false, true);
            });
        }

        function changeSorting(sortColumn, sortDirection) {
            const {company: {groupMembers}, user: {currentUser: {GroupMemberId}}} = $ngRedux.getState();

            $ngRedux.dispatch(updateAppUserSettingsAction({
                listViewConfig: ctrl.listViewConfig,
                searchParams: {
                    ...ctrl.searchParams,
                    SortItems: [{
                        SortDirection: sortDirection === SortDirection.Desc ? SortDirection.Desc : SortDirection.Asc,
                        SortColumn: sortColumn,
                    }]
                },
            })).then((response) => {
                ctrl.searchParams = {
                    ...response.payload.searchParams
                };
                doSearch(ctrl.filterObject, false, true);
            });
        }

        /*
            Do actual search
         */
        function doSearch(filterObject, skipFilterSave) {
            invoiceRegisterService.doSearch(filterObject, skipFilterSave, true, false, true, false, ctrl.localStoragePrefix).then(function (result) {
                $rootScope.$emit('invoiceRegisterLoaded', {
                    result: result.result,
                    filterObject: result.filterObject
                });
            }); // we use the invoiceRegisterLoaded eventwatcher in this component
        }

        /* INTERNAL METHODS */
        /*
            If we are current on archive page then change filters defaults acordingly
         */
        function morphForArchive(filterObject) {
            filterObject.statusFilter = [{ Name: "Archived", Value: 6 }];
            filterObject.timeType = 'forever';
            filterObject.timeTypeLabel = 'component.invoiceFilter.customDates';
            return filterObject;
        }

        ctrl.$onDestroy = function () {
            if(currentPageWatcher) currentPageWatcher();
            if(invoiceRegisterLoadingEvent) invoiceRegisterLoadingEvent();
            if(invoiceRegisterLoadedEvent) invoiceRegisterLoadedEvent();
            if(invoiceRegisterFilterClearedEvent) invoiceRegisterFilterClearedEvent();
        };
    }
})();