import { ReducerFactory } from 'redux-actions-ts-reducer';

import { LoadableData } from '../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../rootReducer';
import { InvoiceDataResponseDTO } from '../../../../services/types/ApiTypes';

import { exportStatusResetLoadable, getInvoiceDataLoadable, updateInvoiceExportStateLoadable } from './InvoiceExportManagementActions';

class State {
    isExportEnabled = false;
    invoiceDataLoadable = new LoadableData<InvoiceDataResponseDTO, string>();
    exportDataLoadable = new LoadableData<boolean, string>();
}

export { State as InvoiceDataState };

export default new ReducerFactory(new State())
    .addReducer(
        getInvoiceDataLoadable.request,
        (state): State => {
            return {
                ...state,
                isExportEnabled: false,
                invoiceDataLoadable: state.invoiceDataLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        getInvoiceDataLoadable.success,
        (state, action): State => {
            return {
                ...state,
                isExportEnabled: !!action.payload.Invoice.IsExportAfterApprovalEnabled,
                invoiceDataLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        exportStatusResetLoadable.request,
        (state): State => {
            return {
                ...state,
                exportDataLoadable: state.exportDataLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        exportStatusResetLoadable.success,
        (state, action): State => {
            return {
                ...state,
                exportDataLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        updateInvoiceExportStateLoadable.success,
        (state): State => {
            return {
                ...state,
            };
        },
    )
    .toReducer();

export const selectInvoiceDataLoadable = (state: GlobalState) => state.invoiceData.invoiceDataLoadable;
export const selectInvoiceExportEnabled = (state: GlobalState) => state.invoiceData.isExportEnabled;
