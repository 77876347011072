import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

(function() {
    'use strict';

    angular
        .module('blocks.websocket')
        .factory('websocketService', websocketService);

    websocketService.$inject = ['$rootScope', 'companyDataService'];

    /*
        Handle everything related to websockets
     */
    function websocketService($rootScope, companyDataService) {
        var socketClient = null;
        var service = {
            connect: connect,
            disconnect: disconnect,
            reconnect: reconnect,
            status: status
        };

        function status() {
            return !!socketClient || socketClient.connected;
        }

        /*
            Establish socket connection
         */
        function connect() {
            console.log('Connecting to socket...');
            var ws = new SockJS(stompUrl); // stompUrl is from index.cshtml
            socketClient = Stomp.over(ws);

            socketClient.heartbeat.outgoing = 0;
            socketClient.heartbeat.incoming = 0;
            socketClient.connect('webapi', 'webapi', onConnect, onError, '/');
            console.log('... connection successful!');
        }

        /*
            Disconnect the socket connection
         */
        function disconnect() {
            console.log('Disconnecting socket...');
            if (socketClient.connected) {
                socketClient.disconnect();
                console.log('... disconnect success!');
            } else {
                console.log('... nothing to disconnect from!');
            }
        }

        function reconnect() {
            console.log('Initiating socket reconnection sequence...');
            disconnect();
            connect();
            console.log('... socket reconnection sequence completed!');
        }

        /*
            Do on socket connection
         */
        function onConnect() {
            console.log('Socket Connected');

            // subscribe to websocket stream and handle all the responses
            socketClient.subscribe("/topic/WebApiChangeTopic" + $rootScope.companyGuid, function (m) {
                // reply by sending the reversed text to the temp queue defined in the "reply-to" header
                var body = m.body;
                // there's a change in available AccountList in DB, so forceFetch new accountList
                if (body == "Account") {
                    companyDataService.getAccounts(true);
                }
                // there's a change in available WorkflowList in DB, so forceFetch new companyData
                if (body == "Workflow") {
                    companyDataService.getCompanyData(true);
                }
            });
        }

        /*
            Do on socket connection error
         */
        function onError(e) {
            console.log('Socket Error:', e);
        }

        return service;
    }
})();