import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { isEmpty } from 'lodash-es';
import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';

import { GlobalState } from '../../rootReducer';
import { AdvancedSearch } from '../typings/InvoiceFilter';

import { resetInvoiceFilter, setInvoiceFilter } from './InvoiceFilterActions';

export interface Location {
    location: string;
    filter: AdvancedSearch;
}

class State {
    current: Location = undefined;
    previous: Location = undefined;
}

export default new ReducerFactory(new State())
    .addReducer(
        setInvoiceFilter,
        (state, action: Action<Location>): State => {
            const currentLocation: Location = {
                location: action.payload.location,
                filter: isEmpty(state.current) ? null : { ...state.current.filter },
            };
            if (/invoices|invoiceconfirmation|transactions/.test(action.payload.location)) {
                currentLocation.filter = action.payload.filter;
            }

            return {
                previous: { ...state.previous },
                current: { ...currentLocation },
            };
        },
    )
    .addReducer(
        resetInvoiceFilter,
        (state): State => {
            return {
                previous: { ...state.previous },
                current: { ...state.current, filter: undefined },
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state, action: Action<RouterState>): State => {
            const previousLocation: Location = isEmpty(state.current) ? undefined : { ...state.current };
            const currentLocation: Location = {
                location: action.payload.location.pathname,
                filter: undefined,
            };

            if (/invoices|invoiceconfirmation|transactions/.test(action.payload.location.pathname)) {
                currentLocation.filter = !!previousLocation && !isEmpty(previousLocation.filter) ? previousLocation.filter : JSON.parse(window.localStorage.getItem('ls.filterObject'));
            }

            return {
                current: currentLocation,
                previous: previousLocation,
            };
        },
    )
    .toReducer();

export { State as InvoiceFilterHistoryState };

export const selectRoutesHistory = (state: GlobalState) => state.invoiceFilterHistory;
