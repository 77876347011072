import { isEmpty } from 'lodash-es';
import { selectPdfToOpen } from '../../../../../src/components/PDFViewer/FileViewReducer';
import { setPdfToOpenAction } from '../../../../../src/components/PDFViewer/FileViewActions';
import { getCurrentUserGroupMember } from "../../../../../src/common/user/UserActions";
import { getViewUserSearchParams } from "../../../../../src/common/user/userSettingUtil";
import { UserSettingName } from "../../../../../src/services/types/ApiTypes";

(function () {
  "use strict";

    angular
    .module("dstreamApp.views.archive-details")
    .component("dsStaticInvoiceSlider", {
      templateUrl: "app/views/archive-details/components/static-invoice-slider/static-invoice-slider.html",
      controller: StaticInvoiceSlider,
      bindings: {
        invoice: "<",
        onInvoiceChange: "&"
      }
    });

  StaticInvoiceSlider.$inject = [
    "$scope",
    "$rootScope",
    "$ngRedux",
    "invoiceRegisterService",
    "location"
  ];

  function StaticInvoiceSlider ($scope, $rootScope, $ngRedux, invoiceRegisterService, location) {

    $scope.isPdfOpen = false;

    var ctrl = this;
    /* CONTROLLER VARIABLES */
    ctrl.loading = true;
    ctrl.localStoragePrefix = 'archive';
    ctrl.displaySlider = true;
    ctrl.displayArrows = true;
    ctrl.invoicesAlreadyLoaded = false;
    ctrl.listViewConfig = {
      sortDir: UserSettingName.ARCHIVE_SORT_DIRECTION,
      sortCol: UserSettingName.ARCHIVE_SORT_COLUMN,
      pageSize: UserSettingName.ARCHIVE_PAGE_SIZE,
    };
    ctrl.filterObject = invoiceRegisterService.getFilterFromLocalStorage(ctrl.localStoragePrefix) || morphForArchive(invoiceRegisterService.createDefaultFilterObject(true)); // get intial filter object from service
    ctrl.searchParams = {
      PagingOptions: {
          Page: 0,
          Count: 0,
      },
      SortItems: [
          {
              SortDirection: 0,
              SortColumn: '',
          },
      ],
      Restrictions: [],
  };
    ctrl.sliderInvoices = [];
    ctrl.slickSettings = {
      infinite: false,
      enabled: false,
      slidesToShow: 7,
      slidesToScroll: 1,
      method: {},
      prevArrow: "<button type=\"button\" data-role=\"none\" class=\"slick-prev\" aria-label=\"Previous\" tabindex=\"0\" role=\"button\">Previous<svg class=\"icon icon__slider-arrow\"><use xlink:href=\"#icon-arrow_left\"></use></svg></button>",
      nextArrow: "<button type=\"button\" data-role=\"none\" class=\"slick-next\" aria-label=\"Next\" tabindex=\"0\" role=\"button\">Next<svg class=\"icon icon__slider-arrow\"><use xlink:href=\"#icon-caret_right\" ></use></svg></button>"
    };
    ctrl.currentSlide = ctrl.slickSettings.initialSlide;
    ctrl.slideCount = 0;
    ctrl.toShow = ctrl.slickSettings.slidesToShow;

    /* CONTROLLER METHODS */
    ctrl.displayInvoice = displayInvoice;
    ctrl.displayInvoiceNumber = displayInvoiceNumber;

    ctrl.$onInit = function () {
      // only load invoices on first page load.
      // when user switches invoices from the slider we don't need to load it again
      if (!ctrl.slickSettings.enabled) {
        // in some cases we have the invoice already loaded before we get the response back from getInvoicesSmall();
        // in that case still display the slider
        ctrl.invoiceLoadedEvent = $rootScope.$on("invoiceLoaded", function (evt, invoice) {
          ctrl.invoicesAlreadyLoaded = true;
          ctrl.invoice = angular.copy(invoice);
        });

        $ngRedux.dispatch(getCurrentUserGroupMember()).then((groupMember) => {
          ctrl.searchParams = getViewUserSearchParams(ctrl.searchParams, ctrl.listViewConfig, groupMember);
          const filterObject = {
              ...ctrl.filterObject,
              pagingOptions: {
                  ...ctrl.filterObject.pagingOptions,
                  count: ctrl.searchParams.PagingOptions.Count,
              },
              sortItems: [
                  {
                      SortColumn: ctrl.searchParams.SortItems[0].SortColumn,
                      SortDirection: ctrl.searchParams.SortItems[0].SortDirection,
                  }
              ],
          };
          loadInvoices(filterObject);
      });
      }
    };

    /*
        We clicked on an invoice in the slider
     */
    function displayInvoice (row, $index, e) {
      e.preventDefault();
      // hide PDF viewer
      $rootScope.isPDFVisible = false;
      $ngRedux.dispatch(setPdfToOpenAction(null));

      //skip the state reload, only update the invoice
      location.skipReload().path("/archivedetails/" + row.Id).replace();

      // start component loaders
      $rootScope.$emit("invoiceRowsLoading");
      $rootScope.$emit("additionalInvoiceLoading");
      $rootScope.$emit("transactionRowsLoading");
      $rootScope.$emit("invoiceLoading");
      $rootScope.$emit("relatedDocumentsLoading");
      $rootScope.$emit("invoiceSwitching");
      $rootScope.$emit("resetTransactionRowsPaginator");

      ctrl.onInvoiceChange({id: row.Id});
      ctrl.slickSettings.method.slickGoTo(calculateSlidePositions($index));
    }

    /*
        To trucate string from the left, we reverse, truncate right, and reverse again
     */
    function displayInvoiceNumber (row) {
      try {
        var reversedNumber = row.Number.split("").reverse().join("");
        var truncatedNumber = reversedNumber.substring(0, 10);  // 10 - max characters allowed
        var finalNumber = truncatedNumber.split("").reverse().join("");
        return (row.Number.length > finalNumber.length ? "..." : "") + finalNumber;
      } catch (err) {
        console.error(err);
        return "";
      }
    }

    /* INTERNAL METHODS */

    /*
        Initial invoice array load
     */
    function loadInvoices (filterObject) {
      filterObject.pagingOptions = {page: 1, count: 100, total: 0};
      return invoiceRegisterService.doSearch(filterObject, true, true, false, true).then(function (data) {
        if (data.result) {
          ctrl.sliderInvoices = data.result;

          $rootScope.$emit("invoiceRegisterLoaded", {
            result: data.result,
            filterObject: data.filterObject
          });

          if (ctrl.sliderInvoices.length <= 1) {
            // if we have one invoice, then hide the slider
            ctrl.loading = false;
            ctrl.displaySlider = false;
            return;
          } else if (ctrl.sliderInvoices.length <= ctrl.slickSettings.slidesToShow) {
            // if we have more invoices than we can fit on the slider then show arrows
            ctrl.slickSettings.arrows = false;
            ctrl.displayArrows = false;
          }
          ctrl.slideCount = ctrl.sliderInvoices.length;
          ctrl.toShow = ctrl.slickSettings.slidesToShow;

          // if we have less slides to show
          if (ctrl.slideCount < ctrl.toShow) {
            ctrl.slickSettings.slidesToShow = ctrl.slideCount;
          }

          // wait until the main invoice data is loaded to display the slider
          if (ctrl.invoicesAlreadyLoaded) {
            ctrl.slickSettings.initialSlide = calculateSlidePositions();
            ctrl.slickSettings.enabled = true;
            ctrl.loading = false;
          } else {
            ctrl.invoiceLoadedEvent = $rootScope.$on("invoiceLoaded", function () {
              ctrl.invoicesAlreadyLoaded = true;
              ctrl.slickSettings.initialSlide = calculateSlidePositions();
              ctrl.slickSettings.enabled = true;
              ctrl.loading = false;
            });
          }
        }
      }); // request for invoices that match current filter
    }

    /*
        If we are current on archive page then change filters defaults acordingly
     */
    function morphForArchive (filterObject) {
      filterObject.statusFilter = [{Name: "Archived", Value: 6}];
      filterObject.timeType = "forever";
      filterObject.timeTypeLabel = "component.invoiceFilter.customDates";
      return filterObject;
    }

    /*
        Since ctrl.slickSettings.initialSlide is the most left box in the slider we need to calculate it
        so the selected box would be in the center
        cur = id of the selected box. if we don't have it, then match it from the invoice array
     */
    function calculateSlidePositions (cur) {
      var moveTo = 0;

      // we clicked on a box
      if (cur || cur === 0) {
        ctrl.currentSlide = cur;
      }
      // we have one box selected already
      else if (!isEmpty(ctrl.invoice)) {
        for (var i = 0; i < ctrl.sliderInvoices.length; i++) {
          if (ctrl.sliderInvoices[i].Id === ctrl.invoice.Id) {
            ctrl.currentSlide = i;
            break;
          }
        }
      }

      // calculate the index of the most left slide to show
      moveTo = ctrl.currentSlide - Math.floor(ctrl.slickSettings.slidesToShow / 2);

      if (moveTo < 0) {
        // fallback if we are moving too far left
        moveTo = 0;
      } else if (moveTo > ctrl.sliderInvoices.length - ctrl.slickSettings.slidesToShow) {
        // fallback if we are moving too far right
        moveTo = ctrl.sliderInvoices.length - ctrl.slickSettings.slidesToShow;
      }

      return moveTo;
    }

    ctrl.$onDestroy = function () {
        ctrl.invoiceLoadedEvent();

    };

    $ngRedux.subscribe(() => {
      const state = $ngRedux.getState();
      const pdfToOpen = selectPdfToOpen(state);
      if (!!pdfToOpen) {
        $scope.isPdfOpen = true;
      } else if ($scope.isPdfOpen) {
        $scope.isPdfOpen = false;
      }
  })

  let unsubscribeReduxPdf = $ngRedux.connect((state) => ({pdfToOpen: selectPdfToOpen(state)}))($scope);

  $scope.$on("$destroy", function () {
      unsubscribeReduxPdf();
  });

  }
})();