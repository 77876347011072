import React, { MouseEvent, useEffect } from 'react';
import { WithTranslation } from 'react-i18next';
import { Dispatchable0, Dispatchable1 } from 'redux-dispatchers';
import { ICONS } from '../Icon/Icon';
import { TopMenuItem } from './components/TopMenuItem';
import { User } from '../../services/ApiClient';
import { FileDTO } from '../../services/types/ApiTypes';
import { paths } from '../../common/router/routePaths';
import ChangeCompanyWrapper from '../../components/ChangeCompany/ChangeCompanyWrapper';
import UserProfileWrapper from '../../components/UserProfile/UserProfileWrapper';
import ChangeLanguageWrapper from '../../components/ChangeLanguage/ChangeLanguageWrapper';
import OpenExternalLinkConfirmModal from '../../components/OpenExternalLinkConfirm/OpenExternalLinkConfirmModalWrapper';
import NewSupplierWrapper from '../../components/EditSupplierModal/EditSupplierModalWrapper';
import EmailVerificationWrapper from '../EmailVerification/EmailVerificationWrapper';

import './TopMenu.scss';
import classNames from 'classnames';

export interface TopMenuProps {
    currentUser: User;
    emailVerificationProcess: boolean;
    emailVerificationCompleted: boolean;
    currentPath?: string;
    currentCompanyName: string;
    pdfToOpen: FileDTO;
    isUserProfileVisibleAndFocusOn: boolean | string;
    signOut?: (e: MouseEvent<Element>, userLogout?: boolean) => void;
    signOutId?: (e: MouseEvent<Element>) => void;
    areWaitingInvoicesCountsUpdating: boolean;
}

export interface DispatchProps {
    showChangeCompany: Dispatchable1<boolean>;
    showUserProfile: Dispatchable1<boolean>;
    showChangeLanguage: Dispatchable1<boolean>;
    getUserCompanies: Dispatchable0;
    updateAllWaitingInvoiceCounts: Dispatchable0;
}

export type Props = WithTranslation & TopMenuProps & DispatchProps;

const TopMenu: React.FC<Props> = ({
    t,
    currentUser,
    emailVerificationProcess,
    emailVerificationCompleted,
    currentPath,
    currentCompanyName,
    pdfToOpen,
    isUserProfileVisibleAndFocusOn,
    showChangeLanguage,
    showChangeCompany,
    signOut,
    signOutId,
    showUserProfile,
    getUserCompanies,
    updateAllWaitingInvoiceCounts,
    areWaitingInvoicesCountsUpdating,
}: Props): React.ReactElement => {
    const isBackOffice = currentPath.includes(paths.backOffice.default);
    let updateInterval: number;
    const setInterval = () => {
        // Invoices count should update every hour
        updateInterval = window.setInterval(() => {
            updateAllWaitingInvoiceCounts();
        }, 60 * 60 * 1000);
    };

    const clearInterval = () => window.clearInterval(updateInterval);

    const shouldUpdateInvoicesCount = () => {
        // it can be 'true' (updates are running) or undefined (initial state),
        // on initial state the update will be triggered by getUserCompanies() action
        return areWaitingInvoicesCountsUpdating !== true && areWaitingInvoicesCountsUpdating !== undefined;
    };

    useEffect(() => {
        getUserCompanies();
        setInterval();
        return clearInterval;
    }, []);

    useEffect(() => {
        if (!isBackOffice && currentCompanyName) {
            clearInterval();
            shouldUpdateInvoicesCount() && updateAllWaitingInvoiceCounts();
            setInterval();
        }
    }, [isBackOffice]);

    const classes = classNames('top-menu', {
        'top-menu--half-width': pdfToOpen,
    });

    return (
        <>
            <div className={classes}>
                <nav className="top-menu__navigation">
                    <ul className="top-menu__links">
                        {!isBackOffice && (
                            <>
                                <TopMenuItem className="language-menu" item={t('view.global.lang')} dataId="header.language" icon={ICONS.LANG_24} itemPress={() => showChangeLanguage(true)} />
                                <TopMenuItem item={currentCompanyName} dataId="header.companyName" icon={ICONS.COMPANY_24} itemPress={() => showChangeCompany(true)} />
                            </>
                        )}

                        <TopMenuItem item={currentUser?.FullName} dataId="header.fullName" itemPress={() => showUserProfile(true)} icon={ICONS.USER_24} />
                        <TopMenuItem dataId="header.logout" item={t('views.home.index.logout')} icon={ICONS.LOGOUT_24} itemPress={(e) => signOut(e, true)} />
                        <button style={{ display: 'none' }} onClick={(e) => signOutId(e)}>
                            Sign Out Id Card
                        </button>
                    </ul>
                </nav>
            </div>

            {/* Modals that are opened from state */}
            {!isBackOffice && <ChangeCompanyWrapper />}
            {!isBackOffice && <ChangeLanguageWrapper />}
            {/* Modal for editting/adding a supplier */}
            {!isBackOffice && <NewSupplierWrapper />}
            {!!isUserProfileVisibleAndFocusOn && <UserProfileWrapper />}
            <OpenExternalLinkConfirmModal />
            {(emailVerificationProcess || emailVerificationCompleted) && <EmailVerificationWrapper emailVerificationCompleted={emailVerificationCompleted} />}
        </>
    );
};

export default TopMenu;
