'use strict';

import { encodeUriFilterObject } from "../../../src/views/invoice-details/components/invoice-slider/InvoiceSliderHelpers";

angular
    .module('dstreamApp.components')
    .component('dsDashboardReceipts', {
        templateUrl: 'app/components/dashboardReceipts/ds-dashboard-receipts.html',
        controller: DsDashboardReceiptsController
    });

function DsDashboardReceiptsController($scope, invoiceService, $rootScope, constants, invoiceRegisterService, $location) {
    var ctrl = this;
    ctrl.DATE_EE_FORMAT = constants.DATE_EE_FORMAT;
    ctrl.localStoragePrefix = 'receipts';
    $scope.invoices = [];
    $scope.searchParams = {};
    $scope.unConfirmedInvoices = 0;
    $scope.invoicesTotalCount = 0;
    $scope.invoicesLoading = true;
    $scope.quickConfirmAllowed = true;
    $scope.allConfirmed = false; // has clicked confirm but actual approval hasn't happened
    $scope.allApproved = false; // actual approval has happened
    $scope.confirmTime = 5000; // time when invoice confirm undo is possible
    ctrl.filterObject = {
        pagingOptions: {
            page: 1,
            count: 15,
            total: 0
        },
        statusFilter: [{Name: 'New', Value: 0}],
        timeType: 'forever',
        invoiceType: 1, // get only receipts
        customRestrictions: [],
        sortItems: [{
            SortColumn: "InvoiceDate",
            SortDirection: "desc"
        }]
    }; // filterObject that ensures that we get correct invoices in the new invoices table. Because we have three tables in the same view that use the filter service, then we need to override all the params other tables might set

    ctrl.getUriFilterObject = encodeUriFilterObject(invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 0, 0, ctrl.localStoragePrefix));
    // event and variable watchers
    var authorizationDataLoadedEvent = $rootScope.$on("authorizationDataLoaded", function () {
        loadInvoices();
    });

    /*
        Save filter before navigation to invoice detail view
     */
    $scope.openInvoice = function(invoice, e) {
        e.preventDefault();
        invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 0, 0, ctrl.localStoragePrefix);
        $location.path("/invoiceconfirmation/" + invoice.Id);
    };

    /*
        Pagination page change
     */
    ctrl.pageChanged = function(value) {
        ctrl.filterObject.pagingOptions.page = value;
        ctrl.filterObject = invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, ctrl.localStoragePrefix);
        loadInvoices();
    };

    /*
        Load and init invoices
     */
    function loadInvoices() {
        if ($rootScope.userData) {
            $scope.invoicesLoading = true;
            invoiceRegisterService.doSearchWithQueue(ctrl.filterObject, 0, 1, 0, 0, ctrl.localStoragePrefix).then(function(data) {
                $scope.invoicesLoading = false;
                if(data.result) {
                    for (var i = 0; i < data.result.length; i++) {
                        data.result[i].currentStatus = invoiceService.setInvoiceStatus(data.result[i]);
                    }

                    $scope.invoices = data.result;
                    $scope.unConfirmedInvoices = $scope.invoices.length;
                }

                // update the filterObject
                if(data.filterObject) {
                    ctrl.filterObject = data.filterObject;
                    $scope.invoicesTotalCount = ctrl.filterObject.pagingOptions.total;
                }
            }); // request for invoices that match current filter
        }
    }

    /*
        ============= ANGULAR FUNCTIONS ================
     */

    /*
        On component init
     */
    ctrl.$onInit = function() {
        loadInvoices();
    };

    /*
        On component destroy
     */
    ctrl.$onDestroy = function() {
        authorizationDataLoadedEvent();
    };
}
