import { AxiosInstance, AxiosResponse } from 'axios';
import { BaseSearch, PagedListContainer, PaymentMethodDTO } from '../types/ApiTypes';

export class PaymentMethodsService {
    constructor(private axios: AxiosInstance) {}

    addPaymentMethod = async (pm: PaymentMethodDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post('/PaymentMethod/Add', pm);
    };

    getPaymentMethodsList = async (searchParams: BaseSearch, isIncludeInUseCount?: boolean): Promise<AxiosResponse<PagedListContainer<PaymentMethodDTO>>> => {
        return this.axios.post(`/PaymentMethod/GetList?isIncludeInUseCount=${isIncludeInUseCount}`, searchParams);
    };

    updatePaymentMethod = async (pm: PaymentMethodDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post('/PaymentMethod/Update', pm);
    };

    deletePaymentMethod = async (pm: PaymentMethodDTO): Promise<AxiosResponse<PagedListContainer<PaymentMethodDTO>>> => {
        return this.axios.post('/PaymentMethod/Delete', pm);
    };

    getPaymentMethodByCode = async (code: string, type?: string, isIncludeInUseCount?: boolean): Promise<AxiosResponse<PaymentMethodDTO>> => {
        return this.axios.get('/PaymentMethod/GetPaymentMethodByCode', { params: { code, type, isIncludeInUseCount } });
    };

    getPaymentMethodByTypeId = async (typeId: number, isIncludeInUseCount?: boolean): Promise<AxiosResponse<PaymentMethodDTO>> => {
        return this.axios.get('/PaymentMethod/GetPaymentMethodByCode', { params: { typeId, isIncludeInUseCount } });
    };
}

export default PaymentMethodsService;
