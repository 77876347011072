import { BackOfficeUserRole } from '../../services/types/BoApiTypes';

export const LISTS_SEARCH_DEBOUNCE_TIME = 1000;

export const MIN_SYMBOLS_IN_PASSWORD = 12;

export const MAX_SYMBOLS_IN_PASSWORD = 256;

const constants: any = {};

// change version on deploy
constants.CurrentVersion = process.env.REACT_APP_VERSION;
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
constants.PublicUrl = window.publicUrl;

// Constants
constants.DATE_EE_FORMAT_FULL = 'dd.MM.yyyy HH:mm:ss';
constants.DATE_EE_FORMAT = 'dd.MM.yyyy';
constants.DATE_EE_FORMAT_TIME_ONLY = 'HH:mm';
constants.DEFAULT_PAGE_SIZES = [10, 15, 30, 50];
constants.DEFAULT_PAGINATOR = {
    page: 1,
    count: 15,
    total: 0,
};
constants.SAPUser = 'ERPREJECTUSER';

// TASK STATUS
constants.TaskStatus = [
    { Value: 14, TranslateKey: 'component.transactionRows.confirmedAction' },
    { Value: 23, TranslateKey: 'component.transactionRows.assignedAction' },
    { Value: 24, TranslateKey: 'component.transactionRows.rejectedAction' },
    { Value: 27, TranslateKey: 'controller.invoiceConfirmationController.Approved' },
];

constants.getTaskStatusTranslateKey = function(statusCode: number) {
    switch (statusCode) {
        case 14:
            return 'component.transactionRows.confirmedAction';
        case 23:
            return 'component.transactionRows.assignedAction';
        case 24:
            return 'component.transactionRows.rejectedAction';
        case 25:
            return 'component.transactionRows.reOpenAction';
        case 26:
            return 'component.transactionRows.skipped';
        case 27:
            return 'controller.invoiceConfirmationController.Approved';
        default:
            return 'controller.invoiceConfirmationController.Status_unknown';
    }
};

export enum ConfirmationFlowStatusLookupId {
    ConfirmedAction = 14,
    AssignedAction = 23,
    RejectedAction = 24,
    ReOpenAction = 25,
    Skipped = 26,
    ExternalApprovement = 27,
    NoActionTaken = 28,
}

// UNITS
constants.Units = [{ Value: 26, TranslateKey: 'constants.Piece' }];

/*
    0 - NEW
    1 - IN PROGRESS
    2 - ASSIGNED
    3 - PENDING EXPORT
    4 - EXPORTED
    5 - DELETED
    6 - ARCHIVED
    7 - NOT FOR EXPORT
    Approved - APPROVED
 */

export enum InvoiceStatus {
    New = 0,
    InProgress = 1,
    InApproval = 2,
    PendingExport = 3,
    Exported = 4,
    Deleted = 5,
    Archived = 6,
    NotForExport = 7,
    Any = 8,
    Rejected = 9,
}

constants.getInvoiceStatusTranslateKey = function(statusCode: number | string) {
    switch (statusCode) {
        case 0:
            return 'controller.invoiceListController.NEW';
        case 1:
            return 'controller.invoiceListController.IN_PROGRESS';
        case 2:
            return 'controller.invoiceListController.ASSINGED';
        case 3:
            return 'controller.invoiceListController.PendingExport';
        case 4:
            return 'controller.invoiceListController.EXPORTED';
        case 5:
            return 'controller.invoiceListController.DELETED';
        case 6:
            return 'controller.invoiceListController.Archived';
        case 7:
            return 'controller.invoiceListController.NotForExport';
        case 8:
            return 'controller.invoiceListController.Any';
        case 9:
            return 'controller.invoiceListController.Rejected';
        case 'Approved':
            return 'controller.invoiceListController.APPROVED';
        default:
            return 'controller.invoiceConfirmationController.Status_unknown';
    }
};

// USER ROLES
export enum UserRole {
    Administrator = 0,
    ArchiveViewer = 9,
    Assigner = 2,
    Auditor = 5,
    CompletingWorkflow = 3,
    ErpUser = 7,
    ExportManager = 8,
    InvoiceRegisterAdministrator = 6,
    Processor = 1, // Approver in UI
    PurchaseOrdersAdministrator = 11,
    PurchaseOrdersCreator = 10,
    Accountant = 12,
    ExpenseCreator = 13,
    SystemAdmin = 101,
}

/**
 * TransactionRows form initialization events for correctly perceiving form initialization finish
 */
export enum FormInitEvent {
    transactionRows = 'transactionRows',
    vatCode = 'vatCode',
    customFields = 'customFields',
}

export const mapUserRoles = {
    [UserRole.Accountant]: 'General.Role.Accountant',
    [UserRole.Administrator]: 'General.Role.Admin',
    [UserRole.ArchiveViewer]: 'General.Role.ArchiveViewer',
    [UserRole.Assigner]: 'General.Role.ProcessOrganizer',
    [UserRole.Auditor]: 'General.Role.Auditor',
    [UserRole.CompletingWorkflow]: 'General.Role.ProcessEnder',
    [UserRole.ErpUser]: 'General.Role.ErpUser',
    [UserRole.ExpenseCreator]: 'General.Role.ExpenseCreator',
    [UserRole.ExportManager]: 'General.Role.ExportManager',
    [UserRole.InvoiceRegisterAdministrator]: 'General.Role.InvoiceRegisterUser',
    [UserRole.Processor]: 'General.Role.Processor',
    [UserRole.PurchaseOrdersAdministrator]: 'General.Role.PurchaseOrdersAdministrator',
    [UserRole.PurchaseOrdersCreator]: 'General.Role.PurchaseOrdersCreator',
    [UserRole.SystemAdmin]: 'General.Role.SystemAdmin',
};

export const getUserRoleLabel = (role: UserRole) => mapUserRoles[role];

export const EXPENSE_TYPE = 'Expense (Mobile App)';

constants.UserRole = UserRole;
constants.FormInitEvent = FormInitEvent;

constants.FileUrl = '../../../../WebApi/api/File/DisplayFileById/';
constants.DownloadUrl = '../../../WebApi/api/File/DownloadFile/';

constants.backOfficeUserAuthorizationRoles = [
    BackOfficeUserRole.GOD_MODE,
    BackOfficeUserRole.SUPER_ADMIN,
    BackOfficeUserRole.PRODUCT_MANAGER,
    BackOfficeUserRole.RE_SELLER,
    BackOfficeUserRole.TECHNICAL_SUPPORT,
];

constants.manageAssistantsAccessRoles = [BackOfficeUserRole.GOD_MODE, BackOfficeUserRole.SUPER_ADMIN, BackOfficeUserRole.PRODUCT_MANAGER, BackOfficeUserRole.TECHNICAL_SUPPORT];

export const DEFAULT_CURRENCY_SELECTION = { text: 'EUR', value: 'EUR' };

export const DEFAULT_CURRENCY_PLACEHOLDER = '-';

export default constants;
