import { useEffect, useState } from 'react';

export function useTimer(start: boolean, seconds: number) {
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (start) {
            setCount(seconds);
            const secondsLeft = setInterval(() => {
                setCount((c) => c - 1);
            }, 1000);
            return () => {
                clearInterval(secondsLeft);
            };
        }
        return undefined;
    }, [start]);

    return count;
}
