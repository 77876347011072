import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { GlobalState } from '../../rootReducer';
import { showUserProfile } from '../UserProfile/UserProfileActions';
import { DispatchProps, EmailVerification, Props } from './EmailVerification';
import { setEmailVerificationPendingSetting } from './EmailVerificationActions';

const mapStateToProps = (state: GlobalState): Pick<Props, 'boUser'> => {
    return {
        boUser: state.boUser.boCurrentUserLoadable,
    };
};

const mapDispatchToProps: DispatchProps = {
    showUserProfile,
    setEmailVerificationPendingSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailVerification));
