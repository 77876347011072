import React, { useEffect, useState, ChangeEvent } from 'react';
import { WithTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash-es';
import { Dispatchable2, Dispatchable1 } from 'redux-dispatchers';
import { saveAs } from 'file-saver';
import { eventTrack } from '../../../../common/analytics/gtm';
import classnames from 'classnames';

import api from '../../../../services/ApiServices';
import { LoadableData } from '../../../../common/utils/LoadableData';
import { isAuthorized, Role } from '../../../../common/user/userPermissionUtil';
import { FileDTO, InvoiceDTO, InvoiceDataResponseDTO, AttachmentType, AttachmentFilesCountResponseDTO, SystemConfigurationDTO } from '../../../../services/types/ApiTypes';
import constants from '../../../../common/constants/appConstants';
import Icon, { IconSize, ICONS } from '../../../../components/Icon/Icon';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import DeleteFileModalContent from './DeleteFileModalContent';
import { getBase64String } from './utils';
import AddFileModal, { UploadFileParams } from './AddFileModal';
import { ATTACHMENTS_MAX_COUNT, ATTACHMENTS_MAX_SIZE_TOTAL } from '../../../../common/utils/attachmentsHelper';
import { CompanySettingStatus } from '../../../settings/company-settings/components/Settings/CompanySettingsListHelper';
import { HubType } from '../../../../common/constants/invoiceConstants';

import './InvoiceRelatedDocuments.scss';

export interface RelatedDocsDispatchProps {
    setExternalLinkToOpen: Dispatchable1<string>;
    setPdfToOpen: Dispatchable1<FileDTO | null>;
    getInvoiceData: Dispatchable1<string>;
    deleteInvoiceFile: Dispatchable2<FileDTO, string>;
    uploadInvoiceFile: Dispatchable2<FileDTO, string>;
}

export interface Props {
    invoiceDataLoadable: LoadableData<InvoiceDataResponseDTO, string>;
    currentCompanySettings: SystemConfigurationDTO[];
    // TODO: remove when the whole page is in React
    invoice: InvoiceDTO;
    invoiceId: number;
    isReadOnly: boolean;
    isNewInvoice?: boolean;
    $rootScope: any;
}

export type RelatedDocsProps = WithTranslation & Props & RelatedDocsDispatchProps;

const RelatedDocuments = (props: RelatedDocsProps) => {
    const [invoiceLoading, setIsInvoiceLoading] = useState<boolean>(true);
    const [invoice, setInvoice] = useState<InvoiceDTO>({} as InvoiceDTO);
    const [visible, setIsVisible] = useState<boolean>(true);
    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
    const [attachmentsCount, setAttachmentsCount] = useState<AttachmentFilesCountResponseDTO>(null);
    const [documentsSizes, setDocumentsSizes] = useState<number[]>([]);

    const getAttachmentsCount = (invoiceId: number) => {
        api.invoice.getAllAttachmentFilesSize(AttachmentType.Invoice, invoiceId).then((response) => {
            if (response.data) {
                setAttachmentsCount(response.data);
            }
        });
    };

    const removeFile = (file: FileDTO) => {
        const documents = cloneDeep(invoice.DocumentFiles.filter((el) => el !== file));
        props.$rootScope.$emit('invoice.newRelatedDocument', documents);
        if (invoice.IsNew) {
            const documentsSize = cloneDeep(documentsSizes);
            documentsSize.splice(
                invoice.DocumentFiles.findIndex((e) => file.Id === e.Id),
                1,
            );
            setDocumentsSizes(documentsSize);
            setInvoice({ ...invoice, DocumentFiles: documents });
        } else {
            setIsInvoiceLoading(true);
            props.deleteInvoiceFile(file, invoice.Id.toString());
        }
    };

    const initialFileObject: FileDTO = {
        IsImported: false,
        IsOriginal: false,
        IsPdf: false,
        IsNew: true,
        WorkflowDocumentId: null,
        DownloadUrl: null,
        FileUrl: null,
        ForceImport: null,
        PathToFile: null,
        Id: null,
        FileName: null,
        InvoiceId: null,
        Base64Content: null,
        HubType: null,
    };

    let activeInvoiceLoadingEvent: any, activeInvoiceLoadedEvent: any, invoiceLoadingEvent: any, invoiceLoadedEvent: any;

    useEffect(() => {
        setIsInvoiceLoading(false);

        if (props.$rootScope.relatedDocumentsLoading) {
            setIsInvoiceLoading(true);
        }
        if (props.$rootScope.relatedDocumentsLoaded) {
            setIsInvoiceLoading(false);
        }

        // TODO: remove when the whole page is in React
        activeInvoiceLoadingEvent = props.$rootScope.$on('activeInvoiceLoading', function() {
            setIsInvoiceLoading(true);
        });
        activeInvoiceLoadedEvent = props.$rootScope.$on('activeInvoiceLoaded', function() {
            setIsInvoiceLoading(false);
        });
        invoiceLoadingEvent = props.$rootScope.$on('invoiceLoading', function() {
            setIsInvoiceLoading(true);
        });
        invoiceLoadedEvent = props.$rootScope.$on('invoiceLoaded', function() {
            setIsInvoiceLoading(false);
        });
    }, [props.$rootScope]);

    const cleanup = () => {
        // TODO: remove when the whole page is in React
        typeof activeInvoiceLoadingEvent === 'function' && activeInvoiceLoadingEvent();
        typeof activeInvoiceLoadedEvent === 'function' && activeInvoiceLoadedEvent();
        typeof invoiceLoadingEvent === 'function' && invoiceLoadingEvent();
        typeof invoiceLoadedEvent === 'function' && invoiceLoadedEvent();
    };

    useEffect(() => {
        return cleanup;
    }, []);

    useEffect(() => {
        const { invoiceDataLoadable } = props;
        // TODO: This functionality loads invoice if not loaded before. Check for its necessity when all Invoice Dtails are in React
        // Ideally getting invoice data should be triggered in higher component after all invoice details are in React
        // if (!invoiceDataLoadable?.loading && (!invoiceDataLoadable?.payload?.Invoice || invoiceDataLoadable.payload?.Invoice?.Id.toString() !== invoiceId.toString())) {
        //     console.log('%c InvoiceRelatedDocs getInvoiceData', 'color:pink');
        //     getInvoiceData(invoiceId);
        // } else
        if (invoiceDataLoadable?.loaded && invoiceDataLoadable?.payload?.Invoice) {
            setInvoice(cloneDeep(invoiceDataLoadable.payload.Invoice));
            setIsInvoiceLoading(false);
            !invoiceDataLoadable.payload.Invoice.IsNew && invoiceDataLoadable.payload.Invoice.Id === props.invoiceId && getAttachmentsCount(invoiceDataLoadable.payload.Invoice.Id);
        }
    }, [props.invoiceDataLoadable, props.invoiceId]);

    useEffect(() => {
        attachmentsCount && !props.isNewInvoice && getAttachmentsCount(props.invoiceId);
    }, [props.invoiceId]);

    useEffect(() => {
        if (props.invoice) {
            // when creating invoice copy isNewInvoice = true and DocumentFiles must be empty
            setInvoice(cloneDeep(props.isNewInvoice ? { ...props.invoice, IsNew: true, DocumentFiles: [] } : props.invoice));
            setIsInvoiceLoading(false);
            !props.isNewInvoice && !attachmentsCount && getAttachmentsCount(props.invoice.Id);
        }
    }, [props.invoice, props.isNewInvoice]);

    const toggleComponent = () => {
        setIsVisible(!visible);
    };

    const addDocument = (document: FileDTO) => {
        const documents = invoice.DocumentFiles ? cloneDeep(invoice.DocumentFiles) : [];
        document.Id = documents.length + 1;
        documents.push(document);
        setInvoice({ ...invoice, DocumentFiles: documents });
        props.$rootScope.$emit('invoice.newRelatedDocument', documents);
    };

    const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    const openFile = (file: any, download?: boolean, withFrontPage?: boolean) => {
        if (download || (!file.IsPdf && !file.FileUrl && !(file.currentFileUrl && file.Content === ''))) {
            if (invoice?.IsNew) {
                const blob = new Blob([b64toBlob(file.Base64Content)], { type: 'application/octet-stream' });
                saveAs(blob, file.FileName);
            } else {
                // hack until popup with language selection is not ready
                let language = props.i18n.language;
                if (!language) {
                    language = 'et_EE';
                }
                // end of hack
                const fromArchive = location.href.indexOf('archivedetails') > -1;
                setIsInvoiceLoading(true);
                api.invoice.downloadFileById(file.Id, fromArchive, withFrontPage || false, language).then((response: any) => {
                    const blob = new Blob([response.data], { type: 'application/octet-stream' });
                    saveAs(blob, file.FileName);
                    setIsInvoiceLoading(false);
                });
            }
            return;
        }

        props.$rootScope.isPDFVisible = false;
        if (!file.FileUrl) {
            setTimeout(function() {
                props.$rootScope.$emit('file.open', file);
                props.setPdfToOpen(file);
                props.$rootScope.$emit('application.collapseMenu');
            });
        } else if (file.IsPdf) {
            file.FileUrl = constants.FileUrl.concat(file.Id);
            file.DownloadUrl = constants.FileUrl.concat(file.Id);
            setTimeout(function() {
                props.$rootScope.$emit('file.open', file, invoice);
            });

            props.setPdfToOpen(file);
        } else {
            if (!file.FileUrl.match(/^https?:\/\//i)) {
                file.FileUrl = 'http://' + file.FileUrl;
            }
            props.setExternalLinkToOpen(file.FileUrl);
        }
    };

    const uploadFile = ({ file, isALink }: UploadFileParams) => {
        const doUpload = (f: FileDTO) => {
            props.$rootScope.$emit('invoice.newRelatedDocument', [...invoice.DocumentFiles, f]);
            props.uploadInvoiceFile(f, props.invoice?.Id?.toString());
            setIsInvoiceLoading(true);
        };

        if (isALink) {
            doUpload(file as FileDTO);
            return;
        }

        const receivedFile = file as File;
        const fileImagerReader = new FileReader();
        fileImagerReader.readAsArrayBuffer(receivedFile);
        if (props.isNewInvoice) {
            setDocumentsSizes([...documentsSizes, receivedFile.size]);
        }
        fileImagerReader.onload = function() {
            const newFile: FileDTO = {
                ...initialFileObject,
                FileName: receivedFile.name,
                InvoiceId: props.invoice.Id,
                IsPdf: receivedFile.type === 'application/pdf',
                IsImported: false,
                IsOriginal: receivedFile.type === 'application/pdf' && invoice?.DocumentFiles?.filter((file) => file?.IsPdf)?.length < 1 ? true : false,
                Base64Content: getBase64String(fileImagerReader.result),
                HubType: HubType.Emr, // set HubType = Emr, as like it was done manually
            };

            if (invoice?.IsNew) {
                addDocument(newFile);
                openFile(newFile);
            } else {
                doUpload(newFile);
            }
        };
    };

    // Company setting useEffect placed here, because const openFile cannot be called before.
    useEffect(() => {
        const isPdfPreviewEnabled = props.currentCompanySettings?.find((s) => s.Name === 'ShowInvoicePdfPreviewAutomatically')?.Value === CompanySettingStatus.Enabled;
        if (isPdfPreviewEnabled) {
            const originalPdf = props.invoice?.DocumentFiles?.find((file) => file.IsPdf && file.IsOriginal);
            if (originalPdf) {
                openFile(originalPdf);
            }
        }
    }, [props.invoiceId]);

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0];
        uploadFile({ file });
        e.target.value = '';
    };

    const getIcon = (extension: string) => {
        extension = extension ? extension.toLowerCase() : '';
        if (['xls', 'xlr', 'xlsx', 'xlsb', 'csv', 'ods', 'fods', 'odf'].includes(extension)) {
            return ICONS.EXCEL;
        }
        if (['pdf'].includes(extension)) {
            return ICONS.PDF;
        }
        if (['xml'].includes(extension)) {
            return ICONS.XML;
        }
        if (['jpg', 'png', 'jpeg', 'tiff', 'bmp', 'gif', 'svg'].includes(extension)) {
            return ICONS.IMAGE;
        }
        if (['doc', 'docs', 'docx', 'odt', 'fodt', 'rtf', 'pages'].includes(extension)) {
            return ICONS.DOCUMENT;
        }
        if (['txt', 'tex', 'log', 'dat'].includes(extension)) {
            return ICONS.TXT;
        }
        if (['zip', 'rar', '7z'].includes(extension)) {
            return ICONS.ARCHIVE;
        }
        if (['msg', 'eml'].includes(extension)) {
            return ICONS.EMAIL;
        }
        if (['pps', 'ppt', 'pptx'].includes(extension)) {
            return ICONS.PRESENTATION;
        }
        return ICONS.FILE_BLANK;
    };

    const getExtensionClass = (extension: string) => {
        return extension ? extension.toLowerCase() : '';
    };

    const canAddInvoiceFiles = () => {
        if (props.isNewInvoice) {
            return (invoice?.DocumentFiles?.length || 0) < ATTACHMENTS_MAX_COUNT && documentsSizes.reduce((a, b) => a + b, 0) < ATTACHMENTS_MAX_SIZE_TOTAL && isAuthorized(Role.CanAddInvoiceFiles);
        }
        return attachmentsCount?.AttachmentsCount < ATTACHMENTS_MAX_COUNT && attachmentsCount?.TotalSize < ATTACHMENTS_MAX_SIZE_TOTAL && isAuthorized(Role.CanAddInvoiceFiles);
    };
    return (
        <>
            <div className={classnames('side-component__toggler', { collapsed: !visible })} onClick={toggleComponent}>
                <span>{props.t('component.relatedDocuments.heading')}</span>
                <svg className="icon icon__component-toggler">
                    <use xlinkHref="#icon-arrow_up" />
                </svg>
            </div>
            {visible && (
                <section className={classnames('side-component related-documents', { 'side-component--loading': invoiceLoading })}>
                    {!invoice?.DocumentFiles?.length && !invoice.IsNew && <p>{props.t('component.relatedDocuments.noDocuments')}</p>}
                    {!!invoice?.DocumentFiles?.length && (
                        <ul>
                            {invoice.DocumentFiles.map((file) => (
                                <li
                                    key={file.Id}
                                    onClick={() => {
                                        openFile(file);
                                        eventTrack({ event: 'preview', label: 'Preview related document' });
                                    }}
                                    className={classnames('file-list-item')}
                                >
                                    <span className="related-documents__type">
                                        <Icon name={getIcon(file.FileName.split('.').pop())} size={IconSize.LG} className={`related-docs__icon ${getExtensionClass(file.FileName.split('.').pop())}`} />
                                    </span>
                                    <span className="related-documents__name ">{file.FileName}</span>
                                    <div className="separator-div"></div>

                                    {file.IsPdf && (
                                        <Tooltip content={props.t('component.relatedDocuments.DownloadWithFrontPage')}>
                                            <button
                                                className="btn--transparent open-file-button"
                                                onClick={(e) => {
                                                    openFile(file, true, true);
                                                    e.stopPropagation();
                                                    eventTrack({ event: 'download', label: 'Download related documents with front pages' });
                                                }}
                                            >
                                                <svg href="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 252 252" width="30px" height="30px">
                                                    <g
                                                        fill="none"
                                                        fillRule="nonzero"
                                                        stroke="none"
                                                        strokeWidth="1"
                                                        strokeLinecap="butt"
                                                        strokeLinejoin="miter"
                                                        strokeMiterlimit="10"
                                                        strokeDasharray=""
                                                        strokeDashoffset="0"
                                                        fontFamily="none"
                                                        fontWeight="none"
                                                        fontSize="none"
                                                        textAnchor="none"
                                                        style={{ mixBlendMode: 'normal' }}
                                                    >
                                                        <path d="M0,252v-252h252v252z" fill="none" />
                                                        <g id="original-icon" fill="#adb5bd" opacity="0" visibility="hidden">
                                                            <g id="surface1">
                                                                <path d="M110.25,15.75v131.16797l-35.92969,-34.45312l-10.82812,11.32031l54.63281,52.47949l54.63281,-52.47949l-10.82812,-11.32031l-35.92969,34.45313v-131.16797zM15.75,157.5v39.375c0,12.98145 10.64355,23.625 23.625,23.625h157.5c12.98145,0 23.625,-10.64355 23.625,-23.625v-39.375h-15.75v39.375c0,4.42969 -3.44531,7.875 -7.875,7.875h-157.5c-4.42969,0 -7.875,-3.44531 -7.875,-7.875v-39.375z" />
                                                            </g>
                                                        </g>
                                                        <g id="subtracted-icon" fill="#adb5bd">
                                                            <g id="surface1 1">
                                                                <path d="M126,15.75v131.16797l35.92969,-34.45312l10.82813,11.32031l-54.63281,52.47949l-54.63281,-52.47949l10.82813,-11.32031l35.92969,34.45313v-131.16797zM31.5,157.5v39.375c0,4.42969 3.44531,7.875 7.875,7.875l86.91108,0l0,15.75h-86.91108c-12.98145,0 -23.625,-10.64355 -23.625,-23.625v-39.375z" />
                                                            </g>
                                                        </g>
                                                        <g fill="#adb5bd">
                                                            <g id="Layer_1">
                                                                <path d="M252.28608,192.40284h-47.25v-47.25h-15.75v47.25h-47.25v15.75h47.25v47.25h15.75v-47.25h47.25z" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </button>
                                        </Tooltip>
                                    )}
                                    {file.IsPdf && (
                                        <Tooltip content={props.t('component.relatedDocuments.OpenNewWindow')}>
                                            <button
                                                className="btn--transparent open-file-button"
                                                onClick={(e) => {
                                                    openFile(file, true, false);
                                                    e.stopPropagation();
                                                    eventTrack({ event: 'download', label: 'Download related document' });
                                                }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="button-icon" id="download">
                                                    <path d="M15 4v16.563L9.72 15.28l-1.44 1.44 7 7 .72.686.72-.687 7-7-1.44-1.44L17 20.564V4h-2zM7 27v2h18v-2H7z" />
                                                </svg>
                                            </button>
                                        </Tooltip>
                                    )}
                                    {isAuthorized(Role.CanUpdateInvoiceFiles) && invoice.Status !== 4 && invoice.Status !== 5 && !props.isReadOnly && (
                                        <Tooltip interactive={true} content={<DeleteFileModalContent file={file} removeFile={removeFile} />} trigger="click" delay={0}>
                                            <button
                                                className={classnames('btn--transparent related-documents__remove open-file-button open-file-button--remove', { hidden: file.IsOriginal })}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <svg className="icon button-icon">
                                                    <use xlinkHref="#icon-close" />
                                                </svg>
                                            </button>
                                        </Tooltip>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                    {/* only deleted (5) - EMR-5780 */}
                    {invoice.Status !== 5 && canAddInvoiceFiles() && !props.isReadOnly && (
                        <p>
                            {invoice?.IsNew ? (
                                <label data-flow-btn="" className="btn btn--secondary btn--wide btn--md">
                                    {props.t('component.relatedDocuments.openOriginalDocument')}
                                    <input type="file" onChange={onFileChange} style={{ visibility: 'hidden', position: 'absolute' }} accept="application/pdf" />
                                </label>
                            ) : (
                                <button className="btn btn--secondary btn--sm" data-id="invoiceConfirmation.addNewDocument" onClick={() => setIsAddModalOpen(true)}>
                                    <span>{props.t('component.relatedDocuments.addNewDocument')}</span>
                                </button>
                            )}
                        </p>
                    )}
                </section>
            )}
            <AddFileModal attachmentsCount={attachmentsCount} invoice={invoice} isOpen={isAddModalOpen} uploadFile={uploadFile} setIsLoading={setIsInvoiceLoading} setIsOpen={setIsAddModalOpen} />
        </>
    );
};

export default RelatedDocuments;
