import { LOCATION_CHANGE } from 'connected-react-router';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { BaseSearch, PagedListContainer, RelatedDimensionsDTO, SortDirection } from '../../../services/types/ApiTypes';

import { loadRelatedDimensionsLoadableActions } from './RelatedDimensionsViewActions';

class State {
    relatedDimensionsLoadable = new LoadableData<PagedListContainer<RelatedDimensionsDTO>, BaseSearch>();

    searchParams: BaseSearch = {
        Restrictions: [],
        SortItems: [
            {
                SortColumn: 'SourceCode',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 15,
        },
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        loadRelatedDimensionsLoadableActions.request,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload,
                relatedDimensionsLoadable: state.relatedDimensionsLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        loadRelatedDimensionsLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                relatedDimensionsLoadable: state.relatedDimensionsLoadable.withPayloadIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        loadRelatedDimensionsLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                relatedDimensionsLoadable: state.relatedDimensionsLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state): State => {
            //const searchRestriction = cloneDeep(state.searchParams.Restrictions[0]);
            //searchRestriction.Value = '';
            return {
                ...new State(),
                searchParams: {
                    ...state.searchParams,
                    Restrictions: [],
                },
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'automation/related-dimensions',
    whitelist: ['relatedDimensionsSearchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as RelatedDimensionsViewState };

export const selectRelatedDimensionsLoadable = (state: GlobalState) => state.relatedDimensions.relatedDimensionsLoadable;
export const selectRelatedDimensionsSearchParams = (state: GlobalState) => state.relatedDimensions.searchParams;
