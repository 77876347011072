import api from '../../../../services/ApiServices';

export enum SUPPORTED_COUNTRY_IDS {
    EE = 'EE',
    LV = 'LV',
    LT = 'LT',
}

export const getPersonalCodeTooltip = (country: string, lastValidated: string) => {
    if (country === 'N/A') {
        return 'views.groupMember.detail.personalCode.tooltip';
    } else if (lastValidated) {
        return 'views.global.UserProfileModal.lockedPersonalCode.tooltip';
    }
    return '';
};

export const validateEmailField = async (value: string, resolve: any, userGuid?: string) => {
    try {
        const result = await api.user.isEmailUnique(value, userGuid || '', false);
        resolve(result.data);
    } catch (error) {
        console.error(error);
        resolve(false);
    }
};

export const validateEmailFieldAllCompanies = async (value: string, country: string, personalCode: string, resolve: any, userGuid?: string) => {
    try {
        const isEmailUnique = await api.user.isEmailUnique(value, userGuid || '', true);
        if (isEmailUnique.data) {
            resolve(true);
        } else {
            const r = await api.user.getUserByEmailOnly(value);
            const otherPersonalCode = r.data.UserGuid !== userGuid && r.data.PersonalCode === personalCode && country === r.data.Country;
            resolve(otherPersonalCode);
        }
    } catch (error) {
        console.error(error);
        resolve(false);
    }
};

export const validatePersonalCodeField = async (value: string, country: string, resolve: any, userGuid?: string) => {
    try {
        const result = await api.user.isPersonalCodeUnique(value, userGuid || '', country, false);
        resolve(result.data);
    } catch (error) {
        console.error(error);
        resolve(false);
    }
};
