import React from 'react';
import { ICONS } from '../../Icon/Icon';
import { BaseComponent } from '../../BaseComponent';
import { MainMenuItem } from './MainMenuItem';
import MainMenuBody from './MainMenuBody';
import { MainMenuProps } from '../MainMenu';
import { paths } from '../../../common/router/routePaths';
import { BackOfficeUserRole } from '../../../services/types/BoApiTypes';

export const ResellerMainMenuBO: React.FC<MainMenuProps> = (props) => {
    return (
        <MainMenuBody {...props}>
            <MainMenuItem href={`#${paths.backOffice.organizations}`} icon={ICONS.MENU_BO_ORGANIZATIONS} dataId="side-menu-link-organizations">
                {props.t('view.backOffice.organizations')}
            </MainMenuItem>
            <MainMenuItem href={`#${paths.backOffice.companies}`} icon={ICONS.MENU_BO_COMPANIES} dataId="side-menu-link-companies">
                {props.t('view.backOffice.companies')}
            </MainMenuItem>
            <MainMenuItem href={`#${paths.backOffice.users}`} icon={ICONS.MENU_BO_USER} dataId="side-menu-link-bo-user">
                {props.t('view.backOffice.allUsers')}
            </MainMenuItem>
        </MainMenuBody>
    );
};

export const DefaultBOMainMenuItems: React.FC<MainMenuProps> = (props) => {
    return (
        <MainMenuBody {...props}>
            <MainMenuItem href={`#${paths.backOffice.boUsers}`} icon={ICONS.MENU_BO_USER} dataId="side-menu-link-bo-BOuser">
                {props.t('view.backOffice.users')}
            </MainMenuItem>
            <MainMenuItem href={`#${paths.backOffice.domains}`} icon={ICONS.MENU_BO_DOMAINS} dataId="side-menu-link-bo-BOdomains">
                {props.t('view.backOffice.domains')}
            </MainMenuItem>
            <MainMenuItem href={`#${paths.backOffice.integrators}`} icon={ICONS.MENU_BO_INTEGRATORS} dataId="side-menu-link-bo-integrators">
                {props.t('view.backOffice.integrators')}
            </MainMenuItem>
            <MainMenuItem href={`#${paths.backOffice.resellers}`} icon={ICONS.MENU_BO_RESELLER} dataId="side-menu-link-bo-re-sellers">
                {props.t('view.backOffice.resellers')}
            </MainMenuItem>
            <MainMenuItem href={`#${paths.backOffice.organizations}`} icon={ICONS.MENU_BO_ORGANIZATIONS} dataId="side-menu-link-organizations">
                {props.t('view.backOffice.organizations')}
            </MainMenuItem>
            <MainMenuItem href={`#${paths.backOffice.companies}`} icon={ICONS.MENU_BO_COMPANIES} dataId="side-menu-link-companies">
                {props.t('view.backOffice.companies')}
            </MainMenuItem>
            <MainMenuItem href={`#${paths.backOffice.users}`} icon={ICONS.MENU_BO_USER} dataId="side-menu-link-bo-user">
                {props.t('view.backOffice.allUsers')}
            </MainMenuItem>
            <MainMenuItem href={`#${paths.backOffice.reports}`} icon={ICONS.TEMPLATE_24} dataId="side-menu-link-bo-reports">
                {props.t('view.backOffice.reporting')}
            </MainMenuItem>
        </MainMenuBody>
    );
};

const menuComponentsMap: Map<BackOfficeUserRole, React.FC> = new Map([[BackOfficeUserRole.RE_SELLER, ResellerMainMenuBO]]);

export class MainMenuBO extends BaseComponent<MainMenuProps> {
    render() {
        const MainMenuBody: React.FC = menuComponentsMap.get(this.props.boCurrentUserLoadable?.payload?.Role) || DefaultBOMainMenuItems;
        return <MainMenuBody {...this.props} />;
    }
}
