(function() {
    'use strict';

    angular
        .module('dstreamApp.directives')
        .directive('autoFocus', autoFocus);

    autoFocus.$inject = [
        "$timeout"
    ];

    function autoFocus ($timeout) {
        return {
            restrict: "A",
            link: linkFunc
        };

        function doFocus (element) {
            $timeout(function () {
                element[0].focus();
            }, 0);
        }

        function linkFunc (scope, element, attrs) {
            if (attrs.hasOwnProperty("isNew")) {
                if (scope.$eval(attrs.isNew)) {
                    doFocus(element);
                }
            } else {
                doFocus(element);
            }
        }
    }
}());
