import { LOCATION_CHANGE } from 'connected-react-router';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { BaseSearch, PagedListContainer, SearchType, SortDirection, WorkflowTemplateDTO } from '../../../services/types/ApiTypes';
import { workflowsLoadableDataActions } from './WorkflowsListViewActions';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

class State {
    workflowsLoadable = new LoadableData<PagedListContainer<WorkflowTemplateDTO>, BaseSearch>();

    searchParams: BaseSearch = {
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: '',
                Values: undefined,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        SortItems: [
            {
                SortColumn: 'Name',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 15,
        },
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        workflowsLoadableDataActions.request,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload,
                workflowsLoadable: LoadableData.loading(action.payload),
            };
        },
    )
    .addReducer(
        workflowsLoadableDataActions.success,
        (state, action): State => {
            return {
                ...state,
                workflowsLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'workflowList',
    whitelist: ['searchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as WorkflowsListViewState };

export const selectWorkflowsLoadable = (state: GlobalState) => state.workflows.workflowsLoadable;
export const selectWorkflowsSearchParams = (state: GlobalState) => state.workflows.searchParams;
