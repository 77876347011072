(function () {
  "use strict";

  angular
    .module("blocks.auth")
    .factory("authenticationInterceptor", authenticationInterceptor);

  authenticationInterceptor.$inject = [
    "$rootScope",
    "$timeout",
    "$q",
    "localStorageService",
    "$window",
    "$filter"
  ];

  /*
      Intercepts all requests and adds a auth token to the header
   */
  function authenticationInterceptor (
    $rootScope,
    $timeout,
    $q,
    localStorageService,
    $window,
    $filter
  ) {
    $rootScope.sessionTimer = {session: false, message: false};
    $rootScope.messageTime = 300000; // time before session timeout to display
    var authInterceptorServiceFactory = {
      request: request,
      responseError: responseError
    };

    /*
        add a auth token to request header
     */
    function request (config) {
      config.headers = config.headers || {};
      var authData = localStorageService.get("authorizationData");
      if (authData && !config.url.contains('/app/resources/locale')) {
        const date = new Date();
        config.headers.Authorization = "Bearer " + authData.token;
        config.headers["Authorization-Token"] = authData.token;
        config.headers['client-datetime'] = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('.')[0];
      }
      return config;
    }

    /*
        In case a response has a 401 status then redirect back to login
     */
    function responseError (rejection) {
      if (rejection.status === 401) {
        $window.location.href = ("../../login/");
      }
      return $q.reject(rejection);
    }

    return authInterceptorServiceFactory;
  }
})();