(function() {
    'use strict';

    angular
        .module('dstreamApp.directives')
        .directive('accessLevel', accessLevel);


    accessLevel.$inject = ['$rootScope', 'ngIfDirective', 'authenticationService'];

    function accessLevel($rootScope, ngIfDirective, authenticationService) {
        if (!ngIfDirective)
            return null;
        var ngIf = ngIfDirective[0];
        var directive = {
            transclude: ngIf.transclude,
            priority: ngIf.priority - 1,
            terminal: ngIf.terminal,
            restrict: ngIf.restrict,
            scope: {
                accessLevel: '@'
            },
            link: linkFunc
        };

        return directive;


        function linkFunc($scope, element, attrs) {
            var visibility = false;

            var watcher = $rootScope.$watch('userData', updateUser, true);

            function updateUser(user) {
                updateVisibility();
            }
            function updateVisibility() {
                visibility = authenticationService.isAuthorized($scope.accessLevel);
                attrs.ngIf = function() { return visibility; };
            }

            attrs.ngIf = function() { return visibility; };
            ngIf.link.apply(ngIf, arguments);

            $scope.$on('$destroy', function() {
                if(watcher) watcher();
            });
        }
    }
}());
