'use strict';

angular
    .module('dstreamApp.components')
    .controller('DsSessionReminderController', function($rootScope, webServices, $translate, $scope, $filter, $timeout, $uibModalInstance, authenticationService, authenticationInterceptor) {
        $scope.timeLeft = $rootScope.messageTime;
        $scope.timeUnit = chooseUnit();
        $scope.timer = false;
        
        $scope.signOut = function(e) {
            e.preventDefault();
            authenticationService.logOut();
        };

        $scope.extendTimer = function(e) {
            e.preventDefault();
            webServices.extendSession().then(function() {
                $timeout.cancel($scope.timer);
                $uibModalInstance.close();
                $uibModalInstance.open = false;

            }).catch(function () {
                authenticationService.logOut(true);
            });
        };

        $scope.ExpirationTime = null;
        function timerCycle() {
            if (authenticationService.getExpirationTime())
                $scope.ExpirationTime = new Date(authenticationService.getExpirationTime());

            
            $scope.timeLeft = $scope.ExpirationTime.getTime() - new Date().getTime();
            $scope.timeLeft = $scope.timeLeft - $scope.timeLeft % 1000;
            if ($scope.timeLeft < 0)
                $scope.timeLeft = 0;
            if ($scope.timeLeft > 300000) {
                $timeout.cancel($scope.timer);
                $uibModalInstance.close();
                $uibModalInstance.open = false;
                return;
            }
            $scope.timeUnit = chooseUnit();
            if (new Date().getTime() < $scope.ExpirationTime.getTime()) {
                $scope.timer = $timeout(timerCycle, 1000);
            } else {
                authenticationService.logOut(true);
            }
        }

        function chooseUnit() {
            if($scope.timeLeft > 60000) {
                if($scope.timeLeft == 60000) {
                    return 'component.sessionModal.minute';
                } else {
                    return 'component.sessionModal.minutes';
                }
            } else {
                if($scope.timeLeft == 1000) {
                    return 'component.sessionModal.second';
                } else {
                    return 'component.sessionModal.seconds';
                }
            }
        }

        $scope.timer = $timeout(timerCycle, 1000);
    });