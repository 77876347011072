import * as React from 'react';
import { Dispatchable2 } from 'redux-dispatchers';
import { useTranslation } from 'react-i18next';

import { eventTrack } from '../../common/analytics/gtm';
import { createDataId } from '../../common/utils/dataId';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { ContentBlockHeader } from '../../components/ContentBlock/ContentBlockHeader';
import { ICONS } from '../../components/Icon/Icon';
import { Button, ButtonType } from '../../components/Buttons/Button';
import { Typography } from '../../components/Typography';
import { GroupMemberCommonDTO, GroupMemberDTO, SubstituteDTO } from '../../services/types/ApiTypes';
import { LoadableData } from '../../common/utils/LoadableData';
import { formatDate } from '../../common/utils/formatters';
import constants from '../../common/constants/appConstants';
import OutOfOfficeModal from './components/OutOfOfficeModal';

import './OutOfOffice.scss';

export interface Props {
    groupMemberCommonLoadable: LoadableData<GroupMemberCommonDTO | GroupMemberDTO, void>;
    isUserDetails?: boolean;
    $rootScope?: any;
}

export interface DispatchProps {
    updateSubstitutes: Dispatchable2<Omit<SubstituteDTO, 'GroupMemberId' | 'GroupMemberName' | 'Id' | 'IsNew'>[], any>;
}

type OutOfOfficeProps = Props & DispatchProps;

const dataId = 'outofoffice';

const OutOfOffice: React.FC<OutOfOfficeProps> = ({ updateSubstitutes, groupMemberCommonLoadable, isUserDetails, $rootScope }) => {
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

    const { t } = useTranslation();

    const isSubstitutesExists = groupMemberCommonLoadable?.payload?.Substitutes?.length;

    const replacedTitleKey = isUserDetails
        ? t('views.home.dashboard.outOfOffice.userNameReplacedBy', {
              Username: groupMemberCommonLoadable?.payload?.Name,
          })
        : t('views.home.dashboard.outOfOffice.replacedBy');

    const handleSubstitutesUpdate = (substitutes: Omit<SubstituteDTO, 'GroupMemberId' | 'GroupMemberName' | 'Id' | 'IsNew'>[]) => {
        updateSubstitutes(substitutes, $rootScope);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleClickOpenModal = () => {
        eventTrack({
            event: 'dashboard_out_of_office',
            label: 'Set out of office',
        });
        setIsModalOpen(true);
    };

    const getFormatDates = (from: Date, to: Date): string => {
        return `${formatDate(from, constants.DATE_EE_FORMAT)} - ${formatDate(to, constants.DATE_EE_FORMAT)}`;
    };

    return (
        <>
            <ContentBlock className={'out-of-office side-component'} loading={groupMemberCommonLoadable?.loading}>
                <ContentBlockHeader>
                    <div className="out-of-office__title">
                        <Typography variant="h2" data-id={createDataId(dataId, 'title')}>
                            {t('views.home.dashboard.out_of_office')}
                        </Typography>
                        <div className="out-of-office__header-buttons">
                            <Button
                                onClick={handleClickOpenModal}
                                dataId={createDataId(dataId, 'button', 'editOutOfOffice')}
                                icon={isSubstitutesExists ? ICONS.EDIT_24 : ICONS.ADD_24}
                                className="icon-button"
                                buttonType={ButtonType.ICON}
                            />
                        </div>
                    </div>
                </ContentBlockHeader>
                <div data-id={createDataId(dataId, 'subtitle')} className="out-of-office__subtitle">
                    {isSubstitutesExists ? replacedTitleKey : t('views.home.dashboard.out_of_office_description')}
                </div>
                {groupMemberCommonLoadable?.payload?.Substitutes?.map((substitute, index) => {
                    return (
                        <div data-id={createDataId(dataId, index, 'item')} className="out-of-office__item" key={substitute.Id}>
                            <span>{substitute.ToSubstituteName}</span>
                            <span>{getFormatDates(substitute.From, substitute.To)}</span>
                        </div>
                    );
                })}
            </ContentBlock>
            <OutOfOfficeModal open={isModalOpen} substitutes={groupMemberCommonLoadable?.payload?.Substitutes} onClose={handleCloseModal} onUpdate={handleSubstitutesUpdate} />
        </>
    );
};

export default OutOfOffice;
