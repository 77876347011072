import classNames from 'classnames';
import * as React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import { addDataId, DataId } from '../../common/utils/dataId';
import { BaseComponent } from '../BaseComponent';
import Icon, { ICONS } from '../Icon/Icon';
import Link from '../Link/Link';
import OverflowTooltip from '../Tooltip/OverflowTooltip';

import './Table.scss';

export interface TableCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
    children: React.ReactNode;
    link?: boolean;
    linkTo?: string;
    nowrap?: boolean;
    hide?: boolean;
    showPlaceholder?: boolean;
    /** break long lines into multiple rows, disabled when hideOverflow true*/
    breakLine?: boolean;
    hideOverflow?: boolean;
    appendTo?: Element | ((ref: Element) => Element);
    dataId: DataId;
    /**
     * use when you need to open link in new tab, has priority over linkTo because of Link implementation
     */
    href?: string;
    isDragOccurring?: boolean;
    dragHandleProps?: DraggableProvidedDragHandleProps;
    contentClass?: string;
    responsiveCellType?: ResponsiveCellType;
    iconBeforeCell?: React.ReactNode;
    columnName?: string;
}

export enum ResponsiveCellType {
    LABEL = 'LABEL',
    ACTIONS = 'ACTIONS',
}

export interface TableCellSnapshot {
    width: number;
    height: number;
}

class TableCell extends BaseComponent<TableCellProps> {
    private ref = React.createRef<HTMLTableDataCellElement>();

    renderContent = () => {
        const { hideOverflow, children, iconBeforeCell } = this.props;
        return hideOverflow ? (
            <span className="data-table__cell-container">
                {iconBeforeCell || null}
                <OverflowTooltip appendTo={this.props.appendTo}>{children}</OverflowTooltip>
            </span>
        ) : iconBeforeCell ? (
            <>
                <span className="data-table__cell-container hide-overflow-icon-before">
                    {iconBeforeCell}
                    <span>{children}</span>
                </span>
            </>
        ) : (
            children
        );
    };

    renderLinkOrContent = () => {
        const { link, linkTo, dataId, href, contentClass, showPlaceholder } = this.props;
        const contentClasses = classNames('data-table__cell-overflow-wrapper', 'data-table__cell-content', contentClass);
        return showPlaceholder ? (
            <TableCellPlaceholder />
        ) : link ? (
            <Link href={href} target={href ? '_blank' : ''} to={linkTo} data-id={addDataId(dataId, '.link').toString()} className="data-table__cell-overflow-wrapper data-table__cell-content">
                {this.renderContent()}
            </Link>
        ) : (
            <div className={contentClasses}>{this.renderContent()}</div>
        );
    };

    getSnapshotBeforeUpdate(prevProps: TableCellProps): TableCellSnapshot {
        if (!this.ref || !this.ref.current) {
            return null;
        }

        const isDragStarting: boolean = this.props.isDragOccurring && !prevProps.isDragOccurring;

        if (!isDragStarting) {
            return null;
        }

        const { width, height } = this.ref.current.getBoundingClientRect();

        const snapshot: TableCellSnapshot = {
            width,
            height,
        };

        return snapshot;
    }

    componentDidUpdate(prevProps: TableCellProps, prevState: any, snapshot: TableCellSnapshot) {
        const ref: HTMLElement = this.ref.current;
        if (!ref) {
            return;
        }

        if (snapshot) {
            if (ref.style.width === snapshot.width.toString()) {
                return;
            }
            ref.style.width = `${snapshot.width}px`;
            ref.style.height = `${snapshot.height - 1}px`;
            return;
        }

        if (this.props.isDragOccurring) {
            return;
        }

        ref.style.height = null;

        // inline styles not applied
        if (ref.style.width == null) {
            return;
        }
    }

    render() {
        const {
            /* eslint-disable @typescript-eslint/no-unused-vars */
            className,
            children,
            hide,
            link,
            linkTo,
            nowrap,
            breakLine,
            hideOverflow,
            dataId,
            href,
            isDragOccurring,
            dragHandleProps,
            contentClass,
            responsiveCellType,
            showPlaceholder,
            iconBeforeCell,
            appendTo,
            columnName,
            /* eslint-enable @typescript-eslint/no-unused-vars */
            ...rest
        } = this.props;
        const classes = classNames('data-table__cell', className, {
            'data-table__cell--is-link': link,
            'data-table__cell--no-wrap': nowrap,
            'data-table__cell--break-line': breakLine && !hideOverflow,
            'data-table__cell--hide-overflow': hideOverflow,
        });
        if (hide) {
            return null;
        }
        return (
            <td className={classes} {...rest} ref={this.ref} data-id={dataId}>
                {dragHandleProps && (
                    <span className="data-table__drag-handle-wrapper">
                        <span className="data-table__drag-handle" {...dragHandleProps}>
                            <Icon className="data-table__drag-handle-icon" name={ICONS.DRAGGABLE} />
                        </span>
                        {this.renderLinkOrContent()}
                    </span>
                )}

                {!dragHandleProps && this.renderLinkOrContent()}
            </td>
        );
    }
}

const TableCellPlaceholder = () => (
    <div className="data-table__cell--placeholder">
        <span className="placeholder-value"></span>
    </div>
);

export default TableCell;
