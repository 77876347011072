import React from 'react';
import { TFunction } from 'i18next';

const SumsDifferentTooltip = ({ cancelSaving, confirmSaving, t }: { cancelSaving: () => void; confirmSaving: () => void; t: TFunction }) => {
    return (
        <div>
            <p>{t('views.invoice.partials.invoiceInformation.sumsDifferentRemoveTransactionRows')}</p>
            <p className="action-wrap action-wrap--right">
                <button className="btn btn--secondary btn--sm" onClick={cancelSaving} data-ng-click="$ctrl.createSumsAreDifferentPopover.cancel()">
                    {t('views.global.No')}
                </button>
                <button className="btn btn--primary btn--sm" onClick={confirmSaving} data-ng-click="$ctrl.createSumsAreDifferentPopover.confirm()">
                    {t('views.global.Yes')}
                </button>
            </p>
        </div>
    );
};

export default SumsDifferentTooltip;
