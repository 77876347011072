(function () {
    'use strict';

    angular
        .module('dstreamApp.views.archive-register')
        .component('dsArchiveRegisterFilter', {
            templateUrl: 'app/views/archive-register/components/archive-register-filter/archive-register-filter.html',
            controller: ArchiveRegisterFilter
        });

    ArchiveRegisterFilter.$inject = ['$rootScope', 'invoiceRegisterService', 'invoiceService', '$filter', 'companyDataService', 'webServices', '$timeout', '_'];

    function ArchiveRegisterFilter($rootScope, invoiceRegisterService, invoiceService, $filter, companyDataService, webServices, $timeout, _) {
        var ctrl = this;
        /* CONTROLLER VARIABLES */
        ctrl.localStoragePrefix = 'archive';
        ctrl.searchPlaceholder = $filter('translate')('component.invoiceFilter.startSearching') + '...';
        ctrl.filterObject = invoiceRegisterService.getFilterFromLocalStorage(ctrl.localStoragePrefix) || morphForArchive(invoiceRegisterService.createDefaultFilterObject(true)); // get intial filter object from service
        ctrl.visibility = {
            showFilterOverlay: false,
            usersFilterVisible: false,
            dimensionFilterVisible: false
        }; // visibility of filter component elements
        ctrl.filterRanges = {
            usersRange: [],
            accountRange: [],
            dimensionRange: [],
            objectRange: []
        }; // all possible filter values for filter autocompletes

        /* CONTROLLER METHODS */
        ctrl.showFilter = showFilter;
        ctrl.hideDimensionFilter = hideDimensionFilter;
        ctrl.dimensionsFiltersCount = dimensionsFiltersCount;
        ctrl.hideFilters = hideFilters;
        ctrl.doSearch = doSearch;
        ctrl.clearFilters = clearFilters;

        ctrl.$onInit = function () {
            // wait for user data before building the ranges
            companyDataService.getArchiveGroupMembers().then(function (res) {
                buildUsersRange();
            });
            buildAccountRange();
            buildDimensionAndObjectRange();
        };

        /* EVENTS & WATHCERS */
        var invoiceRegisterFilterClearedEvent = $rootScope.$on('invoiceRegisterFilterCleared', function (event, defaultFilterObject) {
            ctrl.filterObject = morphForArchive(invoiceRegisterService.createDefaultFilterObject(true));
        });
        var invoiceRegisterLoadedEvent = $rootScope.$on('invoiceRegisterLoaded', function (event, data) {
            // TODO: fix angular.copy for post-1.6.9
            ctrl.filterObject = angular.copy(data.filterObject); // get a copy so we wouldn't change the actual value in the service
        });

        /*
            Show defined filter
         */
        function showFilter($event, filterName) {
            ctrl.visibility.showFilterOverlay = true; //show backdrop
            // focus on input when the filter opens, but not for dimension filter, because it has different UI
            if (filterName !== 'dimension') {
                $timeout(function () {
                    angular.element($event.currentTarget).parent().find('.tags').click();
                }, 100);
            }

            // show predefined filter
            switch (filterName) {
                case 'users':
                    ctrl.visibility.usersFilterVisible = true;
                    break;
                case 'dimension':
                    ctrl.visibility.dimensionFilterVisible = true;
                    setTimeout(function () {
                        $(document).bind('click', ctrl.hideDimensionFilter);
                    });
                    break;
            }
        }

        /*
            Hide dimension filter
         */
        function hideDimensionFilter($event) {
            if (ctrl.visibility.dimensionFilterVisible && angular.element($event.target).closest('.hz-invoice-filter__container--plain').length === 0 &&
                !angular.element($event.target).hasClass('filter-submit')) {
                ctrl.visibility.showFilterOverlay = false;
                ctrl.visibility.dimensionFilterVisible = false;
                setTimeout(function () {
                    $(document).unbind('click', ctrl.hideDimensionFilter);
                });
            }
        }

        /*
            Add up accounts, dimensions and objects
         */
        function dimensionsFiltersCount() {
            return ctrl.filterObject.dimensionFilter.length + ctrl.filterObject.accountsFilter.length + ctrl.filterObject.objectsFilter.length;
        }

        /*
            Hide all filters
         */
        function hideFilters() {
            ctrl.visibility.showFilterOverlay = false;
            ctrl.visibility.statusFilterVisible = false;
            ctrl.visibility.usersFilterVisible = false;
            ctrl.visibility.dimensionFilterVisible = false;
            ctrl.visibility.moreFilterVisible = false;
            ctrl.visibility.templateFilterVisible = false;
            setTimeout(function () {
                $(document).unbind('click', ctrl.hideDimensionFilter);
            });
        }

        /*
            Apply all filters
         */
        function doSearch(filterObject) {
            ctrl.filterObject.pagingOptions.page = 1; // set page pack to beginning
            ctrl.filterObject.statusFilter = [{ Name: "Archived", Value: 6 }];
            if (filterObject) filterObject.pagingOptions.page = 1;
            invoiceRegisterService.doSearch(filterObject || ctrl.filterObject, false, true, null, true, false, ctrl.localStoragePrefix).then(function (result) {
                $rootScope.$emit('invoiceRegisterLoaded', {
                    result: result.result,
                    filterObject: result.filterObject
                });
            }); // we use the invoiceRegisterLoaded eventwatcher in this component
        }

        /*
            Clear all filters
         */
        function clearFilters() {
            invoiceRegisterService.clearFilters(true).then(function (result) {
                $rootScope.$emit('invoiceRegisterLoaded',
                    {
                        result: result.result,
                        filterObject: result.filterObject
                    });
            });
        }

        /* INTERNAL METHODS */
        function morphForArchive(filterObject) {
            filterObject.statusFilter = [{ Name: "Archived", Value: 6 }];
            filterObject.timeType = 'forever';
            filterObject.timeTypeLabel = 'component.invoiceFilter.customDates';
            return filterObject;
        }
        
        function buildUsersRange() {
            ctrl.filterRanges.usersRange.push({
                Name: '', Value: [
                        {
                            Value: $rootScope.userData.GroupMemberId,
                            TranslateKey: 'component.invoiceFilter.byMe',
                            Name: $filter('translate')('component.invoiceFilter.byMe')
                        }
                ]
            });

            ctrl.filterRanges.usersRange.push({
                Value: _.sortBy(getGroupMembers(), 'Name')
            });
        }

        function buildAccountRange() {
            webServices.getArchiveAccounts().then(function (response) {
                ctrl.filterRanges.accountRange.push({ Name: '', Value: [] });
                for (var i = 0; i < response.data.length; i++) {
                    var account = response.data[i];
                    if (account.Id || account.id === 0) {
                        ctrl.filterRanges.accountRange[0].Value.push({
                            Name: account.Code + " - " + account.Description,
                            Value: account.Id
                        });
                    }
                }
            });
        }

        function buildDimensionAndObjectRange() {
            webServices.getArchiveCustomCostObjectives().then(function (response) {
                const archiveCostObjectives = response.data.map(function(co) {
                    return {
                        ...co,
                        Name: `${co.Code} - ${co.Description}`,
                        Dimensions: co.Dimensions.map(function(dim) {
                            return {
                                ...dim,
                                Name: `${dim.Code} - ${dim.Description}`,
                            };
                        }),
                    };
                });

                const costObjectiveRangeValue = [];
                const dimensionRangeValue = [];
                for (const costObjective of archiveCostObjectives) {
                    const costObjectiveExists = costObjectiveRangeValue.find(function (f) { return f.Name === costObjective.Name });
                    if (!costObjectiveExists) {
                        costObjectiveRangeValue.push({
                            Name: costObjective.Name,
                            Value: costObjective.Id,
                            visible: true,
                            isTag: false,
                        });
                        for (const dimension of costObjective.Dimensions) {
                            const dimensionExists = dimensionRangeValue.find(function (f) { return f.Name === dimension.Name });
                            if (!dimensionExists) {
                                dimensionRangeValue.push({
                                    Name: dimension.Name,
                                    Value: dimension.Id,
                                    visible: true,
                                    isTag: false,
                                });
                            }
                        }
                    }
                }

                ctrl.filterRanges.dimensionRange = [{
                    Name: '',
                    Value: costObjectiveRangeValue,
                    visible: true,
                }];
                ctrl.filterRanges.objectRange = [{
                    Name: '',
                    Value: dimensionRangeValue,
                    visible: true,
                }];
            });
        }

        /*
            Get groupmembers for the confirmers filter
         */
        function getGroupMembers(namePart) {
            var temp = [];
            namePart = namePart ? namePart : '';
            if ($rootScope.archiveGroupMembers) {
                $rootScope.archiveGroupMembers.forEach(function (value) {
                    temp.push({
                        Value: value.Id,
                        Name: value.User.FullName
                    });
                });
            }
            return temp;
        }

        ctrl.$onDestroy = function () {
            if (invoiceRegisterFilterClearedEvent) invoiceRegisterFilterClearedEvent();
            if (invoiceRegisterLoadedEvent) invoiceRegisterLoadedEvent();
        };
    }
})();