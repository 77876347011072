import { connect } from 'react-redux';

import { GlobalState } from '../../../../../../rootReducer';
import withSuspense from '../../../../../../common/hocs/withSuspense';
import HistoryList, { DispatchProps, Props } from './HistoryList';
import { selectInvoiceHeaderHistoryListLoadable, selectInvoiceHeaderHistoryListSearchParams } from './HistoryListReducers';
import { getHistoryList, searchInvoiceHeaderHistoryList, setHistoryListCategorySetting, setInvoiceHeaderHistoryPagingOptions, sortInvoiceHeaderHistoryList } from './HistoryListActions';
import { getCurrenUserGroupMemberCommonLoadable } from '../../../../../../common/user/UserSelectors';

const mapStateToProps = (state: GlobalState): Pick<Props, 'invoiceHeaderHistoryList' | 'searchParams' | 'groupMember'> => ({
    invoiceHeaderHistoryList: selectInvoiceHeaderHistoryListLoadable(state),
    searchParams: selectInvoiceHeaderHistoryListSearchParams(state),
    groupMember: getCurrenUserGroupMemberCommonLoadable(state)?.payload,
});

const mapDispatchToProps: DispatchProps = {
    getHistoryList,
    searchInvoiceHeaderHistoryList,
    setInvoiceHeaderHistoryPagingOptions,
    sortInvoiceHeaderHistoryList,
    setHistoryListCategorySetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(HistoryList));
