const containerStyle = 'display: inline-block; position: absolute; visibility: hidden; zIndex: -1';

export const measureDomNode = (node: HTMLElement, enhance = (e: Node) => e) => {
    const container = document.createElement('div');
    container.style.cssText = containerStyle;

    const clonedNode = node.cloneNode(true);
    const content = enhance(clonedNode);

    container.appendChild(content);

    document.body.appendChild(container);

    const height = container.clientHeight;
    const width = container.clientWidth;

    container.parentNode.removeChild(container);
    return { height, width };
};

const enhanceMeasurableNode = (node: HTMLElement) => {
    /* The submenu has the height set to 0 when it's collapsed.
     * To allow the submenu's measurement, we need to set its
     * height to "auto"
     */
    node.style.height = 'auto';
    return node;
};

export const measureSubmenu = (node: HTMLElement) => {
    return measureDomNode(node, enhanceMeasurableNode);
};
