'use strict';
import * as React from 'react';

angular
    .module('dstreamApp.components')
    .controller('DsImportXlsController', function ($rootScope, $scope, $filter, $uibModalInstance, webServices, utils, notifyService, $uibModal, $analytics) {
        $scope.invoice = $scope.$parent.$ctrl.invoice;
        $scope.isOldXMLImport = $scope.$parent.isOldXMLImport;
        $scope.acceptFiles = $scope.isOldXMLImport ? "application/vnd.ms-excel" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        $scope.documentType = "invoice";
        $scope.link = {};
        $scope.contentLoading = false;
        $scope.ForceImport = false;
        $scope.closeModal = closeModal;

        $scope.fileImageAdded = function ($file, $event) {
            $scope.ForceImport = false;
            $scope.file = $file;
        };

        $scope.uploadFile = function () {
            $scope.contentLoading = true;
            fileImagerReader.readAsArrayBuffer($scope.file.file);
        };

        $scope.saveNewDocument = function () {
            if (!$scope.file) {
                notifyService.warning("No file selected!");
                return;
            }
            $scope.uploadFile();
            $analytics.eventTrack('Transaction rows', {
                category: 'Invoice Details',
                label: 'Import XLS',
            });
        };

        function closeModal() {
            $uibModalInstance.close();
            $scope.fileType = "file";
            $scope.documentType = "invoice";
            $scope.link = {};
        }

        var fileImagerReader = new FileReader();

        this.$onInit = function () {
            setTimeout(()=> {
                const input = document.querySelector('input[type="file"]');
                if (input) {
                    input.setAttribute('accept', $scope.acceptFiles);
                }
            }, 0);
        }

        fileImagerReader.onload = function (event) {
            processFileUpload();
        };

        function processFileUpload() {
            const importMethod = $scope.isOldXMLImport ? webServices.importTransactionRowsXls : webServices.uploadTransactionRowsFile;
            $scope.contentLoading = true;
            var file = {};
            file.FileName = $scope.file.name;
            file.InvoiceId = $scope.invoice.Id;
            file.Base64Content = utils.getBase64String(fileImagerReader.result);
            file.ForceImport = $scope.ForceImport;
            //$scope.file = null;
            importMethod(file, file.InvoiceId).then(function (response) {
                $scope.contentLoading = false;
                if (response) {
                    if (response.status === 200) {
                        notifyService.success('component.transactionRows.importXLS.Success', 'component.transactionRows.importXLS.SuccessHeader', true);
                        //TODO get rid of this $parent.$parent scopesoup
                        if($scope.$parent && $scope.$parent.$parent && $scope.$parent.$parent.reloadInvoiceData) {
                            $scope.$parent.$parent.reloadInvoiceData($scope.invoice.Id);
                        }
                        if(response.data && response.data.CustomCostObjectives && response.data.CustomCostObjectives.length > 0) {
                            notifyService.warning(
                                <>
                                    <p>
                                        {$filter("translate")('component.transactionRows.importXLS.Warning.CcoCodes', { codes: response.data.CustomCostObjectives.join(', ') })}
                                    </p>
                                    <p>
                                        {$filter("translate")('component.transactionRows.importXLS.Warning.ToResolve')}:
                                    </p>
                                    <ul>
                                        <li>&bull; {$filter("translate")('component.transactionRows.importXLS.Warning.CcoCodes.Item1')}</li>
                                        <li>&bull; {$filter("translate")('component.transactionRows.importXLS.Warning.CcoCodes.Item2')}</li>
                                        <li>&bull; {$filter("translate")('component.transactionRows.importXLS.Warning.CcoCodes.Item3')}</li>
                                    </ul>
                                    <p>
                                    {$filter("translate")('component\.transactionRows\.importXLS\.Warning\.AskSupport')}
                                    </p>
                                </>,
                                'component.transactionRows.importXLS.Warning.CompletedPartially', false,
                                {
                                    toastOptions: {
                                        autoClose: 15000,
                                    },
                                }
                            );
                        }

                        if(response.data && response.data.Dimensions && response.data.Dimensions.length > 0) {
                            notifyService.warning($filter("translate")('component.transactionRows.importXLS.Warning.DimensionCodes', { dimensionCodes: response.data.Dimensions.join(', ') }), 'component.transactionRows.importXLS.Warning.CompletedPartially', false, {
                                toastOptions: {
                                    autoClose: 15000,
                                },
                            });
                        }

                        $rootScope.$emit('xlsImported');
                        $rootScope.$emit('loadTransactionRows');
                        closeModal();
                    } else if (response.status === 400 && response.data) {
                        notifyService.error(response.data.Message || response.data.ErrorCode);
                        closeModal();
                    }
            
                    // TODO: need to verify if we still need force import confirmation functionality on MissingDimensions
                    // if (response.data) {
                    //     if (response.data.contains('MissingDimensions')) {
                    //         var arrX = response.data.split('|');
                    //         var resultMessage = $filter('translate')('component.transactionRows.importXLS.Error.MissingDimensions');
                    //         resultMessage += " " + arrX[1] + " ";
                    //         resultMessage += $filter('translate')('component.transactionRows.importXLS.Error.DoYouWantToImport');
                    //         missingDimensionAction(resultMessage);
                    //     } else {
                    //         notifyService.error(response.data, 'interceptorsFactory.Error', true);
                    //         closeModal();
                    //     }
                    // }
                }
            }).catch(function (e) {
                notifyService.error(e, true);
                closeModal();
            });
        }

        function missingDimensionAction(message) {
            var title = $filter('translate')('component.transactionRows.importXLS.Error.ErrorProcessingFile');
            var question = message;

            var modalInstance = $uibModal.open({
                templateUrl: 'Views/Home/Confirmation.html',
                controller: 'confirmationController',
                resolve: {
                    title: function () {
                        return title;
                    },
                    question: function () {
                        return question;
                    }
                }
            });

            modalInstance.result.then(function (answer) {
                if (answer) {
                    $scope.ForceImport = true;
                    processFileUpload();
                } else {
                    closeModal();
                }
                return;
            });
        }
    });
