import classNames from 'classnames';
import * as React from 'react';

import { BaseComponent } from '../BaseComponent';

export interface MainComponentHeaderProps {
    children?: React.ReactNode;
    className?: string;
}

export class MainComponentHeader extends BaseComponent<MainComponentHeaderProps> {
    render() {
        const { children, className, ...rest } = this.props;
        const classes = classNames('main-component__header', className);
        return (
            <div className={classes} {...rest}>
                <h2>{children}</h2>
            </div>
        );
    }
}

export default MainComponentHeader;
