import * as React from 'react';

import Notification, { NotificationStatus, NotificationType } from './Notification';

export interface LegacyErrorNotificationProps {
    message?: React.ReactNode;
    inTable?: boolean;
}

const LegacyErrorNotification: React.FunctionComponent<LegacyErrorNotificationProps> = ({ message, inTable }: LegacyErrorNotificationProps) => {
    return (
        <Notification dataId="legacy.notification" type={NotificationType.MINIMAL} visible={true} status={NotificationStatus.LEGACY_ERROR} className={inTable ? 'in-table' : ''}>
            {message}
        </Notification>
    );
};

export default LegacyErrorNotification;
