'use strict';

angular
    .module('dstreamApp.components')
    .component('dsInvoiceRegisterDateModal', {
        templateUrl: 'app/components/invoiceRegisterTable/ds-invoice-register-date-modal.html',
        controller: DsInvoiceRegisterDateModalController,
        bindings: {
            filterObject: '=',
            isArchiveView: '<'
        }
    });

function DsInvoiceRegisterDateModalController($rootScope, $scope, invoiceRegisterService, utils) {
    var ctrl = this;

    ctrl.showDefaultFromDate = false;
    ctrl.showDefaultToDate = false;
    ctrl.visible = false;
    ctrl.fromOpen = false;
    ctrl.toOpen = false;

    ctrl.$onInit = function () {
        ctrl.fromOptions = {
            showWeeks: false,
            startingDay: 1,
            maxDate: ctrl.filterObject.toModel
        };
        ctrl.toOptions = {
            showWeeks: false,
            startingDay: 1,
            minDate: ctrl.filterObject.fromModel
        };
    };

    /*
        ============= EVENT LISTENERS ===============
     */
    var toggleDateRangeVisibilityEvent = $rootScope.$on('toggleDateRangeVisibility', function () {
        ctrl.visible = !ctrl.visible;
        if (ctrl.visible) {
            setTimeout(function () {
                $(document).bind('click', ctrl.hideModal);
            });
            if (!ctrl.filterObject.lastXDaysModel) {
                ctrl.filterObject.lastXDaysModel = "90";
            }
            if (!ctrl.filterObject.fromModel || ctrl.filterObject.fromModel === "fromBeginningOfTime") {
                ctrl.filterObject.fromModel = utils.getXDaysAgo("90");
            }
            if (!ctrl.filterObject.toModel || ctrl.filterObject.toModel === "untilEndOfTime") {
                ctrl.filterObject.toModel = utils.getToday();
            }
            ctrl.openFrom();
        }
    });
    var hideDateRangeEvent = $rootScope.$on('hideDateRange', function () {
        ctrl.visible = false;
    });

    $scope.focusInput = function ($event) {
        setTimeout(function () {
            angular.element($event.currentTarget).parent().find('input').triggerHandler('click');
        });
    };

    /*
        ================== VISIBILITY ACTIONS ==============
     */
    ctrl.showModal = function () {
        ctrl.visible = true;
    };

    /*
        Hide modal and remove click event on page
     */
    ctrl.hideModal = function (event, forcedHide) {
        // if we are not clicking inside the modal or the datepicker, then hide the modal
        if (
            forcedHide ||
            (
                ctrl.visible &&
                angular.element(event.target).closest('.component-filter__date-range').length === 0 &&
                angular.element(event.target).closest('.uib-datepicker-popup').length === 0
            )
        ) {
            ctrl.visible = false;
            $(document).unbind('click', ctrl.hideModal);
        }
    };

    /*
        ================ MAIN MODAL ACTIONS ==============
     */

    /*
        Open "from datepicker" and close "to datepicker"
     */
    ctrl.openFrom = function () {
        ctrl.toOpen = false;
        ctrl.fromOpen = true;
        ctrl.fromOptions.maxDate = ctrl.filterObject.toModel;
    };

    /*
        Open "to datepicker" and close "from datepicker"
     */
    ctrl.openTo = function () {
        ctrl.fromOpen = false;
        ctrl.toOpen = true;
        ctrl.toOptions.minDate = ctrl.filterObject.fromModel;
    };

    /*
        Selection event in "from datepicker". Calls the API request for new invoices
     */
    ctrl.fromDatepickerSelect = function (value) {
        if (value) {
            ctrl.filterObject.timeType = 'customDates'; // because we are selecting the date from the datepicker then set the search as by custom dates
            if (ctrl.filterObject.toModel !== 'untilEndOfTime') {
                ctrl.filterObject.pagingOptions.page = 1;
                invoiceRegisterService.doSearch(ctrl.filterObject, false, true, $rootScope.totalAmountsWillLoad && !ctrl.isArchiveView, ctrl.isArchiveView).then(function (result) {
                    $rootScope.$emit('invoiceRegisterLoaded', {
                        result: result.result,
                        filterObject: result.filterObject
                    });
                });
            }
            ctrl.openTo();
        }
    };

    /*
        Selection event in "to datepicker". Calls the API request for new invoices
     */
    ctrl.toDatepickerSelect = function (value) {

        if (value) {
            if (!ctrl.filterObject.fromModel) {
                ctrl.openFrom();
            } else if (ctrl.filterObject.toModel) {
                ctrl.filterObject.timeType = 'customDates'; // because we are selecting the date from the datepicker then set the search as by custom dates
                ctrl.filterObject.pagingOptions.page = 1;
                invoiceRegisterService.doSearch(ctrl.filterObject, false, true, $rootScope.totalAmountsWillLoad && !ctrl.isArchiveView, ctrl.isArchiveView).then(function (result) {
                    $rootScope.$emit('invoiceRegisterLoaded', {
                        result: result.result,
                        filterObject: result.filterObject
                    });
                });
                ctrl.hideModal(null, true);
            }
        }
    };

    /*
        Changing the "date range by what date" filter and call invoice update API method
     */
    ctrl.selectTimeFilter = function (value, e) {
        e.preventDefault();
        switch (value) {
            case 'invoice':
                ctrl.filterObject.searchDateBy.value = value;
                ctrl.filterObject.searchDateBy.text = 'component.invoiceFilter.filterTime_invoiceDate';
                break;
            case 'coding':
                ctrl.filterObject.searchDateBy.value = value;
                ctrl.filterObject.searchDateBy.text = 'component.invoiceFilter.filterTime_codingDate';
                break;
            case 'import':
                ctrl.filterObject.searchDateBy.value = value;
                ctrl.filterObject.searchDateBy.text = 'component.invoiceFilter.filterTime_importDate';
                break;
            case 'export':
                ctrl.filterObject.searchDateBy.value = value;
                ctrl.filterObject.searchDateBy.text = 'component.invoiceFilter.filterTime_exportDate';
                break;
            case 'due':
                ctrl.filterObject.searchDateBy.value = value;
                ctrl.filterObject.searchDateBy.text = 'component.invoiceFilter.filterTime_paymentDate';
                break;
        }

        ctrl.filterObject.pagingOptions.page = 1;
        invoiceRegisterService.doSearch(ctrl.filterObject, false, true,  $rootScope.totalAmountsWillLoad && !ctrl.isArchiveView, ctrl.isArchiveView).then(function (result) {
            $rootScope.$emit('invoiceRegisterLoaded', {
                result: result.result,
                filterObject: result.filterObject
            });
        });
    };

    /*
        User has clicked on one of the predefined date ranges and update invoice list through API
     */
    ctrl.changeTimeType = function (val) {
        ctrl.filterObject.timeType = val;
        ctrl.filterObject.pagingOptions.page = 1;
        invoiceRegisterService.doSearch(ctrl.filterObject, false, true, $rootScope.totalAmountsWillLoad && !ctrl.isArchiveView, ctrl.isArchiveView).then(function (result) {
            $rootScope.$emit('invoiceRegisterLoaded', {
                result: result.result,
                filterObject: result.filterObject
            });
        });
        ctrl.hideModal(null, true);
    };

    /*
        ============= ANGULAR FUNCTIONS ================
     */

    /*
        On component destroy
     */
    ctrl.$onDestroy = function () {
        hideDateRangeEvent();
        toggleDateRangeVisibilityEvent();
    };
}