import produce from 'immer';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { Action } from 'redux-actions';

import { GlobalState } from '../../../../../rootReducer';
import { SystemConfigurationDTO } from '../../../../../services/types/ApiTypes';
import { LoadableData } from '../../../../../common/utils/LoadableData';
import { getCompanySettingsData, updateSetting } from './CompanySettingsListActions';

class State {
    companySettingsDataLoadable = new LoadableData<SystemConfigurationDTO[], string>();
}

export default new ReducerFactory(new State())
    .addReducer(
        getCompanySettingsData.request,
        (state): State => {
            return {
                ...state,
                companySettingsDataLoadable: state.companySettingsDataLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        getCompanySettingsData.success,
        (state, action): State => {
            return {
                ...state,
                companySettingsDataLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getCompanySettingsData.error,
        (state, action): State => {
            return {
                ...state,
                companySettingsDataLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        updateSetting,
        produce((draft, action: Action<{ result: SystemConfigurationDTO; itemToUpdate: string }>) => {
            if (!action.payload.itemToUpdate) {
                draft.companySettingsDataLoadable.payload = [...draft.companySettingsDataLoadable.payload, action.payload.result];
            } else {
                const index = draft.companySettingsDataLoadable.payload.findIndex((i: SystemConfigurationDTO) => i.Name === action.payload.itemToUpdate);
                if (index !== -1) {
                    draft.companySettingsDataLoadable.payload[index] = {
                        ...action.payload.result,
                    };
                }
            }
        }),
    )
    .toReducer();

export { State as CompanySettingsListState };

export const selectCompanySettingsDataLoadable = (state: GlobalState) => state.companySettingsList.companySettingsDataLoadable;

export const selectExportCSVDelimiterSymbol = (state: GlobalState) =>
    state.companySettingsList.companySettingsDataLoadable?.payload?.find((i) => {
        return i.Name === 'ExportCSVDelimiterSymbol';
    })?.Value;
