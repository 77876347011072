import React from 'react';
import { TFunction } from 'i18next';
import classNames from 'classnames';

import Icon, { ICONS } from '../../../Icon/Icon';
import { hasDigit, hasEnoughSymbols, hasLowerCase, hasUpperCase, maxLengthCheck } from './utils';

import './PasswordRequirements.scss';

interface Props {
    isPristine: boolean;
    value: string;
    isMatch: boolean;
    t: TFunction;
}

interface RequirementProps {
    children?: React.ReactNode;
    isValid?: boolean;
    isPristine?: boolean;
    isMatch?: boolean;
}

export const Requirement = (props: RequirementProps) => {
    const classes = classNames('password-requirement', { 'password-requirement--is-valid': props.isValid, 'password-requirement--is-pristine': props.isPristine });
    return (
        <li className={classes}>
            <span className="password-requirement-indicator">
                {props.isValid && <Icon name={ICONS.CHECKMARK} />}

                {!props.isValid && !props.isPristine && <Icon name={ICONS.ALERT} />}
                {props.isPristine && !props.isValid && <span>&ndash;</span>}
            </span>
            {props.children}
        </li>
    );
};

const PasswordRequirements = ({ isPristine, t, value, isMatch }: Props) => {
    return (
        <ul className="password-requirements">
            <h4>{t('component.changePassword.requirements')}</h4>
            <Requirement isPristine={isPristine} isValid={hasLowerCase(value)}>
                {t('component.changePassword.error.mustContainAtLeastOneLower')}
            </Requirement>
            <Requirement isPristine={isPristine} isValid={hasUpperCase(value)}>
                {t('component.changePassword.error.mustContainAtLeastOneUpper')}
            </Requirement>
            <Requirement isPristine={isPristine} isValid={hasDigit(value)}>
                {t('component.changePassword.error.mustContainAtLeastOneDigit')}
            </Requirement>
            <Requirement isPristine={isPristine} isValid={hasEnoughSymbols(value)}>
                {t('component.changePassword.error.mustHaveLength12orMore')}
            </Requirement>
            <Requirement isPristine={isPristine} isValid={maxLengthCheck(value)}>
                {t('component.changePassword.error.mustBeAtLessThan256CharsLong')}
            </Requirement>
            <Requirement isPristine={isPristine} isValid={isMatch}>
                {t('component.changePassword.passwordsMatch')}
            </Requirement>
        </ul>
    );
};

export default PasswordRequirements;
