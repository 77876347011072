'use strict';

if (typeof Promise === 'undefined') {
    // Rejection tracking prevents a common issue where React gets into an
    // inconsistent state due to an error, but it gets swallowed by a Promise,
    // and the user has no idea what causes React's erratic future behavior.
    require('promise/lib/rejection-tracking').enable();
    window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');
require('core-js/features/array/from');
require('core-js/features/array/find');
require('core-js/features/array/find-index');
require('core-js/features/array/flat');
require('core-js/features/array/includes');
require('core-js/features/string/includes');
require('core-js/features/string/ends-with');
require('core-js/features/object/values');
require('core-js/features/promise/finally');
require('core-js/es/weak-map');

require('core-js/es/set');
require('core-js/es/map');
require('core-js/modules/web.url.js');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

// Symbol for singletons
require('core-js/features/symbol');

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
    require('raf').polyfill(global);
}
