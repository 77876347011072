import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable1 } from 'redux-dispatchers';

import withSuspense from '../../common/hocs/withSuspense';
import { LoadableData } from '../../common/utils/LoadableData';
import { BackOfficeUserDTO } from '../../services/types/BoApiTypes';
import { createDataId } from '../../common/utils/dataId';
import { Button, ButtonType } from '../Buttons/Button';
import { ICONS } from '../Icon/Icon';
import { sendEmailVerificationLink } from './EmailVerificationHelper';
import { AUTOFOCUS_ON_EMAIL_VERIFICATION_STRING } from '../UserProfile/UserProfile';
import { useTimer } from '../../common/hooks/useTimer';

import './EmailVerification.scss';

const emailVerificationImg = require('../../../images/email-verification.svg') as string;
const emailVerifiedImg = require('../../../images/email-verified.svg') as string;

export interface Props {
    boUser: LoadableData<BackOfficeUserDTO>;
    emailVerificationCompleted: boolean;
}

export type EmailVerificationProps = Props & DispatchProps & WithTranslation;

export const dataId = 'emailVerification';

export interface DispatchProps {
    showUserProfile: Dispatchable1<string>;
    setEmailVerificationPendingSetting: Dispatchable1<boolean>;
}

export const EmailVerification = (props: EmailVerificationProps) => {
    const { boUser, showUserProfile, setEmailVerificationPendingSetting, emailVerificationCompleted, t } = props;
    const [timerOn, setTimerOn] = React.useState(false);
    const timer = useTimer(timerOn, 60);

    React.useEffect(() => {
        if (timer < 1) {
            setTimerOn(false);
        }
    }, [timer]);

    const sendVerificationLink = async () => {
        const sendResult = await sendEmailVerificationLink(boUser.payload.UserGuid, boUser.payload.Email);
        if (sendResult) {
            setEmailVerificationPendingSetting(true);
            setTimerOn(true);
            if (sessionStorage.getItem('loginSession')) {
                sessionStorage.removeItem('loginSession');
            }
        }
    };

    React.useEffect(() => {
        if (sessionStorage.getItem('loginSession') && !emailVerificationCompleted && boUser?.payload && boUser?.payload?.Email) {
            sendVerificationLink();
        }
    }, [boUser]);

    return (
        <div className="email-verification" data-id={createDataId(dataId, 'content')}>
            <div className="email-verification__content" data-id={createDataId(dataId, 'content')}>
                <img className="email-verification__content--img" width="328" height="210" src={emailVerificationCompleted ? emailVerifiedImg : emailVerificationImg} />
                <div className="email-verification__right-block">
                    <div className="email-verification__right-block--text">
                        <h4>
                            {t(
                                emailVerificationCompleted
                                    ? 'views.global.emailVerification.completed'
                                    : boUser.payload.Email
                                    ? 'views.global.emailVerification.linkSent'
                                    : 'views.global.emailVerification.title.setEmail',
                            )}
                        </h4>
                        <p>
                            {emailVerificationCompleted ? (
                                t('views.global.emailVerification.completedBody')
                            ) : boUser.payload.Email ? (
                                <Trans i18nKey={'views.global.emailVerification.linkSentBody'} values={{ email: boUser.payload.Email }}>
                                    <b>{boUser.payload.Email}</b>
                                </Trans>
                            ) : (
                                t('views.global.emailVerification.setEmail.body')
                            )}
                        </p>
                        <p>
                            {emailVerificationCompleted
                                ? t('views.global.emailVerification.completedFooter')
                                : boUser.payload.Email
                                ? t('views.global.emailVerification.linkSentFooter')
                                : t('views.global.emailVerification.setEmail.footer')}
                        </p>
                    </div>
                    <div className="email-verification__right-block--buttons">
                        <Button
                            buttonType={ButtonType.ICON_TEXT}
                            icon={emailVerificationCompleted ? ICONS.CLOSE_24 : ICONS.EDIT_24}
                            onClick={() => {
                                emailVerificationCompleted ? setEmailVerificationPendingSetting(false) : showUserProfile(AUTOFOCUS_ON_EMAIL_VERIFICATION_STRING);
                            }}
                            dataId={createDataId(dataId, 'change-email')}
                        >
                            {t(
                                emailVerificationCompleted
                                    ? 'component.relatedDocuments.close'
                                    : boUser.payload.Email
                                    ? 'views.login.forgotPassword.changeEmail'
                                    : 'views.global.emailVerification.button.setEmail',
                            )}
                        </Button>
                        {!emailVerificationCompleted && boUser.payload.Email && (
                            <Button buttonType={ButtonType.ICON_TEXT} disabled={timer > 0} icon={ICONS.INVITE_24} onClick={sendVerificationLink} dataId={createDataId(dataId, 'resend-link')}>
                                {timer > 0 ? t('views.global.emailVerification.resendTimer', { sec: timer }) : t('views.global.emailVerification.resendLink')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withSuspense(withTranslation()(EmailVerification));
