import { isEmpty } from 'lodash-es';
import { createAction } from 'redux-actions';

import { getViewUserSearchParams, getViewUserSettings, setUserSettingValue, ListViewUserConfig } from '../../../../common/user/userSettingUtil';
import { notify } from '../../../../common/utils/notify';
import { loadableDataActionsWithRequest } from '../../../../common/utils/LoadableData';
import { getCurrentUserGroupMember, updateUserSettings } from '../../../../common/user/UserActions';
import { validateAndFixPagingOptions } from '../../../../common/utils/baseSearchHelpers';
import i18nInstance from '../../../../i18n';
import { GlobalState } from '../../../../rootReducer';
import { DispatchThunk } from '../../../../storeConfig';
import { PagedListContainer, InvoiceRowDTO, UserSettingName } from '../../../../services/types/ApiTypes';
import api from '../../../../services/ApiServices';
import { InvoiceRowsSearchParams, selectInvoiceRowsListSearchParams, createNewRow } from './InvoiceRowsReducers';
import { BulkActionModifier } from './InvoiceRowsHelper';

const ns = 'invoice/invoice-rows/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.INVOICE_ROWS_SORT_DIRECTION,
    sortCol: UserSettingName.INVOICE_ROWS_SORT_COLUMN,
    pageSize: UserSettingName.INVOICE_ROWS_PAGE_SIZE,
};

export const getInvoiceRowsLoadable = loadableDataActionsWithRequest<InvoiceRowsSearchParams, PagedListContainer<InvoiceRowDTO>>(`${ns}ALL_INVOICE_ROWS`);

export const getInvoiceRowsList = (id: number, searchParams?: InvoiceRowsSearchParams, openFirstPage?: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const invoiceRowsSearchParams = selectInvoiceRowsListSearchParams(getState());
        const groupMember = getState().user.groupMemberCommonLoadable.payload;
        searchParams = searchParams || invoiceRowsSearchParams;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember, true);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        //viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Code', SortDirection.Asc);
        const apiSearchParams = { ...searchParams };
        if (openFirstPage) {
            apiSearchParams.PagingOptions.Page = 1;
        }
        let response;
        try {
            dispatch(getInvoiceRowsLoadable.request(searchParams));
            response = await api.invoice.getInvoiceRowsByInvoiceId(id, apiSearchParams);
            if (response?.status === 200) {
                dispatch(
                    getInvoiceRowsLoadable.success({
                        request: searchParams,
                        result: response.data,
                    }),
                );
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            dispatch(
                getInvoiceRowsLoadable.error({
                    request: searchParams,
                    result: e,
                }),
            );
        }
    };
};

export const setInvoiceRowsListPagingOptions = (id: number, page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            invoiceRows,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const paging = invoiceRows.searchParams.PagingOptions;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(listViewConfig, groupMember);
            if (!viewConfig.pageSize || parseInt(viewConfig.pageSize, 10) !== pageSize) {
                groupMember.UserSettings = setUserSettingValue(listViewConfig.pageSize, pageSize, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: InvoiceRowsSearchParams = {
            ...invoiceRows.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        await dispatch(getInvoiceRowsList(id, searchParams));
    };
};

export const addNewRow = createAction(`${ns}ADD_NEW_ROW`);

export const gotoLastPageAndAddNewRow = (invoiceId: number, vatRate?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const payload = state.invoiceRows.invoiceRowsLoadable.payload;
        const lastPage = Math.ceil(payload.TotalCount / payload.Take) || 1;
        const currentPage = payload.Skip / payload.Take + 1;
        if (lastPage !== currentPage) {
            await dispatch(setInvoiceRowsListPagingOptions(invoiceId, lastPage, payload.Take));
        }
        dispatch(addNewRow(createNewRow(invoiceId, state.invoiceRows.invoiceRowsLoadable.payload.TotalCount + 1, vatRate)));
    };
};

export const updateRow = createAction<{ result: InvoiceRowDTO; itemToUpdate: number }>(`${ns}UPDATE_ROW`);

export const saveRow = (item: InvoiceRowDTO, reloadInvoiceData: (invoiceId: number) => void, saveAndAddNewRow?: boolean) => {
    return async (dispatch: DispatchThunk) => {
        try {
            let result;
            item.OrderNo = Number(item?.OrderNo);
            if (item.Id > 0) {
                result = await api.invoice.updateInvoiceRow(item);
            } else {
                result = await api.invoice.addInvoiceRow(item);
            }
            if (result.status === 200 && result.data) {
                dispatch(
                    updateRow({
                        result: item,
                        itemToUpdate: item.Id,
                    }),
                );
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                if (saveAndAddNewRow) {
                    await dispatch(getInvoiceRowsList(item.InvoiceId));
                    dispatch(gotoLastPageAndAddNewRow(item.InvoiceId, item?.VatRate));
                } else {
                    dispatch(getInvoiceRowsList(item.InvoiceId));
                }
                reloadInvoiceData(item.InvoiceId);
            }
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.ErrorCode) {
                notify.error(i18nInstance.t(e.response.data.ErrorCode));
            } else {
                notify.error(i18nInstance.t('view.Accounting.RowSavingFailed'));
            }
        }
    };
};

export const removeRow = createAction(`${ns}REMOVE_NEW_ROW`);

export const removeNewRow = (invoiceId: number) => {
    return async (dispatch: DispatchThunk) => {
        dispatch(removeRow(0));
        dispatch(getInvoiceRowsList(invoiceId)); // lets reload to keep the item count on page up-to-date
    };
};

export const deleteRow = (item: InvoiceRowDTO, reloadInvoiceData?: (invoiceId: number) => void) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const result = await api.invoice.deleteInvoiceRow(item);

            if (result.status === 200 && result.data) {
                if (getState().invoiceRows.invoiceRowsLoadable.payload?.Items.length === 1) {
                    // we deleted the last item, so go to previous page
                    const currentPage = getState().invoiceRows.searchParams?.PagingOptions?.Page;
                    if (currentPage > 1) {
                        dispatch(setInvoiceRowsListPagingOptions(item.InvoiceId, currentPage - 1, getState().invoiceRows.searchParams?.PagingOptions?.Count));
                    } else {
                        dispatch(getInvoiceRowsList(item.InvoiceId));
                    }
                } else {
                    dispatch(getInvoiceRowsList(item.InvoiceId));
                }
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.deleted'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                reloadInvoiceData(item.InvoiceId);
            }
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.ErrorCode) {
                notify.error(i18nInstance.t(e.response.data.ErrorCode));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'));
            }
        }
    };
};

export const bulkDeleteInvoiceRow = (invoiceId: number, type: BulkActionModifier, reloadInvoiceData?: (invoiceId: number) => void, invoiceRowIds?: number[]) => {
    return async (dispatch: DispatchThunk) => {
        try {
            const result = await api.invoice.bulkDeleteInvoiceRow(invoiceId, type, invoiceRowIds);

            if (result.status === 200 && result.data) {
                dispatch(getInvoiceRowsList(invoiceId, null, true));
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.deleted'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                reloadInvoiceData(invoiceId);
            }
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.ErrorCode) {
                notify.error(i18nInstance.t(e.response.data.ErrorCode));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'));
            }
        }
    };
};

export function setIsInvoiceRowsCalculationActive(value: boolean) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
                await dispatch(getCurrentUserGroupMember());
            }
            const state = getState();

            const {
                groupMemberCommonLoadable: { payload: groupMember },
            } = state.user;

            if (!isEmpty(groupMember)) {
                groupMember.UserSettings = setUserSettingValue(UserSettingName.IS_INVOICE_ROWS_CALCULATION_ACTIVE, value, groupMember.UserSettings);

                await dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
                await dispatch(getCurrentUserGroupMember(true));
            }
        } catch (e) {
            console.error(e);
        }
    };
}
