import { withTranslation } from 'react-i18next';

import withSuspense from '../../common/hocs/withSuspense';
import { getCurrentPath } from '../../common/router/routerSelectors';
import { getCurrentUser, getCurrentCompanyName, getWaitingInvoicesCountAreUpdating, selectAskToVerifyEmail, showEmailVerifiedNotification } from '../../common/user/UserSelectors';
import { GlobalState } from '../../rootReducer';
import { connect } from '../../storeConfig';
import { showChangeCompany } from '../ChangeCompany/ChangeCompanyActions';
import { showUserProfile } from '../UserProfile/UserProfileActions';
import { showChangeLanguage } from '../ChangeLanguage/ChangeLanguageActions';
import { selectPdfToOpen } from '../PDFViewer/FileViewReducer';

import TopMenu, { TopMenuProps, DispatchProps } from './TopMenu';
import { getUserCompanies, updateAllWaitingInvoiceCounts } from '../../common/user/UserActions';

const mapStateToProps = (state: GlobalState): TopMenuProps => {
    return {
        currentUser: getCurrentUser(state),
        currentPath: getCurrentPath(state),
        emailVerificationProcess: selectAskToVerifyEmail(state),
        emailVerificationCompleted: showEmailVerifiedNotification(state),
        currentCompanyName: getCurrentCompanyName(state),
        areWaitingInvoicesCountsUpdating: getWaitingInvoicesCountAreUpdating(state),
        pdfToOpen: selectPdfToOpen(state),
        isUserProfileVisibleAndFocusOn: state.userProfile.visibleAndFocusOn,
    };
};

const mapDispatchToProps: DispatchProps = {
    showChangeCompany,
    showUserProfile,
    showChangeLanguage,
    getUserCompanies,
    updateAllWaitingInvoiceCounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(withTranslation()(TopMenu)));
