function importTemplates(ctx, name) {
    angular.module('dstreamApp').run([
        "$templateCache",
        function($templateCache) {
            ctx.keys().forEach(key => {
                $templateCache.put(key.replace("./", `${name}/`), ctx(key));
            });
        }
    ]);
}


export function initializeTemplateCache() {
    //import templates by module
    const componentsCtx = require.context(`./components/`, true, /.*\.html$/);
    const directivesCtx = require.context(`./directives/`, true, /.*\.html$/);
    const viewsCtx = require.context(`./views/`, true, /.*\.html$/);
    const views2Ctx = require.context(`../Views/`, true, /.*\.html$/);
    importTemplates(componentsCtx, 'app/components');
    importTemplates(directivesCtx, 'app/directives');
    importTemplates(viewsCtx, 'app/views');
    importTemplates(views2Ctx, 'Views');
}
