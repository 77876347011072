import React from 'react';
import classNames from 'classnames';

import { createDataId } from '../../common/utils/dataId';
import OverflowTooltip from '../Tooltip/OverflowTooltip';
import Icon from '../Icon/Icon';

import './PropertyListItem.scss';

interface PropertyListItemProps {
    dataId: string;
    label: string;
    value: string | number;
    alignRight?: boolean;
    className?: string;
    isBiggerFontText?: boolean;
    isBiggerLabelFontText?: boolean;
    showPlaceholder?: boolean;
    hideOverflow?: boolean;
    showZeroValue?: boolean;
    textIcon?: string;
    isDefaultLetterCase?: boolean;
}

export const getStringOrDashes = (val?: string | number) => (val ? val.toString() : '--');

const PropertyListItem = (props: PropertyListItemProps) => {
    const { alignRight, className, label, isBiggerFontText, isBiggerLabelFontText, showPlaceholder, hideOverflow, value, showZeroValue, textIcon, isDefaultLetterCase } = props;
    return (
        <li data-id={createDataId(props.dataId, 'property-item', label)} className={classNames('property-list-item__item', { 'property-list-item__item--align-right': alignRight }, className || '')}>
            <span className={classNames('property-list-item__label', { 'is-bigger-font-text': isBiggerLabelFontText })}>{hideOverflow ? <OverflowTooltip>{label}</OverflowTooltip> : label}</span>
            {showPlaceholder ? (
                <PropertyPlaceholder />
            ) : (
                <span className={classNames('property-list-item__value', { 'is-bigger-font-text': isBiggerFontText }, { 'is-default-font-text': isDefaultLetterCase })}>
                    {hideOverflow ? (
                        <>
                            {textIcon && <Icon className={'property-list-item__icon'} name={textIcon} />}
                            <OverflowTooltip>{getStringOrDashes(value)}</OverflowTooltip>
                        </>
                    ) : showZeroValue && value === 0 ? (
                        value
                    ) : (
                        <>
                            {textIcon && <Icon className={'property-list-item__icon'} name={textIcon} />}
                            {getStringOrDashes(value)}
                        </>
                    )}
                </span>
            )}
        </li>
    );
};

export const PropertyPlaceholder = () => (
    <div className="property-list-item__property--placeholder">
        <span className="property-value"></span>
    </div>
);

export default PropertyListItem;
