'use strict';

angular
    .module('dstreamApp.components')
    .component('dsWhosAway', {
        templateUrl: 'app/components/whosAway/ds-whos-away.html',
        controller: DsWhosAwayController
    });

/* @ngInject */
function DsWhosAwayController($rootScope, $scope, webServices, invoiceService, notifyService, constants) {
    var ctrl = this;
    ctrl.DATE_EE_FORMAT = constants.DATE_EE_FORMAT;
    $scope.loading = true;

    /*
        ============= EVENT LISTENERS ===============
     */
    var appperformUpdateSubstituteEvent = $rootScope.$on("app.performUpdateSubstitute", function() {
        getSubstitutes();
    });
    var authorizationDataLoadedEvent = $rootScope.$on("authorizationDataLoaded", function () {
    	getSubstitutes();
    });
    
    function getSubstitutes() {
    	$scope.companySubstitutes = [];
        webServices.getCompanySubstitutes($rootScope.userData.BOGuid).then(function (result) {
            $scope.loading = false;
            if (result.data) {
                // fill scope.companySubstitutes only if we have matching group members
                for (var i = 0; i < result.data.length; i++) {
                	if (result.data.GroupMemberName !== '') {
                		$scope.companySubstitutes.push(result.data[i]);
                	}
                }
            }
        }).catch(function () {
            notifyService.error('controller.dashboardController.Error_loading_My Tasks', 'controller.dashboardController.Error', true);
        });
    }

    this.$onDestroy = function() {
        appperformUpdateSubstituteEvent();
        authorizationDataLoadedEvent();
    };

    if ($rootScope.userData) {
    	getSubstitutes();
    }
}
