import { isEmpty } from 'lodash-es';
import { ViewFilters } from 'src/views/back-office/common/boUserSettingUtil';
import { BaseSearch, BaseSearchWithTableFilters, GroupMemberCommonDTO, GroupMemberDTO, UserSetting, UserSettingName, SearchType, SortDirection } from '../../services/types/ApiTypes';
import constants from '../constants/appConstants';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../utils/baseSearchHelpers';

export type ViewPageSize = UserSettingName;
export type ViewSortColumn = UserSettingName;
export type ViewSortDirection = UserSettingName;
// TODO @tonister: Add this feature in a later release when the saving filters has been thoroughly thought through
// export type ViewFilters = UserSettingName;

export interface ListViewUserConfig {
    pageSize: ViewPageSize;
    sortCol: ViewSortColumn;
    sortDir: ViewSortDirection;
    filters?: ViewFilters;
}

export function createRequest(searchValue = '', sortColumn = 'CompanyName', page = 1, count = 10000) {
    const search: BaseSearch = {
        Restrictions:
            searchValue?.length > 0
                ? [
                      {
                          Field: 'CompanyName',
                          Value: searchValue,
                          Values: undefined,
                          FieldSearchType: SearchType.NotSelected,
                      },
                  ]
                : [],
        SortItems: [
            {
                SortColumn: sortColumn,
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: page,
            Count: count,
        },
    };
    return search;
}

export interface ListViewBaseSearch {
    searchParams: BaseSearch | BaseSearchWithTableFilters<any, any>;
    listViewConfig: ListViewUserConfig;
}

export function getUserSettingValue(setting: UserSettingName, groupMember: GroupMemberDTO | GroupMemberCommonDTO): string {
    if (!groupMember || (groupMember && isEmpty(groupMember.UserSettings))) {
        return undefined;
    }

    const value: undefined | UserSetting = groupMember.UserSettings.find((s) => s.Name === setting);
    if (setting === UserSettingName.IS_EMAIL_NOTIFICATIONS_ENABLED) {
        // if setting is not present, the value is 'true';
        if (!value) {
            return 'true';
        }
        return value.Value;
    }

    return !!value && value.Value;
}

export function setUserSettingValue(setting: UserSettingName, value: any, settings: UserSetting[]): UserSetting[] {
    const index = settings.findIndex((s) => s.Name === setting);
    if (index > -1) {
        settings[index].Value = value.toString();
    } else {
        settings.push({
            Name: setting,
            Value: value.toString(),
        });
    }
    return settings;
}

export function getViewUserSettings(viewConfig: ListViewUserConfig, groupMember: GroupMemberDTO | GroupMemberCommonDTO) {
    const userViewConfig: {
        pageSize: string | false;
        sortCol: string | false;
        sortDir: string | false;
        // TODO @tonister: Add this feature in a later release when the saving filters has been thoroughly thought through
        // filters?: string;
    } = {
        pageSize: getUserSettingValue(viewConfig.pageSize, groupMember) || String(constants.DEFAULT_PAGINATOR.count), // this fallback is needed when GroupMembers doesn't include property for pageSize (like for new users), then getUsersSettingsValue would return false instead of a number string
        sortCol: getUserSettingValue(viewConfig.sortCol, groupMember),
        sortDir: getUserSettingValue(viewConfig.sortDir, groupMember),
    };
    // TODO @tonister: Add this feature in a later release when the saving filters has been thoroughly thought through
    // if (viewConfig.filters) {
    //     userViewConfig.filters = getUserSettingValue(viewConfig.filters, groupMember);
    // }
    return userViewConfig;
}

export function getViewUserPaging(
    searchParams: BaseSearchWithTableFilters<any, any>,
    viewConfig: ListViewUserConfig,
    groupMember: GroupMemberDTO | GroupMemberCommonDTO,
): BaseSearchWithTableFilters<any, any> {
    const viewConf = getViewUserSettings(viewConfig, groupMember);
    if (!!viewConf.pageSize && searchParams && searchParams.hasOwnProperty('PagingOptions')) {
        const searchParamsCount = searchParams?.PagingOptions?.Count;
        searchParams.PagingOptions = validateAndFixPagingOptions({
            ...searchParams.PagingOptions,
            // checking against 15 (default value) is needed to apply Settings Count on VatCodeDetailsView and AccountDetailsView
            // TODO: improve by unifying the way Settings are applied or use different functions for GroupMember vs. BOUser Settings
            Count: searchParamsCount && searchParamsCount !== 15 ? searchParamsCount : parseInt(viewConf.pageSize, 10),
        });
    }

    return searchParams;
}

// TODO @tonister: Add this feature in a later release when the saving filters has been thoroughly thought through
// export function getViewUserFilters(searchParams: BaseSearchWithTableFilters<any, any>, viewConfig: ListViewUserConfig, groupMember: GroupMemberDTO): BaseSearchWithTableFilters<any, any> {
//     const viewConf = getViewUserSettings(viewConfig, groupMember);
//     if (!!viewConf.filters && searchParams && searchParams.hasOwnProperty('filters')) {
//         const filterValues = JSON.parse(viewConf.filters);
//         const restrictions: Restriction[] = filterValues.map(
//             (filter: { columnName: string; values: any[] }): Restriction => {
//                 return {
//                     Field: filter.columnName,
//                     Value: null,
//                     Values: filter.values,
//                     FieldSearchType: SearchType.NotSelected,
//                 };
//             },
//         );
//         searchParams.Restrictions = [...searchParams.Restrictions, ...restrictions];
//     }
//
//     return searchParams;
// }

export function getViewUserSorting(
    searchParams: BaseSearchWithTableFilters<any, any>,
    viewConfig: ListViewUserConfig,
    groupMember: GroupMemberDTO | GroupMemberCommonDTO,
): BaseSearchWithTableFilters<any, any> {
    const viewConf = getViewUserSettings(viewConfig, groupMember);

    if (!!viewConf.sortCol && searchParams && searchParams.hasOwnProperty('SortItems')) {
        searchParams.SortItems[0] = {
            ...searchParams.SortItems[0],
            SortColumn: viewConf.sortCol,
        };
    }
    if (!!viewConf.sortDir && searchParams && searchParams.hasOwnProperty('SortItems')) {
        searchParams.SortItems[0] = {
            ...searchParams.SortItems[0],
            SortDirection: parseInt(viewConf.sortDir, 10),
        };
    }

    searchParams.SortItems = validateAndFixSortItems(searchParams.SortItems);

    return searchParams;
}

export function getViewUserSearchParams(
    searchParams: BaseSearchWithTableFilters<any, any>,
    viewConfig: ListViewUserConfig,
    groupMember: GroupMemberDTO | GroupMemberCommonDTO,
    withoutSorting?: boolean,
): BaseSearchWithTableFilters<any, any> {
    searchParams = getViewUserPaging(searchParams, viewConfig, groupMember);
    if (!withoutSorting) {
        searchParams = getViewUserSorting(searchParams, viewConfig, groupMember);
    }

    // TODO @tonister: Add this feature in a later release when the saving filters has been thoroughly thought through
    // if (viewConfig.hasOwnProperty('filters') && viewConfig.filters) {
    //     searchParams = getViewUserFilters(searchParams, viewConfig, groupMember);
    // }

    return searchParams;
}
