import { EditSupplierFields } from './EditSupplierModal';

type ErrorDataObject<T> = {
    fieldName: T;
    error: string;
};

type ErrorFieldNames = keyof Pick<EditSupplierFields, 'RegistrationCode'>;

export const errorsMap: Map<string, ErrorDataObject<ErrorFieldNames>> = new Map([
    [
        'Suppliers.Update: invalid registration code',
        {
            fieldName: 'RegistrationCode',
            error: 'view.settings.suppliers.addOrEditSupplier.errors.RegistrationCode.invalidCode',
        },
    ],
]);
