import { isEmpty } from 'lodash-es';

import i18n from '../../../../../../i18n';
import api from '../../../../../../services/ApiServices';
import { GlobalState } from '../../../../../../rootReducer';
import { DispatchThunk } from '../../../../../../storeConfig';
import { getCurrentUserGroupMember, updateUserSettings } from '../../../../../../common/user/UserActions';
import { getViewUserSearchParams, getViewUserSettings, ListViewUserConfig, setUserSettingValue } from '../../../../../../common/user/userSettingUtil';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../../../../common/utils/baseSearchHelpers';
import { loadableDataActionsWithRequest } from '../../../../../../common/utils/LoadableData';
import { notify } from '../../../../../../common/utils/notify';
import { BaseSearch, PagedListContainer, SearchType, SortDirection, UserSettingName, InvoiceHeaderHistoryDTO } from '../../../../../../services/types/ApiTypes';
import { DEFAULT_RESTRICTION, HistoryListFilter } from './HistoryListHelper';
import { HistorySearchParams, selectInvoiceHeaderHistoryListSearchParams } from './HistoryListReducers';

const ns = 'invoice-history-list/';

const historyListViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.INVOICE_HEADER_HISTORY_LIST_SORT_DIRECTION,
    sortCol: UserSettingName.INVOICE_HEADER_HISTORY_LIST_SORT_COLUMN,
    pageSize: UserSettingName.INVOICE_HEADER_HISTORY_LIST_PAGE_SIZE,
};

export const getInvoiceHeaderHistoryLoadable = loadableDataActionsWithRequest<HistorySearchParams, PagedListContainer<InvoiceHeaderHistoryDTO>>(`${ns}ALL_HISTORY`);

export const getHistoryList = (searchParams: BaseSearch, invoiceId: number | string, historyCategory?: HistoryListFilter, openFirstPage?: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }

        const historySearchParams = searchParams || selectInvoiceHeaderHistoryListSearchParams(getState());
        const groupMember = getState().user.groupMemberCommonLoadable.payload;

        const viewSearchParams = getViewUserSearchParams(historySearchParams, historyListViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'AuditDate', SortDirection.Asc);

        const apiSearchParams = { ...historySearchParams };
        if (openFirstPage) {
            apiSearchParams.PagingOptions.Page = 1;
        }
        apiSearchParams.Restrictions = [
            {
                Field: historyCategory === HistoryListFilter.PurchaseOrders ? 'InternalInvoiceGuid' : 'Id',
                Value: invoiceId,
                FieldSearchType: 0,
                Values: null,
            },
            {
                Field: 'language',
                Value: i18n.language,
                FieldSearchType: 0,
                Values: null,
            },
            ...apiSearchParams.Restrictions,
        ];
        let response;
        try {
            dispatch(getInvoiceHeaderHistoryLoadable.request(historySearchParams));
            if (historyCategory === HistoryListFilter.PurchaseOrders) {
                response = await api.invoice.getPurchaseOrdersLinkingHistory(apiSearchParams);
            } else if (historyCategory === HistoryListFilter.CustomFields) {
                response = await api.invoice.getInvoiceCustomFieldsHistory(apiSearchParams);
            } else {
                response = await api.invoice.getInvoiceHeaderHistory(apiSearchParams);
            }

            if (response?.status === 200) {
                dispatch(
                    getInvoiceHeaderHistoryLoadable.success({
                        request: historySearchParams,
                        result: response.data,
                    }),
                );
            } else {
                notify.error(i18n.t('interceptorsFactory.ErrorWhileProcessingData'), i18n.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            dispatch(
                getInvoiceHeaderHistoryLoadable.error({
                    request: historySearchParams,
                    result: e,
                }),
            );
        }
    };
};

export const setInvoiceHeaderHistoryPagingOptions = (invoiceId: number | string, page?: number, pageSize?: number, historyCategory?: HistoryListFilter) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            invoiceHeaderHistoryList,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const paging = invoiceHeaderHistoryList.searchParams.PagingOptions;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(historyListViewConfig, groupMember);
            if (!viewConfig.pageSize || parseInt(viewConfig.pageSize, 10) !== pageSize) {
                groupMember.UserSettings = setUserSettingValue(historyListViewConfig.pageSize, pageSize, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: HistorySearchParams = {
            ...invoiceHeaderHistoryList.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        dispatch(getHistoryList(searchParams, invoiceId, historyCategory));
    };
};

export const sortInvoiceHeaderHistoryList = (columnName: string, invoiceId: number | string, historyCategory?: HistoryListFilter) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            invoiceHeaderHistoryList,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const sorting = invoiceHeaderHistoryList.searchParams.SortItems[0];
        const sortingDirection = sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(historyListViewConfig, groupMember);

            if (!viewConfig.sortCol || viewConfig.sortCol !== columnName) {
                groupMember.UserSettings = setUserSettingValue(historyListViewConfig.sortCol, columnName, groupMember.UserSettings);
            }
            if (!viewConfig.sortDir || parseInt(viewConfig.sortDir, 10) !== sortingDirection) {
                groupMember.UserSettings = setUserSettingValue(historyListViewConfig.sortDir, sortingDirection, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: BaseSearch = {
            ...invoiceHeaderHistoryList.searchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };
        dispatch(getHistoryList(searchParams, invoiceId, historyCategory));
    };
};

export const searchInvoiceHeaderHistoryList = (searchString: string, invoiceId: number | string, historyCategory?: HistoryListFilter) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.invoiceHeaderHistoryList.searchParams.PagingOptions;
        let searchRestriction = state.invoiceHeaderHistoryList.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        const otherRestrictions = state.invoiceHeaderHistoryList.searchParams.Restrictions.filter((restriction, index) => index !== 0);
        let restrictions = [];

        if (!isEmpty(searchString)) {
            if (!searchRestriction) {
                searchRestriction = {
                    Field: DEFAULT_RESTRICTION,
                    Value: searchString,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                };
            } else {
                searchRestriction.Value = searchString;
            }
            restrictions.push(searchRestriction);
        }

        if (!isEmpty(otherRestrictions)) {
            restrictions = [...restrictions, ...otherRestrictions];
        }
        const searchParams: HistorySearchParams = {
            ...state.invoiceHeaderHistoryList.searchParams,
            PagingOptions: {
                ...paging,
                Page: 1,
            },
            Restrictions: [...restrictions],
        };
        dispatch(getHistoryList(searchParams, invoiceId, historyCategory));
    };
};

export function setHistoryListCategorySetting(category: HistoryListFilter) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
                await dispatch(getCurrentUserGroupMember());
            }
            const state = getState();

            const {
                groupMemberCommonLoadable: { payload: groupMember },
            } = state.user;

            if (!isEmpty(groupMember)) {
                groupMember.UserSettings = setUserSettingValue(UserSettingName.INVOICE_HEADER_HISTORY_LIST_CATEGORY, category, groupMember.UserSettings);

                await dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
                if (!category) {
                    await dispatch(getCurrentUserGroupMember(true));
                }
            }
        } catch (e) {
            console.error(e);
        }
    };
}
