import { isEmpty } from 'lodash-es';
import i18n from '../../../i18n';

import { loadableDataActions, loadableDataActionsWithRequest } from '../../../common/utils/LoadableData';
import { DispatchThunk } from '../../../storeConfig';
import api from '../../../services/ApiServices';
import { BackOfficeCompanyDTO, BackOfficeUserDTO, BackOfficeResellerDTO } from '../../../services/types/BoApiTypes';
import { BaseSearchWithTableFilters, PagedListContainerOf, Restriction, SearchType, SortDirection, SortItem, UserSettingName } from '../../../services/types/ApiTypes';
import { GlobalState } from '../../../rootReducer';
import { TableFilter } from '../../../components/Table/components/filter/TableFilters';
import { toggleSortDirection, validateAndFixPagingOptions, validateAndFixSortItems } from '../../../common/utils/baseSearchHelpers';
import { notify } from '../../../common/utils/notify';
import { updateBoUserSettingsAction } from '../../../common/middlewares/userSettings';

import { createRequest } from './boCompanyListHelpers';
import { getViewBoUserSearchParams, ListViewUserConfig } from '../common/boUserSettingUtil';
import { getBoCurrentUser } from '../common/BoUserActions';
import { selectBoCompanyListSearchParams } from './BOCompanyListViewReducer';
import { formatDate } from '../../../common/utils/formatters';
import { saveAs } from 'file-saver';

const ns = 'back-office-company-list/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.BACK_OFFICE_COMPANIES_SORT_DIRECTION,
    sortCol: UserSettingName.BACK_OFFICE_COMPANIES_SORT_COLUMN,
    pageSize: UserSettingName.BACK_OFFICE_COMPANIES_PAGE_SIZE,
    filters: UserSettingName.BACK_OFFICE_COMPANIES_FILTERS,
};

export type BoCompanyListSearchParams = BaseSearchWithTableFilters<Array<TableFilter<any>>, BackOfficeCompanyDTO>;

export const DEFAULT_RESTRICTION = 'GeneralSearch';

export const DEFAULT_SORTING: SortItem = {
    SortColumn: 'CompanyName',
    SortDirection: SortDirection.Asc,
};

export const getBoCompanyListLoadable = loadableDataActionsWithRequest<BoCompanyListSearchParams, PagedListContainerOf<BackOfficeCompanyDTO>>(`${ns}ALL_BO_COMPANIES`);
export const exportBoCompaniesToCsvActions = loadableDataActions(`${ns}EXPORT_BO_COMPANIES_TO_CSV`);
export const exportBoCompaniesToXlsActions = loadableDataActions(`${ns}EXPORT_BO_COMPANIES_TO_XLS`);

export const getBoCompanyList = (searchParams: BoCompanyListSearchParams | undefined) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().boUser.boCurrentUserLoadable.payload)) {
            await dispatch(getBoCurrentUser());
        }
        const {
            boCompanyManagement,
            boUser: {
                boCurrentUserLoadable: { payload: currentUser },
            },
        } = getState();

        searchParams = searchParams || boCompanyManagement.searchParams || createRequest('', 1, 15, DEFAULT_RESTRICTION);
        const viewSearchParams = getViewBoUserSearchParams(searchParams, listViewConfig, currentUser);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, DEFAULT_RESTRICTION);

        const apiSearchParams = {
            ...searchParams,
            ...viewSearchParams,
        };

        delete apiSearchParams.filters;
        let response;
        try {
            dispatch(getBoCompanyListLoadable.request(null));
            response = await api.boCompanies.getCompanies(apiSearchParams);
            dispatch(
                getBoCompanyListLoadable.success({
                    result: response.data,
                    request: searchParams,
                }),
            );
        } catch (e) {
            dispatch(
                getBoCompanyListLoadable.error({
                    request: searchParams,
                    result: e,
                }),
            );
        }
    };
};

export const setPagingOptions = (page: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { boCompanyManagement } = getState();
        const paging = boCompanyManagement.searchParams.PagingOptions;
        const searchParams: BoCompanyListSearchParams = {
            ...boCompanyManagement.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        if (pageSize) {
            await dispatch(
                updateBoUserSettingsAction({
                    listViewConfig,
                    searchParams,
                }),
            );
        }
        dispatch(
            getBoCompanyList({
                ...searchParams,
                PagingOptions: {
                    Page: page || searchParams.PagingOptions.Page,
                    Count: pageSize || searchParams.PagingOptions.Count,
                },
            }),
        );
    };
};

export const searchBoCompanies = (searchString: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const { boCompanyManagement } = getState();
            const paging = boCompanyManagement.searchParams.PagingOptions;
            const searchRestriction = boCompanyManagement.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
            const otherRestrictions = boCompanyManagement.searchParams.Restrictions.filter((r) => r.Field !== DEFAULT_RESTRICTION);
            let restrictions: Restriction[] = [];
            if (!searchRestriction || !searchString) {
                restrictions.push({
                    Field: DEFAULT_RESTRICTION,
                    Value: searchString || '',
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                });
            } else {
                restrictions.push({
                    Field: DEFAULT_RESTRICTION,
                    Value: searchString || searchRestriction.Value || '',
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                });
            }
            restrictions = [...restrictions, ...otherRestrictions];
            const searchParams: BoCompanyListSearchParams = {
                ...boCompanyManagement.searchParams,
                PagingOptions: {
                    ...paging,
                    Page: 1, //reset to first page when searching
                },
                Restrictions: [...restrictions],
            };
            dispatch(getBoCompanyList(searchParams));
        } catch (e) {
            console.error(e);
        }
    };
};

export const sortBoCompanies = (column: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const { boCompanyManagement } = getState();
            const sorting = boCompanyManagement.searchParams.SortItems[0] || DEFAULT_SORTING;

            const newSearchParams: BoCompanyListSearchParams = {
                ...boCompanyManagement.searchParams,
                SortItems: [
                    {
                        SortColumn: column,
                        SortDirection: toggleSortDirection(sorting, column),
                    },
                ],
            };

            await dispatch(
                updateBoUserSettingsAction({
                    listViewConfig,
                    searchParams: newSearchParams,
                }),
            );
            await dispatch(getBoCompanyList(newSearchParams));
        } catch (e) {
            console.error(e);
        }
    };
};

export const filterBoCompanies = (restriction?: Restriction, filter?: TableFilter<any>) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { boCompanyManagement } = getState();
        const paging = boCompanyManagement.searchParams.PagingOptions;
        // keep the old restrictions when we filter
        const searchRestriction = boCompanyManagement.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        const filteredRestrictions = boCompanyManagement.searchParams.Restrictions.filter((r) => r.Field !== DEFAULT_RESTRICTION).filter((r) => {
            if (r.Field === restriction.Field) {
                if (isEmpty(restriction.Value) && isEmpty(restriction.Values)) {
                    return false;
                }
                return !!restriction.Values || restriction.Value !== null;
            }
            return r.Value !== null || !!r.Values;
        });
        const restrictions: Restriction[] = [];
        if (searchRestriction) {
            restrictions.push(searchRestriction);
        }
        filteredRestrictions.forEach((r) => {
            if (r.Field === restriction.Field) {
                restrictions.push(restriction);
            } else {
                restrictions.push(r);
            }
        });
        if (!!restriction && !restrictions.includes(restriction) && (restriction.Value !== null || !isEmpty(restriction.Values))) {
            restrictions.push(restriction);
        }
        if (restrictions.find((r) => r.Values === null && r.Value === null)) {
            console.error('Somehow invalid restriction got through! Do not send request!', restrictions, restriction, filter);
            return;
        }

        const searchParams: BoCompanyListSearchParams = {
            ...boCompanyManagement.searchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: restrictions,
            filters: {
                ...boCompanyManagement.searchParams.filters,
                [restriction.Field]: filter,
            },
        };

        await dispatch(
            updateBoUserSettingsAction({
                searchParams,
                listViewConfig,
            }),
        );
        dispatch(getBoCompanyList(searchParams));
    };
};

export const applySavedBoCompanyFilters = (restrictions: Restriction[], filters: TableFilter<any>[]) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { boCompanyManagement } = getState();
        const filtersToApply = {};
        filters.map((f) => (filtersToApply[f.columnName] = f));
        const searchParams: BoCompanyListSearchParams = {
            ...boCompanyManagement.searchParams,
            PagingOptions: {
                Count: boCompanyManagement.searchParams.PagingOptions.Count,
                Page: 1, // reset to first page when searching
            },
            Restrictions: restrictions,
            filters: filtersToApply,
        };
        dispatch(getBoCompanyList(searchParams));
    };
};

export const addOrEditBoCompanyAction = loadableDataActions<BackOfficeCompanyDTO, BackOfficeCompanyDTO>(`${ns}ADD_OR_EDIT_BO_COMPANY`);

export const addOrEditBoCompany = (company: BackOfficeCompanyDTO) => {
    // We don't need to send Reseller Companies while updating a company (https://fitekgroup.atlassian.net/browse/EMR-4987)
    const companyUpdated: BackOfficeCompanyDTO = company.Reseller ? { ...company, Reseller: { ...company.Reseller, Companies: null } as BackOfficeResellerDTO } : company;
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(addOrEditBoCompanyAction.request(companyUpdated));
            const updatedCompany = await api.boCompanies.saveCompany(companyUpdated);
            dispatch(addOrEditBoCompanyAction.success(updatedCompany.data));
            notify.success(i18n.t('view.backOffice.company.save.success'));
        } catch (e) {
            console.error(e);
            dispatch(addOrEditBoCompanyAction.error(e));
            notify.error(i18n.t('view.backOffice.company.save.error'));
        }
    };
};

export const addUserToCompanyLoadableAction = loadableDataActions<BackOfficeCompanyDTO, BackOfficeUserDTO>(`${ns}ADD_USER_TO_COMPANY`);

export function addUserToCompany(company: BackOfficeCompanyDTO) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const {
                user: { boCurrentUserLoadable },
            } = getState();
            dispatch(addUserToCompanyLoadableAction.request(company));
            const response = await api.boUserCompanies.addUserToCompany(boCurrentUserLoadable.payload.UserGuid, company.CompanyGuid);
            dispatch(addUserToCompanyLoadableAction.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(addUserToCompanyLoadableAction.error(e));
        }
    };
}

export const deleteBoCompanyAction = loadableDataActions<string, boolean>(`${ns}DELETE_BO_COMPANY`);

export function deleteBoCompany(companyGuid: string) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            dispatch(deleteBoCompanyAction.request(companyGuid));
            const response = await api.boCompanies.deleteCompany(companyGuid);
            dispatch(deleteBoCompanyAction.success(response.data));
            dispatch(getBoCompanyList(getState().boCompanyManagement.searchParams));
        } catch (e) {
            console.error(e);
        }
    };
}

export function exportBoCompaniesToCsv() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().boUser.boCurrentUserLoadable.payload)) {
            await dispatch(getBoCurrentUser());
        }
        const {
            boCompanyManagement,
            boUser: {
                boCurrentUserLoadable: { payload: currentUser },
            },
        } = getState();

        dispatch(exportBoCompaniesToCsvActions.request(undefined));
        let searchParams = selectBoCompanyListSearchParams(getState());

        searchParams = searchParams || boCompanyManagement.searchParams || createRequest('', 1, 15, DEFAULT_RESTRICTION);
        const viewSearchParams = getViewBoUserSearchParams(searchParams, listViewConfig, currentUser);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, DEFAULT_RESTRICTION);

        const apiSearchParams = {
            ...searchParams,
            ...viewSearchParams,
        };
        try {
            const response = await api.boCompanies.exportCompaniesToCsv(apiSearchParams);
            const fileName = `Bo_Companies_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.csv`;
            const file = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            saveAs(file, fileName);
            dispatch(exportBoCompaniesToCsvActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportBoCompaniesToCsvActions.error(e));
        }
    };
}

export function exportBoCompaniesToXls() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().boUser.boCurrentUserLoadable.payload)) {
            await dispatch(getBoCurrentUser());
        }
        const {
            boCompanyManagement,
            boUser: {
                boCurrentUserLoadable: { payload: currentUser },
            },
        } = getState();

        dispatch(exportBoCompaniesToXlsActions.request(undefined));
        let searchParams = selectBoCompanyListSearchParams(getState());

        searchParams = searchParams || boCompanyManagement.searchParams || createRequest('', 1, 15, DEFAULT_RESTRICTION);
        const viewSearchParams = getViewBoUserSearchParams(searchParams, listViewConfig, currentUser);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, DEFAULT_RESTRICTION);

        const apiSearchParams = {
            ...searchParams,
            ...viewSearchParams,
        };
        try {
            const response = await api.boCompanies.exportCompaniesToXls(apiSearchParams);
            const fileName = `Bo_Companies_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`;
            const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            saveAs(file, fileName);
            dispatch(exportBoCompaniesToXlsActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportBoCompaniesToXlsActions.error(e));
        }
    };
}
