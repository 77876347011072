import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GlobalState } from '../../rootReducer';

import { ChangeLanguage, DispatchProps, Props } from './ChangeLanguage';
import { setCurrentLanguage, showChangeLanguage } from './ChangeLanguageActions';
import { selectIsModalOpen, selectCurrentLanguage } from './ChangeLanguageReducer';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        isModalOpen: selectIsModalOpen(state),
        currentLanguage: selectCurrentLanguage(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    showChangeLanguage,
    changeLanguage: setCurrentLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChangeLanguage));
