import { ReducerFactory } from 'redux-actions-ts-reducer';

import { CompanyDTO, GroupMemberDTO, GroupMemberMicroDTO, SystemConfigurationDTO } from '../../services/types/ApiTypes';

import {
    getCompanyGroupMembersActions,
    setCurrentCompanySettings,
    getCompanyData,
    setCurrentCompanySettingsLoading,
    setGroupMembersAction,
    updateGroupMember,
    updateCurrentCompanySettings,
    getGroupMembersMicroActions,
} from './CompanyActions';

class State {
    groupMembers: GroupMemberDTO[] = [];
    groupMembersMicro: GroupMemberMicroDTO[] = [];
    currentCompanySettings: SystemConfigurationDTO[] = [];
    isCurrentCompanySettingsLoading = false;
    currentCompanyData: CompanyDTO;
}

export default new ReducerFactory(new State())
    .addReducer(
        setGroupMembersAction,
        (state, action): State => {
            return {
                ...state,
                groupMembers: action.payload,
            };
        },
    )
    .addReducer(
        setCurrentCompanySettings,
        (state, action): State => {
            return {
                ...state,
                currentCompanySettings: action.payload,
            };
        },
    )
    .addReducer(
        setCurrentCompanySettingsLoading,
        (state, action): State => {
            return {
                ...state,
                isCurrentCompanySettingsLoading: action.payload,
            };
        },
    )
    .addReducer(
        updateGroupMember.request,
        (state, action): State => {
            const groupMembers = state.groupMembers.map((gm) => (gm.Id === action.payload.Id ? action.payload : gm));
            return {
                ...state,
                groupMembers: [...groupMembers],
            };
        },
    )
    .addReducer(
        getCompanyData.success,
        (state, action): State => {
            return {
                ...state,
                currentCompanyData: action.payload,
            };
        },
    )
    .addReducer(
        getCompanyGroupMembersActions.success,
        (state, action): State => {
            return {
                ...state,
                groupMembers: action.payload,
            };
        },
    )
    .addReducer(
        updateCurrentCompanySettings.success,
        (state, action): State => {
            const companySettings = state.currentCompanySettings.map((ccs) => {
                const index = action.payload.findIndex((cs) => cs.Name === ccs.Name);
                return index !== -1 ? action.payload[index] : ccs;
            });
            return {
                ...state,
                currentCompanySettings: [...companySettings],
            };
        },
    )
    .addReducer(
        getGroupMembersMicroActions.success,
        (state, action): State => {
            return {
                ...state,
                groupMembersMicro: action.payload,
            };
        },
    )
    .toReducer();

export { State as CompanyState };
