import constants from '../../common/constants/appConstants';
import { BackOfficeUserDTO } from '../../services/types/BoApiTypes';

const checkAccessToBO = (boUser: BackOfficeUserDTO) => {
    const role = boUser?.Role;
    if (!role) {
        return false;
    }
    return constants.backOfficeUserAuthorizationRoles.includes(role);
};

export default checkAccessToBO;
