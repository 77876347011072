import classNames from 'classnames';
import * as React from 'react';
import { cloneElement, ReactElement } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import Collapsible from 'react-collapsible';

import { ICONS } from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import { Button, ButtonIconPlacement, ButtonType } from '../Buttons/Button';
import { IsDraggingContext } from './Table';

import './Table.scss';

export interface CollapseRowsDataObject {
    isAllCollapsed: boolean;
    expanedRowsCount: number;
}

export interface TableRowProps extends React.HTMLProps<HTMLTableRowElement> {
    className?: string;
    children?: React.ReactNode;
    provided?: DraggableProvided;
    snapshot?: DraggableStateSnapshot;
    id?: any;
    /**
     * true by default, use false if some row is an exception
     */
    draggable?: boolean;
    isRowSelected?: boolean;
    collapsable?: boolean;
    collapseContent?: React.ReactNode;
    isCollapsed?: boolean;
    toggleCollapse?: (id: number) => void;
    disabled?: boolean;
    isEditting?: boolean;
    fullScreenElementId?: string;
    collapseButtonTooltipContent?: string;
}
function TableRow(props: TableRowProps) {
    const {
        className,
        children,
        snapshot,
        provided,
        draggable,
        isRowSelected,
        collapseContent,
        collapsable,
        disabled,
        isEditting,
        isCollapsed,
        toggleCollapse,
        id,
        fullScreenElementId,
        collapseButtonTooltipContent,
        ...rest
    } = props;
    const isCurrentRowDragged = snapshot && snapshot.isDragging;
    const classes = classNames(
        'data-table__row',
        className,
        { 'data-table__row--is-dragged': isCurrentRowDragged },
        { 'data-table__row--is-selected': isRowSelected },
        { 'data-table__row--is-disabled': disabled },
        { 'data-table__row--is-editting': isEditting },
    );

    const draggableProps = provided && draggable ? provided.draggableProps : undefined;
    const dragHandleProps = provided && draggable ? provided.dragHandleProps : undefined;

    if (!draggable || !provided) {
        // no dragging functionality
        return (
            <>
                <tr className={classes} {...rest}>
                    {children}
                    {collapsable && (
                        <td className="data-table__cell-collpase">
                            <Tooltip appendTo={document.getElementById(fullScreenElementId)} content={collapseButtonTooltipContent} isEnabled={true}>
                                <div>
                                    <Button
                                        iconPlacement={ButtonIconPlacement.LEFT}
                                        onClick={() => {
                                            toggleCollapse(id);
                                        }}
                                        icon={ICONS.CHEVRON_DOWN_24}
                                        className={classNames('data-table__row-icon', { 'data-table__row-icon--collapsed': isCollapsed })}
                                        buttonType={ButtonType.ICON}
                                        disabled={!collapseContent}
                                    />
                                </div>
                            </Tooltip>
                        </td>
                    )}
                </tr>
                {collapsable && (
                    <tr>
                        <td colSpan={12}>
                            <Collapsible
                                easing="ease-in-out"
                                open={isCollapsed}
                                lazyRender={true}
                                triggerDisabled={true}
                                triggerClassName={'toggle-content__trigger'}
                                triggerOpenedClassName={'toggle-content__trigger toggle-content__trigger--open'}
                                transitionTime={150}
                                trigger={null}
                                overflowWhenOpen={'unset'}
                            >
                                {collapseContent}
                            </Collapsible>
                        </td>
                    </tr>
                )}
            </>
        );
    }

    return (
        <IsDraggingContext.Consumer>
            {(isAnyRowDragged: boolean) => (
                <tr className={classes} {...rest} {...draggableProps} ref={provided ? provided.innerRef : undefined}>
                    {React.Children.map(children, (e: ReactElement<any, any>, i) => {
                        return cloneElement(e, { isDragOccurring: isAnyRowDragged, dragHandleProps: i === 0 ? dragHandleProps : undefined });
                    })}
                </tr>
            )}
        </IsDraggingContext.Consumer>
    );
}

TableRow.defaultProps = {
    draggable: true,
};

export default TableRow;
