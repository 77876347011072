import { connect } from '../../storeConfig';
import { GlobalState } from '../../rootReducer';
import { updateSubstitutes } from './OutOfOfficeActions';
import OutOfOffice, { DispatchProps, Props } from './OutOfOffice';
import { selectGroupMemberCommonLoadable } from '../../common/user/UserReducer';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        groupMemberCommonLoadable: selectGroupMemberCommonLoadable(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    updateSubstitutes,
};

export default connect(mapStateToProps, mapDispatchToProps)(OutOfOffice);
