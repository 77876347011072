import { InvoiceStatus } from "../../../src/common/constants/appConstants";
import { setPdfToOpenAction } from '../../../src/components/PDFViewer/FileViewActions';

(function () {
  "use strict";

  angular
    .module("dstreamApp.views.detailed-transactions")
    .component("dsDetailedTransactions", {
      templateUrl: "app/views/detailed-transactions/detailed-transactions.html",
      controller: DetailedTransactions
    });

  DetailedTransactions.$inject = [
    "constants",
    "$rootScope",
    "webServices",
    "$routeParams",
    "$location",
    "$filter",
    "localStorageService",
    "invoiceService",
    "activeInvoiceService",
    "$ngRedux"
  ];

  function DetailedTransactions (
    constants,
    $rootScope,
    webServices,
    $routeParams,
    $location,
    $filter,
    localStorageService,
    invoiceService,
    activeInvoiceService,
    $ngRedux
  ) {
    var ctrl = this;
    ctrl.activeTab = "pdf";
    ctrl.splitPaneProperties = {};
    ctrl.invoice = null;
    ctrl.FileUrl = constants.FileUrl;
    ctrl.DownloadUrl = constants.DownloadUrl;
    ctrl.invoiceTransactionRowsPaginator = {
      page: 1,
      count: 5,
      total: 0
    };

    function openPdfIfExists () {
      if (ctrl.activeTab === 'pdf' && ctrl.invoice.DocumentFiles && ctrl.invoice.DocumentFiles.length) {
        // open up first pdf file
        var firstPdf = _.find(ctrl.invoice.DocumentFiles, function (doc) {return doc.IsPdf && doc.IsOriginal;});

        if (firstPdf) {
          $rootScope.$emit("file.open", firstPdf);
          firstPdf.FileUrl = ctrl.FileUrl + firstPdf.Id;
          firstPdf.DownloadUrl = ctrl.FileUrl + firstPdf.Id;
          $ngRedux.dispatch(setPdfToOpenAction(firstPdf));
        }
      }
    }

    ctrl.$onInit = function () {
      activeInvoiceService.activeInvoice($routeParams.id).then(
        function (response) {
            const invalidInvoiceStatuses = [
                InvoiceStatus.PendingExport,
                InvoiceStatus.Exported,
                InvoiceStatus.Deleted,
                InvoiceStatus.Archived,
                InvoiceStatus.NotForExport,
            ];
          if (!response) {
              // if we didn't receive invoice data, redirect to dashboard
            $location.path("/dashboard");
          } else if (invalidInvoiceStatuses.includes(response.Status)) {
              // if received invoice cannot be edited, redirect to invoice detail view
            $location.path("/invoiceconfirmation/" + response.Id);
          } else {
            ctrl.invoice = response;
          }
          openPdfIfExists();
        }
      );
      const warningElement = document.getElementById('top-warning');
      if (warningElement) {
        ctrl.isTopOffset = true;
      }
    };

    /*
        Change invoice information tab
     */
    ctrl.changeTab = function (tab) {
      ctrl.activeTab = tab;
      openPdfIfExists();
    };
  }
})();