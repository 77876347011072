angular.module('dstreamApp.controllers').controller('invoiceCustomFieldsListController', [
    '$scope', '$uibModal', 'webServices', '$filter', 'ngTableParams', 'companyDataService', function ($scope, $uibModal, webServices, $filter, ngTableParams, companyDataService) {

        $scope.fields = [];
        $scope.tableParams = new ngTableParams({
            page: 1,
            count: 20,
            sorting: {
                Name: 'asc'
            }
        }, {
            total: 0,
            getData: function ($defer, params) {
                $scope.loading = true;
                companyDataService.getCurrentCompanyInvoiceCustomFields(true).then(function (response) {
                    if (response.data.length < 10) {
                      params.settings().counts = 0;
                    }
                    $scope.fields = response.data;
                    $scope.loading = false;
                    $defer.resolve($scope.fields);
                }, function (data) {
                    console.log(data);
                    $scope.loading = false;
                });
            }
        });

        $scope.fieldTypeRanges = {
            0: $filter('translate')("views.InvoiceCustomFields.Text"),
            1: $filter('translate')("views.InvoiceCustomFields.List"),
            2: $filter('translate')("views.InvoiceCustomFields.DateTime")
        };

        $scope.showFieldTypeName = function (field) {
            return $scope.fieldTypeRanges[field.FieldType];
        };


        $scope.deleteCustomField = function (field) {
            webServices.deleteInvoiceCustomField(field).then(function (response) {
                $scope.tableParams.reload();

            }, function (data) {
                console.log(data);

            });
        };

        $scope.editCustomField = function (field) {
            openCustomField(field);
        };


        $scope.addCustomField = function () {
            openCustomField({ InvoiceCustomFieldItems: [] });
        };

        function openCustomField(field) {

            $uibModal.open({
                templateUrl: 'app/components/invoiceCustomFields/InvoiceCustomFieldsDetail.html',
                controller: 'invoiceCustomFieldsDetailController',
                backdrop: 'static',
                resolve: {
                    field: function () {
                        return field;
                    }
                }

            });
        }


    }
]);
