import { getViewUserSearchParams, ListViewUserConfig } from '../../../common/user/userSettingUtil';
import { FileReaderResult } from '../../../common/utils/fileReader';
import { loadableDataActionsWithRequest, loadableDataActions } from '../../../common/utils/LoadableData';
import { notify } from '../../../common/utils/notify';
import i18nInstance from '../../../i18n';
import { GlobalState } from '../../../rootReducer';
import api from '../../../services/ApiServices';
import { BaseSearch, SupplierDTO, FileDTO, PagedListContainer, Restriction, SearchType, SortDirection, UserSettingName } from '../../../services/types/ApiTypes';
import { DispatchThunk } from '../../../storeConfig';
import { DEFAULT_RESTRICTION, selectSuppliersSearchParams } from './SuppliersViewReducer';
import { getCurrentUserGroupMember } from '../../../common/user/UserActions';
import { isEmpty } from 'lodash-es';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../common/utils/baseSearchHelpers';
import { updateAppUserSettingsAction } from '../../../common/middlewares/userSettings';
import { formatDate } from '../../../common/utils/formatters';
import { saveAs } from 'file-saver';

const ns = 'settings/suppliers/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.SETTINGS_SUPPLIERS_SORT_DIRECTION,
    sortCol: UserSettingName.SETTINGS_SUPPLIERS_SORT_COLUMN,
    pageSize: UserSettingName.SETTINGS_SUPPLIERS_PAGE_SIZE,
};

export const loadSuppliersLoadableActions = loadableDataActionsWithRequest<BaseSearch, PagedListContainer<SupplierDTO>>(`${ns}LOAD_SUPPLIERS`);
export const exportSuppliersToCsvActions = loadableDataActions(`${ns}EXPORT_SUPPLIERS_TO_CSV`);
export const exportSuppliersToXlsActions = loadableDataActions(`${ns}EXPORT_SUPPLIERS_TO_XLS`);

export function searchSuppliers(searchParams?: BaseSearch | undefined) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const suppliersSearchParams = selectSuppliersSearchParams(getState());
        searchParams = searchParams || suppliersSearchParams;

        const groupMember = getState().user.groupMemberCommonLoadable.payload;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Name', SortDirection.Asc);

        searchParams = { ...searchParams, ...viewSearchParams };

        let response;
        try {
            dispatch(loadSuppliersLoadableActions.request(searchParams));
            response = await api.suppliers.getSuppliers(searchParams);
            dispatch(
                loadSuppliersLoadableActions.success({
                    request: searchParams,
                    result: response.data,
                }),
            );

            // if our page for some reason is empty, but there is data on previous pages, then load previous pages until we have some data to display
            if (response.data.Items.length === 0 && response.data.HasCount) {
                const currentPage = suppliersSearchParams.PagingOptions.Page;
                if (currentPage > 1) {
                    return dispatch(setPagingOptions(currentPage - 1));
                }
            }
            return Promise.resolve();
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            dispatch(
                loadSuppliersLoadableActions.error({
                    request: searchParams,
                    result: e,
                }),
            );
            return Promise.resolve();
        }
    };
}

export const filterSuppliers = (searchString: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        let searchParams = selectSuppliersSearchParams(state);
        const paging = searchParams.PagingOptions;

        // taking default restriction - GeneralSearch - from reducer
        let searchRestriction: Restriction = searchParams.Restrictions.find((restriction) => restriction.Field === DEFAULT_RESTRICTION);
        const otherRestrictions = searchParams.Restrictions.filter((r) => {
            return r.Field !== DEFAULT_RESTRICTION && r.hasOwnProperty('Value') && r.hasOwnProperty('Values') && r.hasOwnProperty('FieldSearchType');
        });
        if (!searchRestriction) {
            searchRestriction = {
                Field: DEFAULT_RESTRICTION,
                Value: searchString || '',
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            };
        } else {
            searchRestriction = {
                ...searchRestriction,
                Value: searchString || '',
            };
        }
        let restrictions: Restriction[] = [];
        restrictions.push(searchRestriction);
        if (otherRestrictions.length > 0) {
            restrictions = [...restrictions, ...otherRestrictions];
        }

        searchParams = {
            ...searchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: restrictions,
        };
        dispatch(searchSuppliers(searchParams));
    };
};

export const sortSuppliers = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        let searchParams = selectSuppliersSearchParams(getState());
        const sorting = searchParams.SortItems[0];

        searchParams = {
            ...searchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };
        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        dispatch(searchSuppliers(searchParams));
    };
};

export const deleteSupplier = (id: number) => {
    return async (dispatch: DispatchThunk) => {
        try {
            const { data } = await api.suppliers.deleteSupplier(id);
            if (data?.Success) {
                dispatch(searchSuppliers());
                notify.success(i18nInstance.t(data?.Message || 'view.Suppliers.Success.Deleted'));
            } else {
                notify.error(i18nInstance.t(data?.Message || 'interceptorsFactory.ErrorWhileProcessingData'));
            }
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export function uploadCSV(fileReaderResult: FileReaderResult) {
    return async (dispatch: DispatchThunk) => {
        try {
            const file: FileDTO = {
                Base64Content: fileReaderResult.base64Content,
                FileName: fileReaderResult.fileName,
                WorkflowDocumentId: undefined,
                DownloadUrl: undefined,
                FileUrl: undefined,
                ForceImport: undefined,
                HubType: undefined,
                Id: undefined,
                IsImported: undefined,
                IsNew: undefined,
                IsPdf: undefined,
                PathToFile: undefined,
            };

            const response = await api.suppliers.uploadCSVFile(file, false);
            if (response.data) {
                notify.success(i18nInstance.t('views.company.list.importSuccessText'), i18nInstance.t('views.company.list.importSuccessTitle'));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileSendingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
            dispatch(setPagingOptions(1));
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileSendingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
}

export function setPagingOptions(page?: number, pageSize?: number) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState): Promise<any> => {
        let searchParams = selectSuppliersSearchParams(getState());
        const paging = searchParams.PagingOptions;

        searchParams = {
            ...searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        return dispatch(searchSuppliers(searchParams));
    };
}

export function exportSuppliersToCsv() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        dispatch(exportSuppliersToCsvActions.request(undefined));
        let suppliersSearchParams = selectSuppliersSearchParams(getState());
        const groupMember = getState().user.groupMemberCommonLoadable.payload;
        const viewSearchParams = getViewUserSearchParams(suppliersSearchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Name', SortDirection.Asc);
        suppliersSearchParams = { ...suppliersSearchParams, ...viewSearchParams };
        try {
            const response = await api.suppliers.exportSuppliersToCsv(suppliersSearchParams);
            const fileName = `Suppliers_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.csv`;
            const file = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            saveAs(file, fileName);
            dispatch(exportSuppliersToCsvActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportSuppliersToCsvActions.error(e));
        }
    };
}

export function exportSuppliersToXls() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        dispatch(exportSuppliersToXlsActions.request(undefined));
        let suppliersSearchParams = selectSuppliersSearchParams(getState());
        const groupMember = getState().user.groupMemberCommonLoadable.payload;
        const viewSearchParams = getViewUserSearchParams(suppliersSearchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Name', SortDirection.Asc);
        suppliersSearchParams = { ...suppliersSearchParams, ...viewSearchParams };
        try {
            const response = await api.suppliers.exportSuppliersToXls(suppliersSearchParams);
            const fileName = `Suppliers_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`;
            const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            saveAs(file, fileName);
            dispatch(exportSuppliersToXlsActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportSuppliersToXlsActions.error(e));
        }
    };
}
