'use strict';

angular
    .module('dstreamApp.components')
    .controller('DsDeleteInvoiceModal', function($rootScope, $scope, $filter, $uibModalInstance, webServices, invoice) {
        var ctrl = this;
        $scope.invoice = invoice;
        $scope.closeModal = closeModal;

        $scope.delete = deleteInvoice;
        ctrl.reloadInvoiceData = $scope.$parent.$parent.reloadInvoiceData;
        function deleteInvoice() {
            if ($scope.invoice && $scope.invoice.Id) {
                webServices.setInvoiceDeleted($scope.invoice.Id).then(function (result) {
                    ctrl.reloadInvoiceData($scope.invoice.Id);
                    closeModal();
                }).catch(function () {
                    notifyService.error('controller.dashboardController.Error', 'controller.invoiceConfirmationController.Error', true);
                });
            }
        }

        function closeModal(result) {
            $uibModalInstance.close(true);
        }
    });