"use strict";
angular.module("dstreamApp").factory("confirmationService", [
  "webServices",
  "notifyService",
  "$rootScope",
  function (webServices, notifyService, $rootScope) {
    var confirmationService = {};

    /*
        Replace tasks in excisting workflow with edited tasks
     */
    confirmationService.replaceTasksInWorkflow = function (tasksObject, invoice, confirmFirst) {
      // create correct workflow object
      var workFlowObject = {
        Id: invoice ? invoice.WorkflowId : null,
        InvoiceId: invoice ? invoice.Id : null,
        Tasks: []
      };

      // build correct tasks array
      angular.forEach(tasksObject, function (taskObject) {
        angular.forEach(taskObject.tasks, function (task) {
          workFlowObject.Tasks.push(task);
        });
      });

      return webServices.replaceTasks(workFlowObject, confirmFirst).then(function (result) {
        // we don't need to do anything with the result in the service
        return result;
      }).catch(function () {
        notifyService.error("component.confirmerFlow.errorInWorkflow", "component.confirmerFlow.errorInRequest", true);
      });
    };
    
    confirmationService.isFinalConfirmation = function isFinalConfirmation(invoice) {
        if (invoice.Workflow && invoice.Workflow.Tasks && invoice.Workflow.Tasks.length) {
            const lastTask = invoice.Workflow.Tasks[invoice.Workflow.Tasks.length - 1]
            return lastTask.GroupMember.Id === $rootScope.userData.GroupMemberId;
        }
        return false;
    }

    return confirmationService;
  }
]);