import { ReducerFactory } from 'redux-actions-ts-reducer';
import { LoadableData } from '../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../rootReducer';
import { HistoryDTO } from '../../../../services/types/ApiTypes';
import { getInvoiceHistoryLoadable } from './InvoiceHistoryActions';

class State {
    invoiceHistoryLoadable = new LoadableData<HistoryDTO[], string>();
}

export { State as InvoiceHistoryState };

export default new ReducerFactory(new State())
    .addReducer(
        getInvoiceHistoryLoadable.request,
        (state): State => {
            return {
                ...state,
                invoiceHistoryLoadable: state.invoiceHistoryLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        getInvoiceHistoryLoadable.success,
        (state, action): State => {
            return {
                ...state,
                invoiceHistoryLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getInvoiceHistoryLoadable.error,
        (state): State => {
            return {
                ...state,
                invoiceHistoryLoadable: LoadableData.payload(undefined),
            };
        },
    )
    .toReducer();

export const selectInvoiceHistory = (state: GlobalState) => state.invoiceHistory.invoiceHistoryLoadable;
