import { withTranslation } from 'react-i18next';
import withSuspense from '../../../../common/hocs/withSuspense';

import { GlobalState } from '../../../../rootReducer';
import { connect } from '../../../../storeConfig';

import InvoiceExportManagement, { DispatchProps } from './InvoiceExportManagement';
import { exportStatusReset, getInvoiceData, updateInvoiceExportState } from './InvoiceExportManagementActions';
import { selectInvoiceDataLoadable, selectInvoiceExportEnabled } from './InvoiceExportManagementReducers';

const mapStateToProps = (state: GlobalState) => {
    return {
        isExportEnabled: selectInvoiceExportEnabled(state),
        invoiceDataLoadable: selectInvoiceDataLoadable(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    getInvoiceData,
    exportStatusReset,
    updateInvoiceExportState,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(withTranslation()(InvoiceExportManagement)));
