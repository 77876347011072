'use strict';

/*
    Template service
    Define all custom global templates
    //TODO refactor all inline templates in components to this service
 */
angular.module("dstreamApp").factory('templateService', ['$templateCache', '$filter', function ($templateCache, $filter) {
    var templateService = {};

    /*
        Initialize all custom templates
     */
    templateService.init = function () {
        //bootstrap pagination
        $templateCache.put("uib/template/pagination/pagination.html",
            "<li role=\"menuitem\" ng-if=\"::boundaryLinks\" ng-class=\"{disabled: noPrevious()||ngDisabled}\" class=\"pagination-first\">" +
            "  <a href class=\"btn btn--sm btn--alt\" ng-click=\"selectPage(1, $event)\" ng-disabled=\"noPrevious()||ngDisabled\" uib-tabindex-toggle>{{getText('first')}}</a>" +
            "</li>\n" +
            "<li role=\"menuitem\" ng-if=\"::directionLinks\" ng-class=\"{disabled: noPrevious()||ngDisabled}\" class=\"pagination-prev\">" +
            "  <a href class=\"btn btn--sm btn--alt\" ng-click=\"selectPage(page - 1, $event)\" ng-disabled=\"noPrevious()||ngDisabled\" analytics-on=\"click\" analytics-event=\"Navigation\" analytics-properties=\"{ category: 'Pagination', label: 'Previous' }\" uib-tabindex-toggle>" +
            "    <svg class=\"icon\"><use xlink:href=\"#icon-arrow_left\" ></use></svg>" +
            "  </a>" +
            "</li>\n" +
            "<li role=\"menuitem\" ng-repeat=\"page in pages track by $index\" ng-class=\"{active: page.active,disabled: ngDisabled&&!page.active}\" class=\"pagination-page\">" +
            "  <a href class=\"btn btn--sm\" ng-click=\"selectPage(page.number, $event)\" ng-disabled=\"ngDisabled&&!page.active\" uib-tabindex-toggle>" +
            "    {{page.text}}" +
            "  </a>" +
            "</li>\n" +
            "<li role=\"menuitem\" ng-if=\"::directionLinks\" ng-class=\"{disabled: noNext()||ngDisabled}\" class=\"pagination-next\">" +
            "  <a href class=\"btn btn--sm btn--alt\" ng-click=\"selectPage(page + 1, $event)\" ng-disabled=\"noNext()||ngDisabled\" analytics-on=\"click\" analytics-event=\"Navigation\" analytics-properties=\"{ category: 'Pagination', label: 'Next' }\" uib-tabindex-toggle>" +
            "    <svg class=\"icon\"><use xlink:href=\"#icon-caret_right\" ></use></svg>" +
            "  </a>" +
            "</li>\n" +
            "<li role=\"menuitem\" ng-if=\"::boundaryLinks\" ng-class=\"{disabled: noNext()||ngDisabled}\" class=\"pagination-last\">" +
            "  <a href class=\"btn btn--sm btn--alt\" ng-click=\"selectPage(totalPages, $event)\" ng-disabled=\"noNext()||ngDisabled\" uib-tabindex-toggle>" +
            "    {{getText('last')}}" +
            "  </a>" +
            "</li>\n" +
            "");

        //bootstrap datepicker day
        $templateCache.put("uib/template/datepicker/day.html",
            "<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\">\n" +
            "  <thead>\n" +
            "    <tr>\n" +
            "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-left uib-left\" ng-click=\"move(-1)\" tabindex=\"-1\"><svg class=\"icon\"><use xlink:href=\"#icon-arrow_left\" ></use></svg><span class=\"sr-only\">previous</span></button></th>\n" +
            "      <th colspan=\"{{::5 + showWeeks}}\"><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" class=\"btn btn-default btn-sm uib-title\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\"><strong>{{('date.month.' + title.split(' ')[0] | translate) + ' ' + title.split(' ')[1]}}</strong></button></th>\n" +
            "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-right uib-right\" ng-click=\"move(1)\" tabindex=\"-1\"></i><span class=\"sr-only\">next</span><svg class=\"icon\"><use xlink:href=\"#icon-caret_right\" ></use></svg></button></th>\n" +
            "    </tr>\n" +
            "    <tr>\n" +
            "      <th ng-if=\"showWeeks\" class=\"text-center\"></th>\n" +
            "      <th ng-repeat=\"label in ::labels track by $index\" class=\"text-center\"><small aria-label=\"{{'date.day.' + label.abbr}}\" translate>{{'date.day.' + label.abbr}}</small></th>\n" +
            "    </tr>\n" +
            "  </thead>\n" +
            "  <tbody>\n" +
            "    <tr class=\"uib-weeks\" ng-repeat=\"row in rows track by $index\" role=\"row\">\n" +
            "      <td ng-if=\"showWeeks\" class=\"text-center h6\"><em>{{ weekNumbers[$index] }}</em></td>\n" +
            "      <td ng-repeat=\"dt in row\" class=\"uib-day text-center\" role=\"gridcell\"\n" +
            "        id=\"{{::dt.uid}}\"\n" +
            "        ng-class=\"::dt.customClass\">\n" +
            "        <button type=\"button\" class=\"btn btn-default btn-sm\"\n" +
            "          uib-is-class=\"\n" +
            "            'btn-info' for selectedDt,\n" +
            "            'active' for activeDt\n" +
            "            on dt\"\n" +
            "          ng-click=\"select(dt.date)\"\n" +
            "          ng-disabled=\"::dt.disabled\"\n" +
            "          tabindex=\"-1\"><span ng-class=\"::{'text-muted': dt.secondary, 'text-info': dt.current}\">{{::dt.label}}</span></button>\n" +
            "      </td>\n" +
            "    </tr>\n" +
            "  </tbody>\n" +
            "</table>\n" +
            "");

        //bootstrap datepicker month
        $templateCache.put("uib/template/datepicker/month.html",
            "<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\">\n" +
            "  <thead>\n" +
            "    <tr>\n" +
            "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-left uib-left\" ng-click=\"move(-1)\" tabindex=\"-1\"><svg class=\"icon\"><use xlink:href=\"#icon-arrow_left\" ></use></svg><span class=\"sr-only\">previous</span></button></th>\n" +
            "      <th colspan=\"{{::yearHeaderColspan}}\"><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" class=\"btn btn-default btn-sm uib-title\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\"><strong>{{title}}</strong></button></th>\n" +
            "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-right uib-right\" ng-click=\"move(1)\" tabindex=\"-1\"><span class=\"sr-only\">next</span><svg class=\"icon\"><use xlink:href=\"#icon-caret_right\" ></use></svg></button></th>\n" +
            "    </tr>\n" +
            "  </thead>\n" +
            "  <tbody>\n" +
            "    <tr class=\"uib-months\" ng-repeat=\"row in rows track by $index\" role=\"row\">\n" +
            "      <td ng-repeat=\"dt in row\" class=\"uib-month text-center\" role=\"gridcell\"\n" +
            "        id=\"{{::dt.uid}}\"\n" +
            "        ng-class=\"::dt.customClass\">\n" +
            "        <button type=\"button\" class=\"btn btn-default\"\n" +
            "          uib-is-class=\"\n" +
            "            'btn-info' for selectedDt,\n" +
            "            'active' for activeDt\n" +
            "            on dt\"\n" +
            "          ng-click=\"select(dt.date)\"\n" +
            "          ng-disabled=\"::dt.disabled\"\n" +
            "          tabindex=\"-1\"><span ng-class=\"::{'text-info': dt.current}\">{{'date.month.' + dt.label | translate}}</span></button>\n" +
            "      </td>\n" +
            "    </tr>\n" +
            "  </tbody>\n" +
            "</table>\n" +
            "");

        //bootstrap datepicker years
        $templateCache.put("uib/template/datepicker/year.html",
            "<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\">\n" +
            "  <thead>\n" +
            "    <tr>\n" +
            "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-left uib-left\" ng-click=\"move(-1)\" tabindex=\"-1\"><svg class=\"icon\"><use xlink:href=\"#icon-arrow_left\" ></use></svg><span class=\"sr-only\">previous</span></button></th>\n" +
            "      <th colspan=\"{{::columns - 2}}\"><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" class=\"btn btn-default btn-sm uib-title\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\"><strong>{{title}}</strong></button></th>\n" +
            "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-right uib-right\" ng-click=\"move(1)\" tabindex=\"-1\"><span class=\"sr-only\">next</span><svg class=\"icon\"><use xlink:href=\"#icon-caret_right\" ></use></svg></button></th>\n" +
            "    </tr>\n" +
            "  </thead>\n" +
            "  <tbody>\n" +
            "    <tr class=\"uib-years\" ng-repeat=\"row in rows track by $index\" role=\"row\">\n" +
            "      <td ng-repeat=\"dt in row\" class=\"uib-year text-center\" role=\"gridcell\"\n" +
            "        id=\"{{::dt.uid}}\"\n" +
            "        ng-class=\"::dt.customClass\">\n" +
            "        <button type=\"button\" class=\"btn btn-default\"\n" +
            "          uib-is-class=\"\n" +
            "            'btn-info' for selectedDt,\n" +
            "            'active' for activeDt\n" +
            "            on dt\"\n" +
            "          ng-click=\"select(dt.date)\"\n" +
            "          ng-disabled=\"::dt.disabled\"\n" +
            "          tabindex=\"-1\"><span ng-class=\"::{'text-info': dt.current}\">{{::dt.label}}</span></button>\n" +
            "      </td>\n" +
            "    </tr>\n" +
            "  </tbody>\n" +
            "</table>\n" +
            "");

        //invoice register save filter as template
        $templateCache.put("invoice-register-save-filter-template.html",
            "<div class=\"popover__inner-wrap\">\n" +
            "    <label>{{ 'component.invoicesTable.filterTemplateName' | translate }}</label>\n" +
            "    <input type=\"text\" class=\"input\" data-ng-model=\"$ctrl.saveFilterTemplatePopover.filterName\" data-uib-typeahead=\"filterTemplate.Name for filterTemplate in $ctrl.getFilterTemplatesByNamepart($viewValue, $item)\" data-typeahead-min-length=\"0\" data-typeahead-editable=\"true\" data-typeahead-focus-on-select=\"false\" data-typeahead-on-select=\"$ctrl.updateSelectedFilterTemplate($item); $event.stopPropagation();\">\n" +
            "    <span class=\"checkbox\">\n" +
            "        <input type=\"checkbox\" id=\"filter_checkbox_isDefault\" data-ng-model=\"$ctrl.saveFilterTemplatePopover.isDefault\">\n" +
            "        <label class=\"checkbox__label\" for=\"filter_checkbox_isDefault\">\n" +
            "            {{ 'component.invoicesTable.filterTemplateIsDefault' | translate }}\n" +
            "            <span class=\"checkbox__box\">\n" +
            "                <svg class=\"icon\"><use xlink:href=\"#icon-check\" ></use></svg>\n" +
            "            </span>\n" +
            "        </label>\n" +
            "    </span>\n" +
            "    <p class=\"action-wrap action-wrap--right\">\n" +
            "        <button class=\"btn btn--secondary btn--sm\" data-ng-click=\"$ctrl.saveFilterTemplatePopover.cancel()\">\n" +
            "            {{'views.global.Cancel' | translate}}\n" +
            "        </button>\n" +
            "        <button class=\"btn btn--primary btn--sm\" data-ng-click=\"$ctrl.saveFilterTemplatePopover.save()\">\n" +
            "            {{'views.global.save' | translate }}\n" +
            "        </button>\n" +
            "    </p>\n" +
            "</div>\n" +
            "");

        $templateCache.put("transaction-rows-import-xls-popover",
            "<div>" +
                "<p>{{'views.invoice.partials.accountingRows.importXls' | translate}}</p>" +
                "<p class=\"action-wrap action-wrap--right\">" +
                    "<button class=\"btn btn--secondary btn--sm\" " +
                            "data-ng-click=\"$ctrl.importXLSModalOpen = false;\">{{ 'views.global.Cancel' | translate }}" +
                    "</button>" +
                    "<button class=\"btn btn--primary btn--sm\" " +
                            "data-ng-click=\"$ctrl.openImportXLSModal()\">{{ 'views.global.Yes' | translate }}" +
                    "</button>" +
                "</p>" +
            "</div>"
        );

        // copy transaction row to rows below popover
        $templateCache.put("row-copy-template.html",
            "<div class=\"popover__inner-wrap\">\n" +
            "    <label>{{ 'component.transactionRows.confirmRewriteDescription2' | translate }}</label>\n" +
            "    <p class=\"action-wrap action-wrap--right\">\n" +
            "        <button data-ng-click=\"$ctrl.copyContentPopover.cancel(parentRow)\" class=\"btn btn--secondary btn--sm\">{{ 'views.global.No' | translate }}</button>\n" +
            "        <button data-ng-click=\"$ctrl.copyContentPopover.copyContent(parentRow);\" class=\"btn btn--primary btn--sm\" analytics-on=\"click\" analytics-event=\"Transaction rows\" analytics-properties=\"{ category: 'Invoice Details', label: 'Copy content' }\">{{ 'views.global.Yes' | translate }}</button>\n" +
            "    </p>\n" +
            "</div>\n" +
            "");

        $templateCache.put("row-delete-template.html",
            "<div class=\"popover__inner-wrap\">\n" +
            "    <label>{{ 'component.transactionRows.deleteRowConfirmation' | translate }}</label>\n" +
            "    <p class=\"action-wrap action-wrap--right\">\n" +
            "        <button data-ng-click=\"$ctrl.deleteRowPopover.cancel(parentRow)\" class=\"btn btn--secondary btn--sm\">{{ 'views.global.No' | translate }}</button>\n" +
            "        <button data-ng-click=\"$ctrl.deleteRowPopover.delete(parentRow);\" class=\"btn btn--primary btn--sm\" analytics-on=\"click\" analytics-event=\"Transaction rows\" analytics-properties=\"{ category: 'Invoice Details', label: 'Delete row' }\">{{ 'views.global.Yes' | translate }}</button>\n" +
            "    </p>\n" +
            "</div>\n" +
            "");

        // transaction rows combine popover
        $templateCache.put("transactionRows-combine-popover-template.html",
            "<div class=\"popover__inner-wrap\">" +
            "    <ul>\n" +
            "    <li> \n" +
            "        <button data-ng-click=\"$ctrl.mergeAllRows()\" " +
            "           class=\"btn btn--secondary btn--sm\" " +
            "           data-uib-tooltip=\"{{ 'component.transactionRows.combineAllRowsMessage' | translate}}\" " +
            "           data-tooltip-class=\"tooltip--mimic-popover tooltip--xs tooltip--arrow-md\" " +
            "           data-tooltip-enable=\"$ctrl.invoice.InvoiceAccountingRowsTotal < 2\" " +
            "           data-tooltip-placement=\"auto bottom-left\" " +
            "           data-tooltip-append-to-body=\"true\" " +
            "           data-tooltip-trigger=\"'outsideClick'\" " +
            "           analytics-on=\"click\"\n" +
            "           analytics-event=\"Transaction rows\"\n" +
            "           analytics-properties=\"{ category: 'Invoice Details', label: 'Combine all rows' }\">{{ 'component.transactionRows.combineAll' | translate }}</button>" +
            "    </li>\n" +
            "    </ul>\n" +
            "</div>\n" +
            "");

        // transaction rows additional settings
        $templateCache.put("transactionRows-additionalSettings-popover-template.html",
            "<div class=\"popover__inner-wrap\">" +
            "    <ul>\n" +
            "    <li> \n" +
            "        <button data-ng-click=\"$ctrl.createImportXlsPopover.confirm()\" " +
            "           class=\"btn btn--secondary btn--sm\" " +
            "           access-level=\"CanImportXLS\" >{{ 'component.transactionRows.importXLS' | translate }}</button>" +
            "    </li>\n" +
            "    </ul>\n" +
            "</div>\n" +
            "");


    };

    return templateService;
}
]);