import { LOCATION_CHANGE } from 'connected-react-router';
import { cloneDeep } from 'lodash-es';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reorder } from '../../../common/utils/reorder';
import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { ApiResultOfListOfCustomCostObjectiveDTO } from '../../../services/types/ApiTypes';

import { loadCostObjectivesLoadableActions, reorderCostObjectiveLoadableActions } from './AccountingViewActions';

class State {
    costObjectivesLoadable = new LoadableData<ApiResultOfListOfCustomCostObjectiveDTO>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        loadCostObjectivesLoadableActions.request,
        (state): State => {
            return {
                ...state,
                costObjectivesLoadable: LoadableData.loading(),
            };
        },
    )
    .addReducer(
        loadCostObjectivesLoadableActions.success,
        (state, action): State => {
            action.payload.Result = action.payload.Result.sort((a, b) => a.OrderNo - b.OrderNo);
            return {
                ...state,
                costObjectivesLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        loadCostObjectivesLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                costObjectivesLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        reorderCostObjectiveLoadableActions.request,
        (state, action): State => {
            const dragResult = action.payload;
            if (!dragResult.destination) {
                return state;
            }

            const newPayload = cloneDeep(state.costObjectivesLoadable.payload);
            const shiftResultsBy = 2; // because we have two static rows that are not re-orderable, shift the indexes;
            // (it might be react-beautiful-dnd bug that start index is shifted, but end index is not)
            const reorderedResult = reorder(newPayload.Result, dragResult.source.index - shiftResultsBy, dragResult.destination.index);
            newPayload.Result = reorderedResult;
            return {
                ...state,
                costObjectivesLoadable: LoadableData.payload(newPayload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'accounting',
    whitelist: [],
};

export default persistReducer(persistConfig, reducer);

export { State as AccountingViewState };

export const selectCostObjectivesLoadable = (state: GlobalState) => state.accounting.costObjectivesLoadable;
