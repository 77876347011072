import { withTranslation } from 'react-i18next';
import withSuspense from '../../../common/hocs/withSuspense';

import { GlobalState } from '../../../rootReducer';
import { connect } from '../../../storeConfig';

import { Props, DispatchProps, DashboardRejectedInvoices } from './DashboardRejectedInvoices';
import { getRejectedInvoices } from './DashboardRejectedInvoicesActions';
import { selectDashboardRejectedInvoicesLoadable } from './DashboardRejectedInvoicesReducer';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        dashboardRejectedInvoicesLoadable: selectDashboardRejectedInvoicesLoadable(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    getRejectedInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(withTranslation()(DashboardRejectedInvoices)));
