import { debounce } from 'lodash-es';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable1, Dispatchable3, Dispatchable4 } from 'redux-dispatchers';
import * as dateFns from 'date-fns';

import { createDataId } from '../../../../../../common/utils/dataId';
import { isISOString } from '../../../../../../common/utils/datetime';
import { formatMoneyToShowSeparators } from '../../utils';
import { formatDate } from '../../../../../../common/utils/formatters';
import { LoadableData } from '../../../../../../common/utils/LoadableData';
import { ContentBlockBody } from '../../../../../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter, ContentBlockFooterType } from '../../../../../../components/ContentBlock/ContentBlockFooter';
import Pager from '../../../../../../components/Pager/Pager';
import Table from '../../../../../../components/Table/Table';
import TableBody from '../../../../../../components/Table/TableBody';
import TableCell, { ResponsiveCellType } from '../../../../../../components/Table/TableCell';
import TableHead from '../../../../../../components/Table/TableHead';
import { DataTableHeader } from '../../../../../../components/Table/TableHeader';
import { Select } from '../../../../../../components/Select/Select';
import { SelectOptionValue } from '../../../../../../components/Select/SelectOption';
import { ContentBlockHeader, ContentBlockHeaderType } from '../../../../../../components/ContentBlock/ContentBlockHeader';
import TableRow from '../../../../../../components/Table/TableRow';
import { BaseSearch, GroupMemberCommonDTO, InvoiceDTO, InvoiceHeaderHistoryDTO, PagedListContainer } from '../../../../../../services/types/ApiTypes';
import { HistorySearchParams } from './HistoryListReducers';
import { DEFAULT_RESTRICTION, getCategorySetting, HistoryDatesPropertyNames, HistoryListFilter, HistorySumsPropertyNames, HistoryValuesBoolean, INVOICE_FILTER_CATEGORIES } from './HistoryListHelper';

import './HistoryList.scss';

const dataId = 'invoice-history-list';

export interface Props {
    invoiceHeaderHistoryList: LoadableData<PagedListContainer<InvoiceHeaderHistoryDTO>, HistorySearchParams>;
    searchParams: HistorySearchParams;
    invoice: InvoiceDTO;
    groupMember: GroupMemberCommonDTO;
}

export interface DispatchProps {
    getHistoryList: Dispatchable4<BaseSearch, number | string, string, boolean>;
    searchInvoiceHeaderHistoryList: Dispatchable3<string, number | string, string>;
    setInvoiceHeaderHistoryPagingOptions: Dispatchable4<number | string, number, number, string>;
    sortInvoiceHeaderHistoryList: Dispatchable3<string, number | string, string>;
    setHistoryListCategorySetting: Dispatchable1<string>;
}

export type HistoryListViewProps = Props & DispatchProps & WithTranslation;

const HistoryList = (props: HistoryListViewProps) => {
    const [activeCategory, setActiveCategory] = React.useState<SelectOptionValue<HistoryListFilter>>({
        text: props.t(INVOICE_FILTER_CATEGORIES[getCategorySetting(props.groupMember) || 0]),
        value: getCategorySetting(props.groupMember) || HistoryListFilter.DataFields,
    });

    React.useEffect(() => {
        if (props.invoice?.Id) {
            const filterCategory = getCategorySetting(props.groupMember);
            props.getHistoryList(undefined, filterCategory === '1' ? props.invoice.UniqId : props.invoice.Id, filterCategory || HistoryListFilter.DataFields, true);
        }
    }, [props.invoice]);

    const debounceDoSearch = debounce((searchString: string) => {
        props.searchInvoiceHeaderHistoryList(searchString, activeCategory.value === HistoryListFilter.PurchaseOrders ? props.invoice.UniqId : props.invoice.Id, activeCategory.value);
    }, 400);

    const handleSearchInputClick = (value: string) => {
        debounceDoSearch(value);
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        props.setInvoiceHeaderHistoryPagingOptions(activeCategory.value === HistoryListFilter.PurchaseOrders ? props.invoice.UniqId : props.invoice.Id, page, pageSize, activeCategory.value);
    };

    const handleSortClick = (value: string) => {
        props.sortInvoiceHeaderHistoryList(value, activeCategory.value === HistoryListFilter.PurchaseOrders ? props.invoice.UniqId : props.invoice.Id, activeCategory.value);
    };

    const formatDatesAndSums = (name: string, value: string) => {
        if (value && name) {
            if (HistorySumsPropertyNames.includes(name)) {
                return formatMoneyToShowSeparators(value?.replace(',', '.'), true);
            }

            if (HistoryDatesPropertyNames.includes(name)) {
                return value?.substring(0, 10);
            }

            // back-end doesn't return any type info for values, so we need to check if value is boolean
            const isBoolean = HistoryValuesBoolean.includes(value);
            if (isBoolean) {
                return props.t(`view.Invoice.History.Value.Boolean.${value}`);
            }

            // try to parse value as ISO string date, as 'name' can be 'date 3', 'date 4' etc.
            const isValueISODate = isISOString(value);
            if (!isValueISODate) {
                return value;
            }
            const date = new Date(value);
            // if value is not ISO date string, parsedValueAsISO will be Invalid Date
            if (dateFns.isValid(date)) {
                return dateFns.format(date, 'dd.MM.yyyy');
            }
        }
        return value;
    };

    const quickFilterMenu = () => {
        return (
            <Select
                dataId={createDataId(dataId, 'HistoryCategory')}
                key={activeCategory.value}
                onChange={(type) => {
                    if (type && type?.value !== activeCategory?.value) {
                        props.getHistoryList(undefined, type.value === HistoryListFilter.PurchaseOrders ? props.invoice.UniqId : props.invoice.Id, type.value, undefined);
                        setActiveCategory(type);
                        props.setHistoryListCategorySetting(type.value);
                    }
                }}
                value={activeCategory}
                items={Object.keys(INVOICE_FILTER_CATEGORIES).map((key) => ({
                    text: props.t(INVOICE_FILTER_CATEGORIES[key]),
                    value: key,
                }))}
                placeholder={props.t('view.PurchaseOrders.Type')}
            />
        );
    };

    const getSearchValue = () => {
        const searchRestriction = props.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        return searchRestriction ? searchRestriction.Value : null;
    };

    return (
        <div className="invoice-history-list">
            <ContentBlockHeader
                className="invoice-history-list__search"
                type={ContentBlockHeaderType.COMPACT}
                dataId={createDataId(dataId, 'Header')}
                hasSearch={true}
                t={props.t}
                searchOptions={{
                    dataId: createDataId(dataId, 'Search'),
                    onClick: handleSearchInputClick,
                    withSearchButton: true,
                    value: getSearchValue(),
                    quickFilterMenuBeforeSearch: true,
                    quickFilterMenu: quickFilterMenu(),
                    quickFilterLabel: props.t('view.Invoice.History.Filter.Category'),
                }}
            />
            <div className="invoice-history-list__table">
                <ContentBlockBody className="invoice-history-list__table-content" dataId="contentBlockBodyPoHistoryList">
                    <Table>
                        <DataTableHeader data-id={createDataId(dataId, 'Table', 'Header')}>
                            <TableRow>
                                <TableHead
                                    sortable={true}
                                    searchParams={props.invoiceHeaderHistoryList.request}
                                    columnName="AuditDate"
                                    onClick={handleSortClick}
                                    dataId={createDataId(dataId, 'AuditDate')}
                                    style={{ width: '20%' }}
                                >
                                    {props.t('view.PurchaseOrders.History.Column.EventDateTime')}
                                </TableHead>
                                <TableHead
                                    sortable={true}
                                    searchParams={props.invoiceHeaderHistoryList.request}
                                    columnName="ModifiedByName"
                                    onClick={handleSortClick}
                                    dataId={createDataId(dataId, 'ModifiedByName')}
                                    style={{ width: '15%' }}
                                >
                                    {props.t('view.PurchaseOrders.History.Column.ModifiedBy')}
                                </TableHead>
                                <TableHead
                                    sortable={true}
                                    searchParams={props.invoiceHeaderHistoryList.request}
                                    onClick={handleSortClick}
                                    columnName="Change.Name"
                                    dataId={createDataId(dataId, 'Field')}
                                    style={{ width: activeCategory?.value === HistoryListFilter.CustomFields ? '20%' : '15%' }}
                                    hide={activeCategory?.value === HistoryListFilter.PurchaseOrders}
                                >
                                    {props.t(activeCategory?.value === HistoryListFilter.DataFields ? 'view.PurchaseOrders.History.Column.Field' : 'view.Invoice.History.Column.CustomFieldName')}
                                </TableHead>
                                <TableHead
                                    sortable={true}
                                    searchParams={props.invoiceHeaderHistoryList.request}
                                    onClick={handleSortClick}
                                    columnName="PurchaseOrderNumber"
                                    dataId={createDataId(dataId, 'PoNumber')}
                                    hide={activeCategory?.value !== HistoryListFilter.PurchaseOrders}
                                >
                                    {props.t('view.Invoice.History.Column.PoNumber')}
                                </TableHead>
                                <TableHead
                                    sortable={true}
                                    searchParams={props.invoiceHeaderHistoryList.request}
                                    onClick={handleSortClick}
                                    columnName="Change.NewValue"
                                    dataId={createDataId(dataId, 'Action')}
                                    style={{ width: '15%' }}
                                    hide={activeCategory?.value !== HistoryListFilter.PurchaseOrders}
                                >
                                    {props.t('view.Invoice.History.Column.Action')}
                                </TableHead>
                                <TableHead
                                    sortable={true}
                                    searchParams={props.invoiceHeaderHistoryList.request}
                                    onClick={handleSortClick}
                                    columnName="Change.OldValue"
                                    dataId={createDataId(dataId, 'OldValue')}
                                    style={{ width: '25%' }}
                                    hide={activeCategory?.value === HistoryListFilter.PurchaseOrders}
                                >
                                    {props.t('view.PurchaseOrders.History.Column.OldValue')}
                                </TableHead>
                                <TableHead
                                    sortable={true}
                                    searchParams={props.invoiceHeaderHistoryList.request}
                                    onClick={handleSortClick}
                                    columnName="Change.NewValue"
                                    dataId={createDataId(dataId, 'NewValue')}
                                    style={{ width: '25%' }}
                                    hide={activeCategory?.value === HistoryListFilter.PurchaseOrders}
                                >
                                    {props.t('view.PurchaseOrders.History.Column.NewValue')}
                                </TableHead>
                            </TableRow>
                        </DataTableHeader>
                        <TableBody>
                            {props.invoiceHeaderHistoryList?.payload && !props.invoiceHeaderHistoryList?.loading
                                ? props.invoiceHeaderHistoryList.payload.Items.map((row, id) => (
                                      <TableRow key={id} data-id={createDataId(dataId, 'tableRow', id)}>
                                          <TableCell dataId={createDataId(dataId, `${id}.AuditDate`)} hideOverflow={true} responsiveCellType={ResponsiveCellType.LABEL}>
                                              {formatDate(row?.AuditDate, 'dd.MM.yyyy HH:mm:ss') || '\u00A0'}
                                          </TableCell>
                                          <TableCell dataId={createDataId(dataId, `${id}.ModifiedByName`)} hideOverflow={true} responsiveCellType={ResponsiveCellType.LABEL}>
                                              {row.ModifiedByName || '\u00A0'}
                                          </TableCell>
                                          <TableCell
                                              dataId={createDataId(dataId, `${id}.Field`)}
                                              hideOverflow={true}
                                              hide={activeCategory?.value === HistoryListFilter.PurchaseOrders}
                                              responsiveCellType={ResponsiveCellType.LABEL}
                                          >
                                              {row.Change.Name || '\u00A0'}
                                          </TableCell>
                                          <TableCell dataId={createDataId(dataId, `${id}.PoNumber`)} hideOverflow={true} hide={activeCategory?.value !== HistoryListFilter.PurchaseOrders}>
                                              {formatDatesAndSums(row.Change.PropertyName, row.PurchaseOrderNumber)}
                                          </TableCell>
                                          <TableCell dataId={createDataId(dataId, `${id}.Action`)} hideOverflow={true} hide={activeCategory?.value !== HistoryListFilter.PurchaseOrders}>
                                              {formatDatesAndSums(row.Change.PropertyName, row.Change.NewValue)}
                                          </TableCell>
                                          <TableCell dataId={createDataId(dataId, `${id}.OldValue`)} hideOverflow={true} hide={activeCategory?.value === HistoryListFilter.PurchaseOrders}>
                                              {formatDatesAndSums(row.Change.PropertyName, row.Change.OldValue)}
                                          </TableCell>
                                          <TableCell dataId={createDataId(dataId, `${id}.NewValue`)} hideOverflow={true} hide={activeCategory?.value === HistoryListFilter.PurchaseOrders}>
                                              {formatDatesAndSums(row.Change.PropertyName, row.Change.NewValue)}
                                          </TableCell>
                                      </TableRow>
                                  ))
                                : [...Array(props.searchParams?.PagingOptions?.Count || 10)].map((v, i) => (
                                      <TableRow key={i + 'row'}>
                                          <TableCell style={{ width: '15%' }} key={i + '-1'} showPlaceholder={true} dataId={createDataId(dataId, 'Cell-1', i)}>
                                              {null}
                                          </TableCell>
                                          <TableCell style={{ width: '15%' }} key={i + '-2'} showPlaceholder={true} dataId={createDataId(dataId, 'Cell-2', i)}>
                                              {null}
                                          </TableCell>
                                          <TableCell style={{ width: '23%' }} key={i + '-3'} showPlaceholder={true} dataId={createDataId(dataId, 'Cell-3', i)}>
                                              {null}
                                          </TableCell>
                                          <TableCell style={{ width: '23%' }} key={i + '-4'} showPlaceholder={true} dataId={createDataId(dataId, 'Cell-4', i)}>
                                              {null}
                                          </TableCell>
                                          <TableCell
                                              style={{ width: '23%' }}
                                              key={i + '-5'}
                                              showPlaceholder={true}
                                              dataId={createDataId(dataId, 'Cell-5', i)}
                                              hide={activeCategory?.value === HistoryListFilter.PurchaseOrders}
                                          >
                                              {null}
                                          </TableCell>
                                      </TableRow>
                                  ))}
                        </TableBody>
                    </Table>
                </ContentBlockBody>
            </div>
            <ContentBlockFooter type={ContentBlockFooterType.PAGER}>
                <Pager pages={props.invoiceHeaderHistoryList.payload} onPageChange={handlePageChange} />
            </ContentBlockFooter>
        </div>
    );
};

export default withTranslation()(HistoryList);
