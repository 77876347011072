import { cloneDeep, isEmpty, isEqual } from 'lodash-es';
import { ThunkMiddleware } from 'redux-thunk';
import { createAction } from 'redux-actions';

import { GlobalState } from '../../rootReducer';
import { DispatchThunk } from '../../storeConfig';
import {
    getViewBoUserSettings,
    ListViewBaseSearch as BoListViewBaseSearch,
    setBoUserSettingValue,
    updateBoUserSettings,
    deleteBoUserSettingValue,
} from '../../views/back-office/common/boUserSettingUtil';

import { getViewUserSettings, ListViewBaseSearch, setUserSettingValue } from '../user/userSettingUtil';
import { updateUserSettings } from '../user/UserActions';
import { UserSetting, Restriction, SearchType } from '../../services/types/ApiTypes';
import { UserSetting as BoUserSetting } from '../../services/types/BoApiTypes';

/**
 * added separate namespace for user settings updating in reducers
 */
const ns = 'update-user-settings';

/**
 * create updateUserSettings actions for both APP and BO, and also extract the types for easier comparison
 */

// BE user settings updates
export const updateAppUserSettingsAction = createAction<ListViewBaseSearch>(`${ns}/APP`);
export const updateBoUserSettingsAction = createAction<BoListViewBaseSearch>(`${ns}/BACK_OFFICE`);
const updateAppUserSettingsActionType = updateAppUserSettingsAction(undefined).type;
const updateBoUserSettingsActionType = updateBoUserSettingsAction(undefined).type;

export const userSettingsMiddleware: ThunkMiddleware<GlobalState, undefined, DispatchThunk> = ({ dispatch, getState }) => (next: DispatchThunk) => async (action: any) => {
    if (action.type === updateAppUserSettingsActionType) {
        const {
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();

        const { listViewConfig, searchParams } = action.payload;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(listViewConfig, groupMember);
            const currentSettings = cloneDeep(groupMember.UserSettings);

            let newSettings: UserSetting[];
            if (searchParams && searchParams.hasOwnProperty('PagingOptions')) {
                if (!viewConfig.pageSize || parseInt(viewConfig.pageSize, 10) !== searchParams.PagingOptions.Count) {
                    newSettings = setUserSettingValue(listViewConfig.pageSize, searchParams.PagingOptions.Count, groupMember.UserSettings);
                }
            }

            if (searchParams && searchParams.hasOwnProperty('SortItems')) {
                if (!viewConfig.sortCol || viewConfig.sortCol !== searchParams.SortItems[0].SortColumn) {
                    newSettings = setUserSettingValue(listViewConfig.sortCol, searchParams.SortItems[0].SortColumn, groupMember.UserSettings);
                }

                if (!viewConfig.sortDir || parseInt(viewConfig.sortDir, 10) !== searchParams.SortItems[0].SortDirection) {
                    newSettings = setUserSettingValue(listViewConfig.sortDir, searchParams.SortItems[0].SortDirection, groupMember.UserSettings);
                }
            }

            // TODO @tonister: Add this feature in a later release when the saving filters has been thoroughly thought through
            // if (searchParams && searchParams.hasOwnProperty('filters')) {
            //     if (!viewConfig.filters || !isEqual(viewConfig.filters, searchParams.filters.values)) {
            //         const filterValues = Object.keys(searchParams.filters).map((key) => {
            //             const { columnName, values } = searchParams.filters[key];
            //             return {
            //                 columnName,
            //                 values,
            //             };
            //         });
            //         groupMember.UserSettings = setUserSettingValue(listViewConfig.filters, JSON.stringify(filterValues), groupMember.UserSettings);
            //     }
            // }

            if (newSettings && !isEqual(currentSettings, newSettings)) {
                // Settings update in State and BE if only settings have changed
                await dispatch(updateUserSettings(groupMember.Id, newSettings));
                groupMember.UserSettings = newSettings;
            }
        }
    }
    if (action.type === updateBoUserSettingsActionType) {
        const {
            boUser: {
                boCurrentUserLoadable: { payload: currentUser },
            },
        } = getState();
        const { listViewConfig, searchParams } = action.payload;

        if (!isEmpty(currentUser)) {
            let newSettings: BoUserSetting[];
            const viewConfig = getViewBoUserSettings(listViewConfig, currentUser);

            if (searchParams && searchParams.hasOwnProperty('PagingOptions')) {
                if (!viewConfig.pageSize || parseInt(viewConfig.pageSize, 10) !== searchParams.PagingOptions.Count) {
                    newSettings = setBoUserSettingValue(listViewConfig.pageSize, searchParams.PagingOptions.Count, currentUser.Settings);
                }
            }

            if (searchParams && searchParams.hasOwnProperty('SortItems') && !isEmpty(searchParams?.SortItems)) {
                if (!viewConfig.sortCol || viewConfig.sortCol !== searchParams.SortItems[0].SortColumn) {
                    newSettings = setBoUserSettingValue(listViewConfig.sortCol, searchParams.SortItems[0].SortColumn, currentUser.Settings);
                }

                if (!viewConfig.sortDir || parseInt(viewConfig.sortDir, 10) !== searchParams.SortItems[0].SortDirection) {
                    newSettings = setBoUserSettingValue(listViewConfig.sortDir, searchParams.SortItems[0].SortDirection, currentUser.Settings);
                }
            }
            if (searchParams && searchParams.hasOwnProperty('filters')) {
                if (!viewConfig.filters || !isEqual(viewConfig.filters, searchParams.filters.values)) {
                    const filterValues = Object.keys(searchParams.filters).reduce((accum, key) => {
                        const { columnName, values } = searchParams.filters[key];
                        if (values.length) {
                            const newFilter: Restriction = {
                                Field: columnName,
                                Values: values.length > 1 ? values.map((v: any) => v.value) : null,
                                Value: values.length > 1 ? null : values[0].value,
                                FieldSearchType: SearchType.NotSelected,
                            };
                            accum.push(newFilter);
                        }
                        return accum;
                    }, []);

                    if (!filterValues.length) {
                        // delete empty filters
                        newSettings = deleteBoUserSettingValue(listViewConfig.filters, currentUser.Settings);
                    } else {
                        newSettings = setBoUserSettingValue(listViewConfig.filters, JSON.stringify(filterValues), currentUser.Settings);
                    }
                }
            }

            if (newSettings && !isEqual(currentUser.Settings, newSettings)) {
                // Settings update in State and BE if only settings have changed
                await dispatch(updateBoUserSettings({ ...currentUser, Settings: newSettings }));
                currentUser.Settings = newSettings;
            }
        }
    }
    return next(action);
};
