import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { default as createHashHistory } from 'history/createHashHistory';
import { applyMiddleware, compose, createStore, AnyAction, Store } from 'redux';
import createConnect from 'redux-connect-standalone';
import { persistReducer, persistStore, Persistor, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { userSettingsMiddleware } from './common/middlewares/userSettings';
import createRootReducer, { GlobalState } from './rootReducer';

export type DispatchThunk = ThunkDispatch<GlobalState, undefined, AnyAction>;
export type ActionThunk = ThunkAction<any, GlobalState, undefined, AnyAction>;

export const routerHistory = createHashHistory();
const storeAndPersistor: { store: Store; persistor: Persistor } = configureStore({}, routerHistory);

function configureStore(initialState = {}, history: History) {
    const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const persistConfig: PersistConfig<GlobalState> = {
        storage,
        key: 'root',
        whitelist: [], // list of store states that we want to persist
    };

    const rootReducer = createRootReducer(history);

    const persistedReducer = persistReducer(persistConfig, rootReducer as any);

    // create store with all the middleware
    const store = createStore(persistedReducer, initialState, composeEnhancer(applyMiddleware(routerMiddleware(history), thunk, userSettingsMiddleware)));

    // in dev activate HMR
    if (process.env.NODE_ENV === 'development' && (module as any).hot) {
        // HMR reducers
        (module as any).hot.accept('./rootReducer', () => {
            store.replaceReducer(persistedReducer);
        });
    }

    const persistor = persistStore(store);

    return { store, persistor };
}

export const connect = createConnect(storeAndPersistor.store);
export default storeAndPersistor;
