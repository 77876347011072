import { Form, Formik } from 'formik';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable1 } from 'redux-dispatchers';
import * as Yup from 'yup';
import FormikField from '../../../../common/utils/FormikField';

import { Button } from '../../../Buttons/Button';
import { createDataId } from '../../../../common/utils/dataId';
import { TextInputField, TextInputType } from '../../../TextInput/TextInput';
import withSuspense from '../../../../common/hocs/withSuspense';
import InfoTextBlock from '../InfoTextBlock/InfoTextBlock';

import './EmailVerification.scss';

export interface Props {
    verifyEmail: Dispatchable1<string>;
    dataId: string;
    email: string;
    isLoading: boolean;
}

export type EmailVerificationProps = Props & WithTranslation;

export interface EmailVerificationFields {
    code: string;
}

export const EmailVerification = (props: EmailVerificationProps) => {
    const { isLoading, dataId, t, verifyEmail, email } = props;
    const initialFields: EmailVerificationFields = {
        code: '',
    };

    const verifyEmailValidationSchema: Yup.ObjectSchema<EmailVerificationFields> = Yup.object<EmailVerificationFields>().shape({
        code: Yup.string()
            .ensure()
            .required(t('component.emailVerification.emptyCode'))
            .nullable(true),
    });

    return (
        <Form autoComplete="off" className="verify-email">
            <Formik
                initialValues={initialFields}
                onSubmit={(vals) => {
                    const { code } = vals;
                    verifyEmail(code);
                }}
                validationSchema={verifyEmailValidationSchema}
                enableReinitialize={true}
                validateOnBlur={true}
                validateOnChange={true}
            >
                {(formik) => (
                    <>
                        <InfoTextBlock
                            header={t('views.global.UserProfileModal.emailVerification.header')}
                            dataId={dataId}
                            text={t('views.global.UserProfileModal.emailVerification.body').replace('{0}', email)}
                        />
                        <div data-id="verifyEmail.form">
                            <FormikField
                                component={TextInputField}
                                wrapperClass="verify-email__input"
                                type={TextInputType.BORDERED}
                                label={t('component.emailVerification.code') + ' *'}
                                name="code"
                                dataId={createDataId(dataId, 'emailVerification', 'emailVerification')}
                            />
                        </div>
                        <div className="user-profile__footer" data-id={createDataId(dataId, 'verifyEmail', 'footer')}>
                            <Button dataId={createDataId(dataId, 'verifyEmail', 'button.save')} loading={isLoading} onClick={() => formik.submitForm()}>
                                {t('component.emailVerification.verify')}
                            </Button>
                        </div>
                    </>
                )}
            </Formik>
        </Form>
    );
};

export default withSuspense(withTranslation()(EmailVerification));
