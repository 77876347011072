import { loadableDataActions } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { InvoiceRegistryMinDTO, InvoiceSearchOptions, PagedListContainer } from '../../../services/types/ApiTypes';
import api from '../../../services/ApiServices';
import { DispatchThunk } from '../../../storeConfig';

const ns = 'dashboard/';

export const dashboardRejectedInvoicesLoadable = loadableDataActions<InvoiceSearchOptions, PagedListContainer<InvoiceRegistryMinDTO>>(`${ns}GET_REJECTED_INVOICES`);

export function getRejectedInvoices(searchParams: InvoiceSearchOptions | undefined) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        searchParams = searchParams || state.dashboardRejectedInvoices.searchParams;
        let response;
        try {
            dispatch(dashboardRejectedInvoicesLoadable.request(searchParams));

            response = await api.invoice.getRegistryInvoicesMin(searchParams);

            if (!response.data.Items.length && !!response.data.Skip && searchParams.PagingOptions.Page !== 1) {
                const paging = {
                    ...searchParams.PagingOptions,
                    Page: searchParams.PagingOptions.Page - 1,
                };
                dispatch(
                    getRejectedInvoices({
                        ...searchParams,
                        PagingOptions: paging,
                    }),
                );
            }
            dispatch(dashboardRejectedInvoicesLoadable.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(dashboardRejectedInvoicesLoadable.error(e));
        }
    };
}
