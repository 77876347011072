import { createAction } from 'redux-actions';

import { OrganizationShort, BackOfficeOrganizationDTO } from '../../../../../services/types/BoApiTypes';
import { loadableDataActions } from '../../../../../common/utils/LoadableData';
import { DispatchThunk } from '../../../../../storeConfig';
import api from '../../../../../services/ApiServices';
import { notify } from '../../../../../common/utils/notify';
import i18nInstance from '../../../../../i18n';

const ns = 'new-BoOrganization-modal/';

export const setActiveBoOrganization = createAction<OrganizationShort>(`${ns}SET_ACTIVE_COMPANY`);
export const saveOrganizationActions = loadableDataActions<OrganizationShort, BackOfficeOrganizationDTO>(`${ns}SAVE_BO_ORGANIZATION`);

export const saveOrganization = (boOrganization: OrganizationShort) => {
    return async (dispatch: DispatchThunk) => {
        try {
            if (boOrganization?.OrganizationGuid) {
                await api.boOrganizations.saveOrganization(boOrganization);
            } else {
                delete boOrganization.OrganizationGuid;
                dispatch(saveOrganizationActions.request(boOrganization));
                const response = await api.boOrganizations.saveOrganization(boOrganization);
                dispatch(saveOrganizationActions.success(response.data));
            }
            // import the actions lazily, otherwise it seems we get circular dependencies and page crashes, probably the solution would be to move GlobalState out of the rootReducer file
            import('../../BOOrganizationListViewActions').then((actions) => {
                dispatch(actions.setPagingOptions(1));
            });
        } catch (e) {
            console.error(e);
            dispatch(saveOrganizationActions.error(e));
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const showEditBoOrganizationModal = createAction<boolean>(`${ns}SHOW_NEW_BO_ORGANIZATION_MODAL`);

export function showAddOrEditBoOrganizationModal(boOrganization?: OrganizationShort) {
    return async (dispatch: DispatchThunk) => {
        try {
            // load the boOrganization data if Id is provided. If it is not provided, then we are creating from scratch
            if (boOrganization) {
                dispatch(setActiveBoOrganization(boOrganization));
            } else {
                dispatch(setActiveBoOrganization(undefined));
            }
            dispatch(showEditBoOrganizationModal(true));
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileSendingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
}
