import { isEmpty } from 'lodash-es';
import { GroupTasksDTO } from '../InvoiceConfirmations';
import { ApproverPerStep, TaskDTO } from '../../../../../services/types/ApiTypes';

// Get needed approvers count for a specified GroupTask from ApproverPerStep[]
export const getStaticStepsCounterForGroupTask = (approversPerSteps: ApproverPerStep[], groupTask: GroupTasksDTO): number | null => {
    const { group } = groupTask;

    if (isEmpty(approversPerSteps) || !group || group.length < 2) {
        // if no ApproversPerSteps, it's a parallel approvers task or a single task group (no X of Y functionality)
        return null;
    }
    const groupOrderNo = group[0].OrderNo;
    // OrderNo in a groupTask starts from 0, while OrderNo in ApproversPerStep starts from 1
    const approversPerStepForAGroup = approversPerSteps.find((a) => a.OrderNo + 1 === groupOrderNo);
    // returning 0 means it's a dynamic workflow with parallel approvers (or some indices were messed up while modifying the steps)
    return approversPerStepForAGroup?.Count || 0;
};

// Change OrderNo in all GroupTasksDTO affected by the re-ordering and then sort them accordingly
export const reorderGroupTasks = (srcOrderNo: number, destOrderNo: number, groupTasks: GroupTasksDTO[]): GroupTasksDTO[] => {
    const movingDown = srcOrderNo < destOrderNo;
    let groupTasksClone: GroupTasksDTO[] = [...groupTasks];

    if (movingDown) {
        groupTasksClone = groupTasksClone.map((t: GroupTasksDTO) => {
            if (!Array.isArray(t.group)) {
                return t;
            }
            t.group.map((g: TaskDTO) => {
                if (g.OrderNo === srcOrderNo) {
                    g.OrderNo = destOrderNo;
                } else if (g.OrderNo > srcOrderNo && g.OrderNo <= destOrderNo) {
                    g.OrderNo = g.OrderNo - 1;
                }
            });
            return { ...t };
        });
    } else {
        groupTasksClone = groupTasksClone.map((t: GroupTasksDTO) => {
            if (!Array.isArray(t.group)) {
                return t;
            }
            t.group.map((g: TaskDTO) => {
                if (g.OrderNo >= destOrderNo && g.OrderNo < srcOrderNo) {
                    g.OrderNo = g.OrderNo + 1;
                } else if (g.OrderNo === srcOrderNo) {
                    g.OrderNo = destOrderNo;
                }
            });
            return { ...t };
        });
    }

    return groupTasksClone.sort((a, b) => {
        if (!Array.isArray(a.group) || !Array.isArray(b.group)) {
            // this should not happen
            return 0;
        }
        return a.group[0].OrderNo > b.group[0].OrderNo ? 1 : -1;
    });
};

export const hasCurrentConfirmerAndComment = (groupTasks: GroupTasksDTO): boolean => {
    const tasks: TaskDTO[] = groupTasks.group;
    if (tasks && tasks.find((t) => t.isCurrentConfirmer && !t.Completed && t.Comment)) {
        return true;
    }
    return false;
};
