import Tippy from '@tippy.js/react';
import classNames from 'classnames';
import * as React from 'react';

import { addDataId, WithDataId } from '../../common/utils/dataId';
import { BaseStatefulComponent } from '../BaseStatefulComponent';
import Icon from '../Icon/Icon';
import Scrollbars from '../Scrollbars/Scrollbars';
import { TextInput, TextInputType } from '../TextInput/TextInput';

import { DropdownGroupItems, GroupItemData } from './DropdownGroupItems';
import DropdownMenuLoader from './DropdownMenuLoader';

require('tippy.js/dist/tippy.css');
require('tippy.js/dist/themes/light.css');
const styles = require('./DropdownMenu.scss');

interface MenuProps extends WithDataId {
    children?: React.ReactNode;
    className?: string;
    toggleMenu: (e: React.MouseEvent<any>) => void;
    hideOnClick: boolean;
    filterable?: boolean;
    filterOptions?: FilterMenuOptions;
    isVisible: boolean;
    isShown: boolean;
    isOverflowHidden?: boolean;
}

interface FilterMenuOptions {
    placeholder: string;
    filterBy: string;
}

export type MenuChildProps = FilterMenuOptions & {
    filterValue?: string;
};

export const Menu: React.FC<MenuProps> = ({ children, className, toggleMenu, hideOnClick, dataId, filterable, filterOptions, isVisible, isShown, isOverflowHidden }: MenuProps) => {
    const [filterValue, setFilterValue] = React.useState(undefined);

    React.useEffect(() => {
        if (!isVisible && filterValue) {
            setFilterValue(undefined);
        }
    }, [filterValue, isVisible]);

    const handleClick = (e: React.MouseEvent<any>) => {
        e.bubbles = false;
        e.stopPropagation();
        if (hideOnClick) {
            toggleMenu(e);
        }
    };

    const passProps = (arg: any) => (params: MenuChildProps) => (typeof arg === 'function' ? arg(params) : arg);

    const hasFilter = filterable && filterOptions;

    const renderList = () => {
        return (
            <ul data-id={dataId} onClick={handleClick} className={styles.menu}>
                {passProps(children)({ filterValue, ...filterOptions })}
            </ul>
        );
    };

    const classes = classNames('menu-wrapper', className, { 'menu-wrapper--filterable': hasFilter });
    if (!isShown && process.env.NODE_ENV !== 'test') {
        return null;
    }
    return (
        <div className={classes}>
            {hasFilter && (
                <div className="menu-wrapper__input-wrap">
                    <TextInput
                        dataId={addDataId(dataId, 'filter-input')}
                        hideError={true}
                        placeholder={filterOptions.placeholder}
                        autofocus={true}
                        showClear={true}
                        value={filterValue}
                        type={TextInputType.SEARCH_COMPACT}
                        disableAutofocusScroll={true}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFilterValue(e.target.value);
                        }}
                    />
                </div>
            )}

            {isOverflowHidden ? (
                renderList()
            ) : (
                <Scrollbars autoHeight={true} autoHeightMax={340} hideTracksWhenNotNeeded={true}>
                    {renderList()}
                </Scrollbars>
            )}
        </div>
    );
};

export interface DropdownMenuItemProps extends WithDataId {
    className?: string;
    children?: React.ReactNode;
    isDisabled?: boolean;
    icon?: string;
    onClick?: () => void;
}

export const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({ className, children, icon, onClick, isDisabled, dataId, ...rest }: DropdownMenuItemProps) => {
    const classes = classNames('dropdown__item', className, styles.item, {
        [styles['item-disabled']]: isDisabled,
    });
    return (
        <li
            className={classes}
            tabIndex={0}
            data-id={dataId}
            onClick={onClick}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && onClick) {
                    onClick();
                }
            }}
            {...rest}
        >
            {icon && <Icon name={icon} className={styles.icon} data-id={`${dataId}.icon`} />}
            <span className={styles['item-content']} data-id={`${dataId}.content`}>
                {children}
            </span>
        </li>
    );
};

export interface DropdownMenuProps<T extends GroupItemData> extends WithDataId {
    children: React.ReactElement<any>;
    className?: string;
    items?: React.ReactNode | Array<{ [groupName: string]: GroupItemData }>;
    loadItems?: () => Promise<React.ReactNodeArray>;
    isVisible?: boolean;
    isOverflowHidden?: boolean;
    hideOnClick?: boolean;
    onHideCallback?: () => void;
    preventButtonClickEventBubble?: boolean;
    hasArrow?: boolean;
    filterOptions?: FilterMenuOptions;
    filterable?: boolean;
    hasGroups?: boolean;
    groupBy?: string;
    handleDropdownItemClick?: (item: T) => void;
    appendTo?: Element | ((ref: Element) => Element);
    disabled?: boolean;
}

interface DropdownMenuState {
    isVisible: boolean;
    items: React.ReactNodeArray;
    isShown: boolean;
    loading: boolean;
}

export class DropdownMenu<T extends GroupItemData> extends BaseStatefulComponent<DropdownMenuProps<T>, DropdownMenuState> {
    constructor(props: DropdownMenuProps<T>) {
        super(props);
        this.state = {
            isVisible: this.props.isVisible,
            isShown: false,
            items: [],
            loading: false,
        };
    }

    getItems = async () => {
        let items: React.ReactNodeArray = [];
        if (this.props.loadItems) {
            this.setState({
                loading: true,
            });
            items = await this.props.loadItems();
            this.setState({
                loading: false,
            });
        }
        this.setState((prevState) => ({
            ...prevState,
            items,
        }));
    };

    componentDidMount(): void {
        this.getItems();
    }

    componentDidUpdate(prevProps: DropdownMenuProps<T>) {
        if (prevProps.isVisible !== this.props.isVisible) {
            this.setState({
                isVisible: this.props.isVisible,
            });
        }
    }

    toggleMenu = (e: React.MouseEvent<any>) => {
        if (this.props.preventButtonClickEventBubble) {
            e.bubbles = false;
            e.stopPropagation();
        }
        this.setState({
            isVisible: !this.state.isVisible,
        });
    };

    closeMenu = () => {
        if (this.props.onHideCallback) {
            this.props.onHideCallback();
        }
        this.setState({
            isVisible: false,
            isShown: false,
        });
    };

    renderGroupItem = (props: MenuChildProps) => (
        <React.Fragment>
            {Object.keys(this.props.items).map((key) => (
                <DropdownGroupItems key={key} groupName={key} groupItems={this.props.items[key]} {...props}>
                    {(item: T) => (
                        <DropdownMenuItem key={item.id} dataId={addDataId(this.props.dataId, '.dropdownItem')} className={'item-group'} onClick={() => this.props.handleDropdownItemClick(item)}>
                            {item.name}
                        </DropdownMenuItem>
                    )}
                </DropdownGroupItems>
            ))}
        </React.Fragment>
    );

    getSubMenuItems = () => {
        return this.state.loading ? <DropdownMenuLoader /> : this.props.hasGroups ? (props: MenuChildProps) => this.renderGroupItem(props) : this.props.loadItems ? this.state.items : this.props.items;
    };

    render() {
        const { className, dataId, hasArrow, hideOnClick = true, appendTo, disabled, ...rest } = this.props;
        const theme = classNames('dropdown', className, { 'dropdown-filterable': this.props.filterable });
        return (
            <Tippy
                performance={true}
                theme={theme}
                content={
                    <Menu
                        className={className}
                        dataId={addDataId(dataId, '.menu')}
                        isShown={this.state.isShown}
                        isVisible={this.state.isVisible}
                        toggleMenu={this.toggleMenu}
                        hideOnClick={hideOnClick}
                        {...rest}
                    >
                        {this.getSubMenuItems()}
                    </Menu>
                }
                arrow={hasArrow}
                interactive={true}
                trigger={'manual'}
                isVisible={this.state.isVisible}
                placement={'bottom'}
                onShow={() => {
                    this.setState({
                        isShown: true,
                    });
                }}
                duration={250}
                onHidden={this.closeMenu}
                animation={'perspective'}
                appendTo={appendTo || document.body}
                isEnabled={!disabled}
            >
                {React.cloneElement(this.props.children, { onClick: this.toggleMenu })}
            </Tippy>
        );
    }
}
