import classNames from 'classnames';
import * as React from 'react';

import { BaseComponent } from '../BaseComponent';

import './MainComponent.scss';

export interface MainComponentProps {
    className?: string;
    loading?: boolean;
    empty?: boolean;
    children?: React.ReactNode;
}

export class MainComponent extends BaseComponent<MainComponentProps> {
    render() {
        const { className, children, loading, empty, ...rest } = this.props;
        const classes = classNames('main-component', className, { 'main-component--loading': loading, 'main-component--empty': empty });
        return (
            <section className={classes} {...rest}>
                {children}
            </section>
        );
    }
}

export default MainComponent;
