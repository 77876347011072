import { LOCATION_CHANGE } from 'connected-react-router';
import produce from 'immer';
import { cloneDeep } from 'lodash-es';
import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { BaseSearch, PagedListContainer, SearchType, SortDirection, VatCodeDTO } from '../../../services/types/ApiTypes';

import { addNewRow, loadVatCodesLoadableActions, removeRow, updateRow, updateVatCodeDefaults } from './VatCodeDetailsViewActions';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

class State {
    vatCodesLoadable = new LoadableData<PagedListContainer<VatCodeDTO>, BaseSearch>();

    vatCodesSearchParams: BaseSearch = {
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: '',
                Values: undefined,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        SortItems: [
            {
                SortColumn: 'Code',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 15,
        },
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        loadVatCodesLoadableActions.request,
        (state, action): State => {
            return {
                ...state,
                vatCodesSearchParams: action.payload,
                vatCodesLoadable: state.vatCodesLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        loadVatCodesLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                vatCodesSearchParams: action.payload.request,
                vatCodesLoadable: state.vatCodesLoadable.withPayloadIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        loadVatCodesLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                vatCodesLoadable: state.vatCodesLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        addNewRow,
        produce((draft) => {
            draft.vatCodesLoadable.payload.Items.push({
                Id: 0, // NEW_ROW_ID,
                EndDate: undefined,
                StartDate: undefined,
                Description: undefined,
                Code: undefined,
                IsNew: false,
                InvoiceCountInUse: undefined,
                VatRate: undefined,
                FullName: undefined,
                IsDefault: false,
                AssignedCurrentToUser: undefined,
                AutoTransactionCountInUse: undefined,
            });
            draft.vatCodesLoadable.payload.TotalCount++;
        }),
    )
    .addReducer(
        updateRow,
        produce((draft: State, action: Action<{ result: VatCodeDTO; itemToUpdate: number }>) => {
            const index = draft.vatCodesLoadable.payload.Items.findIndex((i: VatCodeDTO) => i.Id === action.payload.itemToUpdate);
            const previousValue = draft.vatCodesLoadable.payload.Items[index];
            // do not update the InUse Counts, because in update response they are null
            draft.vatCodesLoadable.payload.Items[index] = {
                ...action.payload.result,
                InvoiceCountInUse: previousValue.InvoiceCountInUse,
                AutoTransactionCountInUse: previousValue.AutoTransactionCountInUse,
            };
        }),
    )
    .addReducer(
        updateVatCodeDefaults,
        produce((draft, action) => {
            const updatedVatCodes: VatCodeDTO[] = action.payload;
            draft.vatCodesLoadable.payload.Items.forEach((vatCodeItem: VatCodeDTO, index) => {
                const potentiallyChangedVatCode = updatedVatCodes.find((u) => u.Id === vatCodeItem.Id);
                if (potentiallyChangedVatCode) {
                    draft.vatCodesLoadable.payload.Items[index] = {
                        ...vatCodeItem,
                        IsDefault: potentiallyChangedVatCode.IsDefault,
                    };
                }
            });
        }),
    )
    .addReducer(
        removeRow,
        produce((draft, action) => {
            draft.vatCodesLoadable.payload.Items = draft.vatCodesLoadable.payload.Items.filter((i: VatCodeDTO) => i.Id !== action.payload);
        }),
    )
    .addReducer(
        LOCATION_CHANGE,
        (state): State => {
            const searchRestriction = cloneDeep(state.vatCodesSearchParams.Restrictions[0]);
            searchRestriction.Value = '';
            return {
                ...new State(),
                vatCodesSearchParams: {
                    ...state.vatCodesSearchParams,
                    Restrictions: [searchRestriction],
                },
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'accounting/vatCodes',
    whitelist: ['vatCodesSearchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as VatCodeDetailsViewState };

export const selectVatCodesLoadable = (state: GlobalState) => state.vatCodeDetails.vatCodesLoadable;
export const selectVatCodesSearchParams = (state: GlobalState) => state.vatCodeDetails.vatCodesSearchParams;
