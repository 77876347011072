import * as React from 'react';

import './Table.scss';
interface TableBodyProps {
    innerRef?: any;
    dataId?: string;
}
const TableBody: React.FunctionComponent<TableBodyProps> = (props) => {
    return (
        <tbody className="data-table__body" ref={props.innerRef} data-id={props.dataId}>
            {props.children}
        </tbody>
    );
};

export default TableBody;
