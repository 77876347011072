import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { UseTranslateFormErrors } from './WithTranslateFormErorrsTypes';

export const useTranslateFormErrors: UseTranslateFormErrors = (errors, touched, setFieldTouched) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.on('languageChanged', () => {
            Object.keys(errors).forEach((fieldName) => {
                if (Object.keys(touched).includes(fieldName)) {
                    setFieldTouched(fieldName);
                }
            });
        });
        return () => {
            i18n.off('languageChanged', _.noop);
        };
    }, [errors, touched]);
};
