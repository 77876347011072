"use strict";
import constants from "../../src/common/constants/appConstants";


angular.module("dstreamApp.services").factory("constants", [
  "$filter",
  function () {
    // content moved to "../../src/common/constants/appConstants.ts" for reusability
    return constants;
  }
]);