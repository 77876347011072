'use strict';

angular
    .module('dstreamApp.components')
    .controller('DsRelatedDocumentsModalController', function ($rootScope, $scope, $filter, $uibModalInstance, webServices, utils, notifyService, $analytics) {
        $scope.fileType = "file";
        $scope.invoice = $scope.$parent.$ctrl.invoice;
        $scope.documentType = "invoice";
        $scope.link = {};
        $scope.reloadInvoiceData = $scope.$parent.$parent.reloadInvoiceData;
        $scope.closeModal = closeModal;

        $scope.addFileUrl = function () {
            if (!$scope.link.url || !$scope.link.name) {
                return;
            }

            var file = {};
            file.FileName = $scope.link.name;
            file.FileUrl = $scope.link.url;
            file.Base64Content = null;
            file.IsPdf = 0;
            file.IsImported = 0;
            file.IsText = 0;
            file.InvoiceId = $scope.invoice.Id;
            file.HubType = $scope.invoice.HubType ? $scope.invoice.HubType : 3; // set HubType = Emr, as like it was done manually

            $rootScope.$emit('relatedDocumentsLoading');
            webServices.uploadInvoiceFile(file).then(function (response) {
                if (response) {
                    $scope.reloadInvoiceData($scope.invoice.Id, 'relatedDocumentsLoaded');
                } else {
                    $rootScope.$emit('relatedDocumentsLoaded');
                }
                closeModal();
            }, function (data) {
                console.log(data);
            });
        };

        $scope.fileImageAdded = function ($file, $event) {
            $scope.file = $file;
            // fileImagerReader.readAsArrayBuffer($file.file);
        };

        $scope.uploadFile = function () {
            $scope.$parent.$ctrl.invoiceLoading = true;
            closeModal();
            fileImagerReader.readAsArrayBuffer($scope.file.file);
        };

        $scope.saveNewDocument = function () {
            if ($scope.fileType == 'file') {
                if (!$scope.file) {
                    notifyService.warning("No file selected!");
                    return;
                }
                $scope.uploadFile();
            } else {
                if (!$scope.link.url) {
                    notifyService.warning("No url specified!");
                    return;
                }
                if (!$scope.link.name) {
                    notifyService.warning("No name specified!");
                    return;
                }
                $scope.addFileUrl();
            }
            $analytics.eventTrack('Related documents', {
                category: 'Invoice Details',
                label: 'Add new document',
            });
        };

        $scope.focusInput = function (e) {
            e.preventDefault();
            angular.element(e.currentTarget).triggerHandler('click');
        };

        function closeModal() {
            $uibModalInstance.close();
            $scope.fileType = "file";
            $scope.documentType = "invoice";
            $scope.link = {};
        }

        var fileImagerReader = new FileReader();

        fileImagerReader.onload = function (event) {
            var file = {};
            file.FileName = $scope.file.name;
            file.InvoiceId = $scope.invoice.Id;
            file.Base64Content = utils.getBase64String(fileImagerReader.result);
            file.HubType = $scope.invoice.HubType ? $scope.invoice.HubType : 3; // set HubType = Emr, as like it was done manually
            $scope.file = null;
            webServices.uploadInvoiceFile(file).then(function (response) {
                if (response) {
                    $scope.reloadInvoiceData($scope.invoice.Id);
                } else {
                    $scope.$ctrl.invoiceLoading = false;
                }
            }, function (data) {
                console.log(data);
                $scope.$ctrl.invoiceLoading = false;
            });
        };
    });