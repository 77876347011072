(function() {
    'use strict';

    angular
        .module('dstreamApp.views.detailed-transactions')
        .directive('doOnTab', doOnTab)
        .directive('doOnShiftTab', doOnShiftTab)
        .directive('doOnEnter', doOnEnter)
        .directive('doOnShiftEnter', doOnShiftEnter)
        .directive('typeaheadPositionAjustment', typeaheadPositionAjustment);

    doOnTab.$inject = [];
    function doOnTab() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: linkFunc
        };

        function linkFunc(scope, element, attrs, ctrl) {
            angular.element(element).on('keydown', onTabKey);

            /*
                Cancel on esc key press
             */
            function onTabKey(event) {
                if(event.keyCode === 9 && !event.shiftKey) {
                    scope.$eval(attrs.doOnTab);
                    event.stopPropagation();
                }
            }

            scope.$on('$destroy', function() {
                angular.element(element).off('keydown', onTabKey);
            });
        }
    }

    doOnShiftTab.$inject = [];
    function doOnShiftTab() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: linkFunc
        };

        function linkFunc(scope, element, attrs, ctrl) {
            angular.element(element).on('keydown', onTabKey);

            /*
                Cancel on esc key press
             */
            function onTabKey(event) {
                if(event.keyCode === 9 && event.shiftKey) {
                    scope.$eval(attrs.doOnShiftTab);
                    event.stopPropagation();
                }
            }

            scope.$on('$destroy', function() {
                angular.element(element).off('keydown', onTabKey);
            });
        }
    }

    doOnEnter.$inject = [];
    function doOnEnter() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: linkFunc
        };

        function linkFunc(scope, element, attrs, ctrl) {
            angular.element(element).on('keydown', onEnterKey);

            /*
                Cancel on esc key press
             */
            function onEnterKey(event) {
                if(event.keyCode === 13 && !event.shiftKey) {
                    scope.$eval(attrs.doOnEnter);
                    event.stopPropagation();
                }
            }

            scope.$on('$destroy', function() {
                angular.element(element).off('keydown', onEnterKey);
            });
        }
    }

    doOnShiftEnter.$inject = [];
    function doOnShiftEnter() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: linkFunc
        };

        function linkFunc(scope, element, attrs, ctrl) {
            angular.element(element).on('keydown', onEnterKey);

            /*
                Cancel on esc key press
             */
            function onEnterKey(event) {
                if(event.keyCode === 13 && event.shiftKey) {
                    scope.$eval(attrs.doOnShiftEnter);
                    event.stopPropagation();
                }
            }

            scope.$on('$destroy', function() {
                angular.element(element).off('keydown', onEnterKey);
            });
        }
    }

    typeaheadPositionAjustment.$inject = ['$timeout'];
    function typeaheadPositionAjustment($timeout) {
        return {
            restrict: 'A',
            link: linkFunc
        };

        function linkFunc(scope, element, attrs, ctrl) {
            // when the dropdown is created then make it invisible and disable scrollbars on body
            // we use this so the typeahead actually places itself on the page and after that we can check if it is overlapping the window
            // TODO this is super hacky solution, but until angularJS bootstrap-ui supports auto typeahead positioning we use this
            element.css({'opacity': 0});
            angular.element('body').css({'overflow': 'hidden'});

            // if dropdown becomse visible, then wait for next tick
            var watcher = scope.$watch('isOpen()', function(val) {
                if(val) {
                    $timeout(function() {
                        // if element is outside of viewport
                        var offset = isElementInViewport(element);
                        if(offset) {
                            // if element is overflowing the bottom
                            if(offset.bottom) {
                                element.css({'position': 'fixed', 'top': 'auto', 'bottom': element[0].offsetHeight - offset.bottom + 50})
                            }
                            // if element is overflowing the right
                            if(offset.right) {
                                element.css({'left': element[0].offsetLeft - offset.right})
                            }
                        }

                        // we have repositioned the typeahead dropdown, so we can make it visible again and remove body overflow: hidden
                        element.css({'opacity': 1});
                        angular.element('body').css({'overflow': 'auto'});
                    });
                }
            });

            scope.$on('$destroy', function() {
                if(watcher) watcher();
                angular.element('body').css({'overflow': 'auto'});
            });
        }

        /*
            Check for bottom and right window overflow for the dropdown
         */
        function isElementInViewport (el) {
            //special bonus for those using jQuery
            if (typeof jQuery === "function" && el instanceof jQuery) {
                el = el[0];
            }

            var rect = el.getBoundingClientRect();
            var offset = {};

            if(rect.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
                offset.bottom = rect.bottom - window.innerHeight;
            }

            if(rect.right > (window.innerWidth || document.documentElement.clientWidth)) {
                offset.right = rect.right - window.innerWidth;
            }

            return offset || null
        }
    }
}());
