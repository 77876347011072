import { Field, FieldConfig } from 'formik';
import * as React from 'react';

type Props<TComponentProps> = {
    name: string;
    component: React.ComponentType<TComponentProps>;
    // this type is correctly inferred during compile time in FormikFields, but we get TS error here unless we use ts-ignore
    // more info https://github.com/microsoft/TypeScript/issues/21760#issuecomment-381377409
    // make value and onChange optional, since Formik always handles them
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    value?: TComponentProps['value'];
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    onChange?: TComponentProps['onChange'];
    children?: React.ReactNode;
} & Omit<TComponentProps, 'name' | 'component' | 'field' | 'form' | 'value' | 'onChange' | 'children' | 'meta'> &
    Pick<FieldConfig, 'validate'>;

/**
 * This is a type safe Field for Formik, it derives supported props from the props of the component
 * source: https://github.com/jaredpalmer/formik/issues/1334#issuecomment-531715894
 */
class FormikField<T> extends React.PureComponent<Props<T>> {
    render() {
        const { name, component, ...props } = this.props;
        return <Field name={name} component={component} {...props} />;
    }
}

export default FormikField;
