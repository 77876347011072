import React, { useState } from 'react';
import { LabelStyle, TextInputType } from '../../../TextInput/TextInput';
import classNames from 'classnames';
import { createDataId } from '../../../../common/utils/dataId';
import { ControlledInputProps } from './ControlledInputTypes';
import Tooltip from '../../../Tooltip/Tooltip';
import { Button, ButtonType } from '../../../Buttons/Button';
import { ICONS } from '../../../Icon/Icon';

import '../../../TextInput/TextInput.scss';
// Temp component that will be replaced in future with reworked Input components. Renders controlled input with label and error message.
// Do not use inner state for logic here, use props instead.
// Can be extended based on future requirements.

export const ControlledInputView: React.FC<ControlledInputProps> = (props) => {
    const { labelStyle, error, label, dataId, inputType, wrapperClass, className, disabled, readOnly, placeholder, validCharacters, replaceComma, ...inputProps } = props;
    const [state, setState] = useState<{ hasFocus: boolean; isHovered: boolean }>({ hasFocus: false, isHovered: false });
    const classes = classNames('text-input__input', className, {
        'text-input__input--default': inputType === TextInputType.DEFAULT || inputType === TextInputType.LARGE,
        'text-input__input--large': inputType === TextInputType.LARGE,
        'text-input__input--bordered': inputType === TextInputType.BORDERED,
        'text-input__input--compact': inputType === TextInputType.COMPACT || inputType === TextInputType.SEARCH_COMPACT,
        'text-input__input--inline-table': inputType === TextInputType.INLINE_TABLE,
        'text-input__input--search text-input__input--bordered': inputType === TextInputType.SEARCH,
        'text-input__input--search-compact': inputType === TextInputType.SEARCH_COMPACT,
        'text-input__input--search-dimensions': inputType === TextInputType.SEARCH_DIMENSIONS,
        'text-input__input--date-picker text-input__input--bordered': inputType === TextInputType.DATE_PICKER,
        'text-input__input--currency text-input__input--bordered': inputType === TextInputType.CURRENCY,
        'text-input__input--is-hovered': state.isHovered,
        'text-input__input--is-disabled': disabled,
        'text-input__input--has-addon': inputType === TextInputType.WITH_ADDON,
    });

    const wrapperClasses = classNames('text-input', wrapperClass, {
        'text-input--default': inputType === TextInputType.DEFAULT || inputType === TextInputType.LARGE,
        'text-input--has-focus': state.hasFocus,
        'text-input--large': inputType === TextInputType.LARGE,
        'text-input--bordered': inputType === TextInputType.BORDERED || inputType === TextInputType.SEARCH,
        'text-input--compact': inputType === TextInputType.COMPACT || inputType === TextInputType.SEARCH_COMPACT || inputType === TextInputType.DATE_PICKER,
        'text-input--inline-table': inputType === TextInputType.INLINE_TABLE,
        'text-input--has-icon': inputType === TextInputType.SEARCH_COMPACT,
        'text-input--has-error': Boolean(error),
        'text-input--is-disabled': disabled,
        'text-input--search': inputType === TextInputType.SEARCH,
        'text-input--search-compact': inputType === TextInputType.SEARCH_COMPACT,
        'text-input__input--search-dimensions': inputType === TextInputType.SEARCH_DIMENSIONS,
        'text-input__input--currency': inputType === TextInputType.CURRENCY,
        'text-input--highlight-label': inputType === TextInputType.WITH_LABEL,
        'text-input--is-hovered': state.isHovered,
        'text-input--has-addon': inputType === TextInputType.WITH_ADDON,
        'text-input--has-loading': props.loading,
        'text-input--has-success': props.success,
        'text-input--has-content': Boolean(props.value),
    });

    const labelRenderStyle = inputType === TextInputType.SEARCH_COMPACT ? LabelStyle.UPPERCASE : labelStyle;

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
        setState((prevState) => ({
            ...prevState,
            hasFocus: true,
        }));
        props?.onFocus(e);
    };

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
        setState((prev) => ({ ...prev, hasFocus: false }));
        props?.onBlur(e);
    };

    const handleMouseEnter: React.MouseEventHandler<HTMLDivElement> = () => {
        setState((prevState) => ({
            ...prevState,
            isHovered: true,
        }));
    };

    const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => {
        setState((prevState) => ({
            ...prevState,
            isHovered: false,
        }));
    };

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const normalizedValue = e.target.value.replace(/\.{2,}/g, '.');

        if (normalizedValue.length && !validCharacters?.test(normalizedValue)) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (replaceComma) {
            e.target.value = e.target.value.replace(',', '.');
        }

        props.onChange(e);
    };

    return (
        <div className={wrapperClasses} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={classNames('text-input__container')}>
                {label && (
                    <label data-id={createDataId(dataId, 'input', 'label')} className={`text-input__label text-input__label--${labelRenderStyle?.toLowerCase()}`}>
                        {label}
                    </label>
                )}

                <div className="text-input__input-wrapper" tabIndex={-1}>
                    <input
                        data-id={dataId}
                        className={classes}
                        placeholder={disabled ? null : placeholder}
                        name={name}
                        disabled={disabled}
                        readOnly={readOnly}
                        {...inputProps}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {props.error && (
                        <Tooltip content={props.error as React.ReactElement}>
                            <Button buttonType={ButtonType.ICON} className="text-input__alert-icon" icon={ICONS.ALERT} tabIndex={-1} dataId={`${dataId}.error`} />
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>
    );
};
