'use strict';

angular.module('dstreamApp.controllers').controller('adminChatController', [
    '$scope', '$rootScope', 'webServices', 'signalrSvc', function ($scope, $rootScope, webServices, signalrSvc) {
    	$scope.users = [];
    	$scope.recentChatInfos = [];
    	$scope.selectedUser = {};
    	$scope.msg = '';

    	$scope.isEmpty = function (item) {
    		return (item === null || item === undefined || item.length == 0);
    	}

    	$scope.chatMessages = new Array();

    	var addChatMessage = function (data) {
    		$scope.chatMessages.push(data);
    	};

    	$rootScope.$on("newWidget", function (e, data) {
    		if (data.To == sessionStorage.UserPersonalCode) {
    			webServices.getAllUnreadFrom(sessionStorage.UserPersonalCode, data.From).then(function (response) {
    				var recent = _.filter($scope.recentChatInfos, function (i) {
    					return i.User.PersonalCode == data.From;
    				});
    				// If the user who sent the message is not in the recent list then we need to add him
    				if ($scope.isEmpty(recent)) {
    					webServices.getAllUnreadFrom(sessionStorage.UserPersonalCode, data.From).then(function (response) {
    						$scope.recentChatInfos.push({ User: { PersonalCode: data.From }, Messages: response.data });
    					}, function (data) {
    						console.log(data);
    					});
    				}
    				else// If that user is already in the recent list then we just add the message to his message collection
    				{
    					webServices.getAllUnreadFrom(sessionStorage.UserPersonalCode, data.From).then(function (response) {
    						recent[0].Messages.push.apply(recent[0].Messages, response.data);
    					}, function (data) {
    						console.log(data);
    					});
    				}
    			}, function (data) {
    				console.log(data);
    			});
    		}
    	});

    	$scope.sendChatMessage = function (message) {

    		if ($scope.isEmpty($scope.selectedUser))
    			return;

    		var data = {
    			Message: message,
    			ToUser: $scope.selectedUser.PersonalCode, // In reality this is set when the admin selects the chat window from the specific user in the list
    			FromUser: sessionStorage.UserPersonalCode,
    			ConnectionId: $scope.currentConnectionId,
    			DateSent: new Date(),
    			IsUnread: true
    		};

    		addChatMessage(data); // Add the message to chat window for the current user

    		webServices.sendChatMessage(data)
			.then(function (response) {
			}, function (data) {
				console.log(data);
			});
    	}

    	signalrSvc.initialize();

    	$scope.currentConnectionId = function () {
    		return signalrSvc.proxy.connection.id;
    	}

    	$scope.selectUser = function ($index) {
    		$scope.selectedUser = $scope.users[$index];
    		$scope.chatMessages = new Array();
    	}

    	$scope.selectRecentUser = function ($index) {
    		$scope.selectedUser = $scope.recentChatInfos[$index].User;
    		$scope.chatMessages = $scope.recentChatInfos[$index].Messages;
    		webServices.markMessagesAsRead(sessionStorage.UserPersonalCode, $scope.selectedUser.PersonalCode).then(function (response) {
    			angular.forEach($scope.recentChatInfos[$index].Messages, function (value, key) {
    				value.IsUnread = false;
    			});
    		}, function (data) {
    			console.log(data);
    		});
    	}

    	webServices.getAllRecentAdminChatInfo().then(function (response) {
    		$scope.recentChatInfos = response.data;
    	}, function (data) {
    		console.log(data);
    	});

    }])