import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { TableFilter } from '../../../components/Table/components/filter/TableFilters';
import { GlobalState } from '../../../rootReducer';
import { BaseSearchWithTableFilters, InvoiceTypeDTO, PagedListContainer } from '../../../services/types/ApiTypes';

import { getInvoiceTypesListLoadable } from './InvoiceTypesListActions';
import { createRequest } from './InvoiceTypesListHelpers';

export type InvoiceTypesSearchParams = BaseSearchWithTableFilters<TableFilter<any>, InvoiceTypeDTO>;

class State {
    invoiceTypesListLoadable = new LoadableData<PagedListContainer<InvoiceTypeDTO>, InvoiceTypesSearchParams>();
    searchParams: InvoiceTypesSearchParams = {
        ...createRequest(''),
        filters: {},
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        getInvoiceTypesListLoadable.request,
        (state, action): State => {
            const invoiceTypesListLoadable = state.invoiceTypesListLoadable.withLoading(action.payload);
            return {
                ...state,
                searchParams: action.payload,
                invoiceTypesListLoadable,
            };
        },
    )
    .addReducer(
        getInvoiceTypesListLoadable.success,
        (state, action): State => {
            const invoiceTypesListLoadable = state.invoiceTypesListLoadable.withPayloadIfRequestEquals(action.payload);
            return {
                ...state,
                searchParams: action.payload.request,
                invoiceTypesListLoadable,
            };
        },
    )
    .addReducer(
        getInvoiceTypesListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                invoiceTypesListLoadable: state.invoiceTypesListLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state, action: Action<RouterState>): State => {
            if (/\/customfields/.test(action.payload.location.pathname)) {
                return {
                    ...state,
                };
            }
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'settings/invoice-types',
    whitelist: ['searchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as InvoiceTypesViewState };

export const selectInvoiceTypesListLoadable = (state: GlobalState) => state.invoiceTypes.invoiceTypesListLoadable;
export const selectInvoiceTypesListSearchParams = (state: GlobalState) => state.invoiceTypes.searchParams;
