import { isEmpty } from 'lodash-es';
import { GlobalState } from '../../rootReducer';
import { UserSettingName } from '../../services/types/ApiTypes';
import { DispatchThunk } from '../../storeConfig';
import { getCurrentUserGroupMember, updateUserSettings } from '../../common/user/UserActions';
import { setUserSettingValue } from '../../common/user/userSettingUtil';

export function setEmailVerificationPendingSetting(value: boolean) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
                await dispatch(getCurrentUserGroupMember());
            }
            const state = getState();

            const {
                groupMemberCommonLoadable: { payload: groupMember },
            } = state.user;

            if (!isEmpty(groupMember)) {
                groupMember.UserSettings = setUserSettingValue(UserSettingName.IS_EMAIL_VERIFICATION_PENDING, value, groupMember.UserSettings);

                await dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
                if (!value) {
                    await dispatch(getCurrentUserGroupMember(true));
                }
            }
        } catch (e) {
            console.error(e);
        }
    };
}
