import { AxiosResponse } from 'axios';

import { loadableDataActions } from '../../../../common/utils/LoadableData';
import api from '../../../../services/ApiServices';
import { HistoryDTO } from '../../../../services/types/ApiTypes';
import { DispatchThunk } from '../../../../storeConfig';
import { getCompanyGroupMembersMicro } from '../../../../common/company/CompanyActions';

const ns = 'invoiceHistory/';

export const getInvoiceHistoryLoadable = loadableDataActions<{ invoiceId: number }, HistoryDTO[]>(`${ns}GET_INVOICE_HISTORY`);

export function getInvoiceHistory(invoiceId: number) {
    return async (dispatch: DispatchThunk) => {
        let response: AxiosResponse<HistoryDTO[]>;
        try {
            await dispatch(getCompanyGroupMembersMicro(undefined));
            dispatch(getInvoiceHistoryLoadable.request({ invoiceId }));
            response = await api.invoice.getInvoiceHistory(invoiceId);
            dispatch(getInvoiceHistoryLoadable.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(getInvoiceHistoryLoadable.error(e));
        }
    };
}
