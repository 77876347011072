import { Role } from '../../src/common/user/userPermissionUtil.ts';
import { selectPdfToOpen } from '../../src/components/PDFViewer/FileViewReducer';

"use strict";

angular.module("dstreamApp.controllers").controller("dashboardController", [
  "$scope",
  "$timeout",
  "$location",
  "webServices",
  "notifyService",
  "$compile",
  "invoiceService",
  "utilityService",
  "$rootScope",
  "$routeParams",
  "companyDataService",
  "authenticationService",
  "$ngRedux",
  function (
    $scope,
    $timeout,
    $location,
    webServices,
    notifyService,
    $compile,
    invoiceService,
    utilityService,
    $rootScope,
    $routeParams,
    companyDataService,
    authenticationService, 
    $ngRedux
  ) {
    var ctrl = this;
    $scope.utilityService = utilityService;
    $scope.isPdfOpen = false;
    //Kalender
    $("#zabuto-calendar").zabuto_calendar({
      language: "en",
      today: true
    });

    function setup () {
        if (!authenticationService.isAuthorized(Role.CanViewDashboard) && authenticationService.isAuthorized(Role.CanViewInvoiceRegister) && utilityService.isSystemSetting('isInvoiceRegisterModulActive')) {
            $location.path("/invoices");
      } else if (!authenticationService.isAuthorized(Role.CanViewDashboard) && authenticationService.isAuthorized(Role.CanViewPurchaseOrders) && utilityService.isSystemSetting('IsPurchaseOrdersModulActive')) {
            $location.path("/purchase-orders");
      } else if (!authenticationService.isAuthorized(Role.CanViewDashboard) && authenticationService.isAuthorized(Role.CanViewArchive) && utilityService.isSystemSetting('IsArchiveModulActive')) {
          $location.path("/archive");
      }

      $scope.clock = "loading clock...";
      $scope.tickInterval = 1000;

      var tick = function () {
        $scope.clock = Date.now();
        $timeout(tick, $scope.tickInterval);
      };

      $timeout(tick, $scope.tickInterval);

      fetchCompanySettings();
    }

    $scope.getGroupMemberFullNameById = function (groupMemberId) {
      return invoiceService.getGroupMemberFullNameById(groupMemberId, $rootScope.groupMembers);
    };

    /*
        Get group members
        NB! Currently doesn't actually use fromArchive check since there is no archive displayed on dashboard.
            The check is here in case that logic changes in the future.
     */
    function getGroupMembers () {
      var fromArchive = $routeParams.archive;
      return fromArchive ? companyDataService.getArchiveGroupMembers() : companyDataService.getGroupMembers();
    }

    // Get Company Settings in one call for all Dashboard components 
    function fetchCompanySettings () {
      const {company : {currentCompanySettings, isCurrentCompanySettingsLoading}} = $ngRedux.getState();
      if (!currentCompanySettings || !isCurrentCompanySettingsLoading) {
        companyDataService.getCurrentCompanySettings();
      }
    }

    $ngRedux.subscribe(() => {
      const state = $ngRedux.getState();
      const pdfToOpen = selectPdfToOpen(state);
      if (!!pdfToOpen) {
        $scope.isPdfOpen = true;
      } else if ($scope.isPdfOpen) {
        $scope.isPdfOpen = false;
      }
    })

    let unsubscribeReduxPdf = $ngRedux.connect((state) => ({pdfToOpen: selectPdfToOpen(state)}))($scope);

    setup();

    $scope.$on("$destroy", function () {
      unsubscribeReduxPdf();
    });
  }
]);
