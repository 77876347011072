import { getUserSettingValue } from '../../../../../../common/user/userSettingUtil';
import { GroupMemberCommonDTO, SearchType, SortDirection, UserSettingName } from '../../../../../../services/types/ApiTypes';
import { HistorySearchParams } from './HistoryListReducers';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

export function createRequest(searchValue = '', page = 1, count = 15, columnName = 'AuditDate') {
    const search: HistorySearchParams = {
        SortItems: [
            {
                SortColumn: columnName,
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Count: count,
            Page: page,
        },
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: searchValue || '',
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
    };
    return search;
}

export const HistorySumsPropertyNames = ['Vat', 'TotalAmountWithVat', 'SumWithoutVat'];

export const HistoryDatesPropertyNames = ['AccountingDate', 'DueDate', 'InvoiceDate', 'CurrencyRateDate'];

export const HistoryValuesBoolean: string[] = ['True', 'False'];

export enum HistoryListFilter {
    DataFields = '0',
    PurchaseOrders = '1',
    CustomFields = '2',
}

export const INVOICE_FILTER_CATEGORIES = {
    [HistoryListFilter.DataFields]: 'view.Invoice.History.Filter.Categories.DataFields',
    [HistoryListFilter.PurchaseOrders]: 'view.Invoice.History.Filter.Categories.PurchaseOrders',
    [HistoryListFilter.CustomFields]: 'view.Invoice.History.Filter.Categories.CustomFields',
};

export const getCategorySetting = (groupMember: GroupMemberCommonDTO) => {
    return getUserSettingValue(UserSettingName.INVOICE_HEADER_HISTORY_LIST_CATEGORY, groupMember);
};
