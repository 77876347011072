import { ReducerFactory } from 'redux-actions-ts-reducer';

import { GlobalState } from '../../rootReducer';

import { toggleMainMenuOpen } from './MainMenuActions';

class State {
    isOpen = true;
}

export default new ReducerFactory(new State())
    .addReducer(
        toggleMainMenuOpen,
        (state, action): State => {
            return {
                ...state,
                isOpen: action.payload,
            };
        },
    )
    .toReducer();

export { State as MainMenuState };

export const selectIsMainMenuOpen = (state: GlobalState) => state.mainMenu.isOpen;
