import { LOCATION_CHANGE } from 'connected-react-router';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { ProductItemDTO } from '../../../../services/types/ApiTypes';
import { LoadableData } from '../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../rootReducer';

import { saveProductItemActions, setActiveProductItem, showEditProductItemModal } from './EditProductItemModalActions';

class State {
    activeProductItem: ProductItemDTO | null;
    isEditProductItemModalOpen = false;
    saveProductItemActionLoadable = new LoadableData<ProductItemDTO, ProductItemDTO>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        setActiveProductItem,
        (state, action): State => {
            return {
                ...state,
                activeProductItem: action.payload,
            };
        },
    )
    .addReducer(
        showEditProductItemModal,
        (state, action): State => {
            return {
                ...state,
                isEditProductItemModalOpen: action.payload,
            };
        },
    )
    .addReducer(
        saveProductItemActions.request,
        (state): State => {
            return {
                ...state,
                saveProductItemActionLoadable: state.saveProductItemActionLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        saveProductItemActions.success,
        (state, action): State => {
            return {
                ...state,
                saveProductItemActionLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        saveProductItemActions.error,
        (state, action): State => {
            return {
                ...state,
                saveProductItemActionLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'new-ProductItem-modal',
    whitelist: [],
};

export default persistReducer(persistConfig, reducer);

export { State as EditProductItemModalState };

export const selectActiveProductItem = (state: GlobalState) => state.editProductItemModal.activeProductItem;
export const selectIsEditProductItemModalOpen = (state: GlobalState) => state.editProductItemModal.isEditProductItemModalOpen;
