import { push } from 'connected-react-router';

import { paths, resolvePathParams } from '../../common/router/routePaths';
import { getViewUserSearchParams, ListViewUserConfig } from '../../common/user/userSettingUtil';
import { notify } from '../../common/utils/notify';
import { loadableDataActions, loadableDataActionsWithRequest } from '../../common/utils/LoadableData';
import i18n from '../../i18n';
import { GlobalState } from '../../rootReducer';
import { AutoTransactionDTO, BaseSearch, PagedListContainer, SortDirection, UserSettingName } from '../../services/types/ApiTypes';
import api from '../../services/ApiServices';
import { DispatchThunk } from '../../storeConfig';
import { isEmpty } from 'lodash-es';
import { getCurrentUserGroupMember } from '../../common/user/UserActions';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../common/utils/baseSearchHelpers';
import { updateAppUserSettingsAction } from '../../common/middlewares/userSettings';
import { formatDate, formatFileNameSpaces } from '../../common/utils/formatters';
import { getCurrentCompany } from '../../common/company/CompanyActions';
import { selectCurrentCompanyData } from '../../common/company/CompanySelectors';
import { saveAs } from 'file-saver';
import { ExportTypes } from './AutoTransactionsViewHelper';
import { selectSearchParams } from './AutoTransactionsViewReducer';

const ns = 'auto-transactions/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.AUTOMATION_SORT_DIRECTION,
    sortCol: UserSettingName.AUTOMATION_SORT_COLUMN,
    pageSize: UserSettingName.AUTOMATION_PAGE_SIZE,
};

export const searchAutoTransactionsActions = loadableDataActionsWithRequest<BaseSearch, PagedListContainer<AutoTransactionDTO>>(`${ns}SEARCH_AUTO_TRANSACTIONS`);
export const deleteAutoTransactionActions = loadableDataActions(`${ns}DELETE_AUTO_TRANSACTION`);
export const exportAutoTransactionsActions = loadableDataActions(`${ns}EXPORT_AUTO_TRANSACTIONS`);

export const sortAutoTransactions = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { autoTransactions } = getState();
        const sorting = autoTransactions.searchParams.SortItems[0];
        const searchParams: BaseSearch = {
            ...autoTransactions.searchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };

        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        dispatch(searchAutoTransactions(searchParams));
    };
};

export const setAutoTransactionsPagingOptions = (page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { autoTransactions } = getState();
        const paging = autoTransactions.searchParams.PagingOptions;
        const searchParams: BaseSearch = {
            ...autoTransactions.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };

        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        dispatch(searchAutoTransactions(searchParams));
    };
};

export const filterAutoTransactions = (searchString: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.autoTransactions.searchParams.PagingOptions;
        const searchRestriction = state.autoTransactions.searchParams.Restrictions[0];
        const searchParams: BaseSearch = {
            ...state.autoTransactions.searchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: [
                {
                    ...searchRestriction,
                    Value: searchString,
                },
            ],
        };
        dispatch(searchAutoTransactions(searchParams));
    };
};

export function searchAutoTransactions(searchParams: BaseSearch | undefined) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const {
            autoTransactions,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        searchParams = searchParams || autoTransactions.searchParams;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'RuleName', SortDirection.Asc);

        searchParams = { ...searchParams, ...viewSearchParams };

        // Sort by any other column except Name should have a second sorting criteria - Name
        if (!searchParams.SortItems.find((sortItem) => sortItem.SortColumn === 'RuleName')) {
            searchParams.SortItems.push({
                SortColumn: 'RuleName',
                SortDirection: 0,
            });
        }

        let response;
        try {
            dispatch(searchAutoTransactionsActions.request(searchParams));
            response = await api.autoTransaction.getAutoTransactionsList(searchParams);
            dispatch(
                searchAutoTransactionsActions.success({
                    request: searchParams,
                    result: response.data,
                }),
            );
            // if our page for some reason is empty, but there is data on previous pages, then load previous pages until we have some data to display
            if (response.data.Items.length === 0 && response.data.HasCount) {
                const currentPage = autoTransactions.searchParams.PagingOptions.Page;
                if (currentPage > 1) {
                    dispatch(setAutoTransactionsPagingOptions(currentPage - 1));
                    return;
                }
            }
        } catch (e) {
            console.error(e);
            dispatch(
                searchAutoTransactionsActions.error({
                    request: searchParams,
                    result: e,
                }),
            );
        }
    };
}

export function deleteAutoTransaction(atId: number) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const state = getState();
            dispatch(deleteAutoTransactionActions.request(atId));
            const response = await api.autoTransaction.deleteAutoTransaction(atId);
            dispatch(deleteAutoTransactionActions.success(response.data));
            if (state.autoTransactions.autoTransactionsLoadable.payload && state.autoTransactions.autoTransactionsLoadable.payload.Items.length === 1) {
                // we deleted the last item, so go to previous page
                const currentPage = state.autoTransactions.searchParams.PagingOptions.Page;
                if (currentPage > 1) {
                    dispatch(setAutoTransactionsPagingOptions(currentPage - 1));
                    return;
                }
            }
            dispatch(searchAutoTransactions(undefined));
        } catch (e) {
            console.error(e);
            dispatch(deleteAutoTransactionActions.error(e));
        }
    };
}

export function openAutoTransaction(atId: number, isSnapshot?: boolean) {
    return (dispatch: DispatchThunk) => {
        dispatch(push(resolvePathParams(isSnapshot ? paths.app.addAutoTransactionSnapshot : paths.app.autoTransactionDetails, { id: atId })));
    };
}

export const duplicateAutoTransactionActions = loadableDataActions(`${ns}DUPLICATE_AUTO_TRANSACTION`);
export function duplicateAutoTransaction(atId: number) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(duplicateAutoTransactionActions.request(atId));
            const response = await api.autoTransaction.duplicateAutoTransaction(atId);
            dispatch(duplicateAutoTransactionActions.success(response.data));
            dispatch(searchAutoTransactions(undefined));
        } catch (e) {
            console.error(e);
            dispatch(duplicateAutoTransactionActions.error(e));
        }
    };
}

export const toggleRuleActivityActions = loadableDataActions<number, any>(`${ns}TOGGLE_RULE_ACTIVITY`);
export function toggleRuleActivity(at: AutoTransactionDTO) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(toggleRuleActivityActions.request(at.Id));
            const response = await api.autoTransaction.toggleRuleActivity(at.Id);
            dispatch(toggleRuleActivityActions.success(response.data));
            if (at.IsActive) {
                notify.success(i18n.t('component.AutoTransaction.Settings.RuleDisabled'), i18n.t('component.AutoTransaction.SuccessHeader'));
            } else {
                notify.success(i18n.t('component.AutoTransaction.Settings.RuleEnabled'), i18n.t('component.AutoTransaction.SuccessHeader'));
            }
        } catch (e) {
            console.error(e);
            dispatch(toggleRuleActivityActions.error(e));
        }
    };
}

export function exportAutoTransactions(exportType: ExportTypes) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        if (isEmpty(selectCurrentCompanyData(getState())) || getState().user?.currentCompany?.CompanyGuid !== selectCurrentCompanyData(getState())?.CompanyGuid) {
            await dispatch(getCurrentCompany());
        }

        dispatch(exportAutoTransactionsActions.request(undefined));
        let autoTransactionsSearchParams = selectSearchParams(getState());
        const groupMember = getState().user.groupMemberCommonLoadable.payload;
        const viewSearchParams = getViewUserSearchParams(autoTransactionsSearchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'RuleName', SortDirection.Asc);
        autoTransactionsSearchParams = { ...autoTransactionsSearchParams, ...viewSearchParams };
        try {
            let fileName;
            let file;
            const response = await api.autoTransaction.exportAutoTransactions(autoTransactionsSearchParams, exportType);
            const companyData = selectCurrentCompanyData(getState());
            const companyCode = companyData?.VatCode || companyData?.RegistrationCode;
            const fileDate = formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss');
            if (exportType === ExportTypes.CSV) {
                fileName = `${companyData?.CompanyName}_${companyCode}_Autotransactions_${fileDate}.csv`;
                file = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            } else {
                fileName = `${companyData?.CompanyName}_${companyCode}_Autotransactions_${fileDate}.xlsx`;
                file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            }
            saveAs(file, formatFileNameSpaces(fileName));
            dispatch(exportAutoTransactionsActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportAutoTransactionsActions.error(e));
        }
    };
}
