import React from 'react';
import { createDataId } from '../../../../common/utils/dataId';
import './InfoTextBlock.scss';

interface InfoBlockProps {
    header: string;
    dataId: string;
    text: string;
}

const InfoTextBlock = ({ header, dataId, text }: InfoBlockProps) => {
    return (
        <div className="info-wrapper" data-id={createDataId(dataId, 'info-block')}>
            <p className="info-header">
                <span className="icon">i</span>
                {header}
            </p>
            <span className="info-body">{text}</span>
        </div>
    );
};

export default InfoTextBlock;
