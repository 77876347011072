'use strict';

angular.module('dstreamApp.controllers').controller('confirmationController', ['$scope',
	'$uibModalInstance', 'question', 'title', function ($scope, $uibModalInstance, question, title) {
	    $scope.question = question;
        $scope.title = title;

	    $scope.cancel = function () {
	        $uibModalInstance.close(false);
	    }

	    $scope.close = function () {
	        $uibModalInstance.dismiss();
	    }

	   $scope.confirm = function () {
	       $uibModalInstance.close(true);
	    }
	}]);