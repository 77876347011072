import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { WrapperState } from 'react-aria-menubutton';

import { createDataId } from '../../common/utils/dataId';
import withFormikField from '../../common/utils/withFormikField';
import Checkbox from '../Checkbox/Checkbox';
import { SelectMenu } from './SelectMenu';
import { SelectOption, SelectOptionValue } from './SelectOption';
import { SelectTrigger } from './SelectTrigger';
import { SelectWrapper } from './SelectWrapper';
import { ICONS } from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import { Button, ButtonType } from '../Buttons/Button';
import { LabelStyle } from '../TextInput/TextInput';

export interface SelectProps<T> {
    value: SelectOptionValue<T> | SelectOptionValue<T>[];
    name?: string;
    items?: SelectOptionValue<T>[];
    loadItems?: () => Promise<SelectOptionValue<T>[]>;
    onChange?: (value: SelectOptionValue<T>) => void;
    isOpen?: boolean;
    isCompact?: boolean;
    isTranslatable?: boolean;
    handleMenuToggle?: (value: WrapperState) => void;
    label?: string;
    labelStyle?: LabelStyle;
    className?: string;
    placeholder?: string;
    onlyIconOnSelected?: boolean; // if true, only icon is shown on selected option (no additional text)
    dataId: string;
    disabled?: boolean;
    triggerIcon?: string;
    hideIcon?: boolean;
    error?: React.ReactNode;
    hideError?: boolean;
    selectMenuMaxHeight?: number;
    menuOpenUp?: boolean;
    multiselect?: boolean;
    closeOnSelection?: boolean;
    // only for multiselect
    readonlyOptions?: string[];
}

const getLoadItems = async <T,>(callback: () => Promise<SelectOptionValue<T>[]>) => {
    if (callback) {
        return await callback();
    }
    return [];
};

export const Select = <T,>(props: SelectProps<T>) => {
    const {
        value,
        error,
        hideError,
        triggerIcon,
        hideIcon,
        items,
        menuOpenUp,
        loadItems,
        onChange,
        onlyIconOnSelected,
        isOpen,
        isCompact,
        isTranslatable,
        handleMenuToggle,
        label,
        labelStyle,
        className,
        placeholder,
        selectMenuMaxHeight,
        dataId,
        disabled,
        multiselect,
        closeOnSelection = true,
        readonlyOptions,
    } = props;
    const [selectItems, setSelectItems] = useState<SelectOptionValue<T>[]>([]);

    useEffect(() => {
        if (items && items.length > 0) {
            setSelectItems(items as SelectOptionValue<T>[]);
        }
        if (loadItems) {
            getLoadItems(loadItems).then((response) => {
                if (response.length > 0) {
                    setSelectItems(response);
                }
            });
        }
    }, [items, loadItems]);

    const getTriggerContent = () => {
        if (Array.isArray(value)) {
            const isError = error && !hideError;
            const className = classNames('select-option--custom-child', { 'select-option--error': isError, 'select__trigger--error': isError });

            return (
                <SelectOption isTranslatable={isTranslatable} dataId={createDataId(dataId, 'selected')} className={className}>
                    <div className="select-option__multiselect--value">{value?.map((el) => el?.text).join(', ')}</div>
                </SelectOption>
            );
        }

        if (error && !hideError) {
            const errorClasses = classNames('select__trigger--error', 'select-option--error');
            return (
                <SelectOption isTranslatable={isTranslatable} value={value} dataId={createDataId(dataId, 'selected')} className={errorClasses}>
                    <Tooltip content={error as React.ReactElement}>
                        <Button buttonType={ButtonType.ICON} className="select-option__alert-icon" icon={ICONS.ALERT} tabIndex={-1} dataId={`${dataId}.error`} />
                    </Tooltip>
                </SelectOption>
            );
        }
        if (value) {
            if (value.element) {
                return (
                    <SelectOption isTranslatable={isTranslatable} value={value} dataId={createDataId(dataId, 'selected')} className="select-option--custom-child">
                        {value.element}
                    </SelectOption>
                );
            }
            if (value.value || value.text === '--') {
                return (
                    <SelectOption
                        text={(value as SelectOptionValue<T>).text}
                        value={value as SelectOptionValue<T>}
                        icon={!hideIcon ? (value as SelectOptionValue<T>).icon : null}
                        dataId={createDataId(dataId, 'selected')}
                        isTranslatable={isTranslatable}
                    >
                        {onlyIconOnSelected && value.icon && <></>}
                    </SelectOption>
                );
            }
        }
        return <SelectOption isTranslatable={isTranslatable} className="select-option--placeholder" text={placeholder} value={value} dataId={createDataId(dataId, 'placeholder')} />;
    };

    const getOptionElement = (menuItem: SelectOptionValue<T>, index: number) => {
        if (menuItem.element) {
            return (
                <SelectOption isTranslatable={isTranslatable} key={index} value={menuItem} dataId={createDataId(dataId, 'option')} className={'select-option--custom-child'}>
                    {menuItem.element}
                </SelectOption>
            );
        }
        if (multiselect) {
            const searchValue = Array.isArray(value) ? value : [value];
            const isSelected = Boolean((searchValue as SelectOptionValue<any>[])?.find((el) => el.value === menuItem.value));
            const isDisabled = readonlyOptions?.includes(menuItem.value as string);
            return (
                <SelectOption isTranslatable={isTranslatable} key={index} value={menuItem} dataId={createDataId(dataId, 'option')} className={'select-option--custom-child'}>
                    <div className="select-option--multiselect-option">
                        <Checkbox name={menuItem.text} value={isSelected} disabled={isDisabled} disableChange />
                        <div className="select-option--multiselect-option__content">{menuItem.text}</div>
                    </div>
                </SelectOption>
            );
        }
        return (
            <SelectOption
                isTranslatable={isTranslatable}
                value={menuItem}
                text={menuItem.text || placeholder}
                icon={!hideIcon ? menuItem.icon : null}
                key={index}
                dataId={createDataId(dataId, 'option')}
            />
        );
    };

    const wrapperClasses = classNames(className, {
        'select--has-error': !!error,
    });

    return (
        <SelectWrapper
            isOpen={isOpen}
            isCompact={isCompact}
            className={wrapperClasses}
            label={label}
            labelStyle={labelStyle}
            onMenuToggle={handleMenuToggle}
            closeOnSelection={closeOnSelection}
            onSelection={(val) => {
                const isOptionDisabled = readonlyOptions?.includes(val.value as string);
                if (isOptionDisabled) {
                    return;
                }
                onChange(val as SelectOptionValue<T>);
            }}
        >
            <SelectTrigger dataId={dataId} disabled={disabled} icon={triggerIcon} isCompact={isCompact}>
                {getTriggerContent()}
            </SelectTrigger>
            {selectItems?.length > 0 && (
                <SelectMenu menuHeight={selectMenuMaxHeight} menuOpenUp={menuOpenUp} dataId={dataId}>
                    {selectItems.map((menuItem, index) => getOptionElement(menuItem, index))}
                </SelectMenu>
            )}
        </SelectWrapper>
    );
};

export const SelectField = withFormikField(Select);
