import * as React from 'react';
import { LogoProps } from '../Logo';

const FitekInLogo: React.FunctionComponent<LogoProps> = ({ compact, className }: LogoProps) =>
    compact ? (
        <svg width="20" height="22" viewBox="0 0 10 12" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.77161 0.00407892C9.96567 0.0251244 10.0304 0.130352 9.98723 0.340807L9.70692 1.79294C9.6638 1.98235 9.55599 2.06654 9.38349 2.06654H5.48076C5.11421 2.06654 4.87703 2.15072 4.72609 2.29804C4.57516 2.44535 4.46735 2.6979 4.42422 3.01358L3.99298 5.07604H7.93884C8.13289 5.07604 8.21914 5.18127 8.17602 5.37068L7.87415 6.84386C7.83103 7.03327 7.72322 7.13849 7.52916 7.13849H3.5833L2.65613 11.7475C2.63457 11.9158 2.52676 12 2.35427 12H0.219622C0.0471258 12 -0.0175603 11.9158 0.00400173 11.7475L1.85834 2.50849C1.94459 2.04549 2.07396 1.66667 2.24645 1.32994C2.39739 1.01426 2.61301 0.761716 2.89332 0.551261C3.17362 0.361852 3.49705 0.214534 3.88517 0.130352C4.27329 0.0461699 4.74765 -0.0169666 5.2867 0.00407892H9.77161Z"
                fill="white"
            />
        </svg>
    ) : (
        <svg width="89" height="30" viewBox="0 0 89 30" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.7048 5.56418C19.9755 5.59125 20.0837 5.75366 20.0296 6.07849L19.5965 8.29815C19.5424 8.56884 19.38 8.70419 19.1093 8.70419H13.2623C12.721 8.70419 12.342 8.81247 12.1254 9.05609C11.9089 9.29971 11.7465 9.67868 11.6653 10.1659L11.0427 13.3059H16.9708C17.2686 13.3059 17.3768 13.4413 17.3227 13.739L16.8896 16.0128C16.8355 16.3106 16.673 16.4459 16.3753 16.4459H10.4472L9.03956 23.538C9.01249 23.8087 8.85008 23.917 8.57939 23.917H5.38524C5.11455 23.917 5.00627 23.7817 5.06041 23.538L7.82146 9.38092C7.9568 8.67712 8.14628 8.0816 8.38991 7.59436C8.63353 7.10712 8.95836 6.70108 9.36439 6.40332C9.77043 6.10556 10.2577 5.88901 10.8532 5.75366C11.4487 5.61832 12.1254 5.53711 12.9375 5.53711L19.7048 5.56418Z"
                fill="white"
            />
            <path
                d="M20.977 11.0321C21.0311 10.7343 21.1935 10.5719 21.4913 10.5719H24.3877C24.6854 10.5719 24.7937 10.7343 24.7396 11.0321L22.3034 23.5109C22.2492 23.8087 22.0868 23.944 21.789 23.944H18.8927C18.5949 23.944 18.4866 23.8087 18.5408 23.5109L20.977 11.0321Z"
                fill="white"
            />
            <path
                d="M27.961 10.5719L28.5836 7.45896C28.6377 7.1612 28.8001 6.99878 29.0979 6.99878H31.9943C32.292 6.99878 32.4003 7.1612 32.3462 7.45896L31.7236 10.5719H35.0531C35.3508 10.5719 35.4591 10.7072 35.405 11.005L34.9989 13.0623C34.9448 13.36 34.7824 13.4954 34.4846 13.4954H31.1551L30.0453 19.234C29.9641 19.5588 29.937 19.8566 29.937 20.1002C29.937 20.3438 29.9912 20.5604 30.0994 20.7228C30.2077 20.8852 30.3701 20.9935 30.5867 21.0747C30.8032 21.1559 31.101 21.183 31.48 21.183C31.7777 21.183 32.0755 21.183 32.3462 21.1559C32.6439 21.1288 32.9417 21.1018 33.2124 21.0747C33.4831 21.0476 33.5643 21.183 33.5101 21.3995L33.1853 23.1861C33.1312 23.4026 32.9688 23.5651 32.6981 23.6733C32.3462 23.8087 31.9131 23.917 31.3988 23.9982C30.9115 24.0794 30.4243 24.1064 29.9641 24.1064C29.0979 24.1064 28.3941 23.9982 27.8527 23.8087C27.3113 23.5921 26.8782 23.2944 26.5805 22.9154C26.2827 22.5094 26.1203 22.0492 26.0391 21.4807C25.9849 20.9123 26.012 20.2897 26.1474 19.5859L27.3384 13.4954L27.961 10.5719Z"
                fill="white"
            />
            <path
                d="M47.8296 11.8443C47.0175 10.8427 45.637 10.3555 43.661 10.3555C41.4142 10.3555 39.6548 10.9239 38.3825 12.0608C37.1103 13.1977 36.2711 14.7677 35.8922 16.7438L35.7297 17.5558C35.5132 18.5845 35.459 19.5048 35.5673 20.344C35.6485 21.156 35.9463 21.8598 36.3794 22.4283C36.8396 22.9967 37.4622 23.4298 38.2742 23.7276C39.0863 24.0254 40.1149 24.1607 41.3601 24.1607C42.0368 24.1607 42.7677 24.1066 43.5527 24.0253C44.3106 23.9441 45.0415 23.7817 45.7182 23.5922C45.9889 23.511 46.1243 23.3486 46.1784 23.105L46.5303 21.2643C46.5844 20.9936 46.4491 20.9124 46.1784 20.9665C45.637 21.0477 45.0144 21.129 44.3377 21.156C43.661 21.2102 43.0113 21.2102 42.3617 21.2102C41.3872 21.2102 40.6292 21.0207 40.1149 20.6688C39.6006 20.3169 39.357 19.7214 39.357 18.8822H44.0129C45.231 18.8822 46.2325 18.6386 47.0175 18.1514C47.8296 17.6641 48.3169 16.9062 48.5063 15.8505C48.8582 14.1722 48.6417 12.8458 47.8296 11.8443ZM44.852 15.4174C44.7979 15.661 44.6896 15.8505 44.5272 15.9858C44.3648 16.1212 44.1482 16.2024 43.8505 16.2024H39.7901C40.0879 15.1467 40.521 14.3888 41.0623 13.9557C41.6308 13.5226 42.2534 13.306 42.9842 13.306C43.7693 13.306 44.3106 13.4955 44.6084 13.8474C44.9061 14.1993 44.9874 14.7136 44.852 15.4174Z"
                fill="white"
            />
            <path
                d="M57.7367 17.1227L61.3099 23.3486C61.3911 23.511 61.4181 23.6463 61.364 23.7817C61.3099 23.917 61.2016 23.9712 61.0121 23.9712H57.7909C57.5202 23.9712 57.3578 23.8629 57.2495 23.6734L54.1095 17.7453L52.9996 23.5381C52.9455 23.8358 52.7831 23.9712 52.4853 23.9712H49.5889C49.2912 23.9712 49.1829 23.8358 49.237 23.5381L52.6477 6.02438C52.7019 5.72662 52.8643 5.56421 53.1621 5.56421H56.0314C56.3291 5.56421 56.4374 5.72662 56.3833 6.02438L54.4072 16.1482L59.4692 10.8427C59.6316 10.6532 59.8481 10.5449 60.1188 10.5449H63.34C63.5295 10.5449 63.6378 10.5991 63.6378 10.7073C63.6649 10.8156 63.5837 10.951 63.4483 11.0863L57.7367 17.1227Z"
                fill="white"
            />
            <path
                style={{
                    stroke: '#FEC017',
                }}
                d="M0.621094 1.20605H37.3538L37.4892 0.44812H0.756439L0.621094 1.20605Z"
            />
            <path
                style={{
                    stroke: '#FEC017',
                }}
                d="M42.9841 29.0331H87.4857L87.621 28.2751H43.1195L42.9841 29.0331Z"
            />
            <path
                d="M21.4373 8.7312L22.0057 5.94309C22.0598 5.72653 22.2223 5.59119 22.4388 5.59119H25.4976C25.66 5.59119 25.7683 5.72653 25.7412 5.88895L25.3623 7.91913C25.2811 8.3793 24.875 8.7312 24.3878 8.7312H21.4373Z"
                fill="white"
            />
            <path
                d="M70.811 5.86197C70.8381 5.69955 70.7298 5.56421 70.5674 5.56421H67.5086C67.2921 5.56421 67.1026 5.72662 67.0755 5.9161C66.101 10.7615 64.4498 18.9363 63.6377 23.511C63.5836 23.8088 63.7189 23.9441 63.9896 23.9441H66.886C67.1838 23.9441 67.3462 23.8088 67.4003 23.511L70.811 5.86197Z"
                fill="#FEC017"
            />
            <path
                d="M70.9764 23.9144C70.7198 23.9144 70.5268 23.6805 70.5754 23.4286L73.8262 6.58875C73.9076 5.92558 74.3634 5.59399 75.1934 5.59399H79.5992C80.2665 5.59399 80.6897 5.894 80.8687 6.49401L82.3135 19.2732L84.8979 5.97184C84.9352 5.77993 85.1033 5.64137 85.2988 5.64137L88.1462 5.64136C88.4029 5.64136 88.596 5.87551 88.547 6.12756L85.2724 22.9907C85.1911 23.6065 84.7434 23.9144 83.9297 23.9144H80.6095C79.926 23.9144 79.4783 23.5749 79.2668 22.8959L77.3747 9.12301L74.2823 23.5914C74.242 23.7797 74.0756 23.9144 73.8829 23.9144H70.9764Z"
                fill="#FEC017"
            />
        </svg>
    );

export default FitekInLogo;
