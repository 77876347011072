import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';

import { LoadableData } from '../../common/utils/LoadableData';
import { GlobalState } from '../../rootReducer';
import { BaseSearch, PagedListContainer, PurchaseOrdersDTO } from '../../services/types/ApiTypes';
import { createRequest } from '../purchase-orders/PurchaseOrdersViewHelper';

import { dashboardPurchaseOrdersLoadable } from './DashboardPurchaseOrdersActions';

class State {
    dashboardPurchaseOrdersLoadable = new LoadableData<PagedListContainer<PurchaseOrdersDTO>, BaseSearch>();
    searchParams: BaseSearch = createRequest('', 1);
}

export default new ReducerFactory(new State())
    .addReducer(
        dashboardPurchaseOrdersLoadable.request,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload,
                dashboardPurchaseOrdersLoadable: state.dashboardPurchaseOrdersLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        dashboardPurchaseOrdersLoadable.success,
        (state, action): State => {
            return {
                ...state,
                dashboardPurchaseOrdersLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        dashboardPurchaseOrdersLoadable.error,
        (state, action): State => {
            return {
                ...state,
                dashboardPurchaseOrdersLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state, action: Action<RouterState>): State => {
            if (/\/dashboard|\/details/.test(action.payload.location.pathname)) {
                return {
                    ...state,
                };
            }
            return new State();
        },
    )
    .toReducer();

export { State as DashboardPurchaseOrdersState };

export const selectDashboardPurchaseOrdersLoadable = (state: GlobalState) => state.dashboardPurchaseOrders.dashboardPurchaseOrdersLoadable;
