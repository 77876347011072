import React from 'react';
import { TFunction } from 'i18next';

import { Button, ButtonType, ButtonSize } from '../../../../../components/Buttons/Button';
import { ConfirmationFlowActions } from '../../../../../common/constants/invoiceConstants';
import { validateInvoiceConfirm, createInvoiceAction, getApproveAllConfirmersByNamePart } from '../InvoiceConfirmationsHelper';
import { eventTrack } from '../../../../../common/analytics/gtm';
import { TextInput, TextInputType } from '../../../../../components/TextInput/TextInput';
import { TypeaheadAsync, TypeaheadItem } from '../../../../../components/Typeahead/TypeaheadAsync';
import { GroupTasksDTO, TaskType } from '../InvoiceConfirmations';
import { InvoiceDTO } from '../../../../../services/types/ApiTypes';
import CommentInfo from './CommentInfo';

interface AssignBeforeAfterProps {
    invoice: InvoiceDTO;
    t: TFunction;
    editVisible?: boolean;
    approveInvoice: any;
    groupTask: GroupTasksDTO;
    onCancel: (task: GroupTasksDTO) => void;
}
export const AssignBeforeAfter = ({ invoice, onCancel, groupTask, approveInvoice, t }: AssignBeforeAfterProps): React.ReactElement => {
    const maxCommentLength = 500;

    const [comment, setComment] = React.useState('');
    const [nextConfirmer, updateNextConfirmer] = React.useState<TypeaheadItem<any>>(undefined);
    const [errors, setErrors] = React.useState({
        comment: undefined,
        approver: undefined,
    });

    const resetNextConfirmer = () => {
        updateNextConfirmer({
            value: undefined,
            text: undefined,
        });
    };

    const handleActions = () => {
        if (nextConfirmer && nextConfirmer.value) {
            const stepNumber = groupTask.type === TaskType.AddAfterMe ? groupTask.step + 1 : groupTask.step;
            if (validateInvoiceConfirm(invoice, ConfirmationFlowActions.Assign, nextConfirmer.value)) {
                approveInvoice(createInvoiceAction(ConfirmationFlowActions.Assign, invoice.Id, comment, nextConfirmer.value), stepNumber);
                eventTrack({
                    event: 'confirmations',
                    label: 'Assign',
                });
            }
        } else {
            setErrors({
                ...errors,
                approver: t('component.confirmationFlow.chooseUser.error'),
            });
        }
        resetNextConfirmer();
        return;
    };

    const updateComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        if (value.length >= maxCommentLength) {
            value = value.substring(0, maxCommentLength);
            //notify.info(t('General.Notification.Message.DataTrimmed'));
        }
        setComment(value);
    };

    return (
        <div className="confirmation__add-approver">
            <TypeaheadAsync
                wrapperClass="confirmation__choose-confirmation"
                dataId={'choose-confirmation-flow'}
                loadItems={(name?: string) => {
                    return getApproveAllConfirmersByNamePart(name, groupTask.group && groupTask.group[0], false, invoice.Status, false);
                }}
                error={errors.approver}
                inputProps={{
                    autofocus: false,
                    showClear: false,
                    type: TextInputType.BORDERED,
                }}
                placeholder={t('component.confirmationFlow.chooseUser.placeholder')}
                searchOnFocus={true}
                toggleVisible={true}
                value={nextConfirmer}
                onBlur={() => setErrors({ ...errors, approver: undefined })}
                onChange={(item) => {
                    updateNextConfirmer(item);
                }}
            />
            <TextInput
                dataId={'add-approver-comment'}
                type={TextInputType.COMPACT}
                placeholder={t('component.transactionRows.addComment')}
                value={comment}
                textarea={true}
                //onFocus={() => setErrors({ ...errors, comment: undefined })}
                onChange={(item) => {
                    item && updateComment(item);
                }}
                //error={errors.comment}
                maxLength={maxCommentLength}
                addonAfter={<span className="action-block__length-comment">{`${comment?.length || 0}/${maxCommentLength}`}</span>}
                //onBlur={trimSpacesOnBlur}
            />
            {comment && comment.length > 0 && <CommentInfo content={t('component.commentInfo.deletedAfterAssignedUserAaction')} />}
            <div className="confirmation__wrapper-actions">
                <Button buttonType={ButtonType.PRIMARY} buttonSize={ButtonSize.SMALL} className={`confirmation__button-assign`} onClick={handleActions}>
                    {t('views.global.Add')}
                </Button>
                <Button buttonType={ButtonType.SECONDARY} className={`confirmation__button-assign`} buttonSize={ButtonSize.SMALL} onClick={() => onCancel(groupTask)}>
                    {t('views.global.Cancel')}
                </Button>
            </div>
        </div>
    );
};
