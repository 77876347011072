"use strict";

angular.module("dstreamApp.filters", []).filter("optionsFilter", function () {
  return function (options, searchText) {
    var filtered = [{Name: "Lisa uus..."}];
    searchText = searchText.toLowerCase();
    angular.forEach(options, function (option) {
      if (option.Name === undefined) return;
      if (option.Name.toLowerCase().indexOf(searchText) !== -1) { filtered.push(option); }
    });
    return filtered;
  };
});

angular.module("dstreamApp.filters").filter("sessionTimer", function () {
  return function (milliseconds) {
    if (milliseconds > 60000) {
      return Math.ceil(milliseconds / 60000);
    } else {
      return milliseconds / 1000;
    }
  };
});

angular.module("dstreamApp.filters").filter("tagInputFilter", function () {
  return function (array, query) {
    var filteredArray = array;
    for (var i = filteredArray.length - 1; i >= 0; i--) {
      if (Array.isArray(filteredArray[i].Value)) {
        var count = 0;
        for (var j = filteredArray[i].Value.length - 1; j >= 0; j--) {
          if (filteredArray[i].Value[j].Name && filteredArray[i].Value[j].Name.toLowerCase().indexOf(query.toLowerCase()) === -1) {
            filteredArray[i].Value[j].visible = false;
          } else {
            filteredArray[i].Value[j].visible = true;
            count++;
          }
        }

        filteredArray[i].visible = !!count;
      } else {
        filteredArray[i].visible = !(filteredArray[i].Name && filteredArray[i].Name.toLowerCase().indexOf(query.toLowerCase()) === -1);
      }
    }
    return filteredArray;
  };
});

angular.module("dstreamApp.filters").filter("tagInputTranslate", [
  "$filter",
  function ($filter) {
    return function (array) {
      for (var i = 0; i < array.length; i++) {
        if (Array.isArray(array[i].Value)) {
          for (var j = 0; j < array[i].Value.length; j++) {
            if (array[i].Value[j].TranslateKey) {
              array[i].Value[j].Name = $filter("translate")(array[i].Value[j].TranslateKey);
            }
          }
        }
      }

      return array;
    };
  }
]);

angular.module("dstreamApp.filters").factory("truncate", function () {
  return function (input, allowed) {
    allowed = (((allowed || "") + "")
      .toLowerCase()
      .match(/<[a-z][a-z0-9]*>/g) || [])
      .join("");
    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    var commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return input
      .replace(commentsAndPhpTags, "")
      .replace(tags, function ($0, $1) {
        return allowed.indexOf("<" + $1.toLowerCase() + ">") > -1 ? $0 : "";
      });
  };
});

angular.module("dstreamApp.filters").filter("trimWhitespace", function () {
  return function (item) {
    if (!item) return item;
    return item.replace(/\s/g, "");
  };
});

angular.module("dstreamApp.filters").filter("getDocTypeName", function () {
  return function (item) {
    if (!item) { return item; }
    return item[1];
  };
});

angular.module("dstreamApp.filters").filter("limitItems", function () {
  return function (items, value) {
    if (!items) { return; }
    var filtered = [];
    for (var i = 0; i < items.length - 1; i += 1) {
      if (i === value) break;
      filtered.push(items[i]);
    }
    return filtered;
  };
});

angular.module("dstreamApp.filters").filter("excludeExisting", function () {
  function getItems (providedItems, existing, param, param2) {
    var filtered = [];
    if (!existing || !existing.length) {
      return providedItems;
    }
    for (var i = 0; i <= providedItems.length - 1; i += 1) {
      var cntr = 0;
      for (var j = 0; j <= existing.length - 1; j += 1) {
        if (param2) {
          if (existing[j][param2] === providedItems[i][param]) {
            cntr += 1;
          }
        } else {
          if (existing[j][param] === providedItems[i][param]) {
            cntr += 1;
          }
        }
      }
      if (!cntr) filtered.push(providedItems[i]);
    }
    return filtered;
  }

  return function (items, existing, param, param2) {
    if (!items) return;
    if (items.constructor !== Array) {
      var itemsArray = [];
      Object.keys(items).forEach(function (key) {
        itemsArray.push(items[key]);
      });
    }
    if (itemsArray) {
      return getItems(itemsArray, existing, param, param2);
    } else {
      return getItems(items, existing, param, param2);
    }
  };
});

angular.module("dstreamApp.filters").filter("customCostObjectiveName",
  function () {
    return function (item, templates) {
      if (!item) return;
      var name = "";
      templates.forEach(function (template) {
        if (template.Id === item.CustomCostObjectiveId) {
          name = template.Name;
        }
      });
      return name;
    };
  });

angular.module("dstreamApp.filters").filter("customCostObjectiveValue",
  function () {
    return function (item) {
      if (!item) return;
      var value = "";
      item.Dimensions.forEach(function (templateItem) {
        if (templateItem.Id === item.CostObjectiveId) {
          value = templateItem.Name;
        }
      });
      return value;
    };
  });

angular.module("dstreamApp.filters").filter("findCustomFieldValue", function () {
  return function (item, descriptor, param) {
    if (!item || !descriptor || !param) {
      return null;
    } else if (!_.isEmpty(descriptor.Dimension)) {
      return (descriptor.Dimension.Code + " - " + descriptor.Dimension[param]);
    } else {
      return null;
    }
  };
});

angular.module("dstreamApp.filters").filter("allocationAmount", function () {
  return function (item, old) {
    var regex = /^(100)$|^(100\.[0]{0,2})$|^([0-9]{0,2})$|^([0-9]{0,2}\.[0-9]{0,2})$/;
    if (regex.test(item)) {
      return (parseFloat(item).toFixed(2));
    } else {
      if (item < 100 && item >= 0) {
        return parseFloat(item).toFixed(2);
      } else if (item > 100) {
        return (100).toFixed(2);
      } else {
        return (0).toFixed(2);
      }
    }
  };
});

angular.module("dstreamApp.filters")
  .filter("toSumFormat", function() {
    return function(item) {
      return parseFloat(item).toFixed(2);
    }
  })

  angular.module("dstreamApp.filters")
  .filter("formatThousands", function() {
    return function(item) {
      return item ? item.replace(/,/g, ' ') : item;
    }
  })
