import { LOCATION_CHANGE } from 'connected-react-router';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { LoadableData } from '../../common/utils/LoadableData';
import { GlobalState } from '../../rootReducer';
import { SupplierDTO } from '../../services/types/ApiTypes';
import { addSupplierActions, setActiveSupplier, showEditSupplierModal, updateSupplierActions } from './EditSupplierModalActions';

class State {
    activeSupplier: SupplierDTO = null;
    isEditSupplierModalOpen = false;
    addSupplierLoadable = new LoadableData<SupplierDTO, SupplierDTO>();
    updateSupplierLoadable = new LoadableData<SupplierDTO, SupplierDTO>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        setActiveSupplier,
        (state, action): State => {
            return {
                ...state,
                activeSupplier: action.payload,
            };
        },
    )
    .addReducer(
        showEditSupplierModal,
        (state, action): State => {
            return {
                ...state,
                isEditSupplierModalOpen: action.payload,
            };
        },
    )
    .addReducer(
        addSupplierActions.request,
        (state): State => {
            return {
                ...state,
                addSupplierLoadable: state.addSupplierLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        addSupplierActions.success,
        (state, action): State => {
            return {
                ...state,
                addSupplierLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        addSupplierActions.error,
        (state, action): State => {
            return {
                ...state,
                addSupplierLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        updateSupplierActions.request,
        (state): State => {
            return {
                ...state,
                updateSupplierLoadable: state.updateSupplierLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        updateSupplierActions.success,
        (state, action): State => {
            return {
                ...state,
                updateSupplierLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        updateSupplierActions.error,
        (state, action): State => {
            return {
                ...state,
                updateSupplierLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'new-supplier-modal',
    whitelist: [],
};

export default persistReducer(persistConfig, reducer);

export { State as EditSupplierModalState };

export const selectActiveSupplier = (state: GlobalState) => state.editSupplierModal.activeSupplier;
export const selectIsEditSupplierModalOpen = (state: GlobalState) => state.editSupplierModal.isEditSupplierModalOpen;
export const selectAddSupplierLoadable = (state: GlobalState) => state.editSupplierModal.addSupplierLoadable;
export const selectUpdateSupplierLoadable = (state: GlobalState) => state.editSupplierModal.updateSupplierLoadable;
export const selectCurrentCompanyCountry = (state: GlobalState) => state.user.currentCompany?.Country;
