import { cloneDeep, maxBy } from 'lodash-es';
import { CompanySetting } from '../../../src/common/user/userCompanySettingsUtil';
import { CompanySettingStatus } from '../../../src/views/settings/company-settings/components/Settings/CompanySettingsListHelper';

(function () {
  "use strict";

  angular
    .module("blocks.invoice")
    .factory("invoiceService", invoiceService);

  invoiceService.$inject = [
    "$rootScope",
    "webServices",
    "notifyService",
    "$routeParams",
    "$uibModal",
    "$q",
    "$filter",
    "$location",
    "utilityService",
    "companyDataService",
    "authenticationService"
  ];

  /*
      All functions that we use app wide to parse or interact with invoice related data
   */
  function invoiceService (
    $rootScope,
    webServices,
    notifyService,
    $routeParams,
    $uibModal,
    $q,
    $filter,
    $location,
    utilityService,
    companyDataService,
    authenticationService
  ) {
    var fields = [];
    var invoice = [];
    var transactionRows = [];

    return {
      activeInvoice: activeInvoice,
      addCustomCostObjectivesBasedOnAccount: addCustomCostObjectivesBasedOnAccount,
      approveInvoice: approveInvoice,
      createInvoiceAction: createInvoiceAction,
      getAccountDistributionItemRowClass: getAccountDistributionItemRowClass,
      getCurrentStep: getCurrentStep,
      getCurrentUncompletedTask: getCurrentUncompletedTask,
      getGroupMemberFullNameById: getGroupMemberFullNameById,
      getNextApprover: getNextApprover,
      getNextConfirmer: getNextConfirmer,
      isPenultimateConfirmer: isPenultimateConfirmer,
      getTransactionRows: getTransactionRows,
      getTransactionRowsData: getTransactionRowsData,
      getUserFullNameById: getUserFullNameById,
      isUserNextConfirmer: isUserNextConfirmer,
      mergeAccountingRows: mergeAccountingRows,
      openSaveAccountDistTemplateDialog: openSaveAccountDistTemplateDialog,
      showAutoTransactionConfirmationDialog: showAutoTransactionConfirmationDialog,
      openAutoTransaction: openAutoTransaction,
      setInvoiceStatus: setInvoiceStatus,
      combineAccountDistributionItems: combineAccountDistributionItems,
      showValidationFailedModal: showValidationFailedModal,
      validateInvoiceDataForConfirmation: validateInvoiceDataForConfirmation
    };

    function getTransactionRows (id) {
      if (_.isEmpty(transactionRows)) {
        transactionRows = getTransactionRowsData(id);
      }
      return $q.when(transactionRows);
    }

      function getInvoiceData(invoiceId) {
          return webServices
              .getInvoiceData(invoiceId)
              .then(function(response) {
                  if (response && response.data && response.data.Invoice) {
                      // const invoice = cloneDeep(response.data.Invoice);
                      const invoice = {
                          ...response.data.Invoice,
                          InvoiceRowsCount: response.data.InvoiceRowsCount,
                          InvoiceRowsPrice: response.data.InvoiceRowsPrice,
                          InvoiceRowsVAT: response.data.InvoiceRowsVAT,
                          InvoiceRowsTotal: response.data.InvoiceRowsTotal,
                          TransactionRowsPrice: response.data.TransactionRowsPrice,
                          TransactionRowsTotal: response.data.TransactionRowsTotal,
                          TransactionRowsVAT: response.data.TransactionRowsVAT,
                          InvoiceAccountingRowsTotal: response.data.InvoiceAccountingRowsTotal,
                          InvoiceDate: new Date(utilityService.removeTimeZone(response.data.Invoice.InvoiceDate)),
                          AccountingDate: response.data.Invoice.AccountingDate ? new Date(utilityService.removeTimeZone(response.data.Invoice.AccountingDate)) : response.data.Invoice.InvoiceDate,
                          DueDate: new Date(utilityService.removeTimeZone(response.data.Invoice.DueDate)),
                          ImportedDate: new Date(utilityService.removeTimeZone(response.data.Invoice.ImportedDate)),
                          InvoiceCustomization: response.data.Invoice.InvoiceCustomization && response.data.Invoice.InvoiceCustomization.map((item) => {
                              if (
                                  item.Customization &&
                                  item.Customization.FieldType === 2 &&
                                  item.Value !== null
                              ) {
                                  item.Value = new Date(utilityService.removeTimeZone(item.Value));
                              }
                          }),
                          Beneficiary: response.data.Invoice.Beneficiary ||
                              response.data.Invoice.SupplierName,
                          invoiceInvoiceRowsLoaded: true,
                          invoiceAccountingRowsLoading: true
                      };

                      return cloneDeep(invoice);
                  }
              }, function(error) {
                  console.log('Request rejected due to:', error);
              });
      }

      function activeInvoice(id) {
        if (id) {
            return getInvoiceData(id).then(response => {
              if (response) {
                invoice = response;
                return { ...invoice};
              }
          })
        }
      }

    function combineAccountDistributionItems (invoiceId, apiObj) {
      var apiObject = {
        "InformationName": apiObj.informationName,
        "ContentCount": apiObj.contentCount,
        "RowsCount": apiObj.rowsCount
      };

      return webServices.mergeAccountingRowsByTransactionRowExtension(invoiceId, apiObject)
        .then(function (response) {
          if (response) {
            // Reload Transaction Rows Here
            return response;
            // reloadTransactionRows(paginator.currentPage);
            // $scope.$parent.reloadInvoiceData(invoiceId);
          } else {
            notifyService.error("component.transactionRow.split.errorDuringSplit", "controller.invoiceConfirmationController.Error", true);
          }
        }, function (error) {
          if (error) console.log("mergeFail:", error);
        });
    }

    /*
        Get transaction rows for invoice
     */
    function getTransactionRowsData (id, invoiceRowPage) {
      var searchId = id || $routeParams.id;
      var customCostObjectivesArray = [];
      var transactionRowsArray = [];
      invoiceRowPage = invoiceRowPage || 5;
      return $q.all([
          companyDataService.getCustomCostObjectives(true),
          webServices.getAccountingRowsWithPaging(searchId, 0, invoiceRowPage)
      ]).then(function (responses) {
        customCostObjectivesArray = responses[0];
        transactionRowsArray = responses[1].data;
        // parse some of the AccountingRows data so it's more edible for the app
        _.map(transactionRowsArray, function (row) {
          if (row.AccountingDate) {
            row.AccountingDate = new Date(utilityService.removeTimeZone(row.AccountingDate));
          }
          // if we have the account already selected
          var account = _.find($rootScope.accountList, function (r) {
            return r.Id === row.AccountId;
          });
          if (account) {
            row.AccountDescription = account.Description;
            row.Account = account;
            row.TransactionRowsDimensions = row.TransactionRowsDimensions.concat(addCustomCostObjectivesBasedOnAccount(account, row));
          }
          // add all mandatory fields set for current company
          row.TransactionRowsDimensions = row.TransactionRowsDimensions.concat(addCustomCostObjectivesBasedOnCompanySettings(row, customCostObjectivesArray));
          return row;
        });
        // TODO: fix utilityService.hardCopy for post-1.6.9
        return transactionRowsArray;
      }, function (error) {
          console.log("Request rejected due to:", error);
      });
    }

    /*
        If we have set company wide mandatory or default custom fields then add them to customCostObjectives array
        Mandatory field - field that have to be filled for invoice confirmation
        Visible field - field that is visible by default, but isn't mandatory to fill
     */
    function addCustomCostObjectivesBasedOnCompanySettings (accountDistributionItem, accountDistributionItemCustomCostObjectives) {
      var customCostObjectives = [];
      var mandatoryFields = _.filter(accountDistributionItemCustomCostObjectives, function (t) {
          return t.IsMandatory || t.IsVisible;
        });
      if (mandatoryFields.length) {
        _.forEach(mandatoryFields, function (mf) {
          // if custom field isn't in the array yet
          if (!_.some(accountDistributionItem.TransactionRowsDimensions, function (item) {
            return item.CustomCostObjective.Id === mf.Id;
          })) {
            customCostObjectives.push({
              CustomCostObjectiveId: mf.Id,
              CustomCostObjective: mf,
              IsVisible: mf.IsVisible,
              DimensionId: null
            });
          }
        });
      }

      return customCostObjectives;
    }

    /*
      open modal for displaying warnings before continuing on to save new auto transaction snapshot
     */

      function openAutoTransaction(id, isSnapshot) {
          if (!isSnapshot && id) {
              $location.path('/auto-transactions/details/' + id);
          } else if (isSnapshot && id){
              $location.path('/auto-transactions/add/' + id);
          } else {
              $location.path('/auto-transactions/add');
          }
      }

    function showAutoTransactionConfirmationDialog (unPostedAmount, invoiceCurrency, emptyMandatoryFields, existingDuplicate) {
      var modalInstance = $uibModal.open({
        templateUrl: "app/components/autoTransactions/ds-auto-transaction-confirmation.tpl.html",
        controller: "DsAutoTransactionConfirmationController",
        controllerAs: "$ctrl",
        windowClass: "validation-failed draggable",
        backdrop: "static",
        resolve: {
          unPostedAmount: function () {
            return unPostedAmount;
          },
          invoiceCurrency: function () {
            return invoiceCurrency;
          },
          emptyMandatoryFields: function () {
            return emptyMandatoryFields;
          },
          existingDuplicate: function () {
            return existingDuplicate;
          }
        }
      });
      modalInstance.result.then(function (result) {
        if (result.accepted || result.passedValidation) {
            openAutoTransaction($routeParams.id, true);
        } else if (result.emptyMandatoryFields) {
          if (result.emptyMandatoryFields) {
            $rootScope.$emit("mustValidateTransactionRows", result.emptyMandatoryFields);
          }
        }
      }).catch(function (reason) {
        console.log("Request rejected due to", reason);
      });
    }

    /*
        Open modal for saving accountingDistribution Template
     */
    function openSaveAccountDistTemplateDialog (invoice, dataObject) {
      $uibModal.open({
        templateUrl: "Views/AccountDistributionTemplate/Detail.html",
        controller: "accountDistributionTemplateDetailController",
        size: "lg",
        resolve: {
          invoice: function () {
            return invoice;
          },
          id: function () {
            return 0;
          },
          data: function () {
            // TODO no need send $rootScope items to model
            return {
              vatCodes: $rootScope.companyData.VatCodes,
              accounts: $rootScope.accountList,
              users: $rootScope.companyData.Users,
              groupMembers: dataObject.groupMembers,
              workflowTemplates: $rootScope.companyData.WorkflowTemplates,
              accountDistributionItemCustomCostObjectives: dataObject.accountDistributionItemCustomCostObjectives
            };
          }
        }
      });
    }

    /*
        Adds all mandatory custom fields, that are bound to the selected account, to the CustomFields array
     */
    function addCustomCostObjectivesBasedOnAccount (account, accountDistributionItem) {
      var customCostObjectives = [];

      // loop through each additional custom field that is required by the selected account
      _.forEach(account.AdditionalFields, function (additionalField) {
        // TODO: fix utilityService.hardCopy for post-1.6.9
        var costObjective = _.find($rootScope.accountDistributionItemCustomCostObjectives, function (r) {
          return r.Id === additionalField.CustomCostObjectiveIdaddCustomCostObjectivesBasedOnAccount;
        });
        if (costObjective) {
          // if we don't have this customField set yet, then add it to CustomFields array and make sure it's set as mandatory
          if (!_.some(accountDistributionItem.TransactionRowsDimensions, function (item) {
            return item.CustomCostObjective && item.CustomCostObjective.Id === costObjective.Id;
          })) {
            customCostObjectives.push({
              CustomCostObjectiveId: additionalField.CustomCostObjectiveId,
              CustomCostObjective: customFieldItemValue,
              DimensionId: null
            });
          }
        }
      });

      return customCostObjectives;
    }

    /*
        Merge transactionRows
     */
    function mergeAccountingRows (rows) {
      return webServices.mergeAccountingRows(rows).then(function (response) {
      }, function (data) {
        // TODO show merge error
        console.log(data);
      });
    }

    /*
        functions for CSS
    */
    function getAccountDistributionItemRowClass (item, users, currentUserGMId) {
      var rowClass = "";
      if (isCurrentUserNextConfirmer(item, users, currentUserGMId)) {
        rowClass += "red";
      } else if (getNextConfirmer(item, users)) {
        rowClass += "text-yellow";
      }
      return rowClass;
    }

    function isUserNextConfirmer (item, users, currentUserGMId) {
      return !!isCurrentUserNextConfirmer(item, users, currentUserGMId);
    }

    function setInvoiceStatus (invoice) {
      if (invoice.DueDate) {
        var currentDate = new Date();
        var invoiceDueDate = new Date(invoice.DueDate);
        var marginDate = new Date(invoice.DueDate).setDate(invoiceDueDate.getDate() - 5);
        // TODO replace number 7 with configurable days before payment due date status is shown
        var invoiceDueDateWithMargin = new Date(marginDate);

        // Payment due date is getting close. Show stopwatch
        if (invoiceDueDateWithMargin.getTime() <= currentDate.getTime()) {
          return 1;
        }
        // TODO see if invoice total sum is a lot larger than usually. Show NB!
        // else if (false) {
        //   return 2;
        // }
      }

      return false;
    }

    /*
        confirmer/approver related functions
    */
    function isCurrentUserNextConfirmer (item, users, currentUserGMId) {
      var user = getNextConfirmer(item, users);
      if (user) { return user.GroupMemberId === currentUserGMId; }
      return false;
    }

    function getNextConfirmer (item, users) {
      if (item) {
        var task = getCurrentUncompletedTask(item);
        if (task) {
          return getGroupMemberUser(task, users);
        }
      }
      return "";
    }

    function getNextApprover (item, users) {
      if (item) {
        var task = getNextUncompletedTask(item);
        if (task) {
          return getGroupMemberUser(task, users);
        }
      }
      return "";
    }

    function isPenultimateConfirmer(item) {
      if (item) {
          if (item && item.Workflow && item.Workflow.Tasks && item.Workflow.Tasks.length > 0) {
              const lastTask = maxBy(item.Workflow.Tasks, (task) => {
                  return task.OrderNo;
              });
              return lastTask && lastTask.OrderNo === item.Workflow.CurrentStep  + 1;
          }
      }
      return false;
    }

    /*
        Task related functions
    */
    function getCurrentUncompletedTask (accDiscitem) {
      if (accDiscitem && accDiscitem.Workflow && accDiscitem.Workflow.Tasks && accDiscitem.Workflow.Tasks.length > 0) {
        var tasks = accDiscitem.Workflow.Tasks.sort(function (a, b) {
          return a.OrderNo - b.OrderNo;
        });
        return _.find(tasks, function (t) {
          return !t.Completed && t.OrderNo === accDiscitem.Workflow.CurrentStep;
        });
      }
    }

    function getNextUncompletedTask (accDiscitem) {
      if (accDiscitem && accDiscitem.Workflow && accDiscitem.Workflow.Tasks && accDiscitem.Workflow.Tasks.length > 0) {
        var tasks = accDiscitem.Workflow.Tasks.sort(function (a, b) {
          return a.OrderNo - b.OrderNo;
        });
        return _.find(tasks, function (t) {
          return !t.Completed && t.OrderNo > accDiscitem.Workflow.CurrentStep;
        });
      }
    }

    function getGroupMemberUser (task, users) {
      return _.find(users, function (r) {
        return task.GroupMember && r.GroupMemberId === task.GroupMember.Id;
      });
    }

    /*
        User related functions
    */
    function getUserFullNameById (id, users) {
      var user = _.find(users, function (r) {
        return r.GroupMemberId === id;
      });
      return user ? user.FullName : "";
    }

    function getGroupMemberFullNameById (groupMemberId, groupMembers) {
      var groupMember = _.find(groupMembers, function (r) {
        return r.Id === groupMemberId;
      });
      return groupMember ? groupMember.User.FullName : "";
    }

    /// ///
    /// DTO creations for moving data between services
    /// //
    function createInvoiceAction (action, invoiceId, comment, nextConfirmer) {
      return {
        InvoiceId: invoiceId,
        Comment: comment,
        NextConfirmerGroupMemberOrWorkflowTemplateId: nextConfirmer ? nextConfirmer.Id : null,
        IsNextConfirmerWorkflowTemplate: nextConfirmer ? nextConfirmer.IsTemplate : null,
        Action: action
      };
    }

    function approveInvoice (invoiceAction, stepNumber) {
      return webServices.approveInvoice(invoiceAction, stepNumber).then(
        function (result) {
          if (result && result.data) {
            return result.data;
          }
        }).catch(
        function () {
          notifyService.error("component.invoicesTable.approvedError", "component.invoicesTable.invoiceApproval", true);
        });
    }

    function getCurrentStep (workflow) {
      if (!workflow.Tasks.length) { return 1; }
      var sortedTasks = workflow.Tasks.sort(function (a, b) {
        if (a.OrderNo < b.OrderNo) { return -1; }
        if (a.OrderNo > b.OrderNo) { return 1; }
        return 0;
      });
      for (var i = 0; i < sortedTasks.length; i++) {
        if (sortedTasks[i].Completed) { continue; }
        return sortedTasks[i].OrderNo;
      }
      return sortedTasks[sortedTasks.length - 1].OrderNo;
    }

      function showValidationFailedModal(validations, errors, invoiceId) {
          var modalInstance = $uibModal.open({
              templateUrl: 'app/components/validationFailedModal/ds-validation-failed-modal.tpl.html',
              controller: 'DsValidationFailedModalController',
              controllerAs: '$ctrl',
              windowClass: 'validation-failed draggable',
              backdrop: 'static',
              resolve: {
                  validations: () => validations,
                  errors: () => errors,
              },
          });
          modalInstance.result.then(function(result) {
              if (result.passedValidation) {
                  confirmInvoice(invoiceId);
              } else if (result && result.confirmAll) {
                  if (errors[0].invoices.length > 0) {
                      const transactionRowsLoadedEvent = $rootScope.$on('transactionRowsLoaded', (e, data) => {
                          if (data === true) {
                              setTimeout(() => {
                                  $rootScope.$emit('mustValidateTransactionRows');
                              }, 10);
                              transactionRowsLoadedEvent();
                          }
                      });
                      $location.path(`/invoiceconfirmation/${invoiceId}`);
                  }
              } else {
                  if (errors.find(e => e.payload)) {
                      const transactionRowsLoadedEvent = $rootScope.$on('transactionRowsLoaded', (e, data) => {
                          if (data === true) {
                              setTimeout(() => {
                                  $rootScope.$emit('mustValidateTransactionRows', errors.find(e => e.payload).payload);
                              }, 10);
                              transactionRowsLoadedEvent();
                          }
                      });
                  }
                  $location.path(`/invoiceconfirmation/${invoiceId}`);
              }
          }).catch(function(reason) {
              console.log('Request rejected due to', reason);
          });
      }

      function validateInvoiceDataForConfirmation(invoiceId) {
          return $q.all([
              activeInvoice(invoiceId),
              webServices.AreTransactionRowsValid(invoiceId), // TransactionRows validation is dependent on setting
              webServices.AreCustomFieldsValid(invoiceId),
          ]).then((responseArray) => {
              let isLastTask = false;
              let isCompleter = authenticationService.isAuthorized('CanApproveInvoiceWithoutNextConfirmer');
              let isCheckTransactionRows = authenticationService.isCompanySettingEnabled(CompanySetting.IsTransactionRowsCheckEnabled);
              let isSkipWhenPOExists = authenticationService.isCompanySettingEnabled(CompanySetting.SkipTransactionRowsVerificationWhenPOSpecified);
              let isPOExists = false;

              // penultimate part
              let isPenultimateTask = false;
              const penultimateCheckSetting = $rootScope.companySettings.data.find((c) => c.Name === CompanySetting.IsTransactionRowsPenultimateCheckEnabled);
              const isPenultimateCheckEnabled = penultimateCheckSetting && penultimateCheckSetting.Value === CompanySettingStatus.Enabled;

              const validations = {
                  accessValid: true,
                  dataValid: true,
              };

              let errors = [];
              let emptyMandatoryDimensions = responseArray[1].data;
              let emptyMandatoryCustomFields = responseArray[2].data;
              const invoice = responseArray[0];

              function hasEmptyMandatoryDimensions(dims) {
                  return dims.length > 0;
              }

              function hasUnPostedAmount(inv) {
                  return (parseFloat(inv.TransactionRowsTotalSumRemaining).toFixed(2) !== parseFloat(inv.TotalAmountWithVat).toFixed(2));
              }

              if (!!invoice) {
                  isLastTask = invoice.isLastTask;
                  isPenultimateTask = isPenultimateConfirmer(invoice);
                  isPOExists = !!invoice.PurchaseOrder;

                  if (isLastTask && !isCompleter) {
                      errors.push({
                          message: {
                              title: $filter('translate')(
                                  'component.DashboardConfirmation.InsufficientAccess.Title',
                              ),
                              content: $filter('translate')(
                                  'component.DashboardConfirmation.InsufficientAccess.Secondary',
                              ),
                          },
                      });
                      validations.accessValid = false;
                  }

                  if ((isLastTask && isCheckTransactionRows) || (isPenultimateTask && isPenultimateCheckEnabled)) {
                      if (
                          !isSkipWhenPOExists || (
                              isSkipWhenPOExists &&
                              !isPOExists
                          )
                      ) {
                          if (hasEmptyMandatoryDimensions(emptyMandatoryDimensions)) {
                              errors.push({
                                  message: {
                                      title: $filter('translate')(
                                          'component.AutoTransaction.Confirmation.EmptyMandatoryFields',
                                      ),
                                      content: $filter('translate')(
                                          'component.AutoTransaction.Confirmation.EmptyMandatoryFields.Secondary',
                                          {
                                              count: emptyMandatoryDimensions.length,
                                          },
                                      ),
                                  },
                                  payload: emptyMandatoryDimensions,
                              });
                              validations.dataValid = false;
                          }
                          if (hasUnPostedAmount(invoice)) {
                              errors.push({
                                  message: {
                                      title: $filter('translate')(
                                          'component.AutoTransaction.Confirmation.UnPostedAmount',
                                      ),
                                      content: $filter('translate')(
                                          'component.AutoTransaction.Confirmation.UnPostedAmount.Secondary',
                                          {
                                              amount: invoice.TotalAmountWithVat - invoice.TransactionRowsTotalSumRemaining,
                                              currency: invoice.Currency,
                                          },
                                      ),
                                  },
                              });
                              validations.dataValid = false;
                          }
                          if (hasEmptyMandatoryDimensions(emptyMandatoryCustomFields)) {
                            errors.push({
                                message: {
                                    title: $filter('translate')(
                                        'component.AutoTransaction.Confirmation.EmptyMandatoryFields',
                                    ),
                                    content: $filter('translate')(
                                        'component.mainActions.InvalidCustomFieldsMessage.confirm',                                       
                                    ),
                                },
                            });
                            validations.dataValid = false;
                        }
                      }
                  }
              }
              return {
                  validations,
                  errors,
              };
          });
      }
  }
})();
