import { ReducerFactory } from 'redux-actions-ts-reducer';
import { cloneDeep } from 'lodash-es';
import { getSliderInvoicesLoadable, updateInvoice, setSliderInvoices } from './InvoiceSliderActions';
import { BaseSearch, InvoiceSliderMinDTO, PagedListContainer } from '../../../../services/types/ApiTypes';
import { GlobalState } from '../../../../rootReducer';
import { LoadableData } from '../../../../common/utils/LoadableData';

class State {
    invoiceSliderDataLoadable = new LoadableData<PagedListContainer<InvoiceSliderMinDTO>, BaseSearch>();
    currentSlide = 0;
    isInvoiceRowsEditing = false;
}

export { State as SliderInvoiceDataState };

export default new ReducerFactory(new State())
    .addReducer(
        getSliderInvoicesLoadable.request,
        (state): State => {
            return {
                ...state,
                invoiceSliderDataLoadable: LoadableData.loading(undefined),
            };
        },
    )
    .addReducer(
        getSliderInvoicesLoadable.success,
        (state, action): State => {
            return {
                ...state,
                invoiceSliderDataLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getSliderInvoicesLoadable.error,
        (state, action): State => {
            return {
                ...state,
                invoiceSliderDataLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        updateInvoice,
        (state, action): State => {
            const invoiceList: InvoiceSliderMinDTO[] = state.invoiceSliderDataLoadable.payload.Items.map((invoice) => {
                if (invoice.Id === action.payload.Id) {
                    return action.payload;
                }
                return invoice;
            });
            const invoiceDataLoadable: LoadableData<PagedListContainer<InvoiceSliderMinDTO>, BaseSearch> = cloneDeep(state.invoiceSliderDataLoadable);
            invoiceDataLoadable.payload = {
                ...state.invoiceSliderDataLoadable.payload,
                Items: [...invoiceList],
            };
            return {
                ...state,
                invoiceSliderDataLoadable: invoiceDataLoadable,
            };
        },
    )
    .addReducer(
        setSliderInvoices,
        (state, action): State => {
            const invoiceDataLoadable: LoadableData<PagedListContainer<InvoiceSliderMinDTO>, BaseSearch> = cloneDeep(state.invoiceSliderDataLoadable);
            invoiceDataLoadable.payload = {
                ...state.invoiceSliderDataLoadable.payload,
                Items: [...action.payload],
            };
            return {
                ...state,
                invoiceSliderDataLoadable: invoiceDataLoadable,
            };
        },
    )
    .toReducer();

export const selectInvoiceDataLoadable = (state: GlobalState) => state.sliderInvoiceData.invoiceSliderDataLoadable;
