import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import * as React from 'react';
import Collapsible from 'react-collapsible';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';

import { BaseStatefulComponent } from '../BaseStatefulComponent';
import Icon, { ICONS, IconSize } from '../Icon/Icon';

import './ToggleContent.scss';
import { Button, ButtonType } from '../Buttons/Button';
import { WithDataId } from 'src/common/utils/dataId';

export interface ToggleContentProps extends WithDataId {
    defaultOpen?: boolean;
    toggleContent?: string | React.ReactElement<any>;
    disableTrigger?: boolean;
    type?: ToggleContentType;
    className?: string;
    connectDragSource?: ConnectDragSource;
    connectDragPreview?: ConnectDragPreview;
    isContentReadOnly?: boolean;
    overflowWhenOpen?: 'hidden' | 'visible' | 'auto' | 'scroll' | 'inherit' | 'initial' | 'unset';
    alwayOpen?: boolean;
    loading?: boolean;
}

export enum ToggleContentType {
    DEFAULT = 'DEFAULT',
    COMPACT = 'COMPACT',
    CONTENT_BLOCK = 'CONTENT_BLOCK',
}

interface ToggleContentState {
    open: boolean;
}
export class ToggleContent extends BaseStatefulComponent<ToggleContentProps, ToggleContentState> {
    static defaultProps: Partial<ToggleContentProps> = {
        type: ToggleContentType.DEFAULT,
        defaultOpen: false,
        disableTrigger: false,
    };

    constructor(props: ToggleContentProps) {
        super(props);
        this.state = {
            open: props.defaultOpen,
        };
    }

    componentDidUpdate(prevProps: ToggleContentProps): void {
        if (prevProps.defaultOpen !== this.props.defaultOpen && this.props.defaultOpen) {
            this.setState({
                open: this.props.defaultOpen,
            });
        }
        if (this.props.disableTrigger) {
            this.setState({ open: false });
        }
    }

    toggleOpen = (open = !this.state.open) => {
        this.setState({
            open,
        });
    };

    renderTrigger() {
        const { dataId, disableTrigger, connectDragSource, toggleContent, type } = this.props;

        if (this.props.alwayOpen) {
            return (
                <>
                    <span className={'toggle-content__trigger-content'}>{toggleContent}</span>
                </>
            );
        }

        if ([ToggleContentType.CONTENT_BLOCK].includes(type)) {
            return (
                <>
                    <span className="toggle-content__trigger-content">{toggleContent}</span>
                    {!disableTrigger && (
                        <Button
                            dataId={dataId}
                            buttonType={ButtonType.ICON_SQUARE}
                            icon={ICONS.CHEVRON_DOWN_24}
                            iconSize={IconSize.SM}
                            iconClass="toggle-content__toggle-icon"
                            className={'toggle-content__toggle' + (disableTrigger ? ' disabled' : '')}
                            onClick={() => !disableTrigger && this.toggleOpen()}
                        />
                    )}
                </>
            );
        }

        return (
            <>
                {connectDragSource &&
                    connectDragSource(
                        <span className="toggle-content__draggable">
                            <Icon name={ICONS.DRAGGABLE} />
                        </span>,
                    )}

                <button type="button" className={'toggle-content__toggle' + (disableTrigger ? ' disabled' : '')} onClick={() => !disableTrigger && this.toggleOpen()}>
                    {!disableTrigger && <Icon className="toggle-content__toggle-icon" name={ICONS.CHEVRON_DOWN_24} />}
                </button>
                <div className={'toggle-content__trigger-content'}>{toggleContent}</div>
            </>
        );
    }

    render() {
        const { className, connectDragPreview, isContentReadOnly = true, loading } = this.props;
        const classes = classNames('toggle-content', className, `toggle-content--${this.props.type.toLowerCase()}`, {
            'toggle-content--open': this.state.open,
            'toggle-content--content-read-only': this.props.type === ToggleContentType.CONTENT_BLOCK && isContentReadOnly,
            'toggle-content--extended': this.props.type === ToggleContentType.CONTENT_BLOCK,
        });

        const contentClasses = classNames('toggle-content__content', { 'toggle-content__content--loading': loading });

        return (
            <Collapsible
                easing="ease-in-out"
                open={this.state.open && !isEmpty(this.props.toggleContent)}
                lazyRender={true}
                className={classes}
                openedClassName={classes}
                contentInnerClassName={contentClasses}
                triggerDisabled={true}
                triggerClassName={'toggle-content__trigger'}
                triggerOpenedClassName={'toggle-content__trigger toggle-content__trigger--open'}
                transitionTime={150}
                overflowWhenOpen={this.props.overflowWhenOpen}
                trigger={connectDragPreview ? connectDragPreview(<div className="toggle-content__trigger-drag-preview">{this.renderTrigger()}</div>) : this.renderTrigger()}
            >
                {this.props.children}
            </Collapsible>
        );
    }
}
