import createFieldNameConstants from '../../common/utils/fieldNames';
import { TypeaheadItem } from '../../components/Typeahead/TypeaheadAsync';
import { SupplierDTO, PurchaseOrdersRowsDTO, PurchaseOrderType } from '../../services/types/ApiTypes';

export interface PurchaseOrdersAddViewFields {
    Currency: TypeaheadItem<string>;
    PoNumber: string;
    Description: string;
    Supplier: TypeaheadItem<SupplierDTO>;
    Issued: Date;
    Type: PurchaseOrderType;
    Rows: Array<Partial<PurchaseOrdersRowsDTO>>;
    Id: number;
    CreatedBy: string;
}

export interface PurchaseOrdersAddViewEmptyRowFields {
    Key?: string;
    Description: string;
    Quantity: number;
    Unit: string;
    UnitPrice: number;
    SumWithoutVat: number;
    Vat: number;
    VatAmount: number;
    Total: number;
}

export const fieldNamesSource: PurchaseOrdersAddViewFields = {
    PoNumber: null,
    Description: null,
    Supplier: null,
    Issued: null,
    Type: null,
    Rows: null,
    Id: null,
    Currency: null,
    CreatedBy: null,
};

const rowFieldNamesSource: PurchaseOrdersRowsDTO = {
    Description: null,
    Quantity: null,
    Unit: null,
    UnitPrice: null,
    SumWithoutVat: null,
    Vat: null,
    /**
     * NB! Currently used as VAT TOTAL!
     */
    VatAmount: null,
    Total: null,
    PurchaseOrderId: null,
    Id: null,
    IsNew: null,
    ModifiedBy: null,
    VatCode: null,
    VatCodeId: null,
};
const fieldNames = createFieldNameConstants(fieldNamesSource);

export const rowFieldNames = createFieldNameConstants(rowFieldNamesSource);

export default fieldNames;
