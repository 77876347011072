import React from 'react';
import { WithTranslation } from 'react-i18next';
import { Dispatchable1 } from 'redux-dispatchers';

import ModalConfirm, { ConfirmButtonStyle } from '../../components/Modal/ModalConfirm';

export interface OpenExternalLinkConfirmModalProps {
    linkToOpen: string;
}

export interface DispatchProps {
    setExternalLinkToOpen: Dispatchable1<string>;
}

type Props = OpenExternalLinkConfirmModalProps & DispatchProps & WithTranslation;

export const OpenExternalLinkConfirmModal: React.FC<Props> = ({ linkToOpen, setExternalLinkToOpen, t }) => {
    return (
        <ModalConfirm
            open={!!linkToOpen}
            onClose={() => {
                setExternalLinkToOpen('');
            }}
            title={t('component.openExternalLinkModal.Title')}
            confirmText={t('component.openExternalLinkModal.ConfirmText')}
            handleActions={() => {
                window.open(linkToOpen, '_newtab');
                setExternalLinkToOpen('');
            }}
            dataId="openExternalLink"
            confirmButtonStyle={ConfirmButtonStyle.PRIMARY}
        >
            {t('component.openExternalLinkModal.Message')}
        </ModalConfirm>
    );
};
