import { InvoiceSearchOptions, SearchType } from '../../../services/types/ApiTypes';

export const DEFAULT_RESTRICTION = 'Status';

export function createRequest(searchValue = '', page = 1, count = 15) {
    const search: InvoiceSearchOptions = {
        SortItems: [],
        PagingOptions: {
            Count: count,
            Page: page,
        },
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: searchValue || '',
                Values: [9],
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        IsIncludeWorkflows: false,
    };
    return search;
}
