(function() {
    'use strict';

    angular.module('dstreamApp.core', [
        'ngRoute',
        'ngSanitize',
        'xeditable',
        'ui.bootstrap',
        'pascalprecht.translate',   //translations
        'ngCookies',                //needed for translations
        //'tmh.dynamicLocale',      //needed for translations
        'oi.select',
        'flow',
        'slickCarousel',
        'ngTagsInput',
        'ngAnimate',
        'angular.filter',
        'LocalStorageModule',      //needed for authetincationService
        'angularCSS',
        'dstreamApp.blocks',
        'angulartics',                  // google analytics
        'angulartics.google.tagmanager' // google analytics
    ]);
})();
