import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { cloneDeep } from 'lodash-es';
import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';

import { LoadableData } from '../../common/utils/LoadableData';
import { TableFilter } from '../../components/Table/components/filter/TableFilters';
import { GlobalState } from '../../rootReducer';
import { BaseSearchWithTableFilters, PagedListContainer, UserDTO } from '../../services/types/ApiTypes';

import { getActiveUsersCountLoadable, getUserListLoadable, updateUserStateAction } from './UserListViewActions';
import { createRequest } from './UserListViewHelpers';

export type UserListSearchParams = BaseSearchWithTableFilters<TableFilter<any>, UserDTO>;

class State {
    activeUsersCountLoadable = new LoadableData<number>();
    usersListLoadable = new LoadableData<PagedListContainer<UserDTO>, UserListSearchParams>();
    searchParams: UserListSearchParams = {
        ...createRequest(''),
        filters: {},
    };
}

export default new ReducerFactory(new State())
    .addReducer(
        getActiveUsersCountLoadable.request,
        (state, action): State => {
            return {
                ...state,
                activeUsersCountLoadable: state.activeUsersCountLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        getActiveUsersCountLoadable.success,
        (state, action): State => {
            return {
                ...state,
                activeUsersCountLoadable: state.activeUsersCountLoadable.withPayloadIfRequestEquals({
                    request: null,
                    result: action.payload,
                }),
            };
        },
    )
    .addReducer(
        getUserListLoadable.request,
        (state, action): State => {
            const usersListLoadable = state.usersListLoadable.withLoading(action.payload);
            return {
                ...state,
                searchParams: action.payload,
                usersListLoadable,
            };
        },
    )
    .addReducer(
        getUserListLoadable.success,
        (state, action): State => {
            const usersListLoadable = state.usersListLoadable.withPayloadIfRequestEquals(action.payload);
            return {
                ...state,
                searchParams: action.payload.request,
                usersListLoadable,
            };
        },
    )
    .addReducer(
        getUserListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                usersListLoadable: state.usersListLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        updateUserStateAction.success,
        (state, action): State => {
            const userList: UserDTO[] = state.usersListLoadable.payload.Items.map((user) => {
                if (user.BOGuid === action.payload.result.UserGuid) {
                    user.IsActive = action.payload.result.IsActive;
                }
                return user;
            });
            const usersListLoadable: LoadableData<PagedListContainer<UserDTO>, UserListSearchParams> = cloneDeep(state.usersListLoadable);
            usersListLoadable.payload = {
                ...state.usersListLoadable.payload,
                Items: [...userList],
            };
            return {
                ...state,
                usersListLoadable,
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state, action: Action<RouterState>): State => {
            if (/\/users/.test(action.payload.location.pathname)) {
                return {
                    ...state,
                };
            }
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

export { State as UserManagementViewState };

export const selectUserListLoadable = (state: GlobalState) => state.userManagement.usersListLoadable;
export const selectUserListSearchParams = (state: GlobalState) => state.userManagement.searchParams;
export const selectActiveUsersCountLoadable = (state: GlobalState) => state.userManagement.activeUsersCountLoadable;
