import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { FileDTO } from '../../../../services/types/ApiTypes';

interface Props extends WithTranslation {
    file: FileDTO;
    removeFile: (f: FileDTO) => void;
    tippyRef?: React.RefObject<any>;
}

const DeleteFileModalContent = (props: Props) => {
    const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        // TODO: implement proper closing of tooltip (update tippy-react ?)
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        //@ts-ignore
        document.querySelectorAll('.related-documents')[0].click();
    };

    const confirm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        props.removeFile(props.file);
    };

    return (
        <div>
            <p>{props.t('component.relatedDocuments.confirmDelete')}</p>
            <p className="action-wrap action-wrap--right">
                <button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => cancel(e)} className="btn btn--secondary btn--sm">
                    {props.t('views.global.Cancel')}
                </button>
                <button onClick={confirm} className="btn btn--primary btn--sm">
                    {props.t('views.global.Delete')}
                </button>
            </p>
        </div>
    );
};

export default withTranslation()(DeleteFileModalContent);
