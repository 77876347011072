(function() {
    'use strict';

    angular
        .module('dstreamApp.views.detailed-transactions')
        .config(DetailedTransactionsRoute);

    DetailedTransactionsRoute.$inject = ['customRouteProvider'];

    /*@ngInject */
    function DetailedTransactionsRoute(customRouteProvider) {
        customRouteProvider
            .when('/transactions/:id/:archive?', {
                template: '<ds-detailed-transactions></ds-detailed-transactions>',
                // css: ['CSS/bootstrap.3.2.0.css?' + CurrentVersion, 'CSS/green-blue.css?' + CurrentVersion, 'CSS/Site.css?' + CurrentVersion],
                access: {
                    requiresLogin: true
                },
                resolve: {
                }
            });
    }
})();