import React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';

import { Dispatchable1 } from 'redux-dispatchers';
import { WithTranslation } from 'react-i18next';
import { SliderInvoiceDataState } from './InvoiceSliderReducers';
import CustomArrow, { ArrowType } from './components/CustomArrow';
import { sliderSettings, displayInvoiceNumber, decodeUriFilter } from './InvoiceSliderHelpers';

import './InvoiceSlider.scss';
import { formatDate } from '../../../../common/utils/formatters';
import { eventTrack } from '../../../../common/analytics/gtm';
import { BaseStatefulComponent } from '../../../../components/BaseStatefulComponent';
import { getStringOrDashes } from '../../../../components/PropertyListItem/PropertyListItem';
import { FileDTO, InvoiceSliderMinDTO } from '../../../../services/types/ApiTypes';
import { formatMoneyToShowSeparators } from '../invoice-header/utils';

export interface SlickProps extends Pick<SliderInvoiceDataState, 'invoiceSliderDataLoadable'> {
    isConfirmationFlowEditing: boolean;
    pdfToOpen?: FileDTO;
}

export interface DispatchProps {
    loadInvoice: Dispatchable1<number>;
    setIsConfirmationFlowEditing: Dispatchable1<boolean>;
    setPdfToOpen: Dispatchable1<FileDTO | null>;
}

export interface Props {
    invoice: InvoiceSliderMinDTO;
    reloadInvoiceData: (invoiceId: number) => void;

    // TODO: remove this when revert <-(rewrite?) whole invoice detail page
    location: any;
    $rootScope: any;
}

export interface RouteParams {
    id: string;
    filterObject?: string;
}

export type InvoiceSliderProps = SlickProps & DispatchProps & WithTranslation & Props;

interface State {
    currentSlide: number;
    currentInvoiceId: number;
}

let slider: any;

class Slick extends BaseStatefulComponent<InvoiceSliderProps, State> {
    constructor(props: InvoiceSliderProps) {
        super(props);
        this.state = {
            currentSlide: 0,
            currentInvoiceId: undefined,
        };
    }

    componentDidMount() {
        if (this.props.location && this.props.location.$$search && this.props.location.$$search.filterObject) {
            const uriFilterObject = decodeUriFilter(this.props.location.$$search.filterObject);
            localStorage.setItem('ls.filterObject', JSON.stringify({ ...uriFilterObject }));
        }
        if (this.props.location && this.props.location.$$search && this.props.location.$$search.status) {
            this.props.loadInvoice(Number(this.props.location.$$search.status));
            return;
        }
        this.props.loadInvoice(undefined);
    }

    componentDidUpdate(prevProps: InvoiceSliderProps) {
        if (
            (!prevProps.invoiceSliderDataLoadable.payload && this.props.invoiceSliderDataLoadable.payload && this.props.invoice) ||
            (!prevProps.invoice && this.props.invoice && this.props.invoiceSliderDataLoadable.payload)
        ) {
            const currentPosition = this.calculateSlidePositions();
            slider.slickGoTo(currentPosition);
            this.setState({
                currentInvoiceId: this.props.invoice.Id,
            });
        }
    }

    displayInvoice = (row: InvoiceSliderMinDTO, index: number) => {
        if (this.props.isConfirmationFlowEditing) {
            const continueChangeInvoice = confirm(this.props.t('component.confirmationFlow.Workflow.Unsaved'));
            if (!continueChangeInvoice) {
                return;
            }
            this.props.setIsConfirmationFlowEditing(false);
        }
        const { invoice, location, reloadInvoiceData, $rootScope } = this.props;
        if (invoice != null && row.Id === invoice.Id) {
            return;
        }
        this.setState({
            currentInvoiceId: row.Id,
        });
        slider.slickGoTo(this.calculateSlidePositions(index));
        reloadInvoiceData(row.Id);
        // do not reload same invoice
        location
            .skipReload()
            .path('/invoiceconfirmation/' + row.Id)
            .replace();

        // hide PDF viewer
        $rootScope.isPDFVisible = false;
        if (this.props.pdfToOpen) {
            this.props.setPdfToOpen(null);
        }

        $rootScope.$emit('additionalInvoiceLoading');
        $rootScope.$emit('relatedDocumentsLoading');
    };

    calculateSlidePositions = (cur?: number) => {
        const { invoiceSliderDataLoadable, invoice } = this.props;
        let currentSlide;
        let moveTo = 0;

        if (cur || cur === 0) {
            currentSlide = cur;
        }
        // we have one box selected already
        else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            for (let i = 0; i < invoiceSliderDataLoadable.payload.Items.length; i++) {
                if (invoice && invoiceSliderDataLoadable.payload.Items[i].Id === invoice.Id) {
                    currentSlide = i;
                    break;
                }
            }
        }
        // calculate the index of the most left slide to show
        moveTo = currentSlide - Math.floor(sliderSettings.slidesToShow / 2);
        if (moveTo < 0) {
            // fallback if we are moving too far left
            moveTo = 0;
        } else if (moveTo > invoiceSliderDataLoadable.payload.Items.length - sliderSettings.slidesToShow) {
            // fallback if we are moving too far right
            moveTo = invoiceSliderDataLoadable.payload.Items.length - sliderSettings.slidesToShow;
        }
        return moveTo;
    };

    render() {
        const { invoiceSliderDataLoadable, pdfToOpen } = this.props;
        const { currentInvoiceId } = this.state;
        const items: InvoiceSliderMinDTO[] = invoiceSliderDataLoadable.payload?.Items || [];
        const showArrow = items.length > sliderSettings.slidesToShow;
        const classes = classNames('invoice-slider', {
            loading: invoiceSliderDataLoadable.loading,
            'invoice-slider--without-arrows': !showArrow,
            'invoice-slider--half-width': pdfToOpen,
        });

        return (
            <div className={classes}>
                <Slider
                    {...sliderSettings}
                    arrows={showArrow}
                    lazyLoad={'progressive'}
                    slidesToShow={items.length < sliderSettings.slidesToShow ? items.length : sliderSettings.slidesToShow}
                    ref={(slide) => (slider = slide)}
                    prevArrow={<CustomArrow type={ArrowType.PREVIOUS} items={invoiceSliderDataLoadable.payload && invoiceSliderDataLoadable.payload.Items} />}
                    nextArrow={<CustomArrow type={ArrowType.NEXT} items={invoiceSliderDataLoadable.payload && invoiceSliderDataLoadable.payload.Items} />}
                >
                    {items.map((t, index) => (
                        <div
                            data-id={`slider.card.${index}`}
                            onClick={() => this.displayInvoice(t, index)}
                            className={classNames('invoice-slider__card', {
                                'invoice-slider__card--active': currentInvoiceId === t.Id,
                            })}
                            key={index}
                        >
                            <div
                                className="invoice-slider__link"
                                data-id="slider.link"
                                onClick={() => {
                                    eventTrack({
                                        event: 'quick_navigation',
                                        label: 'Open invoice',
                                    });
                                }}
                            >
                                <span className="invoice-slider__company" data-id={`slider.SupplierName.${index}`}>
                                    {t.SupplierName}
                                </span>
                                <span className="invoice-slider__sum" data-id={`slider.TotalAmountWithVat.${index}`}>
                                    {`${formatMoneyToShowSeparators(t.TotalAmountWithVat, true)} ${getStringOrDashes(t?.Currency)}`}
                                </span>
                                <span className="invoice-slider__date" data-id={`slider.InvoiceNumber.${index}`}>
                                    {displayInvoiceNumber(t)}
                                </span>
                                <span className="invoice-slider__date" data-id={`slider.InvoiceDate.${index}`}>
                                    {formatDate(t.InvoiceDate)}
                                </span>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }
}

export default Slick;
