import { Action } from 'redux-actions';
import storage from 'redux-persist/lib/storage';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import produce from 'immer';

import { GlobalState } from '../../../../rootReducer';
import { LoadableData } from '../../../../common/utils/LoadableData';
import { getTransactionRowsLoadable, addNewRow, removeRow, getTransactionRowsVatRates, getTransactionRowsExtendedInfoList, transactionRowsCopyDownLoadableActions } from './TransactionRowsActions';
import { BaseSearch, TransactionRowDTO, PagedListContainer, TransactionRowsExtendedInfoDTO } from '../../../../services/types/ApiTypes';

const createRequest = (page = 1, count = 15): TransactionRowsSearchParams => ({
    PagingOptions: {
        Count: count,
        Page: page,
    },
    Restrictions: [],
    SortItems: [],
});

export type TransactionRowsSearchParams = BaseSearch;

class State {
    transactionRowsLoadable = new LoadableData<PagedListContainer<TransactionRowDTO>, BaseSearch>();
    searchParams: TransactionRowsSearchParams = {
        ...createRequest(),
    };
    transactionRowsVatRatesLoadable = new LoadableData<{ Item1: number; Item2: number }[], number>();
    transactionRowsExtendedInfoLoadable = new LoadableData<TransactionRowsExtendedInfoDTO[], number>();
    transactionRowsCopyDownLoadable = new LoadableData<boolean>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        getTransactionRowsLoadable.request,
        (state, action): State => {
            const transactionRowsLoadable = state.transactionRowsLoadable.withLoading(action.payload);
            return {
                ...state,
                searchParams: action.payload,
                transactionRowsLoadable,
            };
        },
    )
    .addReducer(
        getTransactionRowsLoadable.success,
        (state, action): State => {
            const transactionRowsLoadable = state.transactionRowsLoadable.withPayloadIfRequestEquals(action.payload);
            return {
                ...state,
                searchParams: action.payload.request,
                transactionRowsLoadable,
            };
        },
    )
    .addReducer(
        getTransactionRowsLoadable.error,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                transactionRowsLoadable: state.transactionRowsLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        addNewRow,
        produce((draft, action) => {
            draft.transactionRowsLoadable.payload.Items.push({
                Id: 0,
                InvoiceId: action.payload,
                Description: '-',
                SumWithoutVat: '0.00',
                VAT: '0.00',
                VatRate: 0,
                Total: '0.00',
                ItemUnit: null,
                ItemAmount: '0',
                ItemPrice: '0.00',
                AccountId: null,
                VatCodeId: null,
                VatCode: null,
                Account: null,
                AccountCode: null,
                TransactionRowsDimensions: [],
                TransactionRowExtensions: [],
                SellerProductId: null,
                BuyerProductName: null,
                TaricCode: null,
                CustomerRef: null,
                EAN: null,
                OrderNo: draft.transactionRowsLoadable.payload.TotalCount + 1,
                BuyerProductId: null,
                SerialNumber: null,
                Comment: null,
                AccountDescription: null,
                AccountingDate: null,
                IsNew: true,
            });
            draft.transactionRowsLoadable.payload.TotalCount++;
        }),
    )
    .addReducer(
        removeRow,
        produce((draft, action) => {
            draft.transactionRowsLoadable.payload.Items = draft.transactionRowsLoadable.payload.Items.filter((i: TransactionRowDTO) => i.Id !== action.payload);
        }),
    )
    .addReducer(
        getTransactionRowsVatRates.request,
        (state): State => {
            return {
                ...state,
                transactionRowsVatRatesLoadable: state.transactionRowsVatRatesLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        getTransactionRowsVatRates.success,
        (state, action): State => {
            return {
                ...state,
                transactionRowsVatRatesLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getTransactionRowsVatRates.error,
        (state): State => {
            return {
                ...state,
                transactionRowsVatRatesLoadable: LoadableData.payload(undefined),
            };
        },
    )
    .addReducer(
        getTransactionRowsExtendedInfoList.request,
        (state): State => {
            return {
                ...state,
                transactionRowsExtendedInfoLoadable: state.transactionRowsExtendedInfoLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        getTransactionRowsExtendedInfoList.success,
        (state, action): State => {
            return {
                ...state,
                transactionRowsExtendedInfoLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getTransactionRowsExtendedInfoList.error,
        (state): State => {
            return {
                ...state,
                transactionRowsExtendedInfoLoadable: LoadableData.payload(undefined),
            };
        },
    )
    .addReducer(
        transactionRowsCopyDownLoadableActions.request,
        (state): State => {
            return {
                ...state,
                transactionRowsCopyDownLoadable: LoadableData.loading(undefined),
            };
        },
    )
    .addReducer(
        transactionRowsCopyDownLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                transactionRowsCopyDownLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        transactionRowsCopyDownLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                transactionRowsCopyDownLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state, action: Action<RouterState>): State => {
            if (/\/invoiceconfirmation/.test(action.payload.location.pathname)) {
                return {
                    ...state,
                };
            }
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'invoice/transaction-rows',
    whitelist: ['searchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as TransactionRowsViewState };

export const selectTransactionRowsListLoadable = (state: GlobalState) => state.transactionRows.transactionRowsLoadable;
export const selectTransactionRowsListSearchParams = (state: GlobalState) => state.transactionRows.searchParams;
export const selectTransactionRowsVatRatesListLoadable = (state: GlobalState) => state.transactionRows.transactionRowsVatRatesLoadable;
export const selectTransactionRowsExtendedInfoListLoadable = (state: GlobalState) => state.transactionRows.transactionRowsExtendedInfoLoadable;
export const selectTransactionRowsCopyDownLoadable = (state: GlobalState) => state.transactionRows.transactionRowsCopyDownLoadable;
