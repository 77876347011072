require("../src/common/monitoring/userReport");
require("../src/common/analytics/analytics");

import {is1to1, isGrantThornton, isSuf, isRiga, isMaxima, isUsingGoogleAnalytics} from "../src/common/utils/whitelabelHelper";

require("../Content/font-awesome.min.css");
require("../css/xeditable.0.1.8.css");
require("../css/nestable.css");
require("../Content/slick.css");
require("../Content/ng-tags-input.css");
require("../Content/ng-table.min.css");
require("../css/zabuto_calendar.min.css");
require("../css/oi-select.css");
require("../node_modules/@shagstrom/split-pane/split-pane.css");
require("../node_modules/angular-ui-grid/ui-grid.min.css");

global.cssVars = require('css-vars-ponyfill').default;

cssVars({
  watch: true,
  exclude: "link[ng-href*='css']",
});

window.document.body.style.visibility = 'hidden';

if (isGrantThornton()) {
    import("../Content/scss/themes/grantthorton/grantthorton.scss").then(() => {
        window.document.body.style.visibility = '';
    });
} else if (is1to1()) {
    import("../Content/scss/themes/1to1/1to1.scss").then(() => {
        window.document.body.style.visibility = '';
    });
} else if (isSuf()) {
  import("../Content/scss/themes/suf/suf.scss").then(() => {
    window.document.body.style.visibility = '';
  });
} else if (isRiga()) {
  import("../Content/scss/themes/riga/riga.scss").then(() => {
    window.document.body.style.visibility = '';
  });
} else if (isMaxima()) {
  import("../Content/scss/themes/maxima/maxima.scss").then(() => {
    window.document.body.style.visibility = '';
  });
} else {
  // Using the default styles for both FitekIN and SUF
  import("../Content/scss/themes/unifiedpost/unifiedpost.scss").then(() => {
    window.document.body.style.visibility = '';
  });
}

function requireAll(context) {
  context.keys().forEach(context);
}

global.CurrentVersion = process.env.REACT_APP_VERSION;
global.PublicUrl = window.publicUrl;
global.stompUrl = '/stomp';
global.companies = {};

require("../node_modules/sockjs-client/dist/sockjs.min");
require("../node_modules/stompjs/lib/stomp.min");
global.jQuery = global.$ = require("jquery");
require("../Scripts/jquery/jqueryUi.min.js"); // TODO: clean up this mess
require("../Scripts/modernizr.js");
require("../Scripts/svgxuse");
require("angular");
require("ng-device-detector");
require("../node_modules/angular-translate/dist/angular-translate.min.js");
require("../node_modules/angular-translate/dist/angular-translate-loader-url/angular-translate-loader-url.min.js");
require("../node_modules/angular-translate/dist/angular-translate-storage-local/angular-translate-storage-local.min.js");
require("../node_modules/angular-translate/dist/angular-translate-storage-cookie/angular-translate-storage-cookie.min.js");
require("../node_modules/angular-translate/dist/angular-translate-loader-static-files/angular-translate-loader-static-files.min.js");
require("../node_modules/angular-translate/dist/angular-translate-handler-log/angular-translate-handler-log.min.js");
require("../node_modules/angular-mocks/angular-mocks.js");
require("../node_modules/angular-animate/angular-animate.min.js");
require("../node_modules/angular-route/angular-route.min.js");
require("../node_modules/angular-sanitize/angular-sanitize.min.js");
require("../node_modules/angular-local-storage/dist/angular-local-storage.min.js");
require("../node_modules/angular-cookies/angular-cookies.min.js");
require("../node_modules/angular-filter/dist/angular-filter.min.js");
require("../node_modules/@shagstrom/split-pane/split-pane.js");
require("../node_modules/@shagstrom/angular-split-pane/angular-split-pane.js");
require("../node_modules/angular-ui-grid/ui-grid.min.js");
require("../Scripts/bootstrap.min.js");
require("../node_modules/angular-ui-bootstrap/dist/ui-bootstrap-tpls.js");
global._ = require("../Scripts/underscore.min.js");
require("../Scripts/ng-table.min.js");
require("../Scripts/ng-flow/ng-flow.min.js");
global.Flow = require("../Scripts/flow/flow.min.js");
require("../Scripts/slick/slick.js");
require("../Scripts/slick/angular-slick.min.js");
require("../Scripts/ng-tags/ng-tags-input.js");
global.Sortable = require("../Scripts/sortable.min.js");
require("../Scripts/xeditable.0.1.8.js");
require("../Scripts/zabuto_calendar.min.js");
require("../Scripts/date.format.js");

// Google analytics scripts
require("../Scripts/angulartics.min.js");
require("../Scripts/angulartics-gtm.min.js");

require("../node_modules/angular-css/angular-css.min.js");
global.FileSaver = require("file-saver");
global.saveAs = FileSaver.saveAs;
require("../Scripts/select-tpls.min.js");


require("./app.js");
require("./modules.js");
require("./views/views.module.js");
require("./filters.js");
require("./directives.js");
require("./directives/access-level.js");
require("./directives/autofocus.directive.js");
require("./directives/draggable-modal.directive.js");
require("./directives/debounce-mouse-hover.directive.js");
require("./directives/do-on-typeahead-blur.directive.js");
require("./directives/do-on-input-save.directive.js");
require("./directives/do-on-input-cancel.directive.js");
require("./directives/on-iframe-load.directive.js");
require("./directives/ellipsis.directive.js");
require("./directives/sort.js");
require("./directives/moveFocusOnEnter.js");
require("./directives/popOver.js");
require("./directives/core/buttons/delete/delete-btn.directive.js");
require("./directives/core/handlers/handle-esc.directive.js");
require("./directives/core/handlers/handle-enter.directive.js");
require("./directives/core/handlers/handle-enter-as-tab.directive.js");
require("./directives/core/handlers/string-to-number.directive.js");
require("./directives/core/handlers/remove-non-numbers.directive.js");
require("./components.js");
require("./react-bridge.js");
require("./services.js");
require("./core/core.module.js");
require("./blocks/blocks.module.js");

require("./blocks/auth/auth.module.js");
require("./blocks/auth/auth.service.js");
require("./blocks/auth/auth.interceptor.js");

require("./blocks/router/router.module.js");
require("./blocks/router/router.provider.js");
require("./blocks/websocket/websocket.module.js");
require("./blocks/websocket/websocket.service.js");
require("./blocks/utility/utility.module.js");
require("./blocks/utility/utility.service.js");
require("./blocks/companyData/company-data.module.js");
require("./blocks/companyData/company-data.service.js");
require("./blocks/invoice/invoice.module.js");
require("./blocks/invoice/invoice.service.js");

require("./components/approversDashboardInvoices/ds-approvers-dashboard-invoices.js");
require("./components/accountantDashboardInvoices/ds-accountant-dashboard-invoices.js");
require("./components/dashboardReceipts/ds-dashboard-receipts.js");
require("./components/calendar/ds-calendar.js");
require("./components/changedVersionReminder/ds-changed-version-reminder.js");
require("./components/whosAway/ds-whos-away.js");
//require("./components/outOfOffice/ds-out-of-office.js");
//require("./components/outOfOffice/ds-out-of-office-modal.js");
require("./components/invoiceInformation/ds-invoice-information.js");
require("./components/invoiceInformation/ds-delete-invoice-modal.js");
require("./components/relatedDocuments/ds-related-documents.js");
require("./components/exportManagement/ds-export-management.js");
require("./components/relatedDocuments/ds-related-documents-modal.js");
require("./components/fileViewer/ds-file-viewer.js");
require("./components/sessionReminder/ds-session-reminder.js");
require("./components/search/ds-search.js");
require("./components/autoTransactions/ds-auto-transaction-apply.component.js");
require("./components/autoTransactions/ds-auto-transaction-confirmation.component.js");
require("./components/transactionRows/ds-import-xls.js");
require("./components/transactionRows/components/autoTransactionsPopover/ds-auto-transactions-popover.component.js");
require("./components/transactionRows/components/combinePopover/ds-combine-popover.component.js");
require("./components/transactionRows/ds-transaction-rows.js");
require("./components/transactionRows/ds-transaction-row-split.js");
require("./components/transactionRowsCombine/ds-transaction-rows-combine.component.js");
require("./components/invoiceFilter/ds-invoice-filter.js");
require("./components/invoiceRegisterTable/ds-invoice-register-table.js");
require("./components/invoiceCustomFields/invoiceCustomFieldsListController.js");
require("./components/invoiceCustomFields/invoiceCustomFieldsDetailController.js");
require("./components/invoiceRegisterTable/ds-invoice-register-date-modal.js");
require("./components/support/ds-support.js");
require("./components/paginatorDropdown/ds-paginator-dropdown.component.js");
require("./components/validationFailedModal/ds-validation-failed-modal.component.js");

requireAll(require.context('./react-bridge', true));

require("./views/detailed-transactions/detailed-transactions.module.js");
require("./views/detailed-transactions/detailed-transactions.controller.js");
require("./views/detailed-transactions/detailed-transactions.route.js");
require("./views/invoice-register/invoice-register.module.js");
require("./views/invoice-register/invoice-register.controller.js");
require("./views/invoice-register/invoice-register.route.js");
require("./views/archive-register/archive-register.module.js");
require("./views/archive-register/archive-register.controller.js");
require("./views/archive-register/archive-register.route.js");
require("./views/archive-details/archive-details.module.js");
require("./views/archive-details/archive-details.controller.js");
require("./views/archive-details/archive-details.route.js");

require("./views/detailed-transactions/components/detailed-transactions-table/detailed-transactions-table.controller.js");
require("./views/detailed-transactions/components/detailed-transactions-table/detailed-transactions-table.service.js");
require("./views/detailed-transactions/components/detailed-transactions-table/detailed-transactions-table.templates.js");
require("./views/detailed-transactions/components/detailed-transactions-table/detailed-transactions-table.directives.js");
require("./views/detailed-transactions/components/detailed-transactions-table-header/detailed-transactions-table-header.controller.js");
require("./views/detailed-transactions/components/detailed-transactions-table-footer/detailed-transactions-table-footer.controller.js");
require("./views/archive-register/components/archive-register-table/archive-register-table.controller.js");
require("./views/archive-register/components/archive-register-filter/archive-register-filter.controller.js");
require("./views/archive-details/components/static-invoice-information/static-invoice-information.controller.js");
require("./views/archive-details/components/static-invoice-rows/static-invoice-rows.controller.js");
require("./views/archive-details/components/static-transaction-rows/static-transaction-rows.controller.js");
require("./views/archive-details/components/static-confirmation-workflow/static-confirmation-workflow.controller.js");
require("./views/archive-details/components/static-related-documents/static-related-documents.controller.js");
require("./views/archive-details/components/static-invoice-slider/static-invoice-slider.controller.js");
require("./views/side-menus/ds-side-menus.controller.js");
require("./views/side-menus/components/mobile-side-menu/ds-mobile-side-menu.controller.js");

requireAll(require.context("./services", true));

requireAll(require.context('./controllers', true));


require("./templates").initializeTemplateCache();
