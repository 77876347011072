import classNames from 'classnames';
import * as React from 'react';

import { BaseComponent } from '../BaseComponent';

export interface MainComponentRowContentProps {
    children?: React.ReactNode;
    className?: string;
}

export class MainComponentRowContent extends BaseComponent<MainComponentRowContentProps> {
    render() {
        const { children, className, ...rest } = this.props;
        const classes = classNames('table-main__row-content', className);
        return (
            <div className={classes} {...rest}>
                {children}
            </div>
        );
    }
}

export default MainComponentRowContent;
