import { LOCATION_CHANGE } from 'connected-react-router';
import { cloneDeep } from 'lodash-es';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { BaseSearch, PagedListContainer, ProductItemDTO, SearchType, SortDirection } from '../../../services/types/ApiTypes';
import { loadProductItemsLoadableActions } from './ProductItemsViewActions';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

class State {
    productItemsLoadable = new LoadableData<PagedListContainer<ProductItemDTO>, BaseSearch>();

    productItemsSearchParams: BaseSearch = {
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: '',
                Values: undefined,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        SortItems: [
            {
                SortColumn: 'Code',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 15,
        },
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        loadProductItemsLoadableActions.request,
        (state, action): State => {
            return {
                ...state,
                productItemsSearchParams: action.payload,
                productItemsLoadable: state.productItemsLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        loadProductItemsLoadableActions.success,
        (state, action): State => {
            const payload = action.payload;
            payload.result.Items = action.payload.result.Items.map(
                (item): ProductItemDTO => {
                    return {
                        ...item,
                        FullName: '', // ignore FullName because we do not want it to re-trigger autosave
                    };
                },
            );
            return {
                ...state,
                productItemsSearchParams: action.payload.request,
                productItemsLoadable: state.productItemsLoadable.withPayloadIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        loadProductItemsLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                productItemsLoadable: state.productItemsLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state): State => {
            const searchRestriction = cloneDeep(state.productItemsSearchParams.Restrictions[0]);
            searchRestriction.Value = '';
            return {
                ...new State(),
                productItemsSearchParams: {
                    ...state.productItemsSearchParams,
                    Restrictions: [searchRestriction],
                },
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'accounting/product-items',
    whitelist: ['productItemsSearchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as ProductItemsViewState };

export const selectProductItemsLoadable = (state: GlobalState) => state.productItems.productItemsLoadable;
export const selectProductItemsSearchParams = (state: GlobalState) => state.productItems.productItemsSearchParams;
