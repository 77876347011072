import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { TableFilter } from '../../../components/Table/components/filter/TableFilters';
import { GlobalState } from '../../../rootReducer';
import { BaseSearchWithTableFilters, PaymentMethodDTO, PagedListContainer } from '../../../services/types/ApiTypes';

import { getPaymentMethodsListLoadable } from './PaymentMethodsActions';
import { createRequest } from './PaymentMethodsHelpers';

export type PaymentMethodsSearchParams = BaseSearchWithTableFilters<TableFilter<any>, PaymentMethodDTO>;

class State {
    paymentMethodsListLoadable = new LoadableData<PagedListContainer<PaymentMethodDTO>, PaymentMethodsSearchParams>();
    searchParams: PaymentMethodsSearchParams = {
        ...createRequest(''),
        filters: {},
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        getPaymentMethodsListLoadable.request,
        (state, action): State => {
            const paymentMethodsListLoadable = state.paymentMethodsListLoadable.withLoading(action.payload);
            return {
                ...state,
                searchParams: action.payload,
                paymentMethodsListLoadable,
            };
        },
    )
    .addReducer(
        getPaymentMethodsListLoadable.success,
        (state, action): State => {
            const paymentMethodsListLoadable = state.paymentMethodsListLoadable.withPayloadIfRequestEquals(action.payload);
            return {
                ...state,
                searchParams: action.payload.request,
                paymentMethodsListLoadable,
            };
        },
    )
    .addReducer(
        getPaymentMethodsListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                paymentMethodsListLoadable: state.paymentMethodsListLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state, action: Action<RouterState>): State => {
            if (/\/paymentmethods/.test(action.payload.location.pathname)) {
                return {
                    ...state,
                };
            }
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'settings/payment-methods',
    whitelist: ['searchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as PaymentMethodsViewState };

export const selectPaymentMethodsListLoadable = (state: GlobalState) => state.paymentMethods.paymentMethodsListLoadable;
export const selectPaymentMethodsListSearchParams = (state: GlobalState) => state.paymentMethods.searchParams;
