import { withTranslation } from 'react-i18next';
import withSuspense from '../../../../common/hocs/withSuspense';

import { GlobalState } from '../../../../rootReducer';
import { connect } from '../../../../storeConfig';

import RelatedDocuments, { RelatedDocsDispatchProps, Props } from './InvoiceRelatedDocuments';
import { setExternalLinkToOpen } from '../../../../components/OpenExternalLinkConfirm/OpenExternalLinkConfirmModalActions';
import { getInvoiceData, deleteInvoiceFile, uploadInvoiceFile } from '../../../../views/invoice-details/components/invoice-export-management/InvoiceExportManagementActions';
import { selectInvoiceDataLoadable } from '../../../../views/invoice-details/components/invoice-export-management/InvoiceExportManagementReducers';
import { setPdfToOpenAction } from '../../../../components/PDFViewer/FileViewActions';
import { selectCurrentCompanySettings } from '../../../../common/company/CompanySelectors';

const mapStateToProps = (state: GlobalState): Pick<Props, 'invoiceDataLoadable' | 'currentCompanySettings'> => ({
    invoiceDataLoadable: selectInvoiceDataLoadable(state),
    currentCompanySettings: selectCurrentCompanySettings(state),
});

const mapDispatchToProps: RelatedDocsDispatchProps = {
    setExternalLinkToOpen,
    setPdfToOpen: setPdfToOpenAction,
    getInvoiceData,
    deleteInvoiceFile,
    uploadInvoiceFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(withTranslation()(RelatedDocuments)));
