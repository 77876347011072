import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable1, Dispatchable3, Dispatchable0 } from 'redux-dispatchers';

import { createDataId } from '../../common/utils/dataId';
import { CompanyDataDTO, PagedListContainer } from '../../services/types/ApiTypes';
import { Button, ButtonType } from '../Buttons/Button';
import { ModalType, ModalScrollableFormWithFixedHeaderAndSubmit } from '../Modal/Modal';
import Icon, { ICONS } from '../Icon/Icon';
import Counter from '../Counter/Counter';
import withSuspense from '../../common/hocs/withSuspense';
import { LoadableData } from '../../common/utils/LoadableData';
import { BackOfficeCompanyDTOExtended } from '../../services/types/BoApiTypes';
import ContentBlockSearch from '../ContentBlock/ContentBlockSearch';
import Delayed from '../Delayed/Delayed';
import { CompanySwitcherSearchParams } from './ChangeCompanyReducer';

import './ChangeCompany.scss';

export interface Props {
    isModalOpen: boolean;
    userCompanies: LoadableData<PagedListContainer<BackOfficeCompanyDTOExtended>, CompanySwitcherSearchParams>;
    currentCompany: CompanyDataDTO;
}

export interface DispatchProps {
    changeCompany: Dispatchable1<string>;
    searchUserCompanies: Dispatchable3<string, boolean, boolean>;
    clearUserCompanies: Dispatchable0;
    showChangeCompany: Dispatchable1<boolean>;
    updateSingleCompanyWaitingInvoiceCount: Dispatchable1<BackOfficeCompanyDTOExtended>;
    updateAllWaitingInvoiceCounts: Dispatchable1<BackOfficeCompanyDTOExtended[]>;
}

export type ChangeCompanyProps = DispatchProps & Props & WithTranslation;

export interface State {
    filteredCompanies: BackOfficeCompanyDTOExtended[];
}

const COMPANIES_PER_PAGE = 25;

export const ChangeCompany = (props: ChangeCompanyProps) => {
    const [searchString, setSearchString] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (props.userCompanies?.payload?.Items) {
            isLoading && setIsLoading(false);
        }
    }, [props.userCompanies?.payload?.Items]);

    useEffect(() => {
        if (props.isModalOpen) {
            props.searchUserCompanies(null, null, true);
            return () => {
                props.clearUserCompanies();
                setSearchString('');
            };
        }

        return undefined;
    }, [props.isModalOpen]);

    const doSearch = () => {
        setIsLoading(true);
        setTimeout(() => {
            props.searchUserCompanies(searchString, null, true);
        }, 500);
    };

    const handleCompanyClick = (companyGuid: string) => {
        props.changeCompany(companyGuid);
    };

    const handleCounterClick = (e: React.MouseEvent<HTMLSpanElement>, company: BackOfficeCompanyDTOExtended) => {
        e.preventDefault();
        e.stopPropagation();
        e.bubbles = false;
        props.updateSingleCompanyWaitingInvoiceCount(company);
    };

    const getHeader = () => (
        <div className="change-company__header">
            <h2>{`${props.t('component.changeCompany.myCompanies')} (${props.userCompanies?.payload?.TotalCount || 0})`}</h2>
            <ContentBlockSearch
                autofocus
                dataId="changeCompany.Search"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)}
                onClick={doSearch}
                withSearchButton
                noHeading
                withoutSearchIcon
            />
        </div>
    );

    const closeModal = () => {
        setSearchString('');
        props.showChangeCompany(false);
    };

    return (
        <ModalScrollableFormWithFixedHeaderAndSubmit
            isOpen={props.isModalOpen}
            type={ModalType.FIXED_RIGHT_MIDDLE_SCROLL}
            onClose={closeModal}
            header={getHeader()}
            onSubmitClick={() => props.updateAllWaitingInvoiceCounts(props.userCompanies.payload?.Items)}
            footerButtonText={props.t('component.changeCompany.refreshAll')}
            className="change-company"
            dataId="changeCompanyModal"
        >
            <div className="change-company__companies">
                {!isLoading && props.userCompanies && (
                    <>
                        {props.userCompanies.payload?.Items?.map((company) => (
                            <Button
                                dataId={`company.${company.CompanyGuid}.ChangeCompanyItem`}
                                isActive={company.CompanyGuid === props.currentCompany.CompanyGuid}
                                className="change-company__company"
                                buttonType={ButtonType.FLUID}
                                key={company.CompanyGuid}
                                onClick={() => {
                                    if (company.AllowToSwitch) {
                                        handleCompanyClick(company.CompanyGuid);
                                    }
                                }}
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (e.key === 'Enter' && company.AllowToSwitch) {
                                        handleCompanyClick(company.CompanyGuid);
                                    }
                                }}
                                customTag="span"
                                tabIndex={0}
                            >
                                <span className="change-company__company-name">{company.CompanyName}</span>
                                {company.WaitingInvoiceCount !== -1 &&
                                    (company.AllowToSwitch ? (
                                        <Counter
                                            loading={company.WaitingInvoiceCount === undefined}
                                            dataId={createDataId('changeCompany', company.CompanyGuid, 'counter')}
                                            isRound
                                            clickHandler={(e) => {
                                                handleCounterClick(e, company);
                                            }}
                                            className={company.WaitingInvoiceCount ? '' : 'counter__zero'}
                                        >
                                            {company.WaitingInvoiceCount}
                                        </Counter>
                                    ) : (
                                        <span className="icon-container" data-id={createDataId('changeCompany', 'lock-icon')}>
                                            <Icon name={ICONS.LOCK_24} />
                                        </span>
                                    ))}
                            </Button>
                        ))}
                    </>
                )}
                {(props.userCompanies.loading || isLoading) &&
                    Array.from(
                        '1'.repeat(
                            isLoading
                                ? COMPANIES_PER_PAGE
                                : props.userCompanies?.payload?.Items?.length > 0
                                ? props.userCompanies?.payload?.TotalCount - props.userCompanies?.payload?.Items?.length > COMPANIES_PER_PAGE
                                    ? COMPANIES_PER_PAGE
                                    : props.userCompanies?.payload?.TotalCount - props.userCompanies?.payload?.Items?.length
                                : COMPANIES_PER_PAGE,
                        ),
                    ).map((j, i) => (
                        <Delayed key={`delayed-${i}`} waitBeforeShow={i * 150}>
                            <CompanyPlaceholder key={`plhldr-${i}`} />
                        </Delayed>
                    ))}
                {props.userCompanies?.payload?.TotalCount > props.userCompanies?.payload?.Items?.length && (
                    <div className={'change-company__load-more' + (props.userCompanies.loading || isLoading ? ' hide' : '')}>
                        <Button
                            dataId={createDataId('changeCompany', 'loadMore')}
                            className="change-company__load-more-btn"
                            buttonType={ButtonType.TEXT}
                            onClick={() => {
                                props.searchUserCompanies(props.userCompanies?.request?.Restrictions[0]?.Value || null, true, false);
                            }}
                            disabled={props.userCompanies.loading || isLoading}
                            tabIndex={0}
                        >
                            {props.t('component.changeCompany.loadMore')}
                        </Button>
                    </div>
                )}
            </div>
        </ModalScrollableFormWithFixedHeaderAndSubmit>
    );
};

const CompanyPlaceholder = () => (
    <div className="change-company__company--placeholder">
        <span className="company-name"></span>
        <span className="company-count"></span>
    </div>
);

export default withSuspense(withTranslation()(ChangeCompany));
