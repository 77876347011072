'use strict';

angular.module('dstreamApp.controllers').controller('alertController', ['$scope',
	'$uibModalInstance', 'question', 'alertConfirmText', function ($scope, $uibModalInstance, question, alertConfirmText) {
	    $scope.question = question;
        $scope.alertConfirmText = alertConfirmText;

	   

	    $scope.close = function () {
	        $uibModalInstance.close();
	    }

	   $scope.confirm = function () {
	       $uibModalInstance.close();
	    }
	}]);