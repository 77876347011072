import { AxiosResponse } from 'axios';

import { loadableDataActions } from '../../../../common/utils/LoadableData';
import { FileDTO, InvoiceDataResponseDTO } from '../../../../services/types/ApiTypes';
import api from '../../../../services/ApiServices';
import { DispatchThunk } from '../../../../storeConfig';

const ns = 'invoiceData/';

export const getInvoiceDataLoadable = loadableDataActions<{ invoiceId: string }, InvoiceDataResponseDTO>(`${ns}GET_INVOICE_DATA`);
export const exportStatusResetLoadable = loadableDataActions<{ invoiceId: string }, boolean>(`${ns}EXPORT_STATUS_RESET`);
export const updateInvoiceExportStateLoadable = loadableDataActions<{ invoiceId: string; enabledExport: boolean }, { status: boolean }>(`${ns}SET_EXPORT_STATE`);

export function getInvoiceData(invoiceId: string) {
    return async (dispatch: DispatchThunk) => {
        let response: AxiosResponse<InvoiceDataResponseDTO>;
        try {
            dispatch(getInvoiceDataLoadable.request({ invoiceId }));
            response = await api.invoice.getInvoiceData(invoiceId);
            dispatch(getInvoiceDataLoadable.success(response.data));
        } catch (e) {
            dispatch(getInvoiceDataLoadable.error(e));
        }
    };
}

export function deleteInvoiceFile(file: FileDTO, invoiceId: string) {
    return async (dispatch: DispatchThunk) => {
        let response: AxiosResponse<any>;
        try {
            response = await api.invoice.deleteInvoiceFile(file);
            if (response && response.data === 'OK') {
                dispatch(getInvoiceData(invoiceId));
            }
        } catch (e) {
            console.error(e);
        }
    };
}

export function uploadInvoiceFile(file: FileDTO, invoiceId: string) {
    return async (dispatch: DispatchThunk) => {
        let response: AxiosResponse<any>;
        try {
            response = await api.invoice.uploadInvoiceFile(file);
            if (response && response.data === 'OK') {
                dispatch(getInvoiceData(invoiceId));
            }
        } catch (e) {
            console.error(e);
        }
    };
}

export function exportStatusReset(invoiceId: string, callback?: () => void) {
    return async (dispatch: DispatchThunk) => {
        let response: AxiosResponse<boolean>;
        try {
            dispatch(exportStatusResetLoadable.request({ invoiceId }));
            response = await api.invoice.exportStatusReset(invoiceId);
            dispatch(exportStatusResetLoadable.success(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(exportStatusResetLoadable.error(e));
        }
    };
}

export function updateInvoiceExportState(invoiceId: string, enabledExport: boolean, callback?: () => void) {
    return async (dispatch: DispatchThunk) => {
        let response: AxiosResponse<boolean>;
        try {
            if (!invoiceId) {
                return;
            }
            dispatch(updateInvoiceExportStateLoadable.request({ invoiceId, enabledExport }));
            response = await api.invoice.updateInvoiceExportSetting(invoiceId, enabledExport);
            dispatch(updateInvoiceExportStateLoadable.success({ status: response.data }));
            if (callback) {
                callback();
            }
        } catch (e) {
            console.error(e);
            dispatch(updateInvoiceExportStateLoadable.error(e));
        }
    };
}
