import { createAction } from 'redux-actions';

import { InvoiceCustomFieldDTO } from '../../../../services/types/ApiTypes';
import { loadableDataActions } from '../../../../common/utils/LoadableData';
import { DispatchThunk } from '../../../../storeConfig';
import api from '../../../../services/ApiServices';
import { notify } from '../../../../common/utils/notify';
import i18nInstance from '../../../../i18n';
import { getHeaderExtensionsList } from '../HeaderExtensionsActions';

const ns = 'new-HeaderExtension-modal/';

export const setActiveHeaderExtension = createAction<InvoiceCustomFieldDTO>(`${ns}SET_ACTIVE_HEADER_EXTENSIONS`);
export const saveHeaderExtensionActions = loadableDataActions<InvoiceCustomFieldDTO, InvoiceCustomFieldDTO>(`${ns}SAVE_HEADER_EXTENSIONS`);
export const showEditHeaderExtensionModalAction = createAction<boolean>(`${ns}SHOW_EDIT_HEADER_EXTENSIONS_MODAL`);

export const addOrUpdateHeaderExtension = (headerExtensions: InvoiceCustomFieldDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(saveHeaderExtensionActions.request(headerExtensions));
            const response = await api.headerExtensions.addInvoiceCustomField(headerExtensions);
            if (response?.status === 200) {
                dispatch(saveHeaderExtensionActions.success(response.data));
                if (headerExtensions?.Id) {
                    notify.success(i18nInstance.t('views.headerExtensions.editModal.successfully'));
                } else {
                    notify.success(i18nInstance.t('views.headerExtensions.editModal.add.successfully'));
                }
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
            dispatch(getHeaderExtensionsList());
        } catch (e) {
            console.error(e);
            dispatch(saveHeaderExtensionActions.error(e));
            if (e?.response?.data?.ErrorCode) {
                notify.error(i18nInstance.t(e.response.data.ErrorCode), i18nInstance.t('interceptorsFactory.Error'));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        }
    };
};

export function showAddOrEditHeaderExtensionModal(headerExtension?: InvoiceCustomFieldDTO) {
    return (dispatch: DispatchThunk) => {
        dispatch(setActiveHeaderExtension(headerExtension || undefined));
        dispatch(showEditHeaderExtensionModalAction(true));
    };
}
