import { isEmpty } from 'lodash-es';

import { getViewUserSearchParams, getViewUserSettings, setUserSettingValue, ListViewUserConfig } from '../../../common/user/userSettingUtil';
import { notify } from '../../../common/utils/notify';
import { loadableDataActionsWithRequest } from '../../../common/utils/LoadableData';
import i18nInstance from '../../../i18n';
import { GlobalState } from '../../../rootReducer';
import { PagedListContainer, SearchType, SortDirection, PaymentMethodDTO, UserSettingName } from '../../../services/types/ApiTypes';
import api from '../../../services/ApiServices';
import { DispatchThunk } from '../../../storeConfig';

import { PaymentMethodsSearchParams, selectPaymentMethodsListSearchParams } from './PaymentMethodsReducer';
import { getCurrentUserGroupMember, updateUserSettings } from '../../../common/user/UserActions';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../common/utils/baseSearchHelpers';
import { DEFAULT_RESTRICTION } from './PaymentMethodsHelpers';

const ns = 'settings/payment-methods/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.SETTINGS_PAYMENT_METHODS_SORT_DIRECTION,
    sortCol: UserSettingName.SETTINGS_PAYMENT_METHODS_SORT_COLUMN,
    pageSize: UserSettingName.SETTINGS_PAYMENT_METHODS_PAGE_SIZE,
};

export const getPaymentMethodsListLoadable = loadableDataActionsWithRequest<PaymentMethodsSearchParams, PagedListContainer<PaymentMethodDTO>>(`${ns}PAYMENT_METHODS_LIST`);

export const getPaymentMethodsList = (searchParams?: PaymentMethodsSearchParams) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const invoiceTypesSearchParams = selectPaymentMethodsListSearchParams(getState());
        const groupMember = getState().user.groupMemberCommonLoadable.payload;
        searchParams = searchParams || invoiceTypesSearchParams;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Code', SortDirection.Asc);

        const apiSearchParams = { ...searchParams };
        delete apiSearchParams.filters;
        let response;
        try {
            dispatch(getPaymentMethodsListLoadable.request(searchParams));
            response = await api.paymentMethod.getPaymentMethodsList(apiSearchParams, true);
            if (response?.status === 200) {
                dispatch(
                    getPaymentMethodsListLoadable.success({
                        request: searchParams,
                        result: response.data,
                    }),
                );
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            dispatch(
                getPaymentMethodsListLoadable.error({
                    request: searchParams,
                    result: e,
                }),
            );
        }
    };
};

export const setPaymentMethodsPagingOptions = (page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            paymentMethods,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const paging = paymentMethods.searchParams.PagingOptions;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(listViewConfig, groupMember);
            if (!viewConfig.pageSize || parseInt(viewConfig.pageSize, 10) !== pageSize) {
                groupMember.UserSettings = setUserSettingValue(listViewConfig.pageSize, pageSize, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: PaymentMethodsSearchParams = {
            ...paymentMethods.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        dispatch(getPaymentMethodsList(searchParams));
    };
};

export const sortPaymentMethods = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            paymentMethods,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const sorting = paymentMethods.searchParams.SortItems[0];
        const sortingDirection = sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(listViewConfig, groupMember);

            if (!viewConfig.sortCol || viewConfig.sortCol !== columnName) {
                groupMember.UserSettings = setUserSettingValue(listViewConfig.sortCol, columnName, groupMember.UserSettings);
            }
            if (!viewConfig.sortDir || parseInt(viewConfig.sortDir, 10) !== sortingDirection) {
                groupMember.UserSettings = setUserSettingValue(listViewConfig.sortDir, sortingDirection, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: PaymentMethodsSearchParams = {
            ...paymentMethods.searchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };
        dispatch(getPaymentMethodsList(searchParams));
    };
};

export const searchPaymentMethods = (searchString: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.paymentMethods.searchParams.PagingOptions;
        let searchRestriction = state.paymentMethods.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        const otherRestrictions = state.paymentMethods.searchParams.Restrictions.filter((restriction, index) => index !== 0);
        let restrictions = [];

        if (!isEmpty(searchString)) {
            if (!searchRestriction) {
                searchRestriction = {
                    Field: DEFAULT_RESTRICTION,
                    Value: searchString,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                };
            } else {
                searchRestriction.Value = searchString;
            }
            restrictions.push(searchRestriction);
        }

        if (!isEmpty(otherRestrictions)) {
            restrictions = [...restrictions, ...otherRestrictions];
        }
        const searchParams: PaymentMethodsSearchParams = {
            ...state.paymentMethods.searchParams,
            PagingOptions: {
                ...paging,
                Page: 1,
            },
            Restrictions: [...restrictions],
        };
        dispatch(getPaymentMethodsList(searchParams));
    };
};

export const deletePaymentMethod = (pm: PaymentMethodDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            await api.paymentMethod.deletePaymentMethod(pm);
            dispatch(getPaymentMethodsList());
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};
