angular.module("dstreamApp.directives").directive('ngEsc', function () {
  return function (scope, element, attrs) {
    element.bind('keydown', function (event) {
      if (event.which === 27) {
        scope.$apply(function () {
          scope.$eval(attrs.ngEsc)
        })
        element[0].blur()
        event.preventDefault()
      }
    })
  }
})
