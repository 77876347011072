(function(w, d, r) {
    if (['test', 'local'].indexOf(r) !== -1) {
        w._urq = w._urq || [];
        _urq.push(['initSite', '6206039c-42d6-41e5-9fee-0f934c11cae0']);
        (function() {
            var ur = d.createElement('script');
            ur.type = 'text/javascript';
            ur.async = true;
            ur.src = 'https:' === d.location.protocol ? 'https://cdn.userreport.com/userreport.js' : 'http://cdn.userreport.com/userreport.js';
            d.head.append(ur);
        })();
    }
})(window, document, '%REACT_APP_DEPLOYMENT%');
