import * as React from 'react';

export interface ModalTitleProps {
    children?: React.ReactNode;
    dataId?: string;
}

const ModalTitle: React.FunctionComponent<ModalTitleProps> = ({ children, dataId }: ModalTitleProps) => {
    return (
        <h2 data-id={dataId} className="dialog__title">
            {children}
        </h2>
    );
};

export default ModalTitle;
