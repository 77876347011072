import * as _ from 'lodash-es';
import Route from 'route-parser';

// Routes with dynamic params
// template is used for actual route paths, and root is used to check if user is on that route
export const pathTemplates = {};

// Route paths
export const paths = {
    public: '/',
    app: {
        dashboard: '/dashboard',
        subpage: '/react-demo',
        invoiceRegister: '/invoices/',
        listPurchaseOrders: '/purchase-orders',
        addPurchaseOrder: '/purchase-orders/add',
        purchaseOrderDetails: '/purchase-orders/details/:id',
        addAutoTransaction: '/auto-transactions/add',
        addAutoTransactionSnapshot: '/auto-transactions/add/:id',
        autoTransactionDetails: '/auto-transactions/details/:id',
        autoTransactions: '/automation',
        newInvoice: '/invoiceconfirmation',
        invoiceConfirmation: '/invoiceconfirmation/:id',
        userManagement: '/users',
        addUser: '/users/add',
        userDetails: '/users/:id',
        accounting: '/accounting',
        costObjectiveDetails: '/accounting/cost-objective-details/:id',
        vatCodeDetails: '/accounting/vat-code-details',
        accountDetails: '/accounting/account-details',
        productItems: '/productitems',
        relatedDimensions: '/relateddimensions',
        documentTypes: '/customfields',
        paymentMethods: '/paymentmethods',
        headerExtensions: '/headerextensions',
        workflows: '/workflows',
        workflowsAdd: '/workflows/add',
        workflowDetails: '/workflows/details/:id',
        suppliers: '/suppliers',
        companySettings: '/company',
    },
    backOffice: {
        default: '/backoffice',
        boUsers: '/backoffice/backoffice-users',
        domains: '/backoffice/domains',
        integrators: '/backoffice/integrators',
        companies: '/backoffice/companies',
        mailboxDetails: '/backoffice/companies/:guid/mailboxes/:mailboxGuid',
        company: '/backoffice/companies/:guid',
        resellers: '/backoffice/resellers',
        organizations: '/backoffice/organizations',
        users: '/backoffice/users',
        reports: '/backoffice/reports',
    },
};

/*
    Gives path calls type-safe parameters
 */
export function resolvePathParams<R extends object>(pathTemplate: string, pathParams: R, defaultsParamsForNilValues?: Partial<R>): string | never {
    const { pathParamsWithDefaults, unresolvedParamNames } = getPathParametersWithDefaults(pathParams, defaultsParamsForNilValues);

    const route = new Route(pathTemplate);
    const resolvedPath = route.reverse(pathParamsWithDefaults);
    if (resolvedPath === false) {
        const message =
            'failed to resolve route path from\n"' +
            pathTemplate +
            '"\nbecause parameters\n' +
            JSON.stringify(unresolvedParamNames) +
            '\nwere not resolved from params:\n' +
            JSON.stringify(pathParams) +
            '\nand default parameter values:\n' +
            JSON.stringify(defaultsParamsForNilValues);
        throw new Error(message);
    }
    return resolvedPath;
}

/**
 * @param {R} pathParams
 * @param {Partial<R extends object>} defaultsParamsForNilValues
 * @returns object based on pathParams, where values equal to `null` and ´undefined´ are replaced from `defaultsParamsForNilValues`
 */
function getPathParametersWithDefaults<R extends object>(pathParams: R, defaultsParamsForNilValues: Partial<R> | undefined): { pathParamsWithDefaults: R; unresolvedParamNames: string[] } {
    const pathParamsWithDefaults = _.extend({}, pathParams);
    const unresolvedParamNames: string[] = [];
    Object.keys(pathParams)
        .filter((paramName) => _.get(pathParams, paramName) == null)
        .forEach((paramName) => {
            const defaultValue = _.get(defaultsParamsForNilValues, paramName);
            if (defaultValue != null) {
                _.set(pathParamsWithDefaults, paramName, defaultValue);
            } else {
                unresolvedParamNames.push(paramName);
            }
        });
    return {
        pathParamsWithDefaults,
        unresolvedParamNames,
    };
}
