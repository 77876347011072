import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { GlobalState } from '../../rootReducer';

import { UserProfile, DispatchProps, Props } from './UserProfile';
import {
    changePassword,
    getAssistantsForUser,
    saveAssistantsForUser,
    showUserProfile,
    updateUserDetails,
    sendVerificationCodeToEmail,
    verifyEmail,
    getUserSessions,
    terminateSessions,
} from './UserProfileActions';
import { selectAskToVerifyEmail } from '../../common/user/UserSelectors';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        boUser: state.boUser.boCurrentUserLoadable,
        userCompanies: state.user.userCompanies,
        changePasswordLoadable: state.userProfile.changePasswordLoadable,
        userAssistantsLoadable: state.userProfile.userAssistantsLoadable,
        userSessionsLoadable: state.userProfile.userSessionsLoadable,
        saveUserAssistantsLoadable: state.userProfile.saveUserAssistantsLoadable,
        emailVerificationProcess: selectAskToVerifyEmail(state),
        sendEmailVerificationLoadable: state.userProfile.sendEmailVerificationLoadable,
        verifyEmailLoadable: state.userProfile.VerifyEmailLoadable,
        isUserProfileVisibleAndFocusOn: state.userProfile.visibleAndFocusOn,
    };
};

const mapDispatchToProps: DispatchProps = {
    changePassword,
    getAssistantsForUser,
    saveAssistantsForUser,
    showUserProfile,
    updateUserDetails,
    sendVerificationCodeToEmail,
    verifyEmail,
    getUserSessions,
    terminateSessions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserProfile));
