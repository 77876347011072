import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { LOCATION_CHANGE, RouterState } from 'connected-react-router';

import { LoadableData } from '../../common/utils/LoadableData';
import { TableFilter } from '../../components/Table/components/filter/TableFilters';
import { GlobalState } from '../../rootReducer';
import { ApiResultOfListOfPurchaseOrdersForInvoiceDTO, BaseSearchWithTableFilters, GetPurchaseOrdersForInvoiceParams, PagedListContainer, PurchaseOrdersDTO } from '../../services/types/ApiTypes';

import {
    deletePurchaseOrderLoadable,
    searchPurchaseOrdersLoadable,
    searchPurchaseOrdersForInvoiceLoadable,
    setUserTouchedStatusFilter,
    duplicatePurchaseOrderLoadable,
} from './PurchaseOrdersViewActions';
import { createRequest } from './PurchaseOrdersViewHelper';

export type POSearchParams = BaseSearchWithTableFilters<Array<TableFilter<any>>, PurchaseOrdersDTO>;

class State {
    userTouchedStatusFilter = false;
    searchParams: POSearchParams = {
        ...createRequest('', 1),
        filters: {},
    };
    purchaseOrdersLoadable = new LoadableData<PagedListContainer<PurchaseOrdersDTO>, POSearchParams>();
    purchaseOrdersForInvoiceLoadable = new LoadableData<ApiResultOfListOfPurchaseOrdersForInvoiceDTO, GetPurchaseOrdersForInvoiceParams>();
    deletePurchaseOrderLoadable = new LoadableData<PurchaseOrdersDTO, PurchaseOrdersDTO>();
    duplicatePurchaseOrderLoadable = new LoadableData<PurchaseOrdersDTO, PurchaseOrdersDTO>();
}

export default new ReducerFactory(new State())
    .addReducer(
        setUserTouchedStatusFilter,
        (state, action): State => {
            return {
                ...state,
                userTouchedStatusFilter: action.payload,
            };
        },
    )
    .addReducer(
        searchPurchaseOrdersLoadable.request,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload,
                purchaseOrdersLoadable: state.purchaseOrdersLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        searchPurchaseOrdersLoadable.success,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                purchaseOrdersLoadable: state.purchaseOrdersLoadable.withPayloadIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        searchPurchaseOrdersLoadable.error,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                purchaseOrdersLoadable: state.purchaseOrdersLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        searchPurchaseOrdersForInvoiceLoadable.request,
        (state, action): State => {
            return {
                ...state,
                purchaseOrdersForInvoiceLoadable: state.purchaseOrdersForInvoiceLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        searchPurchaseOrdersForInvoiceLoadable.success,
        (state, action): State => {
            return {
                ...state,
                purchaseOrdersForInvoiceLoadable: state.purchaseOrdersForInvoiceLoadable.withPayloadIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        searchPurchaseOrdersForInvoiceLoadable.error,
        (state, action): State => {
            return {
                ...state,
                purchaseOrdersForInvoiceLoadable: state.purchaseOrdersForInvoiceLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        deletePurchaseOrderLoadable.request,
        (state): State => {
            return {
                ...state,
                deletePurchaseOrderLoadable: state.deletePurchaseOrderLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        deletePurchaseOrderLoadable.error,
        (state, action): State => {
            return {
                ...state,
                deletePurchaseOrderLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        duplicatePurchaseOrderLoadable.request,
        (state): State => {
            return {
                ...state,
                duplicatePurchaseOrderLoadable: state.duplicatePurchaseOrderLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        duplicatePurchaseOrderLoadable.success,
        (state, action): State => {
            return {
                ...state,
                duplicatePurchaseOrderLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        duplicatePurchaseOrderLoadable.error,
        (state, action): State => {
            return {
                ...state,
                duplicatePurchaseOrderLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state, action: Action<RouterState>): State => {
            if (/\/purchase-orders/.test(action.payload.location.pathname)) {
                return {
                    ...state,
                };
            }
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

export { State as PurchaseOrdersViewState };

export const selectUserTouchedStatusFilter = (state: GlobalState) => state.purchaseOrders.userTouchedStatusFilter;
export const selectDeletePurchaseOrderLoadable = (state: GlobalState) => state.purchaseOrders.deletePurchaseOrderLoadable;
export const selectPurchaseOrdersLoadable = (state: GlobalState) => state.purchaseOrders.purchaseOrdersLoadable;
export const selectSearchParams = (state: GlobalState) => state.purchaseOrders.searchParams;
export const selectPurchaseOrdersForInvoiceLoadable = (state: GlobalState) => state.purchaseOrders.purchaseOrdersForInvoiceLoadable;
