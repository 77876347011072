import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { isArray } from 'lodash-es';
import { initReactI18next } from 'react-i18next';
import { ICONS } from './components/Icon/Icon';

export const selectedLanguageKey = 'NG_TRANSLATE_LANG_KEY';

const DEFAULT_LANGUAGE = 'en_US';

// NB: this file has to be imported and referenced for the reactI18nextModule to be initialized,
// otherwise the translate HOC will not get the correct i18n instance reference
const i18next = i18n
    .use(XHR)
    .use(LngDetector)
    .use(initReactI18next); // if not using I18nextProvider

export const languages = [
    {
        locale: 'cs_CZ',
        name: 'Česky',
        icon: ICONS.CZ_SQ,
    },
    {
        locale: 'de_DE',
        name: 'Deutsch',
        icon: ICONS.GER_SQ,
    },
    {
        locale: 'et_EE',
        name: 'Eesti',
        icon: ICONS.EE_SQ,
    },
    {
        locale: 'en_US',
        name: 'English',
        icon: ICONS.GB_SQ,
    },
    {
        locale: 'fr_FR',
        name: 'Français',
        icon: ICONS.FR_SQ,
    },
    {
        locale: 'fi_FI',
        name: 'Suomi',
        icon: ICONS.FI_SQ,
    },
    {
        locale: 'hr_HR',
        name: 'Hrvatski',
        icon: ICONS.HR_SQ,
    },
    {
        locale: 'lv_LV',
        name: 'Latviešu',
        icon: ICONS.LV_SQ,
    },
    {
        locale: 'lt_LT',
        name: 'Lietuvių',
        icon: ICONS.LT_SQ,
    },
    {
        locale: 'hu_HU',
        name: 'Magyar',
        icon: ICONS.HU_SQ,
    },
    {
        locale: 'nl_NL',
        name: 'Nederlands',
        icon: ICONS.NL_SQ,
    },
    {
        locale: 'pl_PL',
        name: 'Polski',
        icon: ICONS.PL_SQ,
    },
    {
        locale: 'ro_RO',
        name: 'Română',
        icon: ICONS.RO_SQ,
    },
    {
        locale: 'sr_SL',
        name: 'Srpski',
        icon: ICONS.RS_SQ,
    },
    {
        locale: 'sk_SK',
        name: 'Slovenčina',
        icon: ICONS.SK_SQ,
    },
    {
        locale: 'bg_BG',
        name: 'Български',
        icon: ICONS.BG_SQ,
    },
    {
        locale: 'ru_RU',
        name: 'Русский',
        icon: ICONS.RU_SQ,
    },
    {
        locale: 'sr_SC',
        name: 'Српски',
        icon: ICONS.RS_SQ,
    },
];

const getUserBrowserLanguage = () => {
    const langs = navigator.languages || navigator.language?.substring(0, 2);
    let browserLang: string;
    if (isArray(langs)) {
        browserLang = langs.find((l) => languages.find((lang) => lang.locale.substring(0, 2) === l.substring(0, 2)));
        return languages.find((l) => l.locale.substring(0, 2) === (browserLang?.substring(0, 2) || DEFAULT_LANGUAGE.substring(0, 2)))?.locale;
    } else {
        browserLang = languages.find((l) => langs === l.locale.substring(0, 2))?.locale;
        return languages.find((l) => l.locale === (browserLang || DEFAULT_LANGUAGE))?.locale;
    }
};
// if no saved language, try to detect it and change it, i18next will use the localStorage language by default
if (!localStorage.getItem(selectedLanguageKey)) {
    localStorage.setItem(selectedLanguageKey, getUserBrowserLanguage());
}

i18next.init({
    fallbackLng: process.env.NODE_ENV === 'test' ? 'cimode' : DEFAULT_LANGUAGE,
    debug: false,
    ns: 'common',
    defaultNS: 'common',
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    backend: {
        loadPath: () => {
            if (document.title.includes('Storybook')) {
                return '/app/resources/locale-{{lng}}.json';
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            return `${window.publicUrl}/app/resources/locale-{{lng}}.json`;
        },
        /*eslint no-undef:0*/
        queryStringParams: { v: BUILD_TIME },
    },
    detection: {
        lookupLocalStorage: selectedLanguageKey,
    },
    nsSeparator: false, // needed for allowing colon ':' inside translatable string
    keySeparator: '$',
    // react i18next special options (optional)
    react: {
        useSuspense: true,
    },
});

const i18nInstance = i18next;

// register default plural rules for all of our languages, because our locale format is unknown for i18next https://github.com/i18next/i18next/issues/1061#issuecomment-395528467
languages.forEach((l) => {
    i18nInstance.services.pluralResolver.addRule(l.locale, {
        name: l.name,
        numbers: [1, 2],
        plurals: (n: number) => {
            return Number(n !== 1);
        },
    });
});

function formatLanguage(lang: string) {
    if (!lang) {
        return '';
    }
    return lang.replace('-', '_');
}

export function detectLanguage(detectedIpLanguage: string) {
    let language: string;
    try {
        if (detectedIpLanguage && navigator.languages && navigator.languages.includes(detectedIpLanguage) && languages.find((l) => l.locale === formatLanguage(detectedIpLanguage))) {
            return formatLanguage(detectedIpLanguage);
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
        language = language.replace('-', '_');
    } catch (e) {
        console.error('Error detecting user language', e);
    }
    if (language && languages.find((l) => l.locale === language)) {
        return language;
    }
    return 'en_US';
}

export default i18nInstance;
