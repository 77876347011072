import { withTranslation } from 'react-i18next';

import withSuspense from '../../../../common/hocs/withSuspense';
import { isInvoiceRowsCalculationActive } from '../../../../common/user/UserSelectors';
import { connect } from '../../../../storeConfig';
import { GlobalState } from '../../../../rootReducer';
import InvoiceRows, { DispatchProps, Props } from './InvoiceRows';
import {
    deleteRow,
    bulkDeleteInvoiceRow,
    getInvoiceRowsList,
    gotoLastPageAndAddNewRow,
    removeNewRow,
    saveRow,
    setInvoiceRowsListPagingOptions,
    setIsInvoiceRowsCalculationActive,
} from './InvoiceRowsActions';
import { importFromInvoiceRows, uploadFile } from '../../components/transaction-rows/TransactionRowsActions';
import { selectInvoiceRowsListLoadable, selectInvoiceRowsListSearchParams } from './InvoiceRowsReducers';

const mapStateToProps = (state: GlobalState): Pick<Props, 'invoiceRowsLoadable' | 'searchParams' | 'isInvoiceRowsCalculationActive'> => ({
    invoiceRowsLoadable: selectInvoiceRowsListLoadable(state),
    searchParams: selectInvoiceRowsListSearchParams(state),
    isInvoiceRowsCalculationActive: isInvoiceRowsCalculationActive(state),
});

const mapDispatchToProps: DispatchProps = {
    getInvoiceRowsList,
    setInvoiceRowsListPagingOptions,
    saveRow,
    deleteRow,
    bulkDeleteInvoiceRow,
    removeNewRow,
    gotoLastPageAndAddNewRow,
    setIsInvoiceRowsCalculationActive,
    importFromInvoiceRows,
    uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(withTranslation()(InvoiceRows)));
