(function() {
    'use strict';

    angular
        .module('dstreamApp.directives')
        .directive('moveFocusOnEnter', moveFocusOnEnter);

    /* @ngInject */
    function moveFocusOnEnter() {
        var directive = {
            restrict: 'A',
            link: linkFunc
        }

        return directive;


        function linkFunc(scope, element, attrs, tab) {
            var focusables = $(":focusable");
            // on keypress event focus on another element
            $(element).keypress(function (e) {
                if (e.keyCode == 13) {
                    var current = focusables.index(this);
                    var next = focusables.eq(current + 1).length ? focusables.eq(current + 1) : focusables.eq(0);
                    next.focus();
                    e.preventDefault();
                }
            });
        }
    }
}());
