import { getViewUserSearchParams, ListViewUserConfig } from '../../../common/user/userSettingUtil';
import { FileReaderResult } from '../../../common/utils/fileReader';
import { notify } from '../../../common/utils/notify';
import { loadableDataActionsWithRequest } from '../../../common/utils/LoadableData';
import i18nInstance from '../../../i18n';
import { GlobalState } from '../../../rootReducer';
import { BaseSearch, FileDTO, PagedListContainer, ProductItemDTO, SortDirection, UserSettingName } from '../../../services/types/ApiTypes';
import api from '../../../services/ApiServices';
import { DispatchThunk } from '../../../storeConfig';
import { selectProductItemsSearchParams, DEFAULT_RESTRICTION } from './ProductItemsViewReducer';
import { getCurrentUserGroupMember } from '../../../common/user/UserActions';
import { isEmpty } from 'lodash-es';
import { updateAppUserSettingsAction } from '../../../common/middlewares/userSettings';

const ns = 'settings/product-items/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.SETTINGS_PRODUCT_ITEMS_SORT_DIRECTION,
    sortCol: UserSettingName.SETTINGS_PRODUCT_ITEMS_SORT_COLUMN,
    pageSize: UserSettingName.SETTINGS_PRODUCT_ITEMS_PAGE_SIZE,
};

export const setPagingOptions = (page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState): Promise<any> => {
        const productItemsSearchParams = selectProductItemsSearchParams(getState());
        const paging = productItemsSearchParams.PagingOptions;

        const searchParams: BaseSearch = {
            ...productItemsSearchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        return dispatch(searchProductItems(searchParams));
    };
};

export const loadProductItemsLoadableActions = loadableDataActionsWithRequest<BaseSearch, PagedListContainer<ProductItemDTO>>(`${ns}LOAD_PRODUCT_ITEMS`);

export function searchProductItems(searchParams?: BaseSearch | undefined) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const productItemsSearchParams = selectProductItemsSearchParams(getState());
        searchParams = searchParams || productItemsSearchParams;

        const groupMember = getState().user.groupMemberCommonLoadable.payload;
        if (groupMember) {
            searchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        }

        let response;
        try {
            dispatch(loadProductItemsLoadableActions.request(searchParams));
            response = await api.productItem.getProductItems(searchParams);
            dispatch(
                loadProductItemsLoadableActions.success({
                    request: searchParams,
                    result: response.data,
                }),
            );

            // if our page for some reason is empty, but there is data on previous pages, then load previous pages until we have some data to display
            if (response.data.Items.length === 0 && response.data.HasCount) {
                const currentPage = productItemsSearchParams.PagingOptions.Page;
                if (currentPage > 1) {
                    return dispatch(setPagingOptions(currentPage - 1));
                }
            }
            return Promise.resolve();
        } catch (e) {
            console.error(e);
            dispatch(
                loadProductItemsLoadableActions.error({
                    request: searchParams,
                    result: e,
                }),
            );
            return Promise.resolve();
        }
    };
}

export const filterProductItems = (searchString: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const productItemsSearchParams = selectProductItemsSearchParams(state);
        const paging = productItemsSearchParams.PagingOptions;

        // taking default restriction - GeneralSearch - from reducer
        const searchRestriction = productItemsSearchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);

        const searchParams: BaseSearch = {
            ...productItemsSearchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: [
                {
                    ...searchRestriction,
                    Value: searchString,
                },
            ],
        };
        dispatch(searchProductItems(searchParams));
    };
};

export const sortProductItems = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const productItemsSearchParams = selectProductItemsSearchParams(getState());
        const sorting = productItemsSearchParams.SortItems[0];

        const searchParams: BaseSearch = {
            ...productItemsSearchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };
        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        dispatch(searchProductItems(searchParams));
    };
};

export const deleteProductItem = (id: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const productItemsSearchParams = selectProductItemsSearchParams(getState());
            const response = await api.productItem.deleteProductItem(id, productItemsSearchParams);
            dispatch(loadProductItemsLoadableActions.request(productItemsSearchParams));
            dispatch(
                loadProductItemsLoadableActions.success({
                    request: productItemsSearchParams,
                    result: response.data,
                }),
            );
        } catch (e) {
            console.error(e);
        }
    };
};

export function uploadCSV(fileReaderResult: FileReaderResult) {
    return async (dispatch: DispatchThunk) => {
        try {
            const file: FileDTO = {
                Base64Content: fileReaderResult.base64Content,
                FileName: fileReaderResult.fileName,
                WorkflowDocumentId: undefined,
                DownloadUrl: undefined,
                FileUrl: undefined,
                ForceImport: undefined,
                HubType: undefined,
                Id: undefined,
                IsImported: undefined,
                IsNew: undefined,
                IsPdf: undefined,
                PathToFile: undefined,
            };

            const response = await api.productItem.uploadCSVFile(file);
            if (response.data) {
                notify.success(i18nInstance.t('component.ProductItems.fileUploadedSuccessfully'), i18nInstance.t('views.home.index.ProductItems'));
            } else {
                notify.error(i18nInstance.t('component.ProductItems.errorWhileFileUploading'), i18nInstance.t('interceptorsFactory.Error'));
            }
            dispatch(setPagingOptions(1));
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('component.ProductItems.errorWhileFileUploading'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
}
