import classNames from 'classnames';
import * as React from 'react';

import { BaseComponent } from '../BaseComponent';

export interface MainComponentRowActionsProps {
    children?: React.ReactNode;
    className?: string;
}

export class MainComponentRowActions extends BaseComponent<MainComponentRowActionsProps> {
    render() {
        const { children, className, ...rest } = this.props;
        const classes = classNames('table-main__row-actions', className);
        return (
            <div className={classes} {...rest}>
                {children}
            </div>
        );
    }
}

export default MainComponentRowActions;
