import { getCurrentUserGroupMember, setCurrentUserAction } from '../../../src/common/user/UserActions';
import { isAuthorized } from '../../../src/common/user/userPermissionUtil';
import { isSettingEnabled } from '../../../src/common/user/userCompanySettingsUtil';
import { showNewVersionDialog } from '../../../src/common/utils/versionUtil';
import storeConfig from '../../../src/storeConfig';
import api from '../../../src/services/ApiServices';

(function() {
    'use strict';

    angular.module('blocks.auth').factory('authenticationService', authenticationService);

    authenticationService.$inject = [
        '$rootScope',
        '$q',
        '$uibModal',
        'localStorageService',
        'webServices',
        'constants',
        '_',
        '$window',
        '$translate',
        '$analytics',
        'notifyService',
        '$timeout',
        '$ngRedux',
    ];

    /*

     */
    function authenticationService(
        $rootScope,
        $q,
        $uibModal,
        localStorageService,
        webServices,
        constants,
        _,
        $window,
        $translate,
        $analytics,
        notifyService,
        $timeout,
        $ngRedux,
    ) {
        var authorizationDataString = 'authorizationData',
            serbianSSOKey = 'serbianSSO',
            userData = getAuthData(),
            userDataRequestInFlight = false;

        var authServiceFactory = {
            logOut: logOut,
            logOutId: logOutId,
            changeCompany: changeCompany,
            changePassword: changePassword,
            setUserAuthData: setUserAuthData,
            isAuthorized: isUserAuthorized,
            isCompanySettingEnabled: isCompanySettingEnabled,
            userData: userData,
            getAuthData: getAuthData,
            getExpirationTime: getExpirationTime,
            getUserData: getUserData
        };

        if (userData) {
            userData = userData.user;
        } else {
            $window.location.href = '../../login/';
        }

        /*
            Remove all local storage data and reset expirationtime
            Used for logOut
         */
        function removeAllData() {
            localStorageService.clearAll();
            localStorageService.set('ExpirationTime', new Date());
        }

        /*
            Change users password
         */
        function changePassword(oldPassword, newPassword) {
            return webServices.boChangePassword(oldPassword, newPassword).then(function(result) {
                if (result && result.data && result.data.Result) {
                    if (result.data.Result.Success) {
                        notifyService.success('component.changePassword.passwordChangedSuccessfully', 'component.changePassword.success', true);
                        return true;
                    } else {
                        notifyService.error(result.data.Result.Message, 'component.changePassword.error', true);
                        return false;
                    }

                    //var res = result.data.split(":");
                    //if (res[0] === "TRUE") {
                    //    notifyService.success("component.changePassword.passwordChangedSuccessfully", "component.changePassword.success", true);
                    //    return true;
                    //} else {
                    //    notifyService.error("component.changePassword.wrongOldPassword", "component.changePassword.error", true);
                    //    return false;
                    //}
                }
                return false;
            });
        }

        /*
            Set companyData into session
         */
        function setSessionData(companyGuid, userBoGuid) {
            return api.user.setSessionCompany(companyGuid, userBoGuid).then((result) => {
                return result;
            });
        }

        /*
            Perform a logout
         */
        function logOut(wasSessionTimeout, isUserLogout) {
            const isSSOSet = isSerbianSSOSet();
            $timeout.cancel($rootScope.sessionTimer);
            storeConfig.persistor.purge(); // purges persist data from local storage
            if (isSSOSet){
                removeSerbianSSO();
                removeAllData();
                $window.location.href = api.login.serbiaSSOLogout();
            } else {
                // if the session timed out, or logout forced by user, then show a special message in login
                $window.location.href = '../../login/' + (wasSessionTimeout ? '?expired' : '') + (isUserLogout ? '?userLogout' : '');
            }
            removeAllData();
        }

        /*
            Perform a logout
         */
        function logOutId(wasSessionTimeout) {
            alert('loggin out with ID');
            $timeout.cancel($rootScope.sessionTimer);
            removeAllData();
            storeConfig.persistor.purge(); // purges persist data from local storage
            $window.location.href = $window.location.origin.replace('http://', 'https://') + '/IdLoginApi/Home/Logout';
        }

        /*
            Change currently selected company
        */
        function changeCompany(companyGuid) {
            /**
             * Check the user's access to the selected company
             */
            return api.user.checkAccessToCompany({BackOfficeCompanyGuid: companyGuid})
                .then(function(result) {
                    if (result && result.data && result.data.CompanyAccessStatus === 0) {
                        return;
                    } else {
                        notifyService.info('component.CompanySelect.ChangeCompany.NotUserCompany', 'interceptorsFactory.Error', true);
                        throw new Error('Sellist firmat ei ole kasutajal');
                    }
                })
                .then(function() {
                    const globalState = $ngRedux.getState();
                    if (
                        globalState.hasOwnProperty('user') &&
                        globalState.user &&
                        globalState.user.hasOwnProperty('groupMemberCommonLoadable') &&
                        globalState.user.groupMemberCommonLoadable.payload &&
                        globalState.user.groupMemberCommonLoadable.payload.hasOwnProperty('UserGuid')
                    ) {
                        return $q
                            .all([
                                webServices.bochangeUserLastCompany(companyGuid),
                                setCompanyAuthData({data: globalState.user.userCompanies || []}, companyGuid),
                            ])
                            .then((responses) => {
                                if (responses[0].data === 'Company selection successfully saved.' && responses[1]) {
                                    // fetch user data only after company context changed (otherwise wrong dashboard user-related invoices are requested)
                                    return setUserAuthData();                                   
                                }
                            })
                            .then(() => {
                                $rootScope.companyGuid = companyGuid;
                                notifyService.success('component.CompanySelect.ChangeCompany.Success', 'views.global.Success', true);
                                return $rootScope.companyGuid;
                            });
                    } else {
                        /**
                         * In the extremely rare case of when CurrentUser GroupMember is not yet loaded
                         * by the time the user tries to switch companies, then initiate the loading of
                         * getCurrentUserGroupMember and then re-call this function after GM is loaded.
                         * ******************
                         * !!! If the groupMember is not even loaded after manually requesting it
                         * then log the user out because something must be very wrong then. !!!
                         * ******************
                         */
                        return $ngRedux.dispatch(getCurrentUserGroupMember()).then((response) => {
                            if (response) {
                                return changeCompany(companyGuid);
                            } else {
                                logOut(false);
                            }
                        })
                    }
                });
        }

        /*
            get new userData and set it in local storage
         */
        function setUserAuthData() {
            $ngRedux.dispatch(setCurrentUserAction(null)); // reset user to avoid fetching wrong user data on company change
            $ngRedux.dispatch(getCurrentUserGroupMember(true));
            function checkCurrentUser () {
                const currentUser = $ngRedux.getState().user.currentUser;
                if (!currentUser) {
                    // if no User in state yet, wait 300ms and check again recursively until User is fetched 
                    return new Promise(res => setTimeout(()=>res(), 300))
                        .then(() => {
                            const user = $ngRedux.getState().user.currentUser;
                            if(!user) {
                                return checkCurrentUser();
                            } else {
                                return processCurrentUser(user);
                            }
                        }
                    )
                } else {
                    return processCurrentUser(currentUser);
                }
            }

            function processCurrentUser (currentUser) {
                var authData = getAuthData();
                authData.fullName = currentUser.FullName;
                authData.userId = currentUser.Id;
                authData.user = currentUser;
                userData = currentUser;
                $rootScope.userData = userData;
                $rootScope.companies = authData.companies.sort(function(a, b) {
                    if (a.hasOwnProperty('Name')) {
                        return a.Name.localeCompare(b.Name);
                    }
                    return a.CompanyName.localeCompare(b.CompanyName);
                });
                localStorageService.set(authorizationDataString, authData);
                $ngRedux.dispatch(setCurrentUserAction(currentUser));
                // set user info into GA requests
                $analytics.setUsername(authData.userName);
                $analytics.setUserProperties({ ID: authData.userId, roles: authData.user.MemberRoles });
                $analytics.eventTrack('userIdentify', {
                    category: 'Login',
                    label: $translate.use(),
                    value: authData.userId,
                });

                reloadOnVersionChanged(userData); // check version numbers
                $rootScope.$emit('ds.currentCompany.changed', $rootScope.companyData.CompanyGuid);
                return currentUser;
            }

            return checkCurrentUser();
        }

        /*
            Get user data either from DB when it's a first login or from localstorage
            This is a mandatory method that is done in all route resolves. Where data is requested from depends if it's a first time running this method or not
         */
        function getUserData(forceFetch) {
            if ((forceFetch || !$rootScope.userData) && !userDataRequestInFlight) {
                // used during first time load or forceFetch
                userDataRequestInFlight = true;
                return setUserAuthData().then(function(res) {
                    userDataRequestInFlight = false;
                    //setUserAuthData sets the user data into local storage. Get it and also map it to rootScope
                    var authData = getAuthData();
                    if (authData) {
                        $rootScope.userData = authData.user;
                        authServiceFactory.userData = authData;
                        $rootScope.companyGuid = authData.companyGuid;
                        $rootScope.companies = authData.companies.sort(function(a, b) {
                            if (a.hasOwnProperty('Name')) {
                                return a.Name.localeCompare(b.Name);
                            }
                            return a.CompanyName.localeCompare(b.CompanyName);
                        });

                        // if userData has a Language then set it as app language
                        if ($rootScope.userData.Language) {
                            //$translate.use($rootScope.userData.Language);
                        }

                        $rootScope.$emit('authorizationDataLoaded'); // TODO LEGACY CODE. still used to initialize some components

                        return res;
                    }
                });
            } else {
                // used during route changes
                var authData = getAuthData();
                if (authData) {
                    $rootScope.userData = authData.user;
                    $rootScope.companyGuid = authData.companyGuid;
                }
                return $q.when($rootScope.userData);
            }
        }

        /*
            Set new company data to localstorage
         */
        function setCompanyAuthData(result, companyGuid) {
            var authData = getAuthData();
            authData.companyGuid = companyGuid;
            authData.companies = result.data;
            $rootScope.accountDistributionItemCustomCostObjectives = false;
            $rootScope.groupMembers = false;
            localStorageService.set(authorizationDataString, authData);
            return $q.when(authData);
        }

        /*
            If userData version number doesn't match the app version number then open a warning modal
         */
        function reloadOnVersionChanged(userData) {
            if (process.env.NODE_ENV === 'development') {
                return;
            }
            if (showNewVersionDialog(userData.CurrentVersion, constants.CurrentVersion)) {
                $uibModal.open({
                    templateUrl: 'app/components/changedVersionReminder/ds-changed-version-reminder.html',
                    controller: 'DsChangedVersionReminderController',
                    windowClass: 'changed-version-reminder',
                    backdrop: 'static',
                });
            }
        }

        /*
            Get authentication data from local storage
         */
        function getAuthData() {
            return localStorageService.get(authorizationDataString);
        }

        /*
            Get session expiration time from local storage
         */
        function getExpirationTime() {
            return localStorageService.get('ExpirationTime');
        }

        function isUserAuthorized(role) {
            // authorization logic moved to "../../../src/common/user/userPermissionUtil.ts" for reusability
            return isAuthorized(role, userData);
        }

        function isCompanySettingEnabled(setting) {
            return isSettingEnabled(setting);
        }

        function isSerbianSSOSet() {
            try {
                const isSet = localStorage.getItem(serbianSSOKey);
                if (isSet && isSet === 'true') {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                console.log('error', e);
                return false;
            }
        };

        function removeSerbianSSO() {
            localStorage.removeItem(serbianSSOKey);
        }

        return authServiceFactory;
    }
})();