import { ReducerFactory } from 'redux-actions-ts-reducer';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { BackOfficeDomainDTO } from '../../../services/types/BoApiTypes';
import { BaseSearch, PagedListContainerOf } from '../../../services/types/ApiTypes';

import { getBoDomainListLoadable, BoDomainListSearchParams, addOrEditBoDomainAction, showAddOrEditBoDomainModal, getAllBoDomainListLoadable } from './BODomainViewActions';

class State {
    boDomainListLoadable = new LoadableData<PagedListContainerOf<BackOfficeDomainDTO>, BaseSearch>();
    addOrEditBoDomainAction = new LoadableData<BackOfficeDomainDTO, BackOfficeDomainDTO>();
    searchParams: BoDomainListSearchParams;
    isEditBoDomainModalOpen: boolean;
    domainToEdit: BackOfficeDomainDTO | null;
    domains: BackOfficeDomainDTO[] = [];
}

export default new ReducerFactory(new State())
    .addReducer(
        getBoDomainListLoadable.request,
        (state, action): State => {
            return {
                ...state,
                boDomainListLoadable: LoadableData.loading(action.payload),
                searchParams: action.payload,
            };
        },
    )
    .addReducer(
        getBoDomainListLoadable.success,
        (state, action): State => {
            return {
                ...state,
                boDomainListLoadable: LoadableData.payload(action.payload.result),
                searchParams: action.payload.request,
            };
        },
    )
    .addReducer(
        getBoDomainListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                boDomainListLoadable: LoadableData.error(action.payload.result),
            };
        },
    )
    .addReducer(
        addOrEditBoDomainAction.request,
        (state, action): State => {
            return {
                ...state,
                addOrEditBoDomainAction: LoadableData.loading(action.payload),
            };
        },
    )
    .addReducer(
        addOrEditBoDomainAction.success,
        (state, action): State => {
            return {
                ...state,
                addOrEditBoDomainAction: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        addOrEditBoDomainAction.error,
        (state, action): State => {
            return {
                ...state,
                addOrEditBoDomainAction: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        showAddOrEditBoDomainModal,
        (state, action): State => {
            let newState: State;
            // payload === false                => modal closes, domains -> null
            // payload === domain object    => modal opens, domains data can be edited
            // payload === undefined            => modal opens, new domains (null) to be created
            if (action.payload === false) {
                newState = {
                    ...state,
                    domainToEdit: null,
                    isEditBoDomainModalOpen: false,
                };
            } else {
                newState = {
                    ...state,
                    isEditBoDomainModalOpen: true,
                    domainToEdit: typeof action.payload === 'object' ? action.payload : null,
                };
            }
            return newState;
        },
    )
    .addReducer(
        getAllBoDomainListLoadable.success,
        (state, action): State => {
            return {
                ...state,
                domains: action.payload,
            };
        },
    )
    .toReducer();

export { State as BoDomainManagementViewState };

export const selectBODomainListLoadable = (state: GlobalState) => state.boDomainsManagement.boDomainListLoadable;
export const selectBoDomainListSearchParams = (state: GlobalState) => {
    if (state.boDomainsManagement.searchParams?.SortItems?.length > 0) {
        state.boDomainsManagement.searchParams.SortItems[0].SortColumn = 'DomainName';
    }
    return state.boDomainsManagement.searchParams;
};
export const selectIsEditBoDomainModalOpen = (state: GlobalState) => state.boDomainsManagement.isEditBoDomainModalOpen;
export const selectDomainToEdit = (state: GlobalState) => state.boDomainsManagement.domainToEdit;
export const selectAllDomainOptions = (state: GlobalState) =>
    state.boDomainsManagement.domains
        .filter((p) => p.IsEnabled)
        .map((domain) => ({
            text: domain.DomainName,
            value: domain.DomainGuid,
        }));
