import api from '../../../services/ApiServices';
import { PaymentMethodDTO, PaymentMethodInvoiceType, SearchType, SortDirection } from '../../../services/types/ApiTypes';
import { PaymentMethodsSearchParams } from './PaymentMethodsReducer';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

export const createRequest = (searchValue = '', sortColumn = 'Code', page = 1, count = 15): PaymentMethodsSearchParams => ({
    PagingOptions: {
        Count: count,
        Page: page,
    },
    Restrictions:
        searchValue && searchValue.length > 0
            ? [
                  {
                      Field: '',
                      FieldSearchType: SearchType.NotSelected,
                      Value: searchValue || null,
                      Values: null,
                  },
              ]
            : [],
    SortItems: [
        {
            SortColumn: sortColumn,
            SortDirection: SortDirection.Asc,
        },
    ],
    filters: {},
});

export const PAYMENT_METHODS_TYPES_TRANSLATION = {
    [PaymentMethodInvoiceType.PurchaseInvoice]: 'report.InvoiceFrontPage.InvoiceType.PurchaseInvoice',
    [PaymentMethodInvoiceType.None]: 'views.paymentMethods.InvoiceType.None',
    [PaymentMethodInvoiceType.All]: 'views.paymentMethods.InvoiceType.All',
    [PaymentMethodInvoiceType.Expense]: 'views.invoice.partials.invoiceInformation.expense',
};

export const getPaymentMethodInvoiceTypeLabel = (type: PaymentMethodInvoiceType) => {
    return PAYMENT_METHODS_TYPES_TRANSLATION[type];
};

export const getPaymentMethodsTypeAheadList = async (searchString: string, typeId: number): Promise<Array<PaymentMethodDTO>> => {
    try {
        const searchParams: PaymentMethodsSearchParams = {
            SortItems: [
                {
                    SortColumn: 'Code',
                    SortDirection: SortDirection.Asc,
                },
            ],
            PagingOptions: {
                Page: 1,
                Count: 25,
            },
            Restrictions: [
                {
                    Field: DEFAULT_RESTRICTION,
                    Value: searchString || '',
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                },
                {
                    Field: 'InvoiceTypes',
                    Value: typeId + 1,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                },
            ],
        };
        const paymentMethodResponse = await api.paymentMethod.getPaymentMethodsList(searchParams, false);
        return Promise.resolve(paymentMethodResponse.data.Items);
    } catch (error) {
        console.error(error);
        return Promise.resolve(null);
    }
};

export const getPaymentMethodsTypeaheadItems = async (searchString: string, typeId: number): Promise<Array<PaymentMethodDTO>> => {
    if (!isNaN(typeId)) {
        const paymentMethods = await getPaymentMethodsTypeAheadList(searchString, typeId);
        return paymentMethods;
    }
    return [];
};
