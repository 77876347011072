'use strict';

angular.module('dstreamApp.controllers').controller('invoiceSendComplaintController', [
    '$scope', '$uibModalInstance', '$filter', '$translate', 'webServices', '_', 'utils', 'notifyService', 'invoice',
    function ($scope, $uibModalInstance, $filter, $translate, webServices, _, utils, notifyService, invoice) {
        $scope.errorDefinitions = [];
        $scope.selectedDefinition = null;
        
        $scope.invoiceError = {
            "InvoiceId": invoice.HubId,
            "InitialErrorDefinitionId": 0,
            "ClientComment": ""
        };


        $scope.close = function () {
            $uibModalInstance.close(false);
            $uibModalInstance.dismiss();
        };

        $scope.save = function () {
            if ($scope.complaintForm.$valid) {
                $scope.invoiceError.InitialErrorDefinitionId = $scope.selectedDefinition.Id;
                webServices.sendComplaint($scope.invoiceError).then(function () {
                    $uibModalInstance.close(false);
                    $uibModalInstance.dismiss();
                });
            }
        };

        function init() {
            webServices.getDomasErrorDefinitions().then(function (result) {
                $scope.errorDefinitions = result.data;
            });
        }

        init();
    }]
);