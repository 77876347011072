(function() {
    'use strict';

    angular.module('dstreamApp.views.detailed-transactions', [
        'shagstrom.angular-split-pane',
        'ui.grid',
        'ui.grid.autoResize',
        'ui.grid.pinning',
        'ui.grid.selection',
        'ui.grid.resizeColumns',
        'ui.grid.edit',
        'ui.grid.cellNav'
    ]);

})();