import * as React from 'react';

import { BaseComponent } from '../BaseComponent';
import SectionSeparator from '../SectionSeparator/SectionSeparator';

import { MenuChildProps } from './DropdownMenu';

export interface Props<T> {
    className?: string;
    children: (data: T) => React.ReactNode;
    groupName: string;
    groupItems: T[];
}

export interface GroupItem<T> {
    item: T;
}

export interface GroupItemData {
    name: string;
    id: number;
}

type DropdownGroupItemsProps<T extends GroupItemData> = Props<T> & MenuChildProps;

export class DropdownGroupItems<T extends GroupItemData> extends BaseComponent<DropdownGroupItemsProps<T>> {
    filter = (items: T[], filterValue: string, filterBy: string) => {
        return (filterValue && filterBy && items.filter((i) => i[filterBy]?.toLowerCase().includes(filterValue.toLowerCase()))) || items;
    };

    render() {
        const { className, children, groupName, groupItems, filterBy, filterValue } = this.props;
        const items = this.filter(groupItems, filterValue, filterBy);
        if (items.length === 0) {
            return null;
        }
        return (
            <div className={className}>
                <SectionSeparator className="mx-5">
                    {groupName} ({items.length})
                </SectionSeparator>
                <ul className="my-1">{items.map((item) => children(item))}</ul>
            </div>
        );
    }
}
