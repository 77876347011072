import { find, isEmpty } from 'lodash-es';
import { Settings as SlickSettings } from 'react-slick';
import { formatDate } from '../../../../common/utils/formatters';
import { InvoiceSliderMinDTO, InvoiceType, Restriction, SearchType, SortDirection } from '../../../../services/types/ApiTypes';
import {
    getFirstDateOfMonth,
    getFirstDateOfWeek,
    getFirstDateOfYear,
    getLastDateOfMonth,
    getLastDateOfWeek,
    getLastDateOfYear,
    subtractDaysFromToday,
    subtractMonthsFromToday,
} from '../../../../common/utils/datetime';

export enum InvoiceFilterTimeType {
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    THIS_WEEK = 'thisWeek',
    LAST_WEEK = 'lastWeek',
    CURRENT_MONTH = 'currentMonth',
    LAST_MONTH = 'lastMonth',
    THIS_YEAR = 'thisYear',
    CUSTOM_DATES = 'customDates',
    SHIFTED_CUSTOM_MONTH = 'shiftedCustomMonth',
    SHIFTED_CUSTOM_YEAR = 'shiftedCustomYear',
    LAST_X_DAYS = 'lastXDays',
    LAST_SIX_MONTHS = 'lastSixMonths',
    FOREVER = 'forever',
}

export enum SearchDateBy {
    INVOICE = 'invoice',
    CODING = 'coding',
    IMPORT = 'import',
    EXPORT = 'export',
    PAYMENT = 'due',
}

export interface InvoiceFilterObjBaseFilterDTO {
    Name: string;
    isTag?: boolean;
    visible?: true;
}
export interface InvoiceFilterObjValueFilterDTO extends InvoiceFilterObjBaseFilterDTO {
    Value: number;
}
export interface InvoiceFilterObjTranslateKeyFilterDTO extends InvoiceFilterObjValueFilterDTO {
    TranslateKey: string;
}

export interface UsersSortByItemDTO extends InvoiceFilterObjBaseFilterDTO {
    id: string;
}

export interface InvoiceDateFilterDTO {
    text: string;
    value: SearchDateBy;
}

export interface InvoiceFilterObjectDTO {
    searchValue: string;
    statusFilter: InvoiceFilterObjTranslateKeyFilterDTO[];
    usersFilter: InvoiceFilterObjTranslateKeyFilterDTO[];
    invoiceTypesFilter: InvoiceFilterObjValueFilterDTO[];
    dimensionFilter: InvoiceFilterObjValueFilterDTO[];
    accountsFilter: InvoiceFilterObjValueFilterDTO[];
    objectsFilter: InvoiceFilterObjValueFilterDTO[];
    moreFilter: InvoiceFilterObjTranslateKeyFilterDTO[];
    timeType: InvoiceFilterTimeType;
    timeTypeLabel: string;
    invoiceType: InvoiceType;
    isIncludeWorkflows: boolean;
    lastXDaysModel: string;
    fromModel: string;
    toModel: string;
    sortItems: Array<{
        SortColumn: string;
        SortDirection: SortDirection;
    }>;
    pagingOptions: {
        page: number;
        count: number;
        total: number;
    };
    customRestrictions: Restriction[];
    searchDateBy: InvoiceDateFilterDTO;
    usersSortByList: UsersSortByItemDTO[];
}

export interface CalculatedTimesDTO {
    from: string;
    to: string;
    label: string;
}

export const defaultRestriction: Restriction = {
    Field: '',
    Value: null,
    Values: null,
    FieldSearchType: SearchType.NotSelected,
};

export const sliderSettings: SlickSettings = {
    speed: 300,
    slidesToShow: 7,
    infinite: false,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    touchMove: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
            },
        },
    ],
};

export function displayInvoiceNumber(row: InvoiceSliderMinDTO): string {
    try {
        if (row?.Number) {
            const reversedNumber = row.Number.split('')
                .reverse()
                .join('');
            const truncatedNumber = reversedNumber.substring(0, 10); // 10 - max characters allowed
            const finalNumber = truncatedNumber
                .split('')
                .reverse()
                .join('');
            return (row.Number.length > finalNumber.length ? '...' : '') + finalNumber;
        }
        return '';
    } catch (err) {
        console.error(err);
        return '';
    }
}

/**
 * Parse filter object into correct format for the API call
 */
export function parseFilters(filterObject: InvoiceFilterObjectDTO): Restriction[] {
    const filterRestrictions: Restriction[] = [];
    let calculatedTimes: CalculatedTimesDTO;
    const timeFrame = { from: '', to: '' };

    // set timeFrame
    switch (filterObject.searchDateBy?.value) {
        case SearchDateBy.INVOICE:
            timeFrame.from = 'InvoiceDateFrom';
            timeFrame.to = 'InvoiceDateTo';
            break;
        case SearchDateBy.CODING:
            timeFrame.from = 'AccountingDateFrom';
            timeFrame.to = 'AccountingDateTo';
            break;
        case SearchDateBy.IMPORT:
            timeFrame.from = 'ImportedDateFrom';
            timeFrame.to = 'ImportedDateTo';
            break;
        case SearchDateBy.EXPORT:
            timeFrame.from = 'ExportedDateFrom';
            timeFrame.to = 'ExportedDateTo';
            break;
        case SearchDateBy.PAYMENT:
            timeFrame.from = 'DueDateFrom';
            timeFrame.to = 'DueDateTo';
            break;
        default:
            break;
    }

    //set timeType
    calculatedTimes = calculateTimes(filterObject);
    //if time frame still missing -> setting defaults
    if (isEmpty(calculatedTimes.from) || isEmpty(calculatedTimes.to)) {
        calculatedTimes = calculateTimes(filterObject);
    }

    // if our time frame is "forever", then we don't need to set time constraint
    if (calculatedTimes.from !== 'fromBeginningOfTime' && calculatedTimes.to !== 'untilEndOfTime') {
        filterObject.fromModel = calculatedTimes.from;
        filterRestrictions.push({
            ...defaultRestriction,
            Field: timeFrame.from,
            Value: formatDate(calculatedTimes.from, 'yyyy-MM-dd'),
        });
        filterObject.toModel = calculatedTimes.to;
        filterRestrictions.push({
            ...defaultRestriction,
            Field: timeFrame.to,
            Value: formatDate(calculatedTimes.to, 'yyyy-MM-dd'),
        });
    }

    filterObject.timeTypeLabel = calculatedTimes.label;

    // status filter
    if (filterObject.statusFilter && filterObject.statusFilter.length) {
        const tempStatus: Restriction = {
            ...defaultRestriction,
            Field: 'Status',
            Values: [],
        };
        filterObject.statusFilter.forEach(function(value: InvoiceFilterObjTranslateKeyFilterDTO) {
            if (value.Name && value.Value !== undefined) {
                tempStatus.Values.push(value.Value);
            }
        });
        filterRestrictions.push(tempStatus);
    }

    // users value
    if (filterObject.usersFilter && filterObject.usersFilter.length) {
        const tempConfirmed = [];
        for (const usersFilter of filterObject.usersFilter) {
            tempConfirmed.push(usersFilter.Value);
        }

        filterRestrictions.push({
            ...defaultRestriction,
            Field: find(filterObject.usersSortByList, function(sort) {
                return sort.isTag;
            }).id,
            Values: tempConfirmed,
        });
    }

    if (filterObject.invoiceTypesFilter?.length) {
        const tempConfirmed = [];
        for (const invoiceTypeFilter of filterObject.invoiceTypesFilter) {
            tempConfirmed.push(invoiceTypeFilter.Value);
        }

        filterRestrictions.push({
            ...defaultRestriction,
            Field: 'InvoiceTypeId',
            Values: tempConfirmed,
        });
    }

    // more filter
    if (filterObject.moreFilter && filterObject.moreFilter.length) {
        filterObject.moreFilter.forEach(function(value: any) {
            if (value.TranslateKey === 'component.invoiceFilter.relatedCompanies') {
                filterRestrictions.push({
                    ...defaultRestriction,
                    Field: 'Invoice.IncludeRelatedCompanies',
                    Value: 1,
                });
            }
            if (value.TranslateKey === 'component.invoiceFilter.OnlyCreditInvoice') {
                filterRestrictions.push({
                    ...defaultRestriction,
                    Field: 'IsCredit',
                    Value: true,
                });
            }
            if (value.TranslateKey === 'component.invoiceFilter.OnlyDebitInvoice') {
                filterRestrictions.push({
                    ...defaultRestriction,
                    Field: 'IsDebit',
                    Value: true,
                });
            }
            if (value.TranslateKey === 'component.invoiceFilter.InvoiceCustomFields') {
                filterRestrictions.push({
                    ...defaultRestriction,
                    Field: 'SearchInCustomFields',
                    Value: true,
                });
            }
        });
    }
    // dimensions filter
    if (
        (filterObject.accountsFilter && filterObject.accountsFilter.length) ||
        (filterObject.dimensionFilter && filterObject.dimensionFilter.length) ||
        (filterObject.objectsFilter && filterObject.objectsFilter.length)
    ) {
        const dimensions: number[] = [];
        const accounts: number[] = [];
        const objects: number[] = [];
        filterObject.accountsFilter.forEach(function(account) {
            accounts.push(account.Value);
        });
        filterObject.dimensionFilter.forEach(function(dimension) {
            dimensions.push(dimension.Value);
        });
        filterObject.objectsFilter.forEach(function(object) {
            objects.push(object.Value);
        });
        if (accounts.length) {
            filterRestrictions.push({
                ...defaultRestriction,
                Field: 'Accounts',
                Values: accounts,
            });
        }
        if (dimensions.length) {
            filterRestrictions.push({
                ...defaultRestriction,
                Field: 'Dimensions',
                Values: dimensions,
            });
        }
        if (objects.length) {
            filterRestrictions.push({
                ...defaultRestriction,
                Field: 'Objects',
                Values: objects,
            });
        }
    }

    // search value
    if (filterObject.searchValue) {
        filterRestrictions.push({
            ...defaultRestriction,
            Field: 'GeneralSearch',
            Value: filterObject.searchValue,
        });
    }

    if (filterObject.customRestrictions.length) {
        filterObject.customRestrictions.forEach(function(restriction: Restriction) {
            filterRestrictions.push(restriction);
        });
    }

    return filterRestrictions;
}

/*
    Calculate date ranges based on the current timeType
*/
function calculateTimes(filterObject: InvoiceFilterObjectDTO): CalculatedTimesDTO {
    const calculatedTimes: CalculatedTimesDTO = {
        from: new Date().toISOString() || '',
        to: new Date().toISOString() || '',
        label: '',
    };

    switch (filterObject.timeType) {
        case InvoiceFilterTimeType.TODAY:
            calculatedTimes.from = new Date().toISOString();
            calculatedTimes.to = new Date().toISOString();
            calculatedTimes.label = 'component.invoiceFilter.today';
            break;
        case InvoiceFilterTimeType.YESTERDAY:
            calculatedTimes.from = subtractDaysFromToday(1).toISOString();
            calculatedTimes.to = subtractDaysFromToday(1).toISOString();
            calculatedTimes.label = 'component.invoiceFilter.yesterday';
            break;
        case InvoiceFilterTimeType.THIS_WEEK:
            calculatedTimes.from = getFirstDateOfWeek(new Date()).toISOString();
            calculatedTimes.to = getLastDateOfWeek(new Date()).toISOString();
            calculatedTimes.label = 'component.invoiceFilter.thisWeek';
            break;
        case InvoiceFilterTimeType.LAST_WEEK:
            calculatedTimes.from = getFirstDateOfWeek(subtractDaysFromToday(7)).toISOString();
            calculatedTimes.to = getLastDateOfWeek(subtractDaysFromToday(7)).toISOString();
            calculatedTimes.label = 'component.invoiceFilter.lastWeek';
            break;
        case InvoiceFilterTimeType.CURRENT_MONTH:
            calculatedTimes.from = getFirstDateOfMonth(new Date()).toISOString();
            calculatedTimes.to = getLastDateOfMonth(new Date()).toISOString();
            calculatedTimes.label = 'component.invoiceFilter.currentMonth';
            break;
        case InvoiceFilterTimeType.LAST_MONTH:
            calculatedTimes.from = getFirstDateOfMonth(subtractMonthsFromToday(1)).toISOString();
            calculatedTimes.to = getLastDateOfMonth(subtractMonthsFromToday(1)).toISOString();
            calculatedTimes.label = 'component.invoiceFilter.lastMonth';
            break;
        case InvoiceFilterTimeType.THIS_YEAR:
            calculatedTimes.from = getFirstDateOfYear(new Date()).toISOString();
            calculatedTimes.to = getLastDateOfYear(new Date()).toISOString();
            calculatedTimes.label = 'component.invoiceFilter.thisYear';
            break;
        case InvoiceFilterTimeType.CUSTOM_DATES:
            calculatedTimes.from = new Date(filterObject.fromModel).toISOString();
            calculatedTimes.to = new Date(filterObject.toModel).toISOString();
            calculatedTimes.label = 'component.invoiceFilter.customDates';
            break;
        case InvoiceFilterTimeType.SHIFTED_CUSTOM_MONTH:
            calculatedTimes.from = new Date(filterObject.fromModel).toISOString();
            calculatedTimes.to = new Date(filterObject.toModel).toISOString();
            calculatedTimes.label = 'component.invoiceFilter.customDates';
            break;
        case InvoiceFilterTimeType.SHIFTED_CUSTOM_YEAR:
            calculatedTimes.from = new Date(filterObject.fromModel).toISOString();
            calculatedTimes.to = new Date(filterObject.toModel).toISOString();
            calculatedTimes.label = 'component.invoiceFilter.customDates';
            break;
        case InvoiceFilterTimeType.LAST_X_DAYS:
            calculatedTimes.from = subtractDaysFromToday(Number(filterObject.lastXDaysModel)).toISOString();
            calculatedTimes.to = new Date().toISOString();
            calculatedTimes.label = Number(filterObject.lastXDaysModel) === 1 ? 'component.invoiceFilter.lastXDay' : 'component.invoiceFilter.lastXDays';
            break;
        case InvoiceFilterTimeType.LAST_SIX_MONTHS:
            calculatedTimes.from = subtractDaysFromToday(180).toISOString();
            calculatedTimes.to = new Date().toISOString();
            calculatedTimes.label = Number(filterObject.lastXDaysModel) === 1 ? 'component.invoiceFilter.lastXDay' : 'component.invoiceFilter.lastXDays';
            break;
        case InvoiceFilterTimeType.FOREVER:
        default:
            calculatedTimes.from = 'fromBeginningOfTime';
            calculatedTimes.to = 'untilEndOfTime';
            calculatedTimes.label = 'component.invoiceFilter.customDates';
            break;
    }
    return calculatedTimes;
}

export function encodeUriFilterObject(filterObject: InvoiceFilterObjectDTO): string {
    const lsUriFilterObj = JSON.stringify(filterObject);
    const base64LsUriFilterObj = b64EncodeUnicode(lsUriFilterObj);
    return base64LsUriFilterObj;
}

export function decodeUriFilter(encodedUriFilterObject: string): InvoiceFilterObjectDTO {
    const decodedUriFilterObj = b64DecodeUnicode(encodedUriFilterObject);
    const parsedUriFilterObj = JSON.parse(decodedUriFilterObj);
    return parsedUriFilterObj;
}

/**
 * from https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#Solution_4_%E2%80%93_escaping_the_string_before_encoding_it
 */
function b64EncodeUnicode(str: string) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
            return String.fromCharCode(Number('0x' + p1));
        }),
    );
}

function b64DecodeUnicode(str: string) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
        atob(str)
            .split('')
            .map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );
}
