(function() {
    'use strict';

    angular
        .module('dstreamApp.directives')
        .directive('doOnTypeaheadBlur', doOnTypeaheadBlur);

    doOnTypeaheadBlur.$inject = ['$window'];

    function doOnTypeaheadBlur($window) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: linkFunc
        };

        function linkFunc(scope, element, attrs, ctrl) {
            angular.element($window).on('click', onWindowClick);
            angular.element(element).on('keydown', onEscKey);

            /*
                Cancel on esc key press
             */
            function onEscKey(event) {
                if(event.keyCode === 27) {
                    scope.$eval(attrs.doOnTypeaheadBlur);
                    event.stopPropagation();
                }
            }

            /*
                On click anywhere else than the input and typeahead dropdown
             */
            function onWindowClick(event) {
                if(event.target !== element[0]) {
                    scope.$eval(attrs.doOnTypeaheadBlur);
                }
            }

            scope.$on('$destroy', function() {
                angular.element($window).off('click', onWindowClick);
                angular.element(element).off('keydown', onEscKey);
            });
        }
    }
}());
