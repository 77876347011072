import { useEffect, useState } from 'react';
import { debounce, noop } from 'lodash-es';

interface Props {
    contentNode: React.RefObject<any> | null;
    itemsPerPage: number;
    bottomOffsetTreshold?: number;
    topOffsetTreshold?: number;
    cbFunction?: (page: number, start: number, end: number) => void;
    reset?: boolean;
    dontScrollUp?: boolean;
    totalPages?: number;
}

const useInfiniteScroll = ({ contentNode, dontScrollUp, itemsPerPage, bottomOffsetTreshold = 30, topOffsetTreshold = 20, cbFunction, reset, totalPages }: Props) => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const handleNextPage = debounce(async (currPage: number) => {
        // we need to get next chunk of items
        const newPageNum = currPage + 1;
        const currentStartIndex = (newPageNum - 1) * itemsPerPage;
        const currentEndIndex = currentStartIndex + itemsPerPage * 2;
        setCurrentPage(newPageNum);
        cbFunction && (await cbFunction(newPageNum, currentStartIndex, currentEndIndex));
    }, 500);

    const handleScroll = debounce(async (e: Event) => {
        let currPage: number;
        setCurrentPage((page) => {
            // a trick to get current state, not initial (as is usually seen from an event handler)
            currPage = page;
            return page;
        });

        if (totalPages && totalPages <= currPage) {
            totalPages !== currPage && setCurrentPage(totalPages);
            return;
        }

        const targetElem = e.target as HTMLElement;
        const scrollHeight = targetElem.scrollHeight;

        if (targetElem.scrollTop + targetElem.clientHeight > scrollHeight - bottomOffsetTreshold) {
            handleNextPage(currPage);
        }
        if (!dontScrollUp && targetElem.scrollTop === 0 && currPage !== 0) {
            // we need to get previous chunk of items
            const newPageNum = currPage - 1 || 1;
            const currentStartIndex = newPageNum * itemsPerPage;
            const currentEndIndex = currentStartIndex + itemsPerPage * 2;
            setCurrentPage(newPageNum);
            cbFunction && (await cbFunction(newPageNum, currentStartIndex, currentEndIndex));
            targetElem.scrollTop = topOffsetTreshold;
        }
    }, 500);

    useEffect(() => {
        reset && setCurrentPage(1);
        if (reset && contentNode?.current) {
            contentNode.current.scrollTop = 0;
        }
    }, [reset]);

    useEffect(() => {
        if (!contentNode || !contentNode.current) {
            return noop;
        }
        contentNode.current.addEventListener('scroll', handleScroll);
        return () => contentNode?.current?.removeEventListener('scroll', handleScroll);
    }, [contentNode]);

    return currentPage;
};

export default useInfiniteScroll;
