import React, { Suspense, useEffect, useState } from 'react';
import classNames from 'classnames';

import { User } from '../../../../../services/ApiClient';
import { TaskDTO } from '../../../../../services/types/ApiTypes';

import { getConfirmerName } from '../InvoiceConfirmationsHelper';

export interface ConfirmerNameProps {
    taskItem: TaskDTO;
    userData: User;
    className?: string;
}

/**
 * Render the InvoiceConfirmations individual tasks approver/confirmer name
 * IF current user is the assignee then render the assignees GroupMembers full name (ex. "Assignee Name")
 * IF current user is not the assignee but is filling in for the assignee then render the substitutes name with assignee name in paren.'s (ex. "Substitute Name (Assignee Name)")
 * IF current task is completed by the assignee then render the assignees GroupMembers full name (ex. "Assignee Name")
 * IF current task is completed by the substitue of the assignee then render the substitute name (ex. "Substitute Name")
 */
export const ConfirmerName = ({ taskItem, userData, className }: ConfirmerNameProps) => {
    const classes = classNames('confirmer-name', className);
    const [name, setName] = useState('');

    useEffect(() => {
        setName(getConfirmerName(taskItem, userData));
    }, [taskItem]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <span className={classes}>{name}</span>
        </Suspense>
    );
};
