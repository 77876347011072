import { LOCATION_CHANGE } from 'connected-react-router';
import produce from 'immer';
import { cloneDeep } from 'lodash-es';
import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { CustomCostObjectiveFullDTO, BaseSearch, DimensionDTO, PagedListContainer, SearchType, SortDirection } from '../../../services/types/ApiTypes';

import { addNewRow, loadCostObjectiveItemsLoadableActions, loadCostObjectiveLoadableActions, saveCostObjectiveLoadableActions, removeRow, updateRow } from './CostObjectiveDetailsViewActions';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

class State {
    costObjectiveLoadable = new LoadableData<CustomCostObjectiveFullDTO>();
    costObjectiveItemsLoadable = new LoadableData<PagedListContainer<DimensionDTO>, BaseSearch>();
    costObjectiveId: number;

    costObjectiveItemsSearchParams: BaseSearch = {
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: '',
                Values: undefined,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        SortItems: [
            {
                SortColumn: 'Code',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 15,
        },
    };
    saveCostObjectiveLoadable = new LoadableData<boolean>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        loadCostObjectiveLoadableActions.request,
        (state, action): State => {
            return {
                ...state,
                costObjectiveId: action.payload,
                costObjectiveLoadable: LoadableData.loading(),
            };
        },
    )
    .addReducer(
        loadCostObjectiveLoadableActions.success,
        (state, action): State => {
            if (action.payload && action.payload) {
                // do not store the items in store, we retrieve them with separate request // TODO: use optimized API in the future
                action.payload.Dimensions = undefined;
            }
            return {
                ...state,
                costObjectiveId: action.payload?.Id,
                costObjectiveLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        loadCostObjectiveLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                costObjectiveLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        loadCostObjectiveItemsLoadableActions.request,
        (state, action): State => {
            return {
                ...state,
                costObjectiveItemsSearchParams: action.payload,
                costObjectiveItemsLoadable: state.costObjectiveItemsLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        loadCostObjectiveItemsLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                costObjectiveItemsSearchParams: action.payload.request,
                costObjectiveItemsLoadable: state.costObjectiveItemsLoadable.withPayloadIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        loadCostObjectiveItemsLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                costObjectiveItemsLoadable: state.costObjectiveItemsLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        addNewRow,
        produce((draft) => {
            draft.costObjectiveItemsLoadable.payload.Items.push({
                Id: 0, // NEW_ROW_ID,
                EndDate: undefined,
                StartDate: undefined,
                Description: undefined,
                Code: undefined,
                CustomCostObjectiveId: draft.costObjectiveLoadable.payload.Id,
                IsNew: false,
                InvoiceCountInUse: undefined,
                AssignedCurrentToUser: undefined,
                AutoTransactionCountInUse: undefined,
            });
            draft.costObjectiveItemsLoadable.payload.TotalCount++;
        }),
    )
    .addReducer(
        updateRow,
        produce((draft, action: Action<{ result: DimensionDTO; itemToUpdate: number }>) => {
            const index = draft.costObjectiveItemsLoadable.payload.Items.findIndex((i: DimensionDTO) => {
                if (i.Id > 0) {
                    return i.Id === action.payload.itemToUpdate;
                }
                return i.Id === 0;
            });
            const previousValue = draft.costObjectiveItemsLoadable.payload.Items[index];
            // do not update the InvoiceCountInUse, AutoTransactionCountInUse
            draft.costObjectiveItemsLoadable.payload.Items[index] = {
                ...action.payload.result,
                InvoiceCountInUse: (previousValue && previousValue.InvoiceCountInUse) || 0,
                AutoTransactionCountInUse: (previousValue && previousValue.AutoTransactionCountInUse) || 0,
            };
        }),
    )
    .addReducer(
        removeRow,
        produce((draft, action) => {
            draft.costObjectiveItemsLoadable.payload.Items = draft.costObjectiveItemsLoadable.payload.Items.filter((i: DimensionDTO) => i.Id !== action.payload);
        }),
    )
    .addReducer(
        saveCostObjectiveLoadableActions.request,
        produce((draft) => {
            draft.saveCostObjectiveLoadable = LoadableData.loading(undefined);
        }),
    )
    .addReducer(
        saveCostObjectiveLoadableActions.success,
        produce((draft, action) => {
            draft.saveCostObjectiveLoadable = LoadableData.payload(action.payload);
        }),
    )
    .addReducer(
        saveCostObjectiveLoadableActions.error,
        produce((draft, action) => {
            draft.saveCostObjectiveLoadable = LoadableData.error(action.payload);
        }),
    )
    .addReducer(
        LOCATION_CHANGE,
        (state): State => {
            const searchRestriction = cloneDeep(state.costObjectiveItemsSearchParams.Restrictions[0]);
            searchRestriction.Value = '';
            return {
                ...new State(),
                costObjectiveItemsSearchParams: {
                    ...state.costObjectiveItemsSearchParams,
                    Restrictions: [searchRestriction],
                },
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'accounting/costObjective',
    whitelist: ['costObjectiveItemsSearchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as CostObjectiveDetailsViewState };

export const selectCostObjectiveLoadable = (state: GlobalState) => state.costObjectiveDetails.costObjectiveLoadable;
export const selectCostObjectiveItemsLoadable = (state: GlobalState) => state.costObjectiveDetails.costObjectiveItemsLoadable;
export const selectCostObjectiveItemsSearchParams = (state: GlobalState) => state.costObjectiveDetails.costObjectiveItemsSearchParams;
export const selectSaveCostObjectiveLoadable = (state: GlobalState) => state.costObjectiveDetails.saveCostObjectiveLoadable;
