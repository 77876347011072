import React from 'react';
import Icon, { ICONS } from 'src/components/Icon/Icon';

export interface CommentInfoProps {
    content: string;
}

const CommentInfo = (props: CommentInfoProps) => {
    const { content } = props;

    return (
        <div className="comment-info">
            <p className="comment-info__icon-wrapper">
                <Icon name={ICONS.INFO_ROUND} />
            </p>
            <span className="comment-info__content">{content}</span>
        </div>
    );
};

export default CommentInfo;
