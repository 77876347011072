import { createAction } from 'redux-actions';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash-es';

import { loadableDataActions } from '../../../common/utils/LoadableData';
import { getCurrentCompany } from '../../../common/company/CompanyActions';
import { formatDate, formatFileNameSpaces } from '../../../common/utils/formatters';
import { GlobalState } from '../../../rootReducer';
import { BaseSearch, PagedListContainer, Restriction, SortDirection, UserSettingName, WorkflowTemplateDTO } from '../../../services/types/ApiTypes';
import api from '../../../services/ApiServices';
import { DispatchThunk } from '../../../storeConfig';
import { getViewUserSearchParams, ListViewUserConfig } from '../../../common/user/userSettingUtil';
import { DEFAULT_RESTRICTION, selectWorkflowsSearchParams } from './WorkflowsListViewReducers';
import { getCurrentUserGroupMember } from '../../../common/user/UserActions';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../common/utils/baseSearchHelpers';
import { updateAppUserSettingsAction } from '../../../common/middlewares/userSettings';

const ns = 'settings/vat-code-details/';

const listViewConfig: ListViewUserConfig = {
    pageSize: UserSettingName.SETTINGS_WORKFLOWS_PAGE_SIZE,
    sortCol: UserSettingName.SETTINGS_WORKFLOWS_SORT_COLUMN,
    sortDir: UserSettingName.SETTINGS_WORKFLOWS_SORT_DIRECTION,
};

export const workflowsLoadableDataActions = loadableDataActions<BaseSearch, PagedListContainer<WorkflowTemplateDTO>>(`${ns}GET_WORKFLOWS`);

export const getWorkflowTemplates = (searchParams?: BaseSearch) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const {
            workflows,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        searchParams = searchParams || workflows.searchParams;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Name', SortDirection.Asc);

        searchParams = { ...searchParams, ...viewSearchParams };

        let response;
        try {
            dispatch(workflowsLoadableDataActions.request(searchParams));
            response = await api.workflowTemplate.getCompanyTemplates(searchParams);
            dispatch(workflowsLoadableDataActions.success(response.data));
        } catch (e) {
            dispatch(workflowsLoadableDataActions.error(e));
        }
    };
};

export const setPagingOptions = (page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { workflows } = getState();
        const paging = workflows.searchParams.PagingOptions;

        const searchParams: BaseSearch = {
            ...workflows.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };

        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        return dispatch(getWorkflowTemplates(searchParams));
    };
};

export function searchWorkflows(searchString?: string | undefined) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const {
            workflows,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();

        let searchParams = workflows.searchParams;

        if (groupMember) {
            searchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        }

        let searchRestriction: Restriction;
        let searchRestrictionIndex: number;

        searchParams.Restrictions.forEach((r, index) => {
            if (r.Field === DEFAULT_RESTRICTION) {
                searchRestriction = { ...r, Value: searchString };
                searchRestrictionIndex = index;
            }
        });

        if (searchRestrictionIndex !== undefined && !!searchRestriction) {
            searchParams.Restrictions[searchRestrictionIndex] = searchRestriction;
        }

        searchParams.PagingOptions = { ...searchParams.PagingOptions, Page: 1 }; // reset to first page when searching

        return dispatch(getWorkflowTemplates(searchParams));
    };
}

// TODO @tonister: Clean up all these commented out functions!
// export const filterWorkflows = (searchString: string) => {
//     return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
//         const { workflows } = getState();
//         const paging = workflows.searchParams.PagingOptions;
//         const searchRestriction = workflows.searchParams.Restrictions.find((r) => r.Field === 'GeneralSearch');
//         const searchParams: BaseSearch = {
//             ...workflows.searchParams,
//             PagingOptions: {
//                 ...paging,
//                 Page: 1, // reset to first page when searching
//             },
//             Restrictions: [
//                 {
//                     ...searchRestriction,
//                     Value: searchString,
//                 },
//             ],
//         };
//         dispatch(getWorkflowTemplates(searchParams));
//     };
// };

export const sortWorkflows = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { workflows } = getState();
        const sorting = workflows.searchParams.SortItems[0];

        const searchParams: BaseSearch = {
            ...workflows.searchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };

        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        dispatch(getWorkflowTemplates(searchParams));
    };
};

// export const addNewRow = createAction(`${ns}ADD_NEW_ROW`);
//
// export const gotoLastPageAndAddNewRow = () => {
//     return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
//         const state = getState();
//         const payload = state.workflows.WorkflowsLoadable.payload;
//         const lastPage = Math.ceil(payload.TotalCount / payload.Take) || 1;
//         const currentPage = payload.Skip / payload.Take + 1;
//         if (lastPage !== currentPage) {
//             await dispatch(setPagingOptions(lastPage));
//         }
//         dispatch(addNewRow());
//     };
// };

export const removeTemplate = createAction<WorkflowTemplateDTO>(`${ns}REMOVE_TEMPLATE`);

export const deleteWorkflow = (workflowTemplate: WorkflowTemplateDTO) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        try {
            await api.workflowTemplate.deleteTemplates(workflowTemplate);
            if (state.workflows.workflowsLoadable.payload && state.workflows.workflowsLoadable.payload.Items.length === 1) {
                // we deleted the last item, so go to previous page
                const currentPage = state.autoTransactions.searchParams.PagingOptions.Page;
                if (currentPage > 1) {
                    dispatch(setPagingOptions(currentPage - 1));
                    return;
                }
            }
            dispatch(getWorkflowTemplates());
        } catch (e) {
            console.error(e);
        }
    };
};

export const toggleWorkflowState = (workflowTemplate: WorkflowTemplateDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            await api.workflowTemplate.updateWorkflowTemplate(workflowTemplate);
            dispatch(getWorkflowTemplates());
        } catch (e) {
            console.error(e);
        }
    };
};

export const exportWorkflowsToCsvActions = loadableDataActions(`${ns}EXPORT_WORKFLOWS_TO_CSV`);
export const exportWorkflowsToXlsActions = loadableDataActions(`${ns}EXPORT_WORKFLOWS_TO_XLS`);

export function exportWorkflowsToCsv() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        if (isEmpty(getState().company.currentCompanyData) || getState().user?.currentCompany?.CompanyGuid !== getState().company.currentCompanyData?.CompanyGuid) {
            await dispatch(getCurrentCompany());
        }
        const {
            workflows,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
            company: { currentCompanyData },
        } = getState();

        dispatch(exportWorkflowsToCsvActions.request(undefined));

        let searchParams = selectWorkflowsSearchParams(getState());

        searchParams = searchParams || workflows.searchParams;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Email', SortDirection.Asc);

        searchParams = { ...searchParams, ...viewSearchParams };

        const apiSearchParams = searchParams.Restrictions;
        try {
            const response = await api.workflowTemplate.exportWorkflowsToCsv(apiSearchParams);
            const companyCode = currentCompanyData?.VatCode || currentCompanyData?.RegistrationCode;
            const fileDate = formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss');
            const fileName = formatFileNameSpaces(`${currentCompanyData?.CompanyName}_${companyCode}_ConfirmationFlows_${fileDate}.csv`);
            const file = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            saveAs(file, fileName);
            dispatch(exportWorkflowsToCsvActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportWorkflowsToCsvActions.error(e));
        }
    };
}

export function exportWorkflowsToXls() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        if (isEmpty(getState().company.currentCompanyData) || getState().user?.currentCompany?.CompanyGuid !== getState().company.currentCompanyData?.CompanyGuid) {
            await dispatch(getCurrentCompany());
        }
        const {
            workflows,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
            company: { currentCompanyData },
        } = getState();

        dispatch(exportWorkflowsToXlsActions.request(undefined));

        let searchParams = selectWorkflowsSearchParams(getState());

        searchParams = searchParams || workflows.searchParams;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Email', SortDirection.Asc);

        searchParams = { ...searchParams, ...viewSearchParams };

        const apiSearchParams = searchParams.Restrictions;
        try {
            const response = await api.workflowTemplate.exportWorkflowsToXls(apiSearchParams);
            const companyCode = currentCompanyData?.VatCode || currentCompanyData?.RegistrationCode;
            const fileDate = formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss');
            const fileName = formatFileNameSpaces(`${currentCompanyData?.CompanyName}_${companyCode}_ConfirmationFlows_${fileDate}.xlsx`);
            const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            saveAs(file, fileName);
            dispatch(exportWorkflowsToXlsActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportWorkflowsToXlsActions.error(e));
        }
    };
}
