import { cloneDeep } from 'lodash-es';
"use strict";

angular
  .module("dstreamApp.services")
  .service("autoTransactionsService", DsAutoTransactionsService);

DsAutoTransactionsService.$inject = [
  "$rootScope",
  "$filter",
  "$q",
  "webServices",
  "notifyService",
  "invoiceService",
  "activeInvoiceService",
  "companyDataService"
];

function DsAutoTransactionsService (
  $rootScope,
  $filter,
  $q,
  webServices,
  notifyService,
  invoiceService,
  activeInvoiceService,
  companyDataService
) {
  var service = this;

  var currentlyOpenAutoTransaction = {};

  var validateTransctRows = false;

  var mustValidateTransactionRowsEvent = $rootScope.$on("mustValidateTransactionRows", function () {
    validateTransctRows = true;
  });

  // TODO @anton: Would be nice If this information was actually stored in the database and could be fetched through the webServices API
  var MatchingRules = function () {
    return {
      // 0: {
      //   value: 0,
      //   label: 'component.AutoTransaction.Condition.Any'
      // },
      1: {
        value: 1,
        label: "component.AutoTransaction.Condition.Contains"
      },
      2: {
        value: 2,
        label: "component.AutoTransaction.Condition.IsNotEmpty"
      },
      3: {
        value: 3,
        label: "component.AutoTransaction.Condition.IsEmpty"
      },
      4: {
        value: 4,
        label: "component.AutoTransaction.Condition.IsEqualTo"
      }
    };
  };
  // TODO @anton: Would be nice If this information was actually stored in the database and could be fetched through the webServices API
  var TriggerConditions = function () {
    return {
      1: {
        value: 1,
        label: "component.AutoTransaction.Company",
        FailedValidation: false,
        selectedOption: {},
        options: {
          4: {
            value: 4,
            label: "component.AutoTransaction.Condition.IsEqualTo"
          }
        }
      },
      2: {
        value: 2,
        label: "component.AutoTransaction.ReferenceNumber",
        FailedValidation: false,
        selectedOption: {},
        options: {
          2: {
            value: 2,
            label: "component.AutoTransaction.Condition.IsNotEmpty"
          },
          3: {
            value: 3,
            label: "component.AutoTransaction.Condition.IsEmpty"
          },
          4: {
            value: 4,
            label: "component.AutoTransaction.Condition.IsEqualTo"
          }
        }
      }
    };
  };
  // TODO @anton: Would be nice If this information was actually stored in the database and could be fetched through the webServices API
  var TransactionRowsMainSteps = function () {
    return {
      30: {
        value: 30,
        label: "component.AutoTransaction.MainStep.ApplyAll",
        FailedValidation: false,
        selectedParam: {},
        params: {}
      },
      20: {
        value: 20,
        label: "component.AutoTransaction.MainStep.CombineAll",
        FailedValidation: false,
        selectedParam: {},
        params: {}
      },
      10: {
        value: 10,
        label: "component.AutoTransaction.MainStep.CombineSearch",
        FailedValidation: false,
        selectedParam: {},
        params: {
          description: {
            value: "description",
            label: "component.AutoTransaction.Parameter.Description",
            options: {
              1: {
                value: 1,
                label: "component.AutoTransaction.Condition.Contains"
              },
              4: {
                value: 4,
                label: "component.AutoTransaction.Condition.IsEqualTo"
              }
            }
          }
        }
      }
    };
  };
  // TODO @anton: Would be nice If this information was actually stored in the database and could be fetched through the webServices API
  var WorkflowAssignmentConditions = function () {
    return {
      assignTo: {
        value: "assignTo",
        label: "component.AutoTransaction.Assignment.AssignTo",
        FailedValidation: false,
        selectedOption: {},
        options: {
          // 1: {
          //   value: 1,
          //   label: 'component.AutoTransaction.Assignment.AssignTo.Person'
          // },
          2: {
            value: 2,
            label: "component.AutoTransaction.Assignment.AssignTo.Workflow"
          }
        }
      }
    };
  };

  var CustomCostObjective = function () {
    return {
      AutoTransactionRowId: 0,
      CostObjectiveType: 3,
      CustomCostObjectiveId: 0,
      CostObjectiveId: null,
      Id: 0,
      IsNew: true
    };
  };

  var AllocationTemplate = function () {
    return {
      AllocationDescription: "",
      AllocationPercent: 100.00,
      Dimensions: [
        {
          AutoTransactionRowId: 0,
          CostObjectiveType: 1,
          CustomCostObjectiveId: 0,
          CostObjectiveId: null,
          Id: 0,
          IsNew: true
        },
        {
          AutoTransactionRowId: 0,
          CostObjectiveType: 2,
          CustomCostObjectiveId: 0,
          CostObjectiveId: null,
          Id: 0,
          IsNew: true
        }
      ]
    };
  };

  var VatRateTemplate = function () {
    var newAllocations = [];
    newAllocations.push(cloneDeep(new AllocationTemplate()));
    return {
      VatRate: "ANY",
      Allocations: cloneDeep(newAllocations)
    };
  };

  var VatRatesTemplate = function () {
    var newVatRates = [];
    // newVatRates.push(cloneDeep(new VatRateTemplate()))
    return cloneDeep(newVatRates);
  };

  var TransactionRowTemplate = function () {
    var newVatRates = new VatRatesTemplate();
    return {
      AutoTransactionId: null,
      CombinationOption: null,
      CombineField: null,
      CombineMatchType: null,
      Comment: null,
      Id: 0,
      IsNew: true,
      OrderNo: null,
      RowSearchString: "",
      VatRates: cloneDeep(newVatRates)
    };
  };

  // var TransactionRowsTemplate = function () {
  //   return [cloneDeep(TransactionRowTemplate)];
  // };

  service.calls = {
    autoTransaction: autoTransaction,
    // validateTriggers: validateTriggers,
    validateAutoTransaction: validateAutoTransaction,
    saveAutoTransaction: saveAutoTransaction,
    saveAutoTransactionSnapshot: saveAutoTransactionSnapshot,
    getMatchingRules: getMatchingRules,
    // getTriggerConditions: getTriggerConditions,
    // getTransactionRowsMainSteps: getTransactionRowsMainSteps,
    // getWorkflowAssignmentConditions: getWorkflowAssignmentConditions,
    GetDimensionTemplate: getDimensionTemplate,
    GetAllocationTemplate: getAllocationTemplate,
    GetVatRateTemplate: getVatRateTemplate,
    // GetVatRatesTemplate: getVatRatesTemplate,
    GetTransactionRowTemplate: getTransactionRowTemplate,
    // GetTransactionRowsTemplate: getTransactionRowsTemplate
  };

  return service.calls;

  function autoTransaction (rule) {
    if (!rule) {
      return currentlyOpenAutoTransaction;
    } else {
      currentlyOpenAutoTransaction = cloneDeep(rule);
    }
  }

  function getMatchingRules () {
    var newMatchingRules = new MatchingRules();
    return cloneDeep(newMatchingRules);
  }

  // function getTriggerConditions () {
  //   var newTriggerConditions = new TriggerConditions();
  //   return cloneDeep(newTriggerConditions);
  // }
  //
  // function getTransactionRowsMainSteps () {
  //   var newTransactionRowsMainSteps = new TransactionRowsMainSteps();
  //   return cloneDeep(newTransactionRowsMainSteps);
  // }
  //
  // function getWorkflowAssignmentConditions () {
  //   var newWorkflowAssignmentConditions = new WorkflowAssignmentConditions();
  //   return cloneDeep(newWorkflowAssignmentConditions);
  // }

  function getDimensionTemplate () {
    var newDimension = new CustomCostObjective();
    return cloneDeep(newDimension);
  }

  function getAllocationTemplate () {
    var newAllocationTemplate = new AllocationTemplate();
    return cloneDeep(newAllocationTemplate);
  }

  function getVatRateTemplate () {
    var newVatRate = new VatRateTemplate();
    return cloneDeep(newVatRate);
  }

  // function getVatRatesTemplate () {
  //   var newVatRates = new VatRatesTemplate();
  //   return cloneDeep(newVatRates);
  // }

  function getTransactionRowTemplate () {
    var newTransactionRow = new TransactionRowTemplate();
    if (!_.isEmpty(currentlyOpenAutoTransaction)) {
      newTransactionRow.AutoTransactionId = currentlyOpenAutoTransaction.Id;
    }
    return cloneDeep(newTransactionRow);
  }

  // function getTransactionRowsTemplate () {
  //   var newTransactionRows = new TransactionRowsTemplate();
  //   return cloneDeep(newTransactionRows);
  // }

  function hasOnlyNumbers (value) {
    return /^([0-9]*)$/.test(value);
  }

  function validateAutoTransaction (autoTransaction) {
    var validationStatus = {
      errors: [],
      status: 1
    };

    var headerValidation = validateHeader(autoTransaction);
    if (headerValidation) {
      validationStatus.errors.push(headerValidation);
    }

    var triggersValidation = validateTriggers(autoTransaction.Triggers);
    if (triggersValidation) {
      validationStatus.errors.push(triggersValidation);
    }
    var transactionRowsValidation = validateTransactionRows(autoTransaction.AutoTransactionsRows);
    if (transactionRowsValidation) {
      validationStatus.errors.push(transactionRowsValidation);
    }
    var workflowValidation = validateWorkflows(autoTransaction.WorkflowAssignments);
    if (workflowValidation) {
      validationStatus.errors.push(workflowValidation);
    }
    if (validationStatus.errors.length) {
      validationStatus.errors.forEach(function (value) {
        validationStatus.status = validationStatus && value.status;
      });
    }
    return validationStatus;
  }

  function validateHeader (rule) {
    var validationResult = {
      errors: [],
      status: 1
    };
    if ((!rule.RuleName || !rule.RuleName.length)) {
      rule.FailedHeaderValidation = true;
      notifyService.info("component.AutoTransaction.Error.Empty.New.RuleName",
        "component.AutoTransaction.Error.Title.ValueTooShort",
        false);
      validationResult.status = 0;
      validationResult.errors.push({
        ruleName: "autoTransaction.RuleName",
        error: "value.empty"
      });
    }
    return (validationResult.errors.length ? validationResult : null);
  }

  function validateTriggers (triggers) {
    var validationResult = {
      status: 1,
      errors: []
    };
    triggers.forEach(function (trigger) {
      switch (trigger.TriggerType) {
        case 1:
          if (trigger.selectedOption.value === 4 && (
            !trigger.selectedOption.assignedValue ||
            trigger.selectedOption.assignedValue === "" ||
            !trigger.selectedOption.model ||
            trigger.selectedOption.model === ""
          )) {
            notifyService.info($filter("translate")("component.AutoTransaction.Error.Empty.Equal.Trigger", {
                triggerType: $filter("translate")(trigger.label),
                conditionType: $filter("translate")(trigger.selectedOption.label)
              }),
              "component.AutoTransaction.Error.Title.EmptyValue",
              false);
            trigger.FailedValidation = true;
            validationResult.status = 0;
            validationResult.errors.push({
              triggerType: trigger.TriggerType,
              error: "value.empty"
            });
          } else {
            trigger.FailedValidation = false;
          }
          break;
        case 2:
          if (!trigger.selectedOption.value) {
            notifyService.info($filter("translate")("component.AutoTransaction.Error.Empty.Option.Trigger", {
                triggerType: $filter("translate")(trigger.label)
              }),
              "component.AutoTransaction.Error.Title.EmptyValue",
              false);
            trigger.FailedValidation = true;
            validationResult.status = 0;
            validationResult.errors.push({
              triggerType: trigger.TriggerType,
              error: "option.empty"
            });
          } else {
            switch (trigger.selectedOption.value) {
              case 1:
              case 4:
                if ((!trigger.selectedOption.assignedValue ||
                  trigger.selectedOption.assignedValue === "" ||
                  !trigger.selectedOption.model ||
                  trigger.selectedOption.model === "")) {
                  notifyService.info($filter("translate")("component.AutoTransaction.Error.Empty.Equal.Trigger", {
                      triggerType: $filter("translate")(trigger.label),
                      conditionType: $filter("translate")(trigger.selectedOption.label)
                    }), "component.AutoTransaction.Error.Title.EmptyValue",
                    false);
                  trigger.FailedValidation = true;
                  validationResult.status = 0;
                  validationResult.errors.push({
                    triggerType: trigger.TriggerType,
                    error: "value.empty"
                  });
                } else if (!hasOnlyNumbers(trigger.selectedOption.assignedValue)) {
                  notifyService.info($filter("translate")("component.AutoTransaction.Error.NotNumber.Trigger", {
                      triggerType: $filter("translate")(trigger.label)
                    }), "component.AutoTransaction.Error.Title.NotNumber",
                    false);
                  trigger.FailedValidation = true;
                  validationResult.status = 0;
                  validationResult.errors.push({
                    triggerType: trigger.TriggerType,
                    error: "value.notNumbers"
                  });
                } else {
                  trigger.FailedValidation = false;
                }
                break;
              case 0:
              case 2:
              case 3:
              case 5:
                break;
              default:
                break;
            }
          }
          break;
        default:
          break;
      }
    });
    return (validationResult.errors.length ? validationResult : null);
  }

  function validateTransactionRows (transactionRows) {
    var validationResult = {
      errors: [],
      status: 1
    };
    angular.forEach(transactionRows, function (transactionRow) {
        if (transactionRow.CombinationOption === 10) {
          if (!transactionRow.CombineField) {
            transactionRow.FailedValidation = true;
            notifyService.info($filter("translate")("component.AutoTransaction.Error.Empty.CombineField", {
                combinationOption: $filter("translate")(transactionRow.label)
              }),
              "component.AutoTransaction.Error.Title.EmptyValue",
              false);
            validationResult.status = 0;
            validationResult.errors.push({
              transactionRowType: transactionRow.value,
              error: "combineField.empty"
            });
          } else {
            if (!transactionRow.CombineMatchType) {
              transactionRow.FailedValidation = true;
              notifyService.info($filter("translate")("component.AutoTransaction.Error.Empty.MatchType", {
                  combineField: $filter("translate")(transactionRow.selectedParam.label)
                }),
                "component.AutoTransaction.Error.Title.EmptyValue",
                false);
              validationResult.status = 0;
              validationResult.errors.push({
                transactionRowType: transactionRow.value,
                error: "combineMatchType.empty"
              });
            } else {
              if (!transactionRow.RowSearchString) {
                transactionRow.FailedValidation = true;
                notifyService.info($filter("translate")("component.AutoTransaction.Error.Empty.CombineValue", {
                    matchType: $filter("translate")(transactionRow.selectedParam.matchingCondition.label),
                    combinationOption: $filter("translate")(transactionRow.label)
                  }),
                  "component.AutoTransaction.Error.Title.EmptyValue",
                  false);
                validationResult.status = 0;
                validationResult.errors.push({
                  transactionRowType: transactionRow.value,
                  error: "combineValue.empty"
                });
              } else {
                transactionRow.FailedValidation = false;
              }
            }
          }
        }
        if (transactionRow && _.isEmpty(transactionRow.VatRates)) {
          transactionRow.FailedValidation = true;
          notifyService.info("component.AutoTransaction.Error.Empty.VatRates",
            "component.AutoTransaction.Error.Title.EmptyValue",
            false);
          validationResult.status = 0;
          validationResult.errors.push({
            transactionRowType: transactionRow.value,
            error: "vatRates.empty"
          });
        } else {
          angular.forEach(transactionRow.VatRates, function (vatRate) {
            if (_.isEmpty(vatRate.Allocations)) {
              transactionRow.FailedValidation = true;
              notifyService.info("component.AutoTransaction.Error.Empty.Allocations",
                "component.AutoTransaction.Error.Title.EmptyValue",
                false);
              validationResult.status = 0;
              validationResult.errors.push({
                transactionRowType: transactionRow.value,
                error: "allocations.empty"
              });
            } else {
              if (vatRate.unallocatedAmount) {
                notifyService.info("component.AutoTransaction.Error.Empty.Allocations",
                  "component.AutoTransaction.Confirmation.UnPostedAmount",
                  false);
                validationResult.status = 0;
                validationResult.errors.push({
                  transactionRowType: transactionRow.value,
                  error: "allocations.unposted"
                });
              } else {
                angular.forEach(vatRate.Allocations, function (allocation) {
                    if (allocation.valueInvalid) {
                      notifyService.info("component.AutoTransaction.Error.Incorrect.Allocations",
                        "component.AutoTransaction.Error.Title.IncorrectValue",
                        false);
                      validationResult.status = 0;
                      validationResult.errors.push({
                        transactionRowType: transactionRow.value,
                        error: "allocations.incorrectFormat"
                      });
                    }
                    angular.forEach(allocation.Dimensions, function (dimension) {
                      if (dimension.CostObjectiveType === 3 && (
                        !dimension.CostObjectiveId ||
                        !dimension.CustomCostObjectiveId
                      )) {
                        transactionRow.FailedValidation = true;
                        notifyService.info("component.AutoTransaction.Error.Empty.Dimension",
                          "component.AutoTransaction.Error.Title.EmptyValue",
                          false);
                        validationResult.status = 0;
                        validationResult.errors.push({
                          transactionRowType: transactionRow.value,
                          error: "dimensionValue.empty"
                        });
                      }
                    });
                  }
                );
              }
            }
          });
        }
      }
    )
    ;
    return (validationResult.errors.length ? validationResult : null);
  }

  function validateWorkflows (workflows) {
    var validationResult = {
      errors: [],
      status: 1
    };
    angular.forEach(workflows, function (workflow) {
      if (workflow.selectedOption && workflow.selectedOption.value && !workflow.selectedOption.assignee) {
        workflow.FailedValidation = true;
        notifyService.info($filter("translate")(
          "component.AutoTransaction.Error.Empty.Workflow",
          {
            workflowType: $filter("translate")(workflow.selectedOption.label)
          }
          ), "component.AutoTransaction.Error.Title.EmptyValue",
          false);
        validationResult.status = 0;
        validationResult.errors.push({
          workflowType: workflow.selectedOption.value,
          error: "value.empty"
        });
      } else {
        workflow.FailedValidation = false;
      }
    });
    return (validationResult.errors.length ? validationResult : null);
  }

  function mapTriggersValues (autoTransaction) {
    autoTransaction.Supplier = {};
    autoTransaction.SupplierId = null;
    autoTransaction.SupplierMatchTypeMatchType = null;
    autoTransaction.ReferenceNumber = null;
    autoTransaction.ReferenceNumberMatchType = null;
    autoTransaction.Triggers.forEach(function (trigger) {
      switch (trigger.TriggerType) {
        case 1:
          if (trigger.selectedOption.value !== 4) {
            autoTransaction.Supplier = autoTransaction.SupplierId = null;
            autoTransaction.SupplierMatchType = trigger.MatchType;
          } else {
            if (!_.isEmpty(trigger.selectedOption.assignedValue)) {
              autoTransaction.Supplier = trigger.selectedOption.assignedValue;
              autoTransaction.SupplierId = autoTransaction.Supplier.Id;
            }
          }
          break;
        case 2:
          if (trigger.selectedOption.value !== 4) {
            autoTransaction.ReferenceNumber = null;
          } else {
            if (trigger.selectedOption.model !== "") {
              autoTransaction.ReferenceNumber = parseInt(trigger.selectedOption.assignedValue);
            }
          }
          autoTransaction.ReferenceNumberMatchType = trigger.MatchType;
          break;
        default:
          break;
      }
    });
    return autoTransaction;
  }

  function saveAutoTransaction (autoTransaction) {
    var deferred = $q.defer();
    autoTransaction = mapTriggersValues(autoTransaction);
    var validationResult = validateAutoTransaction(autoTransaction);
    if (validationResult.status) {
      webServices.saveAutoTransaction(autoTransaction)
        .then(
          function (response) {
            if (response && response.data && response.data.Id) {
              deferred.resolve(response.data);
              // return response.data
            }
          }, function (err) {
            console.log("Error saving autoTransaction:", err);
            deferred.reject(err);
          }
        );
    } else {
      deferred.reject(validationResult);
      // angular.forEach(validationResult.errors, function (error) {
      //   if (!error.status) {
      //     error
      //     notifyService.info('There are errors in the ' + error + ', please correct them before continuing', 'Error', false)
      //   }
      // })
    }
    return deferred.promise;
  }

  function saveAutoTransactionSnapshot (invoiceId, transactionRows, totalSumRemaining, fromHzView) {
    // check if all transaction rows are valid
    var vatCodes = [];
    var emptyMandatoryFields = [];
    companyDataService.getVatCodes().then(
      function (response) {
        vatCodes = response;
        service.vatCodes = vatCodes;
      }
    ).then(function () {
        return activeInvoiceService.activeInvoice().then(
          function (response) {
            return response;
          }
        );
      }
    ).then(function (invoice) {
      return webServices.AreTransactionRowsValid(invoiceId).then(
        function (response) {
          if (response && response.data && response.data.length > 0 && response.data[0] !== -1) {
            emptyMandatoryFields = cloneDeep(response.data);
            $rootScope.$emit('mustValidateTransactionRows');
          }
          return emptyMandatoryFields;
        },
        function (error) {
          console.log("Request rejected due to:", error);
        }
      ).then(
        function () {
          if (fromHzView || (_.isEmpty(emptyMandatoryFields) && !totalSumRemaining)) {
            invoiceService.openAutoTransaction(invoiceId, true);
          } else {
            activeInvoiceService.activeInvoice().then(
              function (response) {
                if (response) {
                  invoiceService.showAutoTransactionConfirmationDialog(totalSumRemaining, response.Currency, emptyMandatoryFields);
                }
              }
            );
          }
        }
      );
    });
  }
}
