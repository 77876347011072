(function() {
    'use strict';

    angular
        .module('dstreamApp.views.archive-register')
        .config(ArchiveRegisterRoute);

    ArchiveRegisterRoute.$inject = ['customRouteProvider'];

    function ArchiveRegisterRoute(customRouteProvider) {
        customRouteProvider
            .when('/archive/', {
                template: '<ds-archive-register group-members="$resolve.groupMembers"></ds-archive-register>',
                access: {
                    requiresLogin: true
                }
            });
    }
})();