import storeAndPersistor from '../../storeConfig';
import { CompanyMinDTO, WorkflowDTO } from '../../services/types/ApiTypes';
import { selectCurrentCompanySettings } from '../../common/company/CompanySelectors';

const store = storeAndPersistor.store;

export enum HubType {
    Domas = 0,
    Krediidiinfo = 1,
    ExchangeService = 2,
    Emr = 3,
    IWS = 4,
    tsekkEE = 5,
    ImportV3Api = 6,
    EmrAT = 7,
}

export interface InvoiceSmallDTO {
    AccountingDate: Date;
    Currency: string;
    Company: CompanyMinDTO;
    HubType: number;
    InvoiceDate: Date;
    IsExportAfterApprovalEnabled: boolean;
    IsNew: boolean;
    SupplierId: number;
    Workflow: WorkflowDTO;
}

export const getDefaultCurrency = () => {
    const companySettings = selectCurrentCompanySettings(store.getState());
    return companySettings.find((c) => c.Name === 'DefaultCurrency')?.Value || 'EUR';
};

export const newInvoice = (): InvoiceSmallDTO => ({
    AccountingDate: null,
    Currency: getDefaultCurrency(),
    Company: {
        Id: null,
        Name: null,
        RegistrationCode: null,
    },
    HubType: null,
    InvoiceDate: null,
    IsExportAfterApprovalEnabled: true,
    IsNew: true,
    SupplierId: null,
    Workflow: null,
});

export enum ConfirmationFlowActions {
    Confirm = 'Approved',
    Assign = 'Sent',
    ConfirmAndAssign = 'ConfirmAndAssign',
    Reject = 'Declined',
    Skip = 'Skip',
    Reopen = 'Reopen',
}
