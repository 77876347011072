import withSuspense from '../../common/hocs/withSuspense';
import { GlobalState } from '../../rootReducer';
import { connect } from '../../storeConfig';

import PDFViewer, { DispatchProps } from './PDFViewer';
import { setPdfToOpenAction } from './FileViewActions';
import { selectPdfToOpen } from './FileViewReducer';

const mapStateToProps = (state: GlobalState) => {
    return {
        pdfToOpen: selectPdfToOpen(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    setPdfToOpen: setPdfToOpenAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(PDFViewer));
