import { LOCATION_CHANGE } from 'connected-react-router';
import { isNumber, isString } from 'lodash-es';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { createSelector } from 'reselect';

import { getCompanyCustomCostObjectives, getCompanyWorkflowTemplates, getCurrentCompany } from '../../common/user/UserSelectors';
import { LoadableData } from '../../common/utils/LoadableData';
import { TypeaheadItem } from '../../components/Typeahead/Typeahead';
import { GlobalState } from '../../rootReducer';
import { AutoTransactionUiObjectDTO, ExtensionField } from '../../services/types/ApiTypes';

import { AutoTransactionsAddViewFields } from './autoTransactionAddViewFields';
import { addOrUpdateATActions, retrieveATActions, retrieveVatRatesActions } from './AutoTransactionsAddViewActions';
import { createAutomationStepsFields, createWorkflowFields } from './AutoTransactionsAddViewHelper';

class State {
    saveATLoadable = new LoadableData<AutoTransactionUiObjectDTO, AutoTransactionUiObjectDTO>();
    retrieveATLoadable = new LoadableData<AutoTransactionUiObjectDTO, string>();
    autoTransaction: AutoTransactionUiObjectDTO = undefined; // used for updating
    vatRatesLoadable = new LoadableData<number[], undefined>();
}

export { State as AutoTransactionAddViewState };

export const selectRetrieveATLoadable = (state: GlobalState) => state.autoTransactionAdd.retrieveATLoadable;

export const selectInitialValues = createSelector(
    selectRetrieveATLoadable,
    getCurrentCompany,
    getCompanyCustomCostObjectives,
    getCompanyWorkflowTemplates,
    (retrieveATLoadable, company, customCostObjectives, workflowTemplates): AutoTransactionsAddViewFields => {
        if (!retrieveATLoadable.loading && retrieveATLoadable.payload && company && customCostObjectives && workflowTemplates) {
            const { payload: autoTransaction } = retrieveATLoadable;
            const initialValues: AutoTransactionsAddViewFields = {
                id: autoTransaction.Id || 0,
                isNew: autoTransaction.IsNew || true,
                ruleName: autoTransaction.RuleName || '',
                ruleDescription: autoTransaction.RuleDescription || '',
                referenceNumber: autoTransaction.ReferenceNumber || null,
                workflow: createWorkflowFields(autoTransaction.WorkflowAssignments || [], workflowTemplates),
                triggersView: {
                    stopProcessing: autoTransaction.StopProcessing || false,
                    isRuleActive: autoTransaction.IsActive || false,
                    triggers:
                        autoTransaction.Triggers &&
                        autoTransaction.Triggers.map((trigger) => {
                            let value: string | number | TypeaheadItem<number>;
                            if ([ExtensionField.Company, ExtensionField.BeneficiaryName].includes(trigger.ExtensionField)) {
                                value = {
                                    text: trigger.DisplayValue,
                                    value: isString(trigger.Value) ? Number(trigger.Value) : trigger.Value,
                                };
                            } else {
                                value = isString(trigger.Value) ? String(trigger.Value) : isNumber(trigger.Value) && trigger.Value;
                            }
                            return {
                                matchType: trigger.MatchType || null,
                                extensionField: trigger.ExtensionField,
                                extensionType: trigger.ExtensionType,
                                value,
                                displayValue: trigger.DisplayValue,
                            };
                        }),
                },
                autoTransactionsRows: createAutomationStepsFields(autoTransaction.AutoTransactionsRows, company.VatCodes),
                companyVatCodes: company.VatCodes,
                recreateTransactionRows: autoTransaction.RecreateTransactionRows,
                autoTransactionsCustomFields: autoTransaction.AutoTransactionsCustomFields || [],
            };
            return initialValues;
        }
        return {
            id: undefined,
            isNew: true,
            ruleName: '',
            ruleDescription: '',
            referenceNumber: null,
            workflow: null,
            triggersView: {
                stopProcessing: false,
                isRuleActive: false,
                triggers: [],
            },
            autoTransactionsRows: [],
            companyVatCodes: [],
            recreateTransactionRows: false,
            autoTransactionsCustomFields: [],
        };
    },
);

export default new ReducerFactory(new State())
    .addReducer(
        addOrUpdateATActions.request,
        (state, action): State => {
            return {
                ...state,
                saveATLoadable: state.saveATLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        addOrUpdateATActions.success,
        (state, action): State => {
            return {
                ...state,
                saveATLoadable: LoadableData.payload(action.payload),
                retrieveATLoadable: LoadableData.payload(action.payload),
                autoTransaction: action.payload,
            };
        },
    )
    .addReducer(
        addOrUpdateATActions.error,
        (state, action): State => {
            return {
                ...state,
                saveATLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        retrieveATActions.request,
        (state): State => {
            return {
                ...state,
                retrieveATLoadable: state.retrieveATLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        retrieveATActions.success,
        (state, action): State => {
            return {
                ...state,
                retrieveATLoadable: LoadableData.payload(action.payload),
                autoTransaction: action.payload,
            };
        },
    )
    .addReducer(
        retrieveATActions.error,
        (state, action): State => {
            return {
                ...state,
                retrieveATLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        retrieveVatRatesActions.success,
        (state, action): State => {
            return {
                ...state,
                vatRatesLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return new State();
        },
    )
    .toReducer();

export const selectCompanyVatRates = (state: GlobalState) => state.autoTransactionAdd.vatRatesLoadable.payload;
