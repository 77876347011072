import { ReducerFactory } from 'redux-actions-ts-reducer';

import { TableFilter } from '../../../../../components/Table/components/filter/TableFilters';
import { BaseSearchWithTableFilters, PagedListContainer, PurchaseOrderInvoicesSearch, PurchaseOrderHeaderInvoicesDTO, LinkedCountDTO } from '../../../../../services/types/ApiTypes';
import { LoadableData } from '../../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../../rootReducer';
import { getPurchaseOrderInvoicesLinkedCount, getPurchaseOrderInvoicesLoadable } from './InvoicesListActions';
import { createRequest } from './InvoicesListHelper';

export type InvoicesSearchParams = BaseSearchWithTableFilters<TableFilter<any>, PurchaseOrderHeaderInvoicesDTO>;

class State {
    linkedInvoicesCount: LoadableData<LinkedCountDTO>;
    searchParams: InvoicesSearchParams = {
        ...createRequest('', 1),
    };
    purchaseOrderInvoicesLoadable = new LoadableData<PagedListContainer<PurchaseOrderHeaderInvoicesDTO>, PurchaseOrderInvoicesSearch>();
    filterType?: number;
}

export { State as PurchaseOrderInvoicesState };

export default new ReducerFactory(new State())
    .addReducer(
        getPurchaseOrderInvoicesLinkedCount.request,
        (state): State => {
            return {
                ...state,
                linkedInvoicesCount: LoadableData.loading(),
            };
        },
    )
    .addReducer(
        getPurchaseOrderInvoicesLinkedCount.success,
        (state, action): State => {
            return {
                ...state,
                linkedInvoicesCount: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getPurchaseOrderInvoicesLinkedCount.error,
        (state, action): State => {
            return {
                ...state,
                linkedInvoicesCount: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        getPurchaseOrderInvoicesLoadable.request,
        (state, action): State => {
            const purchaseOrderInvoicesLoadable = state.purchaseOrderInvoicesLoadable.withLoading(action.payload);
            return {
                ...state,
                searchParams: action.payload.SearchParams,
                filterType: action.payload.Filter,
                purchaseOrderInvoicesLoadable,
            };
        },
    )
    .addReducer(
        getPurchaseOrderInvoicesLoadable.success,
        (state, action): State => {
            const purchaseOrderInvoicesLoadable = state.purchaseOrderInvoicesLoadable.withPayloadIfRequestEquals(action.payload);
            return {
                ...state,
                searchParams: action.payload.request.SearchParams,
                filterType: action.payload.request.Filter,
                purchaseOrderInvoicesLoadable,
            };
        },
    )
    .addReducer(
        getPurchaseOrderInvoicesLoadable.error,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request.SearchParams,
                filterType: action.payload.request.Filter,
                purchaseOrderInvoicesLoadable: LoadableData.error(action.payload.result),
            };
        },
    )
    .toReducer();

export const selectPurchaseOrderLinkedInvoicesCount = (state: GlobalState) => state.purchaseOrderInvoices.linkedInvoicesCount;
export const selectPurchaseOrderInvoicesLoadable = (state: GlobalState) => state.purchaseOrderInvoices.purchaseOrderInvoicesLoadable;
export const selectPurchaseOrderInvoicesSearchParams = (state: GlobalState) => state.purchaseOrderInvoices.searchParams;
export const selectPurchaseOrderInvoicesFilterType = (state: GlobalState) => state.purchaseOrderInvoices.filterType;
