import { ReducerFactory } from 'redux-actions-ts-reducer';
import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { InvoiceRegistryMinDTO, InvoiceSearchOptions, PagedListContainer } from '../../../services/types/ApiTypes';
import { dashboardRejectedInvoicesLoadable } from './DashboardRejectedInvoicesActions';
import { createRequest } from './DashboardRejectedInvoicesHelper';

class State {
    dashboardRejectedInvoicesLoadable = new LoadableData<PagedListContainer<InvoiceRegistryMinDTO>, InvoiceSearchOptions>();
    searchParams: InvoiceSearchOptions = createRequest();
}

export default new ReducerFactory(new State())
    .addReducer(
        dashboardRejectedInvoicesLoadable.request,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload,
                dashboardRejectedInvoicesLoadable: state.dashboardRejectedInvoicesLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        dashboardRejectedInvoicesLoadable.success,
        (state, action): State => {
            return {
                ...state,
                dashboardRejectedInvoicesLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        dashboardRejectedInvoicesLoadable.error,
        (state, action): State => {
            return {
                ...state,
                dashboardRejectedInvoicesLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .toReducer();

export { State as DashboardRejectedInvoicesState };

export const selectDashboardRejectedInvoicesLoadable = (state: GlobalState) => state.dashboardRejectedInvoices.dashboardRejectedInvoicesLoadable;
