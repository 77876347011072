import { debounce } from 'lodash-es';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable2, Dispatchable3 } from 'redux-dispatchers';

import { createDataId } from '../../../../../../common/utils/dataId';
import { formatDate, formatMoneyAndCurrency } from '../../../../../../common/utils/formatters';
import { LoadableData } from '../../../../../../common/utils/LoadableData';
import { DEFAULT_CURRENCY_PLACEHOLDER, UserRole } from '../../../../../../common/constants/appConstants';
import { hasOnlyOneRole } from '../../../../../../common/user/userPermissionUtil';
import { ContentBlockBody } from '../../../../../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter, ContentBlockFooterType } from '../../../../../../components/ContentBlock/ContentBlockFooter';
import Pager from '../../../../../../components/Pager/Pager';
import Table from '../../../../../../components/Table/Table';
import TableBody from '../../../../../../components/Table/TableBody';
import TableCell, { ResponsiveCellType } from '../../../../../../components/Table/TableCell';
import TableHead from '../../../../../../components/Table/TableHead';
import { DataTableHeader } from '../../../../../../components/Table/TableHeader';
import TableRow from '../../../../../../components/Table/TableRow';
import { Select } from '../../../../../../components/Select/Select';
import { Button, ButtonType } from '../../../../../../components/Buttons/Button';
import { ICONS } from '../../../../../../components/Icon/Icon';
import EmptySearchResults from '../../../../../../components/EmptySearchResults/EmptySearchResults';
import Status from '../../../../../../components/Status/Status';
import { TagSelectType } from '../../../../../../components/TagSelect/TagSelect';
import { TableFilter } from '../../../../../../components/Table/components/filter/TableFilters';
import { DATE_RESTRICTION, getSupplierList } from '../../../../../purchase-orders/PurchaseOrdersViewHelper';
import { ContentBlockHeader, ContentBlockHeaderType } from '../../../../../../components/ContentBlock/ContentBlockHeader';
import PropertyListItem from '../../../../../../components/PropertyListItem/PropertyListItem';
import { InvoicesSearchParams } from './PurchaseOrdersListReducers';
import { areDateRangeValuesEmpty, DateRangeValues } from '../../../../../../components/CalendarDateRangeModal/CalendarDateRangeModal';
import { DEFAULT_RESTRICTION, getPurchaseOrderStatusType, AllowedLinkInvoiceStatuses, parseFilters, parseRestrictions, PO_INVOICES_FILTER_TYPES } from './PurchaseOrdersListHelper';
import {
    BaseSearch,
    PagedListContainer,
    InvoicePurchaseOrdersSearch,
    InvoiceHeaderPurchaseOrdersDTO,
    PurchaseOrderInvoicesFilter,
    Restriction,
    InvoiceDTO,
    LinkedCountDTO,
    SearchType,
} from '../../../../../../services/types/ApiTypes';

import './PurchaseOrdersList.scss';

const dataId = 'invoice-purchase-orders';

export interface Props {
    linkedPurchaseOrdersCount: LoadableData<LinkedCountDTO>;
    invoicePurchaseOrdersLoadable: LoadableData<PagedListContainer<InvoiceHeaderPurchaseOrdersDTO>, InvoicePurchaseOrdersSearch>;
    searchParams: InvoicesSearchParams;
    invoice: InvoiceDTO;
    filterType: number;
}

export interface DispatchProps {
    getInvoicesList: Dispatchable3<BaseSearch, number, PurchaseOrderInvoicesFilter>;
    getLinkedPurchaseOrdersCount: Dispatchable3<number, boolean, boolean>;
    searchPoInvoicesList: Dispatchable3<string, number, boolean>;
    setPoInvoicesPagingOptions: Dispatchable3<number, number, number>;
    sortPoInvoicesList: Dispatchable2<string, number>;
    filterInvoicePurchaseOrders: Dispatchable3<number, Restriction, TableFilter<any>>;
    linkPurchaseOrderToInvoice: Dispatchable2<number, number>;
    unlinkPurchaseOrderFromInvoice: Dispatchable2<number, number>;
}

export type InvoicesPurchaseOrdersListViewProps = Props & DispatchProps & WithTranslation;

const PurchaseOrdersList = (props: InvoicesPurchaseOrdersListViewProps) => {
    const [appliedFilters, setAppliedFilters] = React.useState<TableFilter<any>[]>(null);

    const isPoLinkAllowed = AllowedLinkInvoiceStatuses.includes(props.invoice?.Status);
    const isReadyOnly = hasOnlyOneRole(UserRole.Auditor);

    React.useEffect(() => {
        if (props.invoice?.Id) {
            props.getLinkedPurchaseOrdersCount(props.invoice.Id, !isPoLinkAllowed, true);
        }
    }, []);

    React.useEffect(() => {
        const { searchParams } = props;
        const filters = (searchParams && parseRestrictions(searchParams)) || [];
        if (filters !== appliedFilters) {
            setAppliedFilters(filters);
        }
    }, [props.searchParams]);

    const debounceDoSearch = debounce((searchString: string) => {
        props.searchPoInvoicesList(searchString, props.invoice.Id, false);
    }, 400);

    const debounceDoFilter = debounce((filter?: TableFilter<any>) => {
        props.filterInvoicePurchaseOrders(props.invoice.Id, parseFilters(filter), filter);
    }, 400);

    const getSearchValue = () => {
        const searchRestriction = props.searchParams.Restrictions.find((r: any) => r.Field === DEFAULT_RESTRICTION);
        return searchRestriction ? searchRestriction.Value : null;
    };

    const getCurrentDateRange = () => {
        const dateRangeRestriction = props.searchParams.Restrictions.find((r) => r.Field === DATE_RESTRICTION);
        return (dateRangeRestriction?.Values || [null, null]) as [Date, Date];
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        props.setPoInvoicesPagingOptions(props.invoice.Id, page, pageSize);
    };

    const handleSortClick = (value: string) => {
        props.sortPoInvoicesList(value, props.invoice.Id);
    };

    const isFilterActive = (columnName: string): TableFilter<any> => {
        const { searchParams } = props;
        const filters = (searchParams && parseRestrictions(searchParams)) || [];
        return filters.find((filter: TableFilter<any>) => filter.columnName === columnName);
    };

    const getSuppliers = (name: string) => {
        return getSupplierList(name, props.invoice?.Supplier);
    };

    const getAppliableFilters = () => {
        const filtersAvailable = [
            {
                placeholder: props.t('view.global.filters.any'),
                tagSelectType: TagSelectType.DEFAULT,
                values: (!!isFilterActive('SupplierId') && isFilterActive('SupplierId').values) || [],
                loadItems: getSuppliers,
                columnName: 'SupplierId',
                label: props.t('view.PurchaseOrders.Invoices.Column.Supplier'),
                searchOnFocus: true,
                onSelectChangeCallback: (items: any) => {
                    if (!isFilterActive('SupplierId')) {
                        debounceDoFilter({
                            columnName: 'SupplierId',
                            label: props.t('view.PurchaseOrders.Invoices.Column.Supplier'),
                            loadItems: getSuppliers,
                            values: items.values,
                            tagSelectType: TagSelectType.DEFAULT,
                            onSelectChangeCallback: debounceDoFilter,
                            searchOnFocus: true,
                        });
                    } else {
                        debounceDoFilter({
                            ...isFilterActive('SupplierId'),
                            values: items.values,
                        });
                    }
                },
            },
        ];
        return filtersAvailable;
    };

    const onDateRangeChange = (v: DateRangeValues) => {
        const filter: Restriction = {
            Field: DATE_RESTRICTION,
            Value: null,
            Values: areDateRangeValuesEmpty(v.value) ? null : v.value,
            FieldSearchType: SearchType.NotSelected,
        };
        props.filterInvoicePurchaseOrders(props.invoice.Id, filter, null);
    };

    const handleRemoveFilter = (index: number, filter: TableFilter<any>) => {
        const removedFilter = appliedFilters.find((f) => {
            return f.columnName === filter.columnName;
        });
        // filters with empty values list will be removed upon filtering, hence empty the values
        removedFilter.values = [];
        debounceDoFilter(removedFilter);
    };

    const resetFilters = () => {
        props.searchPoInvoicesList(undefined, props.invoice.Id, true);
    };

    const quickFilterMenu = () => {
        return (
            <Select
                dataId={createDataId(dataId, 'LinkedType')}
                onChange={(type) => {
                    if (type) {
                        props.getInvoicesList(undefined, props.invoice.Id, Number(type.value));
                    }
                }}
                value={{
                    text: props.t(PO_INVOICES_FILTER_TYPES[props.filterType || 0]),
                    value: props.filterType || '0',
                }}
                items={Object.keys(PO_INVOICES_FILTER_TYPES).map((key) => ({
                    text: props.t(PO_INVOICES_FILTER_TYPES[key]),
                    value: key,
                }))}
                placeholder={props.t('view.PurchaseOrders.Type')}
            />
        );
    };

    const toggleLinkPO = (poRow: InvoiceHeaderPurchaseOrdersDTO) => {
        const action = poRow.IsLinked ? props.unlinkPurchaseOrderFromInvoice : props.linkPurchaseOrderToInvoice;
        action(props.invoice.Id, poRow.Id);
    };

    const showHeader = isPoLinkAllowed || props.linkedPurchaseOrdersCount?.payload?.LinkedObjectsCount > 0;
    const showContent = (isPoLinkAllowed || props.linkedPurchaseOrdersCount?.payload?.LinkedObjectsCount > 0) && props.invoicePurchaseOrdersLoadable.payload?.Items.length > 0;

    return (
        <div className="invoice-purchase-orders">
            <div className="invoice-purchase-orders__counts-row">
                <div className="invoice-purchase-orders__counts-left">
                    <PropertyListItem
                        dataId={createDataId(dataId)}
                        showZeroValue={true}
                        label={props.t('view.PurchaseOrders.Invoices.Totals.LinkedCount')}
                        value={props.linkedPurchaseOrdersCount?.payload?.LinkedObjectsCount}
                    />
                    <PropertyListItem
                        dataId={createDataId(dataId)}
                        showZeroValue={true}
                        label={props.t('view.PurchaseOrders.Invoices.Totals.LinkedByMe')}
                        value={props.linkedPurchaseOrdersCount?.payload?.LinkedByMeObjectsCount}
                    />
                </div>
                <div className="invoice-purchase-orders__counts-right">
                    <PropertyListItem
                        dataId={createDataId(dataId)}
                        showZeroValue={true}
                        label={props.t('component.invoiceHeader.purchaseOrders.totals.linkedNetTotal')}
                        value={formatMoneyAndCurrency(
                            props.linkedPurchaseOrdersCount?.payload?.LinkedObjectsSumWithoutVat,
                            props.linkedPurchaseOrdersCount?.payload?.Currency || DEFAULT_CURRENCY_PLACEHOLDER,
                        )}
                    />
                    <PropertyListItem
                        dataId={createDataId(dataId)}
                        showZeroValue={true}
                        label={props.t('component.invoiceHeader.purchaseOrders.totals.linkedGrandTotal')}
                        value={formatMoneyAndCurrency(props.linkedPurchaseOrdersCount?.payload?.LinkedObjectsTotal, props.linkedPurchaseOrdersCount?.payload?.Currency || DEFAULT_CURRENCY_PLACEHOLDER)}
                    />
                </div>
            </div>

            {showHeader && (
                <ContentBlockHeader
                    className="invoice-purchase-orders"
                    type={ContentBlockHeaderType.COMPACT}
                    dataId={createDataId(dataId, 'Header')}
                    hasSearch
                    hasStaticFilters
                    onDateRangeChange={onDateRangeChange}
                    dateRange={getCurrentDateRange()}
                    t={props.t}
                    datePickerLabel={props.t('views.global.dateCreated')}
                    datePickerActiveScope={'dateCreated'}
                    datePickerScopeList={[{ text: props.t('views.global.dateCreated'), value: 'dateCreated' }]}
                    hasDateFilter
                    searchOptions={{
                        dataId: createDataId(dataId, 'Search'),
                        value: getSearchValue(),
                        onClick: debounceDoSearch,
                        withSearchButton: true,
                        withFilterButton: true,
                        quickFilterMenu: quickFilterMenu(),
                        quickFilterLabel: props.t('component.invoiceHeader.purchaseOrders.filter.label.linkedWithCurrent'),
                    }}
                    filterable
                    filterOptions={{
                        dataId: createDataId(dataId, 'tableFilters'),
                        className: 'filter',
                        t: props.t,
                        appliedFilters: getAppliableFilters(),
                        onRemove: handleRemoveFilter,
                    }}
                />
            )}
            {showContent ? (
                <>
                    <div className="invoice-purchase-orders__table">
                        <ContentBlockBody className="po-invoice__table-content" loading={props.invoicePurchaseOrdersLoadable.loading} dataId="contentBlockBodyPoInvoicesList">
                            <Table>
                                <DataTableHeader data-id={createDataId(dataId, 'Table', 'Header')}>
                                    <TableRow>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.invoicePurchaseOrdersLoadable.request?.SearchParams}
                                            columnName="OrderNumber"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'OrderNumber')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Number')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.invoicePurchaseOrdersLoadable.request?.SearchParams}
                                            columnName="DateCreated"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'DateCreated')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.DateCreated')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.invoicePurchaseOrdersLoadable.request?.SearchParams}
                                            columnName="SupplierName"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'SupplierName')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Invoices.Column.Supplier')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.invoicePurchaseOrdersLoadable.request?.SearchParams}
                                            columnName="LinkedDate"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'LinkedDate')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Invoices.Column.LinkedDateTime')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.invoicePurchaseOrdersLoadable.request?.SearchParams}
                                            columnName="LinkerName"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'LinkerName')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Invoices.Column.LinkedBy')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.invoicePurchaseOrdersLoadable.request?.SearchParams}
                                            columnName="SumWithoutVat"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'SumWithoutVat')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Invoices.Column.NetTotal')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={props.invoicePurchaseOrdersLoadable.request?.SearchParams}
                                            columnName="TotalAmountWithVat"
                                            onClick={handleSortClick}
                                            dataId={createDataId(dataId, 'TotalAmountWithVat')}
                                            style={{ width: '14%' }}
                                        >
                                            {props.t('view.PurchaseOrders.Invoices.Column.GrandTotal')}
                                        </TableHead>
                                    </TableRow>
                                </DataTableHeader>
                                <TableBody>
                                    {props.invoicePurchaseOrdersLoadable?.payload &&
                                        props.invoicePurchaseOrdersLoadable.payload.Items.map((row, id) => (
                                            <TableRow key={id} data-id={createDataId(dataId, 'tableRow', id)}>
                                                <TableCell dataId={createDataId(dataId, `${id}.OrderStatus`)} hideOverflow={true} responsiveCellType={ResponsiveCellType.LABEL}>
                                                    <Status type={getPurchaseOrderStatusType(row.OrderStatus)}>{row.PurchaseOrderNumber || '\u00A0'}</Status>
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.DateCreated`)} hideOverflow={true} responsiveCellType={ResponsiveCellType.LABEL}>
                                                    {formatDate(row?.CreatedDate) || '\u00A0'}
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.SupplierName`)} hideOverflow={true} responsiveCellType={ResponsiveCellType.LABEL}>
                                                    {row?.SupplierName || '\u00A0'}
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.LinkedDate`)} hideOverflow={true}>
                                                    {formatDate(row?.LinkedDate, 'dd.MM.yyyy HH:mm:ss') || '\u00A0'}
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.LinkerName`)} hideOverflow={true}>
                                                    {row.LinkerName || '\u00A0'}
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.SumWithoutVat`)} hideOverflow={true}>
                                                    {formatMoneyAndCurrency(row.SumWithoutVat, row?.Currency || DEFAULT_CURRENCY_PLACEHOLDER)}
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.TotalAmountWithVat`)} hideOverflow={true}>
                                                    {formatMoneyAndCurrency(row.TotalAmountWithVat, row?.Currency || DEFAULT_CURRENCY_PLACEHOLDER) || '\u00A0'}
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.link`)}>
                                                    <Button
                                                        onClick={() => {
                                                            toggleLinkPO(row);
                                                        }}
                                                        dataId={createDataId(dataId, 'btn', 'link', row.Id)}
                                                        icon={row.IsLinked ? ICONS.UN_LINK_24 : ICONS.LINK_24}
                                                        className="po-action-button"
                                                        buttonType={ButtonType.ICON_TEXT}
                                                        disabled={!isPoLinkAllowed || isReadyOnly}
                                                    />
                                                </TableCell>
                                                <TableCell dataId={createDataId(dataId, `${id}.open`)}>
                                                    <a href={`/app/#/purchase-orders/details/${row.Id}`} target="_blank" rel="noopener noreferrer">
                                                        <Button
                                                            onClick={() => {}}
                                                            dataId={createDataId(dataId, 'btn', 'open', row.Id)}
                                                            icon={ICONS.GO_TO_24}
                                                            className="po-action-button"
                                                            buttonType={ButtonType.ICON_TEXT}
                                                        />
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </ContentBlockBody>
                    </div>
                    <ContentBlockFooter type={ContentBlockFooterType.PAGER}>
                        <Pager pages={props.invoicePurchaseOrdersLoadable.payload} onPageChange={handlePageChange} />
                    </ContentBlockFooter>
                </>
            ) : (
                <EmptySearchResults
                    title={!isPoLinkAllowed ? props.t('view.PurchaseOrders.Invoices.EmptySearchRestricted.Title') : props.t('view.PurchaseOrders.Invoices.EmptySearch.Title')}
                    subTitle={!isPoLinkAllowed ? props.t('view.PurchaseOrders.Invoices.EmptySearchRestricted.SubTitle') : props.t('view.PurchaseOrders.Invoices.EmptySearch.SubTitle')}
                    resetFiltersCb={isPoLinkAllowed ? resetFilters : null}
                />
            )}
        </div>
    );
};

export default withTranslation()(PurchaseOrdersList);
