(function () {
  "use strict";

  angular
    .module("dstreamApp.views.archive-details")
    .component("dsStaticConfirmationWorkflow", {
      templateUrl: "app/views/archive-details/components/static-confirmation-workflow/static-confirmation-workflow.html",
      controller: StaticConfirmationWorkflow,
      bindings: {
        invoice: "<",
        invoiceLoading: "<"
      }
    });

  StaticConfirmationWorkflow.$inject = ["constants"];

  function StaticConfirmationWorkflow (constants) {
    var ctrl = this;
    /* Controller variables */
    ctrl.getInvoiceStatusTranslateKey = constants.getInvoiceStatusTranslateKey;
    ctrl.DATE_EE_FORMAT_FULL = constants.DATE_EE_FORMAT_FULL;

    ctrl.showSubstituteName = showSubstituteName;

    function showSubstituteName (user) {
      return !!user.Substitute && !!user.SubstituterUserFullName && !!user.Name && user.Name !== user.SubstituterUserFullName;
    }
  }
})();