import { getViewUserSearchParams, ListViewUserConfig } from '../../../common/user/userSettingUtil';
import { FileReaderResult } from '../../../common/utils/fileReader';
import { notify } from '../../../common/utils/notify';
import { loadableDataActionsWithRequest } from '../../../common/utils/LoadableData';
import i18nInstance from '../../../i18n';
import { GlobalState } from '../../../rootReducer';
import { BaseSearch, FileDTO, PagedListContainer, RelatedDimensionsDTO, SortDirection, UserSettingName } from '../../../services/types/ApiTypes';
import api from '../../../services/ApiServices';
import { DispatchThunk } from '../../../storeConfig';
import { selectRelatedDimensionsSearchParams } from './RelatedDimensionsViewReducer';
import { isEmpty } from 'lodash-es';
import { getCurrentUserGroupMember } from '../../../common/user/UserActions';
import { updateAppUserSettingsAction } from '../../../common/middlewares/userSettings';

const ns = 'automation/related-dimensions/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.AUTOMATION_RELATED_DIMENSIONS_SORT_DIRECTION,
    sortCol: UserSettingName.AUTOMATION_RELATED_DIMENSIONS_SORT_COLUMN,
    pageSize: UserSettingName.AUTOMATION_RELATED_DIMENSIONS_PAGE_SIZE,
};

export const setPagingOptions = (page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState): Promise<any> => {
        const relatedDimensionsSearchParams = selectRelatedDimensionsSearchParams(getState());
        const paging = relatedDimensionsSearchParams.PagingOptions;

        const searchParams: BaseSearch = {
            ...relatedDimensionsSearchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        return dispatch(searchRelatedDimensions(searchParams));
    };
};

export const loadRelatedDimensionsLoadableActions = loadableDataActionsWithRequest<BaseSearch, PagedListContainer<RelatedDimensionsDTO>>(`${ns}LOAD_RELATED_DIMENSIONS`);

export function searchRelatedDimensions(searchParams?: BaseSearch | undefined) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const relatedDimensionsSearchParams = selectRelatedDimensionsSearchParams(getState());
        searchParams = searchParams || relatedDimensionsSearchParams;
        const groupMember = getState().user.groupMemberCommonLoadable.payload;
        if (groupMember) {
            searchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        }

        let response;
        try {
            dispatch(loadRelatedDimensionsLoadableActions.request(searchParams));
            response = await api.relatedDimensions.getRelatedDimensions(searchParams);
            dispatch(
                loadRelatedDimensionsLoadableActions.success({
                    request: searchParams,
                    result: response.data,
                }),
            );

            // if our page for some reason is empty, but there is data on previous pages, then load previous pages until we have some data to display
            if (response.data.Items.length === 0 && response.data.HasCount) {
                const currentPage = relatedDimensionsSearchParams.PagingOptions.Page;
                if (currentPage > 1) {
                    return dispatch(setPagingOptions(currentPage - 1));
                }
            }
            return Promise.resolve();
        } catch (e) {
            console.error(e);
            dispatch(
                loadRelatedDimensionsLoadableActions.error({
                    request: searchParams,
                    result: e,
                }),
            );
            return Promise.resolve();
        }
    };
}

export const filterRelatedDimensions = () => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const relatedDimensionsSearchParams = selectRelatedDimensionsSearchParams(state);
        const paging = relatedDimensionsSearchParams.PagingOptions;
        const searchParams: BaseSearch = {
            ...relatedDimensionsSearchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
        };
        dispatch(searchRelatedDimensions(searchParams));
    };
};

export const sortRelatedDimensions = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const relatedDimensionsSearchParams = selectRelatedDimensionsSearchParams(getState());
        const sorting = relatedDimensionsSearchParams.SortItems[0];

        const searchParams: BaseSearch = {
            ...relatedDimensionsSearchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };
        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        dispatch(searchRelatedDimensions(searchParams));
    };
};

export function uploadRelatedDimensionsFile(fileReaderResult: FileReaderResult) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const file: FileDTO = {
                Base64Content: fileReaderResult.base64Content,
                FileName: fileReaderResult.fileName,
                WorkflowDocumentId: undefined,
                DownloadUrl: undefined,
                FileUrl: undefined,
                ForceImport: undefined,
                HubType: undefined,
                Id: undefined,
                IsImported: undefined,
                IsNew: undefined,
                IsPdf: undefined,
                PathToFile: undefined,
            };
            const response = await api.relatedDimensions.uploadRelatedDimensionsFile(file);
            if (response.status === 200) {
                notify.success(i18nInstance.t('component.relatedDimensions.fileUploadedSuccessfully'), i18nInstance.t('views.home.index.RelatedDimensions'));
            } else {
                notify.error(i18nInstance.t('component.relatedDimensions.errorWhileFileUploading'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            if (e.response?.status === 400) {
                notify.error(e.response.data?.ErrorCode ? i18nInstance.t(e.response.data.ErrorCode) : e.response.data?.Message);
            } else {
                notify.error(i18nInstance.t('component.relatedDimensions.errorWhileFileUploading'), i18nInstance.t('interceptorsFactory.Error'));
            }
            console.error(e);
        } finally {
            const relatedDimensionsSearchParams = selectRelatedDimensionsSearchParams(getState());
            dispatch(loadRelatedDimensionsLoadableActions.request(relatedDimensionsSearchParams));
            dispatch(setPagingOptions(1));
        }
    };
}
