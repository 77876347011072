import { createAction } from 'redux-actions';

import { getViewUserSearchParams, ListViewUserConfig } from '../../../common/user/userSettingUtil';
import { FileReaderResult } from '../../../common/utils/fileReader';
import { notify } from '../../../common/utils/notify';
import { loadableDataActionsWithRequest } from '../../../common/utils/LoadableData';
import { saveFileFromResponse } from '../../../common/utils/saveFileFromResponse';
import i18nInstance from '../../../i18n';
import { GlobalState } from '../../../rootReducer';
import { BaseSearch, FileDTO, PagedListContainer, Restriction, SortDirection, UserSettingName, VatCodeDTO } from '../../../services/types/ApiTypes';
import api from '../../../services/ApiServices';
import { DispatchThunk } from '../../../storeConfig';
import { isEmpty } from 'lodash-es';
import { getCurrentUserGroupMember } from '../../../common/user/UserActions';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../common/utils/baseSearchHelpers';
import { updateAppUserSettingsAction } from '../../../common/middlewares/userSettings';

const ns = 'settings/vat-code-details/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.SETTINGS_ACCOUNTING_VAT_CODES_SORT_DIRECTION,
    sortCol: UserSettingName.SETTINGS_ACCOUNTING_VAT_CODES_SORT_COLUMN,
    pageSize: UserSettingName.SETTINGS_ACCOUNTING_VAT_CODES_PAGE_SIZE,
};

export const setPagingOptions = (page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState): Promise<any> => {
        const { vatCodeDetails } = getState();
        const paging = vatCodeDetails.vatCodesSearchParams.PagingOptions;

        const searchParams: BaseSearch = {
            ...vatCodeDetails.vatCodesSearchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };

        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        return dispatch(searchVatCodes(searchParams));
    };
};

export const loadVatCodesLoadableActions = loadableDataActionsWithRequest<BaseSearch, PagedListContainer<VatCodeDTO>>(`${ns}LOAD_VAT_CODES`);

export function searchVatCodes(searchParams?: BaseSearch | undefined) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const {
            vatCodeDetails,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        searchParams = searchParams || vatCodeDetails.vatCodesSearchParams;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Code', SortDirection.Asc);

        searchParams = { ...searchParams, ...viewSearchParams };

        let response;
        try {
            dispatch(loadVatCodesLoadableActions.request(searchParams));
            response = await api.company.getVatCodesItems(searchParams);
            dispatch(
                loadVatCodesLoadableActions.success({
                    request: searchParams,
                    result: response.data,
                }),
            );
            // if our page for some reason is empty, but there is data on previous pages, then load previous pages until we have some data to display
            if (response.data.Items.length === 0 && response.data.HasCount) {
                const currentPage = vatCodeDetails.vatCodesSearchParams.PagingOptions.Page;
                if (currentPage > 1) {
                    return dispatch(setPagingOptions(currentPage - 1));
                }
            }
            return Promise.resolve();
        } catch (e) {
            console.error(e);
            dispatch(
                loadVatCodesLoadableActions.error({
                    request: searchParams,
                    result: e,
                }),
            );
            return Promise.resolve();
        }
    };
}

export const filterVatCodes = (searchString: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.vatCodeDetails.vatCodesSearchParams.PagingOptions;
        const searchRestriction = state.vatCodeDetails.vatCodesSearchParams.Restrictions[0];
        const searchParams: BaseSearch = {
            ...state.vatCodeDetails.vatCodesSearchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: [
                {
                    ...searchRestriction,
                    Value: searchString,
                },
            ],
        };
        dispatch(searchVatCodes(searchParams));
    };
};

export const sortVatCodes = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { vatCodeDetails } = getState();
        const sorting = vatCodeDetails.vatCodesSearchParams.SortItems[0];

        const searchParams: BaseSearch = {
            ...vatCodeDetails.vatCodesSearchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };

        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        dispatch(searchVatCodes(searchParams));
    };
};

export const addNewRow = createAction(`${ns}ADD_NEW_ROW`);

export const gotoLastPageAndAddNewRow = () => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const payload = state.vatCodeDetails.vatCodesLoadable.payload;
        const lastPage = Math.ceil(payload.TotalCount / payload.Take) || 1;
        const currentPage = payload.Skip / payload.Take + 1;
        if (lastPage !== currentPage) {
            await dispatch(setPagingOptions(lastPage));
        }
        dispatch(addNewRow());
    };
};

export const updateRow = createAction<{ result: VatCodeDTO; itemToUpdate: number }>(`${ns}UPDATE_ROW`);

export const saveRow = (item: VatCodeDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            let result;
            if (item.Id > 0) {
                result = await api.company.editVatCode(item);
            } else {
                result = await api.company.addVatCode(item);
            }
            // res 200, but errors array in response
            if (Array.isArray(result.data) && result.data.length > 0) {
                const error = result.data[0].includes('exist') ? 'view.Accounting.CodeAlreadyExists' : 'view.Accounting.RowSavingFailed';
                notify.error(i18nInstance.t(error));
                return Promise.reject(result.data);
            }
            dispatch(
                updateRow({
                    result: result.data,
                    itemToUpdate: item.Id,
                }),
            );
            return Promise.resolve();
        } catch (e) {
            notify.error(i18nInstance.t(e?.response?.data?.ErrorCode || 'view.Accounting.RowSavingFailed'));
            console.error(e);
            return Promise.reject(e);
        }
    };
};

export const updateVatCodeDefaults = createAction<VatCodeDTO[]>(`${ns}UPDATE_VAT_CODE_DEFAULTS`);

export const toggleVatCodeDefault = (item: VatCodeDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            const result = await api.company.toggleVatCodeDefault({
                ...item,
                IsDefault: !item.IsDefault,
            });
            if (result.data && result.data) {
                dispatch(updateVatCodeDefaults(result.data));
            }
        } catch (e) {
            notify.info(i18nInstance.t('view.Accounting.VatCode.ChangingDefaultFailed'));
            console.error(e);
        }
    };
};

export const removeRow = createAction<number>(`${ns}REMOVE_ROW`);

export const deleteRow = (id: number) => {
    return async (dispatch: DispatchThunk) => {
        try {
            if (id > 0) {
                await api.company.deleteVatCode(id);
            }
            dispatch(removeRow(id));
            dispatch(searchVatCodes()); // let's reload to keep the item count on page up-to-date
            return Promise.resolve();
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    };
};

export function uploadCSV(fileReaderResult: FileReaderResult) {
    return async (dispatch: DispatchThunk) => {
        try {
            const file: FileDTO = {
                Base64Content: fileReaderResult.base64Content,
                FileName: fileReaderResult.fileName,
                WorkflowDocumentId: undefined,
                DownloadUrl: undefined,
                FileUrl: undefined,
                ForceImport: undefined,
                HubType: undefined,
                Id: undefined,
                IsImported: undefined,
                IsNew: undefined,
                IsPdf: undefined,
                PathToFile: undefined,
            };

            await api.company.uploadVatCodeCSVFile(file);
            dispatch(setPagingOptions(1));
        } catch (e) {
            console.error(e);
        }
    };
}

export const exportVatCodesToCSVLoadableActions = loadableDataActionsWithRequest(`${ns}EXPORT_VAT_CODES_TO_CSV`);
export const exportVatCodesToCSV = (restrictions: Restriction[]) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(exportVatCodesToCSVLoadableActions.request(null));
            const response = await api.company.exportVatCodesToCSV(restrictions);
            saveFileFromResponse(response, { fileExtension: 'csv', namePrefix: 'VAT_codes' });
            dispatch(exportVatCodesToCSVLoadableActions.success(null));
        } catch (e) {
            console.error(e);
            dispatch(exportVatCodesToCSVLoadableActions.error(e));
        }
    };
};

export const exportVatCodesToXLSXLoadableActions = loadableDataActionsWithRequest(`${ns}EXPORT_VAT_CODES_TO_XLSX`);

export const exportVatCodesToXLSX = (restrictions: Restriction[]) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(exportVatCodesToXLSXLoadableActions.request(null));
            const response = await api.company.exportVatCodesToXls(restrictions);
            saveFileFromResponse(response, { fileExtension: 'xlsx', namePrefix: 'VAT_codes' });
            dispatch(exportVatCodesToXLSXLoadableActions.success(null));
        } catch (e) {
            console.error(e);
            dispatch(exportVatCodesToXLSXLoadableActions.error(e));
        }
    };
};
