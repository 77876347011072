import { loadableDataActions } from '../../../common/utils/LoadableData';
import { WorkflowTemplateDTO } from '../../../services/types/ApiTypes';
import api from '../../../services/ApiServices';
import { DispatchThunk, routerHistory } from '../../../storeConfig';
import { replace } from 'connected-react-router';
import { notify } from '../../../common/utils/notify';
import i18nInstance from '../../../i18n';
import { AxiosResponse } from 'axios';

const ns = 'settings/vat-code-details/';

export const workflowLoadableDataActions = loadableDataActions<number, WorkflowTemplateDTO>(`${ns}GET_WORKFLOW_BY_ID`);
export const addOrUpdateWorkflowTemplateLoadableActions = loadableDataActions<WorkflowTemplateDTO, WorkflowTemplateDTO>(`${ns}SAVE_WORKFLOW`);

export const createEmptyWorkflowTemplate = (): WorkflowTemplateDTO => ({
    Description: undefined,
    IsDynamic: undefined,
    IsActive: undefined,
    InUse: undefined,
    IsNew: true,
    WorkflowTemplateItems: [],
    Name: undefined,
    Id: undefined,
    ApproversPerSteps: [],
});

export const getWorkflowTemplate = (id?: number) => {
    return async (dispatch: DispatchThunk) => {
        let response: AxiosResponse<WorkflowTemplateDTO>;
        if (!id || isNaN(id)) {
            dispatch(workflowLoadableDataActions.success(createEmptyWorkflowTemplate()));
            return;
        }
        try {
            dispatch(workflowLoadableDataActions.request(id));
            response = await api.workflowTemplate.getById(id);
            if (!response.data) {
                routerHistory.replace('/workflows');
            }
            dispatch(workflowLoadableDataActions.success(response.data));
        } catch (e) {
            dispatch(workflowLoadableDataActions.error(e));
        }
    };
};

export const addOrUpdateWorkflowTemplate = (workflowTemplate: WorkflowTemplateDTO) => {
    return async (dispatch: DispatchThunk): Promise<WorkflowTemplateDTO> => {
        let response;
        try {
            dispatch(addOrUpdateWorkflowTemplateLoadableActions.request(workflowTemplate));

            if (!workflowTemplate.Id) {
                response = await api.workflowTemplate.addWorkflowTemplate(workflowTemplate);
            } else {
                response = await api.workflowTemplate.updateWorkflowTemplate(workflowTemplate);
            }

            if (!response.data.Success) {
                dispatch(addOrUpdateWorkflowTemplateLoadableActions.error(new Error(response.data.Message)));
                notify.info(i18nInstance.t(response.data.Message), i18nInstance.t('view.Workflows.Details.Error.Save'));
                return undefined;
            }

            notify.success(i18nInstance.t('view.Workflows.Details.Save.Success'));
            dispatch(addOrUpdateWorkflowTemplateLoadableActions.success(response.data.ResultObject));

            if (!workflowTemplate.Id && response.data.ResultObject.Id) {
                dispatch(replace(`/workflows/details/${response.data.ResultObject.Id}`));
                return response.data.ResultObject;
            }

            return response.data.ResultObject;
        } catch (e) {
            dispatch(addOrUpdateWorkflowTemplateLoadableActions.error(e));
        }
        return workflowTemplate;
    };
};
