import React from 'react';
import classNames from 'classnames';

import Icon, { ICONS } from '../Icon/Icon';

import './StampLabel.scss';

export enum StampType {
    NEUTRAL = 'NEUTRAL',
    ACTIVE = 'ACTIVE',
    INFO = 'INFO',
    ALARM = 'ALARM',
    ARCHIVED = 'ARCHIVED',
    APPROVED = 'APPROVED',
    DISCOUNT = 'DISCOUNT',
    EXTRA_CHARGE = 'EXTRA-CHARGE',
}
type Props = {
    text: string;
    type: StampType;
    isDropdown?: boolean;
};

const StampLabel = (props: Props) => {
    const { text, type, isDropdown } = props;

    return (
        <span className={classNames('stamp-label', `stamp-label--${type.toLowerCase()}`)}>
            {text}
            {isDropdown && <Icon name={ICONS.CHEVRON_DOWN_24} />}
        </span>
    );
};

export default React.memo(StampLabel);
