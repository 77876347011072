import AccountService from './controllers/AccountService';
import AuthService from './controllers/AuthService';
import AutoTransactionService from './controllers/AutoTransactionService';
import CompanyService from './controllers/CompanyService';
import CurrencyService from './controllers/CurrencyService';
import CustomCostObjectiveService from './controllers/CustomCostObjectiveService';
import InvoiceService from './controllers/InvoiceService';
import LoginService from './controllers/LoginService';
import ProductItemService from './controllers/ProductItemService';
import PurchaseOrderService from './controllers/PurchaseOrderService';
import RelatedDimensionsService from './controllers/RelatedDimensionsService';
import SessionsService from './controllers/SessionsService';
import UserService from './controllers/UserService';
import WorkflowTemplateService from './controllers/WorkflowTemplateService';
import { getApiClient, getBoApiClient, getIdCardApiClient, getLoginApiClient, runSessionTimer } from './ApiClient';
import BoAssistantsService from './controllers/BoAssistantsService';
import BoUsersService from './controllers/BoUsersService';
import BoIntegratorsService from './controllers/BoIntegratorsService';
import BoDomainsService from './controllers/BoDomainsService';
import BoManagersService from './controllers/BoManagersService';
import BoUserCompaniesService from './controllers/BoUserCompaniesService';
import BoCompaniesService from './controllers/BoCompaniesService';
import BoOrganizationsService from './controllers/BoOrganizationsService';
import BoResellersService from './controllers/BoResellersService';
import SupplierService from './controllers/SupplierService';
import InvoiceTypesService from './controllers/InvoiceTypeService';
import PaymentMethodsService from './controllers/PaymentMethodsService';
import HeaderExtensionsService from './controllers/HeaderExtensionsService';

export class ApiService {
    public auth: AuthService;
    public user: UserService;
    public company: CompanyService;
    public purchaseOrder: PurchaseOrderService;
    public workflowTemplate: WorkflowTemplateService;
    public currency: CurrencyService;
    public autoTransaction: AutoTransactionService;
    public account: AccountService;
    public customCostObjective: CustomCostObjectiveService;
    public invoice: InvoiceService;
    public invoiceTypes: InvoiceTypesService;
    public login: LoginService;
    public productItem: ProductItemService;
    public paymentMethod: PaymentMethodsService;
    public headerExtensions: HeaderExtensionsService;
    public relatedDimensions: RelatedDimensionsService;
    public sessions: SessionsService;
    public boAssistants: BoAssistantsService;
    public boUsers: BoUsersService;
    public boIntegrators: BoIntegratorsService;
    public boDomains: BoDomainsService;
    public boManagers: BoManagersService;
    public boUserCompanies: BoUserCompaniesService;
    public boCompanies: BoCompaniesService;
    public boOrganizations: BoOrganizationsService;
    public boResellers: BoResellersService;
    public suppliers: SupplierService;

    constructor() {
        this.auth = new AuthService(getApiClient());
        this.user = new UserService(getApiClient());
        this.company = new CompanyService(getApiClient());
        this.purchaseOrder = new PurchaseOrderService(getApiClient());
        this.workflowTemplate = new WorkflowTemplateService(getApiClient());
        this.currency = new CurrencyService(getApiClient());
        this.autoTransaction = new AutoTransactionService(getApiClient());
        this.account = new AccountService(getApiClient());
        this.customCostObjective = new CustomCostObjectiveService(getApiClient());
        this.invoice = new InvoiceService(getApiClient());
        this.invoiceTypes = new InvoiceTypesService(getApiClient());
        this.login = new LoginService(getLoginApiClient(), getIdCardApiClient());
        this.productItem = new ProductItemService(getApiClient());
        this.paymentMethod = new PaymentMethodsService(getApiClient());
        this.headerExtensions = new HeaderExtensionsService(getApiClient());
        this.relatedDimensions = new RelatedDimensionsService(getApiClient());
        this.sessions = new SessionsService(getApiClient());
        this.boAssistants = new BoAssistantsService(getBoApiClient());
        this.boUsers = new BoUsersService(getBoApiClient());
        this.boIntegrators = new BoIntegratorsService(getBoApiClient());
        this.boDomains = new BoDomainsService(getBoApiClient());
        this.boManagers = new BoManagersService(getBoApiClient());
        this.boUserCompanies = new BoUserCompaniesService(getBoApiClient());
        this.boCompanies = new BoCompaniesService(getBoApiClient());
        this.boOrganizations = new BoOrganizationsService(getBoApiClient());
        this.boResellers = new BoResellersService(getBoApiClient());
        this.suppliers = new SupplierService(getApiClient());

        runSessionTimer();
    }
}

const api = new ApiService();
export default api;
