import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { BackOfficeOrganizationDTO } from '../../../services/types/BoApiTypes';
import { BaseSearch, PagedListContainerOf, SearchType, SortDirection } from '../../../services/types/ApiTypes';

import { getBOOrganizationListLoadable } from './BOOrganizationListViewActions';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

class State {
    boOrganizationListLoadable = new LoadableData<PagedListContainerOf<BackOfficeOrganizationDTO>, BaseSearch>();
    searchParams: BaseSearch = {
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: '',
                Values: undefined,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        SortItems: [
            {
                SortColumn: 'OrganizationName',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 15,
        },
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        getBOOrganizationListLoadable.request,
        (state, action): State => {
            return {
                ...state,
                boOrganizationListLoadable: LoadableData.loading(action.payload),
            };
        },
    )
    .addReducer(
        getBOOrganizationListLoadable.success,
        (state, action): State => {
            return {
                ...state,
                boOrganizationListLoadable: state.boOrganizationListLoadable.withPayloadIfRequestEquals(action.payload),
                searchParams: action.payload.request,
            };
        },
    )
    .addReducer(
        getBOOrganizationListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                boOrganizationListLoadable: state.boOrganizationListLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'bo/boOrganization',
    whitelist: ['searchParams'],
};
export { State as BOOrganizationManagementViewState };

export default persistReducer(persistConfig, reducer);

export const selectBOOrganizationListLoadable = (state: GlobalState) => state.boOrganizationManagement.boOrganizationListLoadable;
export const selectBOOrganizationSearchParams = (state: GlobalState) => state.boOrganizationManagement.searchParams;
