import * as React from 'react';
import { WithTranslation } from 'react-i18next';
import { Dispatchable1 } from 'redux-dispatchers';
import { User } from '../../services/ApiClient';
import { BaseComponent } from '../BaseComponent';
import { isBackOffice } from './MainMenuHelper';
import { MainMenuWebUI } from './components/MainMenuWebUI';
import { MainMenuBO } from './components/MainMenuBO';
import { BackOfficeUserDTO } from '../../services/types/BoApiTypes';
import { LoadableData } from '../../common/utils/LoadableData';

export interface Props {
    boCurrentUserLoadable: LoadableData<BackOfficeUserDTO>;
    isSubMenuOpen?: boolean;
    onOpenChange?: (isOpen: boolean) => void;
    currentPath?: string;
    currentUser?: User;
    clearFilters?: (path: string, localStoragePrefix: string) => void;
    isLoading?: boolean;
    isOpen?: boolean; // use to override the internal state and trigger closing/opening from outside
    willAskToVerifyEmail: boolean;
    emailVerificationCompleted: boolean;
}

export interface DispatchProps {
    toggleMainMenuOpen: Dispatchable1<boolean>;
}

export const CurrentUserContext = React.createContext<User>(undefined);

export type MainMenuProps = Props & DispatchProps & WithTranslation;

export class MainMenu extends BaseComponent<MainMenuProps> {
    render() {
        const { currentUser, currentPath } = this.props;
        const MainMenuUi = isBackOffice(currentPath) ? MainMenuBO : MainMenuWebUI;
        return (
            <CurrentUserContext.Provider value={currentUser}>
                <MainMenuUi {...this.props} />
            </CurrentUserContext.Provider>
        );
    }
}
