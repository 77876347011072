import { ReducerFactory } from 'redux-actions-ts-reducer';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { BaseSearch, PagedListContainerOf } from '../../../services/types/ApiTypes';
import { BackOfficeCompanyDTO } from '../../../services/types/BoApiTypes';
import { createRequest } from './boCompanyListHelpers';

import { addOrEditBoCompanyAction, BoCompanyListSearchParams, getBoCompanyListLoadable } from './BOCompanyListViewActions';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

class State {
    boCompanyListLoadable = new LoadableData<PagedListContainerOf<BackOfficeCompanyDTO>, BaseSearch>();
    addOrEditBoCompanyLoadable = new LoadableData<BackOfficeCompanyDTO, BackOfficeCompanyDTO>();
    searchParams: BoCompanyListSearchParams = {
        ...createRequest('', 1),
        filters: {},
    };
}

export default new ReducerFactory(new State())
    .addReducer(
        getBoCompanyListLoadable.request,
        (state, action): State => {
            return {
                ...state,
                boCompanyListLoadable: LoadableData.loading(action.payload),
                searchParams: action.payload,
            };
        },
    )
    .addReducer(
        getBoCompanyListLoadable.success,
        (state, action): State => {
            return {
                ...state,
                boCompanyListLoadable: LoadableData.payload(action.payload.result),
                searchParams: action.payload.request,
            };
        },
    )
    .addReducer(
        getBoCompanyListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                boCompanyListLoadable: LoadableData.error(action.payload.result),
                searchParams: action.payload.request,
            };
        },
    )
    .addReducer(
        addOrEditBoCompanyAction.request,
        (state, action): State => {
            return {
                ...state,
                addOrEditBoCompanyLoadable: state.addOrEditBoCompanyLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        addOrEditBoCompanyAction.success,
        (state, action): State => {
            return {
                ...state,
                addOrEditBoCompanyLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .toReducer();

export { State as BOCompanyManagementViewState };

export const selectBOCompanyListLoadable = (state: GlobalState) => state.boCompanyManagement.boCompanyListLoadable;
export const selectBoCompanyListSearchParams = (state: GlobalState) => state.boCompanyManagement.searchParams;
export const selectAddOrEditBoCompanyLoadable = (state: GlobalState) => state.boCompanyManagement.addOrEditBoCompanyLoadable;
