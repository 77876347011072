(function () {
	'use strict';

	angular
		.module('dstreamApp.directives')
		.directive('deleteBtn', DeleteButton);

	function DeleteButton() {
		return {
			restrict: 'A',
			link: linkFunc,
			templateUrl: 'app/directives/core/buttons/delete/delete-btn.tpl.html'
		};

		function linkFunc(scope, element) {
		}
	}
})();
