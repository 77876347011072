import React from 'react';
import classNames from 'classnames';
import { createDataId } from '../../../../../common/utils/dataId';

import { PropertyPlaceholder } from '../../../../../components/PropertyListItem/PropertyListItem';
import { PropertyListItemContainerProps } from './InvoiveHeaderTypes';

export const PropertyListItemWrapper: React.FC<PropertyListItemContainerProps> = (props) => {
    const { isLoading, children, label, alignRight, dataId } = props;
    return (
        <li className={classNames('invoice-header__property-list-item--wrapper', { 'property-list-item__item--align-right': alignRight })} data-id={createDataId(dataId, 'property-item', label)}>
            <label className={'property-list-item__label'}>{label}</label>
            {isLoading ? <PropertyPlaceholder /> : children}
        </li>
    );
};
