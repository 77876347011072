import { ReducerFactory } from 'redux-actions-ts-reducer';
import { BackOfficeAssistantDTO } from 'src/services/types/BoApiTypes';

import { LoadableData } from '../../common/utils/LoadableData';
import { Response, BooleanResponse, SessionDTO } from '../../services/types/ApiTypes';
import {
    changePasswordActions,
    getAssistantsLoadableActions,
    showUserProfile,
    saveAssistantsLoadableActions,
    sendVerificationCodeToEmailActions,
    verifyEmailActions,
    getSessionsLoadableActions,
} from './UserProfileActions';

class State {
    changePasswordLoadable = new LoadableData<Response<any>, string>();
    userAssistantsLoadable = new LoadableData<BackOfficeAssistantDTO[]>();
    userSessionsLoadable = new LoadableData<SessionDTO[]>();
    saveUserAssistantsLoadable = new LoadableData<BackOfficeAssistantDTO[]>();
    sendEmailVerificationLoadable = new LoadableData<BooleanResponse, string>();
    VerifyEmailLoadable = new LoadableData<BooleanResponse, string>();
    visibleAndFocusOn: string | boolean = false;
}

export default new ReducerFactory(new State())
    .addReducer(
        changePasswordActions.request,
        (state): State => {
            return {
                ...state,
                changePasswordLoadable: state.changePasswordLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        changePasswordActions.success,
        (state, action): State => {
            return {
                ...state,
                changePasswordLoadable: LoadableData.payload(action.payload),
                visibleAndFocusOn: false,
            };
        },
    )
    .addReducer(
        changePasswordActions.error,
        (state, action): State => {
            return {
                ...state,
                changePasswordLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        getAssistantsLoadableActions.request,
        (state): State => {
            return {
                ...state,
                userAssistantsLoadable: state.userAssistantsLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        getAssistantsLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                userAssistantsLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getAssistantsLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                userAssistantsLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        saveAssistantsLoadableActions.request,
        (state): State => {
            return {
                ...state,
                saveUserAssistantsLoadable: state.saveUserAssistantsLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        saveAssistantsLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                saveUserAssistantsLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        saveAssistantsLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                saveUserAssistantsLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        showUserProfile,
        (state, action): State => {
            return {
                ...state,
                visibleAndFocusOn: action.payload,
            };
        },
    )

    .addReducer(
        sendVerificationCodeToEmailActions.request,
        (state): State => {
            return {
                ...state,
                sendEmailVerificationLoadable: state.sendEmailVerificationLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        sendVerificationCodeToEmailActions.success,
        (state, action): State => {
            return {
                ...state,
                sendEmailVerificationLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        sendVerificationCodeToEmailActions.error,
        (state, action): State => {
            return {
                ...state,
                sendEmailVerificationLoadable: LoadableData.error(action.payload),
            };
        },
    )

    .addReducer(
        verifyEmailActions.request,
        (state): State => {
            return {
                ...state,
                VerifyEmailLoadable: state.VerifyEmailLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        verifyEmailActions.success,
        (state, action): State => {
            return {
                ...state,
                VerifyEmailLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        verifyEmailActions.error,
        (state, action): State => {
            return {
                ...state,
                VerifyEmailLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        getSessionsLoadableActions.request,
        (state): State => {
            return {
                ...state,
                userSessionsLoadable: state.userSessionsLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        getSessionsLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                userSessionsLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getSessionsLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                userSessionsLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .toReducer();

export { State as UserProfileState };
