import {getCurrentUserGroupMember, setAccountsAction, setCurrentCompanyAction, setCustomCostObjectivesAction, setUserActiveVatCodesAction, updateCostObjectivesFilterLastDateAction, updateCustomCostObjectivesFilterLastDateAction} from "../../../src/common/user/UserActions";
import {filter, isEmpty} from "lodash-es";
import {setCurrentCompanySettings, setCurrentCompanySettingsLoading, setGroupMembersAction} from "../../../src/common/company/CompanyActions";
import {setActiveCompanyGuid} from "../../../src/components/ChangeCompany/ChangeCompanyActions"
import { isEqual } from "underscore";

(function () {
    "use strict";

    angular
        .module("blocks.companyData")
        .factory("companyDataService", companyDataService);

    companyDataService.$inject = [
        "$rootScope",
        "$location",
        "webServices",
        "notifyService",
        "localStorageService",
        "utilityService",
        "$q",
        "authenticationService",
        "$routeParams",
        "$filter",
        "$window",
        "$ngRedux"
    ];

    /*
        TODO get rid of using rootScopes and use promise resolving instead && split getCompanyData into separate data objects.
     */
    function companyDataService(
        $rootScope,
        $location,
        webServices,
        notifyService,
        localStorageService,
        utilityService,
        $q,
        authenticationService,
        $routeParams,
        $filter,
        $window,
        $ngRedux
    ) {
        var accountRequestInFlight = false;
        var vatCodesRequestInFlight = false;
        var companyDataRequestInFlight = false;
        var companySettingsRequestInFlight = false;
        var companyCustomFieldsRequestInFlight = false;
        var groupMembersRequestInFlight = false;
        var groupArchiveMembersRequestInFlight = false;
        var templatesWithItemsRequestInFlight = false;
        var templatesRequestInFlight = false;
        var lastTemplateArrayIsFromArchive = false;
        var queue = [];

        const service = {
            ...this,
            companyChanged: false,
            companyDataReloaded: {
                companyData: false,
                companySettings: false,
                groupMembers: false,
                companyCustomCostObjectives: false,
                companyCustomCostObjectivesWithItems: false,
                invoiceAccounts: false,
                companyVatCodes: false
            },
            companyData: {},
            vatCodes: [],
            accountsList: [],
            customCostObjectives: [],
            customCostObjectivesWithItems: [],
            calls: {},
            $onDestroy: destroy,
        };

        var companyChangeEventListener = $rootScope.$on('ds.currentCompany.changed', function (event, value) {
            setCompanyChanged();
        });
        function destroy() {
            companyChangeEventListener();
        }

        service.calls = {
            getCustomCostObjectives: getCustomCostObjectives,
            getCompanyData: getCompanyData,
            getCompanyUsersList: getCompanyUsersList,
            getUserFullNameById: getUserFullNameById,
            getGroupMemberFullNameById: getGroupMemberFullNameById,
            getGroupMembers: getGroupMembers,
            getArchiveGroupMembers: getArchiveGroupMembers,
            getAccounts: getAccounts,
            getVatCodes: getVatCodes,
            getCurrentCompanySettings: getCurrentCompanySettings,
            getCurrentCompanyInvoiceCustomFields: getCurrentCompanyInvoiceCustomFields,
            setCompanyChanged: setCompanyChanged,
            getCompanyId: getCompanyId,
            getCurrentUserGM: getCurrentUserGM,
            getQtyPrecision: getQtyPrecision,
            getPricePrecision: getPricePrecision,
        };

        return service.calls;

        function getCurrentUserGM() {
            // TODO: implement the logic to check if GM is already fetched or in progress
            return $ngRedux.dispatch(getCurrentUserGroupMember()).then((groupMember) => {
                return groupMember;
            });
        }

        function getCompanyId() {
            return service.companyData.Id;
        }

        function setCompanyChanged() {
            service.companyChanged = true;
            Object.keys(service.companyDataReloaded).forEach(function (key) {
                service.companyDataReloaded[key] = false;
            });
        }

        function getQtyPrecision () {
            const currentCompanySettings = $ngRedux.getState().company.currentCompanySettings;
            const companySetting = _.find(currentCompanySettings, function (setting) { return setting.Name === 'MonetaryAmountsQuantityPrecision' });
            if (companySetting) {
              return companySetting.Value;
            } else {
              return '7';
            }
          };
    
          function getPricePrecision () {
            const currentCompanySettings = $ngRedux.getState().company.currentCompanySettings;
            const companySetting = _.find(currentCompanySettings, function (setting) { return setting.Name === 'MonetaryAmountsItemPricePrecision' });
            if (companySetting) {
              return companySetting.Value;
            } else {
              return '7';
            }
          };

        /*
            Get app wide data that is need to run core functionality
            This is a mandatory method that is done in all route resolves. Where data is requested depends if it's a first time running this method or not
         */
        function getCompanyData(forceFetch) {
            const params = $location.search();
            // when we have a companyGuid in the url, this means that we need to switch the company before loading the PO
            // otherwise PO companyGuid might not match currently active company
            // this is used when mobile app opens PO in webview and needs the possibility to set the company that the invoice belongs to
            if ($location.path().includes('/purchase-orders/details/') && params.companyGuid && params.companyGuid !== authenticationService.getAuthData().companyGuid) {
                authenticationService.changeCompany(params.companyGuid).then(function () {
                    $location.search('companyGuid', null);
                }).catch((e) => {
                    console.error(e);
                });
            } else {
                // in some weird cases CompanyGuid didn't match with authorizationData companyGuid and this case get new data.
                if ((!!forceFetch ||
                    !service.companyDataReloaded.companyData ||
                    !$rootScope.companyData ||
                    _.isEmpty($rootScope.companyData) ||
                    $rootScope.companyData.CompanyGuid !== authenticationService.getAuthData().companyGuid) &&
                    !companyDataRequestInFlight) {
                    companyDataRequestInFlight = true;
                    // if we don't have companyData yet, then fetch it and store it in rootScope
                    return webServices.getCompanyData().then(
                        function (result) {
                            companyDataRequestInFlight = false;
                            if (result) {
                                if ($rootScope.companyData.CompanyGuid && $rootScope.companyData.CompanyGuid !== authenticationService.getAuthData().companyGuid) {
                                    service.customCostObjectivesWithItems = [];
                                    $ngRedux.dispatch(setCustomCostObjectivesAction([]));
                                }
                                $rootScope.companyData = result.data;
                                $ngRedux.dispatch(setCurrentCompanyAction(result.data));
                                $ngRedux.dispatch(setActiveCompanyGuid(result.data.CompanyGuid));
                                service.companyData = result.data;
                                service.companyDataReloaded.companyData = true;
                            }
                            return result;
                        }, function (error) {
                            console.log("Request rejected due to", error);
                            companyDataRequestInFlight = false;
                        });
                } else {
                    // if we already have companyData then just return it in a promise
                    var deferred = $q.defer();
                    service.companyDataWatcher = $rootScope.$watch(function () {
                        return service.companyData;
                    }, function (newVal) {
                        if (newVal && !_.isEmpty(newVal)) {
                            deferred.resolve(service.companyData);
                            service.companyDataWatcher();
                        }
                    });
                    return deferred.promise;
                }
            }
        }

        function getUserFullNameById(groupMemberId) {
            var user = null;
            if (service.companyData && service.companyData.Users) {
                for (var i = 0; i < service.companyData.Users.length; i += 1) {
                    if (service.companyData.Users[i].GroupMemberId === groupMemberId) {
                        user = service.companyData.Users[i];
                        break;
                    }
                }
            }
            return user ? $q.when(user) : $q.when("");
        }

        function getGroupMemberFullNameById(groupMemberId) {
            var member = null;
            let i = 0;
            if (service.groupMembers && service.groupMembers.length > 0) {
                for (i = 0; i < service.groupMembers.length; i += 1) {
                    if (service.groupMembers[i].Id === groupMemberId) {
                        member = service.groupMembers[i];
                        break;
                    }
                }
                return member ? $q.when(member.User.FullName) : $q.when('');
            } else {
                return getGroupMembers().then(groupMembers => {
                    if (groupMembers && groupMembers.length > 0) {
                        member = groupMembers.find(groupMember => groupMember.Id === groupMemberId);
                        return member.User.FullName;
                    }
                    return '';
                })
            }
        }

        function getCompanyUsersList() {
            return $q.when(service.companyData.Users);
        }

        function execNext() {
            var task = queue[0];
            task.c().then(function (data) {
                queue.shift();
                task.d.resolve(data);
                if (queue.length > 0) execNext();
            }, function (err) {
                task.d.reject(err);
            });
        }

        function queueFactory(action) {
            var d = $q.defer();
            queue.push({c: action, d: d});
            if (queue.length === 1) execNext();
            return d.promise;
        }

        function getCurrentCompanySettings(forceFetch) {
            // in some weird cases CompanyGuid didn't match with authorizationData companyGuid and this case get new data.
            return queueFactory(function () {
                if (((forceFetch ||
                    !service.companyDataReloaded.companySettings ||
                    !$rootScope.companySettings ||
                    _.isEmpty($rootScope.companyData) ||
                    $rootScope.companyData.CompanyGuid !== authenticationService.getAuthData().companyGuid || 
                    ($rootScope.companySettings.data && $rootScope.companySettings.data[0].CompanyGuid !== $rootScope.companyData.CompanyGuid)) &&
                    !companySettingsRequestInFlight)) {
                    companySettingsRequestInFlight = true;
                    // if we don't have companyData yet, then fetch it and store it in rootScope
                    $ngRedux.dispatch(setCurrentCompanySettingsLoading(true));
                    return webServices.getCompanySettings(authenticationService.getAuthData().companyGuid || $rootScope.companyData.CompanyGuid).then(function (result) {
                        companySettingsRequestInFlight = false;
                        $ngRedux.dispatch(setCurrentCompanySettingsLoading(false));
                        if (result && result.data) {
                            $rootScope.companySettings = result;
                            $ngRedux.dispatch(setCurrentCompanySettings(result.data));
                            // emit event for Angular Dashboard view components to init settings 
                            $rootScope.$emit('ds.currentCompany.companySettingsLoaded');
                            service.companySettings = result;
                            service.companyDataReloaded.companySettings = true;
                        }
                        return result;
                    });
                } else {
                    // if we already have companyData then just return it in a promise
                    return $q.when($rootScope.companySettings);
                }
            });
        }

        function getCurrentCompanyInvoiceCustomFields(forceFetch) {
            // in some weird cases CompanyGuid didn't match with authorizationData companyGuid and this case get new data.
            return queueFactory(function () {
                if (((forceFetch ||
                    !$rootScope.invoiceCustomFields ||
                    _.isEmpty($rootScope.companyData) ||
                    $rootScope.companyData.CompanyGuid !== authenticationService.getAuthData().companyGuid) &&
                    !companyCustomFieldsRequestInFlight)) {
                    companyCustomFieldsRequestInFlight = true;
                    // if we don't have companyData yet, then fetch it and store it in rootScope
                    return webServices.getCurrentCompanyInvoiceCustomFields().then(function (result) {
                        companyCustomFieldsRequestInFlight = false;
                        if (result) {
                            $rootScope.invoiceCustomFields = result;
                        }
                        return result;
                    });
                } else {
                    // if we already have companyData then just return it in a promise
                    return $q.when($rootScope.invoiceCustomFields);
                }
            });
        }

        /*
            Get all available account values for invoices under this copany
            This is a mandatory method that is done in all route resolves. Where data is requested depends if it's a first time running this method or not
         */
        function getAccounts(forceFetch, date) {
            if ((forceFetch || !$rootScope.accountList)) {
                const {user: {accounts, costObjectivesFilterLastDate}} = $ngRedux.getState();
                // have data in state and request with the same/undefined date again
                if(accounts && accounts.length && isEqual(costObjectivesFilterLastDate, date) || accountRequestInFlight){
                    $rootScope.accountList = accounts;
                    return $q.when($rootScope.accountList);
                } else {
                    accountRequestInFlight = true;
                    return webServices.getAccounts(undefined, date).then(function (accountsResponse) {
                        const accountList = accountsResponse.data.Items.map(function (item) {
                            item.FullName = item.Code + " - " + item.Description;
                            return item;
                        });
                        $rootScope.accountList = accountList;
                        $ngRedux.dispatch(setAccountsAction($rootScope.accountList));
                        $ngRedux.dispatch(updateCostObjectivesFilterLastDateAction({costObjectivesFilterLastDate: date}));
                        service.accountsList = $rootScope.accountList;
                        accountRequestInFlight = false;
                        service.companyDataReloaded.invoiceAccounts = true;
                        return accountList;
                    }).catch((e) => {
                        console.error(e);
                        accountRequestInFlight = false;
                    });
                }
            } else {
                return $q.when($rootScope.accountList);
            }
        }

        function getVatCodes(forceFetch, date) {
            if (service.VatCodes && !forceFetch) {
                return $q.when(service.VatCodes);
            } else {
                const {user: {vatCodes, costObjectivesFilterLastDate}} = $ngRedux.getState();
                // have data in state and request with the same/undefined date again
                if(vatCodes && vatCodes.length && isEqual(costObjectivesFilterLastDate, date) || vatCodesRequestInFlight){
                    service.VatCodes = vatCodes.map((v) => ({...v, FullName: v.Code + " - " + v.Description}));
                    return $q.when(service.VatCodes);
                } else {
                    vatCodesRequestInFlight = true;
                    return webServices.getActiveVatCodesForUser(undefined, date).then((r) => {
                        const items = r.data.Items;
                        const vatCodes = items.map((v) => ({...v, FullName: v.Code + " - " + v.Description}));
                        service.VatCodes = vatCodes;
                        $ngRedux.dispatch(setUserActiveVatCodesAction(items));
                        $ngRedux.dispatch(updateCostObjectivesFilterLastDateAction({costObjectivesFilterLastDate: date}));
                        vatCodesRequestInFlight = false;
                        return vatCodes;
                    }).catch((e) => {
                        console.error(e);
                        accountRequestInFlight = false;
                    });
                }
            }
        }

        /*
            Get all users associated with currently selected company
            Isn't stored in service because each Invoice might need a different GroupMembers array, since some of them might be an archive Invoice
         */
        function getGroupMembers(forceFetch) {
            if (forceFetch || (!$rootScope.groupMembers || !service.companyDataReloaded.groupMembers) && !groupMembersRequestInFlight) {
                // if groupMembers doesn't exist in $rootScope or request is already in flight
                groupMembersRequestInFlight = true;
                return webServices.getCompanyGroupMembers($rootScope.companyGuid).then(function (result) {
                    groupMembersRequestInFlight = false;
                    if (result && result.data && result.data.length > 0) {
                        $ngRedux.dispatch(setGroupMembersAction(result.data));
                        $rootScope.groupMembers = result.data;
                        service.groupMembers = result.data;
                        service.companyDataReloaded.groupMembers = true;
                        return result.data;
                    } else {
                        $rootScope.groupMembers = [];
                        service.groupMembers = [];
                        return [];
                    }
                }).catch(function () {
                    groupMembersRequestInFlight = false;
                    notifyService.error("controller.dashboardController.Error", "controller.invoiceConfirmationController.Error", true);
                });
            } else {
                return $q.when($rootScope.groupMembers);
            }
        }

        function getArchiveGroupMembers() {
            if (!$rootScope.archiveGroupMembers && !groupArchiveMembersRequestInFlight) {
                // if groupMembers doesn't exist in $rootScope or request is already in flight
                groupArchiveMembersRequestInFlight = true;
                return webServices.getArchiveCompanyGroupMembers().then(function (result) {
                    groupArchiveMembersRequestInFlight = false;
                    if (result && result.data) {
                        $rootScope.archiveGroupMembers = result.data;
                        return result.data;
                    } else {
                        $rootScope.archiveGroupMembers = [];
                        return [];
                    }
                }).catch(function () {
                    groupArchiveMembersRequestInFlight = false;
                    notifyService.error("controller.dashboardController.Error", "controller.invoiceConfirmationController.Error", true);
                });
            } else {
                return $q.when($rootScope.archiveGroupMembers);
            }
        }

        function getCustomCostObjectives(withItemCount, forceFetch, filterBy, accountingDate) {
            const { user: { customCostObjectives, customCostObjectivesFilterLastDate } } = $ngRedux.getState();
            const customCostObjectivesLoaded = customCostObjectives && customCostObjectives.length;
            if (withItemCount) {
                if (forceFetch || ((_.isEmpty(service.customCostObjectivesWithItems) || !service.companyDataReloaded.companyCustomCostObjectivesWithItems) && !templatesWithItemsRequestInFlight)) {
                    if (customCostObjectivesLoaded && isEqual(accountingDate, customCostObjectivesFilterLastDate)) {
                        var def = $q.defer();
                        def.resolve(customCostObjectives);
                        return def.promise;
                    } else {
                        templatesWithItemsRequestInFlight = true;
                        return webServices.getAllCompanyCustomCostObjectivesWithItemCount(accountingDate).then(
                            function (response) {
                                if (response && response.data && response.data.Items) {
                                    service.customCostObjectivesWithItems = filterBy ? filter(response.data.Items, cf => cf[filterBy]) : response.data.Items;
                                    service.companyDataReloaded.companyCustomCostObjectivesWithItems = true;
                                }
                                $ngRedux.dispatch(setCustomCostObjectivesAction(response.data.Items));
                                $ngRedux.dispatch(updateCustomCostObjectivesFilterLastDateAction({ customCostObjectivesFilterLastDate: accountingDate }));
                                templatesWithItemsRequestInFlight = false;
                                return service.customCostObjectivesWithItems;
                            }, function (error) {
                                templatesWithItemsRequestInFlight = false;
                                console.log("Request rejected due to:", error);
                            }
                        );
                    }
                } else {
                    var def = $q.defer();
                    var customCostObjectivesWatcher = $rootScope.$watch(function () {
                        return service.customCostObjectivesWithItems;
                    }, function (newVal) {
                        if (templatesWithItemsRequestInFlight) {
                            if (newVal && !isEmpty(newVal)) {
                                def.resolve(filterBy ? filter(service.customCostObjectivesWithItems, cf => cf[filterBy]) : service.customCostObjectivesWithItems);
                                templatesWithItemsRequestInFlight = false;
                                $ngRedux.dispatch(setCustomCostObjectivesAction(service.customCostObjectivesWithItems));
                                return customCostObjectivesWatcher();
                            }
                        } else {
                            def.resolve(newVal || service.customCostObjectivesWithItems);
                        }
                    });
                    return def.promise;
                }
            } else {
                if (forceFetch || ((_.isEmpty(service.customCostObjectives) || !service.companyDataReloaded.companyCustomCostObjectives) && !templatesRequestInFlight)) {
                    templatesRequestInFlight = true;
                    return webServices.getAllCompanyCustomCostObjectivesWithoutItems().then(
                        function (response) {
                            if (response && response.data && response.data.Result) {
                                service.customCostObjectives = filterBy ? filter(response.data.Result, cf => cf[filterBy]) : response.data.Result;
                                service.companyDataReloaded.companyCustomCostObjectives = true;
                            }
                            templatesRequestInFlight = false;
                            return service.customCostObjectives;
                        }, function (error) {
                            templatesRequestInFlight = false;
                            console.log("Request rejected due to:", error);
                        }
                    )
                } else {
                    templatesRequestInFlight = false;
                    return $q.when(filterBy ? filter(service.customCostObjectives, cf => cf[filterBy]) : service.customCostObjectives);
                }
            }
        }
    }
})();
