import { TFunction } from 'i18next';
import { AutoTransactionDTO, AutoTransactionMatchType, BaseSearch, ExtensionField, SearchType, SortDirection } from '../../services/types/ApiTypes';
import { getMatchTypes } from '../auto-transactions-add/AutoTransactionsAddViewHelper';

export function createRequest(searchValue = '') {
    const search: BaseSearch = {
        Restrictions: [
            {
                Field: 'RuleName',
                Value: searchValue,
                Values: undefined,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        SortItems: [
            {
                SortColumn: 'RuleName',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 15,
        },
    };
    return search;
}

export function getReferenceNumberValue(rule: AutoTransactionDTO, t: TFunction) {
    const referenceNumberTrigger = rule.AutoTransactionsTriggers.find((trigger) => {
        return trigger.ExtensionField === ExtensionField.ReferenceNumber;
    });

    if (referenceNumberTrigger) {
        const matchTypeText = `${t(getMatchTypes()[referenceNumberTrigger.MatchType])}`;
        if ([AutoTransactionMatchType.Empty, AutoTransactionMatchType.NotEmpty].includes(referenceNumberTrigger.MatchType)) {
            // the trigger.Value may still be present, so only display the text
            return matchTypeText;
        }
        return `${matchTypeText} ${referenceNumberTrigger.Value || ''}`;
    }
    return '';
}

export enum ExportTypes {
    CSV = 'Csv',
    XLSX = 'Xlsx',
}
