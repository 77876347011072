import { isEmpty } from 'lodash-es';

import { loadableDataActionsWithRequest } from '../../../common/utils/LoadableData';
import { DispatchThunk } from '../../../storeConfig';
import api from '../../../services/ApiServices';
import { BackOfficeOrganizationDTO } from '../../../services/types/BoApiTypes';
import { BaseSearch, PagedListContainerOf, UserSettingName, SortDirection } from '../../../services/types/ApiTypes';
import { GlobalState } from '../../../rootReducer';
import { getCurrentUserGroupMember } from '../../../common/user/UserActions';
import { getViewUserSearchParams } from '../../../common/user/userSettingUtil';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../common/utils/baseSearchHelpers';

import { selectBOOrganizationSearchParams, DEFAULT_RESTRICTION } from './BOOrganizationListViewReducer';
import { updateBoUserSettingsAction } from '../../../common/middlewares/userSettings';

const ns = 'back-office-organization-list/';

const listViewConfig = {
    sortDir: UserSettingName.BACK_OFFICE_ORGANIZATIONS_SORT_DIRECTION,
    sortCol: UserSettingName.BACK_OFFICE_ORGANIZATIONS_SORT_COLUMN,
    pageSize: UserSettingName.BACK_OFFICE_ORGANIZATIONS_PAGE_SIZE,
};

export const getBOOrganizationListLoadable = loadableDataActionsWithRequest<BaseSearch, PagedListContainerOf<BackOfficeOrganizationDTO>>(`${ns}ALL_BO_ORGANIZATIONS`);

export const searchBOOrganization = (searchParams: BaseSearch) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        let response;
        try {
            if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
                await dispatch(getCurrentUserGroupMember());
            }
            const boOrganizationSearchParams = selectBOOrganizationSearchParams(getState());
            searchParams = searchParams || boOrganizationSearchParams;

            const groupMember = getState().user.groupMemberCommonLoadable.payload;

            const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
            viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
            viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'OrganizationName', SortDirection.Asc);

            searchParams = { ...searchParams, ...viewSearchParams };
            dispatch(getBOOrganizationListLoadable.request(searchParams));
            response = await api.boOrganizations.getOrganizations(searchParams);
            dispatch(
                getBOOrganizationListLoadable.success({
                    result: response.data,
                    request: searchParams,
                }),
            );
        } catch (e) {
            dispatch(getBOOrganizationListLoadable.error(e));
        }
    };
};

export const getBOOrganizationList = (searchString: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        let searchParams = selectBOOrganizationSearchParams(state);
        const paging = searchParams.PagingOptions;
        const searchRestriction = searchParams.Restrictions.find((restriction) => restriction.Field === DEFAULT_RESTRICTION);

        searchParams = {
            ...searchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: [
                {
                    ...searchRestriction,
                    Value: searchString,
                },
            ],
        };
        dispatch(searchBOOrganization(searchParams));
    };
};

export const sortBoOrganizationList = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        let searchParams = selectBOOrganizationSearchParams(getState());
        const sorting = searchParams.SortItems[0];

        searchParams = {
            ...searchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };
        await dispatch(
            updateBoUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        dispatch(searchBOOrganization(searchParams));
    };
};

export function setPagingOptions(page?: number, pageSize?: number) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState): Promise<any> => {
        let searchParams = selectBOOrganizationSearchParams(getState());
        const paging = searchParams.PagingOptions;

        searchParams = {
            ...searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        await dispatch(
            updateBoUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        return dispatch(searchBOOrganization(searchParams));
    };
}
