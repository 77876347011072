import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable1 } from 'redux-dispatchers';

import { formatDate, formatMoneyAndCurrency } from '../../common/utils/formatters';
import { BaseStatefulComponent } from '../../components/BaseStatefulComponent';
import MainComponent from '../../components/MainComponent/MainComponent';
import MainComponentContent from '../../components/MainComponent/MainComponentContent';
import MainComponentHeader from '../../components/MainComponent/MainComponentHeader';
import MainComponentMessage from '../../components/MainComponent/MainComponentMessage';
import MainComponentRow from '../../components/MainComponent/MainComponentRow';
import MainComponentRowActions from '../../components/MainComponent/MainComponentRowActions';
import MainComponentRowAmount from '../../components/MainComponent/MainComponentRowAmount';
import MainComponentRowContent from '../../components/MainComponent/MainComponentRowContent';
import Pager from '../../components/Pager/Pager';
import { BaseSearch, PurchaseOrdersDTO } from '../../services/types/ApiTypes';
import { createRequest } from '../purchase-orders/PurchaseOrdersViewHelper';
import { DashboardPurchaseOrdersState } from './DashboardPurchaseOrdersReducers';
import { Typography } from '../../components/Typography';
import withSuspense from '../../common/hocs/withSuspense';
import { eventTrack } from '../../common/analytics/gtm';

import './DashboardPurchaseOrders.scss';

export interface Props extends Pick<DashboardPurchaseOrdersState, 'dashboardPurchaseOrdersLoadable'> {}

export interface DispatchProps {
    getPurchaseOrdersToConfirm: Dispatchable1<BaseSearch>;
}

export type DashboardPurchaseOrdersProps = Props & DispatchProps & WithTranslation;

export class DashboardPurchaseOrders extends BaseStatefulComponent<DashboardPurchaseOrdersProps, DashboardPurchaseOrdersState> {
    componentDidMount() {
        this.props.getPurchaseOrdersToConfirm(undefined);
    }

    handlePageChange = (page: number) => {
        const { payload } = this.props.dashboardPurchaseOrdersLoadable;
        const searchParams = createRequest('', page, payload.Take);
        this.props.getPurchaseOrdersToConfirm(searchParams);
    };

    handleClick = (po: PurchaseOrdersDTO) => {
        eventTrack({
            event: 'dashboard_purchase_orders_to_confirm',
            label: 'Open purchase order',
        });
        window.location.href = `#/purchase-orders/details/${po.Id}`;
    };

    render() {
        const { dashboardPurchaseOrdersLoadable, t } = this.props;
        return (
            <MainComponent
                loading={dashboardPurchaseOrdersLoadable.loading}
                empty={!dashboardPurchaseOrdersLoadable.loading && dashboardPurchaseOrdersLoadable.payload && !dashboardPurchaseOrdersLoadable.payload.Items.length}
            >
                <MainComponentHeader>
                    <Typography element="span" variant="h1">
                        {t('view.Dashboard.PurchaseOrders.OrdersToConfirm')} ({dashboardPurchaseOrdersLoadable.payload && dashboardPurchaseOrdersLoadable.payload.TotalCount})
                    </Typography>
                </MainComponentHeader>
                <MainComponentContent>
                    {dashboardPurchaseOrdersLoadable.payload && dashboardPurchaseOrdersLoadable.payload.Items.length ? (
                        dashboardPurchaseOrdersLoadable.payload.Items.map((po, i) => {
                            return (
                                <MainComponentRow key={`${po.OrderType}+${i}`}>
                                    <MainComponentRowContent>
                                        <span className="table-main__row-supplier">{(po.Supplier && po.Supplier.Name) || t('view.Dashboard.PurchaseOrders.NoSupplier')}</span>
                                        <span className="table-main__row-description">{po.OrderNumber}</span>
                                        <span className="table-main__row-date">{formatDate(po.DateCreated)}</span>
                                    </MainComponentRowContent>
                                    <MainComponentRowAmount>
                                        <span>{formatMoneyAndCurrency(po.Total || 0, po.Currency)}</span>
                                    </MainComponentRowAmount>
                                    <MainComponentRowActions>
                                        <button className={'btn btn--md btn--primary'} onClick={() => this.handleClick(po)}>
                                            {t('views.invoice.IvoiceFastApprove')}
                                        </button>
                                    </MainComponentRowActions>
                                </MainComponentRow>
                            );
                        })
                    ) : (
                        <MainComponentMessage>{t('view.Dashboard.PurchaseOrders.NothingToConfirm')}</MainComponentMessage>
                    )}
                </MainComponentContent>
                <div className="pagination__wrap">
                    <Pager showExtremes={false} closedArrows={false} pages={dashboardPurchaseOrdersLoadable.payload} onPageChange={this.handlePageChange} hidePageSize={true} />
                </div>
            </MainComponent>
        );
    }
}

export default withSuspense(withTranslation()(DashboardPurchaseOrders));
