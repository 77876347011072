import React from 'react';
import { TaskDTO, UserDataDTO } from '../../../../../services/types/ApiTypes';
import { formatDate } from '../../../../../common/utils/formatters';
import { canUserAddBeforeOrAfter } from '../InvoiceConfirmationsHelper';
import { Button, ButtonType } from '../../../../../components/Buttons/Button';
import { ICONS, IconSize, IconType } from '../../../../../components/Icon/Icon';
import { TaskType } from '../InvoiceConfirmations';
import { TFunction } from 'i18next';

export interface TaskItemContentRightProps {
    taskItem: TaskDTO;
    hasAddBefore: boolean;
    hasAddAfter: boolean;
    userData: UserDataDTO;
    addApprover: (taskItem: TaskDTO, type: TaskType) => void;
    byWhom?: boolean;
    t?: TFunction;
}

/**
 * Render the content of the right-hand-side container of TaskItem
 * IF task is already completed then render the timestamp when it was completed
 * IF task is not completed yet but current user can add approver/confirmer before or after them then display the buttons that allow this
 * ELSE render nothing
 */
const TaskItemContentRight = (props: TaskItemContentRightProps) => {
    const { hasAddAfter, hasAddBefore, taskItem, userData, addApprover, byWhom, t } = props;

    if (byWhom && t) {
        if (taskItem.CreatorName && !taskItem.Completed) {
            return (
                <>
                    {t('component.commentInfo.by')} {taskItem.CreatorName}
                </>
            );
        } else {
            return null;
        }
    } else if (taskItem.CompletedDate) {
        return <> {formatDate(taskItem.CompletedDate, 'dd.MM.yy • HH:mm')} </>;
    } else if (taskItem.displayAddAfterMeProp && taskItem.displayAddBeforeMeProp && canUserAddBeforeOrAfter(taskItem, userData)) {
        return (
            <div className="confirmation__task-item-wrap">
                {!hasAddBefore && (
                    <Button buttonType={ButtonType.ICON_SQUARE} icon={ICONS.USER_UP} iconSize={IconSize.SM} iconType={IconType.WIDE} onClick={() => addApprover(taskItem, TaskType.AddBeforeMe)} />
                )}
                {!hasAddAfter && (
                    <Button buttonType={ButtonType.ICON_SQUARE} icon={ICONS.USER_DOWN} iconSize={IconSize.SM} iconType={IconType.WIDE} onClick={() => addApprover(taskItem, TaskType.AddAfterMe)} />
                )}
            </div>
        );
    }
    return null;
};

export default TaskItemContentRight;
