import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

import { getViewUserSearchParams, ListViewUserConfig } from '../../../common/user/userSettingUtil';
import { FileReaderResult } from '../../../common/utils/fileReader';
import { notify } from '../../../common/utils/notify';
import { loadableDataActions, loadableDataActionsWithRequest } from '../../../common/utils/LoadableData';
import { saveFileFromResponse } from '../../../common/utils/saveFileFromResponse';
import i18nInstance from '../../../i18n';
import { GlobalState } from '../../../rootReducer';
import { AccountDTO, BaseSearch, FileDTO, PagedListContainer, Restriction, SortDirection, UserSettingName } from '../../../services/types/ApiTypes';
import api from '../../../services/ApiServices';
import { DispatchThunk } from '../../../storeConfig';
import { isEmpty } from 'lodash-es';
import { getCurrentUserGroupMember } from '../../../common/user/UserActions';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../common/utils/baseSearchHelpers';
import { updateAppUserSettingsAction } from '../../../common/middlewares/userSettings';

const ns = 'settings/account-details/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.SETTINGS_ACCOUNTING_ACCOUNTS_SORT_DIRECTION,
    sortCol: UserSettingName.SETTINGS_ACCOUNTING_ACCOUNTS_SORT_COLUMN,
    pageSize: UserSettingName.SETTINGS_ACCOUNTING_ACCOUNTS_PAGE_SIZE,
};

export const setPagingOptions = (page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState): Promise<any> => {
        const { accountDetails } = getState();
        const paging = accountDetails.accountsSearchParams.PagingOptions;

        const searchParams: BaseSearch = {
            ...accountDetails.accountsSearchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };

        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        return dispatch(searchAccounts(searchParams));
    };
};

export const loadAccountsLoadableActions = loadableDataActionsWithRequest<BaseSearch, PagedListContainer<AccountDTO>>(`${ns}LOAD_ACCOUNTS`);

export function searchAccounts(searchParams?: BaseSearch | undefined) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const {
            accountDetails,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        searchParams = searchParams || accountDetails.accountsSearchParams;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Code', SortDirection.Asc);

        searchParams = { ...searchParams, ...viewSearchParams };

        let response;
        try {
            dispatch(loadAccountsLoadableActions.request(searchParams));
            response = await api.account.getAccounts(searchParams);
            dispatch(
                loadAccountsLoadableActions.success({
                    request: searchParams,
                    result: response.data,
                }),
            );
            // if our page for some reason is empty, but there is data on previous pages, then load previous pages until we have some data to display
            if (response.data.Items.length === 0 && response.data.HasCount) {
                const currentPage = accountDetails.accountsSearchParams.PagingOptions.Page;
                if (currentPage > 1) {
                    return dispatch(setPagingOptions(currentPage - 1));
                }
            }
            return Promise.resolve();
        } catch (e) {
            console.error(e);
            dispatch(
                loadAccountsLoadableActions.error({
                    request: searchParams,
                    result: e,
                }),
            );
            return Promise.resolve();
        }
    };
}

export const filterAccounts = (searchString: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.accountDetails.accountsSearchParams.PagingOptions;
        const searchRestriction = state.accountDetails.accountsSearchParams.Restrictions[0];
        const searchParams: BaseSearch = {
            ...state.accountDetails.accountsSearchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: [
                {
                    ...searchRestriction,
                    Value: searchString,
                },
            ],
        };
        dispatch(searchAccounts(searchParams));
    };
};

export const sortAccounts = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { accountDetails } = getState();
        const sorting = accountDetails.accountsSearchParams.SortItems[0];

        const searchParams: BaseSearch = {
            ...accountDetails.accountsSearchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };

        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        dispatch(searchAccounts(searchParams));
    };
};

export const addNewRow = createAction(`${ns}ADD_NEW_ROW`);

export const gotoLastPageAndAddNewRow = () => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const payload = state.accountDetails.accountsLoadable.payload;
        const lastPage = Math.ceil(payload.TotalCount / payload.Take) || 1;
        const currentPage = payload.Skip / payload.Take + 1;
        if (lastPage !== currentPage) {
            await dispatch(setPagingOptions(lastPage));
        }
        dispatch(addNewRow());
    };
};

export const updateRow = createAction<{ result: AccountDTO; itemToUpdate: number }>(`${ns}UPDATE_ROW`);

export const saveRow = (item: AccountDTO) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const backupItem = getState().accountDetails.accountsLoadable.payload.Items.find((acc) => acc.Id === item.Id);
        try {
            let result;
            if (item.Id > 0) {
                dispatch(
                    updateRow({
                        result: item,
                        itemToUpdate: item.Id,
                    }),
                );
                await api.account.editAccount(item);
            } else {
                result = await api.account.addAccount(item);

                dispatch(
                    updateRow({
                        result: result.data,
                        itemToUpdate: item.Id,
                    }),
                );
            }
        } catch (e) {
            console.error(e);
            if (backupItem) {
                dispatch(
                    updateRow({
                        result: backupItem,
                        itemToUpdate: backupItem.Id,
                    }),
                );
            }
            notify.info(i18nInstance.t('view.Accounting.RowSavingFailed'));
        }
    };
};

export const removeRow = createAction<number>(`${ns}REMOVE_ROW`);

export const deleteRow = (id: number) => {
    return async (dispatch: DispatchThunk) => {
        try {
            if (id > 0) {
                await api.account.deleteAccount(id);
            }
            dispatch(removeRow(id));
            dispatch(searchAccounts()); // lets reload to keep the item count on page up-to-date
        } catch (e) {
            console.error(e);
        }
    };
};

export function uploadCSV(fileReaderResult: FileReaderResult) {
    return async (dispatch: DispatchThunk) => {
        try {
            const file: FileDTO = {
                Base64Content: fileReaderResult.base64Content,
                FileName: fileReaderResult.fileName,
                WorkflowDocumentId: undefined,
                DownloadUrl: undefined,
                FileUrl: undefined,
                ForceImport: undefined,
                HubType: undefined,
                Id: undefined,
                IsImported: undefined,
                IsNew: undefined,
                IsPdf: undefined,
                PathToFile: undefined,
            };

            await api.account.uploadCSVFile(file);
            dispatch(setPagingOptions(1));
        } catch (e) {
            console.error(e);
        }
    };
}

export const exportAccountsToCSVloadableActions = loadableDataActions(`${ns}EXPORT_ACCOUNTS_TO_CSV`);

export const exportAccountsToCSV = (restrictions: Restriction[]) => {
    return async (dispatch: Dispatch) => {
        dispatch(exportAccountsToCSVloadableActions.request(null));
        try {
            const response = await api.account.exportAccountsToCSV(restrictions);
            dispatch(exportAccountsToCSVloadableActions.success(null));
            saveFileFromResponse(response, { namePrefix: 'Accounts', fileExtension: 'csv' });
        } catch (e) {
            dispatch(exportAccountsToCSVloadableActions.error(e));
            console.error(e);
        }
    };
};

export const exportAccountsToXLSXloadableActions = loadableDataActions(`${ns}EXPORT_ACCOUNTS_TO_XLSX`);

export const exportAccountsToXLSX = (restrictions: Restriction[]) => {
    return async (dispatch: Dispatch) => {
        dispatch(exportAccountsToXLSXloadableActions.request(null));

        try {
            const response = await api.account.exportAccountsToXLSX(restrictions);
            dispatch(exportAccountsToXLSXloadableActions.success(null));
            saveFileFromResponse(response, { namePrefix: 'Accounts', fileExtension: 'xlsx' });
        } catch (e) {
            dispatch(exportAccountsToXLSXloadableActions.error(e));
            console.error(e);
        }
    };
};
