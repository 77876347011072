import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Button, ButtonType } from '../Buttons/Button';
import { createDataId } from '../../common/utils/dataId';
import { ICONS } from '../Icon/Icon';
import { formatDate } from '../../common/utils/formatters';
import { TextInput, TextInputType } from '../TextInput/TextInput';
import CalendarDateRangeModal, { areDateRangeValuesEmpty, DateRangeValues, Props as CalendarDateRangeModalProps } from '../../components/CalendarDateRangeModal/CalendarDateRangeModal';
import { SelectOptionValue } from '../Select/SelectOption';
import { addDaysFromDate, getDifferenceInDays, removeTimeZone, subtractDaysFromDate } from '../../common/utils/datetime';

interface Props extends WithTranslation, Pick<CalendarDateRangeModalProps, 'classNameModal' | 'commitOnChange' | 'showDoubleView'> {
    wrapperClass?: string;
    dataId: string;
    datePickerActiveScope: string;
    datePickerScopeList?: SelectOptionValue<string>[];
    dateRange: [Date, Date];
    label: string;
    placeholder?: string;
    onChange: (val: DateRangeValues | { scope?: string; value: [string, string] }) => void;
    render?: (props: RenderProps) => React.ReactNode;
}

interface RenderProps {
    modal: {
        open: VoidFunction;
        close: VoidFunction;
        isOpen: boolean;
    };
}

const DateRangeFilter = ({
    wrapperClass,
    dataId,
    datePickerActiveScope,
    datePickerScopeList,
    dateRange,
    label,
    placeholder,
    onChange,
    t,
    render,
    commitOnChange,
    showDoubleView,
    classNameModal,
}: Props) => {
    const [isDateRangeOpen, setIsDateRangeOpen] = useState<boolean>(false);
    const [rangeInDays, setRangeInDays] = useState<number>();

    useEffect(() => {
        if (dateRange[0] && dateRange[1]) {
            setRangeInDays(getDifferenceInDays(new Date(dateRange[1]), new Date(dateRange[0])));
        }
    }, [dateRange]);

    const parseDate = (val: DateRangeValues) => {
        onChange({ scope: val.scope, value: val.value.map((v) => removeTimeZone(v)) as [string, string], range: val.range });
    };

    const setDate = (val: [Date, Date]) => {
        return val.map((v) => new Date(v)) as [Date, Date];
    };

    const shiftRange = (isNext?: boolean) => {
        let firstDate: Date, secondDate: Date;
        if (isNext) {
            firstDate = addDaysFromDate(new Date(dateRange[1]), 1);
            secondDate = addDaysFromDate(firstDate, rangeInDays);
        } else {
            secondDate = subtractDaysFromDate(new Date(dateRange[0]), 1);
            firstDate = subtractDaysFromDate(secondDate, rangeInDays);
        }
        parseDate({ value: [firstDate, secondDate] });
    };

    const isDateShiftDisabled = () => !(dateRange.length && dateRange[0] && dateRange[1]);
    const handleModalClose = () => {
        setIsDateRangeOpen(false);
    };

    const handleModalOpen = () => {
        setIsDateRangeOpen(true);
    };
    return (
        <div className={`${wrapperClass || ''} named-block`}>
            {!render && <h4>{label}</h4>}
            <div className="named-block__subblock">
                {render ? (
                    render({ modal: { open: handleModalOpen, close: handleModalClose, isOpen: isDateRangeOpen } })
                ) : (
                    <>
                        <Button
                            className="date-arrow-btn"
                            onClick={() => shiftRange()}
                            disabled={isDateShiftDisabled()}
                            buttonType={ButtonType.ICON}
                            dataId={createDataId(dataId, 'date-filter-prev')}
                            icon={ICONS.CHEVRON_LEFT}
                        ></Button>
                        <Button
                            className="date-arrow-btn"
                            onClick={() => shiftRange(true)}
                            disabled={isDateShiftDisabled()}
                            buttonType={ButtonType.ICON}
                            dataId={createDataId(dataId, 'date-filter-next')}
                            icon={ICONS.CHEVRON_RIGHT}
                        ></Button>
                        <TextInput
                            placeholder={placeholder || t('component.invoiceFilter.customDates')}
                            dataId={createDataId(dataId, 'text-input')}
                            onChange={(e) => {
                                e.stopPropagation();
                                onChange({ value: [null, null] });
                            }}
                            onKeyDown={handleModalOpen}
                            value={!areDateRangeValuesEmpty(dateRange) && `${formatDate(dateRange?.[0])} - ${formatDate(dateRange?.[1])}`}
                            type={TextInputType.DATE_PICKER}
                            showClear
                            icon={areDateRangeValuesEmpty(dateRange) && ICONS.CALENDAR}
                            onClick={handleModalOpen}
                        />
                    </>
                )}

                {isDateRangeOpen && (
                    <CalendarDateRangeModal
                        classNameModal={classNameModal}
                        scope={datePickerActiveScope}
                        scopeOptions={datePickerScopeList}
                        value={!areDateRangeValuesEmpty(dateRange) ? setDate(dateRange) : dateRange}
                        onChange={parseDate}
                        onClose={handleModalClose}
                        commitOnChange={commitOnChange}
                        showDoubleView={showDoubleView}
                    />
                )}
            </div>
        </div>
    );
};

export default withTranslation()(DateRangeFilter);
