'use strict';

angular.module('dstreamApp.services').factory('utils', [function () {
    var utils = {};

    utils.getToday = function () {
        return new Date();
    };

    utils.isDate = function (obj) {
        return obj instanceof Date && !isNaN(obj.valueOf());
    };

    utils.getBeginningOfTimes = function () {
        return new Date(1970, 1, 1);
    };

    utils.getXDaysAgo = function (days) {
        var d = new Date();
        var day = new Date(d);
        return new Date(day.setDate(d.getDate() - days));
    };

    utils.getYesterday = function () {
        var d = new Date();
        var day = new Date(d);
        return new Date(day.setDate(d.getDate() - 1));
    };

    utils.getSevenDaysAgo = function () {
        var d = new Date();
        var day = new Date(d);
        return new Date(day.setDate(d.getDate() - 7));
    };

    utils.getNextDay = function (d) {
        return new Date(d).addDays(1);
    };

    utils.getPreviousDay = function (d) {
        return new Date(d).addDays(-1);
    };

    utils.shiftForward = function (s, e) {
        s = new Date(s);
        e = new Date(e);
        var utc1 = Date.UTC(s.getFullYear(), s.getMonth(), s.getDate());
        var utc2 = Date.UTC(e.getFullYear(), e.getMonth(), e.getDate());
        var diff = Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24)) + 1;
        return e.addDays(diff);
    };

    utils.shiftBack = function (s, e) {
        s = new Date(s);
        e = new Date(e);
        var utc1 = Date.UTC(s.getFullYear(), s.getMonth(), s.getDate());
        var utc2 = Date.UTC(e.getFullYear(), e.getMonth(), e.getDate());
        var diff = Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24)) + 1;
        return s.addDays(-diff);
    };

    utils.getFirstDateOfWeek = function (d) {
        d = new Date(d);
        var day = d.getDay(),
			diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
        //return new Date(date.setDate(date.getDate() - date.getDay() - 1));
    };

    utils.getLastDateOfWeek = function (date) {
        return utils.getFirstDateOfWeek(date).addDays(6);
    };

    utils.getFirstDateOfMonth = function (date) {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    };

    utils.getFirstDateOfYear = function (date) {
        return new Date(date.getFullYear(), 0, 1);
    };

    utils.getFirstDateOfNextMonth = function (date) {
        date = new Date(date);
        return new Date(date.getFullYear(), date.getMonth() + 1, 1);
    };

    utils.getFirstDateOfNextYear = function (date) {
        date = new Date(date);
        return new Date(date.getFullYear() + 1, 0, 1);
    };

    utils.getLastDateOfNextMonth = function (date) {
        date = new Date(date);
        return new Date(date.getFullYear(), date.getMonth() + 2, 0);
    };

    utils.getLastDateOfNextYear = function (date) {
        date = new Date(date);
        return new Date(date.getFullYear() + 1, 11, 31);
    };

    utils.getFirstDateOfPreviousMonth = function (date) {
        date = new Date(date);
        return new Date(date.getFullYear(), date.getMonth() - 1, 1);
    };

    utils.getFirstDateOfPreviousYear = function (date) {
        date = new Date(date);
        return new Date(date.getFullYear() - 1, 0, 1);
    };

    utils.getLastDateOfPreviousMonth = function (date) {
        date = new Date(date);
        return new Date(date.getFullYear(), date.getMonth(), 0);
    };

    utils.getLastDateOfPreviousYear = function (date) {
        date = new Date(date);
        return new Date(date.getFullYear() - 1, 11, 31);
    };

    utils.getLastDateOfMonth = function (date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    };

    utils.getLastDateOfYear = function (date) {
        return new Date(date.getFullYear() + 1, 11, 31);
    };

    utils.getFirstDateOfYear = function (date) {
        return new Date(date.getFullYear(), 0, 1);
    };

    utils.getLastDateOfYear = function (date) {
        return new Date(date.getFullYear(), 11, 31);
    };

    utils.isEmpty = function (item) {
        return (item === null || item === undefined || item.length == 0);
    };

    utils.isEmptyObject = function (obj) {
        // null and undefined are "empty"
        if (obj == null) return true;

        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0) return false;
        if (obj.length === 0) return true;

        // If it isn't an object at this point
        // it is empty, but it can't be anything *but* empty
        // Is it empty?  Depends on your application.
        if (typeof obj !== "object") return true;

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (var key in obj) {
            if (hasOwnProperty.call(obj, key)) return false;
        }

        return true;
    };

    utils.isEmptyOrZero = function (item) {
        return utils.isEmpty(item) || item == 0;
    };

    utils.isEmptyOrMoreThan = function (item, item1) {
        return utils.isEmpty(item) || item > item1;
    };

    utils.isNotNumber = function (item) {
        return utils.isEmpty(item) || isNaN(item);
    };

    utils.isNumberInRange = function (item, min, max) {
        return !utils.isNotNumber(item) && item >= min && item <= max;
    };

    utils.isValidEmail = function (email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    utils.getBase64String = function (input) {
        var binary_string = ''
        var bytes = new Uint8Array(input);
        for (var i = 0; i < bytes.byteLength; i++) {
            binary_string += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary_string);
    };

    utils.sum = function (items, prop) {
        if (items != null) {
            return items.reduce(function (a, b) {
                return a + b[prop];
            }, 0);
        }
        return 0;
    };

    return utils;
}
]);