import { AxiosInstance, AxiosResponse } from 'axios';
import { BackOfficeDomainDTO } from '../types/BoApiTypes';
import { BaseSearch, PagedListContainerOf } from '../types/ApiTypes';

export default class BoDomainsService {
    constructor(private axios: AxiosInstance) {}

    getDomains = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainerOf<BackOfficeDomainDTO>>> => {
        return this.axios.post('/Domain/GetDomains', searchParams);
    };

    saveDomain = async (integrator: BackOfficeDomainDTO): Promise<AxiosResponse<BackOfficeDomainDTO>> => {
        return this.axios.post('/Domain/SaveDomain', integrator);
    };

    getAllDomains = async (): Promise<AxiosResponse<BackOfficeDomainDTO[]>> => {
        return this.axios.get('/Domain/GetAllDomains');
    };
}
