import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';

import { DefaultTimeRange, getDefaultRangeValues } from '../../common/utils/datetime';
import { Button, ButtonIconPlacement, ButtonType } from '../Buttons/Button';
import CalendarDatePicker from '../CalendarDatePicker/CalendarDatePicker';
import { ICONS } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import { Select } from '../Select/Select';
import { SelectOptionValue } from '../Select/SelectOption';
import { LabelStyle, TextInputType } from '../TextInput/TextInput';

import './CalendarDateRangeModal.scss';

export type DateRangeValues = Pick<Props, 'scope' | 'value'> & { range?: DefaultTimeRange };

export const areDateRangeValuesEmpty = (value: DateRangeValues['value']) => !(value?.[0] && value?.[1]);

export interface Props extends WithTranslation {
    onClose: VoidFunction;
    onChange: (v: DateRangeValues) => void;
    scopeOptions?: SelectOptionValue<string>[];
    value: [Date, Date];
    isOpen?: boolean;
    scope?: string;
    commitOnChange?: boolean;
    showDoubleView?: boolean;
    classNameModal?: string;
}

type DefaultTimeRangeWithValues = {
    [key in DefaultTimeRange]: [Date, Date];
};

const getDefaultTimeRangesValues = () => {
    const ranges = {} as DefaultTimeRangeWithValues;
    Object.keys(DefaultTimeRange).forEach((k) => {
        if (k === DefaultTimeRange.CUSTOM) {
            ranges[DefaultTimeRange[k]] = null;
        } else {
            ranges[DefaultTimeRange[k]] = getDefaultRangeValues(DefaultTimeRange[k]);
        }
    });
    return ranges;
};

const CalendarDateRangeModal = (props: Props) => {
    const [range, setRange] = useState<DefaultTimeRange>(DefaultTimeRange.TODAY); // active range from default buttons
    const [calendarRange, setCalendarRange] = useState<[Date, Date]>([new Date(), new Date()]);
    const [scope, setScope] = useState<string>();
    const [defaultTimeRangesValues] = useState<DefaultTimeRangeWithValues>(getDefaultTimeRangesValues());

    useEffect(() => {
        props.scope && setScope(props.scope);
    }, [props.scope]);

    const checkForCustomRange = (values: [Date, Date]) => {
        let defaultRange = DefaultTimeRange.CUSTOM;
        Object.keys(defaultTimeRangesValues).forEach((key) => {
            const defaultValue = defaultTimeRangesValues[key];
            const isDefault = values[0].toDateString() === defaultValue?.[0].toDateString() && values[1].toDateString() === defaultValue?.[1].toDateString();
            if (isDefault) {
                defaultRange = DefaultTimeRange[key];
            }
        });
        setRange(defaultRange);
    };

    const changeCalendarRange = (values: [Date, Date]) => {
        if (values) {
            setCalendarRange(values);
            checkForCustomRange(values);
            if (props.commitOnChange) {
                props.onChange({ value: [values[0], values[1]], scope, range: DefaultTimeRange.CUSTOM });
                props.onClose();
            }
        }
    };

    useEffect(() => {
        if (props.value[0] && props.value[1]) {
            setCalendarRange([new Date(props.value[0]), new Date(props.value[1])]);
            checkForCustomRange(props.value);
        }
    }, [props.value]);

    const onDefaultRangeClick = (range: DefaultTimeRange) => {
        const defaultRangeValues = getDefaultRangeValues(range);
        setRange(range);
        setCalendarRange(defaultRangeValues);
        if (props.commitOnChange) {
            props.onChange({ value: [defaultRangeValues[0], defaultRangeValues[1]], scope, range });
            props.onClose();
        }
    };

    const onChange = () => {
        props.onChange({ value: [calendarRange[0], calendarRange[1]], scope, range });
        props.onClose();
    };

    return (
        <Modal className={classNames('calendar-range-wrapper', props.classNameModal)} isOpen onClose={props.onClose} noCloseButton dataId="calendar-date-range">
            <div className="default-ranges-block">
                <ul className="time-ranges">
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.TODAY)} isActive={range === DefaultTimeRange.TODAY} buttonType={ButtonType.TEXT}>
                            {props.t('component.invoiceFilter.today')}
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.YESTERDAY)} isActive={range === DefaultTimeRange.YESTERDAY} buttonType={ButtonType.TEXT}>
                            {props.t('component.invoiceFilter.yesterday')}
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.TOMORROW)} isActive={range === DefaultTimeRange.TOMORROW} buttonType={ButtonType.TEXT}>
                            {props.t('component.invoiceFilter.tomorrow')}
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.THIS_WEEK)} isActive={range === DefaultTimeRange.THIS_WEEK} buttonType={ButtonType.TEXT}>
                            {props.t('component.invoiceFilter.thisWeek')}
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.LAST_WEEK)} isActive={range === DefaultTimeRange.LAST_WEEK} buttonType={ButtonType.TEXT}>
                            {props.t('component.invoiceFilter.lastWeek')}
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.NEXT_WEEK)} isActive={range === DefaultTimeRange.NEXT_WEEK} buttonType={ButtonType.TEXT}>
                            {props.t('component.invoiceFilter.nextWeek')}
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.THIS_MONTH)} isActive={range === DefaultTimeRange.THIS_MONTH} buttonType={ButtonType.TEXT}>
                            {props.t('component.invoiceFilter.currentMonth')}
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.LAST_MONTH)} isActive={range === DefaultTimeRange.LAST_MONTH} buttonType={ButtonType.TEXT}>
                            {props.t('component.invoiceFilter.lastMonth')}
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.NEXT_MONTH)} isActive={range === DefaultTimeRange.NEXT_MONTH} buttonType={ButtonType.TEXT}>
                            {props.t('component.invoiceFilter.nextMonth')}
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.LAST_90_DAYS)} isActive={range === DefaultTimeRange.LAST_90_DAYS} buttonType={ButtonType.TEXT}>
                            {`${props.t('component.invoiceFilter.last')} 90 ${props.t('component.invoiceFilter.days')}`}
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.LAST_180_DAYS)} isActive={range === DefaultTimeRange.LAST_180_DAYS} buttonType={ButtonType.TEXT}>
                            {`${props.t('component.invoiceFilter.last')} 180 ${props.t('component.invoiceFilter.days')}`}
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.THIS_YEAR)} isActive={range === DefaultTimeRange.THIS_YEAR} buttonType={ButtonType.TEXT}>
                            {props.t('component.invoiceFilter.thisYear')}
                        </Button>
                    </li>
                    <li>
                        <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.LAST_YEAR)} isActive={range === DefaultTimeRange.LAST_YEAR} buttonType={ButtonType.TEXT}>
                            {props.t('component.invoiceFilter.lastYear')}
                        </Button>
                    </li>
                    {!props.showDoubleView && (
                        <li>
                            <Button onClick={() => onDefaultRangeClick(DefaultTimeRange.CUSTOM)} isActive={range === DefaultTimeRange.CUSTOM} buttonType={ButtonType.TEXT}>
                                {props.t('component.invoiceFilter.customRange')}
                            </Button>
                        </li>
                    )}
                </ul>
            </div>
            <div className="calendar-block">
                <div className="calendar-date-picker">
                    {!!props.scopeOptions?.length && (
                        <Select
                            dataId="scope-select"
                            disabled={props.scopeOptions.length <= 1}
                            onChange={(o) => setScope(o.value)}
                            label={props.t('component.calendar.scope')}
                            labelStyle={LabelStyle.UPPERCASE}
                            isCompact
                            items={props.scopeOptions}
                            value={props.scopeOptions.find((o) => o.value === scope)}
                        ></Select>
                    )}
                    <CalendarDatePicker
                        alwaysOpen
                        isRange
                        showDoubleView={props.showDoubleView}
                        label={props.t('component.calendar.dateFrom')}
                        label2={props.t('component.calendar.dateTo')}
                        value={calendarRange}
                        dataId="calendar"
                        inputProps={{
                            labelStyle: LabelStyle.UPPERCASE,
                            type: TextInputType.COMPACT,
                        }}
                        onChange={(values: [Date, Date]) => changeCalendarRange(values)}
                    />
                </div>
                {!props.commitOnChange && (
                    <div className="action-buttons">
                        <Button buttonType={ButtonType.ICON_TEXT} icon={ICONS.CLOSE_SMALL} iconPlacement={ButtonIconPlacement.LEFT} onClick={props.onClose}>
                            {props.t('views.global.Cancel')}
                        </Button>
                        <Button buttonType={ButtonType.ICON_TEXT} icon={ICONS.FILTER_SMALL} iconPlacement={ButtonIconPlacement.LEFT} onClick={onChange}>
                            {props.t('views.global.ApplyFilter')}
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default withTranslation()(CalendarDateRangeModal);
