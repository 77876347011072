import classNames from 'classnames';
import { debounce } from 'lodash-es';
import * as React from 'react';

import { BaseStatefulComponent } from '../BaseStatefulComponent';

import Tooltip from './Tooltip';
import './OverflowTooltip.scss';

type TooltipContentType = ((isOverflowing: boolean) => React.ReactNode) | React.ReactNode;

export interface OverflowTooltipProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
    tooltipContent?: TooltipContentType;
    open?: boolean;
    appendTo?: Element | ((ref: Element) => Element);
}

export interface OverflowTooltipState {
    isOverflowing: boolean;
}

export class OverflowTooltip extends BaseStatefulComponent<OverflowTooltipProps, OverflowTooltipState> {
    private contentElement = React.createRef<HTMLSpanElement>();
    state = {
        isOverflowing: false,
    };

    componentDidMount() {
        window.setTimeout(this.handleLabelOverflow, 100);

        window.addEventListener('resize', this.debounceOnResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debounceOnResize);
    }

    componentDidUpdate() {
        this.handleLabelOverflow();
    }

    handleLabelOverflow = () => {
        const contentElementNode = this.contentElement.current;

        if (contentElementNode) {
            const isOverflowing = contentElementNode.offsetWidth < contentElementNode.scrollWidth;

            this.setState({
                isOverflowing,
            });
        }
    };

    isTooltipContentFunction(tooltipContent: TooltipContentType): tooltipContent is (isOverflowing: boolean) => void {
        return typeof tooltipContent === 'function';
    }

    debounceOnResize = debounce(this.handleLabelOverflow, 500);

    render() {
        const { children, tooltipContent, appendTo, style } = this.props;
        const { isOverflowing } = this.state;

        const content: any = this.isTooltipContentFunction(tooltipContent) ? tooltipContent(isOverflowing) : isOverflowing ? children : undefined;
        const classes = classNames('overflow-tooltip', { 'overflow-tooltip--is-overflowing': isOverflowing });
        return (
            <Tooltip appendTo={appendTo || document.body} content={content}>
                <span ref={this.contentElement} className={classes} style={style}>
                    {children}
                </span>
            </Tooltip>
        );
    }
}

export default OverflowTooltip;
