import { push } from 'connected-react-router';
import { saveAs } from 'file-saver';
import { isEmpty, flatten, isNull } from 'lodash-es';
import { createAction } from 'redux-actions';

import { updateAppUserSettingsAction } from '../../common/middlewares/userSettings';
import { paths, resolvePathParams } from '../../common/router/routePaths';
import { getCurrentUserGroupMember, updateUserSettings } from '../../common/user/UserActions';
import { getUserSettingValue, getViewUserSearchParams, ListViewUserConfig, setUserSettingValue } from '../../common/user/userSettingUtil';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../common/utils/baseSearchHelpers';
import { DefaultTimeRange, getDefaultRangeValues, removeTimeZone } from '../../common/utils/datetime';
import { formatDate } from '../../common/utils/formatters';
import { loadableDataActions, loadableDataActionsWithRequest } from '../../common/utils/LoadableData';
import { TableFilter } from '../../components/Table/components/filter/TableFilters';
import { TableColumnsConfigurableItem } from '../../components/Table/components/TableColumnsConfigurable/TableColumnsConfigurableContext';
import { GlobalState } from '../../rootReducer';
import api from '../../services/ApiServices';
import {
    ApiResultOfListOfPurchaseOrdersForInvoiceDTO,
    GetPurchaseOrdersForInvoiceParams,
    PagedListContainer,
    PurchaseOrdersDTO,
    PurchaseOrderStatus,
    Restriction,
    SearchType,
    SortDirection,
    UserSettingName,
} from '../../services/types/ApiTypes';
import { DispatchThunk } from '../../storeConfig';
import currentLanguage from '..//../common/utils/languageHelper';

import { DATE_RESTRICTION, DEFAULT_RESTRICTION, DEFAULT_SORT_COLUMN, getColumnName } from './PurchaseOrdersViewHelper';
import { POSearchParams } from './PurchaseOrdersViewReducer';

const ns = 'purchase-orders/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.PURCHASE_ORDERS_SORT_DIRECTION,
    sortCol: UserSettingName.PURCHASE_ORDERS_SORT_COLUMN,
    pageSize: UserSettingName.PURCHASE_ORDERS_PAGE_SIZE,
};

export const setUserTouchedStatusFilter = createAction(`${ns}SET_USER_TOUCHED_STATUS_FILTER`);
export const linkPurchaseOrderToInvoiceAction = createAction(`${ns}LINK_PURCHASE_ORDER_TO_INVOICE`);
export const unlinkPurchaseOrderFromInvoiceAction = createAction(`${ns}UNLINK_PURCHASE_ORDER_FROM_INVOICE`);
export const searchPurchaseOrdersLoadable = loadableDataActionsWithRequest<POSearchParams, PagedListContainer<PurchaseOrdersDTO>>(`${ns}SEARCH_PURCHASE_ORDERS`);
export const searchPurchaseOrdersForInvoiceLoadable = loadableDataActionsWithRequest<GetPurchaseOrdersForInvoiceParams, ApiResultOfListOfPurchaseOrdersForInvoiceDTO>(
    `${ns}SEARCH_PURCHASE_ORDERS_FOR_INVOICE`,
);
export const deletePurchaseOrderLoadable = loadableDataActions(`${ns}DELETE_PURCHASE_ORDER`);
export const exportPurchaseOrdersToXLSXActions = loadableDataActions(`${ns}EXPORT_PURCHASE_ORDERS_TO_XLSX`);
export const exportPurchaseOrderToPdfActions = loadableDataActions(`${ns}EXPORT_PURCHASE_ORDER_TO_PDF`);
export const duplicatePurchaseOrderLoadable = loadableDataActions<string, PurchaseOrdersDTO>(`${ns}DUPLICATE_PURCHASE_ORDER`);

export const setUserTouchedPOListStatusFilter = (touched: boolean) => {
    return (dispatch: DispatchThunk) => {
        dispatch(setUserTouchedStatusFilter(touched));
    };
};

export const sortPurchaseOrders = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { purchaseOrders } = getState();
        const sorting = purchaseOrders.searchParams.SortItems[0];

        const searchParams: POSearchParams = {
            ...purchaseOrders.searchParams,
            SortItems: [
                {
                    SortColumn: getColumnName(columnName),
                    SortDirection: sorting.SortColumn === getColumnName(columnName) ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };

        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        dispatch(getPurchaseOrdersList(searchParams));
    };
};

export const setPurchaseOrdersPagingOptions = (page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { purchaseOrders } = getState();
        const paging = purchaseOrders.searchParams.PagingOptions;
        const searchParams: POSearchParams = {
            ...purchaseOrders.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };

        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        dispatch(getPurchaseOrdersList(searchParams));
    };
};

export const searchPurchaseOrders = (searchString?: string, resetFilters?: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.purchaseOrders.searchParams.PagingOptions;
        // keep the old restrictions when we filter
        const generalSearchRestriction = state.purchaseOrders.searchParams.Restrictions.find((restriction) => restriction.Field === DEFAULT_RESTRICTION);

        const otherRestrictions = state.purchaseOrders.searchParams.Restrictions.filter((restriction) => restriction.Field !== DEFAULT_RESTRICTION);
        let restrictions: Restriction[] = [];
        if (!!generalSearchRestriction || searchString !== null) {
            restrictions.push({
                Field: DEFAULT_RESTRICTION,
                Value: searchString || '',
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            });
        }

        restrictions = [...restrictions, ...otherRestrictions];
        // Need to implement the new PO list filtering here....
        const searchParams: POSearchParams = {
            ...state.purchaseOrders.searchParams,
            SortItems: resetFilters
                ? [
                      {
                          SortColumn: DEFAULT_SORT_COLUMN,
                          SortDirection: SortDirection.Asc,
                      },
                  ]
                : state.purchaseOrders.searchParams.SortItems,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: resetFilters ? [] : restrictions,
        };

        if (resetFilters) {
            await dispatch(saveAndUpdatePOFiltersSettings([...searchParams.Restrictions, { Field: DATE_RESTRICTION, Values: [null, null] as [Date, Date], Value: null, FieldSearchType: 0 }]));
        }

        dispatch(getPurchaseOrdersList(searchParams, resetFilters));
    };
};

export const filterPurchaseOrders = (restriction?: Restriction, filter?: Omit<TableFilter<any>, 'onSelectChangeCallback' | 'tagSelectType'>) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.purchaseOrders.searchParams.PagingOptions;
        // keep the old restrictions when we filter
        let restrictions = state.purchaseOrders.searchParams.Restrictions.filter((r, index) => {
            if (index === 0) {
                return r;
            }
            if (r.Field === restriction.Field) {
                if (isEmpty(restriction.Value) && isEmpty(restriction.Values)) {
                    // we need to include even empty date range restriction to know if it was cleared on purpose (by default it's last 90 days)
                    // but remove all other old restrictions when new are present
                    return restriction.Field === DATE_RESTRICTION;
                }
                return !!restriction.Values || !!restriction.Value;
            }
            return !!r.Value || !!r.Values;
        }).map((r) => {
            if (r.Field === restriction.Field) {
                return restriction;
            }
            return r;
        });
        if (
            !!restriction &&
            !restrictions.includes(restriction) &&
            ((typeof restriction.Value === 'number' ? restriction.Value !== 0 : !isEmpty(restriction.Value)) || !isEmpty(restriction.Values)) // don't include empty restrictions
        ) {
            restrictions.push(restriction);
        }
        if (filter?.childColumnName) {
            restrictions = restrictions.filter((p) => p.Field !== filter.childColumnName);
            let children = Array<any>();
            filter.values.forEach((value) => {
                if (value.children) {
                    children = [...children, ...value.children];
                }
            });
            if (children.length) {
                restrictions.push({
                    Field: filter.childColumnName,
                    Value: children.length === 1 ? children[0].value : null,
                    Values: children.length === 1 ? null : children.map((val) => val.value),
                    FieldSearchType: SearchType.NotSelected,
                });
            }
        }
        const poStatusRestriction = restrictions.find((r) => r.Field === 'OrderStatus');
        if (poStatusRestriction) {
            const value: string = poStatusRestriction.Value?.toString();
            const values: string[] = poStatusRestriction.Values?.map((el) => el.toString());
            const orderExtraStatusCodes: string[] = [];
            const extraStatusIsValue = value?.includes('extra');
            const extraStatusInValues = values?.find((el) => el.includes('extra'));
            const newExtraStatusRes: Restriction = {
                Value: null,
                Values: [],
                Field: 'OrderExtraStatus',
                FieldSearchType: SearchType.NotSelected,
            };

            if (extraStatusIsValue) {
                orderExtraStatusCodes.push(poStatusRestriction.Value);
            }

            if (extraStatusInValues) {
                orderExtraStatusCodes.push(extraStatusInValues);
            }

            const existExtraStatusRestriction = restrictions.find((r) => r.Field === 'OrderExtraStatus');
            newExtraStatusRes.Values.push(orderExtraStatusCodes);
            if (existExtraStatusRestriction) {
                newExtraStatusRes.Values.push(existExtraStatusRestriction.Values, existExtraStatusRestriction.Value);
            }
            newExtraStatusRes.Values = flatten(newExtraStatusRes.Values)
                .filter((el) => !isNull(el))
                .map((el) => el.toString().split('-')[0]);

            const newPoStatusRestrictionValues = flatten([poStatusRestriction.Value, poStatusRestriction.Values]);
            poStatusRestriction.Values = newPoStatusRestrictionValues.filter((el) => !el?.toString().includes('extra') && !isNull(el));
            if (!poStatusRestriction.Values.length) {
                poStatusRestriction.Values = null;
            }
            if (poStatusRestriction.Value?.includes('extra')) {
                poStatusRestriction.Value = PurchaseOrderStatus.Confirmed.toString();
            }

            if (newExtraStatusRes.Values.length || newExtraStatusRes.Value) {
                if (!existExtraStatusRestriction) {
                    restrictions.push(newExtraStatusRes);
                } else {
                    restrictions = restrictions.map((r) => (r.Field === 'OrderExtraStatus' ? newExtraStatusRes : r));
                }
            }
        }

        const searchParams: POSearchParams = {
            ...state.purchaseOrders.searchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: restrictions,
            filters: {
                ...state.purchaseOrders.searchParams.filters,
                [restriction.Field]: filter,
            },
        };
        await dispatch(saveAndUpdatePOFiltersSettings(restrictions));
        dispatch(getPurchaseOrdersList(searchParams));
    };
};

export function getPurchaseOrdersList(searchParams: POSearchParams | undefined, resetFilters?: boolean) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { purchaseOrders } = getState();
        searchParams = searchParams || purchaseOrders.searchParams;
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const groupMember = getState().user.groupMemberCommonLoadable.payload;
        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'DateCreated', SortDirection.Desc);
        const savedUserSettings = getUserSettingValue(UserSettingName.PURCHASE_ORDERS_TABLE_FILTERS, groupMember);

        if (savedUserSettings) {
            const savedSettingsParsed: Restriction[] = JSON.parse(savedUserSettings);
            const newRestrictions: Restriction[] = savedSettingsParsed.filter((r) => r.Field !== DEFAULT_RESTRICTION);
            const generalRestriction = searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);

            if (generalRestriction) {
                newRestrictions.push({
                    Field: DEFAULT_RESTRICTION,
                    Value: generalRestriction.Value,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                });
            }

            viewSearchParams.Restrictions = newRestrictions;
        }
        const apiSearchParams = { ...searchParams, ...viewSearchParams };
        delete apiSearchParams.filters;

        if (!apiSearchParams.Restrictions.filter((r) => r.Field === DATE_RESTRICTION).length) {
            // if no date range set explicitly, apply the 90 days date range filter by default
            searchParams.Restrictions.push({
                Field: DATE_RESTRICTION,
                Values: resetFilters ? [null, null] : getDefaultRangeValues(DefaultTimeRange.LAST_90_DAYS).map((e) => removeTimeZone(e)),
                Value: null,
                FieldSearchType: SearchType.NotSelected,
            });
        }
        let response;
        try {
            dispatch(searchPurchaseOrdersLoadable.request(searchParams));
            response = await api.purchaseOrder.getList(apiSearchParams);
            dispatch(
                searchPurchaseOrdersLoadable.success({
                    request: searchParams,
                    result: response.data,
                }),
            );
        } catch (e) {
            console.error(e);
            dispatch(
                searchPurchaseOrdersLoadable.error({
                    request: searchParams,
                    result: e,
                }),
            );
        }
    };
}

export function deletePurchaseOrder(iPurchaseOrderId: number) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const state = getState();
            dispatch(deletePurchaseOrderLoadable.request(iPurchaseOrderId));
            const response = await api.purchaseOrder.deletePurchaseOrder(iPurchaseOrderId);
            dispatch(deletePurchaseOrderLoadable.success(response.data));
            if (state.purchaseOrders.purchaseOrdersLoadable.payload && state.purchaseOrders.purchaseOrdersLoadable.payload.Items.length === 1) {
                // we deleted the last item, so go to previous page
                const currentPage = state.purchaseOrders.searchParams.PagingOptions.Page;
                if (currentPage > 1) {
                    dispatch(setPurchaseOrdersPagingOptions(currentPage - 1));
                    return;
                }
            }
            dispatch(getPurchaseOrdersList(undefined));
        } catch (e) {
            console.error(e);
            dispatch(deletePurchaseOrderLoadable.error(e));
        }
    };
}

export function exportPurchaseOrdersToXLSX() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            dispatch(exportPurchaseOrdersToXLSXActions.request(undefined));
            const {
                purchaseOrders: { searchParams },
            } = getState();
            const response = await api.purchaseOrder.exportPurchaseOrdersToXls(searchParams);
            const fileName = `PurchaseOrders_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`;
            const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            saveAs(file, fileName);
            dispatch(exportPurchaseOrdersToXLSXActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportPurchaseOrdersToXLSXActions.error(e));
        }
    };
}

export function exportPurchaseOrderToPdf(iPurchaseOrderId: string, name: string) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(exportPurchaseOrderToPdfActions.request(iPurchaseOrderId));
            const response = await api.purchaseOrder.exportPurchaseOrderToPdf(iPurchaseOrderId, currentLanguage());
            const fileName = `PurchaseOrder_${name}.pdf`;
            const file = new Blob([response.data], { type: 'application/pdf' });
            saveAs(file, fileName);
            dispatch(exportPurchaseOrderToPdfActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportPurchaseOrderToPdfActions.error(e));
        }
    };
}

export function duplicatePurchaseOrder(iPurchaseOrderId: string) {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.purchaseOrder.clonePurchaseOrder(iPurchaseOrderId);
            dispatch(push(resolvePathParams(paths.app.purchaseOrderDetails, { id: response.data })));
        } catch (e) {
            console.error(e);
        }
    };
}

export const setTableColumnsConfigurable = (newColumns: TableColumnsConfigurableItem[]) => async (dispatch: DispatchThunk, getState: () => GlobalState) => {
    const {
        user: {
            groupMemberCommonLoadable: { payload: groupMember },
        },
    } = getState();
    const updatedSettings = setUserSettingValue(UserSettingName.PURCHASE_ORDERS_COLUMNS_CONFIG, JSON.stringify(newColumns), [...groupMember.UserSettings]);
    await dispatch(updateUserSettings(groupMember.Id, updatedSettings));
    dispatch(getCurrentUserGroupMember(true));
};

export function saveAndUpdatePOFiltersSettings(restrictions: Restriction[]) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const updatedSettings = setUserSettingValue(UserSettingName.PURCHASE_ORDERS_TABLE_FILTERS, JSON.stringify(restrictions), [...groupMember.UserSettings]);
        await dispatch(updateUserSettings(groupMember.Id, updatedSettings));
        dispatch(getCurrentUserGroupMember(true));
    };
}
