import { isEmpty } from 'lodash-es';

import { PurchaseOrderInvoicesFilter, PurchaseOrderStatus, Restriction, SearchType, SortDirection } from '../../../../../../services/types/ApiTypes';
import { StatusType } from '../../../../../../components/Status/Status';
import { TableFilter } from '../../../../../../components/Table/components/filter/TableFilters';
import { InvoiceStatus } from '../../../../../../common/constants/appConstants';
import { SearchDateBy } from '../../../invoice-slider/InvoiceSliderHelpers';
import { InvoicesSearchParams } from './PurchaseOrdersListReducers';

export const DEFAULT_RESTRICTION = 'GeneralSearch';
export const DEFAULT_SORT_COLUMN = 'DateCreated';

export function createRequest(searchValue = '', page = 1, count = 15, columnName = DEFAULT_SORT_COLUMN) {
    const search: InvoicesSearchParams = {
        SortItems: [
            {
                SortColumn: columnName,
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Count: count,
            Page: page,
        },
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: searchValue || '',
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
    };
    return search;
}

export const parseRestrictions = (searchParams: InvoicesSearchParams) => {
    const appliedFilters: Array<TableFilter<any>> = [];
    searchParams.Restrictions.forEach((restriction: any) => {
        if (restriction.Field !== DEFAULT_RESTRICTION && searchParams.filters[restriction.Field]) {
            const newAppliedFilter: TableFilter<any> = {
                label: restriction.Field,
                columnName: restriction.Field,
                items: searchParams.filters[restriction.Field].items,
                loadItems: searchParams.filters[restriction.Field].loadItems,
                values: searchParams.filters[restriction.Field].values,
                tagSelectType: searchParams.filters[restriction.Field].tagSelectType,
                onSelectChangeCallback: searchParams.filters[restriction.Field].onSelectChangeCallback,
                placeholder: searchParams.filters[restriction.Field].placeholder,
                searchOnFocus: searchParams.filters[restriction.Field].searchOnFocus,
                childColumnName: searchParams.filters[restriction.Field]?.childColumnName,
            };
            if (!!newAppliedFilter.values && !isEmpty(newAppliedFilter.values)) {
                appliedFilters.push(newAppliedFilter);
            }
        }
    });
    return appliedFilters;
};

export const getTimeFrameObject = (scope: string) => {
    const timeFrame = { from: '', to: '' };
    switch (scope) {
        case SearchDateBy.INVOICE:
            timeFrame.from = 'InvoiceDateFrom';
            timeFrame.to = 'InvoiceDateTo';
            break;
        case SearchDateBy.CODING:
            timeFrame.from = 'AccountingDateFrom';
            timeFrame.to = 'AccountingDateTo';
            break;
        case SearchDateBy.IMPORT:
            timeFrame.from = 'ImportedDateFrom';
            timeFrame.to = 'ImportedDateTo';
            break;
        case SearchDateBy.EXPORT:
            timeFrame.from = 'ExportedDateFrom';
            timeFrame.to = 'ExportedDateTo';
            break;
        case SearchDateBy.PAYMENT:
            timeFrame.from = 'DueDateFrom';
            timeFrame.to = 'DueDateTo';
            break;
        default:
            timeFrame.from = 'InvoiceDateFrom';
            timeFrame.to = 'InvoiceDateTo';
            break;
    }

    return timeFrame;
};

export const parseFilters = (filter: TableFilter<any>): Restriction => {
    const appliedFilter: Restriction = {
        Field: filter.columnName,
        Value: null,
        Values: null,
        FieldSearchType: SearchType.NotSelected,
    };
    if (filter && filter.values.length > 0) {
        appliedFilter.Value = null;
        appliedFilter.Values = filter.values.map((val) => val.value);
    }
    return appliedFilter;
};

export const PO_INVOICES_FILTER_TYPES = {
    [PurchaseOrderInvoicesFilter.Any]: 'view.PurchaseOrders.Invoices.Filter.Any',
    [PurchaseOrderInvoicesFilter.Linked]: 'view.PurchaseOrders.Invoices.Filter.Yes',
    [PurchaseOrderInvoicesFilter.LinkedByMe]: 'view.PurchaseOrders.Invoices.Filter.ByMe',
    [PurchaseOrderInvoicesFilter.NotLinked]: 'view.PurchaseOrders.Invoices.Filter.No',
};

export const getPurchaseOrderStatusType = (status: PurchaseOrderStatus): StatusType => {
    switch (status) {
        case PurchaseOrderStatus.New:
            return StatusType.BLUE;
        case PurchaseOrderStatus.Assigned:
            return StatusType.YELLOW;
        case PurchaseOrderStatus.Confirmed:
            return StatusType.GREEN;
        case PurchaseOrderStatus.Rejected:
            return StatusType.RED;
        default:
            return StatusType.GRAY;
    }
};

export const AllowedLinkInvoiceStatuses = [InvoiceStatus.New, InvoiceStatus.InApproval, InvoiceStatus.PendingExport, InvoiceStatus.Exported, InvoiceStatus.NotForExport];
