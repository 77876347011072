import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import {
    BaseSearch,
    PagedListContainer,
    PurchaseOrdersDTO,
    PurchaseOrderFileAttachmentDTO,
    PurchaseOrderTaskActionDto,
    PurchaseOrderTaskDTO,
    PurchaseOrderUpdateExtraStatusDTO,
    PurchaseOrdersRowsDTO,
    PurchaseOrdersRowDTO,
    PurchaseOrderHeaderHistoryDTO,
    PurchaseOrderHistoryDTO,
    PurchaseOrderHeaderInvoicesDTO,
    LinkedCountDTO,
    PurchaseOrderInvoicesSearch,
    InvoiceHeaderPurchaseOrdersDTO,
    InvoicePurchaseOrdersSearch,
} from '../types/ApiTypes';
import { UploadProgress } from '../ApiClient';
import { longsToStrings } from './InvoiceService';
import { PurchaseOrdersAddViewEmptyRowFields } from '../../views/purchase-orders-add/purchaseOrderAddViewFields';

export default class PurchaseOrderService {
    constructor(private axios: AxiosInstance) {}

    addNewPurchaseOrder = async (purchaseOrder: PurchaseOrdersDTO): Promise<AxiosResponse<PurchaseOrdersDTO>> => {
        return this.axios.post('/PurchaseOrders/AddNewPurchaseOrder', purchaseOrder);
    };

    createNewPurchaseOrder = async (): Promise<AxiosResponse<PurchaseOrdersDTO>> => {
        return this.axios.post('/PurchaseOrders/CreateNewPurchaseOrder');
    };

    savePurchaseOrder = async (purchaseOrder: PurchaseOrdersDTO): Promise<AxiosResponse<PurchaseOrdersDTO>> => {
        return this.axios.post('/PurchaseOrders/SavePurchaseOrder', purchaseOrder);
    };

    deletePurchaseOrder = async (iPurchaseOrderId: number): Promise<AxiosResponse<PurchaseOrdersDTO>> => {
        return this.axios.get(`/PurchaseOrders/DeletePurchaseOrder`, {
            params: {
                iPurchaseOrderId,
            },
        });
    };

    getPurchaseOrderById = async (id: string): Promise<AxiosResponse<PurchaseOrdersDTO>> => {
        return this.axios.get(`/PurchaseOrders/GetPurchaseOrderById/${id}`);
    };

    getPurchaseOrderRows = async (id: string): Promise<AxiosResponse<PurchaseOrdersRowsDTO[]>> => {
        return this.axios.get(`/PurchaseOrders/GetPurchaseOrderRows/${id}`);
    };

    getPurchaseOrderRelatedDocuments = async (id: string): Promise<AxiosResponse<PurchaseOrderFileAttachmentDTO[]>> => {
        return this.axios.get(`/PurchaseOrders/GetPurchaseOrderRelatedDocuments/${id}`);
    };

    savePurchaseOrderRow = async (id: number, row: PurchaseOrdersRowsDTO | PurchaseOrdersAddViewEmptyRowFields): Promise<AxiosResponse<PurchaseOrdersRowDTO>> => {
        return this.axios.post(`/PurchaseOrders/SavePurchaseOrderRow/${id}`, row);
    };

    deletePurchaseOrderRow = async (row: PurchaseOrdersRowsDTO): Promise<AxiosResponse<PurchaseOrdersRowDTO>> => {
        return this.axios.post('/PurchaseOrders/DeletePurchaseOrderRow/', row);
    };

    getList = async (search: BaseSearch): Promise<AxiosResponse<PagedListContainer<PurchaseOrdersDTO>>> => {
        return this.axios.post('/PurchaseOrders/GetList', search);
    };

    getListForDashboard = async (search: BaseSearch): Promise<AxiosResponse<PagedListContainer<PurchaseOrdersDTO>>> => {
        return this.axios.post('/PurchaseOrders/GetListForDashboard', search);
    };

    getPurchaseOrdersForInvoice = async (search: InvoicePurchaseOrdersSearch): Promise<AxiosResponse<PagedListContainer<InvoiceHeaderPurchaseOrdersDTO>>> => {
        return this.axios.post('/PurchaseOrders/GetPurchaseOrdersForInvoice', search);
    };

    getLinkedPurchaseOrdersCount = async (invoiceId: number): Promise<AxiosResponse<LinkedCountDTO>> => {
        return this.axios.get(`/PurchaseOrders/GetLinkedPurchaseOrdersCount/${invoiceId}`);
    };

    linkPurchaseOrderToInvoice = async (invoiceId: number, purchaseOrderId: number) => {
        return this.axios.post('/PurchaseOrders/LinkPurchaseOrderToInvoice', { invoiceId, purchaseOrderId });
    };

    unlinkPurchaseOrderFromInvoice = async (invoiceId: number, purchaseOrderId: number) => {
        return this.axios.post('/PurchaseOrders/UnLinkPurchaseOrderToInvoice', { invoiceId, purchaseOrderId });
    };

    uploadPurchaseOrderInvoiceFile = async (file: PurchaseOrderFileAttachmentDTO, onUploadProgress: UploadProgress): Promise<AxiosResponse<any>> => {
        const FILE_UPLOAD_TIMEOUT = 5 * 60 * 1000; // 5 minutes
        const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: any) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (onUploadProgress) {
                    onUploadProgress(progress);
                }
            },
            timeout: FILE_UPLOAD_TIMEOUT,
        };
        return this.axios.post('/PurchaseOrders/UploadPurchaseOrderInvoiceFile', file, config);
    };

    downloadPurchaseOrderFile = async (attachmentId: number): Promise<AxiosResponse<any>> => {
        return this.axios.post(
            `/File/DownloadPurchaseOrderFile?iPurchaseOrderFileAttachmentId=${attachmentId}`,
            {},
            {
                responseType: 'blob',
            },
        );
    };

    deletePurchaseOrderFile = async (file: PurchaseOrderFileAttachmentDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/PurchaseOrders/DeletePurchaseOrderFile`, file);
    };

    doTaskAction = async (poTaskAction: PurchaseOrderTaskActionDto): Promise<AxiosResponse<PurchaseOrderTaskActionDto>> => {
        return this.axios.post(`/PurchaseOrders/DoTaskAction`, poTaskAction);
    };

    updateExtraStatus = async (updateDto: PurchaseOrderUpdateExtraStatusDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/PurchaseOrders/UpdateExtraStatus`, updateDto);
    };

    getPurchaseOrderTaskItems = async (purchaseOrderId: number): Promise<AxiosResponse<PurchaseOrderTaskDTO[]>> => {
        return this.axios.post(`/PurchaseOrders/GetPurchaseOrderTaskItemsDto?iPurchaseOrderId=${purchaseOrderId}`);
    };

    getPurchaseOrderFileToken = async (purchaseOrderId: number): Promise<AxiosResponse<string>> => {
        return this.axios.get(`/File/GetPurchaseOrderFileToken?iPurchaseOrderFileAttachmentId=${purchaseOrderId}`);
    };

    insertTaskAction = async (purchaseOrderId: number, groupMemberId: number, orderNumber: number): Promise<AxiosResponse<PurchaseOrderTaskActionDto>> => {
        return this.axios.post(`/PurchaseOrders/InsertTaskAction?iPurchaseOrderId=${purchaseOrderId}&iGroupMemberId=${groupMemberId}&iOrderNumberToInsert=${orderNumber}&bInParallel=${false}`);
    };

    exportPurchaseOrdersToXls = async (search: BaseSearch): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/PurchaseOrders/ExportPurchaseOrdersToXls`, search, {
            responseType: 'blob',
        });
    };

    exportPurchaseOrderToPdf = async (iPurchaseOrderId: string, language: string): Promise<AxiosResponse<any>> => {
        return this.axios.post(`File/DownloadPurchaseOrderPdf?purchaseOrderId=${iPurchaseOrderId}&language=${language}`, null, {
            responseType: 'blob',
        });
    };

    duplicatePurchaseOrder = async (sourcePurchaseOrderId: string): Promise<AxiosResponse<PurchaseOrdersDTO>> => {
        return this.axios.get(`/PurchaseOrders/DuplicatePurchaseOrder`, {
            params: {
                sourcePurchaseOrderId,
            },
        });
    };

    clonePurchaseOrder = async (sourcePurchaseOrderId: string): Promise<AxiosResponse<number>> => {
        return this.axios.get(`/PurchaseOrders/ClonePurchaseOrder`, {
            params: {
                sourcePurchaseOrderId,
            },
        });
    };

    getPurchaseOrderHeaderHistory = async (search: BaseSearch): Promise<AxiosResponse<PagedListContainer<PurchaseOrderHeaderHistoryDTO>>> => {
        return this.axios.post('/PurchaseOrders/GetPurchaseOrderHeaderHistory', search);
    };

    getPurchaseOrderHistory = async (purchaseOrderId: number): Promise<AxiosResponse<PurchaseOrderHistoryDTO[]>> => {
        return this.axios.get('/PurchaseOrders/GetPurchaseOrderHistory', {
            params: {
                purchaseOrderId,
            },
        });
    };

    getInvoicesForPurchaseOrder = async (search: PurchaseOrderInvoicesSearch): Promise<AxiosResponse<PagedListContainer<PurchaseOrderHeaderInvoicesDTO>>> => {
        return this.axios.post('/PurchaseOrders/GetInvoicesForPurchaseOrder', search, {
            transformResponse(value) {
                return longsToStrings(value);
            },
        });
    };

    getLinkedInvoicesCount = async (purchaseOrderId: number): Promise<AxiosResponse<LinkedCountDTO>> => {
        return this.axios.get(`/PurchaseOrders/GetLinkedInvoicesCount/${purchaseOrderId}`, {
            transformResponse(value) {
                return longsToStrings(value);
            },
        });
    };

    isOrderNumberDuplicate = async (purchaseOrderNumber: string): Promise<AxiosResponse<boolean>> => {
        return this.axios.get(`/PurchaseOrders/IsOrderNumberDuplicate?orderNumber=${encodeURIComponent(purchaseOrderNumber)}`);
    };
}
