import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable0, Dispatchable1, Dispatchable2 } from 'redux-dispatchers';
import { isEqual } from 'lodash-es';

import { LoadableData } from '../../common/utils/LoadableData';
import constants from '../../common/constants/appConstants';
import { BackOfficeUserDTO, BackOfficeCompanyDTO, BackOfficeCompanyDTOExtended, BackOfficeUserSettingDTO, BackOfficeAssistantDTO } from '../../services/types/BoApiTypes';
import { Button, ButtonType } from '../Buttons/Button';
import Link, { LinkType } from '../Link/Link';
import Icon, { ICONS, IconSize } from '../Icon/Icon';
import { createDataId } from '../../common/utils/dataId';
import { PasswordDTO, SessionDTO } from 'src/services/types/ApiTypes';
import Modal, { ModalType, ModalWidth } from '../Modal/Modal';
import ChangePassword from './components/ChangePassword/ChangePassword';
import PersonalDataForm from './components/PersonalDataForm/PersonalDataForm';
import CompaniesNotifications from './components/CompaniesNotifications/CompaniesNotifications';
import ManageAssistants from './components/ManageAssistants/ManageAssistants';
import ManageSessions from './components/ManageSessions/ManageSessions';
import { UserSettingName } from '../../services/types/ApiTypes';
import ModalTitle from '../Modal/ModalTitle';

import withSuspense from '../../common/hocs/withSuspense';
import { Response, BooleanResponse } from '../../services/types/ApiTypes';
import EmailVerification from './components/EmailVerification/EmailVerification';

import './UserProfile.scss';

export const AUTOFOCUS_ON_EMAIL_VERIFICATION_STRING = 'EMAIL_VERIFICATION';

export interface Props {
    boUser: LoadableData<BackOfficeUserDTO>;
    userCompanies: BackOfficeCompanyDTOExtended[];
    changePasswordLoadable: LoadableData<Response<any>, string>;
    userAssistantsLoadable: LoadableData<BackOfficeAssistantDTO[]>;
    userSessionsLoadable: LoadableData<SessionDTO[]>;
    saveUserAssistantsLoadable: LoadableData<BackOfficeAssistantDTO[]>;
    emailVerificationProcess: boolean;
    sendEmailVerificationLoadable: LoadableData<BooleanResponse, string>;
    verifyEmailLoadable: LoadableData<BooleanResponse, string>;
    isUserProfileVisibleAndFocusOn: boolean | string;
}

export interface DispatchProps {
    changePassword: Dispatchable1<PasswordDTO>;
    getAssistantsForUser: Dispatchable1<string>;
    getUserSessions: Dispatchable0;
    saveAssistantsForUser: Dispatchable2<string, BackOfficeAssistantDTO[]>;
    showUserProfile: Dispatchable1<boolean>;
    updateUserDetails: Dispatchable1<BackOfficeUserDTO>;
    sendVerificationCodeToEmail: Dispatchable1<string>;
    verifyEmail: Dispatchable1<string>;
    terminateSessions: Dispatchable1<number[]>;
}

export type UserProfileProps = Props & DispatchProps & WithTranslation;

interface UserProfileOptions {
    notificationsEdit: boolean;
    passwordEdit: boolean;
    personalDataEdit: boolean;
    manageAssistants: boolean;
    emailVerification: boolean;
    manageSessions: boolean;
}

const initialOptions: UserProfileOptions = {
    notificationsEdit: false,
    passwordEdit: false,
    personalDataEdit: false,
    manageAssistants: false,
    emailVerification: false,
    manageSessions: false,
};

export const dataId = 'userProfileModal';

export interface CompanyNotificationsSetting {
    CompanyGuid: string;
    notificationsOn: boolean;
}

export type CompanyWithNotificationsSetting = BackOfficeCompanyDTO & CompanyNotificationsSetting;

export const UserProfile = (props: UserProfileProps) => {
    const [options, setOptions] = useState<UserProfileOptions>(initialOptions);
    const [companiesWithNotificationsSettings, setCompaniesWithNotificationsSettings] = useState<CompanyWithNotificationsSetting[]>([]);
    const [contentRef, setContentRef] = useState<React.RefObject<any>>();
    const {
        boUser,
        changePassword,
        changePasswordLoadable,
        getAssistantsForUser,
        getUserSessions,
        saveAssistantsForUser,
        saveUserAssistantsLoadable,
        showUserProfile,
        t,
        updateUserDetails,
        userAssistantsLoadable,
        userCompanies,
        emailVerificationProcess,
        sendEmailVerificationLoadable,
        sendVerificationCodeToEmail,
        verifyEmail,
        verifyEmailLoadable,
        userSessionsLoadable,
        terminateSessions,
        isUserProfileVisibleAndFocusOn,
    } = props;

    useEffect(() => {
        if (sendEmailVerificationLoadable?.payload?.Success) {
            setOptions({ ...initialOptions, emailVerification: true });
        }
    }, [sendEmailVerificationLoadable]);

    useEffect(() => {
        if (verifyEmailLoadable?.payload?.Success) {
            setOptions({ ...initialOptions, personalDataEdit: true });
        }
    }, [verifyEmailLoadable]);

    useEffect(() => {
        if (isUserProfileVisibleAndFocusOn === AUTOFOCUS_ON_EMAIL_VERIFICATION_STRING) {
            setOptions({ ...initialOptions, personalDataEdit: true });
        }
    }, [isUserProfileVisibleAndFocusOn]);

    useEffect(() => {
        const userCompaniesWithNotificationsSettings: CompanyWithNotificationsSetting[] = [];
        if (boUser?.payload && userCompanies) {
            const notificationsSettingsFromBE = boUser.payload.Settings.find((s: BackOfficeUserSettingDTO) => s.Name === UserSettingName.SETTINGS_PROFILE_COMPANIES_NOTIFICATIONS);
            // if notifications settings are not present at all, default to all active
            const settingsFromBE: CompanyNotificationsSetting[] = notificationsSettingsFromBE?.Value ? JSON.parse(notificationsSettingsFromBE?.Value) : [];

            userCompanies.map((c: BackOfficeCompanyDTO) => {
                if (!notificationsSettingsFromBE) {
                    // if no notifications settings found, all are activated by default
                    userCompaniesWithNotificationsSettings.push({ ...c, notificationsOn: true });
                } else {
                    const currentCompanySetting = settingsFromBE.find((s: CompanyNotificationsSetting) => s.CompanyGuid === c.CompanyGuid);
                    userCompaniesWithNotificationsSettings.push({ ...c, notificationsOn: currentCompanySetting?.notificationsOn || false });
                }
            });
        }
        if (!isEqual(companiesWithNotificationsSettings, userCompaniesWithNotificationsSettings)) {
            setCompaniesWithNotificationsSettings(userCompaniesWithNotificationsSettings);
        }

        if ((boUser?.payload?.EmailLastValidated || !boUser?.payload?.Email) && sessionStorage.getItem('loginSession')) {
            sessionStorage.removeItem('loginSession');
        }
    }, [boUser, userCompanies]);

    const checkAccessToManageAssistants = (): boolean => {
        const role = boUser?.payload?.Role;
        return role ? constants.manageAssistantsAccessRoles.includes(role) : false;
    };

    const onNotificationsChange = (notificationSettings: CompanyNotificationsSetting[]) => {
        const currentUser = boUser?.payload;
        const userOtherSettings = currentUser?.Settings?.filter((s: BackOfficeUserSettingDTO) => s.Name !== UserSettingName.SETTINGS_PROFILE_COMPANIES_NOTIFICATIONS);

        const userWithNotificationsSettings: BackOfficeUserDTO = {
            ...currentUser,
            Settings: [...userOtherSettings, { Name: UserSettingName.SETTINGS_PROFILE_COMPANIES_NOTIFICATIONS, Value: JSON.stringify(notificationSettings) }],
        };
        updateUserDetails(userWithNotificationsSettings);
    };

    const toggleModalLinks = (link?: keyof UserProfileOptions) => {
        link ? setOptions({ ...initialOptions, [link]: true }) : setOptions(initialOptions);
    };

    const isUserProfileScreen = (): boolean => isEqual(options, initialOptions);

    const getModalTitle = (): string => {
        if (options.notificationsEdit) {
            return t('views.global.UserProfileModal.manageNotifications');
        } else if (options.passwordEdit) {
            return t('views.global.UserProfileModal.changePassword');
        } else if (options.personalDataEdit) {
            return t('views.global.UserProfileModal.personalData');
        } else if (options.manageAssistants) {
            return t('views.global.UserProfileModal.manageAssistants');
        } else if (options.emailVerification) {
            return t('views.global.UserProfileModal.emailVerification');
        } else if (options.manageSessions) {
            return t('views.global.UserProfileModal.manageSessions');
        }
        return t('views.global.UserProfileModal.userProfile');
    };

    const setRef = (node: React.RefObject<any>) => {
        setContentRef(node);
    };

    return (
        <Modal setRef={setRef} width={ModalWidth.XS} isOpen={true} type={ModalType.FIXED_RIGHT} onClose={() => showUserProfile(false)} className="user-profile" dataId={createDataId(dataId)}>
            <div className="user-profile__content" data-id={createDataId(dataId, 'content')}>
                <div className="header" data-id={createDataId(dataId, 'header')}>
                    <>
                        {!isUserProfileScreen() && (
                            <Button
                                dataId={createDataId(dataId, 'return-button')}
                                buttonType={ButtonType.ICON_SQUARE}
                                icon={ICONS.ARROW_LEFT_24}
                                iconSize={IconSize.LG}
                                iconClass="toggle-content__toggle-icon"
                                className="return-button"
                                onClick={() => toggleModalLinks()}
                            />
                        )}
                        <ModalTitle dataId={createDataId(dataId, 'title')}>{getModalTitle()}</ModalTitle>
                    </>
                </div>
                <main className="main-content" data-id={createDataId(dataId, 'main-content')}>
                    <>
                        {isUserProfileScreen() && (
                            <>
                                <div className="avatar" data-id={createDataId(dataId, 'avatar')}>
                                    {boUser.payload && (boUser.payload.FirstName?.charAt(0) + boUser.payload.LastName?.charAt(0)).toUpperCase()}
                                </div>
                                <div className="name" data-id={createDataId(dataId, 'name')}>
                                    {boUser.payload && boUser.payload.FullName}
                                </div>
                                <div className="email" data-id={createDataId(dataId, 'email')}>
                                    {boUser.payload && boUser.payload.Email}
                                </div>
                                <div className="personal-code" data-id={createDataId(dataId, 'personal-code')}>
                                    {(boUser.payload?.PersonalCode && boUser.payload.PersonalCode) || '--'}
                                </div>

                                <div className="profile-options" data-id={createDataId(dataId, 'profile-options')}>
                                    <ul>
                                        <li>
                                            <Link
                                                button={true}
                                                disabled={!!boUser.payload?.ExternalId}
                                                linkType={LinkType.TEXT}
                                                onClick={() => toggleModalLinks('personalDataEdit')}
                                                dataId={createDataId(dataId, 'link.edit-personal-data')}
                                            >
                                                <Icon name={ICONS.PROFILE_USER} size={IconSize.SM} />
                                                {emailVerificationProcess && <span className="user-icon-alert"></span>}
                                                <span>{t('views.global.UserProfileModal.editPersonalData')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                button={true}
                                                linkType={LinkType.TEXT}
                                                onClick={() => toggleModalLinks('notificationsEdit')}
                                                dataId={createDataId(dataId, 'link.manage-notifications')}
                                            >
                                                <Icon name={ICONS.NOTIFICATIONS} size={IconSize.SM} />
                                                <span>{t('views.global.UserProfileModal.manageNotifications')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                button={true}
                                                disabled={!!boUser.payload?.ExternalId}
                                                linkType={LinkType.TEXT}
                                                onClick={() => toggleModalLinks('passwordEdit')}
                                                dataId={createDataId(dataId, 'link.change-password')}
                                            >
                                                <Icon name={ICONS.CHANGE_PASSWORD} size={IconSize.SM} />
                                                <span>{t('views.global.UserProfileModal.changePassword')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link button={true} linkType={LinkType.TEXT} onClick={() => toggleModalLinks('manageSessions')} dataId={createDataId(dataId, 'link.manage-sessions')}>
                                                <Icon name={ICONS.SESSIONS} size={IconSize.SM} />
                                                <span>{t('views.global.UserProfileModal.manageSessions')}</span>
                                            </Link>
                                        </li>
                                        {checkAccessToManageAssistants() && (
                                            <li>
                                                <Link
                                                    button={true}
                                                    linkType={LinkType.TEXT}
                                                    onClick={() => toggleModalLinks('manageAssistants')}
                                                    dataId={createDataId(dataId, 'link.manage-assistants')}
                                                >
                                                    <Icon name={ICONS.ASSISTANT} size={IconSize.SM} />
                                                    <span>{t('views.global.UserProfileModal.manageAssistants')}</span>
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </>
                        )}

                        {/* Profile menu sub-pages */}
                        {options.notificationsEdit && (
                            <CompaniesNotifications dataId={dataId} userCompanies={companiesWithNotificationsSettings} onSave={onNotificationsChange} isLoading={boUser.loading} />
                        )}
                        {options.passwordEdit && <ChangePassword dataId={dataId} changePassword={changePassword} isLoading={changePasswordLoadable.loading} />}
                        {options.personalDataEdit && (
                            <PersonalDataForm
                                dataId={dataId}
                                saveBoUser={updateUserDetails}
                                showUserProfile={showUserProfile}
                                activeBoUser={boUser.payload}
                                isLoading={boUser.loading}
                                isSendingEmailLoading={sendEmailVerificationLoadable.loading}
                                sendVerificationCodeToEmail={sendVerificationCodeToEmail}
                                autoFocusOnEmail={isUserProfileVisibleAndFocusOn === AUTOFOCUS_ON_EMAIL_VERIFICATION_STRING}
                            />
                        )}
                        {options.manageSessions && <ManageSessions userSessionsLoadable={userSessionsLoadable} getUserSessions={getUserSessions} terminateSessions={terminateSessions} />}
                        {options.manageAssistants && (
                            <ManageAssistants
                                dataId={dataId}
                                boUserGuid={boUser.payload?.UserGuid}
                                userAssistantsLoadable={userAssistantsLoadable}
                                getAssistantsForUser={getAssistantsForUser}
                                saveAssistantsForUser={saveAssistantsForUser}
                                saveUserAssistantsLoadable={saveUserAssistantsLoadable}
                                modalContentNode={contentRef}
                            />
                        )}
                        {options.emailVerification && <EmailVerification dataId={dataId} verifyEmail={verifyEmail} isLoading={verifyEmailLoadable.loading} email={boUser.payload.Email} />}
                    </>
                </main>
            </div>
        </Modal>
    );
};

export default withSuspense(withTranslation()(UserProfile));
