import React, { MouseEvent } from 'react';
import classNames from 'classnames';

import { createDataId } from '../../../common/utils/dataId';
import { Button, ButtonIconPlacement, ButtonType } from '../../../components/Buttons/Button';

interface TopMenuItemProps {
    className?: string;
    item: React.ReactNode;
    dataId?: string;
    icon: string;
    itemPress?: (e: MouseEvent<Element>) => void;
}

export const TopMenuItem: React.FC<TopMenuItemProps> = ({ className, item, dataId, icon, itemPress }): React.ReactElement => {
    const classes = classNames('top-menu__element', className);
    return (
        <li className={classes} onClick={itemPress} data-id={dataId}>
            <Button dataId={createDataId(dataId, 'button')} className="top-menu__link" icon={icon} buttonType={ButtonType.ICON_TEXT} iconPlacement={ButtonIconPlacement.LEFT}>
                {item}
            </Button>
        </li>
    );
};
