import { BackOfficeUserDTO } from '../../../services/types/BoApiTypes';
import { LoadableData } from '../../../common/utils/LoadableData';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { getBoCurrentUserLoadableAction } from './BoUserActions';

class State {
    boCurrentUserLoadable = new LoadableData<BackOfficeUserDTO>();
}

export default new ReducerFactory(new State())
    .addReducer(getBoCurrentUserLoadableAction.request, (state) => {
        return {
            ...state,
            boCurrentUserLoadable: state.boCurrentUserLoadable.withLoading(),
        };
    })
    .addReducer(getBoCurrentUserLoadableAction.success, (state, action) => {
        return {
            ...state,
            boCurrentUserLoadable: LoadableData.payload(action.payload),
        };
    })
    .toReducer();

export { State as BoUserState };
