import { AxiosResponse } from 'axios';

import { loadableDataActions } from '../../../../common/utils/LoadableData';
import api from '../../../../services/ApiServices';
import { PurchaseOrderHistoryDTO } from '../../../../services/types/ApiTypes';
import { DispatchThunk } from '../../../../storeConfig';
import { getCompanyGroupMembersMicro } from '../../../../common/company/CompanyActions';

const ns = 'purchaseOrderHistory/';

export const getPurchaseOrderHistoryLoadable = loadableDataActions<{ purchaseOrderId: number }, PurchaseOrderHistoryDTO[]>(`${ns}GET_PURCHASE_ORDER_HISTORY`);

export function getPurchaseOrderHistory(purchaseOrderId: number) {
    return async (dispatch: DispatchThunk) => {
        let response: AxiosResponse<PurchaseOrderHistoryDTO[]>;
        try {
            await dispatch(getCompanyGroupMembersMicro(undefined));
            dispatch(getPurchaseOrderHistoryLoadable.request({ purchaseOrderId }));
            response = await api.purchaseOrder.getPurchaseOrderHistory(purchaseOrderId);
            dispatch(getPurchaseOrderHistoryLoadable.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(getPurchaseOrderHistoryLoadable.error(e));
        }
    };
}
