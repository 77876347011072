(function() {
    'use strict';

    angular
        .module('dstreamApp.directives')
        .directive('doOnInputSave', doOnInputSave);

    doOnInputSave.$inject = ['$window'];

    function doOnInputSave($window) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: linkFunc
        };

        function linkFunc(scope, element, attrs, ctrl) {
            angular.element($window).on('click', onWindowClick);
            angular.element(element).on('keydown', onEnterKey);

            /*
                Cancel on esc key press
             */
            function onEnterKey(event) {
                if(event.keyCode === 13) {
                    scope.$eval(attrs.doOnInputSave);
                    event.stopPropagation();
                }
            }

            /*
                On click anywhere else than the input and typeahead dropdown
             */
            function onWindowClick(event) {
                if(event.target !== element[0]) {
                    scope.$eval(attrs.doOnInputSave);
                }
            }

            scope.$on('$destroy', function() {
                angular.element($window).off('click', onWindowClick);
                angular.element(element).off('keydown', onEnterKey);
            });
        }
    }
}());
