'use strict';

angular.module("dstreamApp").factory('location', [ '$location', '$route', '$rootScope', '$routeParams',
    function($location, $route, $rootScope, $routeParams) {
        $location.skipReload = function() {
            var lastRoute = $route.current;
            var un = $rootScope.$on('$locationChangeSuccess', function() {
                // TODO: fix angular.copy for post-1.6.9
                angular.copy($route.current.params, $routeParams);
                $route.current = lastRoute;
                un();
            });
            return $location;
        };
        return $location;
    }
]);

angular.module("dstreamApp").factory('passData', function() {
    var savedData = {};
    function set(data) {
        savedData = data;
    }
    function get() {
        return savedData;
    }
    function clear() {
        savedData = {};
    }

    return {
        set: set,
        get: get,
        clear: clear
    }
});