'use strict';

import { eventTrack } from '../../../src/common/analytics/gtm';

angular
    .module('dstreamApp.components')
    .component('dsSearch', {
        templateUrl: 'app/components/search/ds-search.html',
        controller: DsSearchController
    });

/* @ngInject */
function DsSearchController($route, $timeout, $location, invoiceRegisterService) {
    var ctrl = this;
    ctrl.searchValue = '';

    // navigate to invoice register view with filters searchValue set
    ctrl.doSearch = function (searchByKeyPressed) {
        if(!searchByKeyPressed) {
            eventTrack({
                event: 'dashboard_search',
                label: 'Search button',
            });
        }

        const filterObject = {
          searchValue: ctrl.searchValue,
          statusFilter: [],
          invoiceType: 0,
          customRestrictions: [],
          pagingOptions: {
            page: 1,
            count: 10,
            total: 0
          },
          timeType: 'forever'
        };
        $timeout(function () {
            if ($route.current.$$route.originalPath !== '/invoices' && $route.current.$$route.originalPath !== '/invoices/') {
                invoiceRegisterService.saveFilterToLocalStorage(filterObject, false, true, 'search'); // we need to set the default values to override all the filter values set by other tables in the dashboard
                $location.path('invoices/search');
            }
        });
    };

    ctrl.searchByKeyPressed = function(event, keyValue) {
        if(event && keyValue && event.which === keyValue){
            ctrl.doSearch(true);
        }
    }

    /*
        ============= ANGULAR FUNCTIONS ================
     */

    /*
        On component destroy
     */
    ctrl.$onDestroy = function () { };
}
