'use strict';
import { isSuf } from "../../src/common/utils/whitelabelHelper";
import api from '../../src/services/ApiServices';
import { CompanySettingStatus } from '../../src/views/settings/company-settings/components/Settings/CompanySettingsListHelper';

angular.module('dstreamApp.controllers').controller('companyController', [
    '$scope', '$filter', '_', 'webServices', 'utils', 'companyDataService', 'authenticationService', '$location',
    function ($scope, $filter, _, webServices, utils, companyDataService, authenticationService, $location) {
        $scope.showAddRelatedCompanyForm = false;
        $scope.relatedCompany = {};
        $scope.invoiceNumberFormatPreffix = "A";
        $scope.invoiceNumberFormatType = 0;
        $scope.invoiceNumberFormatStarting = 1;
        $scope.isValid = false;
        $scope.opened1 = false;
        $scope.opened2 = false;
        $scope.companySettings = [];
        $scope.dailyReminderSendingDays = [
            { "Day": "date.day.Mon", "Value": true },
            { "Day": "date.day.Tue", "Value": true },
            { "Day": "date.day.Wed", "Value": true },
            { "Day": "date.day.Thu", "Value": true },
            { "Day": "date.day.Fri", "Value": true },
            { "Day": "date.day.Sat", "Value": false },
            { "Day": "date.day.Sun", "Value": false }
        ];

        $scope.languages = [
            {
                "Id": "cs_CZ",
                "Value": "Česky"
            },
            {
                "Id": "de_DE",
                "Value": "Deutsch"
            },
            {
                "Id": "et_EE",
                "Value": "Eesti"
            },
            {
                "Id": "en_US",
                "Value": "English"
            },
            {
                "Id": "fr_FR",
                "Value": "Français"
            },
            {
                "Id": "fi_FI",
                "Value": "Suomi"
            },
            {
                "Id": 'hr_HR',
                "Value": 'Hrvatski',
            },
            {
                "Id": "lv_LV",
                "Value": "Latviešu"
            },
            {
                "Id": "lt_LT",
                "Value": "Lietuvių"
            },
            {
                value: 'nl_NL',
                text: 'Nederlands',
            },
            {
                "Id": "sr_SL",
                "Value": "Serbian"
            },
            {
                "Id": "sk_SK",
                "Value": "Slovenčina"
            },
            {
                "Id": 'bg_BG',
                "Value": 'Български',
            },
            {
                "Id": "ru_RU",
                "Value": "Русский"
            },
            {
                "Id": "sr_SC",
                "Value": "Српски"
            }
        ];
        $scope.roundingOptions = [
            {
                "Id": "0",
                "Value": "views.company.roundingOptions.off"
            },
            {
                "Id": "1",
                "Value": "views.company.roundingOptions.newInvoiceRow"
            }
            ,
            {
                "Id": "2",
                "Value": "views.company.roundingOptions.adjustBiggestAmount"
            }
        ];

        $scope.pricePrecisionOptions = [
            {
                "Id": "4",
                "Value": "views.company.pricePrecisionOptions.digits4"
            }
            ,
            {
                "Id": "7",
                "Value": "views.company.pricePrecisionOptions.digits7"
            }
        ];

        $scope.quantityPrecisionOptions = [
            {
                "Id": "4",
                "Value": "views.company.pricePrecisionOptions.digits4"
            }
            ,
            {
                "Id": "7",
                "Value": "views.company.pricePrecisionOptions.digits7"
            }
        ];

        $scope.confirmationFlowUserIdentifierOptions = [
            {
                "Id": "1",
                "Value": "views.company.confirmationFlowUserIdentifierOptions.personalCode"
            },
            {
                "Id": "2",
                "Value": "views.company.confirmationFlowUserIdentifierOptions.personalCodeAndCountry"
            }
            ,
            {
                "Id": "3",
                "Value": "views.company.confirmationFlowUserIdentifierOptions.email"
            }
        ];

        $scope.invoiceExportOptions = [
            {
                "Id": "0",
                "Value": "views.company.invoiceExportOptions.none"
            },
            {
                "Id": "1",
                "Value": "views.company.invoiceExportOptions.originalPDFOnly"
            },
            {
                "Id": "2",
                "Value": "views.company.invoiceExportOptions.allPDFFilesAndLinks"
            }
        ]

        $scope.KIEstonianSearchOptions = [
            {
                "Id": "0",
                "Value": "views.company.KIEstonianSearchOptions.off"
            },
            {
                "Id": "1",
                "Value": "views.company.KIEstonianSearchOptions.on"
            }
        ]

        $scope.InvoicesExportModes = [
            {
                "Id": "0",
                "Value": "views.company.InvoicesExportModes.disabled"
            },
            {
                "Id": "1",
                "Value": "views.company.InvoicesExportModes.onRequest"
            },
            {
                "Id": "2",
                "Value": "views.company.InvoicesExportModes.push"
            }
        ]

        const timeIntervals = [{ label: '', value: null }]

        for (let index = 0; index < 24; index++) {
            timeIntervals.push({ label: formattedTime(index), value: index })
        }

        $scope.timeIntervals = timeIntervals;

        $scope.selectedRounding = "0";
        $scope.roundingOptionId = null;
        $scope.selectedPricePrecision = "4";
        $scope.selectedPricePrecisionId = null;
        $scope.selectedQuantityPrecision = "4";
        $scope.selectedQuantityPrecisionId = null;
        $scope.selectedLanguage = "en_US";
        $scope.selectedLanguageId = null;
        $scope.selectedInvoiceExportOption = "0";
        $scope.selectedInvoiceExportOptionId = null;
        $scope.selectedKIEstonianSearchOption = "0";
        $scope.selectedKIEstonianSearchOptionId = null;
        $scope.invoicesExportMode = "1";
        $scope.invoicesExportModeId = null;

        $scope.dateOptions = {
            'year-format': "'yy'",
            'starting-day': 1
        };

        var fileImagerReader = new FileReader();

        $scope.open1 = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened1 = true;
        };

        $scope.open2 = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened2 = true;
        };

        $scope.save = function () {
            $scope.company.InvoiceNumberFormat = $scope.invoiceNumberFormatPreffix + "_";
            if ($scope.invoiceNumberFormatType == 1) {
                $scope.company.InvoiceNumberFormat += "Y__";
            }
            else if ($scope.invoiceNumberFormatType == 2) {
                $scope.company.InvoiceNumberFormat += "Y_M_";
            } else {
                $scope.company.InvoiceNumberFormat += "__";
            };
            $scope.company.InvoiceNumberFormat += $scope.invoiceNumberFormatStarting;

            if ($scope.timeInterval1) {
                const time = new Date();
                time.setHours($scope.timeInterval1, 0);
                $scope.company.DailyReportTime1 = time;
            }
            else {
                $scope.company.DailyReportTime1 = null;
            }

            if ($scope.timeInterval2) {
                const time = new Date();
                time.setHours($scope.timeInterval2, 0);
                $scope.company.DailyReportTime2 = time;
            }
            else {
                $scope.company.DailyReportTime2 = null;
            }

            if($scope.dailyReminderSendingDays) {
                var sendingDaysSetting = "";
                for (var i = 0; i < $scope.dailyReminderSendingDays.length; i++) {
                    sendingDaysSetting += $scope.dailyReminderSendingDays[i].Value ? "1," : "0,";
                }
                sendingDaysSetting = sendingDaysSetting.substring(0, sendingDaysSetting.length - 1);
                
                $scope.company.DailyReportDates = sendingDaysSetting;
            }

            api.boCompanies.saveCompany($scope.company).then(function (response) {
                $scope.company = response.data;

                if ($scope.company.DailyReportTime1 != null) {
                    $scope.timeInterval1 = new Date(response.data.DailyReportTime1).getHours().toString();
                }

                if ($scope.company.DailyReportTime2 != null) {
                    $scope.timeInterval2 = new Date(response.data.DailyReportTime2).getHours().toString();
                }

                var userManualSetting = $scope.companySettings.filter(function (item) {
                    if (item.Name == 'UserManual') {
                        return true;
                    }
                    return false;
                })[0];

                if ((userManualSetting.Value && userManualSetting.Value.indexOf('http://') !== 0 && userManualSetting.Value.indexOf('https://') !== 0) && userManualSetting.Value.length > 0) {
                    userManualSetting.Value = 'https://' + userManualSetting.Value;
                }

                var sendingDaysSetting = "";
                for (var i = 0; i < $scope.dailyReminderSendingDays.length; i++) {
                    sendingDaysSetting += $scope.dailyReminderSendingDays[i].Value ? "1," : "0,";
                }
                sendingDaysSetting = sendingDaysSetting.substring(0, sendingDaysSetting.length - 1);

                // TODO: fix angular.copy for post-1.6.9
                var settings = angular.copy($scope.companySettings);
                settings.push({ Id: $scope.roundingOptionId, Name: "CompanyRoundingOption", Value: $scope.selectedRounding });
                settings.push({ Id: $scope.selectedPricePrecisionId, Name: "MonetaryAmountsItemPricePrecision", Value: $scope.selectedPricePrecision });
                settings.push({ Id: $scope.selectedQuantityPrecisionId, Name: "MonetaryAmountsQuantityPrecision", Value: $scope.selectedQuantityPrecision });
                settings.push({ Id: $scope.selectedLanguageId, Name: "NewVendorNotificationLanguage", Value: $scope.selectedLanguage });
                settings.push({ Id: $scope.selectedInvoiceExportOptionId, Name: "IncludePdfIntoExport", Value: $scope.selectedInvoiceExportOption });
                settings.push({ Id: $scope.selectedKIEstonianSearchOptionId, Name: "IsKIEstonianSearchDisabled", Value: $scope.selectedKIEstonianSearchOption });
                settings.push({ Id: $scope.selectedCurrencyId, Name: "DefaultCurrency", Value: $scope.selectedCurrency });
                settings.push({ Id: $scope.sectedInvoicesExportModeId, Name: "InvoicesExportMode", Value: $scope.sectedInvoicesExportMode });
                settings.push({ Id: $scope.selectedConfirmationFlowUserIdentifierId, Name: "ConfirmationFlowUserIdentifier", Value: $scope.selectedConfirmationFlowUserIdentifier });

                webServices.addCompanySettings($scope.company.CompanyGuid, settings).then(function (response) {
                    LoadData(true);
                }, function (data) {
                    console.log(data);
                });
            }, function (data) {
                console.log(data);
            });
        }

        $scope.toggleRelatedCompanyForm = function () {
            $scope.showAddRelatedCompanyForm = !$scope.showAddRelatedCompanyForm;
        }

        //TODO: panna kasutama companySearchi ja kustutada funktsioon getCompaniesByNameOrRegistrationCodePart igast kihist ära
        $scope.getCompaniesByNameOrRegistrationCodePart = function (part) {
            if (utils.isEmpty(part) || part.length < 3)
                return [];
            return webServices.getCompanyByNameOrRegistrationCode(part).then(function (response) {
                var result = _.filter(response.data, function (company) {
                    return company.Id != $scope.company.Id;
                });
                return result;
            });
        }
        //------------START- Add new row realited company----------//
        $scope.counter = 3;

        $scope.addCompany = function () {
            $scope.Company.push('Firma ' + $scope.counter);
            $scope.counter++;
        }

        //------------END- Add new row realited company----------//

        $scope.setRelatedCompany = function ($item) {
            if ($item) {
                webServices.addRelatedCompany($scope.company.Id, $item.Id).then(function (response) {
                    $scope.company.ChildCompanies.push($item);
                    $scope.relatedCompany = null;
                }, function (data) {
                    console.log(data);
                });
            }
            else {
                $scope.relatedCompany = null;
            }
        }

        $scope.deleteRelatedCompany = function (companyId, relatedCompanyId, index) {
            webServices.deleteRelatedCompany($scope.company.Id, relatedCompanyId).then(function (response) {
                $scope.company.ChildCompanies.splice(index, 1);
            }, function (data) {
                console.log(data);
            });
        }

        $scope.showSettingNames = function ($item) {
            if ($item != null) {
                if ($item == 'IsConfirmAllAllowed') {
                    return 'views.company.index.ConfirmAllAllowed';
                }
                else if ($item == 'IsQuickConfirmAllowed') {
                    return 'views.company.index.QuickConfirmAllowed';
                }
                else if ($item == 'IsIWSImportEnabled') {
                    return 'views.company.index.IWSImportEnabled';
                }
                else if ($item == 'ShowInvoicePdfPreviewAutomatically') {
                    return 'views.company.index.ShowInvoicePdfPreviewAutomatically';
                }
                else if ($item == 'EstonianRegNoTransformationEnabled') {
                    return 'views.company.index.EstonianRegNoTransformationEnabled';
                }
                else if ($item == 'IsExportWithUnregisteredSupplierDenied') {
                    return 'views.company.index.ExportWithUnregisteredSupplierDenied';
                }
                else if ($item == 'IsTransactionRowsCheckEnabled') {
                    return 'views.company.index.IsTransactionRowsCheckEnabled';
                }
                else if ($item == 'IsTransactionRowsPenultimateCheckEnabled') {
                    return 'views.company.index.IsTransactionRowsPenultimateCheckEnabled';
                }
                else if ($item == 'IsVatSumDeviationWarningEnabled') {
                    return 'views.company.index.IsVatSumDeviationWarningEnabled';
                }
                else if ($item == 'IsVatCodeManualEditingEnabled') {
                    return 'views.company.index.IsVatCodeManualEditingEnabled';
                }
                else if ($item == 'CombinedRowName') {
                    return 'views.company.index.CombinedRowName';
                }
                else if ($item == 'SkipTransactionRowsVerificationWhenPOSpecified') {
                    return 'views.company.index.SkipTransactionRowsVerificationWhenPOSpecified';
                }
                else if ($item == 'AutoAssignToContactPerson') {
                    return 'views.company.index.AutoAssignToContactPerson';
                }
                else if ($item == 'CustomLoginUrl') {
                    return 'views.company.index.CustomLoginUrl';
                }
                else if ($item == 'OverwriteWithRelatedDimension') {
                    return 'views.company.index.OverwriteWithRelatedDimension';
                }
                else if ($item == 'ValidateExportXml') {
                    return 'views.company.index.ValidateExportXmlEstonianRegNo';
                }
                else if ($item == 'ExportCompanySupplierDataInsteadOfOriginal') {
                    return 'views.company.index.ExportCompanySupplierDataInsteadOfOriginal';
                }
                else if ($item == 'AllowProcessingZeroInvoices') {
                    return 'views.company.index.AllowProcessingZeroInvoices';
                }
                else if ($item == 'NotifyExternalApi') {
                    return 'views.company.index.NotifyExternalApi';
                }
                else if ($item == 'PushInvoiceAfterExportUrl') {
                    return 'views.company.index.PushInvoiceAfterExportUrl';
                }
                else if ($item == 'UseSupplierErpCodeAsKey') {
                    return 'views.company.index.UseSupplierErpCodeAsKey';
                }
                else if ($item == 'SkipKeywordForSupplierMatchingByBankAccount') {
                    return 'views.company.index.SkipKeywordForSupplierMatchingByBankAccount';
                }
                else if ($item == 'AllowLoginWithEmailAndPassword') {
                    return 'views.company.index.AllowLoginWithEmailAndPassword';
                }
                else if ($item == 'AllowLoginWithUsernameAndPassword') {
                    return 'views.company.index.AllowLoginWithUsernameAndPassword';
                }
                else if ($item == 'InvoicesExportMode') {
                    return 'views.company.index.InvoicesExportMode';
                }
                else if ($item == 'SerbiaMidLayerEndpoint') {
                    return 'views.company.index.SerbiaMidLayerEndpoint';
                }
                else {
                    return 'views.company.index.' + $item;
                }
            }
            return '';
        }

        function addZero(i) {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        }

        function LoadData(forceReloadSettings) {
            webServices.getCurrentCompany().then(function (response) {
                $scope.format = 'HH:mm';
                $scope.company = response.data;

                if ($scope.company.DailyReportTime1 != null) {
                    $scope.timeInterval1 = new Date(response.data.DailyReportTime1).getHours().toString();
                }

                if ($scope.company.DailyReportTime2 != null) {
                    $scope.timeInterval2 = new Date(response.data.DailyReportTime2).getHours().toString();
                }

                if (utils.isEmpty($scope.company.InvoiceNumberFormat))
                    $scope.company.InvoiceNumberFormat = "A_1";
                else {
                    var splitted = $scope.company.InvoiceNumberFormat.split("_");
                    $scope.invoiceNumberFormatPreffix = splitted[0];
                    if (splitted.length == 3) {
                        $scope.invoiceNumberFormatType = 1;
                        $scope.invoiceNumberFormatStarting = splitted[2];
                    }
                    else if (splitted.length == 4) {
                        $scope.invoiceNumberFormatType = 1;
                        $scope.invoiceNumberFormatStarting = splitted[3];
                    }
                    else
                        $scope.invoiceNumberFormatStarting = splitted[1];
                }

                $scope.RelatedCompanies = [];
                webServices.getAllCompaniesFromTheSameOrganizationByName().then(function (response) {
                    if (response && response.data) {
                        $scope.RelatedCompanies = response.data;
                    }
                }, function (data) {
                    console.log(data);
                });

                if ($scope.company.DailyReportDates) {
                    var vals = $scope.company.DailyReportDates.split(",");
                    for (var j = 0; j < vals.length; j++) {
                        $scope.dailyReminderSendingDays[j].Value = vals[j] == "1" ? true : false;
                    }
                }

                $scope.$broadcast('company:received', response.data);

                webServices.getCurrentCompanyLogo().then(function (response) {
                    if (response.data) {
                        $scope.company.Logo = response.data;
                    }
                    else {
                        $scope.company.Logo = null;
                    }
                }, function (data) {
                    console.log(data);
                });
            }, function (data) {
                console.log(data);
            });

            webServices.getCurrencyList().then(function (response) {
                if (response.data) {
                    $scope.currencyOptions = response.data;
                }
            }, function (data) {
                console.log(data);
            });

            companyDataService.getCurrentCompanySettings(forceReloadSettings).then(function (response) {
                if (response.data) {
                    $scope.companySettings = [];
                    var settingList = ['IsConfirmAllAllowed',
                        'IsQuickConfirmAllowed',
                        'IsKIEstonianSearchDisabled',
                        'IsIWSImportEnabled',
                        'ShowInvoicePdfPreviewAutomatically',
                        'EstonianRegNoTransformationEnabled',
                        'IsExportWithUnregisteredSupplierDenied',
                        'IsTransactionRowsCheckEnabled',
                        'IsTransactionRowsPenultimateCheckEnabled',
                        'IsVatSumDeviationWarningEnabled',
                        'IsVatCodeManualEditingEnabled',
                        'SkipTransactionRowsVerificationWhenPOSpecified',
                        'OverwriteWithRelatedDimension',
                        'CompanyRoundingOption',
                        'CombinedRowName',
                        'FirstTransactionRowName',
                        'SupportText',
                        'UserManual',
                        'SupportEmail',
                        'SupportPhone',
                        'IsERPIdEnabled',
                        'AutoAssignToContactPerson',
                        'NewVendorNotificationLanguage',
                        'ValidateExportXml',
                        'IncludePdfIntoExport',
                        'DefaultCurrency',
                        'AllowProcessingZeroInvoices',
                        'PushInvoiceAfterExportUrl',
                        'InvoicesExportMode',
                        'ConfirmationFlowUserIdentifier',
                        'ExportCompanySupplierDataInsteadOfOriginal',
                        'SerbiaMidLayerEndpoint',
                        'AllowDimensionApisToCreateCco',
                        'AllowLoginWithUsernameAndPassword',
                        'AllowLoginWithEmailAndPassword',
                        'MonetaryAmountsItemPricePrecision',
                        'MonetaryAmountsQuantityPrecision',
                        'UseSupplierErpCodeAsKey',
                        'SkipKeywordForSupplierMatchingByBankAccount'
                    ];
                    if (authenticationService.userData.isBoAdmin) {
                        settingList.push('IncludeInvoiceRowsIntoExport');
                        settingList.push('CustomLoginUrl');
                        $scope.isBoAdmin = true;
                    }
                    if(!(isSuf() && process.env.NODE_ENV === 'production')) {
                        settingList.push('NotifyExternalApi');
                    }
                    settingList.push('NewSupplierEmail');
                    
                    for (var i = 0; i < settingList.length; i++) {
                        var companySetting = _.find(response.data, function (setting) { return setting.Name === settingList[i] });

                        if (settingList[i] === "CompanyRoundingOption") {
                            if (companySetting != null) {
                                $scope.roundingOptionId = companySetting.Id;
                                $scope.selectedRounding = companySetting.Value;
                            }
                            else
                                $scope.selectedRounding = "0";
                        }
                        else if (settingList[i] === "MonetaryAmountsItemPricePrecision") {
                            if (companySetting != null) {
                                $scope.selectedPricePrecisionId = companySetting.Id;
                                $scope.selectedPricePrecision = companySetting.Value;
                            }
                            else
                                $scope.selectedPricePrecision = "4";
                        }
                        else if (settingList[i] === "MonetaryAmountsQuantityPrecision") {
                            if (companySetting != null) {
                                $scope.selectedQuantityPrecisionId = companySetting.Id;
                                $scope.selectedQuantityPrecision = companySetting.Value;
                            }
                            else
                                $scope.selectedQuantityPrecision = "4";
                        }
                        else if (settingList[i] === "ConfirmationFlowUserIdentifier") {
                            if (companySetting != null) {
                                $scope.selectedConfirmationFlowUserIdentifierId = companySetting.Id;
                                $scope.selectedConfirmationFlowUserIdentifier = companySetting.Value;
                            }
                            else
                                $scope.selectedLanguage = "en_US";
                        }
                        else if (settingList[i] === "NewVendorNotificationLanguage") {
                            if (companySetting != null) {
                                $scope.selectedLanguageId = companySetting.Id;
                                $scope.selectedLanguage = companySetting.Value;
                            }
                            else
                                $scope.selectedLanguage = "en_US";
                        }
                        else if (settingList[i] === "IncludePdfIntoExport") {
                            if (companySetting != null) {
                                $scope.selectedInvoiceExportOptionId = companySetting.Id;
                                $scope.selectedInvoiceExportOption = companySetting.Value;
                            }
                            else
                                $scope.selectedInvoiceExportOption = "0";
                        }
                        else if (settingList[i] === "IsKIEstonianSearchDisabled") {
                            if (companySetting != null) {
                                $scope.selectedKIEstonianSearchOptionId = companySetting.Id;
                                $scope.selectedKIEstonianSearchOption = companySetting.Value;
                            }
                            else {
                                $scope.selectedKIEstonianSearchOption = "0";
                            }
                        }
                        else if (settingList[i] === "DefaultCurrency") {
                            if (companySetting != null) {
                                $scope.selectedCurrencyId = companySetting.Id;
                                $scope.selectedCurrency = companySetting.Value;
                            }
                            else
                                $scope.selectedCurrency = "";
                        }
                        else if (settingList[i] === "InvoicesExportMode") {
                            if (companySetting != null) {
                                $scope.sectedInvoicesExportModeId = companySetting.Id;
                                $scope.sectedInvoicesExportMode = companySetting.Value;
                            }
                            else
                                $scope.sectedInvoicesExportMode = "";
                        }
                        else {
                            var setting = {
                                Id: companySetting != null ? companySetting.Id : null,
                                Name: settingList[i]
                            };
                            if (companySetting == null || companySetting.Value === CompanySettingStatus.Enabled || companySetting.Value === CompanySettingStatus.Disabled || companySetting.Value == null) {
                                setting.Value = companySetting != null && companySetting.Value === CompanySettingStatus.Enabled ? 1 : 0;
                                setting.Type = "bool";
                            } else {
                                setting.Value = companySetting.Value;
                                setting.Type = "text";
                            }

                            $scope.companySettings.push(setting);
                        }
                    }
                }
            }, function (data) {
                console.log(data);
            });
        }

        fileImagerReader.onload = function (event) {
            if (!$scope.company.Logo) {
                $scope.company.Logo = null;
            }
            $scope.company.Logo = utils.getBase64String(fileImagerReader.result);
        }

        $scope.fileImageAdded = function ($file, $event) {
            $scope.image = $file;
            $scope.imageFileName = "yes";
            fileImagerReader.readAsArrayBuffer($file.file);
        }

        $scope.removeImage = function ($flow) {
            if ($scope.image && $flow) {
                for (var i = 0; i < $flow.files.length; i++) {
                    if ($flow.files[i].uniqueIdentifier == $scope.image.uniqueIdentifier) {
                        $flow.files.splice(i, 1);
                        $scope.image = null;
                    }
                }
            }

            $scope.image = null;
            $scope.company.Logo = null;
        }

        function formattedTime(number) {
            return `${number.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            })}:00 - ${(++number == 24 ? 0 : number).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            })}:00`
        }

        LoadData();
    }
]);