import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import EmptyPageSvg from '../Icon/icons/empty-page.svg';
import NotAllowedSvg from '../Icon/icons/empty-page-not-allowed.svg';
import AllowedSvg from '../Icon/icons/empty-page-allowed.svg';
import { Button, ButtonType, ButtonIconPlacement } from '../Buttons/Button';
import { ICONS } from '../Icon/Icon';

import './EmptySearchResults.scss';

export enum EmptyPageType {
    DEFAULT_PAGE,
    NOT_ALLOWED,
    ALLOWED,
}

interface EmptySearchResults extends WithTranslation {
    buttons?: React.ReactNode;
    headerTitle?: string;
    resetFiltersCb?: React.MouseEventHandler<HTMLElement>;
    subTitle?: string;
    title?: string;
    type?: EmptyPageType;
}

const EmptySearchResults = ({ headerTitle, title, subTitle, resetFiltersCb, buttons, type, t }: EmptySearchResults) => {
    return (
        <div className="empty-page">
            <div className="content-wrapper">
                <svg>
                    <use xlinkHref={`#${type === EmptyPageType.ALLOWED ? AllowedSvg.id : type === EmptyPageType.NOT_ALLOWED ? NotAllowedSvg.id : EmptyPageSvg.id}`}></use>
                </svg>
                <h4>{headerTitle || t('component.emptySearchResults.nothingToShow')}</h4>
                {title && <p>{title}</p>}
                {subTitle && <p>{subTitle}</p>}
                {buttons && buttons}
                {resetFiltersCb && (
                    <Button className="reset-filters-btn" buttonType={ButtonType.ICON_TEXT} icon={ICONS.RESET} iconPlacement={ButtonIconPlacement.LEFT} onClick={resetFiltersCb}>
                        {t('component.emptySearchResults.resetFiltering')}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default withTranslation()(EmptySearchResults);
