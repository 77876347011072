import { LOCATION_CHANGE } from 'connected-react-router';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { OrganizationShort, BackOfficeOrganizationDTO } from '../../../../../services/types/BoApiTypes';
import { LoadableData } from '../../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../../rootReducer';

import { saveOrganizationActions, setActiveBoOrganization, showEditBoOrganizationModal } from './EditBoOrganizationModalActions';

class State {
    activeBoOrganization: OrganizationShort = null;
    isEditBoOrganizationModalOpen = false;
    saveOrganizationActionLoadable = new LoadableData<BackOfficeOrganizationDTO, OrganizationShort>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        setActiveBoOrganization,
        (state, action): State => {
            return {
                ...state,
                activeBoOrganization: action.payload,
            };
        },
    )
    .addReducer(
        showEditBoOrganizationModal,
        (state, action): State => {
            return {
                ...state,
                isEditBoOrganizationModalOpen: action.payload,
            };
        },
    )
    .addReducer(
        saveOrganizationActions.request,
        (state): State => {
            return {
                ...state,
                saveOrganizationActionLoadable: state.saveOrganizationActionLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        saveOrganizationActions.success,
        (state, action): State => {
            return {
                ...state,
                saveOrganizationActionLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        saveOrganizationActions.error,
        (state, action): State => {
            return {
                ...state,
                saveOrganizationActionLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'new-BoOrganization-modal',
    whitelist: [],
};

export default persistReducer(persistConfig, reducer);

export { State as EditBoOrganizationModalState };

export const selectActiveBoOrganization = (state: GlobalState) => state.editBoOrganizationModal.activeBoOrganization;
export const selectIsEditBoOrganizationModalOpen = (state: GlobalState) => state.editBoOrganizationModal.isEditBoOrganizationModalOpen;
export const selectSaveOrganizationLoadable = (state: GlobalState) => state.editBoOrganizationModal.saveOrganizationActionLoadable;
