(function() {
    'use strict';

    angular.module('dstreamApp.directives').directive('uibModalWindow', modalDialog);

    /* @ngInject */
    function modalDialog() {
        return {
            restrict: 'A',
            link: linkFunc,
        };

        function linkFunc(scope, element) {
            const draggableElement = angular.element(document.querySelector('.modal-dialog .modal-content'));
            if (draggableElement && element.hasClass('draggable')) {
                draggableElement.draggable({ handle: '.modal__header', containment: element, scroll: false });
                scope.$on('$destroy', function() {
                    draggableElement.draggable('destroy');
                });
            }
        }
    }
})();
