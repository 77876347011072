(function() {
    'use strict';

    angular.module('dstreamApp.blocks', [
        'blocks.auth',
        'blocks.router',
        'blocks.websocket',
        'blocks.utility',
        'blocks.companyData',
        'blocks.invoice'
    ]);
})();