import classNames from 'classnames';
import { noop } from 'lodash-es';
import * as React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import { bemify } from '../../common/utils/bemify';
import { DataId } from '../../common/utils/dataId';
import Icon from '../Icon/Icon';

// use css modules here to avoid conflicting with old .link styles
const styles = bemify(require('./Link.scss'));

export interface LinkProps extends Partial<RouterLinkProps> {
    linkType?: LinkType;
    button?: boolean;
    className?: string;
    iconLeft?: string;
    iconColor?: string;
    iconRight?: string;
    linkTag?: string;
    /**
     * for label & input support
     */
    htmlFor?: string;
    disabled?: boolean;
    dataId?: DataId;
}

export enum LinkType {
    TEXT = 'TEXT',
}

interface WrapperProps {
    children: React.ReactElement<any>;
}

// can be replaced once React.Fragment once Enzyme supports them for mounted components: https://github.com/airbnb/enzyme/issues/1213
export const LinkContentWrapper: React.FunctionComponent = (props: WrapperProps) => props.children;

// more like a visual link, can be button or label also, depending what action is behind the link
const Link: React.FunctionComponent<LinkProps> = (props: LinkProps) => {
    const { className, children, href, button, iconColor, iconLeft, iconRight, to, linkType, linkTag, disabled, dataId, ...restProps } = props;

    const LinkTag: any = linkTag ? linkTag : button ? 'button' : 'a';
    const classes = classNames(
        className,
        styles.link,
        { [styles.link$iconLeft]: !!iconLeft },
        { [styles.link$iconRight]: !!iconRight },
        { [styles.link$text]: linkType === LinkType.TEXT },
        { [styles.link$disabled]: !!disabled },
    );
    const linkContent = (
        <LinkContentWrapper>
            {iconLeft && <Icon name={iconLeft} className={styles.link__icon} color={iconColor} />}
            {children}
            {iconRight && <Icon name={iconRight} className={styles.link__icon} />}
        </LinkContentWrapper>
    );
    // button or custom "link"
    if (linkTag || button) {
        let labelProps;
        if (linkTag === 'label') {
            // add tabIndex and keyDown handler to make file input usable with keyboard
            labelProps = {
                tabIndex: 0,
                onKeyDown: (e: React.KeyboardEvent<HTMLLabelElement>) => {
                    if (e.key === 'Enter') {
                        // simulate click
                        (e.target as HTMLLabelElement).click();
                    }
                },
            };
        }
        return (
            <LinkTag disabled={disabled} {...restProps} className={classes} onClick={props.onClick} data-id={dataId} type={button && 'button'} {...labelProps}>
                {linkContent}
            </LinkTag>
        );
    }

    // external link
    if (href) {
        return (
            <LinkTag {...restProps} href={href} className={classes} onClick={props.onClick} target={props.target} data-id={dataId}>
                {linkContent}
            </LinkTag>
        );
    }

    // inside application link
    return (
        <RouterLink {...restProps} to={to} className={classes} data-id={dataId}>
            {linkContent}
        </RouterLink>
    );
};

Link.defaultProps = {
    target: '', // _self here would cause unwanted reloads with react-router
    onClick: noop,
};

export default Link;
