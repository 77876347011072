import { createAction } from 'redux-actions';
import { isEmpty } from 'lodash-es';

import { DispatchThunk } from '../../storeConfig';
import { GlobalState } from '../../rootReducer';
import api from '../../services/ApiServices';
import {
    setUserCompaniesSearchResultAction,
    setNoMoreCompaniesSearchResultsAction,
    getCurrentUserGroupMember,
    areWaitingInvoicesCountUpdatingAction,
    getAllInvoiceCounts,
} from '../../common/user/UserActions';
import { loadableDataActionsWithRequest } from '../../common/utils/LoadableData';
import { CompanySwitcherSearchParams } from './ChangeCompanyReducer';
import { PagedListContainer, BaseSearch, CompanyInvoiceCountDTO } from '../../services/types/ApiTypes';
import { BackOfficeCompanyDTOExtended, BackOfficeCompanyDTO } from '../../services/types/BoApiTypes';
import { createRequest } from '../../common/user/userSettingUtil';

const ns = 'changeCompany/';

export const showChangeCompanyAction = createAction<boolean>(`${ns}SHOW_CHANGE_COMPANY`);

export const setActiveCompanyGuid = createAction<string>(`${ns}SET_ACTIVE_COMPANY_GUID`);

export const updateWaitingInvoicesCountAction = createAction<{ companyGuid: string; count: number }>(`${ns}UPDATE_WAITING_INVOICES_COUNT`);

export const clearCompanySwitcherListLoadable = createAction(`${ns}CLEAR_COMPANY_SWITCHER_LIST`);

export const getcompanySwitcherListLoadable = loadableDataActionsWithRequest<CompanySwitcherSearchParams, PagedListContainer<BackOfficeCompanyDTOExtended>>(`${ns}GET_COMPANY_SWITCHER_LIST`);

export function showChangeCompany(show: boolean) {
    return async (dispatch: DispatchThunk) => {
        !show && dispatch(setUserCompaniesSearchResultAction([]));
        dispatch(showChangeCompanyAction(show));
    };
}

function addInvoiceCountToCompanies(companies: BackOfficeCompanyDTO[], counts: CompanyInvoiceCountDTO[]) {
    const companiesWithCounts: BackOfficeCompanyDTOExtended[] = [];
    companies.forEach((comp) => {
        const countObj = counts.find((count) => count.CompanyGuid === comp.CompanyGuid);
        // if no countObj found, it means the count is 0 (BE returns only existing counts CompanyInvoiceCountDTO-s)
        companiesWithCounts.push({ ...comp, WaitingInvoiceCount: countObj?.Count || 0 });
    });
    return companiesWithCounts;
}

export function searchUserCompanies(searchValue: string, loadMore?: boolean, onMount?: boolean) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const searchParams: BaseSearch = {
            ...createRequest(searchValue, null, loadMore ? getState().changeCompany.searchParams.PagingOptions.Page + 1 : 1, 25),
        };
        try {
            dispatch(getcompanySwitcherListLoadable.request(searchParams));
            const response = await api.user.getUserCompanies(searchParams);
            const companies = response?.data;
            isEmpty(companies) && dispatch(setNoMoreCompaniesSearchResultsAction(true));
            const newCountsResponse = isEmpty(companies) ? undefined : await api.company.getWaitingInvoicesCountsForCompanyList(companies?.Items?.map((c) => c.CompanyGuid));
            const newCompaniesWithCounts = addInvoiceCountToCompanies(companies?.Items || [], newCountsResponse?.data);
            const oldCompaniesWithCounts = getState().changeCompany.companySwitcherListLoadable?.payload?.Items || [];
            response.data.Items = onMount ? newCompaniesWithCounts : [...oldCompaniesWithCounts, ...newCompaniesWithCounts];
            dispatch(
                getcompanySwitcherListLoadable.success({
                    request: searchParams,
                    result: response.data,
                }),
            );
        } catch (e) {
            console.error(e);
            getcompanySwitcherListLoadable.error({
                request: searchParams,
                result: e,
            });
        }
    };
}

export function clearUserCompanies() {
    return async (dispatch: DispatchThunk) => {
        dispatch(clearCompanySwitcherListLoadable());
    };
}

export function updateAllWaitingInvoiceCounts(companies?: BackOfficeCompanyDTOExtended[]) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
                await dispatch(getCurrentUserGroupMember());
            }
            const state = getState();
            companies = companies || state.changeCompany.companySwitcherListLoadable?.payload?.Items;
            if (!companies) {
                return;
            }
            const {
                groupMemberCommonLoadable: { payload: groupMember },
            } = state.user;

            dispatch(areWaitingInvoicesCountUpdatingAction({ areWaitingInvoicesCountsUpdating: true }));

            for (const company of companies) {
                dispatch(
                    updateWaitingInvoicesCountAction({
                        companyGuid: company.CompanyGuid,
                        count: undefined,
                    }),
                );
            }

            await dispatch(getAllInvoiceCounts());

            for (const company of companies) {
                // can sometimes have an empty user and pollute console log
                if (groupMember) {
                    const count = getState().user.allInvoiceCounts.find((p) => p.CompanyGuid === company.CompanyGuid);
                    dispatch(
                        updateWaitingInvoicesCountAction({
                            companyGuid: company.CompanyGuid,
                            count: count?.Count || 0,
                        }),
                    );
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(areWaitingInvoicesCountUpdatingAction({ areWaitingInvoicesCountsUpdating: false }));
        }
    };
}

export function updateSingleCompanyWaitingInvoiceCount(company: BackOfficeCompanyDTOExtended) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const state = getState();
            const groupMember = state.user.groupMemberCommonLoadable.payload;
            if (!groupMember) {
                return;
            }
            dispatch(
                updateWaitingInvoicesCountAction({
                    companyGuid: company.CompanyGuid,
                    count: undefined,
                }),
            );

            const countResponse = await api.company.getWaitingInvoicesCountsForCompanyList([company.CompanyGuid]);
            dispatch(
                updateWaitingInvoicesCountAction({
                    companyGuid: company.CompanyGuid,
                    count: countResponse.data[0]?.Count || 0,
                }),
            );
        } catch (e) {
            console.error(e);
        }
    };
}
