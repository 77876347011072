import { LOCATION_CHANGE } from 'connected-react-router';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { InvoiceCustomFieldDTO } from '../../../../services/types/ApiTypes';
import { LoadableData } from '../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../rootReducer';
import { saveHeaderExtensionActions, setActiveHeaderExtension, showEditHeaderExtensionModalAction } from './EditHeaderExtensionModalActions';

class State {
    activeHeaderExtension: InvoiceCustomFieldDTO | null;
    isEditHeaderExtensionModalOpen = false;
    saveHeaderExtensionActionLoadable = new LoadableData<InvoiceCustomFieldDTO, InvoiceCustomFieldDTO>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        setActiveHeaderExtension,
        (state, action): State => {
            return {
                ...state,
                activeHeaderExtension: action.payload,
            };
        },
    )
    .addReducer(
        showEditHeaderExtensionModalAction,
        (state, action): State => {
            return {
                ...state,
                isEditHeaderExtensionModalOpen: action.payload,
            };
        },
    )
    .addReducer(
        saveHeaderExtensionActions.request,
        (state): State => {
            return {
                ...state,
                saveHeaderExtensionActionLoadable: state.saveHeaderExtensionActionLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        saveHeaderExtensionActions.success,
        (state, action): State => {
            return {
                ...state,
                saveHeaderExtensionActionLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        saveHeaderExtensionActions.error,
        (state, action): State => {
            return {
                ...state,
                saveHeaderExtensionActionLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'new-HeaderExtension-modal',
    whitelist: [],
};

export default persistReducer(persistConfig, reducer);

export { State as EditHeaderExtensionModalState };

export const selectActiveHeaderExtension = (state: GlobalState) => state.editHeaderExtensionModal.activeHeaderExtension;
export const selectIsEditHeaderExtensionModalOpen = (state: GlobalState) => state.editHeaderExtensionModal.isEditHeaderExtensionModalOpen;
