import { AxiosInstance, AxiosResponse } from 'axios';
import { BackOfficeAssistantDTO } from '../types/BoApiTypes';

export default class BoAssistantsService {
    constructor(private axios: AxiosInstance) {}

    getAssistantsForUser = async (userGuid: string): Promise<AxiosResponse<BackOfficeAssistantDTO[]>> => {
        return this.axios.post('/Assistant/GetAssistantsForUser', { UserGuid: userGuid });
    };

    saveAssistantsForUser = async (userGuid: string, assistants: BackOfficeAssistantDTO[]) => {
        return this.axios.post('/Assistant/SaveAssistantsForUser', { userGuid, assistants });
    };
}
