import classNames from 'classnames';
import * as React from 'react';

export interface ContentBlockHeadingProps {
    children?: React.ReactNode;
    type?: ContentBlockHeadingType;
    className?: string;
    dataId: string;
}

export enum ContentBlockHeadingType {
    H2 = 'H2',
    H3 = 'H3',
    DEFAULT = 'DEFAULT',
    BOLD = 'BOLD',
}

const ContentBlockHeading: React.FunctionComponent<ContentBlockHeadingProps> = ({ children, type, className, dataId }: ContentBlockHeadingProps) => {
    const classes = classNames('content-block__heading', `content-block__heading--${type.toLowerCase()}`, className);
    return (
        <h2 data-id={dataId || undefined} className={classes}>
            {children}
        </h2>
    );
};

ContentBlockHeading.defaultProps = {
    type: ContentBlockHeadingType.DEFAULT,
};

export default ContentBlockHeading;
