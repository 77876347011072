import { isEmpty } from 'lodash-es';

"use strict";

angular
    .module("dstreamApp.components")
    .controller("DsValidationFailedModalController", DsValidationFailedModalController);

function DsValidationFailedModalController (
    $scope,
    $uibModalInstance,
    validations,
    errors
) {
    var ctrl = this;
    ctrl.confirmAll = false;

    ctrl.$onInit = init;
    ctrl.close = close;
    ctrl.proceed = proceed;
    ctrl.getMessage = getMessage;

    $scope.invoices = '';

    function init () {
        const keys = Object.keys(validations);
        if (keys.length > 0) {
            const verdict = keys.reduce((key, lastVerdict) => ( validations[key] && lastVerdict ), false);
            if (verdict) {
                $uibModalInstance.close({accepted: true})
            }
        }
        if (!isEmpty(errors)) {
            $scope.warnings = errors;
        }
        if (isEmpty(validations)) {
            ctrl.confirmAll = true;
            $scope.invoices = errors[0].invoices.map(invoice => invoice.Number).join(', ');
        }
    }

    function close () {
        $uibModalInstance.dismiss("user close");
    }

    function proceed() {
        if (ctrl.confirmAll) {
            $uibModalInstance.close({confirmAll: true})
        } else {
            $uibModalInstance.close({passedValidation: false});
        }
    }

    function getMessage() {
        if (isEmpty(validations)) {
            return '';
        }
        if (!validations.accessValid && !validations.dataValid) {
            return 'component.DashboardConfirmation.AllFail';
        }
        if (!validations.accessValid && validations.dataValid) {
            return 'component.DashboardConfirmation.AccessFail';
        }
        if (validations.accessValid && !validations.dataValid) {
            return 'component.DashboardConfirmation.DataFail';
        }
        return '';
    }
}
