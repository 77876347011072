import { cloneDeep } from 'lodash-es';
import { newInvoice } from '../../src/common/constants/invoiceConstants';
import { getInvoiceDataLoadable} from '../../src/views/invoice-details/components/invoice-export-management/InvoiceExportManagementActions';
import { updateInvoice } from '../../src/views/invoice-details/components/invoice-slider/InvoiceSliderActions';
import { showAddOrEditSupplierModal } from "../../src/components/EditSupplierModal/EditSupplierModalActions";
import { setConfirmationFlowLoading } from "../../src/views/invoice-details/components/invoice-confirmations/InvoiceConfirmationsAction";
import { setCurrentCompanySettingsLoading, setCurrentCompanySettings } from "../../src/common/company/CompanyActions";
import { selectPdfToOpen } from '../../src/components/PDFViewer/FileViewReducer';
import { CompanySettingStatus } from '../../src/views/settings/company-settings/components/Settings/CompanySettingsListHelper';
import { checkUserOnlyRoleExpenseCreator } from '../../src/common/user/userPermissionUtil';

"use strict";
// TODO: This controller acts as both, a controller and a service...
// This SHOULD NOT be the case! This needs to be broken down into a service and a controller
angular.module("dstreamApp.controllers")
  .controller("invoiceConfirmationController", [
    "$rootScope",
    "$scope",
    "$location",
    "$uibModal",
    "$filter",
    "$ngRedux",
    "_",
    "$routeParams",
    "$window",
    "$sce",
    "webServices",
    "localStorageService",
    "truncate",
    "notifyService",
    "utils",
    "$timeout",
    "invoiceService",
    "constants",
    "authenticationService",
    "companyDataService",
    "utilityService",
    "activeInvoiceService",
    "$analytics",
    function (
      $rootScope,
      $scope,
      $location,
      $uibModal,
      $filter,
      $ngRedux,
      _,
      $routeParams,
      $window,
      $sce,
      webServices,
      localStorageService,
      truncate,
      notifyService,
      utils,
      $timeout,
      invoiceService,
      constants,
      authenticationService,
      companyDataService,
      utilityService,
      activeInvoiceService,
      ga,
    ) {
      var ctrl = this;

      /// ///
      /// Ui variables
      /// //
      $scope.fileUrl = "";
      $scope.isMessage = false;
      $scope.isArchiveView = $routeParams.archive;
      $scope.message = "";
      $scope.approveAllComment = "";
      $scope.approveAllNextConfirmer = "";
      $scope.companies = [];
      $scope.moreCompanies = true;
      $scope.isPdfOpen = false;
      $scope.accountDistributionTypes = [
        {
          Id: 0,
          Name: $filter("translate")("controller.invoiceConfirmationController.Supplier_specific")
        },
        {
          Id: 1,
          Name: $filter("translate")("controller.invoiceConfirmationController.Universal")
        }
      ];
      $scope.accountDistributionTemplate = {
        Name: "",
        AccountDistributionTemplateItems: [],
        TemplateExpressions: []
      };
      $scope.distributionTemplateId = "";
      $scope.distributionTemplates = [];
      $scope.masks = [{Expression: ""}];
      $scope.invoicePrice = 0;
      $scope.invoiceTotal = 0;
      $scope.invoiceVat = 0;
      $scope.accountDistributionItemsVat = [];
      $scope.utils = utils;
      $scope.measures = constants.Units;
      $scope.isInvoiceRowsPartOfInvoice = true;
      $scope.isApproveAllPartOfInvoice = true;
      $scope.isAccountingRowsPartOfInvoice = true;
      $scope.BeneficiaryId = null;
      $scope.BeneficiaryRegCode = null;
      $scope.limit2 = 1;
      $scope.switchingCompany = false; // this is used when mobile app needs to switch company before displaying the invoice
      $scope.limitInvoice2 = 1;
      $scope.isRelatedFilesCollapsed = true;
      $scope.isNewInvoice = !$routeParams.id;
      $scope.$parent.header.top_menu_style = "";
      $scope.newFileName = null;
      $scope.isTransactionRowsCheckEnabled = true;
      $scope.IsTransactionRowsPenultimateCheckEnabled = true;
      $scope.skipTransactionRowsVerificationWhenPOSpecified = false;
      $scope.invoiceId = $routeParams.id;
      $scope.transactionRowsLoaded = false;
      $scope.invoiceTransactionRowsPaginator = {
        page: 1,
        count: localStorageService.get("accountingRowsPageSize") || 5,
        total: 0
      };
      $scope.fields = [];
      /// ///
      /// Script variables
      /// //
      // var confirmers = []
      var supplier = {
        SupplierName: "",
        SupplierRegCode: "",
        SupplierAddress: "",
        SupplierRepresentative: ""
      };

      var confirmDashboardInvoiceEvent = $rootScope.$on('confirmDashboardInvoice', function(e, invoice) {
        const action = invoiceService.createInvoiceAction('Approved', invoice.Id, '', null);
        approveInvoice(action);
      });

      function init() {

          const params = $location.search();

          // when we have a companyGuid in the url, this means that we need to switch the company before loading the invoice data
          // otherwise invoice companyGuid might not match currently active company
          // this is used when mobile app opens invoices in webview and needs the possibility to set the company that the invoice belongs to
          // and also when changing invoice Buyer from Invoice details header (Additional info)
          if (params.companyGuid) {
              $scope.switchingCompany = true; // prevents from inner components from loading (they might fetch their own data that is dependant on active company)
              authenticationService.changeCompany(params.companyGuid).then(function () {
                  $location.search('companyGuid', null);
                  $scope.switchingCompany = false;
                  onViewReady();
              }).catch(function() {
                  // still show page when company switch fails
                  $scope.switchingCompany = false;
                  onViewReady();
              });
            } else if (params.CompanyGuid) {
              if ($rootScope.companyGuid !== params.CompanyGuid) {
                $scope.switchingCompany = true; // prevents from inner components from loading (they might fetch their own data that is dependant on active company)
                authenticationService.changeCompany(params.CompanyGuid).then(function () {
                    $location.search('CompanyGuid', null);
                    $scope.switchingCompany = false;
                    if(params.InvoiceId) {
                      window.location.href = `/app/#/invoiceconfirmation/${params.InvoiceId}`;
                    } else {
                      onViewReady();
                    }
                }).catch(function() {
                    // still show page when company switch fails
                    $scope.switchingCompany = false;
                    onViewReady();
                });
            } else {
              $location.search('CompanyGuid', null);
              if (params.InvoiceId) {
                window.location.href = `/app/#/invoiceconfirmation/${params.InvoiceId}`;
              } else {
                onViewReady();
              }
            }
          } else {
              onViewReady();
          }
      }

      function onViewReady() {
          getGroupMembers()
              .then(setCompanySettings)
              .then(function () {
                  if ($routeParams.id) {
                      getActiveInvoiceData($routeParams.id);
                      ga.eventTrack('Navigate', {category: 'Open Invoice', label: 'Open invoice'});
                  } else {
                      modifyNewInvoiceFromEmptyTemplate();
                  }
              });
      }

      /// ///
      /// UI functions
      /// //
      function getActiveInvoiceData(invoiceId, page, callbackEmit) {
        $rootScope.$broadcast('activeInvoiceLoading', invoiceId);
        $ngRedux.dispatch(setConfirmationFlowLoading(true))
        return activeInvoiceService.activeInvoice(invoiceId, callbackEmit).then(
          function (response) {
            if (response) {
                $scope.invoice = cloneDeep(response);
                $scope.invoiceId = $scope.invoice.Id;
                $scope.originalInvoiceCompanyId = response.CompanyGuid;
                $rootScope.$broadcast('invoiceLoaded', $scope.invoice);
                $rootScope.$broadcast('activeInvoiceLoaded', $scope.invoice.Id);
                $rootScope.$emit("loadTransactionRows", page);
                $ngRedux.dispatch(setConfirmationFlowLoading(false))
                const accountingDate = $scope.invoice.AccountingDate ? $scope.invoice.AccountingDate : undefined;
                companyDataService.getCustomCostObjectives(false, false, undefined, accountingDate);
                return $scope.invoice;
            } else {
                notifyService.error('interceptorsFactory.ErrorWhileProcessingData', 'interceptorsFactory.Error', true);
            }
          }
        );
      }

      function getMinimalActiveInvoiceData(invoiceId, callbackEmit) {
        $rootScope.$broadcast('activeInvoiceLoading', invoiceId);
        $ngRedux.dispatch(setConfirmationFlowLoading(true))
        return activeInvoiceService.minimalActiveInvoice(invoiceId, callbackEmit).then(
          function (response) {
            if (response) {
                $scope.invoice = cloneDeep(response);
                $scope.invoiceId = $scope.invoice.Id;
                $scope.originalInvoiceCompanyId = response.CompanyGuid;
                $rootScope.$broadcast('invoiceLoaded', $scope.invoice);
                $rootScope.$broadcast('activeInvoiceLoaded', $scope.invoice.Id);
                return $scope.invoice;
              } else {
                notifyService.error('interceptorsFactory.ErrorWhileProcessingData', 'interceptorsFactory.Error', true);
              }
              $ngRedux.dispatch(setConfirmationFlowLoading(false))
          }
        ).catch(() => {
          $ngRedux.dispatch(setConfirmationFlowLoading(false))
          notifyService.error('interceptorsFactory.ErrorWhileProcessingData', 'interceptorsFactory.Error', true);
        });
      }

      $scope.pdfContainerHeight = function () {
        var v1 = $window.innerHeight;
        var v2 = angular.element("#pnlLeft")[0].offsetHeight;
        var v3 = angular.element("#pnlRelatedFiles")[0].offsetHeight;
        return v2 - v3 - 8 > v1 - 280 ? v2 - v3 - 8 : v1 - 280;
      };
      $scope.pdfHeight = function (fileUrl) {
        $scope.minHeight = fileUrl ? 1000 : 300;
        return {"height": $scope.minHeight + "px"};
      };
      $scope.toggleRelatedFile = function () {
        $scope.isRelatedFilesCollapsed = !$scope.isRelatedFilesCollapsed;
      };
      // $scope.toggleDetail = function (id) {
      //   var index = expandedRows.indexOf(id)
      //   if (index === -1) {
      //     // expand
      //     expandedRows.push(id)
      //   } else {
      //     // collapse
      //     expandedRows.splice(index, 1)
      //   }
      // }
      $scope.startEdit = function (index) {
        return index + 1;
      };
      $scope.cancelEdit = function () {
        return false;
      };
      $scope.isExpanded = function (id) {
        return isExpanded(id);
      };
      $scope.isEmpty = function (item) {
        return (item === null || item === undefined || item.length === 0);
      };
      $scope.notNumber = function (item) {
        return item === null || item === undefined || isNaN(item);
      };
      $scope.openCalendar = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.opened = true;
      };
      $scope.openCalendar2 = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.opened2 = true;
      };
      $scope.openCalendar3 = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.opened3 = true;
      };
      $scope.openCalendar4 = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.opened4 = true;
      };
      $scope.entryForm = {
        showButtons: false
      };
      $scope.showButtons = function () {
        $scope.entryForm.showButtons = true;
      };
      $scope.cancel = function (row) {
        // clearInvoiceEntryRow(row)
        $scope.entryForm.showButtons = false;
      };
      $scope.clearItem = function (item) {
        if ($scope.isEmpty(item)) {
          return;
        }
        item.FileName = null;
        item.FileUrl = null;
      };
      $scope.rowPriceWithVAT = function (row, data) {
        if ($scope.isEmpty(row)) {
          return "";
        }
        row.Price = Number(data).toFixed(2);
        row.VAT = $scope.calculateVAT(row);
        return row.VAT;
      };
      $scope.rowPriceWithVatRate = function (row, data) {
        if ($scope.isEmpty(row)) {
          return "";
        }
        row.VatRate = Number(data).toFixed(2);
        row.Total = $scope.calculateTotalSum(row);
        return row.Total;
      };
      $scope.calculateVAT = function (row) {
        if (row === null || row === undefined || $scope.isEmpty(row.Price) || $scope.isEmpty(row.VatRate)) {
          return null;
        }
        row.VAT = (row.Price * row.VatRate / 100).toFixed(2);
        return row.VAT;
      };
      $scope.calculateTotalSum = function (row) {
        if (row === null || row === undefined || $scope.isEmpty(row.Price) || $scope.isEmpty(row.VAT)) {
          return null;
        }
        row.Total = (Number(row.Price) + Number(row.VAT)).toFixed(2);
        return row.Total;
      };
      $scope.setUrl = function (item) {
        if (item.IsPdf) {
          // its Pdf and setting fileUrlconst
          $scope.fileUrl = `${window.publicUrl}/app/pdf/web/viewer.html?file=` + item.FileUrl;
        } else if (item.FileUrl && item.Content === "") {
          // its link
          window.open(item.FileUrl);
        } else {
          // else its a file that needs to be downloaded
          webServices.downloadInvoiceFileByUrl(item.DownloadUrl).then(function (response) {
            // TODO: need to declare global variable for eslint
            var file = new Blob([response.data], {type: "text/xml;charset=utf-8"});
            // TODO: need to declare global variable for eslint
            saveAs(file, item.FileName);
          });
        }
      };
      $scope.$watch("message", function () {
        $scope.sanitized = $sce.trustAsHtml(truncate($scope.message, "<a><br><div><span><p><img><li><ul><font><table><tr><td><thead><h1><h2><h3><h4><h5><h6><u><i><strong><b><link>"));
      });
      $scope.getMeasures = function (id) {
        var translation = _.find($scope.measures, function (r) {
          return r.LookupId === id;
        });
        return translation ? translation.Value : "";
      };
      $scope.getAccountDistributionItemVAT = function (item, row) {
        if (item && item.Amount) {
          var percent = getAccountingRowVATPercent(row);
          return item.Amount * percent / 100.0;
        }
        return 0;
      };
      $scope.updateApproveAllNextConfirmer = function (confirmer) {
        $scope.approveAllNextConfirmer = confirmer;
      };
      $scope.updateApproveAllComment = function (comment) {
        $scope.approveAllComment = comment;
      };
      $scope.isUnconfirmedAccountDistributionItem = function (item) {
        return true;
      };
      $scope.addCustomField = function (item) {
        var newCustomField = {CustomCostObjective: {Name: ""}, isMandatory: false};
        item.TransactionRowsDimensions.push(newCustomField);
      };
      $scope.validateItemAmount = function (data, oldPrice, row) {
        if (isNotNumber(data)) {
          return "invoiceConfirmationController.Incorrect_amount";
        }
        var diff = data - parseFloat(oldPrice);
        if (Math.abs(row.AccountDistributionItemsSumRemaining + diff) > Math.abs(row.Total)) {
          if (row.Total >= 0) {
            return "invoiceConfirmationController.Must_be_less_than" + Math.abs(Number(oldPrice + row.AccountDistributionItemsSumRemaining)).toFixed(2);
          } else {
            return "invoiceConfirmationController.Must_be_less_than" + Number(oldPrice + row.AccountDistributionItemsSumRemaining).toFixed(2);
          }
        }
      };
      $scope.isConfirmerSelectVisible = function (item) {
        return ($scope.isConfirmationEdit(item) || (!item.Workflow || !item.Workflow.Id || !item.Workflow.Tasks || item.Workflow.Tasks.length === 0)) && !item.Workflow.WorkflowTemplateId;
      };
      $scope.isConfirmationEdit = function (item) {
        var isConfirmationEdit = item.Workflow && item.Workflow.Tasks;
        if (isConfirmationEdit) {
          var task = invoiceService.getCurrentUncompletedTask(item);
          if (!task || !task.Id) {
            isConfirmationEdit = false;
          }
        }
        return isConfirmationEdit;
      };
      $scope.removeDuplicateFlag = function () {
        return removeDuplicateFlag();
      };

      function removeDuplicateFlag(invoiceId) {
        if (invoiceId || $scope.invoice && $scope.invoice.Id) {
          return webServices.removeDuplicateFlag(invoiceId || $scope.invoice.Id).then(function (response) {
            getActiveInvoiceData(invoiceId || $scope.invoice.Id);
            return response;
          }, function (data) {
            console.log(data);
          });
        }
      }

      $scope.saveWorkflowInvoice = function (workflowInvoice, retainOldDocFiles = false, onlySupplier = false) {
        if (workflowInvoice) {
          const oldDocFiles = cloneDeep(workflowInvoice.DocumentFiles);
          activeInvoiceService.compareTotalSum(workflowInvoice);
          var clone = cloneDeep(workflowInvoice);
          clone.AccountingDate = workflowInvoice.AccountingDate || null;
          clone.InvoiceDate = workflowInvoice.InvoiceDate || null;
          clone.DueDate = workflowInvoice.DueDate || null;
          clone.TransactionRows = null;
          clone.Company = null;
          clone.InvoiceRows = null;
          clone.Workflow = null;
          $rootScope.$broadcast('invoiceLoading');
          if (retainOldDocFiles) {
            clone.DocumentFiles = null;
          }
          return webServices.saveInvoice(clone).then(function (response) {
            if (response.data) {
              // kui arve ei ole uus
              if(!onlySupplier) {
                notifyService.success('views.invoice.SavedSuccessfully');
              }
              if ($scope.invoice && $scope.invoice.Id) {
                if (workflowInvoice.CompanyGuid && $scope.invoice.CompanyGuid && $scope.invoice.CompanyGuid !== workflowInvoice.CompanyGuid) {
                  return webServices.ChangeInvoiceCompany(workflowInvoice.CompanyGuid, $scope.invoice.Id)
                    .then(
                      function (newInvoiceData) {
                        notifyService.success('views.invoice.partials.invoiceInformation.Invoice_Moved');
                        $rootScope.$emit("invoiceMoved", newInvoiceData.data.Id, workflowInvoice.CompanyGuid);
                      },
                      function (data) {
                        notifyService.error('views.invoice.partials.invoiceInformation.ErrorMovingInvoice');
                        console.log(data);
                      });
                } else {
                  workflowInvoice.SupplierCode = response.data.SupplierCode;
                  $scope.invoice = retainOldDocFiles ? cloneDeep({...workflowInvoice, DocumentFiles: oldDocFiles}) : cloneDeep(workflowInvoice);
                  $scope.invoice.DocumentType = $scope.fields.filter(function (item) {
                    return item[0] === $scope.invoice.DocumentType;
                  })[0];
                  $rootScope.$emit("additionalInvoiceLoaded");
                  $rootScope.$emit("loadTransactionRows");
                }
                $rootScope.$broadcast('invoiceLoaded');
                $scope.invoice.AccountingDate = new Date(response.data.AccountingDate);
              } else {
                if ($scope.isEmpty(workflowInvoice.Id)) {
                  return $location.path("/invoiceconfirmation/" + response.data.Id);
                } else {
                  $rootScope.$emit("loadTransactionRows");
                  $scope.invoice = cloneDeep(workflowInvoice);
                    $rootScope.$emit("invoiceLoaded", $scope.invoice);
                }
              }
              $rootScope.$emit("additionInfoEdited", workflowInvoice);
              $ngRedux.dispatch(updateInvoice(workflowInvoice));
            }
            return retainOldDocFiles ? {...response.data, DocumentFiles: oldDocFiles} : response.data;
          }, function (error) {
            notifyService.error('views.invoice.ErrorSavingInvoice');
            console.log(error);
          });
        }
      };
      $scope.myGroupIds = false;
      if (!$scope.myGroupIds) {
        webServices.getSubstituteSubstitute($rootScope.userData.GroupMemberId).then(function (response) {
          $scope.myGroupIds = [];
          $scope.myGroupIds.push($rootScope.userData.GroupMemberId);
          for (var i = 0; i < response.data.length; i++) {
            $scope.myGroupIds.push(response.data[i].GroupMemberId);
          }
        });
      }
      // TODO DEPRECATED
      $scope.getConfirmerByNamePart = function (namePart) {
        // var result = invoiceService.getConfigetApproveAllConfirmersByNamePartrmers($rootScope.companyData.WorkflowTemplates, $scope.groupMembers, $rootScope.companyData.Users, namePart)
        // confirmers = result
        return invoiceService.getConfigetApproveAllConfirmersByNamePartrmers($rootScope.companyData.WorkflowTemplates, $scope.groupMembers, $rootScope.companyData.Users, namePart);
      };
      $scope.getUserFullNameById = function (userGMID) {
        return invoiceService.getUserFullNameById(userGMID, $rootScope.companyData.Users);
      };
      $scope.getGroupMemberFullNameById = function (groupMemberId) {
        return invoiceService.getGroupMemberFullNameById(groupMemberId, $scope.groupMembers);
      };
      $scope.getAccountDistributionItemRowClass = function (item) {
        var rowClass = invoiceService.getAccountDistributionItemRowClass(item, $rootScope.companyData.Users, $rootScope.userData.GroupMemberId);
        if (isExpanded(item.Id)) {
          rowClass += "exdown";
        }
        return rowClass;
      };
      $scope.isUserNextConfirmer = function (item) {
        return invoiceService.isUserNextConfirmer(item, $rootScope.companyData.Users, $rootScope.userData.GroupMemberId);
      };
      $scope.isUserNextConfirmerInRow = function (row) {
        // TODO 1560 //
        // for (var i = 0; i < row.AccountDistributionItems.length; i++) {
        //    if ($scope.isUserNextConfirmer(row.AccountDistributionItems[i])) {
        //        return true;
        //    }
        // }
        return false;
      };
      $scope.isNextConfirmerVisible = function (item) {
        return invoiceService.getCurrentUncompletedTask(item);
      };
      $scope.getNextConfirmerName = function (item) {
        var user = invoiceService.getNextConfirmer(item, $rootScope.companyData.Users);
        if (user) {
          return user.FullName;
        }
        return "";
      };
      $scope.filterTasks = function (task, value) {
        return task.Completed;
      };
      $scope.canEditAccountDistributionItem = function (item) {
        // TODO: there is no function canEditAccountDistributionItem
        // return canEditAccountDistributionItem(item)
      };
      $scope.canEditInvoiceRowDetails = false;
      $scope.canViewInvoiceRowDetails = true;
      $scope.canAddInvoiceFiles = function () {
        return (CanAddInvoiceFiles() && $scope.invoice && $scope.invoice.Status !== 5);
      };
      $scope.canUpdateInvoiceFiles = function () {
        return (CanUpdateInvoiceFiles() && $scope.invoice && $scope.invoice.Status !== 5);
      };
      $scope.canSendComplaint = function () {
        return (canSendComplaint() && $scope.invoice && $scope.invoice.Status !== 5);
      };
      $scope.CanDeleteField = function (item) {
        // TODO: there is no function canEditAccountDistributionItem
        // return canEditAccountDistributionItem(item)
      };
      $scope.canUpdateSupplierId = function () {
        return CanChangeInvoiceSupplier();
      };
      $scope.CanSaveInvoice = function (checkExportedStatus) {
        if (!checkExportedStatus) {
          return !!(CanAddInvoice() && $scope.invoice && !$scope.invoice.Id && $scope.invoice.CanEditInvoice) ||
            (CanUpdateInvoice() && $scope.invoice && $scope.invoice.Id && $scope.invoice.CanEditInvoice);
        } else {
          return !!(CanAddInvoice() && $scope.invoice && !$scope.invoice.Id && $scope.invoice.Status !== 5) ||
            (CanUpdateInvoice() && $scope.invoice && $scope.invoice.Id && $scope.invoice.Status !== 5);
        }
      };
      $scope.canUpdateInvoiceHeader = function (checkExportedStatus) {
        if (!checkExportedStatus) {
          return !!(CanAddInvoice() && $scope.invoice && !$scope.invoice.Id && $scope.invoice.CanEditInvoice) ||
            (CanUpdateInvoiceHeader() && $scope.invoice && $scope.invoice.Id && $scope.invoice.CanEditInvoice);
        } else {
          return !!(CanAddInvoice() && $scope.invoice && !$scope.invoice.Id && $scope.invoice.Status !== 5) ||
            (CanUpdateInvoiceHeader() && $scope.invoice && $scope.invoice.Id && $scope.invoice.Status !== 5);
        }
      };
      $scope.CanChangeInvoiceStatus = function () {
        return ((CanChangeInvoiceStatus() || $rootScope.userData.MemberRoles.indexOf(3) !== -1) && $scope.invoice && $scope.invoice.Id && ($scope.invoice.Status === 4 || $scope.invoice.Status === 3 || $scope.invoice.Status === 7));
      };
      $scope.CanDeleteInvoice = function () {
        return (CanDeleteInvoice() && $scope.invoice && $scope.invoice.Id && $scope.invoice.InvoiceType === 0);
      };
      $scope.isInvoiceDeleted = function () {
        return ($scope.invoice && $scope.invoice.Id && $scope.invoice.Status === 5);
      };
      $scope.isReadOnly = function () {
        const isUserOnlyExpenseCreator = checkUserOnlyRoleExpenseCreator();
        return ($scope.invoice && $scope.invoice.Status !== 0 && isUserOnlyExpenseCreator);
      };
      $scope.deleteField = function (accountDistItem, customField) {
        for (var i = 0; i < accountDistItem.TransactionRowsDimensions.length; i++) {
          if (accountDistItem.TransactionRowsDimensions[i] === customField) {
            accountDistItem.TransactionRowsDimensions.splice(i, 1);
            break;
          }
        }
      };
      $scope.getAccountByDescriptionPart = function (descriptionPart, item) {
        descriptionPart = descriptionPart || "";
        var result = _.filter($rootScope.accountList, function (c) {
          return c.FullName.toLowerCase().indexOf(descriptionPart.toLowerCase()) > -1;
        });
        return result;
      };
      $scope.getCompaniesByName = function (namePart) {
        if (ctrl.debounce) {
          $timeout.cancel(ctrl.debounce);
        }
        ctrl.debounce = $timeout(function () {
          namePart = namePart || "";
          var searchParams = {
            "SortItems": [
              {
                "SortColumn": "Name",
                "SortDirection": 0
              }
            ],
            "PagingOptions": {
              "Count": 25,
              "Page": 1
            },
            "Restrictions": [
              {
                "Field": "Name",
                "Value": namePart,
                "FieldSearchType": 0
              }
            ],
          };
          var companyList = webServices.getCompanies(searchParams).then(
            function (response) {
              $scope.companies = response.data.Items;
              return getCompaniesByName(namePart, response.data.Items);
            }, function (data) {
              console.log(data);
            });
          return companyList;
        }, 200);
        return ctrl.debounce;
      };

      function getCompaniesByName(namePart, list) {
        var unusedCompanies = [];
        if (_.find(list, function (com) {
          return com.Name === namePart;
        })) {
          unusedCompanies = utilityService.copy(list);
        } else {
          unusedCompanies = _.filter(list, function (com) {
            var regex = new RegExp((namePart || "").toLowerCase());
            return regex.test(com.Name.toLowerCase());
          });
        }
        unusedCompanies.push({
          Id: 0,
          Name: $filter("translate")("controller.invoiceConfirmationController.Add_new")
        });
        return unusedCompanies;
      }

      $scope.getCompaniesFromTheSameOrganizationByName = function (namePart) {
        if (ctrl.debounce) {
          $timeout.cancel(ctrl.debounce)
        }
        ctrl.debounce = $timeout(function () {
            return webServices.getAllCompaniesFromTheSameOrganizationByName('').then(function (response) {
            if (_.find(response.data, function (company) {
              return company.CompanyName === namePart;
            })) {
              return response.data;
            } else {
              var matchingCompanies = _.filter(response.data, function (company) {
                var regex = new RegExp((namePart || '').toLowerCase());
                return regex.test(company.CompanyName.toLowerCase());
              });
              return matchingCompanies;
            }
          }, function (data) {
            console.log(data);
          });
        }, 300);
        return ctrl.debounce;
      };
        $scope.updateSupplier = function (item) {
        UpdateSupplier(item);
        if (item && item.Id !== 0) {
          if (!$scope.invoice.Beneficiary) {
            $scope.invoice.Beneficiary = item.Name;
            $scope.BeneficiaryId = item.Id;
          }
        }
      };
      $scope.updateBeneficiary = function (item) {
        if (item) {
          $scope.invoice.Beneficiary = item.Name;
          $scope.BeneficiaryId = item.Id;
          $scope.BeneficiaryRegCode = item.RegistrationCode;
        } else {
          $scope.invoice.Beneficiary = null;
          $scope.BeneficiaryId = null;
          $scope.BeneficiaryRegCode = null;
        }
      };
      $scope.addNewCompany = function (item) {
        if (item) {
          $scope.invoice.Company.Address = item.Address;
          $scope.invoice.Company.RegistrationCode = item.RegistrationCode;
          $scope.invoice.CompanyGuid = item.Id;
        } else {
          $scope.invoice.Company.Address = null;
          $scope.invoice.Company.RegistrationCode = null;
        }
      };
      $scope.backupSupplier = function () {
        supplier.SupplierId = $scope.invoice.SupplierId;
        supplier.SupplierName = $scope.invoice.SupplierName;
        supplier.SupplierRegCode = $scope.invoice.SupplierRegCode;
        supplier.SupplierAddress = $scope.invoice.SupplierAddress;
        supplier.SupplierRepresentative = $scope.invoice.SupplierRepresentative;
      };
      $scope.restoreSupplier = function () {
        $scope.invoice.SupplierId = supplier.SupplierId;
        $scope.invoice.SupplierName = supplier.SupplierName;
        $scope.invoice.SupplierRegCode = supplier.SupplierRegCode;
        $scope.invoice.SupplierAddress = supplier.SupplierAddress;
        $scope.invoice.SupplierRepresentative = supplier.SupplierRepresentative;
      };
      $scope.getAccountVatCodes = function (numberPart, item, vatrate) {
        numberPart = numberPart || "";
        if (numberPart === "") {
          return $rootScope.companyData.VatCodes;
        }
        var result = _.filter($rootScope.companyData.VatCodes, function (c) {
          return c.Code.toLowerCase().indexOf(numberPart.toLowerCase()) > -1 ||
            c.Description.toLowerCase().indexOf(numberPart.toLowerCase()) > -1;
        });
        return result;
      };
      $scope.getAccountVatRates = function (numberPart, item) {
        return companyDataService.getVatCodes().then(
          function (response) {
            var vatCodes = _.sortBy(_.uniq(_.pluck(response, "VatRate")), function (num) {
              return num;
            });
            if (typeof _.find(vatCodes, function (code) {
              return parseInt(code) === parseInt(numberPart);
            }) !== undefined) {
              return vatCodes;
            } else {
              var regexp = new RegExp(numberPart);
              vatCodes = _.filter(vatCodes, function (code) {
                return regexp.test(code.toString());
              });
              return vatCodes;
            }
          }
        );
      };
      $scope.updateItemAmountByPrice = function (item, row, $index) {
        // We apply percent based calculation to get proportional change in VatSum and SumWithVat also
        item.VatSum = ((Number(item.Sum) * (item.VatCode.VatRate + 100) / 100) - Number(item.Sum)).toFixed(2);
        item.SumWithVat = (Number(item.Sum) + Number(item.VatSum)).toFixed(2);
      };

      $scope.getAccountDistributionItemCustomCostObjectivesByNumberPart = function (namePart, item) {
        namePart = namePart || "";
        // if (!namePart) return; // we need to display the whole list when user clicks on the input
        var result = _.filter($scope.accountDistributionItemCustomCostObjectives, function (c) {
          return c.Name.toLowerCase().indexOf(namePart.toLowerCase()) > -1;
        });
        if (result && result.length > 20) {
          result = result.slice(0, 20);
        }
        // only one custom field can be added at a time
        var existingCustomCostObjectives = [];
        var i = 0;
        for (i = 0; i < item.TransactionRowsDimensions.length; i++) {
          existingCustomCostObjectives.push(item.TransactionRowsDimensions[i].CustomCostObjective);
        }
        var repeatedCustomCostObjectives = [];
        for (i = 0; i < result.length; i++) {
          var cf = _.find(existingCustomCostObjectives, function (cft) {
            return cft && cft.Id === result[i].Id;
          });
          if (cf) {
            repeatedCustomCostObjectives.push(cf);
          }
        }
        if (repeatedCustomCostObjectives) {
          for (i = 0; i < repeatedCustomCostObjectives.length; i++) {
            result = _.filter(result, function (r) {
              return r.Id !== repeatedCustomCostObjectives[i].Id;
            });
          }
        }
        // return result;
        return result;
      };
      $scope.clearCustomField = function (customFieldItem) {
        customFieldItem.DimensionId = "";
      };
      $scope.editCompany = function (id, e) {
        e.preventDefault();
        $ngRedux.dispatch(showAddOrEditSupplierModal({Id: id} ));
      };
      $scope.saveAccountDistributionTemplate = function () {
        invoiceService.openSaveAccountDistTemplateDialog($scope.invoice, {
          accountDistributionItemCustomCostObjectives: $scope.accountDistributionItemCustomCostObjectives,
          groupMembers: $scope.groupMembers
        });
      };
      $scope.sendComplaint = function () {
        openSendComplaintDialog();
      };
      $scope.checkSupplier = function (invoice) {
        if (invoice.SupplierId === 0) {
          $ngRedux.dispatch(showAddOrEditSupplierModal());
          invoice.SupplierName = {Name: ""};
        } else {
          UpdateSupplierId(invoice.SupplierId, invoice.Id);
        }
      };
      $scope.checkBeneficiary = function (invoice) {
        $ngRedux.dispatch(showAddOrEditSupplierModal());
      };
      $scope.cancelBeneficiary = function () {
        return $scope.invoice.Beneficiary;
      };
      $scope.setAccountingDate = function (date) {
        $scope.invoice.AccountingDate = date;
      };
      $scope.getInvoiceStatusTranslateKey = constants.getInvoiceStatusTranslateKey;
      /// ///
      /// Script functions
      /// //
      // TODO: this is an un-used function
      // function IsItemChanged (originalItem, currentItem) {
      //   if (angular.equals(originalItem, currentItem)) {
      //     return false
      //   }
      //   return true
      // }
      function isExpanded(id) {
        // TODO: there is no variable called expandedRows
        // return expandedRows.indexOf(id) !== -1
      }

      function CanChangeInvoiceSupplier() {
        return authenticationService.isAuthorized("CanChangeInvoiceSupplier");
      }

      function CanApproveInvoiceWithoutNextConfirmer() {
        return authenticationService.isAuthorized("CanApproveInvoiceWithoutNextConfirmer");
      }

      // TODO: this is an un-used function
      // function CanApproveAndViewAnyInvoice () {
      //   return authenticationService.isAuthorized('CanApproveAndViewAnyInvoice')
      // }
      function CanAddInvoice() {
        return authenticationService.isAuthorized("CanAddInvoice");
      }

      $scope.CanAddInvoice = function () {
        return CanAddInvoice();
      };

      function CanUpdateInvoice() {
        return authenticationService.isAuthorized("CanUpdateInvoice");
      }

      function CanUpdateInvoiceHeader() {
        return authenticationService.isAuthorized("CanUpdateInvoiceHeader");
      }

      function CanUpdateInvoiceFiles() {
        return authenticationService.isAuthorized("CanUpdateInvoiceFiles");
      }

      function CanChangeInvoiceStatus() {
        return authenticationService.isAuthorized("CanChangeInvoiceStatus");
      }

      function CanDeleteInvoice() {
        return authenticationService.isAuthorized("CanDeleteInvoice");
      }

      function CanAddInvoiceFiles() {
        return authenticationService.isAuthorized("CanAddInvoiceFiles");
      }

      function canSendComplaint() {
        return $scope.invoice && $scope.invoice.HubType === 0;
      }

      function UpdateSupplier(item) {
        $scope.invoice.SupplierId = item.Id;
        $scope.invoice.SupplierName = item.Name;
        $scope.invoice.SupplierRegCode = item.RegistrationCode;
        $scope.invoice.SupplierAddress = item.Address;
        $scope.invoice.SupplierRepresentative = item.ContactPersonName;
        $scope.invoice.SupplierEmail = item.Email;
        $scope.invoice.PayToAccount = item.BankAccount;
      }

      function getAccountingRowVATPercent(row) {
        return row.VAT * 100.0 / row.Total;
      }

      function isNotNumber(item) {
        return item === null || item === undefined || isNaN(item);
      }

      function hasAnyCompletedTask(item) {
        if (item && item.Workflow && item.Workflow.Tasks && item.Workflow.Tasks.length > 0) {
          var task = _.find(item.Workflow.Tasks, function (t) {
            return t.Id;
          });
          return task;
        }
      }

      $scope.replaceAccountDistributionItem = function (oldItem, newItem, invoice) {
        if (newItem) {
          for (var i = 0; i < invoice.TransactionRows.length; i++) {
            for (var j = 0; j < invoice.TransactionRows[i].AccountDistributionItems.length; j++) {
              if (invoice.TransactionRows[i].AccountDistributionItems[j].Id === oldItem.Id) {
                invoice.TransactionRows[i].AccountDistributionItems[j].Id = newItem.Id;
                invoice.TransactionRows[i].AccountDistributionItems[j].Sum = newItem.Sum;
                invoice.TransactionRows[i].AccountDistributionItems[j].SumWithVat = newItem.SumWithVat;
                invoice.TransactionRows[i].AccountDistributionItems[j].VatSum = newItem.VatSum;
                invoice.TransactionRows[i].AccountDistributionItems[j].AccountLoading = false;
                invoice.TransactionRows[i].AccountDistributionItems[j].VatLoading = false;
                invoice.TransactionRows[i].AccountDistributionItems[j].ProductCodeLoading = false;
                invoice.TransactionRows[i].AccountDistributionItems[j].Loading = false;
                invoice.TransactionRows[i].AccountDistributionItems[j].SumLoading = false;
                invoice.TransactionRows[i].AccountDistributionItems[j].showAccountValidation = false;
                invoice.TransactionRows[i].AccountDistributionItems[j].showVatValidation = false;
                invoice.TransactionRows[i].AccountDistributionItems[j].showValidation = false;
                for (var k = 0; k < newItem.TransactionRowsDimensions.length; k++) {
                  var currentField = _.find(invoice.TransactionRows[i].AccountDistributionItems[j].TransactionRowsDimensions, function (item) {
                    return item.CustomCostObjective.Id === newItem.TransactionRowsDimensions[k].CustomCostObjectiveId;
                  });
                  if (currentField) {
                    currentField.Id = newItem.TransactionRowsDimensions[k].Id;
                    currentField.DimensionId = newItem.TransactionRowsDimensions[k].DimensionId;
                  } else {
                    invoice.TransactionRows[i].AccountDistributionItems[j].TransactionRowsDimensions.push(
                      newItem.TransactionRowsDimensions[k]);
                  }
                }
                break;
              }
            }
          }
        }
      };

      function openSendComplaintDialog(id) {
        $uibModal.open({
          templateUrl: "Views/Invoice/SendComplaint.html",
          controller: "invoiceSendComplaintController",
          size: "lg",
          resolve: {
            invoice: function () {
              return $scope.invoice;
            }
          }
        });
      }

      /// ///
      /// DTO creations for moving data between services
      /// //
      function createInvoiceAction(nextConfirmer, action) {
        return invoiceService.createInvoiceAction(action, $scope.invoice.Id, $scope.approveAllComment, nextConfirmer);
      }

      function validateInvoiceConfirm(action) {
        if (!$scope.isTransactionRowsCheckEnabled && !$scope.IsTransactionRowsPenultimateCheckEnabled) {
          // skip all verifications
          return true;
        }
        var error = "";
        if (action === "Sent") {
          if (!CanApproveInvoiceWithoutNextConfirmer && !$scope.approveAllNextConfirmer) {
            error += "controller.invoiceConfirmationController.Next_approver_is_mandatory";
          } else if (!CanApproveInvoiceWithoutNextConfirmer && !$scope.approveAllNextConfirmer && !hasAnyCompletedTask($scope.invoice)) {
            error += "controller.invoiceConfirmationController.The_first_approver_is_mandatory";
          }
        }
        if (error !== "") {
          notifyService.error(error, "controller.invoiceConfirmationController.Error", true);
        }
        return !error;
      }

      /// ///
      /// Invoice Actions
      /// ///
      $scope.confirmInvoice = function (nextConfirmer) {
        // timeout is for blur=submit vs click event battle if click wins then blur-able object info will be lost
        $timeout(function () {
          if (validateInvoiceConfirm("Approved")) {
            approveInvoice(createInvoiceAction(nextConfirmer, "Approved"));
            ga.eventTrack('Confirmations', {
                category: 'Invoice Details',
                label: nextConfirmer ? 'ConfirmAndAssign' : 'Confirm',
            });
          }
        }, 10);
      };
      $scope.skipInvoice = function (nextConfirmer) {
        // timeout is for blur=submit vs click event battle if click wins then blur-able object info will be lost
        $timeout(function () {
          approveInvoice(createInvoiceAction(nextConfirmer, "Skip"));
        }, 10);
      };
      $scope.sendInvoice = function (nextConfirmer, stepNumber) {
        // timeout is for blur=submit vs click event battle if click wins then blur-able object info will be lost
        $timeout(function () {
          if (validateInvoiceConfirm("Sent")) {
            approveInvoice(createInvoiceAction(nextConfirmer, "Sent"), stepNumber);
            ga.eventTrack('Confirmations', {
                category: 'Invoice Details',
                label: 'Assign',
            });
          }
        }, 10);
      };
      $scope.declineInvoice = function (nextConfirmer) {
        // timeout is for blur=submit vs click event battle if click wins then blur-able object info will be lost
        $timeout(function () {
          if (validateInvoiceConfirm("Declined")) {
            approveInvoice(createInvoiceAction(nextConfirmer, "Declined"));
          }
        }, 10);
      };
      $scope.reopenAndAssignInvoice = function (nextConfirmer) {
        // timeout is for blur=submit vs click event battle if click wins then blur-able object info will be lost
        $timeout(function () {
          if (validateInvoiceConfirm("Reopen")) {
            var action = createInvoiceAction(nextConfirmer, "Reopen");
              action.NextConfirmerGroupMemberOrWorkflowTemplateId = -1;
            approveInvoice(action);
          }
        }, 10);
      };
      $scope.deleteAccountDistributionItem = function (row, index) {
        row.AccountDistributionItems.splice(index, 1);
      };
      $scope.showInvoiceProcessClass = function (key, currentStep) {
        if (key === currentStep) {
          return "badge badge-yellow";
        } else if (key < currentStep) {
          return "badge badge-green";
        } else {
          return "badge badge-default";
        }
      };
      /// //
      /// Task actions
      /// /
      /// //
      /// WebService call outs
      /// /
      function UpdateSupplierId(supplierId, invoiceId) {
        if ($scope.isEmpty(invoiceId) || $scope.isEmpty(supplierId)) {
          return;
          }
        return webServices.UpdateSupplierId(supplierId, invoiceId).then(function (response) {
          if (response.data.Success) {
            return response.data.Result;
          }
        }, function (data, status) {
          console.log(data);
        });
      }

      $scope.updateSupplierId = UpdateSupplierId;

      $scope.refreshInvoice = function () {
        activeInvoiceService.activeInvoice($scope.invoiceId ? $scope.invoiceId : $routeParams.id).then(
          function (response) {
            $scope.invoice = response;
          }
        );
      };
      $scope.approveInvoice = approveInvoice;

      function approveInvoice(invoiceAction, stepNumber) {
        invoiceService.approveInvoice(invoiceAction, stepNumber).then(() => {
          getMinimalActiveInvoiceData($scope.invoiceId ? $scope.invoiceId : $routeParams.id);
          $scope.approveAllNextConfirmer = null;
          $scope.approveAllComment = null;
        }
        );
      }

      $scope.selectedTemplate = null;
      $scope.setTemplate = function (item, model, label, event) {
        $scope.selectedTemplate = item;
      };

      $scope.getInvoiceData = getActiveInvoiceData;
      $scope.getMinimalInvoiceData = getMinimalActiveInvoiceData;
      /// ///
      /// Page load functions: inits, Data retrivals and Dataprocessing
      /// //
      $scope.$on("ds.confirmation-flow.reload-invoice-data", function (event, invoiceId) {
        getActiveInvoiceData(invoiceId);
      });

      const transactionRowsLoaded = $rootScope.$on("transactionRowsLoaded", function () {
          $scope.transactionRowsLoaded = true;
      });

      $scope.reloadInvoiceData = function (id, callbackEmit, invoiceRowPage) {
          if (id) {
              getActiveInvoiceData(id, invoiceRowPage, callbackEmit);
          }
      };

      $scope.setInvoiceExportAfterApproval = function (exportValue) {
          $scope.invoice = {
              ...$scope.invoice,
              IsExportAfterApprovalEnabled: exportValue,
          }
      };

      function getCurrentInvoiceSupplier() {
        webServices.getCurrentCompany().then(function (result) {
          if (result.data) {
            $scope.invoice.Company.Id = result.data.Id;
            $scope.invoice.Company.Name = result.data.Name;
            $scope.invoice.Company.RegistrationCode = result.data.RegistrationCode;
            $scope.invoice.Company.Address = result.data.Address;
          }
          $rootScope.$emit("invoiceLoaded", $scope.invoice);
        }, function (data) {
          console.log(data);
        });
      }

      function modifyNewInvoiceFromEmptyTemplate() {
        // other invoice blocks are hidden
        $scope.isInvoiceRowsPartOfInvoice = false;
        $scope.isApproveAllPartOfInvoice = false;
        $scope.isAccountingRowsPartOfInvoice = false;
        // invoice information block: set invoice company by default
        webServices.getAllCompaniesFromTheSameOrganizationByName("").then(function (response) {
          if (response.data) {
            $scope.invoice = newInvoice();
            $ngRedux.dispatch(getInvoiceDataLoadable.success( {
                CurrentGroupMember: null,
                Invoice: $scope.invoice,
                InvoiceAccountingRowsTotal: null,
                InvoiceHistory: null,
                InvoiceRowsCount: null,
                InvoiceRowsPrice: null,
                InvoiceRowsTotal: null,
                InvoiceRowsVAT: null,
            } ));
            getCurrentInvoiceSupplier();
          }
        }, function (data) {
          console.log(data);
        });
      }

      // TODO: this function belongs into a service!
      function setCompanySettings() {

        const handleCompanySettings = (settings) => {
          if (settings) {
            var setting = settings.filter(function (item) {
              return item.Name === "DocumentTypes";
            });
            if (setting.length > 0) {
              if (!setting[0].Value) {
                return;
              }
              $scope.fields = setting[0].Value.split(";");
              $scope.fields = $scope.fields.map(function (item) {
                return item.split(",");
              });
            }
            angular.forEach(settings, function (setting) {
              if (setting.Name === "IsTransactionRowsCheckEnabled") {
                $scope.isTransactionRowsCheckEnabled = setting.Value === CompanySettingStatus.Enabled;
                $rootScope.$emit("isTransactionRowsCheckEnabled", $scope.isTransactionRowsCheckEnabled);
              }
              if (setting.Name === "IsTransactionRowsPenultimateCheckEnabled") {
                $scope.IsTransactionRowsPenultimateCheckEnabled = setting.Value === CompanySettingStatus.Enabled;
                $rootScope.$emit("isTransactionRowsPenultimateCheckEnabled", $scope.IsTransactionRowsPenultimateCheckEnabled);
              }
              if (setting.Name === "SkipTransactionRowsVerificationWhenPOSpecified") {
                $scope.skipTransactionRowsVerificationWhenPOSpecified = setting.Value === CompanySettingStatus.Enabled;
              }
            });
          }
        }        

        const {company : {currentCompanySettings}} = $ngRedux.getState();
        if (!currentCompanySettings || !currentCompanySettings.length) {
          // load settings from api only if not fetched before
          $ngRedux.dispatch(setCurrentCompanySettingsLoading(true));
          return companyDataService.getCurrentCompanySettings()
            .then((response) => {
              $ngRedux.dispatch(setCurrentCompanySettings(response.data));
              $ngRedux.dispatch(setCurrentCompanySettingsLoading(false));
              handleCompanySettings(response.data)
            })
            .catch(() => {
              $ngRedux.dispatch(setCurrentCompanySettingsLoading(false));
              notifyService.error("controller.dashboardController.Error", "controller.invoiceConfirmationController.Error", true)
            });
        } else {
          $scope.hideExportManagement = currentCompanySettings.some((item)=>item.Name == 'InvoicesExportMode' && item.Value == '0')
          return new Promise((res) => res(handleCompanySettings(currentCompanySettings)));
        }
      }

      /*
        Get all users associated with currently selected company
     */

      // TODO: this function belongs into a service!
      function getGroupMembers() {
        var fromArchive = $routeParams.archive;
        if (fromArchive) {
          return companyDataService.getArchiveGroupMembers().then(function (res) {
            $scope.groupMembers = res;
            return res;
          });
        } else {
          return companyDataService.getGroupMembers().then(function (res) {
            $scope.groupMembers = res;
            return res;
          });
        }
      }

      const newInvoiceEventWatcher = $rootScope.$on('newInvoice', (evt, data) => {
          $scope.isNewInvoice = data;
      });

      const relatedDocumentEventWatcher = $rootScope.$on('invoice.newRelatedDocument', (evt, data) => {
        $scope.invoice.DocumentFiles = data;
      });

      init();

      $ngRedux.subscribe(() => {
        const state = $ngRedux.getState();
        const pdfToOpen = selectPdfToOpen(state);
        if (!!pdfToOpen) {
          $scope.isPdfOpen = true;
        } else if ($scope.isPdfOpen) {
          $scope.isPdfOpen = false;
        }
      })

      let unsubscribeReduxPdf = $ngRedux.connect((state) => ({pdfToOpen: selectPdfToOpen(state)}))($scope);

      $scope.$on("$destroy", function () {
        transactionRowsLoaded();
        newInvoiceEventWatcher();
        confirmDashboardInvoiceEvent();
        relatedDocumentEventWatcher();
        $scope.$parent.header.top_menu_style = "";
        $scope.isPdfOpen = false;
        unsubscribeReduxPdf();
      });
    }
  ]);
