import classNames from 'classnames';
import React from 'react';
import { CustomArrowProps } from 'react-slick';
import Icon, { ICONS, IconSize } from '../../../../../components/Icon/Icon';

export interface SliderArrowProps<T> {
    type: ArrowType;
    items: T[];
}

export enum ArrowType {
    NEXT = 'NEXT',
    PREVIOUS = 'PREVIOUS',
}

type Props<T> = SliderArrowProps<T> & CustomArrowProps;

const CustomArrow = ({ type, items, currentSlide, onClick }: Props<any>) => {
    const getArrowType = () => {
        const arrowTypes = {
            [ArrowType.NEXT]: 'slick-next',
            [ArrowType.PREVIOUS]: 'slick-prev',
        };
        return arrowTypes[type];
    };

    const getArrowIcon = () => {
        const arrowTypes = {
            [ArrowType.NEXT]: ICONS.PAGINATOR_ARROW,
            [ArrowType.PREVIOUS]: ICONS.BACK,
        };
        return arrowTypes[type];
    };

    const classes = classNames(getArrowType(), { 'slick-disabled': (items.length === currentSlide && type === ArrowType.NEXT) || (currentSlide === 0 && type === ArrowType.PREVIOUS) });
    const ariaLabel = type === ArrowType.NEXT ? 'Next' : 'Previous';
    const dataId = `arrow.${type}`;

    return (
        <button type="button" className={classes} aria-label={ariaLabel} tabIndex={0} role="button" onClick={onClick} data-id={dataId}>
            {type === ArrowType.NEXT ? 'next' : 'previous'}
            <Icon name={getArrowIcon()} size={IconSize.XS} className="icon icon__slider-arrow" />
        </button>
    );
};

export default CustomArrow;
