import { LOCATION_CHANGE } from 'connected-react-router';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { InvoiceTypeDTO } from '../../../../services/types/ApiTypes';
import { LoadableData } from '../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../rootReducer';

import { saveInvoiceTypeActions, setActiveInvoiceType, showEditInvoiceTypeModal } from './EditInvoiceTypeModalActions';

class State {
    activeInvoiceType: InvoiceTypeDTO | null;
    isEditInvoiceTypeModalOpen = false;
    saveInvoiceTypeActionLoadable = new LoadableData<InvoiceTypeDTO, InvoiceTypeDTO>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        setActiveInvoiceType,
        (state, action): State => {
            return {
                ...state,
                activeInvoiceType: action.payload,
            };
        },
    )
    .addReducer(
        showEditInvoiceTypeModal,
        (state, action): State => {
            return {
                ...state,
                isEditInvoiceTypeModalOpen: action.payload,
            };
        },
    )
    .addReducer(
        saveInvoiceTypeActions.request,
        (state): State => {
            return {
                ...state,
                saveInvoiceTypeActionLoadable: state.saveInvoiceTypeActionLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        saveInvoiceTypeActions.success,
        (state, action): State => {
            return {
                ...state,
                saveInvoiceTypeActionLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        saveInvoiceTypeActions.error,
        (state, action): State => {
            return {
                ...state,
                saveInvoiceTypeActionLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'new-InvoiceType-modal',
    whitelist: [],
};

export default persistReducer(persistConfig, reducer);

export { State as EditInvoiceTypeModalState };

export const selectActiveInvoiceType = (state: GlobalState) => state.editInvoiceTypeModal.activeInvoiceType;
export const selectIsEditInvoiceTypeModalOpen = (state: GlobalState) => state.editInvoiceTypeModal.isEditInvoiceTypeModalOpen;
