import { LOCATION_CHANGE } from 'connected-react-router';
import produce from 'immer';
import { cloneDeep } from 'lodash-es';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { createSelector } from 'reselect';

import { selectCompanyGroupMembers } from '../../common/company/CompanySelectors';
import { LoadableData } from '../../common/utils/LoadableData';
import { TypeaheadItem } from '../../components/Typeahead/TypeaheadAsync';
import { GlobalState } from '../../rootReducer';
import { BaseSearch, CostObjectiveItemsCountForUserDTO, DimensionItemUnion, GroupMemberDTO, PagedListContainer, SearchType, SortDirection } from '../../services/types/ApiTypes';

import {
    getCostObjectivesLoadableActions,
    saveUserLoadableActions,
    getDimensionsLoadableActions,
    getUserDataLoadableActions,
    setActiveCostObjective,
    toggleCostObjectiveItemForUserAction,
} from './UserAddViewActions';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

class State {
    searchParams: BaseSearch = {
        SortItems: [
            {
                SortColumn: 'Code',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Count: 15,
            Page: 1,
        },
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: null,
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
    };
    groupMemberLoadable = new LoadableData<GroupMemberDTO>();
    costObjectivesLoadable = new LoadableData<CostObjectiveItemsCountForUserDTO[]>();
    dimensionsLoadable = new LoadableData<PagedListContainer<DimensionItemUnion>, BaseSearch>();
    activeCostObjective: CostObjectiveItemsCountForUserDTO = undefined;
    saveUserLoadable = new LoadableData<boolean>();
    userGuid: string = undefined;
}

export default new ReducerFactory(new State())
    .addReducer(
        getUserDataLoadableActions.request,
        (state, action): State => {
            return {
                ...state,
                userGuid: action.payload,
                groupMemberLoadable: LoadableData.loading(),
            };
        },
    )
    .addReducer(
        getUserDataLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                groupMemberLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getUserDataLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                groupMemberLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        getCostObjectivesLoadableActions.request,
        (state): State => {
            return {
                ...state,
                costObjectivesLoadable: LoadableData.loading(),
            };
        },
    )
    .addReducer(
        getCostObjectivesLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                costObjectivesLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getCostObjectivesLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                costObjectivesLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        setActiveCostObjective,
        (state, action): State => {
            return {
                ...state,
                activeCostObjective: action.payload,
            };
        },
    )
    .addReducer(
        getDimensionsLoadableActions.request,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload,
                dimensionsLoadable: state.dimensionsLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        getDimensionsLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                dimensionsLoadable: state.dimensionsLoadable.withPayloadIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        getDimensionsLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                dimensionsLoadable: state.dimensionsLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        saveUserLoadableActions.request,
        (state): State => {
            return {
                ...state,
                saveUserLoadable: LoadableData.loading(undefined),
            };
        },
    )
    .addReducer(
        saveUserLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                saveUserLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        saveUserLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                saveUserLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        toggleCostObjectiveItemForUserAction.request,
        produce((draft, action) => {
            const index = draft.dimensionsLoadable.payload.Items.findIndex((i: any) => i.Id === action.payload);
            draft.dimensionsLoadable.payload.Items[index].AssignedCurrentToUser = !draft.dimensionsLoadable.payload.Items[index].AssignedCurrentToUser;
        }),
    )
    .addReducer(
        toggleCostObjectiveItemForUserAction.success,
        (state, action): State => {
            const costObjectivesLoadable = cloneDeep(state.costObjectivesLoadable);
            const newCostObjective = cloneDeep(costObjectivesLoadable.payload.find((co) => co.Id === state.activeCostObjective.Id && co.ObjectType === state.activeCostObjective.ObjectType));
            newCostObjective.Count = action.payload;
            costObjectivesLoadable.payload = costObjectivesLoadable.payload.map((co) => {
                if (co.Id === newCostObjective.Id && co.ObjectType === state.activeCostObjective.ObjectType) {
                    return cloneDeep(newCostObjective);
                }
                return co;
            });
            return {
                ...state,
                costObjectivesLoadable,
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

export { State as UserAddViewState };

export const selectUserDataLoadable = (state: GlobalState) => state.userAdd.groupMemberLoadable;
export const selectCostObjectivesLoadable = (state: GlobalState) => state.userAdd.costObjectivesLoadable;
export const selectDimensionsLoadable = (state: GlobalState) => state.userAdd.dimensionsLoadable;
export const selectActiveCostObjective = (state: GlobalState) => state.userAdd.activeCostObjective;
export const selectSearchParams = (state: GlobalState) => state.userAdd.searchParams;
export const selectSaveUserLoadable = (state: GlobalState) => state.userAdd.saveUserLoadable;

export const selectSubstitutes = createSelector(
    selectCompanyGroupMembers,
    (groupMembers): Array<TypeaheadItem<GroupMemberDTO>> => {
        let substitute = groupMembers.filter((member) => {
            return member.IsActive;
        });

        substitute = substitute.sort((a, b) => {
            const firstNameComparison = a.User?.FirstName?.localeCompare(b.User?.FirstName);
            if (firstNameComparison) {
                return firstNameComparison;
            }
            return a.User?.LastName?.localeCompare(b.User?.LastName);
        });

        return substitute.map((approver) => {
            return {
                value: approver,
                text: approver.User.FullName,
            };
        });
    },
);
