import { isUsingGoogleAnalytics } from '../utils/whitelabelHelper';

(function(w, d, s, l, i) {
    if (!isUsingGoogleAnalytics) {
        return;
    }
    if (w.fetch) {
        let promises = [];
        const gaUrls = ['https://www.google-analytics.com/j/collect', 'https://stats.g.doubleclick.net/j/collect'];
        for (let i = 0; i < gaUrls.length; i++) {
            promises.push(fetch(gaUrls[i]));
        }
        Promise.all(
            promises.map((promise) => {
                return promise
                    .then((response) => {
                        if (response.ok) {
                            return 1;
                        }
                        return 0;
                    })
                    .catch(() => {
                        return 0;
                    });
            }),
        )
            .then((result) => {
                // no failed request
                return !result.includes(0);
            })
            .then((gaReachable) => {
                if (gaReachable) {
                    w[l] = w[l] || [];
                    w[l].push({
                        'gtm.start': new Date().getTime(),
                        event: 'gtm.js',
                    });
                    var j = d.createElement(s);
                    var dl = l != 'dataLayer' ? '&l=' + l : '';
                    j.async = true;
                    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                    d.head.appendChild(j);
                }
            });
    }
})(window, document, 'script', 'dataLayer', process.env.REACT_APP_GTM_ID);
