import { size } from 'lodash-es';
import { createAction } from 'redux-actions';

import { GlobalState } from '../../rootReducer';
import { BaseSearch, CompanyDTO, GroupMemberDTO, GroupMemberMicroDTO, SearchType, SortDirection, SystemConfigurationDTO, UserDTO } from '../../services/types/ApiTypes';
import api from '../../services/ApiServices';
import { DispatchThunk } from '../../storeConfig';
import { loadableDataActions } from '../utils/LoadableData';

const ns = 'company/';

export const authorizationDataKey = 'ls.authorizationData';

export const setGroupMembersAction = createAction<GroupMemberDTO[]>(`${ns}SET_GROUP_MEMBERS`);

export const updateGroupMember = loadableDataActions<GroupMemberDTO, UserDTO>(`${ns}UPDATE_GROUP_MEMBER`);

export const getCompanyGroupMembersActions = loadableDataActions<undefined, GroupMemberDTO[]>(`${ns}GET_COMPANY_GROUP_MEMBERS`);

export const setCurrentCompanySettings = createAction<SystemConfigurationDTO[]>('SET_CURRENT_COMPANY_SETTINGS');

export const setCurrentCompanySettingsLoading = createAction<boolean>('SET_CURRENT_COMPANY_SETTINGS_LOADING');

export const updateCurrentCompanySettings = loadableDataActions<SystemConfigurationDTO[], SystemConfigurationDTO[]>(`${ns}UPDATE_CURRENT_COMPANY_SETTINGS`);

export const getCompanyData = loadableDataActions<boolean, CompanyDTO>(`${ns}GET_COMPANY_DATA`);

export function updateCompanyGroupMember(groupMember: GroupMemberDTO) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(updateGroupMember.request(groupMember));
            const response = await api.user.editGroupMember(groupMember);
            dispatch(updateGroupMember.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(updateGroupMember.error(e));
        }
    };
}

export const getGroupMembersMicroActions = loadableDataActions<BaseSearch, GroupMemberMicroDTO[]>(`${ns}/GET_COMPANY_GROUP_MEMBERS_MICRO`);

export function getCompanyGroupMembersMicro(searchParams: BaseSearch) {
    return async (dispatch: DispatchThunk) => {
        try {
            if (!searchParams) {
                searchParams = {
                    Restrictions: [
                        {
                            Field: 'GeneralSearch',
                            Value: '',
                            Values: null,
                            FieldSearchType: SearchType.NotSelected,
                        },
                        {
                            Field: 'IsActive',
                            Value: true,
                            Values: null,
                            FieldSearchType: SearchType.NotSelected,
                        },
                    ],
                    SortItems: [
                        {
                            SortColumn: 'UserFullName',
                            SortDirection: SortDirection.Asc,
                        },
                    ],
                    PagingOptions: {
                        Page: 1,
                        Count: 10000,
                    },
                };
            }
            dispatch(getGroupMembersMicroActions.request(searchParams));
            const response = await api.company.getGroupMembersMicro(searchParams);
            dispatch(getGroupMembersMicroActions.success(response.data.Items));
        } catch (e) {
            dispatch(getGroupMembersMicroActions.error(e));
        }
    };
}

export function getCompanyGroupMembers(forceReload = false) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const state = getState();
            if (forceReload || size(state.company.groupMembers) === 0) {
                dispatch(getCompanyGroupMembersActions.request(undefined));
                const response = await api.company.getCompanyGroupMembers(state.user?.currentCompany?.CompanyGuid);
                dispatch(getCompanyGroupMembersActions.success(response.data));
                return response.data;
            }
            return [];
        } catch (e) {
            console.error(e);
            dispatch(getCompanyGroupMembersActions.error(e));
            return [];
        }
    };
}

export function getCurrentCompanySettings(forceReload = false) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const state = getState();
            if (forceReload || size(state.company.currentCompanySettings) === 0) {
                const authData = JSON.parse(localStorage.getItem(authorizationDataKey));
                dispatch(setCurrentCompanySettingsLoading(true));
                const response = await api.company.getCompanySettings(state.changeCompany?.activeCompanyGuid || authData?.companyGuid);
                dispatch(setCurrentCompanySettings(response.data));
                dispatch(setCurrentCompanySettingsLoading(false));
                return response.data;
            }
            return [];
        } catch (e) {
            console.error(e);
            dispatch(setCurrentCompanySettingsLoading(false));
            return [];
        }
    };
}

export function updateCompanySettings(companySettings: SystemConfigurationDTO[]) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(updateCurrentCompanySettings.request(companySettings));
            const response = await api.company.addCompanySystemConfiguration(companySettings);
            dispatch(updateCurrentCompanySettings.success(response.data));
            dispatch(setCurrentCompanySettings(response.data));
        } catch (e) {
            console.error(e);
            dispatch(updateCurrentCompanySettings.error(e));
        }
    };
}

export function getCurrentCompany() {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(getCompanyData.request(true));
            const response = await api.company.getCurrentCompany();
            dispatch(getCompanyData.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(getCompanyData.error(e));
        }
    };
}
