import { Form, Formik } from 'formik';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable1 } from 'redux-dispatchers';
import * as Yup from 'yup';
import FormikField from '../../../../common/utils/FormikField';

import { Button } from '../../../Buttons/Button';
import { createDataId } from '../../../../common/utils/dataId';
import { PasswordDTO } from 'src/services/types/ApiTypes';
import RequiredFieldsMessage from '../../../RequiredFieldsMessage/RequiredFieldsMessage';
import { TextInputField, TextInputType } from '../../../TextInput/TextInput';
import PasswordRequirements from './PasswordRequirements';
import withSuspense from '../../../../common/hocs/withSuspense';
import { MAX_SYMBOLS_IN_PASSWORD, MIN_SYMBOLS_IN_PASSWORD } from '../../../../common/constants/appConstants';
import { getWhitelabelParameter } from '../../../../common/utils/whitelabelHelper';
import { hasDigit, hasLowerCase, hasUpperCase, passwordMatch } from './utils';

import './ChangePassword.scss';

export interface Props {
    changePassword: Dispatchable1<PasswordDTO>;
    dataId: string;
    isLoading: boolean;
}

export type ChangePasswordProps = Props & WithTranslation;

export interface ChangePasswordFields {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export const ChangePassword = (props: ChangePasswordProps) => {
    const { changePassword, dataId, isLoading, t } = props;
    const initialFields: ChangePasswordFields = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    };

    const changePasswordValidationSchema: Yup.ObjectSchema<ChangePasswordFields> = Yup.object<ChangePasswordFields>().shape({
        currentPassword: Yup.string()
            .ensure()
            .required(t('component.changePassword.emptyOldPassword'))
            .nullable(true),
        newPassword: Yup.string()
            .ensure()
            .notOneOf([Yup.ref('currentPassword')], t('component.changePassword.oldAndNewAreSimilar'))
            .min(MIN_SYMBOLS_IN_PASSWORD, t('component.changePassword.error.mustBeAtLeast12CharsLong'))
            .max(MAX_SYMBOLS_IN_PASSWORD, t('component.changePassword.error.mustBeAtLessThan256CharsLong'))
            .nullable(true),
        confirmPassword: Yup.string()
            .ensure()
            .oneOf([Yup.ref('newPassword')], t('component.changePassword.passwordsDoNotMatch'))
            .min(MIN_SYMBOLS_IN_PASSWORD, t('component.changePassword.error.mustBeAtLeast12CharsLong'))
            .max(MAX_SYMBOLS_IN_PASSWORD, t('component.changePassword.error.mustBeAtLessThan256CharsLong'))
            .nullable(false),
    });

    return (
        <Form autoComplete="off">
            <Formik
                initialValues={initialFields}
                onSubmit={(vals) => {
                    const { currentPassword, newPassword } = vals;
                    const whiteLabel = getWhitelabelParameter();
                    if (hasDigit(newPassword) && hasUpperCase(newPassword) && hasLowerCase(newPassword)) {
                        changePassword({ OldPassword: currentPassword, NewPassword: newPassword, Whitelabel: whiteLabel });
                    }
                }}
                validationSchema={changePasswordValidationSchema}
                enableReinitialize={true}
                validateOnBlur={true}
                validateOnChange={true}
            >
                {(formik) => (
                    <>
                        <div className="change-password" data-id="changePassword.form">
                            <FormikField
                                component={TextInputField}
                                wrapperClass="change-password__input"
                                type={TextInputType.BORDERED}
                                label={t('component.changePassword.oldPassword') + ' *'}
                                name="currentPassword"
                                autoComplete="new-password"
                                password={true}
                                dataId={createDataId(dataId, 'changePassword', 'currentPassword')}
                            />
                            <FormikField
                                component={TextInputField}
                                wrapperClass="change-password__input"
                                type={TextInputType.BORDERED}
                                label={t('component.changePassword.newPassword') + ' *'}
                                name="newPassword"
                                password={true}
                                autoComplete="new-password"
                                dataId={createDataId(dataId, 'changePassword', 'newPassword')}
                            />
                            <FormikField
                                component={TextInputField}
                                wrapperClass="change-password__input"
                                type={TextInputType.BORDERED}
                                label={t('component.changePassword.confirmPassword') + ' *'}
                                name="confirmPassword"
                                password={true}
                                autoComplete="new-password"
                                dataId={createDataId(dataId, 'changePassword', 'confirmPassword')}
                            />
                            <PasswordRequirements
                                t={t}
                                isPristine={!formik.touched.newPassword}
                                isMatch={passwordMatch(formik.values.newPassword, formik.values.confirmPassword)}
                                value={formik.values.newPassword}
                            />

                            <RequiredFieldsMessage />
                        </div>
                        <div className="user-profile__footer" data-id={createDataId(dataId, 'changePassword', 'footer')}>
                            <Button dataId={createDataId(dataId, 'changePassword', 'button.save')} loading={isLoading} onClick={() => formik.submitForm()}>
                                {t('views.home.index.Change_Password')}
                            </Button>
                        </div>
                    </>
                )}
            </Formik>
        </Form>
    );
};

export default withSuspense(withTranslation()(ChangePassword));
