import React from 'react';

interface ToggleItemsProps {
    isFirstItemVisibleDefault?: boolean;
    firstItem: React.ReactElement<any>;
    secondItem: React.ReactElement<any>;
}

const ToggleItems = ({ firstItem, secondItem, isFirstItemVisibleDefault = true }: ToggleItemsProps): React.ReactElement => {
    const [isFirstItemVisible, setIsFirstItemVisible] = React.useState(isFirstItemVisibleDefault);

    return isFirstItemVisible
        ? React.cloneElement(firstItem, { onClick: () => setIsFirstItemVisible(false) })
        : React.cloneElement(secondItem, {
              onBlur: () => {
                  setIsFirstItemVisible(true);
              },
          });
};

export default ToggleItems;
