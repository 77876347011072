"use strict";

angular
    .module("dstreamApp.components")
    .component("dsExportManagement", {
        templateUrl: "app/components/exportManagement/ds-export-management.html",
        controller: DsExportManagementController,
        bindings: {
        }
    });

/* @ngInject */
function DsExportManagementController($scope, $rootScope, webServices, authenticationService) {
    var ctrl = this;

    ctrl.visible = true;
    ctrl.position = {};

    ctrl.invoiceLoading = true;

    var invoiceLoadedEvent = $rootScope.$on("invoiceLoaded", function (evt, invoice) {
        ctrl.invoiceLoading = false;
        if (!!invoice && invoice.hasOwnProperty("IsExportAfterApprovalEnabled") && invoice.IsExportAfterApprovalEnabled !== false) {
            invoice.IsExportAfterApprovalEnabled = true;
        }
        if (invoice) {
            ctrl.invoice = angular.copy(invoice);
        }

        ctrl.IsExportAfterApprovalEnabled = ctrl.invoice.IsExportAfterApprovalEnabled;
    });

    ctrl.toggleComponent = toggleComponent;
    ctrl.isChanged = isChanged;
    ctrl.canChangeExportSetting = canChangeExportSetting;
    ctrl.save = save;
    ctrl.showConfirm = showConfirm;
    ctrl.exportStatusReset = exportStatusReset;

    function exportStatusReset() {
        webServices.exportStatusReset(ctrl.invoice.Id).then((response) => {
            if (response && response.data) {
                return $scope.$parent.getInvoiceData(ctrl.invoice.Id);
            }
        });
    }

    function showConfirm() {
        return (
            !!ctrl.invoice &&
            ctrl.invoice.hasOwnProperty("Status") &&
            ctrl.invoice.Status !== 5
        ) &&
            ctrl.canChangeExportSetting() &&
            ctrl.isChanged();
    }

    function toggleComponent() {
        ctrl.visible = !ctrl.visible;
    }

    function isChanged() {
        if (!ctrl.invoice)
            return false;
        return ctrl.invoice.IsExportAfterApprovalEnabled !== ctrl.IsExportAfterApprovalEnabled;
    }

    function canChangeExportSetting() {
        return authenticationService.isAuthorized("CanChangeExportSettings") &&
            !!ctrl.invoice &&
            ctrl.invoice.hasOwnProperty("Status") &&
            ctrl.invoice.Status < 3;
    }

    function save() {
        ctrl.invoiceLoading = true;
        webServices.updateInvoiceExportSetting(ctrl.invoice.Id, ctrl.invoice.IsExportAfterApprovalEnabled).then(
            (response) => {
                if (response && response.data) {
                    return $scope.$parent.getInvoiceData(ctrl.invoice.Id)
                }
            }).then(
                (result) => {
                    if (!!result) {
                        ctrl.invoice = { ...result };
                        ctrl.IsExportAfterApprovalEnabled = ctrl.invoice.IsExportAfterApprovalEnabled;
                    }
                }).catch(
                    function () {
                        $rootScope.$emit('invoiceLoaded', ctrl.invoice);
                    });
    }

    ctrl.$onDestroy = function () {
        invoiceLoadedEvent();
    };
}