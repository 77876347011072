import {isBackOffice} from '../../../src/components/MainMenu/MainMenuHelper'

(function () {
    'use strict';

    angular
        .module('blocks.router')
        .provider('customRoute', customRouteProvider);

    customRouteProvider.$inject = ['$routeProvider'];

    /*
        Custom route provider that handles universal resolves that we need to call before each route
     */
    function customRouteProvider($routeProvider) {
        return customRouteProvider = angular.extend({}, $routeProvider, {
            when: function (path, route) {
                route.resolve = (route.resolve) ? route.resolve : {};
                angular.extend(route.resolve, {
                    userData: ['authenticationService', function (authenticationService) {
                        return authenticationService.getUserData();
                    }],
                    companyData: ['companyDataService', function (companyDataService) {
                        /* Dont load current companyData for BO as it is not necessary */
                        if (!isBackOffice(window.location.href)) {
                            return companyDataService.getCompanyData()
                        }
                    }],
                });
                $routeProvider.when(path, route);
                return this;
            }
        });
    }
})();
