import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import { formatDate } from './formatters';

export type SaveFileFromResponseOptions = {
    namePrefix?: string;
    fileExtension: 'csv' | 'xlsx';
    customFileName?: string;
};

export const mimeTypesMap: Map<SaveFileFromResponseOptions['fileExtension'], string> = new Map([
    ['csv', 'text/csv;charset=utf-8;'],
    ['xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
]);

export const saveFileFromResponse = (response: AxiosResponse<any>, options: SaveFileFromResponseOptions) => {
    const { fileExtension, namePrefix, customFileName } = options;
    const mimeType = mimeTypesMap.get(fileExtension);
    const file = new Blob([response.data], { type: `${mimeType};charset=utf-8` });
    const fileName = customFileName || `${namePrefix}_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.${fileExtension}`;
    saveAs(file, fileName);
};
