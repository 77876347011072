import { push } from 'connected-react-router';

import { notify } from '../../common/utils/notify';
import { loadableDataActions } from '../../common/utils/LoadableData';
import i18n from '../../i18n';
import i18nInstance from '../../i18n';
import { GlobalState } from '../../rootReducer';
import { AutoTransactionUiObjectDTO } from '../../services/types/ApiTypes';
import api from '../../services/ApiServices';
import { DispatchThunk } from '../../storeConfig';

const ns = 'auto-transaction/';

export const addOrUpdateATActions = loadableDataActions<AutoTransactionUiObjectDTO, AutoTransactionUiObjectDTO>(`${ns}ADD_OR_UPDATE_AT`);
export const retrieveATActions = loadableDataActions<string, AutoTransactionUiObjectDTO>(`${ns}RETRIEVE_AUTO_TRANSACTION`);
export const retrieveVatRatesActions = loadableDataActions<undefined, number[]>(`${ns}RETRIEVE_COMPANY_VAT_RATES`);

export function addOrUpdateAT(values: AutoTransactionUiObjectDTO) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        let response;
        try {
            dispatch(addOrUpdateATActions.request(values));
            const request: AutoTransactionUiObjectDTO = values;
            const oldAT = state.autoTransactionAdd.autoTransaction;
            if (!request || !oldAT) {
                return;
            }
            response = await api.autoTransaction.updateAutoTransaction({
                ...oldAT,
                ...request,
            });

            dispatch(addOrUpdateATActions.success(response.data));
            notify.success(i18n.t('component.AutoTransaction.SuccessHeader'));
        } catch (e) {
            notify.info(e.message, i18n.t('interceptorsFactory.Error'));
            console.error(e);
            dispatch(addOrUpdateATActions.error(e));
        }
    };
}

export function retrieveAT(id?: string, snapshot?: boolean) {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            dispatch(retrieveATActions.request(id));
            if (id && !snapshot) {
                response = await api.autoTransaction.getAutoTransactionForUiById(parseInt(id, 10));
            } else if (id && snapshot) {
                response = await api.autoTransaction.getAutoTransactionSnapshotForInvoice(parseInt(id, 10));
            } else {
                response = await api.autoTransaction.getEmptyUiObject();
            }
            if (response.data) {
                dispatch(retrieveATActions.success(response.data));
            } else {
                dispatch(push('/dashboard'));
            }
        } catch (e) {
            console.error('Failed loading AutoTransaction object:', e);
            notify.error(e.message, i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'));
            dispatch(retrieveATActions.error(e));
        }
    };
}

export function getCompanyVatRates() {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(retrieveVatRatesActions.request(null));
            const response = await api.company.getVatRatesForCurrentCompany();
            dispatch(retrieveVatRatesActions.success(response.data));
        } catch (e) {
            dispatch(retrieveVatRatesActions.error(e));
        }
    };
}
