import { find, isEmpty, isNumber, isString, orderBy, uniqueId } from 'lodash-es';
import uuidV4 from 'uuid/v4';

import {
    AllocationAmountType,
    AutomationStepCombineField,
    AutoTransactionMatchType,
    AutoTransactionUiAllocation,
    AutoTransactionUiDimension,
    AutoTransactionUiObjectDTO,
    AutoTransactionUiRowsObjectDTO,
    AutoTransactionUiRowSearchConditionDTO,
    AutoTransactionUiVatRate,
    CombinationOptionType,
    CostObjectiveType,
    CustomCostObjectiveFullDTO,
    MetaField,
    VatCodeDTO,
    WorkflowAssignment,
    WorkflowAssignmentType,
    WorkflowTemplateDTO,
} from '../../services/types/ApiTypes';

import { AllocationFields, AutomationStepFields, AutoTransactionsAddViewFields, ConditionFields, DimensionFields, VatRateFields, WorkflowFields } from './autoTransactionAddViewFields';

export const AutomationStepCombineFields = {
    [AutomationStepCombineField.Description]: 'component.AutoTransaction.Parameter.Description',
    [AutomationStepCombineField.SerialNumber]: 'component.AutoTransaction.Parameter.SerialNumber',
    [AutomationStepCombineField.SellerProductId]: 'component.AutoTransaction.Parameter.SellerProductId',
    [AutomationStepCombineField.BuyerProductId]: 'component.AutoTransaction.Parameter.BuyerProductId',
    [AutomationStepCombineField.TaricCode]: 'component.AutoTransaction.Parameter.TaricCode',
    [AutomationStepCombineField.CustomerRef]: 'component.AutoTransaction.Parameter.CustomerRef',
    [AutomationStepCombineField.EAN]: 'component.AutoTransaction.Parameter.EAN',
    [AutomationStepCombineField.PartnerCode]: 'component.AutoTransaction.Parameter.PartnerCode',
    [AutomationStepCombineField.BusinessCode]: 'component.AutoTransaction.Parameter.BusinessCode',
    [AutomationStepCombineField.SourceCode]: 'component.AutoTransaction.Parameter.SourceCode',
    [AutomationStepCombineField.CashFlowCode]: 'component.AutoTransaction.Parameter.CashFlowCode',
    [AutomationStepCombineField.ClassificatorCode]: 'component.AutoTransaction.Parameter.ClassificatorCode',
    [AutomationStepCombineField.GroupId]: 'component.AutoTransaction.Parameter.GroupId',
};

export interface AutomationStepCombineFieldItem {
    name: AutomationStepCombineField;
    value: string;
    label: string;
    field?: MetaField;
}

export function getAutomationStepCombineFieldLabel(combineField: AutomationStepCombineField): string | AutomationStepCombineField {
    return AutomationStepCombineFields[combineField] || combineField;
}

const idPrefix = Date.now();

export function getAutomationStepFieldId(): number {
    return Number(uniqueId(idPrefix.toString()));
}

export function createNewAutomationRuleObject(): AutoTransactionUiObjectDTO {
    return {
        ReferenceNumber: null,
        SupplierId: null,
        Supplier: undefined,
        StopProcessing: undefined,
        IsActive: undefined,
        Triggers: [],
        AutoTransactionsRows: [],
        WorkflowAssignments: [],
        RecreateTransactionRows: undefined,
        ModifiedBy: undefined,
        LastInvoiceId: null,
        LastInvoice: undefined,
        IsNew: undefined,
        Id: null,
        Beneficiary: undefined,
        LastRun: undefined,
        RuleDescription: '',
        RuleName: '',
        AutoTransactionsCustomFields: [],
    };
}

export function getAllocationTypes() {
    /**
     * Currency will be supported in the future
     */
    return {
        [AllocationAmountType.Percent]: '%',
        // [AllocationAmountType.Currency]: 'EUR',
    };
}

export function getMatchTypes() {
    /**
     * Any and NONE are commented out because we don't currently offer support for these, but in future it is going to be supported.
     */
    return {
        // [AutoTransactionMatchType.Any]: 'component.AutoTransaction.Condition.Any',
        // [AutoTransactionMatchType.None]: 'component.AutoTransaction.Condition.None',
        [AutoTransactionMatchType.Is]: 'component.AutoTransaction.Condition.Is',
        [AutoTransactionMatchType.Contains]: 'component.AutoTransaction.Condition.Contains',
        [AutoTransactionMatchType.Empty]: 'component.AutoTransaction.Condition.IsEmpty',
        [AutoTransactionMatchType.Equal]: 'component.AutoTransaction.Condition.IsEqualTo',
        [AutoTransactionMatchType.NotEqual]: 'component.AutoTransaction.Condition.IsNotEqualTo',
        [AutoTransactionMatchType.NotEmpty]: 'component.AutoTransaction.Condition.IsNotEmpty',
        [AutoTransactionMatchType.Less]: 'component.AutoTransaction.Condition.Less',
        [AutoTransactionMatchType.LessEqual]: 'component.AutoTransaction.Condition.LessEqual',
        [AutoTransactionMatchType.Greater]: 'component.AutoTransaction.Condition.Greater',
        [AutoTransactionMatchType.GreaterEqual]: 'component.AutoTransaction.Condition.GreaterEqual',
    };
}

export function getCombinationTypes() {
    return {
        [CombinationOptionType.ApplyAll]: 'component.AutoTransaction.MainStep.ApplyAll',
        [CombinationOptionType.CombineAll]: 'component.AutoTransaction.MainStep.CombineAll',
        [CombinationOptionType.CombineSearch]: 'component.AutoTransaction.MainStep.CombineSearch',
        [CombinationOptionType.ApplyToAllWhere]: 'component.AutoTransaction.MainStep.ApplyAllWhere',
        [CombinationOptionType.CombineBy]: 'component.transactionRow.combine.header',
    };
}

export function getCombinationType(selectedCombinationOption: CombinationOptionType) {
    return getCombinationTypes()[selectedCombinationOption];
}

export function getMatchType(selectedMatchType: AutoTransactionMatchType) {
    return getMatchTypes()[selectedMatchType];
}

export function createWorkflowFields(workflowAssignments: WorkflowAssignment[], workflowTemplates: WorkflowTemplateDTO[]): WorkflowFields {
    let assignee: WorkflowTemplateDTO = null;
    if (workflowAssignments.length) {
        assignee = find(workflowTemplates, (template: WorkflowTemplateDTO) => template.Id === workflowAssignments[0].SelectedOption.Assignee.Id);
    }

    const assigneeItem = assignee && assignee.Id && assignee.Name ? { value: assignee.Id, text: assignee.Name } : null;
    return {
        enabled: !!workflowAssignments.length,
        assignee: assigneeItem,
        type: WorkflowAssignmentType.Workflow,
    };
}

export function createMandatoryDimensionsFields(): DimensionFields[] {
    return [
        {
            type: CostObjectiveType.Account,
            isNew: true,
            automationStepId: null,
            readOnly: true,
            customCostObjective: null,
            dimension: null,
            orderNo: 1,
        },
        {
            type: CostObjectiveType.VatCode,
            isNew: true,
            automationStepId: null,
            readOnly: true,
            customCostObjective: null,
            dimension: null,
            orderNo: 2,
        },
    ];
}

export function createDimensionsFields(dimensions: AutoTransactionUiDimension[], vatCodes: VatCodeDTO[]) {
    return dimensions.map((dimension: AutoTransactionUiDimension) => {
        let dimensionName: string;
        let orderNo = 0;
        switch (dimension.CostObjectiveType) {
            case CostObjectiveType.Account:
                dimensionName = dimension.CostObjectiveId ? dimension.CostObjectiveCode + ' - ' + dimension.CostObjectiveDescription : null;
                orderNo = 1;
                break;
            case CostObjectiveType.VatCode:
                const vatCode = dimension.CostObjectiveId && vatCodes.find((code) => code.Id === dimension.CostObjectiveId);
                dimensionName = vatCode ? vatCode.Code + ' - ' + vatCode.Description : null;
                orderNo = 2;
                break;
            case CostObjectiveType.CustomCostObjective:
                dimensionName = dimension.CostObjectiveId && dimension.CostObjectiveCode + ' - ' + dimension.CostObjectiveDescription;
                orderNo = dimension.OrderNo + 2;
                break;
            default:
                break;
        }
        return {
            isNew: dimension.IsNew,
            automationStepId: dimension.AutoTransactionRowId,
            readOnly: dimension.CostObjectiveType !== CostObjectiveType.CustomCostObjective,
            type: dimension.CostObjectiveType,
            customCostObjective: {
                value: dimension.CustomCostObjectiveId,
                text: dimension.CustomCostObjectiveDescription,
            },
            dimension: {
                value: dimension.CostObjectiveId,
                text: dimensionName,
            },
            orderNo,
        };
    });
}

export function createAllocationsFields(allocations: AutoTransactionUiAllocation[], vatCodes: VatCodeDTO[]): AllocationFields[] {
    return allocations.map(
        (allocation: AutoTransactionUiAllocation): AllocationFields => ({
            amount: allocation.AllocationPercent,
            type: AllocationAmountType.Percent,
            description: allocation.AllocationDescription,
            comment: allocation.AllocationComment,
            dimensions: createDimensionsFields(allocation.Dimensions, vatCodes),
        }),
    );
}

export function createVatRatesFields(vatRates: AutoTransactionUiVatRate[], vatCodes: VatCodeDTO[]) {
    return vatRates.map(
        (vatRate: AutoTransactionUiVatRate): VatRateFields => ({
            vatRate: vatRate.VatRate,
            unPostedAmount: parseFloat(vatRate.Allocations.reduce((previous: number, current: AutoTransactionUiAllocation) => previous - current.AllocationPercent, 100).toFixed(2)),
            allocations: createAllocationsFields(vatRate.Allocations, vatCodes),
        }),
    );
}

function parseCombineByValue(rowSearchString: string) {
    return {
        text: rowSearchString.indexOf(':::') === -1 ? rowSearchString : rowSearchString.split(':::')[2],
        value:
            rowSearchString.indexOf(':::') === -1
                ? {
                      field: MetaField.None,
                      value: rowSearchString,
                  }
                : {
                      field: Number(rowSearchString.split(':::')[1]),
                      value: rowSearchString.split(':::')[2],
                  },
    };
}

export function createAutomationStepConditionFields(conditions: AutoTransactionUiRowSearchConditionDTO[], combinationOption: CombinationOptionType): ConditionFields[] {
    return conditions.map(
        (condition): ConditionFields => ({
            combineField: combinationOption === CombinationOptionType.CombineBy ? undefined : isEmpty(condition.CombineField) ? AutomationStepCombineField.Description : condition.CombineField,
            combineMatchType: condition.CombineMatchType,
            rowSearchString: condition.RowSearchString || '',
            combineBySelection:
                combinationOption === CombinationOptionType.CombineBy
                    ? parseCombineByValue(condition.RowSearchString)
                    : {
                          text: '',
                          value: {
                              field: undefined,
                              value: '',
                          },
                      },
            orderNo: condition.OrderNo,
        }),
    );
}

export function createAutomationStepsFields(automationSteps: AutoTransactionUiRowsObjectDTO[], vatCodes: VatCodeDTO[]) {
    return orderBy(automationSteps, ['OrderNo'], ['asc']).map(
        (automationStep: AutoTransactionUiRowsObjectDTO, index: number): AutomationStepFields => {
            return {
                combinationOption: automationStep.CombinationOption,
                conditions: createAutomationStepConditionFields(automationStep.Conditions, automationStep.CombinationOption),
                vatRates: createVatRatesFields(automationStep.VatRates, vatCodes),
                isNew: automationStep.IsNew,
                automationStepId: automationStep.AutoTransactionId,
                automationStepOrderNo: automationStep.OrderNo,
                comment: automationStep.Comment || '',
                orderNo: index + 1, // start using index from the iteration to avoid possible duplicate orderNos (we seem to have them currently) that create issues when dragging,
                id: getAutomationStepFieldId(), // add unique ID to guarantee uniqueness of rows for sorting and re-rendering when rows are saved, used only for FE
            };
        },
    );
}

export function getCustomCostObjectiveName(customCostObjective: CustomCostObjectiveFullDTO) {
    return customCostObjective.Description;
}

export function createDimensionFields(customCostObjective: CustomCostObjectiveFullDTO): DimensionFields {
    return {
        customCostObjective: {
            value: customCostObjective.Id,
            text: getCustomCostObjectiveName(customCostObjective),
        },
        dimension: null,
        readOnly: false,
        type: CostObjectiveType.CustomCostObjective,
        automationStepId: 0,
        isNew: true,
        orderNo: customCostObjective.OrderNo ? customCostObjective.OrderNo + 2 : null,
    };
}

export function createAllocationFields(amount = 100): AllocationFields {
    return {
        amount,
        type: AllocationAmountType.Percent,
        description: '',
        comment: '',
        dimensions: createMandatoryDimensionsFields(),
    };
}

export function createVatRateFields(vatRate: number): VatRateFields {
    return {
        vatRate,
        unPostedAmount: 0,
        allocations: [],
    };
}

export function createAutomationStepFields(combinationType: CombinationOptionType): AutomationStepFields {
    return {
        combinationOption: combinationType,
        conditions: [
            {
                combineField: null,
                combineMatchType: null,
                rowSearchString: '',
                combineBySelection: {
                    text: '',
                    value: {
                        field: undefined,
                        value: '',
                    },
                },
                orderNo: 1,
            },
        ],
        vatRates: [],
        automationStepOrderNo: null,
        automationStepId: combinationType,
        isNew: true,
        comment: '',
        orderNo: -1,
        id: getAutomationStepFieldId(),
    };
}

function formatRowSearchString(value: string) {
    const strArr = value.split(' ');
    strArr.forEach((item, index) => {
        strArr[index] = (function(strItem) {
            return strItem.charAt(0).toUpperCase() + strItem.substr(1);
        })(item);
    });

    const newRowSearchString = strArr.join('');
    return newRowSearchString + uuidV4();
}

export function createAutomationRuleApiObject(
    automationRuleFields: AutoTransactionsAddViewFields,
    automationRule: AutoTransactionUiObjectDTO,
    groupMemberId: number,
    t: any,
): AutoTransactionUiObjectDTO {
    let automationRuleApiObject = createNewAutomationRuleObject();
    automationRuleFields.triggersView.triggers.map((trigger) => {
        automationRuleApiObject.Triggers.push({
            MatchType: trigger.matchType,
            ExtensionField: trigger.extensionField,
            ExtensionType: trigger.extensionType,
            Value: isString(trigger.value) ? trigger.value : isNumber(trigger.value) ? String(trigger.value) : String(trigger.value.value),
            DisplayValue: trigger.displayValue,
        });
    });

    automationRuleFields.autoTransactionsRows.forEach((automationStep, automationStepIndex) => {
        let automationStepSearchString = '';
        const automationStepConditions: AutoTransactionUiRowSearchConditionDTO[] = [];
        automationStep.conditions.forEach((condition) => {
            if (automationStep.combinationOption === CombinationOptionType.CombineBy) {
                const { value } = condition.combineBySelection.value;
                automationStepSearchString = value;
            } else {
                if ([CombinationOptionType.ApplyToAllWhere, CombinationOptionType.CombineSearch].includes(automationStep.combinationOption)) {
                    automationStepSearchString = condition.rowSearchString;
                } else {
                    automationStepSearchString = t(getCombinationType(automationStep.combinationOption));
                    automationStepSearchString = formatRowSearchString(automationStepSearchString);
                }
            }
            automationStepConditions.push({
                RowSearchString: automationStepSearchString,
                OrderNo: condition.orderNo,
                CombineField: condition.combineField,
                CombineMatchType: condition.combineMatchType,
            });
        });
        automationRuleApiObject.AutoTransactionsRows.push({
            VatRates: automationStep.vatRates.map((vatRate) => {
                return {
                    Allocations: vatRate.allocations.map((allocation) => {
                        return {
                            Dimensions: allocation.dimensions.map((dimension) => {
                                let mappedDimension: AutoTransactionUiDimension = {
                                    CostObjectiveType: dimension.type,
                                    AutoTransactionRowId: 0,
                                    Id: 0,
                                    IsNew: true,
                                    CustomCostObjectiveDescription: null,
                                    CustomCostObjectiveId: null,
                                    CostObjectiveId: null,
                                    CostObjectiveCode: null,
                                    CostObjectiveDescription: null,
                                    OrderNo: 0,
                                };
                                if (dimension.type === CostObjectiveType.CustomCostObjective) {
                                    mappedDimension = {
                                        ...mappedDimension,
                                        CustomCostObjectiveId: dimension.customCostObjective.value,
                                        CustomCostObjectiveDescription: dimension.customCostObjective.text,
                                    };
                                }
                                if (!isEmpty(dimension.dimension)) {
                                    mappedDimension = {
                                        ...mappedDimension,
                                        CostObjectiveDescription: dimension.dimension && dimension.dimension.text && dimension.dimension.text.split(' - ')[1],
                                        CostObjectiveCode: dimension.dimension && dimension.dimension.text && dimension.dimension.text.split(' - ')[0],
                                        CostObjectiveId: dimension.dimension && dimension.dimension.value,
                                    };
                                }
                                return mappedDimension;
                            }),
                            AllocationDescription: allocation.description,
                            AllocationComment: allocation.comment,
                            AllocationPercent: allocation.amount,
                        };
                    }),
                    VatRate: vatRate.vatRate,
                };
            }),
            Conditions: automationStepConditions,
            CombinationOption: automationStep.combinationOption,
            OrderNo: automationStepIndex + 1,
            IsNew: automationStep.isNew,
            Id: automationStep.isNew ? 0 : automationStep.automationStepId,
            Comment: automationStep.comment || '',
            AutoTransactionId: automationRuleFields.id || automationRule.Id,
        });
    });
    automationRuleApiObject = {
        ...automationRuleApiObject,
        WorkflowAssignments: automationRuleFields.workflow.enabled
            ? [
                  {
                      SelectedOption: {
                          Assignee: {
                              Id: automationRuleFields.workflow.assignee.value,
                          },
                          Value: automationRuleFields.workflow.type,
                      },
                  },
              ]
            : undefined,
        RuleName: automationRuleFields.ruleName,
        RuleDescription: automationRuleFields.ruleDescription,
        LastRun: automationRule.LastRun,
        LastInvoice: automationRule.LastInvoice,
        LastInvoiceId: automationRule.LastInvoiceId,
        Beneficiary: automationRule.Beneficiary,
        ModifiedBy: groupMemberId,
        IsNew: automationRule.IsNew,
        IsActive: automationRuleFields.triggersView.isRuleActive,
        StopProcessing: automationRuleFields.triggersView.stopProcessing,
        Id: automationRule.IsNew ? 0 : automationRule.Id,
        RecreateTransactionRows: automationRuleFields.recreateTransactionRows,
        AutoTransactionsCustomFields: automationRuleFields.autoTransactionsCustomFields || [],
    };
    return automationRuleApiObject;
}
