import { Role } from "../../../src/common/user/userPermissionUtil";

(function() {
    'use strict';

    angular
        .module('dstreamApp.views.archive-register')
        .component('dsArchiveRegister', {
            templateUrl: 'app/views/archive-register/archive-register.html',
            controller: ArchiveRegister
        });

    ArchiveRegister.$inject = ["$location","authenticationService"];

    function ArchiveRegister($location, authenticationService) {
        var ctrl = this;

        ctrl.$onInit = function() {
            if(!authenticationService.isAuthorized(Role.CanViewArchive))
            {
                $location.path("/dashboard");
            }
        }
    }
})();