import React, { useEffect, useRef } from 'react';
import { Instance } from 'tippy.js';
import { cloneDeep, debounce, isEmpty, noop, set, isNumber } from 'lodash-es';
import * as Yup from 'yup';
import { WithTranslation } from 'react-i18next';
import { Dispatchable1, Dispatchable3, Dispatchable2, Dispatchable4 } from 'redux-dispatchers';
import { Formik, FormikProps } from 'formik';
import classNames from 'classnames';

import { createDataId } from '../../../../common/utils/dataId';
import FormikField from '../../../../common/utils/FormikField';
import { isAuthorized, Role } from '../../../../common/user/userPermissionUtil';
import { readFileContentAsBase64, FileReaderResult } from '../../../../common/utils/fileReader';
import { DECIMAL_ROWS_4_REGEXP, INTEGER_REGEXP, DECIMAL_ROWS_VAT_REGEXP } from '../../../../common/utils/validators';
import { Typography } from '../../../../components/Typography';
import { InvoiceDTO, InvoiceRowDTO, InvoiceRowDiscountType, InvoiceRowDiscountDTO } from '../../../../services/types/ApiTypes';
import { MainPage, MainPageType } from '../../../../components/MainPage/MainPage';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { ContentBlockHeader, ContentBlockHeaderType } from '../../../../components/ContentBlock/ContentBlockHeader';
import { Button, ButtonIconPlacement, ButtonType } from '../../../../components/Buttons/Button';
import { ICONS } from '../../../../components/Icon/Icon';
import { ContentBlockBody } from '../../../../components/ContentBlock/ContentBlockBody';
import Table from '../../../../components/Table/Table';
import { DataTableHeader } from '../../../../components/Table/TableHeader';
import TableRow from '../../../../components/Table/TableRow';
import TableHead from '../../../../components/Table/TableHead';
import TableBody from '../../../../components/Table/TableBody';
import TableCell, { ResponsiveCellType } from '../../../../components/Table/TableCell';
import { ContentBlockFooter, ContentBlockFooterType } from '../../../../components/ContentBlock/ContentBlockFooter';
import Pager from '../../../../components/Pager/Pager';
import { TextInputField, TextInputType } from '../../../../components/TextInput/TextInput';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { Switch } from '../../../../components/Switch/Switch';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import StampLabel from '../../../../components/StampLabel/StampLabel';
import CollapseContent from './components/CollapseContent';
import { DropdownMenu, DropdownMenuItem } from '../../../../components/DropdownMenu/DropdownMenu';
import EmptySearchResults from '../../../../components/EmptySearchResults/EmptySearchResults';
import { formatMoneyToShowSeparators } from '../invoice-header/utils';
import { InvoiceRowsSearchParams, InvoiceRowsViewState } from './InvoiceRowsReducers';
import { MonetaryPrecisionScalesField } from '../../../../components/MonetaryPrecisionScales/MonetaryPrecisionScalesInput';

import {
    BulkActionModifier,
    InvoiceRowsBulkActions,
    getInitialFields,
    getInputRegExp,
    getPricePrecision,
    getQtyPrecision,
    invoiceRowsBulkActionTranslations,
    newDiscountObject,
    rowValueCalculationFns,
    rowsSumsWrong,
    rowsTotalWrong,
    rowsVatSumsWrong,
    trimAfterComma,
    getDiscountLabelTranslateKey,
    getDiscountLabelType,
    getDiscountBeforeIcon,
    checkDiscountSumType,
    areInvoiceRowsSumsNotMatching,
    isMonetaryAmountCheckEnabled,
} from './InvoiceRowsHelper';

import './InvoiceRows.scss';

export interface Props {
    invoice: InvoiceDTO;
    isReadOnly: boolean;
}
export interface Props extends Pick<InvoiceRowsViewState, 'invoiceRowsLoadable' | 'searchParams'> {
    reloadInvoiceData: (invoiceId: number) => void;
    canSaveInvoice: (checkStatus: boolean) => boolean;
    invoice: InvoiceDTO;
    isInvoiceRowsCalculationActive: boolean;
}

export type InvoiceRowsProps = Props & WithTranslation & DispatchProps;

type CollapsedRowsObject = { [key: number]: boolean };

type BulkIdsObject = { [key: number]: number[] };

export interface DispatchProps {
    getInvoiceRowsList: Dispatchable3<number, InvoiceRowsSearchParams, boolean>;
    setInvoiceRowsListPagingOptions: Dispatchable3<number, number, number>;
    saveRow: Dispatchable3<InvoiceRowDTO, (invoiceId: number) => void, boolean>;
    deleteRow: Dispatchable2<InvoiceRowDTO, (invoiceId: number) => void>;
    bulkDeleteInvoiceRow: Dispatchable4<number, BulkActionModifier, (invoiceId: number) => void, number[]>;
    gotoLastPageAndAddNewRow: Dispatchable1<number>;
    removeNewRow: Dispatchable1<number>;
    setIsInvoiceRowsCalculationActive: Dispatchable1<boolean>;
    importFromInvoiceRows: Dispatchable3<number, (invoiceId: number) => void, boolean>;
    uploadFile: Dispatchable3<FileReaderResult, number, (invoiceId: number) => void>;
}

const InvoiceRows: React.FC<InvoiceRowsProps> = ({
    invoice,
    isReadOnly,
    t,
    canSaveInvoice,
    isInvoiceRowsCalculationActive,
    reloadInvoiceData,
    getInvoiceRowsList,
    setInvoiceRowsListPagingOptions,
    deleteRow,
    bulkDeleteInvoiceRow,
    saveRow,
    gotoLastPageAndAddNewRow,
    removeNewRow,
    setIsInvoiceRowsCalculationActive,
    invoiceRowsLoadable,
    importFromInvoiceRows,
    uploadFile,
}) => {
    const [collapsedRows, setCollapsedRows] = React.useState<CollapsedRowsObject>({});
    const [isAllCollapsed, setIsAllCollapsed] = React.useState<boolean>(false);
    const [activeEditRowId, setActiveEditRowId] = React.useState<number>(null);
    const [isFullscreen, setIsFullscreen] = React.useState(false);
    const [tooltipInstance, setTooltipInstance] = React.useState<Instance>(null);
    const [bulkIds, setBulkIds] = React.useState<BulkIdsObject>({});
    const [isAllBulkSelected, setIsAllBulkSelected] = React.useState<boolean>(false);
    const formRef = useRef<Formik<InvoiceRowDTO>>();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const isAllRowsCollapsed = isAllCollapsed || invoiceRowsLoadable?.payload?.Items?.length === Object.keys(collapsedRows)?.length;
    const isBulkDisabled = activeEditRowId !== null || !canSaveInvoice(false) || isReadOnly;

    const toggleCollapse = (id: number) => {
        if (isAllRowsCollapsed) {
            const newCollapsedRows: CollapsedRowsObject = {};
            for (const row of invoiceRowsLoadable.payload.Items) {
                if (row.Id !== id) {
                    newCollapsedRows[row.Id] = true;
                }
            }

            setCollapsedRows(newCollapsedRows);
            setIsAllCollapsed(false);
        } else {
            if (collapsedRows[id]) {
                const newCollapsedRows = { ...collapsedRows };
                delete newCollapsedRows[id];
                setCollapsedRows(newCollapsedRows);
            } else {
                const newCollapsedRows = { ...collapsedRows, [id]: true };
                if (Object.keys(newCollapsedRows)?.length === invoiceRowsLoadable.payload.Items.length) {
                    setIsAllCollapsed(true);
                    setCollapsedRows({});
                } else {
                    setCollapsedRows(newCollapsedRows);
                }
            }
        }
    };

    const toggleCollapseAll = () => {
        if (isAllRowsCollapsed) {
            setCollapsedRows({});
        }
        setIsAllCollapsed(!isAllRowsCollapsed);
    };

    const fullScreenElementId = 'invoice-rows';

    const dataId = 'invoiceRowsList';

    // Watch for fullscreenchange
    useEffect(() => {
        function onFullscreenChange() {
            setIsFullscreen(Boolean(document.fullscreenElement));
        }

        document.addEventListener('fullscreenchange', onFullscreenChange);

        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);

    useEffect(() => {
        if (activeEditRowId) {
            setActiveEditRowId(null);
        }
        if (isAllBulkSelected) {
            setIsAllBulkSelected(false);
        }
        if (!isEmpty(bulkIds)) {
            setBulkIds({});
        }
    }, [invoice]);

    useEffect(() => {
        if (invoice?.Id) {
            getInvoiceRowsList(invoice.Id, null, true);
        }
        setCollapsedRows({});
    }, [invoice?.Id]);

    const invoiceRowDiscountsSchema = Yup.object().shape({
        Type: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: 'Type' })),
        Description: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: 'Description' })),
    });

    const validationSchema = Yup.object<InvoiceRowDTO>().shape({
        OrderNo: Yup.number()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: 'Order number' })),
        Description: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: 'Description' })),
        SumWithoutVat: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: 'SumWithoutVat' })),
        VatRate: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: 'VatRate' })),
        VAT: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: 'VAT' })),
        Total: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: 'Total' })),
        InvoiceRowDiscounts: Yup.array(invoiceRowDiscountsSchema),
    });

    const countBulkIds = () => {
        const newObject = cloneDeep(bulkIds);
        let counter = 0;

        Object.keys(newObject).forEach((key) => {
            const bulkIdsLength = newObject[key]?.length;
            if (bulkIdsLength > 0) {
                counter = counter + bulkIdsLength;
            }
        });

        return counter;
    };

    const handleBulkChange = (bulkType: InvoiceRowsBulkActions) => {
        if (bulkType === InvoiceRowsBulkActions.All) {
            setIsAllBulkSelected(true);
            setBulkIds({});
        }

        if (bulkType === InvoiceRowsBulkActions.None) {
            setBulkIds({});
            if (isAllBulkSelected) {
                setIsAllBulkSelected(false);
            }
        }

        if (bulkType === InvoiceRowsBulkActions.ThisPage) {
            const newObject = cloneDeep(bulkIds);
            newObject[invoiceRowsLoadable.request.PagingOptions.Page] = invoiceRowsLoadable.payload?.Items.map((e) => e.Id);
            setBulkIds(newObject);
            if (isAllBulkSelected) {
                setIsAllBulkSelected(false);
            }
        }

        if (bulkType === InvoiceRowsBulkActions.Invert) {
            if (isAllBulkSelected) {
                setIsAllBulkSelected(false);
            } else {
                const newObject = cloneDeep(bulkIds);
                newObject[invoiceRowsLoadable?.request?.PagingOptions?.Page] = invoiceRowsLoadable.payload?.Items.filter(
                    (e) => !newObject[invoiceRowsLoadable?.request?.PagingOptions?.Page]?.includes(e.Id),
                ).map((e) => e.Id);
                if (isEmpty(newObject[invoiceRowsLoadable?.request?.PagingOptions?.Page])) {
                    delete newObject[invoiceRowsLoadable?.request?.PagingOptions?.Page];
                }
                setBulkIds(newObject);
            }
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];

        readFileContentAsBase64(file).then(async (fileReaderResult) => {
            uploadFile(fileReaderResult, invoice.Id, reloadInvoiceData);
            if (fileInputRef && fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        });
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        setActiveEditRowId(null);
        setCollapsedRows({});
        setInvoiceRowsListPagingOptions(invoice.Id, page, pageSize);
    };

    const toogleFullScreen = () => {
        const elem = document.getElementById(fullScreenElementId);

        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else if (elem.requestFullscreen) {
            elem.requestFullscreen();
        }
    };

    const handleDelete = (row: InvoiceRowDTO) => {
        deleteRow(row, reloadInvoiceData);
        tooltipInstance.hide();
        if (collapsedRows[row.Id]) {
            toggleCollapse(row.Id);
        }
    };

    const debounceHandleDelete = debounce(handleDelete, 250);

    const handleBulkDelete = () => {
        let bulkAction;
        let bulkdIdsArray: number[] = [];
        if (!isEmpty(bulkIds) && !isAllBulkSelected) {
            bulkAction = BulkActionModifier.Exact;

            Object.keys(bulkIds).forEach((key) => {
                if (!isEmpty(bulkIds[key])) {
                    bulkdIdsArray = [...bulkdIdsArray, ...bulkIds[key]];
                }
            });
        }
        if (isEmpty(bulkIds) && isAllBulkSelected) {
            bulkAction = BulkActionModifier.All;
        }
        if (!isEmpty(bulkIds) && isAllBulkSelected) {
            bulkAction = BulkActionModifier.Except;

            Object.keys(bulkIds).forEach((key) => {
                if (!isEmpty(bulkIds[key])) {
                    bulkdIdsArray = [...bulkdIdsArray, ...bulkIds[key]];
                }
            });
        }
        bulkDeleteInvoiceRow(invoice.Id, bulkAction, reloadInvoiceData, bulkdIdsArray);
        if (isAllBulkSelected) {
            setIsAllBulkSelected(false);
            setCollapsedRows({});
            setIsAllCollapsed(false);
        } else {
            bulkIds[invoiceRowsLoadable.request.PagingOptions.Page]?.forEach((e: number) => collapsedRows[e] && toggleCollapse(e));
        }
        setBulkIds({});
    };

    const debounceHandleBulkDelete = debounce(handleBulkDelete, 250);

    const getTooltipContent = (row: InvoiceRowDTO, disabled: boolean) => {
        return disabled ? (
            t('component.invoiceRows.buttons.tooltip.finishEdit')
        ) : (
            <div>
                <p>{t('component.invoiceRows.deleteRowConfirmationText')}</p>
                <p className="action-wrap action-wrap--right">
                    <button className="btn btn--secondary btn--sm" onClick={() => tooltipInstance.hide()}>
                        {t('views.global.No')}
                    </button>
                    <button className="btn btn--primary btn--sm" onClick={() => debounceHandleDelete(row)}>
                        {t('views.global.Yes')}
                    </button>
                </p>
            </div>
        );
    };

    const getTooltipDiscountContent = (row: InvoiceRowDTO) => {
        return (
            <div className="invoice-rows__discounts-tooltip-content">
                <table className="invoice-rows__discounts-tooltip-rows">
                    {row?.InvoiceRowDiscounts.map((e, i) => (
                        <tbody key={i} className="invoice-rows__discounts-tooltip-rows-item">
                            <tr>
                                <td>{`Description: `}</td>
                                <td>{e?.Description || ''}</td>
                            </tr>
                            <tr>
                                <td>{`Rate: `}</td>
                                <td>{isNumber(e?.Rate) ? `${getDiscountBeforeIcon(Number(row.ItemSum), e)}${e.Rate}%` : ''}</td>
                            </tr>
                            <tr>
                                <td>{`Amount: `}</td>
                                <td>{isNumber(e?.Amount) ? `${getDiscountBeforeIcon(Number(row.ItemSum), e)}${formatMoneyToShowSeparators(e.Amount, true)}` : ''}</td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        );
    };

    const handleSubmit = (row: InvoiceRowDTO, saveAndAddNewRow?: boolean) => {
        saveRow(row, reloadInvoiceData, saveAndAddNewRow);
        if (!saveAndAddNewRow) {
            setActiveEditRowId(null);
        }
    };

    const debounceHandleSubmit = debounce(handleSubmit, 250);

    const saveProductId = (row: InvoiceRowDTO) => {
        saveRow(row, reloadInvoiceData, false);
    };

    const debounceSaveProductId = debounce(saveProductId, 250);

    const handleCellChange = (cellValue: any, dataKey: string, formik: FormikProps<InvoiceRowDTO>, noRecalculate?: boolean) => {
        if (isInvoiceRowsCalculationActive && !noRecalculate) {
            const row = cloneDeep(formik.values);
            set(row, dataKey, cellValue);
            const rowValueCalculationFn = rowValueCalculationFns[dataKey];
            if (!rowValueCalculationFn) {
                return;
            }
            rowValueCalculationFn(row);
            formik.setValues(row);
        } else {
            formik.setFieldValue(dataKey, cellValue);
        }
    };

    const showMetaInfo = (invoiceRow: InvoiceRowDTO) => {
        return (
            invoiceRow?.BuyerProductId ||
            invoiceRow?.SellerProductId ||
            invoiceRow?.SerialNumber ||
            invoiceRow?.EAN ||
            invoiceRow?.TaricCode ||
            invoiceRow?.CustomerRef ||
            invoiceRow?.ItemReserve?.length > 0
        );
    };

    const getTooltipContentMeta = (row: InvoiceRowDTO) => {
        return (
            <div key={row?.Id} className="invoice-rows__discounts-tooltip-content">
                <table className="invoice-rows__discounts-tooltip-content-rows">
                    <tbody>
                        <tr>
                            <td>{`${t('views.invoice.partials.invoiceRows.SellerProductId')}: `}</td>
                            <td>{row?.SellerProductId}</td>
                        </tr>
                        <tr>
                            <td>{`${t('views.invoice.partials.invoiceRows.BuyerProductId')}: `}</td>
                            <td>{row?.BuyerProductId}</td>
                        </tr>
                        <tr>
                            <td>{`${t('views.invoice.partials.invoiceRows.SerialNumber')}: `}</td>
                            <td>{row?.SerialNumber}</td>
                        </tr>
                        <tr>
                            <td>{`${t('views.invoice.partials.invoiceRows.EAN')}: `}</td>
                            <td>{row?.EAN}</td>
                        </tr>
                        <tr>
                            <td>{`${t('views.invoice.partials.invoiceRows.CustomerRef')}: `}</td>
                            <td>{row?.CustomerRef}</td>
                        </tr>
                        <tr>
                            <td>{`${t('views.invoice.partials.invoiceRows.GroupId')}: `}</td>
                            <td>{row?.GroupId}</td>
                        </tr>
                        <tr>
                            <td>{`${t('views.invoice.partials.invoiceRows.TaricCode')}: `}</td>
                            <td>{row?.TaricCode}</td>
                        </tr>
                        {row?.ItemReserve.map((e) => (
                            <tr key={e?.InformationName || e?.ExtensionId}>
                                <td>{`${e?.InformationName || e?.ExtensionId}: `}</td>
                                <td>{e?.InformationContent}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>, row: InvoiceRowDTO) => {
        const { key } = e;
        // validate the row and trigger new row adding if row is valid
        /*if (key === 'Enter') {
            try {
                (target as HTMLInputElement).blur();
                await formRef.current.validateForm(formRef.current.state.values);
                setTimeout(() => {
                    if (isEmpty(formRef.current.state.errors)) {
                        debounceHandleSubmit(formRef.current.state.values, row.Id === 0);
                        setActiveEditRowId(0);
                    }
                }, 200);
            } catch (error) {
                console.error(error);
            }
        }*/

        if (key === 'Esc' || key === 'Escape') {
            if (row.Id === 0) {
                removeNewRow(row.InvoiceId);
            }
            setActiveEditRowId(null);
        }
    };

    const handleAddButtonSave = async (newRow?: boolean) => {
        if (newRow) {
            gotoLastPageAndAddNewRow(invoice?.Id);
        } else {
            try {
                await formRef.current.validateForm(formRef.current.state.values);
                setTimeout(() => {
                    if (isEmpty(formRef.current.state.errors)) {
                        debounceHandleSubmit(formRef.current.state.values, true);
                        setActiveEditRowId(0);
                    }
                }, 200);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const debounceHandleAddButtonSave = debounce(handleAddButtonSave, 250);

    const toggleDiscountType = (index: number, discounts: InvoiceRowDiscountDTO[], formik: FormikProps<InvoiceRowDTO>) => {
        const newDiscounts = cloneDeep(discounts);

        if (newDiscounts[index].Type === InvoiceRowDiscountType.DSC) {
            newDiscounts[index].Type = InvoiceRowDiscountType.CHR;
        } else {
            newDiscounts[index].Type = InvoiceRowDiscountType.DSC;
        }
        handleCellChange(newDiscounts, 'InvoiceRowDiscounts', formik);
    };

    const debounceImport = debounce(importFromInvoiceRows, 250);

    const handleFromFileItemClick = () => {
        fileInputRef.current.click();
    };

    const getCreateFromInvoiceRowsTooltipContent = () => {
        return (
            <div>
                <p>{t('component.invoiceRows.createTransactionRows.fromInvoiceRows.tooltip')}</p>
                <p className="action-wrap action-wrap--right">
                    <button className="btn btn--secondary btn--sm" onClick={() => tooltipInstance.hide()}>
                        {t('component.invoiceHeader.delete.modal.cancel')}
                    </button>
                    <button
                        className="btn btn--primary btn--sm"
                        onClick={() => {
                            debounceImport(invoice.Id, reloadInvoiceData, false);
                            tooltipInstance.hide();
                        }}
                    >
                        {t('component.invoiceHeader.delete.modal.proceed')}
                    </button>
                </p>
            </div>
        );
    };

    const getCreateFromFileTooltipContent = () => {
        return (
            <div>
                <p>{t('component.invoiceRows.createTransactionRows.fromFile.tooltip')}</p>
                <p className="action-wrap action-wrap--right">
                    <button className="btn btn--secondary btn--sm" onClick={() => tooltipInstance.hide()}>
                        {t('component.invoiceHeader.delete.modal.cancel')}
                    </button>
                    <button
                        className="btn btn--primary btn--sm"
                        onClick={() => {
                            handleFromFileItemClick();
                            tooltipInstance.hide();
                        }}
                    >
                        {t('component.invoiceHeader.delete.modal.proceed')}
                    </button>
                </p>
            </div>
        );
    };

    const getCreateFromAutoTransactionsTooltipContent = () => {
        return (
            <div>
                <p>{t('component.invoiceRows.createTransactionRows.fromAutoTransactions.tooltip')}</p>
                <p className="action-wrap action-wrap--right">
                    <button className="btn btn--secondary btn--sm" onClick={() => tooltipInstance.hide()}>
                        {t('views.global.No')}
                    </button>
                    <button
                        className="btn btn--primary btn--sm"
                        onClick={() => {
                            debounceImport(invoice.Id, reloadInvoiceData, true);
                            tooltipInstance.hide();
                        }}
                    >
                        {t('component.invoiceHeader.delete.modal.proceed')}
                    </button>
                </p>
            </div>
        );
    };

    const getAddButtonTooltip = (disabled: boolean) => {
        return disabled ? (
            t('component.invoiceRows.buttons.tooltip.finishEdit')
        ) : (
            <div>
                <p>{t('component.invoiceRows.editRow.tooltip.warning')}</p>
                <p className="action-wrap action-wrap--right">
                    <button className="btn btn--secondary btn--sm" onClick={() => tooltipInstance.hide()}>
                        {t('views.global.Cancel')}
                    </button>
                    <button
                        className="btn btn--primary btn--sm"
                        onClick={() => {
                            if (activeEditRowId === null) {
                                setActiveEditRowId(0);
                                debounceHandleAddButtonSave(true);
                            } else {
                                debounceHandleAddButtonSave();
                            }
                            tooltipInstance.hide();
                        }}
                    >
                        {t('component.invoiceHeader.delete.modal.proceed')}
                    </button>
                </p>
            </div>
        );
    };

    const getEditButtonTooltip = (rowId: number, disabled: boolean) => {
        return disabled ? (
            t('component.invoiceRows.buttons.tooltip.finishEdit')
        ) : (
            <div>
                <p>{t('component.invoiceRows.editRow.tooltip.warning')}</p>
                <p className="action-wrap action-wrap--right">
                    <button className="btn btn--secondary btn--sm" onClick={() => tooltipInstance.hide()}>
                        {t('views.global.Cancel')}
                    </button>
                    <button
                        className="btn btn--primary btn--sm"
                        onClick={() => {
                            setActiveEditRowId(rowId);
                            tooltipInstance.hide();
                        }}
                    >
                        {t('component.invoiceHeader.delete.modal.proceed')}
                    </button>
                </p>
            </div>
        );
    };

    const isEditButtonDisabled = (rowId: number) => {
        return (activeEditRowId !== null && activeEditRowId !== rowId) || !(isEmpty(bulkIds) && !isAllBulkSelected);
    };

    const getCreateTransactionRowsButtons = () => {
        const monetaryAmountCheckEnabled = isMonetaryAmountCheckEnabled(invoice.TotalAmountWithVat);
        const isCreateButtonsDisabled = !canSaveInvoice(false) || activeEditRowId !== null || areInvoiceRowsSumsNotMatching(invoice) || !monetaryAmountCheckEnabled || isReadOnly;
        const tooltipTriggerType = isCreateButtonsDisabled ? 'mouseenter' : 'click';
        const disabledButtonTooltipContent = !monetaryAmountCheckEnabled ? t('component.invoiceRows.createTransactionRows.buttons.tooltip.monetaryAmountCheck') : null;
        const tooltipContentFromInvoiceRowsButton = disabledButtonTooltipContent || (invoice?.InvoiceAccountingRowsTotal > 0 && getCreateFromInvoiceRowsTooltipContent());
        const tooltipContentFromAutoTransactionsButton = disabledButtonTooltipContent || (invoice?.InvoiceAccountingRowsTotal > 0 && getCreateFromAutoTransactionsTooltipContent());
        const tooltipContentFromFileButton = disabledButtonTooltipContent || (invoice?.InvoiceAccountingRowsTotal > 0 && getCreateFromFileTooltipContent());
        return (
            <div className="invoice-rows__transaction-rows-block">
                <span>{`${t('component.invoiceRows.createTransactionRows')}:`}</span>
                <span className="invoice-rows__transaction-rows-buttons">
                    <Tooltip
                        appendTo={document.getElementById(fullScreenElementId)}
                        interactive
                        delay={0}
                        content={tooltipContentFromInvoiceRowsButton}
                        trigger={tooltipTriggerType}
                        isEnabled
                        onShow={setTooltipInstance}
                        onHide={() => setTooltipInstance(null)}
                    >
                        <span>
                            <Button
                                className="invoice-rows__transaction-rows-button"
                                buttonType={ButtonType.ICON_TEXT}
                                icon={ICONS.DENSITY_RELAXED_24}
                                onClick={() => {
                                    invoice?.InvoiceAccountingRowsTotal > 0 ? noop : debounceImport(invoice.Id, reloadInvoiceData, false);
                                }}
                                iconPlacement={ButtonIconPlacement.LEFT}
                                dataId={createDataId(dataId, 'import', 'fromInvoiceRows')}
                                disabled={isCreateButtonsDisabled}
                            >
                                {t('component.transactionRows.import.fromInvoiceRows')}
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip
                        appendTo={document.getElementById(fullScreenElementId)}
                        interactive
                        delay={0}
                        content={tooltipContentFromAutoTransactionsButton}
                        trigger={tooltipTriggerType}
                        isEnabled
                        onShow={setTooltipInstance}
                        onHide={() => setTooltipInstance(null)}
                    >
                        <span>
                            <Button
                                className="invoice-rows__transaction-rows-button"
                                buttonType={ButtonType.ICON_TEXT}
                                icon={ICONS.FLASH}
                                onClick={() => {
                                    invoice?.InvoiceAccountingRowsTotal > 0 ? noop : debounceImport(invoice.Id, reloadInvoiceData, true);
                                }}
                                iconPlacement={ButtonIconPlacement.LEFT}
                                dataId={createDataId(dataId, 'import', 'fromAutoTransactions')}
                                disabled={isCreateButtonsDisabled}
                            >
                                {t('component.transactionRows.import.fromAutoTransactions')}
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip
                        appendTo={document.getElementById(fullScreenElementId)}
                        interactive
                        delay={0}
                        content={tooltipContentFromFileButton}
                        trigger={tooltipTriggerType}
                        isEnabled
                        onShow={setTooltipInstance}
                        onHide={() => setTooltipInstance(null)}
                    >
                        <span>
                            <Button
                                className="invoice-rows__transaction-rows-button"
                                buttonType={ButtonType.ICON_TEXT}
                                icon={ICONS.DOCUMENT_24}
                                onClick={() => {
                                    invoice?.InvoiceAccountingRowsTotal > 0 ? noop : handleFromFileItemClick();
                                }}
                                iconPlacement={ButtonIconPlacement.LEFT}
                                dataId={createDataId(dataId, 'import', 'file')}
                                disabled={isCreateButtonsDisabled || !isAuthorized(Role.CanImportXLS)}
                            >
                                {t('component.invoiceRows.createTransactionRows.importFromFile')}
                            </Button>
                        </span>
                    </Tooltip>
                </span>
                <input
                    ref={fileInputRef}
                    data-id={createDataId(dataId, 'importFromFileButton')}
                    onChange={handleFileChange}
                    type="file"
                    accept=".xlsx,.xls,.csv"
                    id="input"
                    style={{
                        display: 'none',
                    }}
                />
            </div>
        );
    };

    const handleBlurCellRecalculate = (cellValue: string, dataKey: string, formik: FormikProps<InvoiceRowDTO>) => {
        if (isInvoiceRowsCalculationActive) {
            const row = cloneDeep(formik.values);
            set(row, dataKey, cellValue);
            const rowValueCalculationFn = rowValueCalculationFns[dataKey];
            if (!rowValueCalculationFn) {
                return;
            }
            rowValueCalculationFn(row);
            formik.setValues(row);
        } else {
            formik.setFieldValue(dataKey, cellValue);
        }
    };

    return (
        <div id={fullScreenElementId}>
            <MainPage className="invoice-rows" type={MainPageType.WIDE}>
                <ContentBlock loading={invoiceRowsLoadable.loading}>
                    <ContentBlockHeader
                        className="invoice-rows__header"
                        hasBackButton={isFullscreen}
                        backButtonTooltipContent={t('component.invoiceRows.header.buttons.exitFullScreen.tooltip')}
                        fullScreenElementId={fullScreenElementId}
                        onBackClick={isFullscreen ? () => document.exitFullscreen() : null}
                        dataId={createDataId(dataId, 'header')}
                        type={ContentBlockHeaderType.TABLE_HEADER}
                        title={
                            <Typography variant="h2" element="span">
                                {t('component.invoiceRows.heading')} ({invoiceRowsLoadable.payload?.TotalCount || 0})
                            </Typography>
                        }
                    >
                        {!isAllBulkSelected && isEmpty(bulkIds) ? (
                            <>
                                {!isReadOnly && canSaveInvoice(false) && invoiceRowsLoadable?.payload?.Items?.length > 0 && (
                                    <Tooltip appendTo={document.getElementById(fullScreenElementId)} content={t('component.invoiceRows.header.buttons.automaticCalculations.tooltip')}>
                                        <Switch
                                            className="content-block__header-text-button"
                                            dataId={createDataId(dataId, 'calculation-switcher')}
                                            value={isInvoiceRowsCalculationActive}
                                            name={'CalculationSwitcher'}
                                            label={t('component.invoiceRows.header.buttons.AutomaticСalculations')}
                                            onChange={(val) => {
                                                setIsInvoiceRowsCalculationActive(val);
                                            }}
                                        />
                                    </Tooltip>
                                )}
                                <Tooltip
                                    appendTo={document.getElementById(fullScreenElementId)}
                                    content={isFullscreen ? t('component.invoiceRows.header.buttons.exitFullScreen.tooltip') : t('component.invoiceRows.header.buttons.fullScreen.tooltip')}
                                >
                                    <Button
                                        className="content-block__header-text-button"
                                        buttonType={ButtonType.ICON_TEXT}
                                        onClick={() => {
                                            toogleFullScreen();
                                        }}
                                        icon={isFullscreen ? ICONS.NORMAL_WIDTH_24 : ICONS.FULL_WIDTH_24}
                                        iconPlacement={ButtonIconPlacement.LEFT}
                                        dataId={createDataId(dataId, 'fullWidthToggler')}
                                    >
                                        {isFullscreen ? t('component.invoiceRows.header.buttons.normalWidth') : t('component.invoiceRows.header.buttons.fullWidth')}
                                    </Button>
                                </Tooltip>
                                {!isReadOnly && canSaveInvoice(false) && (
                                    <Tooltip appendTo={document.getElementById(fullScreenElementId)} content={t('component.invoiceRows.header.buttons.addRow.tooltip')} isEnabled={!activeEditRowId}>
                                        <div>
                                            <Tooltip
                                                appendTo={document.getElementById(fullScreenElementId)}
                                                delay={activeEditRowId ? 500 : 0}
                                                interactive={true}
                                                content={!!activeEditRowId || invoice?.InvoiceAccountingRowsTotal > 0 ? getAddButtonTooltip(!!activeEditRowId) : null}
                                                trigger={activeEditRowId ? 'mouseenter' : 'click'}
                                                isEnabled={!!activeEditRowId || invoice?.InvoiceAccountingRowsTotal > 0}
                                                onShow={setTooltipInstance}
                                                onHide={() => setTooltipInstance(null)}
                                            >
                                                <div>
                                                    <Button
                                                        className="content-block__header-text-button"
                                                        buttonType={ButtonType.ICON_TEXT}
                                                        onClick={
                                                            invoice?.InvoiceAccountingRowsTotal > 0 || !!activeEditRowId
                                                                ? noop
                                                                : () => {
                                                                      if (activeEditRowId === null) {
                                                                          setActiveEditRowId(0);
                                                                          debounceHandleAddButtonSave(true);
                                                                      } else {
                                                                          debounceHandleAddButtonSave();
                                                                      }
                                                                  }
                                                        }
                                                        icon={ICONS.PLUS_THICK_24}
                                                        iconPlacement={ButtonIconPlacement.LEFT}
                                                        disabled={!!activeEditRowId}
                                                        dataId={createDataId(dataId, 'newInvoiceRowButton')}
                                                    >
                                                        {t('views.global.Add')}
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </Tooltip>
                                )}
                            </>
                        ) : (
                            <span className="invoice-rows__bulk-actions">
                                {`${t('component.invoiceRows.bulkActions.selectedItems', { count: isAllBulkSelected ? invoiceRowsLoadable?.payload?.TotalCount - countBulkIds() : countBulkIds() })}:`}
                                <Button
                                    iconPlacement={ButtonIconPlacement.LEFT}
                                    onClick={() => debounceHandleBulkDelete()}
                                    dataId={createDataId(dataId, 'Button', 'Bulk', 'Delete')}
                                    icon={ICONS.DELETE_24}
                                    className="icon-button invoice-rows__bulk-actions-delete"
                                    buttonType={ButtonType.ICON_TEXT}
                                >
                                    {t('views.global.Delete')}
                                </Button>
                            </span>
                        )}
                    </ContentBlockHeader>
                    <div className="invoice-rows__table">
                        {invoiceRowsLoadable?.payload?.Items?.length > 0 ? (
                            <ContentBlockBody className="invoice-rows__table-content" loading={false} dataId={createDataId(dataId, 'contentBlockBody')}>
                                <Table>
                                    <DataTableHeader data-id={createDataId(dataId, 'Table', 'Header')}>
                                        <TableRow>
                                            <TableHead style={{ width: '5%' }}>
                                                <>
                                                    <Checkbox
                                                        name={createDataId(dataId, 'toggleCheckbox')}
                                                        partiallyChecked={
                                                            bulkIds[invoiceRowsLoadable.request?.PagingOptions?.Page]?.length > 0 &&
                                                            bulkIds[invoiceRowsLoadable.request?.PagingOptions?.Page]?.length < invoiceRowsLoadable.payload?.Items?.length &&
                                                            (!isAllBulkSelected || (isAllBulkSelected && bulkIds[invoiceRowsLoadable.request?.PagingOptions?.Page]?.length > 0))
                                                        }
                                                        value={
                                                            (!isAllBulkSelected && bulkIds[invoiceRowsLoadable.request?.PagingOptions?.Page]?.length > 0) ||
                                                            (isAllBulkSelected && bulkIds[invoiceRowsLoadable.request?.PagingOptions?.Page]?.length !== invoiceRowsLoadable?.payload?.Items?.length)
                                                        }
                                                        onChange={() => {
                                                            if (isAllBulkSelected) {
                                                                setIsAllBulkSelected(false);
                                                                handleBulkChange(InvoiceRowsBulkActions.None);
                                                                const newObject = cloneDeep(bulkIds);
                                                                if (isEmpty(bulkIds[invoiceRowsLoadable.request?.PagingOptions?.Page])) {
                                                                    newObject[invoiceRowsLoadable.request?.PagingOptions?.Page] = invoiceRowsLoadable?.payload?.Items.map((e) => e.Id);
                                                                    setBulkIds(newObject);
                                                                } else {
                                                                    delete newObject[invoiceRowsLoadable?.request?.PagingOptions?.Page];
                                                                    setBulkIds(newObject);
                                                                }
                                                            } else {
                                                                handleBulkChange(
                                                                    bulkIds[invoiceRowsLoadable.request?.PagingOptions?.Page]?.length === invoiceRowsLoadable.payload?.Items?.length
                                                                        ? InvoiceRowsBulkActions.None
                                                                        : InvoiceRowsBulkActions.ThisPage,
                                                                );
                                                            }
                                                        }}
                                                        disabled={isBulkDisabled}
                                                    />
                                                    <DropdownMenu
                                                        dataId={createDataId(dataId, 'Bulk', 'toggleAllMenu')}
                                                        appendTo={document.getElementById(fullScreenElementId)}
                                                        disabled={isBulkDisabled}
                                                        items={Object.keys(InvoiceRowsBulkActions)
                                                            .filter((key) => isNaN(Number(InvoiceRowsBulkActions[key])))
                                                            .map((e) => (
                                                                <DropdownMenuItem
                                                                    key={e}
                                                                    onClick={() => {
                                                                        handleBulkChange(Number(e));
                                                                    }}
                                                                    dataId={createDataId(dataId, 'bulk', 'dropDownItem')}
                                                                >
                                                                    <span>{t(invoiceRowsBulkActionTranslations[e])}</span>
                                                                </DropdownMenuItem>
                                                            ))}
                                                    >
                                                        <Button buttonType={ButtonType.ICON} icon={ICONS.ARROW_DOWN_SMALL} />
                                                    </DropdownMenu>
                                                </>
                                            </TableHead>
                                            <TableHead style={{ width: '5%' }} columnName="OrderNo" dataId={createDataId(dataId, 'TableHead', 'OrderNumber')}>
                                                {'#'}
                                            </TableHead>
                                            <TableHead style={{ width: '25%' }} columnName="Description" dataId={createDataId(dataId, 'TableHead', 'Description')}>
                                                {t('component.invoiceRows.description')}
                                            </TableHead>
                                            <TableHead style={{ width: '6%' }} columnName="Quantity" dataId={createDataId(dataId, 'TableHead', 'Quantity')}>
                                                {t('component.invoiceRows.qty')}
                                            </TableHead>
                                            <TableHead style={{ width: '6%' }} columnName="Unit" dataId={createDataId(dataId, 'TableHead', 'Unit')}>
                                                {t('component.invoiceRows.unit')}
                                            </TableHead>
                                            <TableHead style={{ width: '10%' }} columnName="Price" dataId={createDataId(dataId, 'TableHead', 'Price')}>
                                                {t('component.invoiceRows.price')}
                                            </TableHead>
                                            <TableHead style={{ width: '10%' }} columnName="NET" dataId={createDataId(dataId, 'TableHead', 'SumWithoutVat')}>
                                                {t('component.invoiceRows.net')}
                                            </TableHead>
                                            <TableHead style={{ width: '6%' }} columnName="VATPercent" dataId={createDataId(dataId, 'TableHead', 'VatRate')}>
                                                {`${t('component.invoiceRows.VAT')} %`}
                                            </TableHead>
                                            <TableHead style={{ width: '10%' }} columnName="VAT" dataId={createDataId(dataId, 'TableHead', 'VAT')}>
                                                {t('component.invoiceRows.VAT')}
                                            </TableHead>
                                            <TableHead style={{ width: '10%' }} columnName="Total" dataId={createDataId(dataId, 'TableHead', 'Total')}>
                                                {t('component.invoiceRows.total')}
                                            </TableHead>
                                            <TableHead columnName="EditButton" dataId={createDataId(dataId, 'TableHead', 'EditButton')}>
                                                {null}
                                            </TableHead>
                                            <TableHead columnName="collapseAll" style={{ width: '2%' }} dataId={createDataId(dataId, 'collapseAll')}>
                                                <Tooltip
                                                    appendTo={document.getElementById(fullScreenElementId)}
                                                    content={
                                                        activeEditRowId !== null ? t('component.invoiceRows.buttons.tooltip.finishEdit') : t('component.invoiceRows.table.buttons.collapseAll.tooltip')
                                                    }
                                                    isEnabled={true}
                                                >
                                                    <div>
                                                        <Button
                                                            iconPlacement={ButtonIconPlacement.LEFT}
                                                            onClick={toggleCollapseAll}
                                                            icon={ICONS.EXPAND_ALL_24}
                                                            className={classNames('invoice-rows__table-head-icon', {
                                                                'invoice-rows__table-head-icon--collapsed': isAllRowsCollapsed,
                                                            })}
                                                            buttonType={ButtonType.ICON}
                                                            disabled={activeEditRowId !== null}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </TableHead>
                                        </TableRow>
                                    </DataTableHeader>
                                    <TableBody>
                                        {invoiceRowsLoadable &&
                                            invoiceRowsLoadable.payload &&
                                            invoiceRowsLoadable.payload.Items.map((row, index) =>
                                                activeEditRowId !== row.Id ? (
                                                    <TableRow
                                                        collapsable={true}
                                                        toggleCollapse={toggleCollapse}
                                                        isCollapsed={collapsedRows[row.Id] || isAllCollapsed}
                                                        id={row.Id}
                                                        collapseContent={
                                                            <CollapseContent
                                                                activeEditRowId={activeEditRowId}
                                                                key={row.Id || index}
                                                                canSaveInvoice={!isReadOnly && canSaveInvoice(false)}
                                                                invoiceRow={row}
                                                                saveProductId={debounceSaveProductId}
                                                            />
                                                        }
                                                        key={row.Id}
                                                        data-id={createDataId(dataId, 'InvoiceRow.row', index)}
                                                        fullScreenElementId={fullScreenElementId}
                                                        collapseButtonTooltipContent={t('component.invoiceRows.table.buttons.collapse.tooltip')}
                                                    >
                                                        <>
                                                            <TableCell dataId={createDataId(dataId, index, 'Cell', 'CheckBox')}>
                                                                <Checkbox
                                                                    data-id={createDataId(dataId, index, 'Button', 'CheckBox')}
                                                                    name={`bulk.${index}.checkbox`}
                                                                    value={
                                                                        (bulkIds[invoiceRowsLoadable.request?.PagingOptions?.Page]?.includes(row.Id) && !isAllBulkSelected) ||
                                                                        (isAllBulkSelected && !bulkIds[invoiceRowsLoadable.request?.PagingOptions?.Page]?.includes(row.Id))
                                                                    }
                                                                    onChange={() => {
                                                                        const newObject = cloneDeep(bulkIds);
                                                                        if (bulkIds[invoiceRowsLoadable.request?.PagingOptions?.Page]?.includes(row.Id)) {
                                                                            newObject[invoiceRowsLoadable.request?.PagingOptions?.Page] = newObject[
                                                                                invoiceRowsLoadable.request?.PagingOptions?.Page
                                                                            ]?.filter((e: number) => e !== row.Id);
                                                                            if (isEmpty(newObject[invoiceRowsLoadable?.request?.PagingOptions?.Page])) {
                                                                                delete newObject[invoiceRowsLoadable?.request?.PagingOptions?.Page];
                                                                            }
                                                                            setBulkIds(newObject);
                                                                        } else {
                                                                            newObject[invoiceRowsLoadable.request?.PagingOptions?.Page] = isEmpty(
                                                                                newObject[invoiceRowsLoadable.request?.PagingOptions?.Page],
                                                                            )
                                                                                ? [row.Id]
                                                                                : [...newObject[invoiceRowsLoadable.request?.PagingOptions?.Page], row.Id];
                                                                            setBulkIds(newObject);
                                                                        }
                                                                    }}
                                                                    disabled={isBulkDisabled}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId(dataId, index, 'Cell', 'OrderNumber')}
                                                                hideOverflow={true}
                                                                appendTo={document.getElementById(fullScreenElementId)}
                                                                responsiveCellType={ResponsiveCellType.LABEL}
                                                            >
                                                                {row?.OrderNo || '\u00A0'}
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId(dataId, index, 'Cell', 'Description')}
                                                                appendTo={document.getElementById(fullScreenElementId)}
                                                                responsiveCellType={ResponsiveCellType.LABEL}
                                                                className="invoice-rows__table-description-cell"
                                                                iconBeforeCell={
                                                                    showMetaInfo(row) ? (
                                                                        <Tooltip
                                                                            appendTo={document.getElementById(fullScreenElementId)}
                                                                            key={row.Id}
                                                                            interactive={true}
                                                                            content={row.Id !== 0 ? getTooltipContentMeta(row) : null}
                                                                            isEnabled={true}
                                                                        >
                                                                            <Button
                                                                                iconPlacement={ButtonIconPlacement.LEFT}
                                                                                onClick={() => noop}
                                                                                dataId={createDataId(dataId, index, 'Button', 'MetaInfo')}
                                                                                icon={ICONS.INFO_24}
                                                                                className="icon-button invoice-rows__table-body-info"
                                                                                buttonType={ButtonType.ICON}
                                                                            />
                                                                        </Tooltip>
                                                                    ) : null
                                                                }
                                                            >
                                                                {row?.Description || '\u00A0'}
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId(dataId, index, 'Cell', 'Quantity')}
                                                                hideOverflow={true}
                                                                appendTo={document.getElementById(fullScreenElementId)}
                                                                responsiveCellType={ResponsiveCellType.LABEL}
                                                            >
                                                                {formatMoneyToShowSeparators(trimAfterComma(row?.ItemDetailInfo[0]?.ItemAmount, getQtyPrecision()), true)}
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId(dataId, index, 'Cell', 'Unit')}
                                                                hideOverflow={true}
                                                                appendTo={document.getElementById(fullScreenElementId)}
                                                                responsiveCellType={ResponsiveCellType.LABEL}
                                                            >
                                                                {row?.ItemDetailInfo[0]?.ItemUnit}
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId(dataId, index, 'Cell', 'Price')}
                                                                hideOverflow={true}
                                                                appendTo={document.getElementById(fullScreenElementId)}
                                                                responsiveCellType={ResponsiveCellType.LABEL}
                                                            >
                                                                {formatMoneyToShowSeparators(trimAfterComma(row?.ItemDetailInfo[0]?.ItemPrice, getPricePrecision(), 2), true) || '0.00'}
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId(dataId, index, 'Cell', 'SumWithoutVat')}
                                                                iconBeforeCell={
                                                                    row?.InvoiceRowDiscounts?.length > 0 && (
                                                                        <Tooltip
                                                                            appendTo={document.getElementById(fullScreenElementId)}
                                                                            key={row.Id}
                                                                            content={getTooltipDiscountContent(row)}
                                                                            isEnabled={true}
                                                                        >
                                                                            <Button
                                                                                className="invoice-rows__table-discount-button"
                                                                                buttonType={ButtonType.ICON}
                                                                                onClick={() => noop}
                                                                                iconPlacement={ButtonIconPlacement.LEFT}
                                                                                dataId={createDataId(dataId, index, 'discount-tooltip')}
                                                                                icon={
                                                                                    checkDiscountSumType(row.InvoiceRowDiscounts) === InvoiceRowDiscountType.CHR
                                                                                        ? ICONS.EXTRA_CHARGE_ARROW_24
                                                                                        : ICONS.DISCOUNT_ARROW_24
                                                                                }
                                                                            />
                                                                        </Tooltip>
                                                                    )
                                                                }
                                                                hideOverflow={true}
                                                                appendTo={document.getElementById(fullScreenElementId)}
                                                                responsiveCellType={ResponsiveCellType.LABEL}
                                                            >
                                                                {formatMoneyToShowSeparators(trimAfterComma(row?.SumWithoutVat, 4, 2), true)}
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId(dataId, index, 'Cell', 'VatRate')}
                                                                hideOverflow={true}
                                                                appendTo={document.getElementById(fullScreenElementId)}
                                                                responsiveCellType={ResponsiveCellType.LABEL}
                                                            >
                                                                {`${trimAfterComma(String(row?.VatRate), 2)}%`}
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId(dataId, index, 'Cell', 'VAT')}
                                                                hideOverflow={true}
                                                                appendTo={document.getElementById(fullScreenElementId)}
                                                                responsiveCellType={ResponsiveCellType.LABEL}
                                                            >
                                                                {formatMoneyToShowSeparators(trimAfterComma(row?.VAT, 4, 2), true)}
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId(dataId, index, 'Cell', 'Total')}
                                                                hideOverflow={true}
                                                                appendTo={document.getElementById(fullScreenElementId)}
                                                                responsiveCellType={ResponsiveCellType.LABEL}
                                                            >
                                                                {formatMoneyToShowSeparators(trimAfterComma(row?.Total, 4, 2), true)}
                                                            </TableCell>
                                                            <TableCell
                                                                className={'invoice-rows__table-edit-buttons'}
                                                                dataId={createDataId(dataId, index, 'Cell', 'EditButton')}
                                                                nowrap={true}
                                                                responsiveCellType={ResponsiveCellType.LABEL}
                                                            >
                                                                {!isReadOnly && canSaveInvoice(false) && (
                                                                    <>
                                                                        <Tooltip
                                                                            appendTo={document.getElementById(fullScreenElementId)}
                                                                            content={t('component.invoiceRows.table.buttons.delete.tooltip')}
                                                                            isEnabled={!isEditButtonDisabled(row?.Id)}
                                                                        >
                                                                            <span className="invoice-rows__tooltip-block">
                                                                                <Tooltip
                                                                                    appendTo={document.getElementById(fullScreenElementId)}
                                                                                    key={row.Id + '-delete-tooltip'}
                                                                                    interactive={true}
                                                                                    delay={isEditButtonDisabled(row?.Id) ? 500 : 0}
                                                                                    content={getTooltipContent(row, isEditButtonDisabled(row?.Id))}
                                                                                    trigger={isEditButtonDisabled(row?.Id) ? 'mouseenter' : 'click'}
                                                                                    isEnabled={true}
                                                                                    onShow={setTooltipInstance}
                                                                                    onHide={() => setTooltipInstance(null)}
                                                                                >
                                                                                    <span className="invoice-rows__tooltip-block">
                                                                                        <Button
                                                                                            iconPlacement={ButtonIconPlacement.LEFT}
                                                                                            onClick={() => noop}
                                                                                            dataId={createDataId(dataId, index, 'Button', 'DeleteRow')}
                                                                                            icon={ICONS.DELETE_24}
                                                                                            className="icon-button invoice-rows__table-body-edit"
                                                                                            buttonType={ButtonType.ICON}
                                                                                            disabled={isEditButtonDisabled(row?.Id)}
                                                                                        />
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </span>
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            appendTo={document.getElementById(fullScreenElementId)}
                                                                            content={t('component.invoiceRows.table.buttons.edit.tooltip')}
                                                                            isEnabled={!isEditButtonDisabled(row?.Id)}
                                                                        >
                                                                            <span className="invoice-rows__tooltip-block">
                                                                                <Tooltip
                                                                                    appendTo={document.getElementById(fullScreenElementId)}
                                                                                    key={row.Id + '-edit-tooltip'}
                                                                                    interactive={true}
                                                                                    delay={isEditButtonDisabled(row?.Id) ? 500 : 0}
                                                                                    content={
                                                                                        isEditButtonDisabled(row?.Id) || invoice?.InvoiceAccountingRowsTotal > 0
                                                                                            ? getEditButtonTooltip(row.Id, isEditButtonDisabled(row?.Id))
                                                                                            : null
                                                                                    }
                                                                                    trigger={isEditButtonDisabled(row?.Id) ? 'mouseenter' : 'click'}
                                                                                    isEnabled={true}
                                                                                    onShow={setTooltipInstance}
                                                                                    onHide={() => setTooltipInstance(null)}
                                                                                >
                                                                                    <span className="invoice-rows__tooltip-block">
                                                                                        <Button
                                                                                            iconPlacement={ButtonIconPlacement.LEFT}
                                                                                            onClick={
                                                                                                isEditButtonDisabled(row?.Id) || invoice?.InvoiceAccountingRowsTotal > 0
                                                                                                    ? noop
                                                                                                    : () => setActiveEditRowId(row.Id)
                                                                                            }
                                                                                            dataId={createDataId(dataId, index, 'Button', 'EditRow')}
                                                                                            icon={ICONS.EDIT_24}
                                                                                            className="icon-button invoice-rows__table-body-edit"
                                                                                            buttonType={ButtonType.ICON}
                                                                                            disabled={isEditButtonDisabled(row?.Id)}
                                                                                        />
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </span>
                                                                        </Tooltip>
                                                                    </>
                                                                )}
                                                            </TableCell>
                                                        </>
                                                    </TableRow>
                                                ) : (
                                                    <Formik
                                                        key={row.Id}
                                                        ref={formRef}
                                                        initialValues={getInitialFields(row)}
                                                        validationSchema={validationSchema}
                                                        enableReinitialize={true}
                                                        isInitialValid={true}
                                                        validateOnChange={true}
                                                        validateOnBlur={true}
                                                        onSubmit={(values) => debounceHandleSubmit(values)}
                                                    >
                                                        {(formik: FormikProps<InvoiceRowDTO>) => (
                                                            <>
                                                                <TableRow
                                                                    collapsable={true}
                                                                    toggleCollapse={toggleCollapse}
                                                                    isCollapsed={collapsedRows[row.Id] || isAllCollapsed}
                                                                    id={row.Id}
                                                                    key={row.Id}
                                                                    data-id={createDataId(dataId, 'InvoiceRow.row', index)}
                                                                    isEditting={true}
                                                                    fullScreenElementId={fullScreenElementId}
                                                                    collapseButtonTooltipContent={t('component.invoiceRows.buttons.tooltip.finishEdit')}
                                                                >
                                                                    <TableCell dataId={createDataId(dataId, index, 'Cell', 'CheckBox')}>
                                                                        <Checkbox data-id={createDataId(dataId, index, 'Button', 'CheckBox')} name={`bulk.${index}.checkbox`} disabled={true} />
                                                                    </TableCell>
                                                                    <TableCell dataId={createDataId(dataId, index, 'Cell', 'OrderNumber')} className="cell-order-number">
                                                                        <FormikField
                                                                            showTooltipOnOverflow={true}
                                                                            maxLength={4}
                                                                            component={TextInputField}
                                                                            dataId={createDataId(dataId, index, 'Input', 'OrderNumber')}
                                                                            name="OrderNo"
                                                                            onlyChangeOnBlur={true}
                                                                            type={TextInputType.BORDERED}
                                                                            hideError={true}
                                                                            validCharacters={INTEGER_REGEXP}
                                                                            autosize
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell dataId={createDataId(dataId, index, 'Cell', 'Description')} className="data-table__cell__text-area">
                                                                        <FormikField
                                                                            autofocus={true}
                                                                            selectOnAutofocus={true}
                                                                            showTooltipOnOverflow={true}
                                                                            maxLength={500}
                                                                            component={TextInputField}
                                                                            dataId={createDataId(dataId, index, 'Input', 'Description')}
                                                                            name="Description"
                                                                            placeholder={t('component.invoiceRows.description.placeholder')?.toLowerCase()}
                                                                            textarea={true}
                                                                            onlyChangeOnBlur={true}
                                                                            type={TextInputType.BORDERED}
                                                                            hideError={true}
                                                                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                handleKeyDown(e, row);
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell dataId={createDataId(dataId, index, 'Cell', 'Quantity')}>
                                                                        <FormikField
                                                                            component={TextInputField}
                                                                            showTooltipOnOverflow={true}
                                                                            dataId={createDataId(dataId, index, 'Input', 'Quantity')}
                                                                            name="ItemDetailInfo[0].ItemAmount"
                                                                            onlyChangeOnBlur={true}
                                                                            compareOnBlur={true}
                                                                            type={TextInputType.BORDERED}
                                                                            replaceComma={true}
                                                                            validCharacters={getInputRegExp(getQtyPrecision())}
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                handleCellChange(e.target.value, 'ItemDetailInfo[0].ItemAmount', formik);
                                                                            }}
                                                                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                handleKeyDown(e, row);
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell dataId={createDataId(dataId, index, 'Cell', 'Unit')}>
                                                                        <FormikField
                                                                            component={TextInputField}
                                                                            showTooltipOnOverflow={true}
                                                                            dataId={createDataId(dataId, index, 'Input', 'Unit')}
                                                                            name="ItemDetailInfo[0].ItemUnit"
                                                                            onlyChangeOnBlur={true}
                                                                            maxLength={50}
                                                                            type={TextInputType.BORDERED}
                                                                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                handleKeyDown(e, row);
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell dataId={createDataId(dataId, index, 'Cell', 'Price')}>
                                                                        <FormikField
                                                                            component={TextInputField}
                                                                            showTooltipOnOverflow={true}
                                                                            dataId={createDataId(dataId, index, 'Input', 'Price')}
                                                                            name="ItemDetailInfo[0].ItemPrice"
                                                                            onlyChangeOnBlur={true}
                                                                            compareOnBlur={true}
                                                                            type={TextInputType.BORDERED}
                                                                            replaceComma={true}
                                                                            validCharacters={getInputRegExp(getPricePrecision())}
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                handleCellChange(e.target.value, 'ItemDetailInfo[0].ItemPrice', formik);
                                                                            }}
                                                                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                handleKeyDown(e, row);
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell dataId={createDataId(dataId, index, 'Cell', 'SumWithoutVat')}>
                                                                        <FormikField
                                                                            component={MonetaryPrecisionScalesField}
                                                                            dataId={createDataId(dataId, index, 'Input', 'SumWithoutVat')}
                                                                            name={formik.values?.InvoiceRowDiscounts?.length > 0 ? 'ItemSum' : 'SumWithoutVat'}
                                                                            type={TextInputType.BORDERED}
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                handleCellChange(
                                                                                    e.target.value,
                                                                                    formik.values?.InvoiceRowDiscounts?.length > 0 ? 'ItemSum' : 'SumWithoutVat',
                                                                                    formik,
                                                                                    true,
                                                                                );
                                                                            }}
                                                                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                handleKeyDown(e, row);
                                                                            }}
                                                                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                                                handleBlurCellRecalculate(
                                                                                    e.target.value,
                                                                                    formik.values?.InvoiceRowDiscounts?.length > 0 ? 'ItemSum' : 'SumWithoutVat',
                                                                                    formik,
                                                                                );
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    {formik.values?.InvoiceRowDiscounts?.length < 1 ? (
                                                                        <>
                                                                            <TableCell dataId={createDataId(dataId, index, 'Cell', 'VatRate')}>
                                                                                <FormikField
                                                                                    component={TextInputField}
                                                                                    showTooltipOnOverflow={true}
                                                                                    dataId={createDataId(dataId, index, 'Input', 'VatRate')}
                                                                                    name="VatRate"
                                                                                    onlyChangeOnBlur={true}
                                                                                    compareOnBlur={true}
                                                                                    showPercent={true}
                                                                                    type={TextInputType.BORDERED}
                                                                                    hideError={true}
                                                                                    validCharacters={DECIMAL_ROWS_VAT_REGEXP}
                                                                                    replaceComma={true}
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                        handleCellChange(e.target.value, 'VatRate', formik);
                                                                                    }}
                                                                                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                        handleKeyDown(e, row);
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell dataId={createDataId(dataId, index, 'Cell', 'VAT')}>
                                                                                <FormikField
                                                                                    component={MonetaryPrecisionScalesField}
                                                                                    dataId={createDataId(dataId, index, 'Input', 'VAT')}
                                                                                    name="VAT"
                                                                                    type={TextInputType.BORDERED}
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                        handleCellChange(e.target.value, 'VAT', formik, true);
                                                                                    }}
                                                                                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                        handleKeyDown(e, row);
                                                                                    }}
                                                                                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                                                        handleBlurCellRecalculate(e.target.value, 'VAT', formik);
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell dataId={createDataId(dataId, index, 'Cell', 'Total')}>
                                                                                <FormikField
                                                                                    component={MonetaryPrecisionScalesField}
                                                                                    showTooltipOnOverflow={true}
                                                                                    dataId={createDataId(dataId, index, 'Input', 'Total')}
                                                                                    name="Total"
                                                                                    type={TextInputType.BORDERED}
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                        handleCellChange(e.target.value, 'Total', formik, true);
                                                                                    }}
                                                                                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                        handleKeyDown(e, row);
                                                                                    }}
                                                                                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                                                        handleBlurCellRecalculate(e.target.value, 'Total', formik);
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <TableCell dataId={createDataId(dataId, index, 'Cell', 'VatRate')}>{null}</TableCell>
                                                                            <TableCell dataId={createDataId(dataId, index, 'Cell', 'VAT')}>{null}</TableCell>
                                                                            <TableCell dataId={createDataId(dataId, index, 'Cell', 'Total')}>{null}</TableCell>
                                                                        </>
                                                                    )}
                                                                    <TableCell dataId={createDataId(dataId, index, 'Cell', 'EditButton')} responsiveCellType={ResponsiveCellType.ACTIONS} nowrap={true}>
                                                                        <>
                                                                            <Tooltip
                                                                                appendTo={document.getElementById(fullScreenElementId)}
                                                                                content={t('component.invoiceRows.table.buttons.discount.tooltip')}
                                                                                isEnabled={!isEditButtonDisabled(row?.Id)}
                                                                            >
                                                                                <Button
                                                                                    iconPlacement={ButtonIconPlacement.LEFT}
                                                                                    onClick={() => {
                                                                                        if (formik.values.InvoiceRowDiscounts?.length < 1) {
                                                                                            handleCellChange(
                                                                                                [...formik.values.InvoiceRowDiscounts, newDiscountObject(formik.values, formik.values.SumWithoutVat)],
                                                                                                'InvoiceRowDiscounts',
                                                                                                formik,
                                                                                            );
                                                                                            formik.setFieldValue('ItemSum', formik.values.SumWithoutVat);
                                                                                        } else {
                                                                                            handleCellChange(
                                                                                                [...formik.values.InvoiceRowDiscounts, newDiscountObject(formik.values, formik.values.ItemSum)],
                                                                                                'InvoiceRowDiscounts',
                                                                                                formik,
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                    dataId={createDataId(dataId, index, 'Button', 'AddDiscount')}
                                                                                    icon={ICONS.DISCOUNT_24}
                                                                                    className="icon-button invoice-rows__table-body-save-button"
                                                                                    buttonType={ButtonType.ICON}
                                                                                />
                                                                            </Tooltip>
                                                                            <Tooltip
                                                                                appendTo={document.getElementById(fullScreenElementId)}
                                                                                content={t('component.invoiceRows.table.buttons.save.tooltip')}
                                                                                isEnabled={!isEditButtonDisabled(row?.Id)}
                                                                            >
                                                                                <Button
                                                                                    iconPlacement={ButtonIconPlacement.LEFT}
                                                                                    onClick={() => formik.submitForm()}
                                                                                    dataId={createDataId(dataId, index, 'Button', 'SaveRow')}
                                                                                    icon={ICONS.SAVE_24}
                                                                                    className="icon-button invoice-rows__table-body-save-button"
                                                                                    buttonType={ButtonType.ICON}
                                                                                />
                                                                            </Tooltip>
                                                                            <Tooltip
                                                                                appendTo={document.getElementById(fullScreenElementId)}
                                                                                content={t('component.invoiceRows.table.buttons.discard.tooltip')}
                                                                                isEnabled={!isEditButtonDisabled(row?.Id)}
                                                                            >
                                                                                <Button
                                                                                    iconPlacement={ButtonIconPlacement.LEFT}
                                                                                    onClick={() => {
                                                                                        if (row.Id === 0) {
                                                                                            removeNewRow(row.InvoiceId);
                                                                                        }
                                                                                        setActiveEditRowId(null);
                                                                                    }}
                                                                                    dataId={createDataId(dataId, index, 'Button', 'Discard')}
                                                                                    icon={ICONS.CANCEL_CHANGES_24}
                                                                                    className="icon-button invoice-rows__table-body-discard-button"
                                                                                    buttonType={ButtonType.ICON}
                                                                                />
                                                                            </Tooltip>
                                                                        </>
                                                                    </TableCell>
                                                                </TableRow>
                                                                {formik.values?.InvoiceRowDiscounts.map((discount, i) => (
                                                                    <TableRow key={i} data-id={createDataId(dataId, i, 'DiscountRow')} isEditting={true}>
                                                                        <TableCell
                                                                            colSpan={5}
                                                                            dataId={createDataId(dataId, i, 'Cell', 'InvoiceRowDiscounts', 'Description')}
                                                                            responsiveCellType={ResponsiveCellType.LABEL}
                                                                            nowrap={true}
                                                                        >
                                                                            <>
                                                                                <div
                                                                                    onClick={() => {
                                                                                        toggleDiscountType(i, formik.values?.InvoiceRowDiscounts, formik);
                                                                                    }}
                                                                                    className={'invoice-rows__table-body-desc'}
                                                                                >
                                                                                    <StampLabel type={getDiscountLabelType(discount?.Type)} text={t(getDiscountLabelTranslateKey(discount?.Type))} />
                                                                                </div>
                                                                                <FormikField
                                                                                    component={TextInputField}
                                                                                    showTooltipOnOverflow={true}
                                                                                    placeholder={t('component.invoiceRows.description.placeholder').toLowerCase()}
                                                                                    dataId={createDataId(dataId, i, 'Input', 'InvoiceRowDiscounts', 'Description')}
                                                                                    name={`InvoiceRowDiscounts[${i}].Description`}
                                                                                    onlyChangeOnBlur={true}
                                                                                    maxLength={100}
                                                                                    type={TextInputType.BORDERED}
                                                                                    hideError={true}
                                                                                    autofocus={!discount?.Description}
                                                                                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                        handleKeyDown(e, row);
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        </TableCell>
                                                                        <TableCell dataId={createDataId(dataId, i, 'Cell', 'InvoiceRowDiscounts', 'Rate')}>
                                                                            <FormikField
                                                                                component={TextInputField}
                                                                                showTooltipOnOverflow={true}
                                                                                dataId={createDataId(dataId, i, 'Input', 'InvoiceRowDiscounts', 'Rate')}
                                                                                name={`InvoiceRowDiscounts[${i}].Rate`}
                                                                                replaceComma={true}
                                                                                replaceMinus={true}
                                                                                validCharacters={DECIMAL_ROWS_VAT_REGEXP}
                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                    const newDiscounts = cloneDeep(formik.values?.InvoiceRowDiscounts);
                                                                                    if (isInvoiceRowsCalculationActive) {
                                                                                        newDiscounts[i].Amount = (Number(formik.values?.ItemSum) * Number(e.target.value)) / 100;
                                                                                    }
                                                                                    newDiscounts[i].Rate = Number(e.target.value);
                                                                                    handleCellChange(newDiscounts, `InvoiceRowDiscounts`, formik);
                                                                                }}
                                                                                onlyChangeOnBlur={true}
                                                                                compareOnBlur={true}
                                                                                type={TextInputType.BORDERED}
                                                                                hideError={true}
                                                                                showPercent={true}
                                                                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                    handleKeyDown(e, row);
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell dataId={createDataId(dataId, i, 'Cell', 'InvoiceRowDiscounts', 'Amount')}>
                                                                            <FormikField
                                                                                component={TextInputField}
                                                                                showTooltipOnOverflow={true}
                                                                                dataId={createDataId(dataId, i, 'Input', 'InvoiceRowDiscounts', 'Amount')}
                                                                                name={`InvoiceRowDiscounts[${i}].Amount`}
                                                                                validCharacters={DECIMAL_ROWS_4_REGEXP}
                                                                                replaceComma={true}
                                                                                replaceMinus={true}
                                                                                iconBefore={getDiscountBeforeIcon(Number(formik.values.ItemSum), formik.values?.InvoiceRowDiscounts[i])}
                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                    const newDiscounts = cloneDeep(formik.values?.InvoiceRowDiscounts);
                                                                                    newDiscounts[i].Amount = Number(e.target.value);
                                                                                    if (isInvoiceRowsCalculationActive) {
                                                                                        newDiscounts[i].Rate = null;
                                                                                    }
                                                                                    handleCellChange(newDiscounts, `InvoiceRowDiscounts`, formik);
                                                                                }}
                                                                                onlyChangeOnBlur={true}
                                                                                compareOnBlur={true}
                                                                                type={TextInputType.BORDERED}
                                                                                hideError={true}
                                                                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                    handleKeyDown(e, row);
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell dataId={createDataId(dataId, i, 'Cell', 'InvoiceRowDiscounts', 'Empty')}>{null}</TableCell>
                                                                        <TableCell dataId={createDataId(dataId, i, 'Cell', 'InvoiceRowDiscounts', 'Empty')}>{null}</TableCell>
                                                                        <TableCell dataId={createDataId(dataId, i, 'Cell', 'InvoiceRowDiscounts', 'Empty')}>{null}</TableCell>
                                                                        <TableCell dataId={createDataId(dataId, i, 'Cell', 'InvoiceRowDiscounts', 'DeleteRow')} colSpan={2}>
                                                                            <div className="invoice-rows__table-body-delete-discount">
                                                                                <Button
                                                                                    iconPlacement={ButtonIconPlacement.LEFT}
                                                                                    onClick={() => {
                                                                                        const discounts = [...formik.values.InvoiceRowDiscounts];
                                                                                        discounts.splice(i, 1);
                                                                                        handleCellChange(discounts, 'InvoiceRowDiscounts', formik);
                                                                                    }}
                                                                                    dataId={createDataId(dataId, i, 'Button', 'InvoiceRowDiscounts', 'Delete')}
                                                                                    icon={ICONS.DELETE_24}
                                                                                    className="icon-button"
                                                                                    buttonType={ButtonType.ICON}
                                                                                />
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                                {formik.values?.InvoiceRowDiscounts?.length > 0 && (
                                                                    <TableRow key={index} data-id={createDataId(dataId, index, 'DiscountRowSums')} isEditting={true}>
                                                                        <TableCell dataId={createDataId(dataId, index, 'Cell', 'DiscountRowSums', 'Empty')}>{null}</TableCell>
                                                                        <TableCell dataId={createDataId(dataId, index, 'Cell', 'DiscountRowSums', 'Empty')}>{null}</TableCell>
                                                                        <TableCell
                                                                            dataId={createDataId(dataId, index, 'Cell', 'DiscountRowSums', 'Empty')}
                                                                            responsiveCellType={ResponsiveCellType.LABEL}
                                                                        >
                                                                            {null}
                                                                        </TableCell>
                                                                        <TableCell dataId={createDataId(dataId, index, 'Cell', 'DiscountRowSums', 'Empty')}>{null}</TableCell>
                                                                        <TableCell dataId={createDataId(dataId, index, 'Cell', 'DiscountRowSums', 'Empty')}>{null}</TableCell>
                                                                        <TableCell dataId={createDataId(dataId, index, 'Cell', 'DiscountRowSums', 'Empty')}>{null}</TableCell>
                                                                        <TableCell
                                                                            dataId={createDataId(dataId, index, 'Cell', 'DiscountRowSums', 'SumWithoutVat')}
                                                                            responsiveCellType={ResponsiveCellType.LABEL}
                                                                        >
                                                                            <FormikField
                                                                                component={TextInputField}
                                                                                showTooltipOnOverflow={true}
                                                                                dataId={createDataId(dataId, index, 'Input', 'DiscountRowSums', 'SumWithoutVat')}
                                                                                name="SumWithoutVat"
                                                                                onlyChangeOnBlur={true}
                                                                                validCharacters={DECIMAL_ROWS_4_REGEXP}
                                                                                replaceComma={true}
                                                                                compareOnBlur={true}
                                                                                type={TextInputType.BORDERED}
                                                                                hideError={true}
                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                    handleCellChange(e.target.value, 'SumWithoutVat', formik);
                                                                                }}
                                                                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                    handleKeyDown(e, row);
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell
                                                                            dataId={createDataId(dataId, index, 'Cell', 'DiscountRowSums', 'VatRate')}
                                                                            responsiveCellType={ResponsiveCellType.LABEL}
                                                                        >
                                                                            <FormikField
                                                                                component={TextInputField}
                                                                                showTooltipOnOverflow={true}
                                                                                dataId={createDataId(dataId, index, 'Input', 'DiscountRowSums', 'VatRate')}
                                                                                name="VatRate"
                                                                                onlyChangeOnBlur={true}
                                                                                showPercent={true}
                                                                                compareOnBlur={true}
                                                                                type={TextInputType.BORDERED}
                                                                                hideError={true}
                                                                                validCharacters={DECIMAL_ROWS_VAT_REGEXP}
                                                                                replaceComma={true}
                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                    handleCellChange(e.target.value, 'VatRate', formik);
                                                                                }}
                                                                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                    handleKeyDown(e, row);
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell dataId={createDataId(dataId, index, 'Cell', 'DiscountRowSums', 'VAT')} responsiveCellType={ResponsiveCellType.LABEL}>
                                                                            <FormikField
                                                                                component={TextInputField}
                                                                                showTooltipOnOverflow={true}
                                                                                dataId={createDataId(dataId, index, 'Input', 'DiscountRowSums', 'VAT')}
                                                                                name="VAT"
                                                                                validCharacters={DECIMAL_ROWS_4_REGEXP}
                                                                                replaceComma={true}
                                                                                onlyChangeOnBlur={true}
                                                                                compareOnBlur={true}
                                                                                type={TextInputType.BORDERED}
                                                                                hideError={true}
                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                    handleCellChange(e.target.value, 'VAT', formik);
                                                                                }}
                                                                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                    handleKeyDown(e, row);
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell
                                                                            dataId={createDataId(dataId, index, 'Cell', 'DiscountRowSums', 'Total')}
                                                                            responsiveCellType={ResponsiveCellType.LABEL}
                                                                        >
                                                                            <FormikField
                                                                                component={TextInputField}
                                                                                showTooltipOnOverflow={true}
                                                                                dataId={createDataId(dataId, index, 'Input', 'DiscountRowSums', 'Total')}
                                                                                name="Total"
                                                                                validCharacters={DECIMAL_ROWS_4_REGEXP}
                                                                                replaceComma={true}
                                                                                onlyChangeOnBlur={true}
                                                                                compareOnBlur={true}
                                                                                type={TextInputType.BORDERED}
                                                                                hideError={true}
                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                    handleCellChange(e.target.value, 'Total', formik);
                                                                                }}
                                                                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                                                    handleKeyDown(e, row);
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell dataId={createDataId(dataId, index, 'Cell', 'DiscountRowSums', 'Empty')}>{null}</TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </>
                                                        )}
                                                    </Formik>
                                                ),
                                            )}
                                        <TableRow>
                                            <TableCell dataId={createDataId(dataId, 'TableFooter', 'Cell', 'Empty')} responsiveCellType={ResponsiveCellType.LABEL}>
                                                {null}
                                            </TableCell>
                                            <TableCell dataId={createDataId(dataId, 'TableFooter', 'Cell', 'Empty')} responsiveCellType={ResponsiveCellType.LABEL}>
                                                {null}
                                            </TableCell>
                                            <TableCell
                                                dataId={createDataId(dataId, 'TableFooter', 'Cell', 'GrandTotal')}
                                                className={'invoice-rows__grand-total'}
                                                responsiveCellType={ResponsiveCellType.LABEL}
                                            >
                                                {t('component.invoiceRows.grandTotal')}
                                            </TableCell>
                                            <TableCell dataId={createDataId(dataId, 'TableFooter', 'Cell', 'Empty')} responsiveCellType={ResponsiveCellType.LABEL}>
                                                {null}
                                            </TableCell>
                                            <TableCell dataId={createDataId(dataId, 'TableFooter', 'Cell', 'Empty')} responsiveCellType={ResponsiveCellType.LABEL}>
                                                {null}
                                            </TableCell>
                                            <TableCell dataId={createDataId(dataId, 'TableFooter', 'Cell', 'Empty')} responsiveCellType={ResponsiveCellType.LABEL}>
                                                {null}
                                            </TableCell>
                                            <TableCell
                                                className={classNames('invoice-rows__footer-block', { 'invoice-rows__footer-block--error': rowsSumsWrong(invoice) })}
                                                dataId={createDataId(dataId, 'TableFooter', 'Cell', 'SumWithoutVat')}
                                                hideOverflow={true}
                                                appendTo={document.getElementById(fullScreenElementId)}
                                                responsiveCellType={ResponsiveCellType.LABEL}
                                            >
                                                {formatMoneyToShowSeparators(trimAfterComma(String(invoice?.InvoiceRowsPrice || 0), 4, 2), true)}
                                            </TableCell>
                                            <TableCell dataId={createDataId(dataId, 'TableFooter', 'Cell', 'Empty')} responsiveCellType={ResponsiveCellType.LABEL}>
                                                {null}
                                            </TableCell>
                                            <TableCell
                                                className={classNames('invoice-rows__footer-block', { 'invoice-rows__footer-block--error': rowsVatSumsWrong(invoice) })}
                                                dataId={createDataId(dataId, 'TableFooter', 'Cell', 'VAT')}
                                                hideOverflow={true}
                                                appendTo={document.getElementById(fullScreenElementId)}
                                                responsiveCellType={ResponsiveCellType.LABEL}
                                            >
                                                {formatMoneyToShowSeparators(trimAfterComma(String(invoice?.InvoiceRowsVAT || 0), 4, 2), true)}
                                            </TableCell>
                                            <TableCell
                                                className={classNames('invoice-rows__footer-block', { 'invoice-rows__footer-block--error': rowsTotalWrong(invoice) })}
                                                dataId={createDataId(dataId, 'TableFooter', 'Cell', 'Total')}
                                                hideOverflow={true}
                                                appendTo={document.getElementById(fullScreenElementId)}
                                                responsiveCellType={ResponsiveCellType.LABEL}
                                            >
                                                {formatMoneyToShowSeparators(trimAfterComma(String(invoice?.InvoiceRowsTotal || 0), 4, 2), true)}
                                            </TableCell>
                                            <TableCell dataId={createDataId(dataId, 'TableFooter', 'Empty')} responsiveCellType={ResponsiveCellType.LABEL}>
                                                {null}
                                            </TableCell>
                                            <TableCell dataId={createDataId(dataId, 'TableFooter', 'Empty')} responsiveCellType={ResponsiveCellType.LABEL}>
                                                {null}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                {!isFullscreen && canSaveInvoice(false) && !isReadOnly && activeEditRowId === null && !areInvoiceRowsSumsNotMatching(invoice) && getCreateTransactionRowsButtons()}
                            </ContentBlockBody>
                        ) : (
                            <EmptySearchResults headerTitle={t('component.invoiceRows.emptySearchResults.noResultsFound')} />
                        )}
                    </div>
                    <ContentBlockFooter contentClass={'invoice-rows__footer'} type={ContentBlockFooterType.PAGER}>
                        <div className="invoice-rows__footer-row">
                            {invoiceRowsLoadable?.payload?.Items?.length > 0 && (
                                <Pager
                                    pages={invoiceRowsLoadable.payload}
                                    tooltipContent={t('component.invoiceRows.pagination.notAllowed')}
                                    onPageChange={handlePageChange}
                                    appendTo={document.getElementById(fullScreenElementId)}
                                    disabled={activeEditRowId !== null}
                                />
                            )}
                        </div>
                    </ContentBlockFooter>
                </ContentBlock>
            </MainPage>
        </div>
    );
};

export default InvoiceRows;
