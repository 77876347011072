import { cloneDeep } from 'lodash-es';
import { updateInvoice } from '../../src/views/invoice-details/components/invoice-slider/InvoiceSliderActions';
import { setCurrentCompanySettings, setCurrentCompanySettingsLoading} from '../../src/common/company/CompanyActions';
import { areTwoNumsEqualWithPrecision } from '../../src/views/invoice-details/components/invoice-header/utils';

"use strict";
angular
  .module("dstreamApp.services")
  .service("activeInvoiceService", DsActiveInvoiceService);
DsActiveInvoiceService.$inject = [
  "$rootScope",
  "$routeParams",
  "$location",
  "webServices",
  "notifyService",
  "utilityService",
  "invoiceService",
  "companyDataService",
  "$q",
  "$filter",
  "authenticationService",
  "$ngRedux"
];

function DsActiveInvoiceService (
  $rootScope,
  $routeParams,
  $location,
  webServices,
  notifyService,
  utilityService,
  invoiceService,
  companyDataService,
  $q,
  $filter,
  authenticationService,
  $ngRedux
) {
  var service = this;
  service.fields = [];
  service.currentCompanyId = null;
  service.companyChanged = false;
  service.invoice = null;
  service.invoiceId = "";
  service.BeneficiaryId = "";
  service.originalInvoiceCompanyId = "";
  service.isInvoiceRowsPartOfInvoice = false;
  service.isApproveAllPartOfInvoice = false;
  service.isAccountingRowsPartOfInvoice = false;
  service.customFieldsTemplates = [];
  service.customFieldsTemplatesWithItems = [];

  service.calls = {
    currentCompany: currentCompany,
    compareTotalSum: compareTotalSum,
    activeInvoice: activeInvoice,
    minimalActiveInvoice: minimalActiveInvoice,
    getCustomCostObjectives: getCustomCostObjectives,
    replaceTasksInWorkflow: replaceTasksInWorkflow
  };
  return service.calls;

  function currentCompany (companyId) {
    if (!!companyId) {
      service.currentCompanyId = cloneDeep(companyId);
    } else {
      return service.currentCompanyId;
    }
  }

  function compareTotalSum(invoice) {
    if (!invoice) {
        return false;
    }
    invoice.rowsSumsWrong = (invoice.InvoiceRowsPrice || invoice.InvoiceRowsPrice == 0) && !areTwoNumsEqualWithPrecision(invoice.InvoiceRowsPrice, invoice.SumWithoutVat);
    invoice.rowsVatSumsWrong = (invoice.InvoiceRowsVAT || invoice.InvoiceRowsVAT == 0) && !areTwoNumsEqualWithPrecision(invoice.InvoiceRowsVAT, invoice.Vat);
    invoice.rowsTotalWrong = (invoice.InvoiceRowsTotal || invoice.InvoiceRowsTotal == 0) && !areTwoNumsEqualWithPrecision(invoice.InvoiceRowsTotal, invoice.TotalAmountWithVat);

    invoice.areSumsNotMatching = (invoice.rowsSumsWrong || invoice.rowsVatSumsWrong || invoice.rowsTotalWrong);
    return !invoice.areSumsNotMatching;
  }

  function minimalActiveInvoice(invoiceId, callbackEmit) {
    if (invoiceId) {
      return getInvoiceData(invoiceId, callbackEmit).then(
        function (response) {
          if (response) {
            service.invoice = response;
            service.invoiceId = service.invoice.Id;
            compareTotalSum(service.invoice);
            return service.invoice;
          }
        }).then(function (invoice) {
          return $q.when(invoice);
        });
    } else {
      var deferred = $q.defer();
      if ($routeParams.id) {
        $rootScope.$watch(function () {
          return service.invoice;
        }, function (newVal) {
          if (newVal) {
            deferred.resolve(service.invoice);
          }
        });
      } else {
        deferred.resolve(service.invoice);
      }
      return deferred.promise;
    }
  }

  function activeInvoice (invoiceId, callbackEmit) {
    if (invoiceId) {
      return getCurrentCompanyDocTypes().then(
          function () {
            return getInvoiceData(invoiceId, callbackEmit).then(
              function (response) {
                if (response) {
                  service.invoice = response;
                  service.invoiceId = service.invoice.Id;
                  compareTotalSum(service.invoice);
                  return service.invoice;
                }
              }
            );
        }).then(function (invoice) {
          return $q.when(invoice);
        });
    } else {
      var deferred = $q.defer();
      if ($routeParams.id) {
        $rootScope.$watch(function () {
          return service.invoice;
        }, function (newVal) {
          if (newVal) {
            deferred.resolve(service.invoice);
          }
        });
      } else {
        deferred.resolve(service.invoice);
      }
      return deferred.promise;
    }
  }

  function getDocTypesFromSettings(settings) {
    var docTypes = settings.filter(function (item) {
      return item.Name === "DocumentTypes";
    });
    if (docTypes.length > 0) {
      if (!docTypes[0].Value) {
        return;
      }
      service.fields = docTypes[0].Value.split(";");
      service.fields = service.fields.map(function (item) {
        return item.split(",");
      });
    }
    return service.fields;
  }

  function getCurrentCompanyDocTypes () {
    const {company : {currentCompanySettings}} = $ngRedux.getState();

    if (currentCompanySettings && currentCompanySettings.length) {
      return new Promise((res) => res(getDocTypesFromSettings(currentCompanySettings)));
    } else {
      $ngRedux.dispatch(setCurrentCompanySettingsLoading(true));
      return companyDataService.getCurrentCompanySettings().then(
        function (response) {
          $ngRedux.dispatch(setCurrentCompanySettings(response.data));
          $ngRedux.dispatch(setCurrentCompanySettingsLoading(false));
          return getDocTypesFromSettings(response.data)
        }, function (error) {
          $ngRedux.dispatch(setCurrentCompanySettingsLoading(false));
          console.log("Request rejected due to:", error);
        });
    }
  }

  function getUserFullNameById (groupMemberId) {
    return companyDataService.getUserFullNameById(groupMemberId).then(
      function (response) {
        if (response) {
          return response.FullName;
        }
      }
    );
  }

  function getCurrentInvoiceSupplier () {
    webServices.getCurrentCompany().then(function (result) {
      if (result.data) {
        service.invoice.Company.Id = result.data.Id;
        service.invoice.Company.Name = result.data.Name;
        service.invoice.Company.RegistrationCode = result.data.RegistrationCode;
        service.invoice.Company.Address = result.data.Address;
      }
      $rootScope.$emit("invoiceLoaded", service.invoice);
    }, function (error) {
      console.log("Request rejected due to:", error);
    });
  }

  function modifyNewInvoiceFromEmptyTemplate () {
    // other invoice blocks are hidden
    service.isInvoiceRowsPartOfInvoice = false;
    service.isApproveAllPartOfInvoice = false;
    service.isAccountingRowsPartOfInvoice = false;
    // invoice information block: set invoice company by default
    webServices.getAllCompaniesFromTheSameOrganizationByName("").then(function (response) {
      if (response.data) {
        service.invoice = {
          IsNew: true,
          Currency: "EUR",
          InvoiceDate: null,
          AccountingDate: null,
          SupplierId: null,
          HubType: null,
          Company: {
            Id: null,
            Name: null,
            RegistrationCode: null,
            Address: null
          },
          Workflow: null
        };
        getCurrentInvoiceSupplier();
      }
    }, function (error) {
      console.log("Request rejected due to:", error);
    });
  }

  function getInvoiceData (id, callbackEmit) {
    var searchId = id || $routeParams.id;
    if (!!searchId) {
      service.invoiceId = searchId;
      return webServices.getInvoiceData(searchId).then(
        function (response) {
            if (!response || (response && (!response.data || !response.data.Invoice))) {
                $location.path("/dashboard");
            } else {
                var invoice = cloneDeep(response.data.Invoice);
                invoice.InvoiceRowsCount = response.data.InvoiceRowsCount;
                invoice.InvoiceRowsPrice = response.data.InvoiceRowsPrice;
                invoice.InvoiceRowsVAT = response.data.InvoiceRowsVAT;
                invoice.InvoiceRowsTotal = response.data.InvoiceRowsTotal;
                invoice.LinkedPurchaseOrdersCount = response.data.LinkedPurchaseOrdersCount;
                invoice.InvoiceAccountingRowsTotal = response.data.InvoiceAccountingRowsTotal;
                invoice.TransactionRowsPrice = response.data.TransactionRowsPrice;
                invoice.TransactionRowsTotal = response.data.TransactionRowsTotal;
                invoice.TransactionRowsVAT = response.data.TransactionRowsVAT;
                invoice.InvoiceDate = invoice.InvoiceDate;
                invoice.AccountingDate = invoice.AccountingDate;
                invoice.DueDate = invoice.DueDate;
                invoice.ImportedDate = new Date(utilityService.removeTimeZone(invoice.ImportedDate));
                if (invoice.InvoiceCustomization) {
                    for (var j = 0; j < invoice.InvoiceCustomization.length; j++) {
                        if (invoice.InvoiceCustomization[j].Customization.FieldType === 2 && invoice.InvoiceCustomization[j].Value !== null) {
                            invoice.InvoiceCustomization[j].Value = invoice.InvoiceCustomization[j].Value;
                        }
                    }
                }
                if (!Array.isArray(invoice.DocumentType)) {
                    invoice.DocumentType = service.fields.filter(function(item) {
                        return item[0] === invoice.DocumentType;
                    })[0];
                }
                if (!invoice.AccountingDate) {
                    invoice.AccountingDate = invoice.InvoiceDate;
                }
                if (!invoice.Beneficiary) {
                    invoice.Beneficiary = invoice.SupplierName;
                    service.BeneficiaryId = invoice.SupplierId;
                }
                invoice.invoiceInvoiceRowsLoaded = true;
                invoice.invoiceAccountingRowsLoading = true;
                let assembleInvoiceDataQueries = [];
                const newCompany = $q.defer();
                if ($rootScope.companyGuid !== response.data.Invoice.CompanyGuid) {
                    authenticationService.changeCompany(response.data.Invoice.Company.CompanyGuid).then((r) => {
                        assembleInvoiceDataQueries = [
                            companyDataService.getCompanyData(true),
                            companyDataService.getGroupMembers(true),
                            $q.when(invoice)
                        ];
                        newCompany.resolve(assembleInvoiceDataQueries);
                    });
                } else {
                    assembleInvoiceDataQueries = [
                        $q.when(invoice)
                    ];
                    newCompany.resolve(assembleInvoiceDataQueries);
                }
                return newCompany.promise.then(r => {
                    return $q.all(r).then((results) => {

                        if (invoice.Workflow && invoice.Workflow.Tasks && invoice.Workflow.Tasks.length > 0) {
                            for (var i = 0; i < invoice.Workflow.Tasks.length; i++) {
                                var fromArchive = $routeParams.archive;
                                if (!fromArchive) {
                                    (function(indexer) {
                                        return getUserFullNameById(indexer.ProcessedBy).then(
                                            function(response) {
                                                indexer.GroupMember.SubstituterUserFullName = response;
                                                return response;
                                            },
                                        );
                                    })(invoice.Workflow.Tasks[i]);
                                }
                            }
                        }
                        service.invoice = cloneDeep(invoice);
                        if (callbackEmit) {
                            // we can call a special callback emit here if it is specified
                            if (callbackEmit === 'invoiceRowsEdited') {
                                // if we have edited invoice rows we want to send the updated data back to invoice slider
                                $rootScope.$emit(callbackEmit, service.invoice);
                                $ngRedux.dispatch(updateInvoice(service.invoice))
                            } else {
                                $rootScope.$emit(callbackEmit);
                            }
                        } else {
                            $rootScope.$emit('invoiceLoaded', service.invoice);
                        }

                        service.originalInvoiceCompanyId = invoice.CompanyGuid;

                        return invoice;
                    }).catch(function(error) {
                        console.log('Request rejected due to:', error);
                    });
                });
            }
        });
    } else {
      modifyNewInvoiceFromEmptyTemplate();
    }
  }

  function getCustomCostObjectives () {
    if (service.customFieldsTemplates && service.customFieldsTemplates.length && !service.companyChanged) {
      var def = $q.defer();
      def.resolve(service.customFieldsTemplates);
      return def.promise;
    } else {
      return companyDataService.getCustomCostObjectives(false).then(
        function (response) {
          return (response) ? response : [];
        },
        function (error) {
          console.log("Request rejected due to:", error);
        });
    }
  }

  /*
      Replace tasks in existing workflow with edited tasks
   */
  function replaceTasksInWorkflow (tasksObject, invoice, confirmFirst) {
    // create correct workflow object
    var workFlowObject = {
      Id: invoice ? invoice.WorkflowId : null,
      InvoiceId: invoice ? invoice.Id : null,
      Tasks: []
    };
    // build correct tasks array
    Object.keys(tasksObject).forEach(function (key) {
      tasksObject[key].tasks.forEach(function (task) {
        workFlowObject.Tasks.push(task);
      });
    });
    return webServices.replaceTasks(workFlowObject, confirmFirst).then(function (result) {
      // we don't need to do anything with the result in the service
      return result.data;
    }).catch(function () {
      notifyService.error("component.confirmerFlow.errorInWorkflow", "component.confirmerFlow.errorInRequest", true);
    });
  }
}
