import React from 'react';
import { TFunction } from 'i18next';

import { Button, ButtonIconPlacement, ButtonType } from '../../../../../components/Buttons/Button';
import { ICONS } from '../../../../../components/Icon/Icon';
import { createDataId } from '../../../../../common/utils/dataId';

const AddSupplierButton = ({ dataId, t, onClick }: { dataId: string; t: TFunction; onClick: () => void }) => {
    return (
        <Button
            dataId={createDataId(dataId, 'button', 'add-new-supplier')}
            style={{ margin: 'var(--sp-4) var(--sp-6)' }}
            onClick={onClick}
            buttonType={ButtonType.ICON_TEXT}
            icon={ICONS.PLUS_THIN}
            iconPlacement={ButtonIconPlacement.LEFT}
        >
            {t('component.invoiceHeader.addNewSupplier')}
        </Button>
    );
};

export default AddSupplierButton;
