import { cloneDeep, isDate, isEmpty, isNumber } from 'lodash-es';
import { batch } from 'react-redux';

import i18n from '../../../../../i18n';
import api from '../../../../../services/ApiServices';
import { GlobalState } from '../../../../../rootReducer';
import { DispatchThunk } from '../../../../../storeConfig';
import { getCurrentUserGroupMember, updateUserSettings } from '../../../../../common/user/UserActions';
import { getViewUserSearchParams, getViewUserSettings, ListViewUserConfig, setUserSettingValue } from '../../../../../common/user/userSettingUtil';
import { updateAppUserSettingsAction } from '../../../../../common/middlewares/userSettings';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../../../common/utils/baseSearchHelpers';
import { loadableDataActions, loadableDataActionsWithRequest } from '../../../../../common/utils/LoadableData';
import { notify } from '../../../../../common/utils/notify';
import { TableFilter } from '../../../../../components/Table/components/filter/TableFilters';
import {
    BaseSearch,
    PagedListContainer,
    PurchaseOrderInvoicesSearch,
    PurchaseOrderHeaderInvoicesDTO,
    LinkedCountDTO,
    PurchaseOrderInvoicesFilter,
    Restriction,
    SearchType,
    SortDirection,
    UserSettingName,
} from '../../../../../services/types/ApiTypes';
import { DEFAULT_RESTRICTION, DEFAULT_SORT_COLUMN } from './InvoicesListHelper';
import { InvoicesSearchParams, selectPurchaseOrderInvoicesFilterType, selectPurchaseOrderInvoicesSearchParams } from './InvoicesListReducers';
import { retrievePO } from '../../../../purchase-orders-add/PurchaseOrdersAddViewActions';
import { getPurchaseOrderHistory } from '../../HistoryCard/PurchaseOrderHistoryActions';

const ns = 'purchaseOrderInvoices/';

const invoicesListViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.PURCHASE_ORDER_INVOICES_SORT_DIRECTION,
    sortCol: UserSettingName.PURCHASE_ORDER_INVOICES_SORT_COLUMN,
    pageSize: UserSettingName.PURCHASE_ORDER_INVOICES_PAGE_SIZE,
};

export const getPurchaseOrderInvoicesLoadable = loadableDataActionsWithRequest<PurchaseOrderInvoicesSearch, PagedListContainer<PurchaseOrderHeaderInvoicesDTO>>(`${ns}ALL_INVOICES`);

export const getInvoicesList = (searchParams: InvoicesSearchParams, poId: number, type?: PurchaseOrderInvoicesFilter) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const invoicesSearchParams = searchParams || selectPurchaseOrderInvoicesSearchParams(getState());
        const groupMember = getState().user.groupMemberCommonLoadable.payload;

        const viewSearchParams = getViewUserSearchParams(invoicesSearchParams, invoicesListViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'InvoiceDate', SortDirection.Asc);

        if (type) {
            invoicesSearchParams.PagingOptions.Page = 1;
        }

        const apiSearchParams: PurchaseOrderInvoicesSearch = {
            SearchParams: invoicesSearchParams,
            PurchaseOrderId: poId,
            Filter: isNumber(type) ? type : selectPurchaseOrderInvoicesFilterType(getState()),
        };

        const searchParamsWithoutFilter = cloneDeep(apiSearchParams);

        delete searchParamsWithoutFilter.SearchParams.filters;

        let response;
        try {
            dispatch(getPurchaseOrderInvoicesLoadable.request(apiSearchParams));
            response = await api.purchaseOrder.getInvoicesForPurchaseOrder(searchParamsWithoutFilter);
            if (response?.status === 200) {
                dispatch(
                    getPurchaseOrderInvoicesLoadable.success({
                        request: apiSearchParams,
                        result: response.data,
                    }),
                );
            } else {
                notify.error(i18n.t('interceptorsFactory.ErrorWhileProcessingData'), i18n.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            dispatch(
                getPurchaseOrderInvoicesLoadable.error({
                    request: apiSearchParams,
                    result: e,
                }),
            );
        }
    };
};

export const getPurchaseOrderInvoicesLinkedCount = loadableDataActions<number, LinkedCountDTO>(`${ns}INVOICES_LINKED_COUNT`);

export function getLinkedInvoicesCount(id: number, listRestricted?: boolean, onMount?: boolean, getOnlyCount?: boolean) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        let response;
        try {
            dispatch(getPurchaseOrderInvoicesLinkedCount.request(id));
            if (id) {
                response = await api.purchaseOrder.getLinkedInvoicesCount(id);
            }
            if (response.data) {
                dispatch(getPurchaseOrderInvoicesLinkedCount.success(response.data));
                if (!getOnlyCount && (!listRestricted || response?.data?.LinkedObjectsCount > 0)) {
                    const filterType = selectPurchaseOrderInvoicesFilterType(getState());
                    const quickFilterType = isNumber(filterType) && !onMount ? filterType : response.data.LinkedObjectsCount > 0 ? PurchaseOrderInvoicesFilter.Linked : PurchaseOrderInvoicesFilter.Any;
                    dispatch(getInvoicesList(undefined, id, quickFilterType));
                }
            } else {
                notify.info(i18n.t('view.PurchaseOrders.Error.General'));
            }
        } catch (e) {
            console.error(e);
            if (e.response?.data?.ErrorCode) {
                notify.error(i18n.t(e.response.data.ErrorCode));
            }
            dispatch(getPurchaseOrderInvoicesLinkedCount.error(e));
        }
    };
}

export const setPoInvoicesPagingOptions = (poId: number, page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            purchaseOrderInvoices,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const paging = purchaseOrderInvoices.searchParams.PagingOptions;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(invoicesListViewConfig, groupMember);
            if (!viewConfig.pageSize || parseInt(viewConfig.pageSize, 10) !== pageSize) {
                groupMember.UserSettings = setUserSettingValue(invoicesListViewConfig.pageSize, pageSize, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: InvoicesSearchParams = {
            ...purchaseOrderInvoices.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        dispatch(getInvoicesList(searchParams, poId));
    };
};

export const sortPoInvoicesList = (columnName: string, poId: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            purchaseOrderInvoices,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const sorting = purchaseOrderInvoices.searchParams.SortItems[0];
        const sortingDirection = sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(invoicesListViewConfig, groupMember);

            if (!viewConfig.sortCol || viewConfig.sortCol !== columnName) {
                groupMember.UserSettings = setUserSettingValue(invoicesListViewConfig.sortCol, columnName, groupMember.UserSettings);
            }
            if (!viewConfig.sortDir || parseInt(viewConfig.sortDir, 10) !== sortingDirection) {
                groupMember.UserSettings = setUserSettingValue(invoicesListViewConfig.sortDir, sortingDirection, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: BaseSearch = {
            ...purchaseOrderInvoices.searchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };
        dispatch(getInvoicesList(searchParams, poId));
    };
};

export const searchPoInvoicesList = (searchString: string, poId: number, resetFilters?: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.purchaseOrderInvoices.searchParams.PagingOptions;
        let searchRestriction = state.purchaseOrderInvoices.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        const otherRestrictions = state.purchaseOrderInvoices.searchParams.Restrictions.filter((r) => r.Field !== DEFAULT_RESTRICTION);
        let restrictions = [];

        if (!isEmpty(searchString)) {
            if (!searchRestriction) {
                searchRestriction = {
                    Field: DEFAULT_RESTRICTION,
                    Value: searchString,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                };
            } else {
                searchRestriction.Value = searchString;
            }
            restrictions.push(searchRestriction);
        }

        if (!isEmpty(otherRestrictions) && !resetFilters) {
            restrictions = [...restrictions, ...otherRestrictions];
        }
        const searchParams: InvoicesSearchParams = {
            ...state.purchaseOrderInvoices.searchParams,
            SortItems: resetFilters
                ? [
                      {
                          SortColumn: DEFAULT_SORT_COLUMN,
                          SortDirection: SortDirection.Asc,
                      },
                  ]
                : state.purchaseOrderInvoices.searchParams.SortItems,
            PagingOptions: {
                ...paging,
                Page: 1,
            },
            Restrictions: [...restrictions],
        };
        if (resetFilters) {
            await dispatch(
                updateAppUserSettingsAction({
                    listViewConfig: invoicesListViewConfig,
                    searchParams,
                }),
            );
        }
        dispatch(getInvoicesList(searchParams, poId, resetFilters ? 0 : null));
    };
};

export const filterPoInvoices = (id: number, restriction?: Restriction, filter?: TableFilter<any>, dateRestrictions?: Restriction[]) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.purchaseOrderInvoices.searchParams.PagingOptions;

        // keep the old restrictions when we filter
        let restrictions = state.purchaseOrderInvoices.searchParams.Restrictions.filter((r, index) => {
            if (dateRestrictions && isDate(new Date(r.Value))) {
                //reset old date range when adding a new one
                return false;
            }

            if (index === 0) {
                return r;
            }
            if (r.Field === restriction?.Field) {
                if (isEmpty(restriction.Value) && isEmpty(restriction.Values)) {
                    return false;
                }
                return !!restriction.Values || !!restriction.Value;
            }
            return !!r.Value || !!r.Values;
        }).map((r) => {
            if (r.Field === restriction?.Field) {
                return restriction;
            }
            return r;
        });
        if (!!restriction && !restrictions.includes(restriction) && ((typeof restriction.Value === 'number' ? restriction.Value !== 0 : !isEmpty(restriction.Value)) || !isEmpty(restriction.Values))) {
            restrictions.push(restriction);
        }
        if (dateRestrictions) {
            dateRestrictions.forEach((e) => {
                if (e.Value && isDate(new Date(e.Value))) {
                    restrictions.push(e);
                }
            });
        }
        if (filter?.childColumnName) {
            restrictions = restrictions.filter((p) => p.Field !== filter.childColumnName);
            let children = Array<any>();
            filter.values.forEach((value) => {
                if (value.children) {
                    children = [...children, ...value.children];
                }
            });
            if (children.length) {
                restrictions.push({
                    Field: filter.childColumnName,
                    Value: null,
                    Values: children.map((val) => val.value),
                    FieldSearchType: SearchType.NotSelected,
                });
            }
        }
        const searchParams: InvoicesSearchParams = {
            ...state.purchaseOrderInvoices.searchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: restrictions,
            filters: {
                ...state.purchaseOrderInvoices.searchParams.filters,
                [restriction?.Field]: filter,
            },
        };
        dispatch(getInvoicesList(searchParams, id));
    };
};

export const linkPurchaseOrderToInvoice = (invoiceId: number, purchaseOrderId: number) => {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            response = await api.purchaseOrder.linkPurchaseOrderToInvoice(invoiceId, purchaseOrderId);
            if (response.status === 200) {
                notify.success(i18n.t('views.invoiceHeader.purchaseOrderSuccessfullyLinked'));
                batch(() => {
                    dispatch(getLinkedInvoicesCount(purchaseOrderId));
                    dispatch(retrievePO(purchaseOrderId.toString()));
                    dispatch(getPurchaseOrderHistory(purchaseOrderId));
                });
            } else {
                console.error(response.statusText);
                notify.error(i18n.t('views.invoiceHeader.errorLinkingPurchaseOrder'));
            }
        } catch (e) {
            console.error(e);
            notify.error(i18n.t('views.invoiceHeader.errorLinkingPurchaseOrder'));
        }
    };
};

export const unlinkPurchaseOrderFromInvoice = (invoiceId: number, purchaseOrderId: number) => {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            response = await api.purchaseOrder.unlinkPurchaseOrderFromInvoice(invoiceId, purchaseOrderId);
            if (response.status === 200) {
                notify.success(i18n.t('views.invoiceHeader.purchaseOrderSuccessfullyUnlinked'));
                batch(() => {
                    dispatch(getLinkedInvoicesCount(purchaseOrderId));
                    dispatch(retrievePO(purchaseOrderId.toString()));
                    dispatch(getPurchaseOrderHistory(purchaseOrderId));
                });
            } else {
                console.error(response.statusText);
                notify.error(i18n.t('views.invoiceHeader.errorUnlinkingPurchaseOrder'));
            }
        } catch (e) {
            console.error(e);
            notify.error(i18n.t('views.invoiceHeader.errorUnlinkingPurchaseOrder'));
        }
    };
};
