import { createAction } from 'redux-actions';

import { ProductItemDTO } from '../../../../services/types/ApiTypes';
import { loadableDataActions } from '../../../../common/utils/LoadableData';
import { DispatchThunk } from '../../../../storeConfig';
import api from '../../../../services/ApiServices';
import { notify } from '../../../../common/utils/notify';
import i18nInstance from '../../../../i18n';
import { filterProductItems } from '../ProductItemsViewActions';

const ns = 'new-ProductItem-modal/';

export const setActiveProductItem = createAction<ProductItemDTO>(`${ns}SET_ACTIVE_ProductItem`);
export const saveProductItemActions = loadableDataActions<ProductItemDTO, ProductItemDTO>(`${ns}SAVE_ProductItem`);

export const saveProductItem = (ProductItem: ProductItemDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(saveProductItemActions.request(ProductItem));
            const response = await api.productItem.saveProductItem(ProductItem);
            if (response?.status === 200) {
                dispatch(saveProductItemActions.success(response.data));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
            dispatch(filterProductItems(''));
        } catch (e) {
            console.error(e);
            dispatch(saveProductItemActions.error(e));
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const showEditProductItemModal = createAction<boolean>(`${ns}SHOW_NEW_PRODUCT_ITEM_MODAL`);

export function showAddOrEditProductItemModal(ProductItem?: ProductItemDTO) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(setActiveProductItem(ProductItem || undefined));
            dispatch(showEditProductItemModal(true));
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileSendingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
}
