import { createAction } from 'redux-actions';

import { PaymentMethodDTO } from '../../../../services/types/ApiTypes';
import { loadableDataActions } from '../../../../common/utils/LoadableData';
import { DispatchThunk } from '../../../../storeConfig';
import api from '../../../../services/ApiServices';
import { notify } from '../../../../common/utils/notify';
import i18nInstance from '../../../../i18n';
import { getPaymentMethodsList } from '../PaymentMethodsActions';

const ns = 'new-PaymentMethod-modal/';

export const setActivePaymentMethod = createAction<PaymentMethodDTO>(`${ns}SET_ACTIVE_PAYMENT_METHOD`);
export const savePaymentMethodActions = loadableDataActions<PaymentMethodDTO, PaymentMethodDTO>(`${ns}SAVE_PAYMENT_METHOD`);
export const showEditPaymentMethodModalAction = createAction<boolean>(`${ns}SHOW_EDIT_PAYMENT_METHOD_MODAL`);

export const addPaymentMethod = (paymentMethod: PaymentMethodDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(savePaymentMethodActions.request(paymentMethod));
            const response = await api.paymentMethod.addPaymentMethod(paymentMethod);
            if (response?.status === 200) {
                dispatch(savePaymentMethodActions.success(response.data));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
            dispatch(getPaymentMethodsList());
        } catch (e) {
            console.error(e);
            dispatch(savePaymentMethodActions.error(e));
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const updatePaymentMethod = (paymentMethod: PaymentMethodDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(savePaymentMethodActions.request(paymentMethod));
            const response = await api.paymentMethod.updatePaymentMethod(paymentMethod);
            if (response?.status === 200) {
                dispatch(savePaymentMethodActions.success(response.data));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
            dispatch(getPaymentMethodsList());
        } catch (e) {
            console.error(e);
            dispatch(savePaymentMethodActions.error(e));
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export function showAddOrEditPaymentMethodModal(paymentMethod?: PaymentMethodDTO) {
    return (dispatch: DispatchThunk) => {
        dispatch(setActivePaymentMethod(paymentMethod || undefined));
        dispatch(showEditPaymentMethodModalAction(true));
    };
}
