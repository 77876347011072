import { ReducerFactory } from 'redux-actions-ts-reducer';
import { createAction } from 'redux-actions';
import { GlobalState } from '../../rootReducer';

class State {
    locked = false;
    showPrompt = false;
    locationOnConfirm: null | string = null;
}

const ns = 'NAVIGATION_LOCK';

export const setMenuLocked = createAction(`${ns}/MENU_LOCK`);
export const setMenuUnlocked = createAction(`${ns}/MENU_UNLOCK`);
export const setPromptDialogShow = createAction(`${ns}/CONFIRM_MODAL_SHOW`);
export const setPromptDialogHide = createAction(`${ns}/CONFIRM_MODAL_HIDE`);
export const setConfirmLocation = createAction<string>(`${ns}/SET_CONFIRM_LOCATION`);

export default new ReducerFactory(new State())
    .addReducer(
        setMenuLocked,
        (state): State => {
            return {
                ...state,
                locked: true,
            };
        },
    )
    .addReducer(
        setMenuUnlocked,
        (state): State => {
            return {
                ...state,
                locked: false,
            };
        },
    )
    .addReducer(
        setPromptDialogShow,
        (state): State => {
            return {
                ...state,
                showPrompt: true,
            };
        },
    )
    .addReducer(
        setPromptDialogHide,
        (state): State => {
            return {
                ...state,
                showPrompt: false,
            };
        },
    )
    .addReducer(
        setConfirmLocation,
        (state, action): State => {
            return {
                ...state,
                locationOnConfirm: action.payload,
            };
        },
    )
    .toReducer();

export { State as MenuLockState };

export const menuLockStateSelector = (state: GlobalState) => state.menuLock;
export const menuLockStatusSelector = (state: GlobalState) => state.menuLock.locked;
