import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { TFunction } from 'i18next';
import { Draggable } from 'react-beautiful-dnd';

import { GroupMemberDTO, InvoiceDTO, TaskDTO } from '../../../../../services/types/ApiTypes';
import { Button, ButtonType } from '../../../../../components/Buttons/Button';
import Icon, { ICONS, IconSize, IconType } from '../../../../../components/Icon/Icon';
import ToggleItems from '../../../../settings/workflow-details/components/ToggleItems';
import { TypeaheadAsync, TypeaheadItem } from '../../../../../components/Typeahead/TypeaheadAsync';
import { User } from '../../../../../services/ApiClient';
import { DropdownMenu, DropdownMenuItem } from '../../../../../components/DropdownMenu/DropdownMenu';
import { createDataId } from '../../../../../common/utils/dataId';

import { getApproveAllConfirmersByNamePart, getConfirmerName } from '../InvoiceConfirmationsHelper';

export interface ItemEditWorkFlowProps {
    groupTask: TaskDTO[];
    staticStepsNo: number | null; // 'null' means task has only one approver
    t: TFunction;
    index: number;
    $rootScope: any;
    invoice: InvoiceDTO;
    userData: User;
    deleteEnabled?: boolean;
    updateGroupTaskEditing: (group: TaskDTO[]) => void;
    updateApproversPerSteps: (approversCount: number, orderNo: number, oldOrderNo?: number) => void;
    removeGroup: (index: number) => void;
}

export const ItemEditWorkFlow = (props: ItemEditWorkFlowProps): React.ReactElement => {
    const { index, invoice, groupTask, removeGroup, staticStepsNo, t, updateApproversPerSteps, updateGroupTaskEditing, userData, deleteEnabled } = props;

    const [totalApprovers, setTotalApprovers] = useState<number | null>(groupTask.length); // total approvers in the GroupTask
    const [stepsToComplete, setStepsToComplete] = useState(groupTask.length < 2 ? null : groupTask.length); // approves needed to complete GroupTask

    useEffect(() => {
        setStepsToComplete(staticStepsNo);
    }, [staticStepsNo]);

    useEffect(() => {
        setTotalApprovers(groupTask.length);
    }, [groupTask]);

    const onChange = async (groupMember: GroupMemberDTO, index: number) => {
        groupTask[index].GroupMemberId = groupMember.Id;
        groupTask[index].GroupMember = {
            ...Object(groupTask[index].GroupMember),
            Id: groupMember.Id,
            MemberRoles: groupMember.MemberRoles,
            Name: groupMember.Name,
        };

        updateGroupTaskEditing(props.groupTask);
    };

    const addTask = () => {
        groupTask.push({
            OrderNo: groupTask[0].OrderNo,
            Completed: false,
            Id: null,
            CompletedDate: null,
            Comment: null,
            ProcessedBy: null,
            GroupMemberId: null,
            GroupMember: undefined,
            WorkflowId: undefined,
            IsNew: undefined,
            isCurrentConfirmer: undefined,
            displayAddBeforeMeProp: undefined,
            displayAddAfterMeProp: undefined,
            StatusLookupId: undefined,
            ToSubstituteName: undefined,
            CreatorName: null,
        });
        updateGroupTaskEditing(groupTask);
        const newTotalApprovers = totalApprovers ? totalApprovers + 1 : 2;
        // newTotalApprovers > 1 && setStepsToComplete(newTotalApprovers);
        setTotalApprovers(newTotalApprovers);
        if (stepsToComplete) {
            updateApproversPerSteps(totalApprovers ? stepsToComplete + 1 : 2, groupTask[0].OrderNo);
        }
    };

    const removeTask = (index: number) => {
        groupTask.splice(index, 1);
        updateGroupTaskEditing(groupTask);
        setTotalApprovers(totalApprovers === 2 ? null : totalApprovers - 1);
        if (stepsToComplete) {
            updateApproversPerSteps(totalApprovers ? (stepsToComplete < totalApprovers ? stepsToComplete : stepsToComplete - 1) : null, groupTask[0].OrderNo);
        }
    };

    const changeApproversNumber = (n: number | null) => {
        if (n !== stepsToComplete) {
            // save only if it has changed
            setStepsToComplete(n);
            updateApproversPerSteps(n, groupTask[0].OrderNo);
        }
    };

    const loadItemsRequiredForStep = (steps: number) => {
        const options = [];
        options.push(
            <DropdownMenuItem key="parallel.default" dataId={createDataId('workflow', `group-0`, 'requiredForStep', `dropdownItem-default`)} onClick={() => changeApproversNumber(null)}>
                <span>{t('component.confirmationFlow.parallel')}</span>
            </DropdownMenuItem>,
        );
        for (let i = 1; i <= steps; i++) {
            options.push(
                <DropdownMenuItem key={`${i}.default`} dataId={createDataId('workflow', `group-${i}`, 'requiredForStep', `dropdownItem-default`)} onClick={() => changeApproversNumber(i)}>
                    {`${i} ${t('component.confirmationFlow.of')} ${steps}`}
                </DropdownMenuItem>,
            );
        }
        return options;
    };

    const renderRequiredApproversNumberDropdown = (i: number, steps: number) => (
        <DropdownMenu dataId={createDataId('workflow', `group-${i}`, 'requiredForStep')} items={loadItemsRequiredForStep(steps)}>
            <button type="button" className="required-for-step__trigger table-button" data-id={createDataId('workflow', `group-${i}`, 'requiredForStep', 'dropdown', 'trigger')}>
                {stepsToComplete ? `${stepsToComplete} ${t('component.confirmationFlow.of')} ${steps}` : <span>{t('component.confirmationFlow.parallel')}</span>}
                <Icon iconName={ICONS.ARROW_DOWN_SMALL} size={IconSize.SM} rotation={0} />
            </button>
        </DropdownMenu>
    );

    return (
        !isEmpty(groupTask) && (
            <Draggable draggableId={`${groupTask[0].Id}`} index={index}>
                {(provided) => (
                    <div className="confirmation__task--item-edit" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                        <div className="confirmation__task-content confirmation__task-content--upcoming">
                            <div className="confirmation__group-tasks">
                                {groupTask.length > 1 && (
                                    <div className="confirmation__task-item--edit">
                                        <div className="confirmation__task-icon-drag">
                                            <Icon iconName={ICONS.DRAG_FLOW} size={IconSize.SM} />
                                        </div>
                                        {!totalApprovers ||
                                            (totalApprovers === 1 && <span className="confirmation__confirmer confirmation__note-text">{t('component.confirmationFlow.parallelConfirmer')}</span>)}
                                        {groupTask.length > 1 && renderRequiredApproversNumberDropdown(index, totalApprovers)}
                                        <div className="confirmation__task-icon-actions">
                                            <Button buttonType={ButtonType.ICON_SQUARE} icon={ICONS.USER_ADD_FLOW} iconType={IconType.WIDE} onClick={addTask} />
                                            <Button disabled={!deleteEnabled} buttonType={ButtonType.ICON_SQUARE} icon={ICONS.DELETE} onClick={() => removeGroup(index)} />
                                        </div>
                                    </div>
                                )}

                                {groupTask.map((item, i) => {
                                    return (
                                        <div key={i} className={`confirmation__task-item--edit ${item.GroupMember === undefined ? 'confirmation__task-item--new' : ''}`}>
                                            {groupTask.length === 1 && (
                                                <div className="confirmation__task-icon-drag">
                                                    <Icon iconName={ICONS.DRAG_FLOW} size={IconSize.SM} />
                                                </div>
                                            )}

                                            {item.GroupMember === undefined ? (
                                                <TypeaheadAsync
                                                    key={i}
                                                    wrapperClass="confirmation__choose-confirmation"
                                                    dataId={'choose-confirmation-flow'}
                                                    loadItems={(name?: string) => {
                                                        return getApproveAllConfirmersByNamePart(name, groupTask, false, invoice.Status, true);
                                                    }}
                                                    inputProps={{
                                                        autofocus: true,
                                                        showClear: true,
                                                    }}
                                                    placeholder={t('component.confirmationFlow.chooseUser.placeholder')}
                                                    onBlur={() => removeTask(i)}
                                                    searchOnFocus={true}
                                                    onChange={(e: TypeaheadItem<GroupMemberDTO>) => {
                                                        e && onChange(e.value, i);
                                                    }}
                                                />
                                            ) : (
                                                <ToggleItems
                                                    firstItem={
                                                        <div className="confirmation__task-item">
                                                            <span className="confirmation__confirmer">{getConfirmerName(item, userData)}</span>
                                                        </div>
                                                    }
                                                    secondItem={
                                                        <TypeaheadAsync
                                                            wrapperClass="confirmation__choose-confirmation"
                                                            dataId={'choose-confirmation-flow'}
                                                            loadItems={(name?: string) => {
                                                                return getApproveAllConfirmersByNamePart(name, groupTask, false, invoice.Status, true);
                                                            }}
                                                            inputProps={{
                                                                autofocus: true,
                                                                showClear: true,
                                                            }}
                                                            searchOnFocus={true}
                                                            onChange={(e: TypeaheadItem<GroupMemberDTO>) => {
                                                                e && onChange(e.value, i);
                                                            }}
                                                        />
                                                    }
                                                    key={i}
                                                />
                                            )}
                                            {groupTask.length > 1 ? (
                                                <div className="confirmation__delete-edit-task">
                                                    {item.GroupMember !== undefined && (
                                                        <Button buttonType={ButtonType.ICON_SQUARE} iconSize={IconSize.SM} icon={ICONS.DELETE} onClick={() => removeTask(i)} />
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="confirmation__task-icon-actions">
                                                    <Button buttonType={ButtonType.ICON_SQUARE} icon={ICONS.USER_ADD_FLOW} iconType={IconType.WIDE} onClick={addTask} />
                                                    <Button disabled={!deleteEnabled} buttonType={ButtonType.ICON_SQUARE} icon={ICONS.DELETE} onClick={() => removeGroup(index)} />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
        )
    );
};
