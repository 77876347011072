import { useEffect } from 'react';

const usePreventUnload = (doPrevent?: boolean): void => {
    // prevents reloading browser tab when 'doPrevent' is true

    useEffect(() => {
        if (doPrevent) {
            const onBeforeUnload = (event: BeforeUnloadEvent) => {
                const e = event || window.event;
                e.preventDefault(); // Cancel the event
                if (e) {
                    e.returnValue = ''; // Legacy method for cross browser support
                }
                return ''; // Legacy method for cross browser support
            };

            window.addEventListener('beforeunload', onBeforeUnload);
            return () => window.removeEventListener('beforeunload', onBeforeUnload);
        }
        return undefined;
    }, [doPrevent]);
};

export default usePreventUnload;
