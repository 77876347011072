import { push } from 'connected-react-router';
import { isEmpty } from 'lodash-es';

import { paths, resolvePathParams } from '../../common/router/routePaths';
import { getViewUserSearchParams, getViewUserSettings, setUserSettingValue, ListViewUserConfig } from '../../common/user/userSettingUtil';
import { notify } from '../../common/utils/notify';
import { loadableDataActions, loadableDataActionsWithRequest } from '../../common/utils/LoadableData';
import { TableFilter } from '../../components/Table/components/filter/TableFilters';
import i18nInstance from '../../i18n';
import { GlobalState } from '../../rootReducer';
import { PagedListContainer, Restriction, SearchType, SortDirection, UserCreationDTO, UserDTO, UserSettingName } from '../../services/types/ApiTypes';
import api from '../../services/ApiServices';
import { DispatchThunk } from '../../storeConfig';

import { UserListSearchParams, selectUserListSearchParams } from './UserListViewReducer';
import { DEFAULT_RESTRICTION } from './UserListViewHelpers';
import { getCurrentUserGroupMember, updateUserSettings } from '../../common/user/UserActions';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../common/utils/baseSearchHelpers';
import { formatDate, formatFileNameSpaces } from '../../common/utils/formatters';
import { getCurrentCompany } from '../../common/company/CompanyActions';
import { saveAs } from 'file-saver';
import { selectedCountryKey, selectedLanguageKey } from '../login/loginHelper';

const ns = 'user-list/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.SETTINGS_USERS_SORT_DIRECTION,
    sortCol: UserSettingName.SETTINGS_USERS_SORT_COLUMN,
    pageSize: UserSettingName.SETTINGS_USERS_PAGE_SIZE,
};

export const getUserListLoadable = loadableDataActionsWithRequest<UserListSearchParams, PagedListContainer<UserDTO>>(`${ns}ALL_USERS`);
export const updateUserStateAction = loadableDataActionsWithRequest<UserCreationDTO, UserCreationDTO>(`${ns}UPDATE_USER_STATE`);
export const getActiveUsersCountLoadable = loadableDataActions<null, number>(`${ns}ACTIVE_USERS_COUNT`);

export const exportUsersToCsvActions = loadableDataActions(`${ns}EXPORT_USERS_TO_CSV`);
export const exportUsersToXlsActions = loadableDataActions(`${ns}EXPORT_USERS_TO_XLS`);

export const getActiveUsersCount = () => {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            dispatch(getActiveUsersCountLoadable.request(null));
            response = await api.user.getActiveUsersCount();
            dispatch(getActiveUsersCountLoadable.success(response.data));
        } catch (e) {
            console.error('Request rejected due to:', e);
        }
    };
};

export const getUserList = (searchParams: UserListSearchParams | undefined) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const {
            userManagement,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        searchParams = searchParams || userManagement.searchParams;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Email', SortDirection.Asc);

        searchParams = { ...searchParams, ...viewSearchParams };

        const apiSearchParams = { ...searchParams };
        delete apiSearchParams.filters;
        let response;
        try {
            dispatch(getUserListLoadable.request(searchParams));
            response = await api.user.getUsers(apiSearchParams);
            dispatch(
                getUserListLoadable.success({
                    request: searchParams,
                    result: response.data,
                }),
            );

            if (response.data.Items.length === 0 && response.data.HasCount) {
                const currentPage = searchParams.PagingOptions.Page;
                if (currentPage > 1) {
                    searchParams.PagingOptions.Page -= 1;
                    dispatch(getUserList(searchParams));
                }
            }
        } catch (e) {
            dispatch(
                getUserListLoadable.error({
                    request: searchParams,
                    result: e,
                }),
            );
        }
    };
};

export const updateUserState = (user: UserCreationDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(updateUserStateAction.request(user));
            const result = await api.user.updateUser(user);
            if (result.data.Success) {
                dispatch(
                    updateUserStateAction.success({
                        request: user,
                        result: {
                            ...user,
                        },
                    }),
                );
                dispatch(getActiveUsersCount());
            } else {
                notify.error(i18nInstance.t(result.data.Errors[0]));
            }
        } catch (e) {
            console.error('Request rejected due to:', e);
        }
    };
};

export const setUserListPagingOptions = (page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            userManagement,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const paging = userManagement.searchParams.PagingOptions;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(listViewConfig, groupMember);
            if (!viewConfig.pageSize || parseInt(viewConfig.pageSize, 10) !== pageSize) {
                groupMember.UserSettings = setUserSettingValue(listViewConfig.pageSize, pageSize, groupMember.UserSettings);
            }
            if (pageSize !== paging.Count) {
                dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
            }
        }

        const searchParams: UserListSearchParams = {
            ...userManagement.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        dispatch(getUserList(searchParams));
    };
};

/* istanbul ignore next */
// unused action
export const filterUserList = (restriction: Restriction, filter?: TableFilter<any>) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.userManagement.searchParams.PagingOptions;
        // keep the old restrictions when we filter
        const restrictions = state.userManagement.searchParams.Restrictions.filter((r, index) => {
            if (index === 0) {
                return r;
            }
            if (r.Field === restriction.Field) {
                if (isEmpty(restriction.Value) && isEmpty(restriction.Values)) {
                    return false;
                }
                return !!restriction.Values || !!restriction.Value;
            }
            return !!r.Value || !!r.Values;
        }).map((r) => {
            if (r.Field === restriction.Field) {
                return restriction;
            }
            return r;
        });
        if (!!restriction && !restrictions.includes(restriction) && ((typeof restriction.Value === 'number' ? restriction.Value !== 0 : !isEmpty(restriction.Value)) || !isEmpty(restriction.Values))) {
            restrictions.push(restriction);
        }
        const searchParams: UserListSearchParams = {
            ...state.userManagement.searchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: restrictions,
            filters: {
                ...state.userManagement.searchParams.filters,
                [restriction.Field]: filter,
            },
        };
        dispatch(getUserList(searchParams));
    };
};

export const sortUserList = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            userManagement,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const sorting = userManagement.searchParams.SortItems[0];
        const sortingDirection = sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(listViewConfig, groupMember);

            if (!viewConfig.sortCol || viewConfig.sortCol !== columnName) {
                groupMember.UserSettings = setUserSettingValue(listViewConfig.sortCol, columnName, groupMember.UserSettings);
            }
            if (!viewConfig.sortDir || parseInt(viewConfig.sortDir, 10) !== sortingDirection) {
                groupMember.UserSettings = setUserSettingValue(listViewConfig.sortDir, sortingDirection, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: UserListSearchParams = {
            ...userManagement.searchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };
        dispatch(getUserList(searchParams));
    };
};

export const searchUserList = (searchString: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.userManagement.searchParams.PagingOptions;
        let searchRestriction = state.userManagement.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        const otherRestrictions = state.userManagement.searchParams.Restrictions.filter((restriction, index) => index !== 0);
        let restrictions = [];

        if (!isEmpty(searchString)) {
            if (!searchRestriction) {
                searchRestriction = {
                    Field: DEFAULT_RESTRICTION,
                    Value: searchString,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                };
            } else {
                searchRestriction.Value = searchString;
            }
            restrictions.push(searchRestriction);
        }

        if (!isEmpty(otherRestrictions)) {
            restrictions = [...restrictions, ...otherRestrictions];
        }
        const searchParams: UserListSearchParams = {
            ...state.userManagement.searchParams,
            PagingOptions: {
                ...paging,
                Page: 1,
            },
            Restrictions: [...restrictions],
        };
        dispatch(getUserList(searchParams));
    };
};

export const saveNewUser = (user: UserCreationDTO, saveAnother?: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const result = await api.user.addNewUser(user);
            if (result.data.Success) {
                const isSendEmail = !isEmpty(user.Email) || result.data.ExistingUserAddedToCompany; // need to initiate password setup or grant access email when possible
                if (isSendEmail) {
                    const country = localStorage.getItem(selectedCountryKey);
                    const language = localStorage.getItem(selectedLanguageKey);

                    let emailResult;
                    if (!result.data.ExistingUserAddedToCompany) {
                        emailResult = await api.boUsers.sendPasswordSetupEmail({
                            UserGuid: result.data.UserGuid,
                            Country: country,
                            Language: language,
                            CompanyName: getState().user?.currentCompany?.CompanyName,
                        });
                    } else {
                        emailResult = await api.boUsers.sendNewCompanyAccessGrantedEmail({ UserGuid: result.data.UserGuid, CompanyName: getState().user?.currentCompany?.CompanyName });
                    }

                    if (!emailResult.data.Success) {
                        notify.info(i18nInstance.t(emailResult.data.Message));
                    } else {
                        notify.success(i18nInstance.t('views.groupMember.detail.UserAdded'));
                    }
                } else {
                    notify.success(i18nInstance.t('views.groupMember.detail.UserAdded'));
                }
                if (!saveAnother) {
                    dispatch(push(resolvePathParams(paths.app.userDetails, { id: result.data.UserGuid })));
                }
            } else {
                // let's take first for user to fix
                notify.error(i18nInstance.t(result.data.Errors[0]));
            }
        } catch (e) {
            console.error(e);
        }
    };
};

export function exportUsersToCsv() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        if (isEmpty(getState().company.currentCompanyData) || getState().user?.currentCompany?.CompanyGuid !== getState().company.currentCompanyData?.CompanyGuid) {
            await dispatch(getCurrentCompany());
        }
        const {
            userManagement,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
            company: { currentCompanyData },
        } = getState();

        dispatch(exportUsersToCsvActions.request(undefined));

        let searchParams = selectUserListSearchParams(getState());

        searchParams = searchParams || userManagement.searchParams;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Email', SortDirection.Asc);

        searchParams = { ...searchParams, ...viewSearchParams };

        const apiSearchParams = { ...searchParams };
        delete apiSearchParams.filters;
        try {
            const response = await api.user.exportUsersToCsv(apiSearchParams);
            const companyCode = currentCompanyData?.VatCode || currentCompanyData?.RegistrationCode;
            const fileDate = formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss');
            const fileName = formatFileNameSpaces(`${currentCompanyData?.CompanyName}_${companyCode}_Users_${fileDate}.csv`);
            const file = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            saveAs(file, fileName);
            dispatch(exportUsersToCsvActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportUsersToCsvActions.error(e));
        }
    };
}

export function exportUsersToXls() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        if (isEmpty(getState().company.currentCompanyData) || getState().user?.currentCompany?.CompanyGuid !== getState().company.currentCompanyData?.CompanyGuid) {
            await dispatch(getCurrentCompany());
        }
        const {
            userManagement,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
            company: { currentCompanyData },
        } = getState();

        dispatch(exportUsersToXlsActions.request(undefined));

        let searchParams = selectUserListSearchParams(getState());

        searchParams = searchParams || userManagement.searchParams;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'Email', SortDirection.Asc);

        searchParams = { ...searchParams, ...viewSearchParams };

        const apiSearchParams = { ...searchParams };
        delete apiSearchParams.filters;
        try {
            const response = await api.user.exportUsersToXls(apiSearchParams);
            const companyCode = currentCompanyData?.VatCode || currentCompanyData?.RegistrationCode;
            const fileDate = formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss');
            const fileName = formatFileNameSpaces(`${currentCompanyData?.CompanyName}_${companyCode}_Users_${fileDate}.xlsx`);
            const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            saveAs(file, fileName);
            dispatch(exportUsersToXlsActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportUsersToXlsActions.error(e));
        }
    };
}
