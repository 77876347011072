import * as React from 'react';
import { MainMenuItem, MainMenuItemType } from './MainMenuItem';
import { isSystemSetting, Role } from '../../../common/user/userPermissionUtil';
import { BaseComponent } from '../../BaseComponent';
import { ICONS } from '../../Icon/Icon';
import MainMenuBody from './MainMenuBody';
import { MainMenuProps } from '../MainMenu';

export class MainMenuWebUI extends BaseComponent<MainMenuProps> {
    render() {
        const { t, currentUser, clearFilters } = this.props;
        return (
            <MainMenuBody {...this.props}>
                <MainMenuItem
                    onClick={() => {
                        clearFilters('/dashboard', '');
                    }}
                    href="#/dashboard"
                    icon={ICONS.MenuDashboard}
                    visibleForRole={Role.CanViewDashboard}
                    dataId="side-menu-link-dashboard"
                >
                    {t('views.home.index.Dashboard')}
                </MainMenuItem>
                {isSystemSetting('isInvoiceRegisterModulActive', currentUser) && (
                    <MainMenuItem
                        onClick={() => {
                            clearFilters('/invoices', null);
                        }}
                        href="#/invoices"
                        icon={ICONS.MenuPurchaseInvoices}
                        visibleForRole={Role.CanViewInvoiceRegister}
                        dataId="side-menu-link-invoice-register"
                    >
                        {t('views.home.index.Invoice_Register')}
                    </MainMenuItem>
                )}
                {isSystemSetting('IsPurchaseOrdersModulActive', currentUser) && (
                    <MainMenuItem href="#/purchase-orders" icon={ICONS.MenuPurchaseOrders} visibleForRole={Role.CanViewPurchaseOrders} dataId="side-menu-link-purchase-orders">
                        {t('views.home.index.PurchaseOrders')}
                    </MainMenuItem>
                )}
                {isSystemSetting('IsArchiveModulActive', currentUser) && (
                    <MainMenuItem
                        onClick={() => {
                            clearFilters('/archive', null);
                        }}
                        href="#/archive"
                        icon={ICONS.MenuArchive}
                        visibleForRole={Role.CanViewArchive}
                        dataId="side-menu-link-archive"
                    >
                        {t('views.home.index.Archive')}
                    </MainMenuItem>
                )}
                <MainMenuItem icon={ICONS.MenuAutomation} type={MainMenuItemType.WITH_SUBMENU} visibleForRole={Role.CanViewCompanySettings} dataId="side-menu-link-automation-toggle">
                    {t('views.home.index.Automation')}
                    <MainMenuItem href="#/automation" dataId="side-menu-link-automation">
                        {t('component.autoTransactions.autoTransactions')}
                    </MainMenuItem>
                    <MainMenuItem href="#/relateddimensions" dataId="side-menu-link-automation-related-dimensions">
                        {t('views.home.index.RelatedDimensions')}
                    </MainMenuItem>
                </MainMenuItem>
                <MainMenuItem icon={ICONS.MenuSettings} type={MainMenuItemType.WITH_SUBMENU} visibleForRole={Role.CanViewCompanySettings} dataId="side-menu-link-settings-toggle">
                    {t('views.home.index.Settings')}
                    <MainMenuItem href="#/company" dataId="side-menu-link-settings-company" visibleForRole={Role.CanViewSettingsCompany}>
                        {t('views.home.index.Company_Settings')}
                    </MainMenuItem>
                    <MainMenuItem href="#/suppliers" dataId="side-menu-link-settings-company-list">
                        {t('views.home.index.Suppliers')}
                    </MainMenuItem>
                    <MainMenuItem href="#/users" dataId="side-menu-link-settings-group-members" visibleForRole={Role.CanViewSettingsUsers}>
                        {t('views.home.index.Users')}
                    </MainMenuItem>
                    <MainMenuItem href="#/accounting" dataId="side-menu-link-settings-accounting">
                        {t('views.home.index.Accounting')}
                    </MainMenuItem>
                    <MainMenuItem href="#/workflows" dataId="side-menu-link-settings-workflow-definition" visibleForRole={Role.CanViewSettingsConfitmationFlows}>
                        {t('views.home.index.Workflows')}
                    </MainMenuItem>
                    <MainMenuItem visibleForRole={Role.CanViewInvoiceTypes} href="#/customfields" dataId="side-menu-link-settings-invoice-types">
                        {t('views.home.index.InvoiceTypes')}
                    </MainMenuItem>
                    <MainMenuItem visibleForRole={Role.CanViewPaymentMethods} href="#/paymentmethods" dataId="side-menu-link-payment-methods">
                        {t('views.home.index.PaymentMethods')}
                    </MainMenuItem>
                    {/* Hide until we rewrite all functionality of invoice custom fields
                    <MainMenuItem visibleForRole={Role.CanViewPaymentMethods} href="#/headerextensions" dataId="side-menu-link-payment-methods">
                        {t('views.home.index.HeaderExtensions')}
                    </MainMenuItem>*/}
                    <MainMenuItem href="#/invoicecustomfields" dataId="side-menu-link-settings-invoice-custom-fields">
                        {t('views.home.index.InvoiceCustomfields')}
                    </MainMenuItem>
                    {isSystemSetting('IsProductItemsModulActive', currentUser) && (
                        <MainMenuItem href="#/productitems" dataId="side-menu-link-settings-product-items">
                            {t('views.home.index.ProductItems')}
                        </MainMenuItem>
                    )}
                </MainMenuItem>
            </MainMenuBody>
        );
    }
}
