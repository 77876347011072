import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash-es';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable1, Dispatchable2 } from 'redux-dispatchers';

import useInfiniteScroll from '../../../../common/hooks/useInfiniteScroll';
import { LoadableData } from '../../../../common/utils/LoadableData';
import { ContentBlockBody } from '../../../../components/ContentBlock/ContentBlockBody';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { BackOfficeAssistantDTO } from '../../../../services/types/BoApiTypes';
import { createDataId } from '../../../../common/utils/dataId';
import { Button } from '../../../Buttons/Button';
import Checkbox from '../../../Checkbox/Checkbox';

import './ManageAssistants.scss';

interface CompaniesNotificationsProps {
    boUserGuid: string;
    getAssistantsForUser: Dispatchable1<string>;
    saveAssistantsForUser: Dispatchable2<string, BackOfficeAssistantDTO[]>;
    userAssistantsLoadable: LoadableData<BackOfficeAssistantDTO[]>;
    saveUserAssistantsLoadable: LoadableData<BackOfficeAssistantDTO[]>;
    dataId: string;
    modalContentNode: React.RefObject<any>;
}

const ITEMS_PER_PAGE = 22;

const ManageAssistants = ({
    boUserGuid,
    dataId,
    getAssistantsForUser,
    saveAssistantsForUser,
    saveUserAssistantsLoadable,
    userAssistantsLoadable,
    t,
    modalContentNode,
}: CompaniesNotificationsProps & WithTranslation) => {
    const [assistants, setAssistants] = useState<BackOfficeAssistantDTO[]>([]);
    const [activeAssistantsCount, setActiveAssistantsCount] = useState<number>();

    const [isUsingInfiniteScroll, setIsUsingInfiniteScroll] = useState<boolean>(true);
    const [currentAssistants, setCurrentAssistants] = useState<BackOfficeAssistantDTO[]>([]); // items on current page
    const [isPageItemsLoading, setIsPageItemsLoading] = useState<boolean>(false);

    const getActiveAssistants = () => {
        return assistants.filter((a) => a.IsAssistant);
    };

    const setCurrentSlice = (start: number, end: number) => {
        setIsPageItemsLoading(true);
        let ass: BackOfficeAssistantDTO[];
        setAssistants((a) => {
            // a trick to get current state, not initial (as is usually seen from an event handler)
            ass = a;
            return a;
        });
        const newAssistants = ass.slice(start, end);
        setCurrentAssistants(newAssistants);
        setIsPageItemsLoading(false);
    };

    const currentPage = useInfiniteScroll({ contentNode: isUsingInfiniteScroll ? modalContentNode : null, itemsPerPage: ITEMS_PER_PAGE, cbFunction: setCurrentSlice });

    useEffect(() => {
        if (!userAssistantsLoadable.loading) {
            getAssistantsForUser(boUserGuid);
        }
    }, []);

    // useEffect(() => {
    //     //TODO: implement comparison with prev data from BE after saving
    //     if (!userAssistantsLoadable.loading && !saveUserAssistantsLoadable.loading && saveUserAssistantsLoadable.payload) {
    //         if (!isEqual(saveUserAssistantsLoadable.payload, assistants)) {
    //             console.log('NOT EQUAL', saveUserAssistantsLoadable.payload, assistants);
    //             notify.error(t('component.userProfileAssistants.error'));
    //         } else {
    //             console.log('EQUAL', saveUserAssistantsLoadable.payload, assistants);
    //         }
    //     }
    // }, [saveUserAssistantsLoadable.payload]);

    useEffect(() => {
        if (userAssistantsLoadable.loaded && userAssistantsLoadable.payload) {
            const received = userAssistantsLoadable.payload;
            const notActive = received.filter((a) => !a.IsAssistant);

            const allNumber = received.length;
            const usingScroll = allNumber > 70; // don't use infinite scroll if all assistants length is less than 70
            const notActiveNumber = notActive.length;
            setIsUsingInfiniteScroll(usingScroll);
            setActiveAssistantsCount(allNumber - notActiveNumber);
            setAssistants(received);

            // TODO: sort active assistants to the top of the list
            // const resorted = [...getActiveAssistants(), ...notActive];
            // setAssistants(resorted.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE * 2));

            const current = usingScroll ? received.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE * 2) : received;
            setCurrentAssistants(current);
        }
    }, [userAssistantsLoadable.payload]);

    const isPartiallyChecked = (): boolean => {
        const activeAssistantsNumber = getActiveAssistants().length;
        return activeAssistantsNumber !== 0 && activeAssistantsNumber < assistants.length;
    };

    const onAllChange = () => {
        if (assistants.length !== activeAssistantsCount) {
            // check all
            setAssistants(assistants.map((a) => ({ ...a, IsAssistant: true })));
            setCurrentAssistants(currentAssistants.map((a) => ({ ...a, IsAssistant: true })));
            setActiveAssistantsCount(assistants.length);
        } else {
            // uncheck all
            setAssistants(assistants.map((a) => ({ ...a, IsAssistant: false })));
            setCurrentAssistants(currentAssistants.map((a) => ({ ...a, IsAssistant: false })));
            setActiveAssistantsCount(0);
        }
    };

    const toggleAssistant = (a: BackOfficeAssistantDTO) => {
        const assistantIdx = assistants.findIndex((as) => isEqual(as, a));
        const currAssistantIdx = currentAssistants.findIndex((as) => isEqual(as, a));
        const status = a.IsAssistant;
        a.IsAssistant = !status;
        let toggled = false;

        if (assistantIdx !== -1) {
            // toggling assistant in all assistants array
            assistants[assistantIdx] = a;
            setAssistants([...assistants]);
            toggled = true;
        }
        if (currAssistantIdx !== -1) {
            // toggling assistant in current page assistants array
            currentAssistants[currAssistantIdx] = a;
            setCurrentAssistants([...currentAssistants]);
            toggled = true;
        }
        toggled && setActiveAssistantsCount(status ? activeAssistantsCount - 1 : activeAssistantsCount + 1);
    };

    return (
        <ContentBlockBody dataId={createDataId(dataId, 'assistants')} loading={userAssistantsLoadable.loading || saveUserAssistantsLoadable.loading} className="block-body">
            <section className="checkbox-list" data-id={createDataId(dataId, 'assistants', 'assistants-section')}>
                <ul data-id={createDataId(dataId, 'assistants', 'assistants-list')}>
                    <li className="all-items-checkbox">
                        <Checkbox
                            name="all-assistants"
                            label={t('views.global.UserProfileModal.assistants')}
                            value={(assistants.length && getActiveAssistants().length === assistants.length) || isPartiallyChecked()}
                            partiallyChecked={isPartiallyChecked()}
                            data-id={createDataId(dataId, 'checkbox.all-assistants')}
                            onChange={onAllChange}
                        />
                    </li>
                    <ContentBlock className="assistants-loader" loading={isPageItemsLoading}></ContentBlock>
                    {currentAssistants.map((a) => {
                        return (
                            <li key={a.UserGuid} data-id={createDataId(dataId, 'assistants-list-item.assistant', a.UserGuid)}>
                                <Checkbox
                                    name={a.FullName}
                                    label={a.FullName}
                                    value={a.IsAssistant}
                                    onChange={() => toggleAssistant(a)}
                                    data-id={createDataId(dataId, 'checkbox.assistant', a.UserGuid)}
                                />
                            </li>
                        );
                    })}
                    <ContentBlock className="assistants-loader" loading={isPageItemsLoading}></ContentBlock>
                </ul>

                <div className="user-profile__footer" data-id={createDataId(dataId, 'footer')}>
                    <Button loading={false} onClick={() => saveAssistantsForUser(boUserGuid, assistants)} data-id={createDataId(dataId, 'button.save')}>
                        {t('views.global.UserProfileModal.Save')}
                    </Button>
                </div>
            </section>
        </ContentBlockBody>
    );
};

export default withTranslation()(ManageAssistants);
