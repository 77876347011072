import { ICONS } from '../../../../components/Icon/Icon';

export const getIsMobileOS = (osName: string): boolean => {
    if (!osName) {
        return true;
    }
    let isMobileOs = true;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    macosPlatforms.forEach((p) => {
        if (osName.indexOf(p) !== -1) {
            isMobileOs = false;
        }
    });
    windowsPlatforms.forEach((p) => {
        if (osName.indexOf(p) !== -1) {
            isMobileOs = false;
        }
    });
    if (/Linux/.test(osName)) {
        isMobileOs = false;
    }
    return isMobileOs;
};

export const isPlatformIOS = (osName: string): boolean => {
    if (!osName) {
        return false;
    }
    osName = osName.toLowerCase();
    const iosPlatforms = ['iphone', 'ipad', 'ipod'];
    for (const platform of iosPlatforms) {
        if (osName.includes(platform)) {
            return true;
        }
    }
    return false;
};

export const getBrowserIconName = (browserName: string, osName: string) => {
    if (isPlatformIOS(osName)) {
        return ICONS.APPLE;
    } else if (/Android/.test(osName)) {
        return ICONS.ANDROID;
    } else if (!browserName) {
        return ICONS.OTHER_BROWSER;
    } else if (browserName.toLowerCase().includes('chrome')) {
        return ICONS.CHROME;
    } else if (browserName.toLowerCase().includes('firefox')) {
        return ICONS.MOZILA_FIREFOX;
    } else if (browserName.toLowerCase().includes('safari')) {
        return ICONS.SAFARI;
    } else if (browserName.toLowerCase().includes('msie') || browserName.toLowerCase() === 'ie') {
        return ICONS.INTERNET_EXPLORER;
    } else if (browserName.toLowerCase().includes('edge')) {
        return ICONS.MICROSOFT_EDGE;
    } else if (browserName.toLowerCase().includes('opera')) {
        return ICONS.OPERA;
    } else {
        return ICONS.OTHER_BROWSER;
    }
};

export const getBrowserNameAndVersion = () => {
    const navUserAgent = navigator?.userAgent;
    let browserName = navigator?.appName;
    let browserVersion = '' + parseFloat(navigator?.appVersion);
    let tempNameOffset, tempVersionOffset, tempVersion;

    if (navUserAgent) {
        if ((tempVersionOffset = navUserAgent.indexOf('Opera')) !== -1) {
            browserName = 'Opera';
            browserVersion = navUserAgent.substring(tempVersionOffset + 6);
            if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1) {
                browserVersion = navUserAgent.substring(tempVersionOffset + 8);
            }
        } else if ((tempVersionOffset = navUserAgent.indexOf('OPR')) !== -1) {
            browserName = 'Opera';
            browserVersion = navUserAgent.substring(tempVersionOffset + 4);
        } else if ((tempVersionOffset = navUserAgent.indexOf('Edge')) !== -1) {
            browserName = 'Edge';
            browserVersion = navUserAgent.substring(tempVersionOffset + 4);
        } else if ((tempVersionOffset = navUserAgent.indexOf('Edg')) !== -1) {
            browserName = 'Edge';
            browserVersion = navUserAgent.substring(tempVersionOffset + 3);
        } else if ((tempVersionOffset = navUserAgent.indexOf('MSIE')) !== -1) {
            browserName = 'Microsoft Internet Explorer';
            browserVersion = navUserAgent.substring(tempVersionOffset + 5);
        } else if ((tempVersionOffset = navUserAgent.indexOf('Chrome')) !== -1) {
            browserName = 'Chrome';
            browserVersion = navUserAgent.substring(tempVersionOffset + 7);
        } else if ((tempVersionOffset = navUserAgent.indexOf('Safari')) !== -1) {
            browserName = 'Safari';
            browserVersion = navUserAgent.substring(tempVersionOffset + 7);
            if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1) {
                browserVersion = navUserAgent.substring(tempVersionOffset + 8);
            }
        } else if ((tempVersionOffset = navUserAgent.indexOf('Firefox')) !== -1) {
            browserName = 'Firefox';
            browserVersion = navUserAgent.substring(tempVersionOffset + 8);
        } else if ((tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) < (tempVersionOffset = navUserAgent.lastIndexOf('/'))) {
            browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
            browserVersion = navUserAgent.substring(tempVersionOffset + 1);
            if (browserName.toLowerCase() === browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }

        // trim version
        if ((tempVersion = browserVersion.indexOf(';')) !== -1) {
            browserVersion = browserVersion.substring(0, tempVersion);
        }
        if ((tempVersion = browserVersion.indexOf(' ')) !== -1) {
            browserVersion = browserVersion.substring(0, tempVersion);
        }
    }

    return [browserName, browserVersion];
};
