import React, { ReactNode, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Icon, { ICONS } from '../Icon/Icon';
import { TextInput, TextInputType } from '../TextInput/TextInput';
import withSuspense from '../../common/hocs/withSuspense';
import { Button, ButtonType } from '../Buttons/Button';
import { createDataId } from '../../common/utils/dataId';
import { DateRangeValues } from '../../components/CalendarDateRangeModal/CalendarDateRangeModal';
import { SelectOptionValue } from '../Select/SelectOption';
import DateRangeFilter from './DateRangeFilter';

export interface ContentBlockSearchProps extends WithTranslation {
    autofocus?: boolean;
    className?: string;
    dataId: string;
    disabled?: boolean;
    noHeading?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onClick?: (value: string) => void;
    onFiltersClick?: () => void;
    hasActiveFilters?: boolean;
    hasDateFilter?: boolean;
    quickFilterMenu?: ReactNode;
    quickFilterMenuBeforeSearch?: boolean;
    quickFilterLabel?: string;
    dateRange?: [Date, Date];
    datePickerActiveScope?: string;
    datePickerScopeList?: SelectOptionValue<string>[];
    datePickerLabel?: string;
    onDateRangeChange?: (val: DateRangeValues) => void;
    value?: string;
    withSearchButton?: boolean;
    withFilterButton?: boolean;
    withoutSearchIcon?: boolean;
}

const ContentBlockSearch: React.FunctionComponent<ContentBlockSearchProps> = ({
    autofocus,
    className,
    dataId,
    value,
    onChange,
    t,
    disabled,
    hasActiveFilters,
    hasDateFilter,
    dateRange,
    datePickerActiveScope,
    datePickerScopeList,
    datePickerLabel,
    onDateRangeChange,
    noHeading,
    quickFilterMenu,
    quickFilterMenuBeforeSearch,
    quickFilterLabel,
    withSearchButton,
    withFilterButton,
    withoutSearchIcon,
    onClick,
    onFiltersClick,
}: ContentBlockSearchProps) => {
    const [searchVal, setSearchVal] = useState<string>();

    useEffect(() => {
        value !== searchVal && setSearchVal(value);
    }, [value]);

    return withSearchButton || withFilterButton ? (
        <div className={`content-block__searchWithBtn ${className || ''}`}>
            {quickFilterMenuBeforeSearch && quickFilterMenu && (
                <div className="named-block">
                    <h4>{quickFilterLabel}</h4>
                    {quickFilterMenu}
                </div>
            )}
            <div className="named-block named-block--main">
                {!noHeading && <h4>{t('views.global.Search')}</h4>}
                <div className="named-block__subblock">
                    {!!withFilterButton && (
                        <Button
                            className="filter-button"
                            dataId={createDataId(dataId, 'filter-button')}
                            buttonType={ButtonType.ICON}
                            onClick={onFiltersClick}
                            icon={hasActiveFilters ? ICONS.FILTER_LINES_ACTIVE : ICONS.FILTER_LINES}
                        />
                    )}
                    <TextInput
                        placeholder={t('component.ContentBlock.SearchInput.PlaceHolder')}
                        dataId={createDataId(dataId, 'search-input')}
                        autofocus={autofocus}
                        value={value}
                        onChange={(e) => {
                            !withFilterButton && onChange && onChange(e);
                            setSearchVal(e.target.value);
                        }}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === 'Enter') {
                                onClick(searchVal);
                            }
                        }}
                        type={!withoutSearchIcon ? TextInputType.SEARCH_COMPACT : TextInputType.COMPACT}
                        showClear={true}
                        disabled={disabled}
                    />
                </div>
            </div>
            {hasDateFilter && (
                <DateRangeFilter
                    dataId={createDataId(dataId, 'date-range')}
                    label={datePickerLabel}
                    datePickerActiveScope={datePickerActiveScope}
                    datePickerScopeList={datePickerScopeList}
                    dateRange={dateRange}
                    onChange={onDateRangeChange}
                />
            )}

            {!quickFilterMenuBeforeSearch && quickFilterMenu && (
                <div className="named-block">
                    <h4>{quickFilterLabel}</h4>
                    {quickFilterMenu}
                </div>
            )}
            {!!withSearchButton && (
                <Button buttonType={ButtonType.PRIMARY} onClick={() => onClick(searchVal)} dataId={`${dataId}-btn`}>
                    <Icon iconName={ICONS.SEARCH} />
                    <span>{t('views.global.Search')}</span>
                </Button>
            )}
        </div>
    ) : (
        <div className="content-block__search">
            <TextInput
                autofocus={autofocus}
                label={t('views.global.Search')}
                dataId={dataId}
                placeholder={t('views.global.Search')}
                value={searchVal}
                onChange={onChange}
                icon={withoutSearchIcon ? null : ICONS.SEARCH}
                type={TextInputType.SEARCH_COMPACT}
                showClear={true}
                disabled={disabled}
            />
        </div>
    );
};

export default withSuspense(withTranslation()(ContentBlockSearch));
