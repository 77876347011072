import classNames from 'classnames';
import { useEffect, useState } from 'react';
import * as React from 'react';
import ReactModal from 'react-modal';

import { ContentBlockHeader, ContentBlockHeaderType } from '../ContentBlock/ContentBlockHeader';
import { createDataId } from '../../common/utils/dataId';
import { Button, ButtonProps, ButtonType } from '../Buttons/Button';
import { IconSize, ICONS } from '../Icon/Icon';

import './Modal.scss';

export interface ModalProps {
    children?: React.ReactNode;
    onClose?: VoidFunction;
    thinCloseButton?: boolean;
    hideCloseButton?: boolean;
    isOpen: boolean;
    width?: ModalWidth;
    type?: ModalType;
    className?: string;
    dataId: string;
    noCloseButton?: boolean;
    footerCheckBox?: React.ReactNode;
    setRef?: (n: any) => void;
    onSubmitClick?: () => void;
    fullScreenElementId?: string;
}
/*
 * Conditional props ensure that either header component or
 * headerText is provided (but not both)
 */
type ConditionalScrollableModalProps =
    | {
          headerText: string;
          header?: undefined;
      }
    | {
          headerText?: undefined;
          header: React.ReactNode;
      };

type RequiredPropsForScrollableModal = {
    footerButtonText: string;
    type: ModalType.FIXED_RIGHT_MIDDLE_SCROLL;
    hasBackButton?: boolean;
};

type NestedElementsProps = {
    submitButtonProps?: ButtonProps;
};

export type ModalScrollableFormProps = ModalProps & RequiredPropsForScrollableModal & ConditionalScrollableModalProps & NestedElementsProps;

export enum ModalType {
    DEFAULT = 'DEFAULT', // small popup in the middle of document
    FIXED_RIGHT = 'FIXED-RIGHT', // full-height popup sticked to the right side
    FIXED_RIGHT_MIDDLE_SCROLL = 'FIXED-RIGHT-MIDDLE-SCROLL', // fixed-right with title and footer fixed, while body is scrollable on overflow
}

export enum ModalWidth {
    XS = 'XS',
    SM = 'SM',
    MD = 'MD',
    LG = 'LG',
    XL = 'XL',
}

const getCloseButtonElement = (props: ModalProps) => (
    <Button
        className={classNames('dialog__close', props.thinCloseButton ? 'dialog__close--thin' : '')}
        iconSize={IconSize.MD}
        buttonType={ButtonType.ICON}
        icon={[ModalType.FIXED_RIGHT, ModalType.FIXED_RIGHT_MIDDLE_SCROLL].includes(props.type) || props.thinCloseButton ? ICONS.CLOSE_24 : ICONS.CLOSE_SMALL}
        onClick={props.onClose}
        dataId={createDataId('dialog', 'closeButton')}
    />
);

const Modal: React.FunctionComponent<ModalProps> = (props) => {
    const classes = classNames('dialog', `dialog--${props.width.toLowerCase()}`, `dialog--${props.type.toLowerCase()}`, props.className);

    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const root = document.documentElement.getElementsByTagName('body')[0];
        const documentWidth = root.clientWidth;
        const windowWidth = window.innerWidth;
        const scrollBarWidth = windowWidth - documentWidth;
        if (props.isOpen) {
            // save scrollX, scrollY so we can restore it when we re-show the scrollbar
            setScrollY(window.pageYOffset || document.documentElement.scrollTop);
            setScrollX(window.pageXOffset || document.documentElement.scrollLeft);
            // disable page scrolling and add padding to the right to prevent page jumping when modal opens (because scrollbar will be hidden)
            root.style.paddingRight = scrollBarWidth + 'px';
            root.style.overflow = 'hidden';
        }
    }, [props.isOpen]);

    return (
        <ReactModal
            contentRef={(node) => props.setRef && props.setRef(node)}
            ariaHideApp={false}
            closeTimeoutMS={200}
            onAfterClose={() => {
                // restore scrolling and previous scroll position
                const root = document.documentElement.getElementsByTagName('body')[0];
                root.style.paddingRight = 0 + 'px';
                root.style.overflow = 'inherit';
                window.scrollTo(scrollX, scrollY);
            }}
            isOpen={props.isOpen}
            onRequestClose={props.onClose}
            className={classes}
            overlayClassName={`dialog-overlay dialog-overlay--${props.type.toLowerCase()}`}
            testId={props.dataId}
            parentSelector={props.fullScreenElementId ? () => document.getElementById(props.fullScreenElementId) : () => document.documentElement.getElementsByTagName('body')[0]}
        >
            {props.type === ModalType.FIXED_RIGHT_MIDDLE_SCROLL || props.noCloseButton ? (
                props.children
            ) : (
                <>
                    {!props.hideCloseButton && getCloseButtonElement(props)}
                    {props.children}
                </>
            )}
        </ReactModal>
    );
};

ReactModal.setAppElement(document.getElementById('root'));

export const ModalScrollableFormWithFixedHeaderAndSubmit: React.FunctionComponent<ModalScrollableFormProps> = (props) => {
    const { header, headerText, hasBackButton, footerButtonText, onSubmitClick, submitButtonProps = {}, ...rest } = props;
    const [contentRef, setContentRef] = useState<HTMLDivElement>();

    useEffect(() => {
        if (contentRef) {
            // a trick to submit the form from outside Button element
            // this must be easier to achieve with Formik 2.0 update
            const formElements = contentRef.getElementsByTagName('form');
            if (formElements.length) {
                const btn = document.createElement('button');
                btn.id = `${props.dataId}.button-submit-form`;
                btn.type = 'submit';
                btn.style.visibility = 'hidden';
                formElements[0].appendChild(btn);
            }
        }
    }, [contentRef]);
    const submitForm = () => {
        document.getElementById(`${props.dataId}.button-submit-form`)?.click();
    };

    return (
        <Modal {...rest} setRef={setContentRef}>
            <ContentBlockHeader className="modal-header" type={ContentBlockHeaderType.COMPACT} hasBackButton={hasBackButton}>
                {header ? header : <h2>{headerText}</h2>}
                {getCloseButtonElement(rest)}
            </ContentBlockHeader>

            <div className="scroll-content">{props.children}</div>
            <div className="static-footer">
                {props.footerCheckBox}
                <Button onClick={onSubmitClick ? onSubmitClick : submitForm} className={`${props.className}__button`} dataId={createDataId(props.dataId, 'saveButton')} {...submitButtonProps}>
                    {footerButtonText}
                </Button>
            </div>
        </Modal>
    );
};

export default Modal;
Modal.defaultProps = {
    width: ModalWidth.MD,
    type: ModalType.DEFAULT,
};
