import { cloneDeep, isEmpty } from 'lodash-es';
import { Role } from '../../../src/common/user/userPermissionUtil';
import * as IBAN from 'iban';
import { showAddOrEditSupplierModal } from "../../../src/components/EditSupplierModal/EditSupplierModalActions";
import { setSliderInvoices } from "../../../src/views/invoice-details/components/invoice-slider/InvoiceSliderActions";
import { paths } from "../../../src/common/router/routePaths";
import { setCurrentCompanySettingsLoading, setCurrentCompanySettings} from "../../../src/common/company/CompanyActions";
import { CompanySettingStatus } from '../../../src/views/settings/company-settings/components/Settings/CompanySettingsListHelper';

"use strict";

angular
  .module("dstreamApp.components")
  .component("dsInvoiceInformation", {
    templateUrl: "app/components/invoiceInformation/ds-invoice-information.html",
    controller: DsInvoiceInformationController,
    bindings: {
      invoice: "=", // since we reassign a copy of invoice in case we cancel editing, use two-way binding so that other components will know that we are not editing anymore
      invoiceId: "<",
      readonly: "<",
      initiallyVisible: "<"
    }
  });

/* @ngInject */
function DsInvoiceInformationController (
  $rootScope,
  $scope,
  $routeParams,
  $timeout,
  $location,
  webServices,
  utils,
  constants,
  invoiceService,
  companyDataService,
  authenticationService,
  $filter,
  activeInvoiceService,
  $ngRedux,
  location,
  $uibModal,
) {
  var ctrl = this;
  $rootScope.isNewInvoice = !$routeParams.id;
  ctrl.authenticationService = authenticationService;
  ctrl.isNewInvoice = !$routeParams.id;
  ctrl.visible = false;
  ctrl.invoiceCopy = cloneDeep(ctrl.invoice);
  ctrl.buyer = "";
  ctrl.showValidation = false;
  ctrl.dateOptions = {
    showWeeks: false,
    startingDay: 1
  };
  ctrl.DATE_EE_FORMAT = constants.DATE_EE_FORMAT;
  ctrl.DATE_EE_FORMAT_TIME_ONLY = constants.DATE_EE_FORMAT_TIME_ONLY;

  ctrl.isNewSupplierVisible = false;

  ctrl.currencyList = [];

  ctrl.validInvoiceDate = true;
  ctrl.validAccountingDate = true;
  ctrl.validDueDate = true;

  ctrl.validIban = true;
  ctrl.validSumWithVat = true;

  ctrl.invoiceDateOpen = false;
  ctrl.accountingDateOpen = false;
  ctrl.dueDateOpen = false;
  ctrl.invoiceLoading = true;

  ctrl.canSaveInvoice = $scope.$parent.CanSaveInvoice;
  ctrl.CanAddInvoice = $scope.$parent.CanAddInvoice;
  ctrl.canUpdateInvoiceHeader = $scope.$parent.canUpdateInvoiceHeader;
  ctrl.canEditInvoiceSupplier = canEditInvoiceSupplier;

  ctrl.canChangeInvoiceStatus = $scope.$parent.CanChangeInvoiceStatus;
  ctrl.canDeleteInvoice = $scope.$parent.CanDeleteInvoice;
  ctrl.reloadInvoiceData = $scope.$parent.reloadInvoiceData;
  ctrl.isInvoiceDuplicated = false;

  ctrl.notNumber = $scope.$parent.notNumber;
  ctrl.headerHistoryOpened = false;
  ctrl.headerHistory = {};
  ctrl.fields = [];

  var invoiceLoadedEvent = $rootScope.$on("invoiceLoaded", function (evt, invoice) {
    if (invoice) {
      ctrl.invoice = invoice;
      ctrl.invoice.editing = false;
    }
    ctrl.invoiceLoading = false;
    ctrl.headerHistoryOpened = false;
    ctrl.headerHistory = {};
    if (ctrl.isNewInvoice) {
      ctrl.showInvoiceEdit();
    }
  });

  var invoiceLoadingEvent = $rootScope.$on("invoiceLoading", function (evt) {
    ctrl.invoiceLoading = true;
  });

  var activeInvoiceLoadingEvent = $rootScope.$on("activeInvoiceLoading", function (evt) {
    ctrl.invoiceLoading = true;
  });

  var additionalInvoiceLoadingEvent = $rootScope.$on("additionalInvoiceLoading", function () {
    ctrl.invoiceLoading = true;
    ctrl.invoice.editing = false;
  });

  var additionalInvoiceLoadedEvent = $rootScope.$on("additionalInvoiceLoaded", function () {
    ctrl.invoiceLoading = false;
  });

  const relatedDocumentEventWatcher = $rootScope.$on('invoice.newRelatedDocument', (evt, data) => {
    ctrl.invoice.DocumentFiles = data;
  });

  const invoiceMovedEvent = $rootScope.$on('invoiceMoved', function(e, invoice) {
      const {
          sliderInvoiceData: {
              invoiceSliderDataLoadable: {
                  payload: {
                    Items: sliderInvoices,
                  }
              },
          },
      } = $ngRedux.getState();

      if (sliderInvoices && sliderInvoices.length < 2) {
          const modalInstance = $uibModal.open({
              templateUrl: 'Views/Home/Alert.html',
              controller: 'alertController',
              resolve: {
                  question: function() {
                      return $filter('translate')('views.invoice.partials.invoiceInformation.Invoice_Moved');
                  },
                  alertConfirmText: function() {
                      return $filter('translate')('views.invoice.partials.invoiceInformation.RedirectToRegister');
                  },
              },
          });
          modalInstance.result.then(function() {
              $location.path(paths.app.invoiceRegister);
              return;
          });
      } else {
          // When the invoice was successfully moved to subsidiary company, also remove it from slider and display next invoice
          let currentSliderIndex = sliderInvoices.findIndex(sliderInvoice => sliderInvoice.Id === invoice.Id);
          sliderInvoices.splice(currentSliderIndex, 1);
          $ngRedux.dispatch(setSliderInvoices(sliderInvoices));
          if (currentSliderIndex === -1) {
              currentSliderIndex = 0;
          }
          if (currentSliderIndex === sliderInvoices.length) {
              currentSliderIndex = sliderInvoices.length - 1;
          }
          location.path(paths.app.newInvoice + '/' + sliderInvoices[currentSliderIndex].Id).replace();
          ctrl.invoiceLoading = false;
      }
  });

  function getDocumentTypeSetting(settings) {
    var setting = settings.filter(function (item) {
      return item.Name === "DocumentTypes";
    });
    if (setting.length > 0) {
      if (!setting[0].Value) { return; }
      ctrl.fields = setting[0].Value.split(";");
      ctrl.fields = ctrl.fields.map(function (item) {
        return item.split(",");
      });
    }
  }

  function getIsNewSupplierVisible(settings) {
    var companySetting = _.find(settings, function (setting) { return setting.Name === "IsExportWithUnregisteredSupplierDenied"; });
    if (companySetting) { ctrl.isNewSupplierVisible = companySetting.Value === CompanySettingStatus.Enabled; } else { ctrl.isNewSupplierVisible = false; }
  }

  ctrl.getSuppliersByName = function (namePart) {
    if (ctrl.debounce) {
      $timeout.cancel(ctrl.debounce);
    }
    ctrl.debounce = $timeout(function () {
      namePart = namePart || "";
      var searchParams = {
        "SortItems": [
          {
            "SortColumn": "Name",
            "SortDirection": 0
          }
        ],
        "PagingOptions": {
          "Count": 25,
          "Page": 1
        },
        "Restrictions": [
          {
            "Field": "Name",
            "Value": namePart,
            "FieldSearchType": 0
          }
        ]
      };
      var supplierList = webServices.getSuppliers(searchParams).then(
          function (response) {
            ctrl.suppliers = response.data.Items;
            return getSuppliersByName(namePart, response.data.Items);
          }, function (data) {
            console.log(data);
          });
      return supplierList;
    }, 200);
    return ctrl.debounce;
  };

  function getSuppliersByName(namePart, list) {
    var unusedCompanies = [];
    if (_.find(list, function (sup) {
      return sup.Name === namePart;
    })) {
      unusedCompanies = cloneDeep(list);
    } else {
      unusedCompanies = _.filter(list, function (sup) {
        var regex = new RegExp((namePart || "").toLowerCase());
        return regex.test(sup.Name.toLowerCase());
      });
    }
    unusedCompanies.push({
      Id: 0,
      Name: $filter("translate")("controller.invoiceConfirmationController.Add_new")
    });
    return unusedCompanies;
  }

  ctrl.focusInput = function ($event) {
    setTimeout(function () {
      var input = angular.element($event.currentTarget).parent().find("input");
      if (input[0].name === "customlist") input.focus();
      else input.triggerHandler("click");
    });
  };

  ctrl.getDocumentType = function (name) {
    var results = [];
    var regex = new RegExp((name || '').toLowerCase());
    if (_.find(ctrl.fields, function (f) {
        return f[1] === name;
    })) {
        return ctrl.fields;
    }
    for (var i = 0; i < ctrl.fields.length; i++) {
      if (regex.test(ctrl.fields[i][1])) { results.push(ctrl.fields[i]); }
    }
    return _.filter(ctrl.fields, function (f) {
        return regex.test(f[1].toLowerCase());
    });
  };

  ctrl.selectDocumentType = function (field) {
    angular.forEach(ctrl.fields, function (item) {
      if (item[0] === field[0]) {
        ctrl.invoice.DocumentType = item;
      }
    });
    return ctrl.invoice.DocumentType;
  };

  ctrl.newInvoice = function () {
    var invoiceOriginal = ctrl.invoice;
      ctrl.originalInvoiceId = invoiceOriginal.Id;
      ctrl.isNewInvoice = true;
      ctrl.invoice = {
        IsNew : true,
        PayToAccount : invoiceOriginal.PayToAccount,
        SupplierName : invoiceOriginal.SupplierName,
        SupplierRegCode : invoiceOriginal.SupplierRegCode,
        SupplierAddress : invoiceOriginal.SupplierAddress,
        SupplierRepresentative : invoiceOriginal.SupplierRepresentative,
        SupplierEmail : invoiceOriginal.SupplierEmail,
        SupplierVatCodeId : invoiceOriginal.SupplierVatCodeId,
        SupplierCode : invoiceOriginal.SupplierCode,
        ReferenceNumber : invoiceOriginal.ReferenceNumber,
        TotalAmountWithVat : invoiceOriginal.TotalAmountWithVat,
        SumWithoutVat : invoiceOriginal.SumWithoutVat,
        Vat : invoiceOriginal.Vat,
        InvoiceType : invoiceOriginal.InvoiceType,
        IsCredit : invoiceOriginal.IsCredit,
        IsDuplicate : invoiceOriginal.IsDuplicate,
        Description : invoiceOriginal.Description,
        NextManagerName : invoiceOriginal.NextManagerName,
        VatCodeId : invoiceOriginal.VatCodeId,
        SupplierId : invoiceOriginal.SupplierId,
        Currency : invoiceOriginal.Currency,
        Beneficiary : invoiceOriginal.Beneficiary,
        PurchaseOrder: invoiceOriginal.PurchaseOrder,
        ContractNumber : invoiceOriginal.ContractNumber,
        Supplier : invoiceOriginal.Supplier,
        DocumentType : invoiceOriginal.DocumentType,
        Company: invoiceOriginal.Company,
        InvoiceCustomization: invoiceOriginal.InvoiceCustomization,
        DocumentFiles : [],
        editing : true,
      };

      $scope.$parent.isNewInvoice = true;
      //        $scope.$parent.invoice = ctrl.invoice;
      //        $scope.$parent.processInvoiceData(ctrl.invoice);

      ctrl.visible = true;
      $rootScope.isNewInvoice = true;

      $rootScope.$emit("newInvoice", true);
  };

  ctrl.openDatepicker = function (type) {
    ctrl.invoiceDateOpen = false;
    ctrl.accountingDateOpen = false;
    ctrl.dueDateOpen = false;

    switch (type) {
      case "invoice":
        ctrl.invoiceDateOpen = true;
        break;
      case "accounting":
        ctrl.accountingDateOpen = true;
        break;
      case "due":
        ctrl.dueDateOpen = true;
        break;
      default:
        break;
    }
  };

  ctrl.openDatepickerForCustomField = function (field) {
    field.datePickerOpen = true;
  };

  ctrl.getInvoiceCustomFieldItems = function (field, customization) {
    let customFieldItemPart;
    if (isEmpty(field) || field.split(' - ').length > 1) { // split to check if it is a compound value of 'Name - Code' and search by any
      customFieldItemPart = '';
    } else {
      customFieldItemPart = field;
    }

    const searchParams = {
      customizationId: customization.Id,
      page: 1,
      pagesize: 20,
      customFieldItemPart,
    }
    return webServices.getAvailableCustomizationFields(searchParams).then(function (result) {
      customization.InvoiceCustomFieldItems = result.data[0].Customization.InvoiceCustomFieldItems;
      return customization.InvoiceCustomFieldItems;
    }).catch(function (error) {
      console.error(error);
    });
  }
  
  ctrl.getCutomValueName = function (customization) {
    if (!customization.Customization.InvoiceCustomFieldItems) { return []; }
    const customFields = customization.Customization.InvoiceCustomFieldItems;
    if (customFields && customization.Value) {
      var result = customFields.filter(function (item) {
        return item.Code === customization.Value;
      });
      if (result.length > 0) { 
        return result[0].Code;
      } else { 
        return customization.Value; 
      }
    }
    return "";
  };

  ctrl.selectListValue = function (field, customization) {
    customization.Value = field.Code;
    customization.Customization.Code = field.Code;
  };

  ctrl.validateCustomDates = function (customization) {
    customization.validDateTime = utils.isDate(customization.Value);
  };

  $scope.isEmpty = function (item) {
    return (item === null || item === undefined || item.length === 0);
  };

  ctrl.validateDates = function (type) {
    if (utils.isEmpty(type)) {
      // if TYPE is empty then we just check for empty/filled (usually checks on save)
      if (utils.isEmpty(ctrl.invoice.InvoiceDate)) { ctrl.validInvoiceDate = false; }
      if (utils.isEmpty(ctrl.invoice.AccountingDate)) { ctrl.validAccountingDate = false; }
      if (utils.isEmpty(ctrl.invoice.DueDate)) { ctrl.validDueDate = false; }
      return ctrl.validInvoiceDate && ctrl.validAccountingDate && ctrl.validDueDate;
    }

    // check if new value is date
    var isValidDate = false;
    switch (type) {
      case "invoice":
        isValidDate = utils.isDate(ctrl.invoice.InvoiceDate);
        ctrl.validInvoiceDate = isValidDate;
        break;
      case "accounting":
        isValidDate = utils.isDate(ctrl.invoice.AccountingDate);
        ctrl.validAccountingDate = isValidDate;
        break;
      case "due":
        isValidDate = utils.isDate(ctrl.invoice.DueDate);
        ctrl.validDueDate = isValidDate;
        break;
      default:
        break;
    }
    if (!isValidDate) {
      return false;
    }

    // to work with dates, let's create local objects
    var localInvoiceDate = utils.isDate(ctrl.invoice.InvoiceDate) ? new Date(ctrl.invoice.InvoiceDate) : undefined;
    var localAccountingDate = utils.isDate(ctrl.invoice.AccountingDate) ? new Date(ctrl.invoice.AccountingDate) : undefined;
    var localDueDate = utils.isDate(ctrl.invoice.DueDate) ? new Date(ctrl.invoice.DueDate) : undefined;

    if (type === "invoice") {
      // check InvoiceDate
      var isInvoiceDateOk = true;
      if (localDueDate) {
        if (localInvoiceDate > localDueDate) {
          isInvoiceDateOk = false;
        }
      }
      ctrl.validInvoiceDate = isInvoiceDateOk;
    } else if (type === "accounting") {
      // check AccountingDate
      var isAccountingDateOk = true;
      // if (localInvoiceDate) {
      //    if (localAccountingDate < localInvoiceDate) {
      //        isAccountingDateOk = false;
      //    }
      // }
      ctrl.validAccountingDate = isAccountingDateOk;
    } else if (type === "due") {
      // check DueDate
      var isDueDateOk = true;
      if (localInvoiceDate) {
        if (localDueDate < localInvoiceDate) {
          isDueDateOk = false;
        }
      }
      ctrl.validDueDate = isDueDateOk;
    }

    return ctrl.validInvoiceDate && ctrl.validAccountingDate && ctrl.validDueDate;
  };

  ctrl.toggleComponent = function () {
    ctrl.visible = !ctrl.visible;
    if (!Array.isArray(ctrl.invoice.DocumentType)) {
      ctrl.invoice.DocumentType = ctrl.fields.filter(function (item) {
        return item[0] === ctrl.invoice.DocumentType;
      })[0];
    }
  };

  /*
        Open invoice header editing box
     */
  ctrl.showInvoiceEdit = function () {
    ctrl.invoiceCopy = cloneDeep(ctrl.invoice); // set temporary copy of invoice object
    ctrl.showValidation = false;
    ctrl.visible = true;
    ctrl.invoice.editing = true;
  };

  ctrl.cancelInvoiceEdit = function () {
    ctrl.visible = true;
    ctrl.invoice = ctrl.invoiceCopy;
    ctrl.invoice.editing = false;
  };

  ctrl.createSumsAreDifferentPopover = {
    template: "sums-are-different-popover-template.html",
    Open: false,
    quickConfirm: function () {
    },
    cancel: function () {
      closeAll();
      ctrl.cancelInvoiceEdit();
    },
    confirm: function () {
      closeAll();
      $timeout(function () {
        ctrl.updateInvoice(true);
      }).then(function () {
        ctrl.invoice.TransactionRows = [];
      });
    },
    enabled: function () {
      return ctrl.invoice.InvoiceRowsCount > 0 && (Number(ctrl.invoice.SumWithoutVat) !== Number(ctrl.invoice.InvoiceRowsPrice) ||
        Number(ctrl.invoice.Vat) !== Number(ctrl.invoice.InvoiceRowsVAT) ||
        Number(ctrl.invoice.TotalAmountWithVat) !== Number(ctrl.invoice.InvoiceRowsTotal));
    }
  };

  function closeAll () {
    ctrl.createSumsAreDifferentPopover.Open = false;
  }

  ctrl.updateInvoice = function (skipCheck) {
    ctrl.isInvoiceDuplicated = false;
    if (ctrl.invoice.InvoiceRowsCount > 0 &&
      !skipCheck && (
        Number(ctrl.invoice.SumWithoutVat) !== Number(ctrl.invoice.InvoiceRowsPrice.toFixed(2)) ||
        Number(ctrl.invoice.Vat) !== Number(ctrl.invoice.InvoiceRowsVAT.toFixed(2)) ||
        Number(ctrl.invoice.TotalAmountWithVat) !== Number(ctrl.invoice.InvoiceRowsTotal.toFixed(2))
      )
    ) {
      ctrl.createSumsAreDifferentPopover.Open = true;
      return;
    }

    $timeout(function () {
      ctrl.isDuplicateTooltipOpen = false;
    });

    if (validateForm()) {
      if (skipCheck) {
        ctrl.invoice.DeleteTransactionRowsDuringSave = true;
      }
      var pdfIFrame = document.getElementsByClassName("pdfIframe");
      if (!pdfIFrame.length) {
        checkIsDuplicateAndSave();
        return
      }
      var documentInIFrame = pdfIFrame[0].contentWindow.document;
      var filesElement = documentInIFrame.getElementById("fileInput");
      if (filesElement && filesElement.files.length > 0 && $rootScope.isPDFVisible) {
        readFileContent(documentInIFrame.getElementById("fileInput").files[0]);
      } else {
        checkIsDuplicateAndSave();
      }
    }
  };

  ctrl.getInvoiceStatusTranslateKey = constants.getInvoiceStatusTranslateKey;

  ctrl.showSupplierDetails = function () {
    return !!((ctrl.invoice.SupplierId && ctrl.invoice.SupplierName) ||
      ctrl.invoice.SupplierRepresentative ||
      ctrl.invoice.SupplierEmail ||
      ctrl.invoice.SupplierAddress);
  };
  ctrl.showBuyerDetails = function () {
    var contactPersonName = ctrl.invoice.Supplier ? ctrl.invoice.Supplier.ContactPersonName : null;
    var email = ctrl.invoice.Supplier ? ctrl.invoice.Supplier.Email : null;
    return !!(ctrl.invoice.Supplier.Address || contactPersonName || email);
  };

  ctrl.selectBuyer = function (item) {
      if (!ctrl.invoice.Company) {
          ctrl.invoice.Company = {};
    }
    ctrl.invoice.Company.Name = item.Name;
    ctrl.invoice.Company.Address = item.Address;
    ctrl.invoice.Company.RegistrationCode = item.RegistrationCode;
    ctrl.invoice.CompanyId = item.Id;
  };
  ctrl.selectSupplier = function (item) {
    $scope.$parent.updateSupplier(item);
    $scope.$parent.checkSupplier(ctrl.invoice);
  };
  ctrl.selectBeneficiary = function (item) {
    if (item && item.Id !== 0) {
      ctrl.invoice.Beneficiary = item.Name;
      $scope.$parent.BeneficiaryId = item.Id;
      $scope.$parent.BeneficiaryRegCode = item.RegistrationCode;
    } else {
      ctrl.invoice.Beneficiary = null;
      $scope.$parent.BeneficiaryId = null;
      $scope.$parent.BeneficiaryRegCode = null;
      if (item && item.Id === 0) {
        $scope.$parent.checkBeneficiary(ctrl.invoice);
      }
    }
  };

  ctrl.getAuditActionNameByLetter = function (char) {
    if (char === "I") {
      return "component.transactionRows.created";
    } else if (char === "U") {
      return "component.transactionRows.changedhistory";
    } else if (char === "D") {
      return "component.transactionRows.deleted";
    } else if (char === "M") {
      return "component.transactionRows.mergedRow";
    } else if (char === "N") {
      return "component.additionalInfo.noChangesAvailable";
    }
  };

  ctrl.toggleHeaderHistory = function () {
    ctrl.headerHistoryOpened = !ctrl.headerHistoryOpened;
    if (!ctrl.headerHistoryOpened) {
      ctrl.headerHistory = {};
    } else {
      webServices.getInvoiceHeaderChanges(ctrl.invoice.Id).then(function (response) {
        if (response.data.length) {
          ctrl.headerHistory = [];
          for (var i = 0; i < response.data.length; i++) {
            ctrl.headerHistory.push(response.data[i]);

            if (response.data[i].Field === "AccountingDate" ||
              response.data[i].Field === "InvoiceDate" ||
              response.data[i].Field === "DueDate") {
              // try to format dates
              var datOld = Date.parse(response.data[i].OldValue);
              var datNew = Date.parse(response.data[i].NewValue);
              if (datOld) {
                ctrl.headerHistory[i].OldValue = $filter("date")(datOld, ctrl.DATE_EE_FORMAT);
              }
              if (datNew) {
                ctrl.headerHistory[i].NewValue = $filter("date")(datNew, ctrl.DATE_EE_FORMAT);
              }
            }

            if (response.data[i].Field === "Status") {
              ctrl.headerHistory[i].OldValue = $filter("translate")(ctrl.getInvoiceStatusTranslateKey(parseInt(ctrl.headerHistory[i].OldValue)));
              ctrl.headerHistory[i].NewValue = $filter("translate")(ctrl.getInvoiceStatusTranslateKey(parseInt(ctrl.headerHistory[i].NewValue)));
            }
          }
        } else {
          ctrl.headerHistory = [{AuditAction: "N"}];
        }
      }, function (data) {
        console.log(data);
      });
    }
  };

  ctrl.openDeleteInvoiceModal = function () {
    $rootScope.modalInstance.open({
      templateUrl: "app/components/invoiceInformation/ds-delete-invoice-modal.html",
      controller: "DsDeleteInvoiceModal",
      windowClass: "delete-invoice-modal",
      scope: $scope,
      resolve: {
        invoice: function () {
          return ctrl.invoice;
        }
      }
    });
  };

  function checkIsDuplicateAndSave () {
    if (ctrl.invoice.IsNew) {
      var obj = {
        InvoiceDate: ctrl.invoice.InvoiceDate,
        TotalAmountWithVat: ctrl.invoice.TotalAmountWithVat,
        SupplierId: ctrl.invoice.SupplierId,
        Number: ctrl.invoice.Number
      };
      webServices.checkIsDuplicate(obj).then(function (response) {
        var result = response.data;
        ctrl.isInvoiceDuplicated = result;
        ctrl.isDuplicateTooltipOpen = result;
        if (!result) saveInvoice();
      }, function (data) {
        console.log(data);
      });
    } else {
      saveInvoice();
    }
  }

  function validateForm () {
    if (ctrl.validateDates() && ctrl.checkIban() && ctrl.additionalInformationForm.$valid) {
      ctrl.showValidation = false;
      return true;
    }
    ctrl.showValidation = true;
    return false;
  }

  ctrl.removeDuplicateFlag = function () {
    ctrl.invoiceLoading = true;
    $scope.$parent.removeDuplicateFlag($routeParams.id || ctrl.invoice.Id).then(
        activeInvoiceService.activeInvoice($routeParams.id || ctrl.invoice.Id)
    );
  };

  ctrl.getCurrenciesByNamePart = function (namePart) {
      if (!ctrl.currencyList.length) {
        return webServices.getCurrencyList(namePart).then(
            function (response) {
                ctrl.currencyList = cloneDeep(response.data);
                return getCurrenciesByNamePart(namePart, response.data);
            }, function (data) {
                console.log(data);
            });
      } else {
        return getCurrenciesByNamePart(namePart, ctrl.currencyList);
      }
  };

  function getCurrenciesByNamePart (namePart, list) {
      if (_.find(list, function (cur) {
          return cur.Code === namePart;
      })) {
          return list;
      }
      return _.filter(list, function (c) {
          var regex = new RegExp((namePart || "").toLowerCase());
          return regex.test(c.Code.toLowerCase());
      });
  }

  ctrl.checkIban = function() {
      var value = ctrl.invoice.PayToAccount && ctrl.invoice.PayToAccount.toUpperCase();
      // the regexp for checking if the inserted code is of IBAN specification format
      const IBAN_REGEXP = /^([A-Z]{2}[0-9]{0,33})$/;
      // if value is too short to check for IBAN validity return no errors
      if (!value || (value & value.length < 2)) {
          ctrl.validIban = true;
          return true;
      } else if (IBAN_REGEXP.test(value) && IBAN.isValid(value.substring(0, 2))) {
          // check if value is of IBAN format and return validation result accordingly
          ctrl.validIban = IBAN.isValid(value);
          return ctrl.validIban;
      }
      ctrl.validIban = true;
      return true;
  };

  ctrl.checkSumWithVat = function () {
    if (ctrl.invoice.TotalAmountWithVat == null || ctrl.invoice.TotalAmountWithVat === 0) {
      ctrl.validSumWithVat = false;
      return false;
    } else {
      ctrl.validSumWithVat = true;
      return true;
    }
  };

  ctrl.$onDestroy = function () {
    additionalInvoiceLoadedEvent();
    additionalInvoiceLoadingEvent();
    invoiceLoadedEvent();
    invoiceLoadingEvent();
    activeInvoiceLoadingEvent();
    invoiceMovedEvent();
    relatedDocumentEventWatcher();
  };

  function readFileContent (file) {
    var reader = new FileReader();
    var _file = file;

    reader.onload = function () {
      var fileContent = reader.result;
      attachDocumentToInvoice(_file.name, fileContent);
      saveInvoice();
    };
    reader.readAsArrayBuffer(file);
  }

  function attachDocumentToInvoice (fileName, fileData) {
    ctrl.invoice.DocumentFiles = [];
    var base64 = utils.getBase64String(fileData);
    var documentFile = {Base64Content: base64, FileName: fileName, IsPdf: true, IsImported: true};
    ctrl.invoice.DocumentFiles.push(documentFile);
    // Enable auto opening this document
    $rootScope.pdfAutoOpen = true;
  }

  function saveInvoice () {
    ctrl.invoice.PayToAccount = ctrl.invoice.PayToAccount && cloneDeep(ctrl.invoice.PayToAccount.toUpperCase());
    ctrl.invoiceCopy = cloneDeep(ctrl.invoice);
    ctrl.invoiceLoading = true;
    if (ctrl.invoice.DocumentType) {
      ctrl.invoice.DocumentType = ctrl.invoice.DocumentType[0];
    }
    if (ctrl.originalInvoiceId) {
      ctrl.saveInvoiceCopy(ctrl.invoice, ctrl.originalInvoiceId).then(function () {
      });
    } else {
      $scope.$parent.saveWorkflowInvoice(ctrl.invoice).then(function (res) {
        if (res && res.SupplierCode) {
          ctrl.invoice.SupplierCode = res.SupplierCode;
          activeInvoiceService.activeInvoice(ctrl.invoice.Id);
        }
      });
    }
    if (!ctrl.isNewInvoice) {
      ctrl.invoice.editing = false;
    }
    // Finally, make sure the PDF viewer gets closed
    $rootScope.isPDFVisible = false;
  }

  ctrl.saveInvoiceCopy = function (workflowInvoice, originalId) {
    if (workflowInvoice) {
      workflowInvoice.Supplier = null;
      return webServices.saveInvoiceCopy(workflowInvoice, originalId).then(function (response) {
        if (response.data) {
          return $location.path("/invoiceconfirmation/" + response.data.Id);
        }

        return response.data;
      }, function (data) {
        console.log(data);
      });
    }
  };

  ctrl.showNewSupplierLabel = function () {
    if (!ctrl.invoice) {
      return false;
    }
    return !ctrl.invoice.SupplierCode && ctrl.isNewSupplierVisible && ctrl.invoice.Status !== 6;
  };

  ctrl.showAddOrEditSupplierModal = function(supplierId, e) {
      e.preventDefault();
      $ngRedux.dispatch(showAddOrEditSupplierModal({Id: supplierId}));
  };

  function canEditInvoiceSupplier() {
      return authenticationService.isAuthorized(Role.CanUpdateInvoiceHeaderSensitiveData);
  }

  function init () {
    ctrl.visible = ctrl.initiallyVisible;

    const {company : {currentCompanySettings}} = $ngRedux.getState();
    if (currentCompanySettings && currentCompanySettings.length) {
      getDocumentTypeSetting(currentCompanySettings);
      getIsNewSupplierVisible(currentCompanySettings);
    } else {
      $ngRedux.dispatch(setCurrentCompanySettingsLoading(true));
      companyDataService.getCurrentCompanySettings().then(function (response) {
        if (response.data) {
          $ngRedux.dispatch(setCurrentCompanySettings(response.data));
          $ngRedux.dispatch(setCurrentCompanySettingsLoading(false));
          getDocumentTypeSetting(response.data);
          getIsNewSupplierVisible(response.data);
        }
      }, function (data) {
        $ngRedux.dispatch(setCurrentCompanySettingsLoading(false));
        console.log(data);
      });
    }
  }

  ctrl.$onInit = init;
}
