import { isEmpty, memoize } from 'lodash-es';
import {Role} from "../../../../../src/common/user/userPermissionUtil";

(function () {
  "use strict";

  angular
    .module("dstreamApp.components")
    .component("dsCombinePopover", {
      templateUrl: "app/components/transactionRows/components/combinePopover/ds-combine-popover.tpl.html",
      bindings: {
        mergeOpen: "=",
        isMergeDisabled: "<",
        rowsToBeMerged: "=",
        transactionRows: "<",
        mergeAllRows: "&",
        mergeRows: "&",
        combineRows: "&",
        metaInfo: "<",
        transactionRowsCount: "<",
        invoiceConfirmed: "<",
      },
      controller: DsCombinePopoverController
    });

  function DsCombinePopoverController ($scope, activeInvoiceService, authenticationService, notifyService) {
    var ctrl = this;

    ctrl.MergeOpen = $scope.mergeOpen;
    ctrl.isMergeDisabled = $scope.isMergeDisabled;
    ctrl.rowsToBeMerged = $scope.rowsToBeMerged;
    ctrl.mergeAllRows = $scope.mergeAllRows;
    ctrl.mergeRows = $scope.mergeRows;
    ctrl.combineRows = $scope.combineRows;
    ctrl.metaInfo = $scope.metaInfo;
    ctrl.transactionRows = $scope.transactionRows;
    ctrl.transactionRowsCount = $scope.transactionRowsCount;
    ctrl.invoiceConfirmed = $scope.invoiceConfirmed;

    ctrl.$onInit = init;
    ctrl.showCombineBy = showCombineBy;
    ctrl.showCombineRows = showCombineRows;
    ctrl.canUpdateAccountDistributionItem = canUpdateAccountDistributionItem;
    ctrl.handleDropdownItemClick = handleDropdownItemClick;
    ctrl.showCombineMenu = showCombineMenu;

    function init () {
    }

    function handleDropdownItemClick (evt, message, title) {
      if (angular.element(evt.target).hasClass("disabled")) {
        evt.preventDefault();
        evt.stopPropagation();
        if (!message) {
          if (ctrl.transactionRowsCount < 2) {
            message = 'component.transactionRows.error.notEnoughRows'
          } else if (ctrl.invoiceConfirmed) {
            message = 'component.transactionRows.error.invoiceExporting'
          } else if (ctrl.rowsToBeMerged.length < 2 || memoizeShowMergeAllRows()) {
            message = 'component.transactionRows.combineRowsMessage'
          } else {
            message = 'component.transactionRows.error.cannotCombine'
          }
        }

        const notificationTitle = title || 'interceptorsFactory.Error';
        notifyService.info(message, notificationTitle, true);
      }
    }

    function showCombineMenu () {
      return authenticationService.isAuthorized(Role.CanSeeCombineMenu);
    }

    function showCombineRows () {
      return  !ctrl.isMergeDisabled && ctrl.rowsToBeMerged && ctrl.rowsToBeMerged.length > 1;
    }

    function showCombineBy () {
      return canUpdateAccountDistributionItem() && memoizeInvoiceHasMeta();
    }

    function canUpdateAccountDistributionItem () {
      return authenticationService.isAuthorized("CanUpdateAccountDistributionItem");
    }

    function invoiceHasMeta () {
        if (isEmpty(ctrl.metaInfo)) {
            return false;
        }

        return ctrl.metaInfo.find((m) => m.RowsCount > 1);
    }

    const memoizeInvoiceHasMeta = memoize(invoiceHasMeta, () => JSON.stringify(ctrl.metaInfo));

      function showMergeAllRows () {
        let vatList;
        if (isEmpty(ctrl.rowsToBeMerged)) {
            vatList = ctrl.transactionRows.map(rtm => rtm.m_Item2);
        } else {
            vatList = ctrl.transactionRows.filter((tr) => ctrl.rowsToBeMerged.includes(tr.m_Item1)).map((tr) => tr.m_Item2);
        }

        return new Set(vatList).size !== 1;
    }

    const memoizeShowMergeAllRows = memoize(showMergeAllRows, () => JSON.stringify(ctrl.rowsToBeMerged));
  }
})();