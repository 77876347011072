import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { BaseSearch, SupplierDTO, FileDTO, PagedListContainer } from '../types/ApiTypes';
import { UploadProgress } from '../ApiClient';

const basicSearchParams: BaseSearch = {
    SortItems: [
        {
            SortColumn: 'Name',
            SortDirection: 0,
        },
    ],
    PagingOptions: {
        Count: 25,
        Page: 1,
    },
    Restrictions: [],
};
export default class SupplierService {
    constructor(private axios: AxiosInstance) {}

    getSuppliers = async (searchParams: BaseSearch = basicSearchParams): Promise<AxiosResponse<PagedListContainer<SupplierDTO>>> => {
        return this.axios.post('/Supplier/GetSuppliers', {
            ...searchParams,
        });
    };

    getSuppliersByName = async (name: string): Promise<AxiosResponse<SupplierDTO[]>> => {
        return this.axios.get('/Supplier/GetByName', {
            params: {
                name,
            },
        });
    };

    getSupplierById = async (supplierId: number): Promise<AxiosResponse<SupplierDTO>> => {
        return this.axios.get('/Supplier/GetSupplierById', {
            params: {
                supplierId,
            },
        });
    };

    addSupplier = async (supplier: SupplierDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post('/Supplier/AddSupplier', supplier);
    };

    updateSupplier = async (supplier: SupplierDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post('/Supplier/UpdateSupplier', supplier);
    };

    deleteSupplier = async (supplierId: number): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/Supplier/DeleteSupplier`, supplierId, {
            // axios sends the Payload as FormData otherwise
            headers: {
                'Content-Type': 'application/json',
            },
        });
    };

    uploadCSVFile = async (file: FileDTO, deleteUnused: boolean, onUploadProgress?: UploadProgress): Promise<AxiosResponse<any>> => {
        const FILE_UPLOAD_TIMEOUT = 5 * 60 * 1000; // 5 minutes
        const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (onUploadProgress) {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onUploadProgress(progress);
                }
            },
            timeout: FILE_UPLOAD_TIMEOUT,
        };
        return this.axios.post(`/Supplier/UploadCSVFile?deleteUnused=${deleteUnused}`, file, config);
    };

    exportSuppliersToCsv = async (search: BaseSearch): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/Supplier/ExportSuppliersToCSV`, search, {
            responseType: 'blob',
        });
    };

    exportSuppliersToXls = async (search: BaseSearch): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/Supplier/ExportSuppliersToXls`, search, {
            responseType: 'blob',
        });
    };
}
