'use strict';

angular.module('dstreamApp.controllers').controller('transactionsController', ['$scope', 'webServices', '$routeParams', 'localStorageService', function ($scope, webServices, $routeParams, localStorageService) {
    $scope.activeTab = 'pdf';
    $scope.invoice = null;
    $scope.invoiceTransactionRowsPaginator = {
        page: 1,
        count: 5,
        total: 0
    };

    $scope.changeTab = function (tab) {
        $scope.activeTab = tab;
    };

    /*
        PRIVATE FUNCTIONS
     */

    function getInvoiceData(id, invoiceRowPage) {
        var searchId = id ? id : $routeParams.id;
        var invoiceRowTake = localStorageService.get('invoiceRowsPageSize') || 5;
        invoiceRowPage = invoiceRowPage || 1;

        if (searchId !== undefined) {
          return webServices.getInvoiceData(searchId).then(function (response) {
                // if we didn't get any data
                if (!response.data.Invoice) {
                    $location.path('/dashboard');
                }

                response.data.Invoice.InvoiceRowsCount = response.data.InvoiceRowsCount;
                response.data.Invoice.InvoiceRowsPrice = response.data.InvoiceRowsPrice;
                response.data.Invoice.InvoiceRowsVAT = response.data.InvoiceRowsVAT;
                response.data.Invoice.InvoiceRowsTotal = response.data.InvoiceRowsTotal;
                response.data.Invoice.TransactionRowsPrice = response.data.TransactionRowsPrice;
                response.data.Invoice.TransactionRowsTotal = response.data.TransactionRowsTotal;
                response.data.Invoice.TransactionRowsVAT = response.data.TransactionRowsVAT;
                response.data.Invoice.InvoiceAccountingRowsTotal = response.data.InvoiceAccountingRowsTotal;

                if (!response.data.Invoice.AccountingDate) {
                    response.data.Invoice.AccountingDate = response.data.Invoice.InvoiceDate;
                }

                if (!response.data.Invoice.Beneficiary) {
                    response.data.Invoice.Beneficiary = response.data.Invoice.SupplierName;
                }

                console.log(response);
                $scope.invoice = response.data.Invoice;
            }, function (data) {
                console.log(data);
            });
        }
    }

    function init() {
        getInvoiceData();
    }

    init();
}
]);