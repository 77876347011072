(function() {
    'use strict';

    angular
        .module('dstreamApp.views.invoice-register')
        .config(InvoiceRegisterRoute);

    InvoiceRegisterRoute.$inject = ['customRouteProvider'];

    function InvoiceRegisterRoute(customRouteProvider) {
        customRouteProvider
            .when('/invoices/', {
                template: '<ds-invoice-register group-members="$resolve.groupMembers"></ds-invoice-register>',
                access: {
                    requiresLogin: true
                }
            })
            .when('/invoices/:from', {
                template: '<ds-invoice-register group-members="$resolve.groupMembers"></ds-invoice-register>',
                access: {
                    requiresLogin: true
                }
            });
    }
})();