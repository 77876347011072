import constants from '../../../../../src/common/constants/appConstants';
import {setPdfToOpenAction} from '../../../../../src/components/PDFViewer/FileViewActions';
import api from '../../../../../src/services/ApiServices';

(function() {
    'use strict';

    angular
        .module('dstreamApp.views.archive-details')
        .component('dsStaticRelatedDocuments', {
            templateUrl: 'app/views/archive-details/components/static-related-documents/static-related-documents.html',
            controller: StaticRelatedDocuments,
            bindings: {
                invoice: '<',
                invoiceLoading: '<'
            }
        });

    StaticRelatedDocuments.$inject = ['$rootScope', '$ngRedux'];

    function StaticRelatedDocuments($rootScope, $ngRedux) {
        var ctrl = this;
        /* Controller variables */
        ctrl.visible = true;

        /* Controller methods */
        ctrl.toggleComponent = toggleComponent;
        ctrl.openFile = openFile;

        /*
            Expand/Collapse element
         */
        function toggleComponent() {
            ctrl.visible = !ctrl.visible;
        }

        /*
            Open selected file. If pdf, then open in pdfViewer, if not then download file
         */
        
        function openFile(file, e) {
            e.preventDefault();
    
            if(!file.IsPdf && !file.FileUrl)
            {
                api.invoice.downloadFileById(file.Id, true, false, '').then(function (response) {
                    var downloadedFile = new Blob([response.data], { type: "application/octet-stream" });
                    saveAs(downloadedFile, file.FileName);
                });
                return;
            }
    
            if (file.IsPdf) {
                file.FileUrl = constants.FileUrl.concat(file.Id);
                file.DownloadUrl = constants.FileUrl.concat(file.Id);
                setTimeout(function () {
                    $rootScope.$emit('file.open', file);
                });
    
                $ngRedux.dispatch(setPdfToOpenAction(file));
            } else if (!file.FileUrl) {
                setTimeout(function () {
                    $rootScope.$emit('file.open', file);
                    $ngRedux.dispatch(setPdfToOpenAction(file));
                });
            } else {
                if (!file.FileUrl.match(/^https?:\/\//i)) {
                    file.FileUrl = 'http://' + file.FileUrl;
                }
                $ngRedux.dispatch(setExternalLinkToOpen(file.FileUrl));
            }
        }
    }
})();