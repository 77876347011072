import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash-es';

import { createDataId } from '../../../common/utils/dataId';
import Modal from '../../../components/Modal/Modal';
import { FieldArray, FieldArrayRenderProps, Form, Formik, FormikErrors, FormikProps, yupToFormErrors } from 'formik';
import { GroupMemberDTO, SubstituteDTO } from '../../../services/types/ApiTypes';
import FormikField from '../../../common/utils/FormikField';
import { CalendarDatePickerField } from '../../../components/CalendarDatePicker/CalendarDatePicker';
import { TypeaheadAsyncField, TypeaheadItem } from '../../../components/Typeahead/TypeaheadAsync';
import { TextInputType } from '../../../components/TextInput/TextInput';
import { checkIsOverlapping, getUniqueId, handleSearchSubstitutes, initialOutOfOfficeValues, isEndDateBeforeToday, parseSubstituteList } from './OutOfOfficeModalHelper';
import { OutOfOfficeModalValues } from './OutOfOfficeModalTypes';
import { Button, ButtonType } from '../../../components/Buttons/Button';
import Icon, { ICONS, IconSize } from '../../../components/Icon/Icon';
import { ContentBlockHeader, ContentBlockHeaderType } from '../../../components/ContentBlock/ContentBlockHeader';

import './OutOfOfficeModal.scss';

export interface OutOfOfficeModalProps {
    open: boolean;
    onClose: VoidFunction;
    substitutes: SubstituteDTO[];
    onUpdate: (substitutes: Omit<SubstituteDTO, 'GroupMemberId' | 'GroupMemberName' | 'Id' | 'IsNew'>[]) => void;
}

const dataId = 'out-of-office-modal';

const OutOfOfficeModal: React.FC<OutOfOfficeModalProps> = ({ open, substitutes, onClose, onUpdate }) => {
    const [substitutesFormValues, setSubstitutesFormValues] = useState<OutOfOfficeModalValues[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (substitutes?.length) {
            const newSubstitues: OutOfOfficeModalValues[] = substitutes.map((e) => {
                return {
                    Key: getUniqueId(),
                    From: e.From,
                    To: e.To,
                    Substitute: {
                        text: e.ToSubstituteName,
                        value: {
                            id: e.ToSubstituteId,
                            name: e.ToSubstituteName,
                        },
                    },
                };
            });

            setSubstitutesFormValues(newSubstitues);
        } else {
            setSubstitutesFormValues([initialOutOfOfficeValues()]);
        }
    }, [substitutes]);

    const SubstitutesValidationSchema = Yup.object().shape({
        From: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('views.home.dashboard.outOfOffice.modal.error.mandatoryField.startDate'))
            .test('test-from-date', null, function(value: Date) {
                const { parent, options } = this;
                if (new Date(this.parent?.To).getTime() < new Date(value).getTime()) {
                    return this.createError({
                        message: t('view.userProfile.outOfOffice.error.validationDueDate'),
                    });
                }
                const isOverlapping = checkIsOverlapping(parent, options.context as OutOfOfficeModalValues[]);
                if (!isOverlapping) {
                    return true;
                }
                return this.createError({
                    message: t('views.home.dashboard.outOfOffice.modal.error.overlapping'),
                });
            }),
        To: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('views.home.dashboard.outOfOffice.modal.error.mandatoryField.endDate'))
            .test('test-to-date', null, function(value: Date) {
                const { parent, options } = this;
                if (isEndDateBeforeToday(value)) {
                    return this.createError({
                        message: t('views.home.dashboard.outOfOffice.modal.error.beforeToday'),
                    });
                }
                if (new Date(this.parent?.From).getTime() > new Date(value).getTime()) {
                    return this.createError({
                        message: t('view.userProfile.outOfOffice.error.validationDueDate'),
                    });
                }
                const isOverlapping = checkIsOverlapping(parent, options.context as OutOfOfficeModalValues[]);
                if (!isOverlapping) {
                    return true;
                }
                return this.createError({
                    message: t('views.home.dashboard.outOfOffice.modal.error.overlapping'),
                });
            }),
        Substitute: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('views.home.dashboard.outOfOffice.modal.error.mandatoryField.replacement')),
    });

    const validationSchema = Yup.object<OutOfOfficeModalValues>().shape({
        Substitutes: Yup.array(SubstitutesValidationSchema),
    });

    const handleSubmit = (values: { Substitutes: OutOfOfficeModalValues[] }) => {
        const subList = parseSubstituteList(values.Substitutes);
        onUpdate(subList);
        onClose();
    };

    const handleRemove = (index: number, arrayProps: FieldArrayRenderProps) => {
        if (arrayProps?.form?.values?.Substitutes?.length === 1) {
            setErrors([]);
        }
        arrayProps.remove(index);
    };

    const handleAddNew = (arrayProps: FieldArrayRenderProps) => {
        arrayProps.push(initialOutOfOfficeValues());
    };

    const handleValidate = async (values: { Substitutes: OutOfOfficeModalValues[] }): Promise<FormikErrors<{ Substitutes: OutOfOfficeModalValues[] }>> => {
        try {
            await validationSchema.validate(values, { abortEarly: false, context: values?.Substitutes });
            setErrors([]);
            return {};
        } catch (e) {
            setErrors(uniq(e?.errors));
            throw yupToFormErrors(e);
        }
    };

    const handleCloseModal = () => {
        onClose();
        setErrors([]);
    };

    return (
        <Modal className={'out-of-office-modal'} dataId={createDataId(dataId, 'OutOfOfficeModal')} isOpen={open} hideCloseButton>
            <ContentBlockHeader className="out-of-office-modal__header" type={ContentBlockHeaderType.COMPACT}>
                <h2>{t('views.home.dashboard.out_of_office')}</h2>
                <Button
                    className={'out-of-office-modal__close-btn'}
                    iconSize={IconSize.MD}
                    buttonType={ButtonType.ICON}
                    icon={ICONS.CLOSE_24}
                    onClick={handleCloseModal}
                    dataId={createDataId(dataId, 'button', 'closeModal')}
                />
            </ContentBlockHeader>
            <Formik validate={handleValidate} initialValues={{ Substitutes: substitutesFormValues }} validateOnBlur={false} validateOnChange={false} onSubmit={handleSubmit}>
                {(formik: FormikProps<{ Substitutes: OutOfOfficeModalValues[] }>) => (
                    <>
                        {Boolean(errors?.length) && (
                            <div className="out-of-office-modal__alert-block">
                                {errors.map((error: string, i: number) => (
                                    <div key={i} data-id={createDataId(dataId, i, 'error')} className="out-of-office-modal__alert-item">
                                        <Icon className="out-of-office-modal__alert-icon" iconName={ICONS.ALERT} />
                                        {error}
                                    </div>
                                ))}
                            </div>
                        )}
                        <Form className="out-of-office-modal__form">
                            <div>
                                <FieldArray
                                    validateOnChange={false}
                                    name={'Substitutes'}
                                    render={(arrayProps: FieldArrayRenderProps) => (
                                        <>
                                            {formik.values?.Substitutes?.map((substitute: OutOfOfficeModalValues, index: number) => (
                                                <div data-id={createDataId(dataId, index, 'item')} key={substitute?.Key} className="out-of-office-modal__row">
                                                    <FormikField
                                                        component={CalendarDatePickerField}
                                                        label={t('views.home.dashboard.outOfOffice.modal.input.startDate')}
                                                        name={`Substitutes.${index}.From`}
                                                        dataId={createDataId(dataId, index, 'from')}
                                                        inputProps={{
                                                            hideError: true,
                                                            icon: ICONS.CALENDAR_24,
                                                            type: TextInputType.DATE_PICKER,
                                                        }}
                                                    />
                                                    <FormikField
                                                        component={CalendarDatePickerField}
                                                        label={t('views.home.dashboard.outOfOffice.modal.input.endDate')}
                                                        name={`Substitutes.${index}.To`}
                                                        dataId={createDataId(dataId, index, 'to')}
                                                        inputProps={{
                                                            hideError: true,
                                                            icon: ICONS.CALENDAR_24,
                                                            type: TextInputType.DATE_PICKER,
                                                        }}
                                                    />
                                                    <FormikField
                                                        component={TypeaheadAsyncField}
                                                        inputProps={{
                                                            type: TextInputType.COMPACT,
                                                            label: t('views.home.dashboard.outOfOffice.modal.input.replacement'),
                                                            hideError: true,
                                                        }}
                                                        isTooltipError
                                                        toggleVisible
                                                        name={`Substitutes.${index}.Substitute`}
                                                        searchOnFocus
                                                        loadItems={handleSearchSubstitutes}
                                                        onChange={(value: TypeaheadItem<GroupMemberDTO>) => {
                                                            formik.setFieldValue(`Substitutes.${index}.Replacement`, value);
                                                        }}
                                                        dataId={createDataId(dataId, index, 'substitute')}
                                                    />
                                                    <Button
                                                        buttonType={ButtonType.ICON}
                                                        icon={ICONS.DELETE_24}
                                                        onClick={() => handleRemove(index, arrayProps)}
                                                        dataId={createDataId(dataId, index, 'button', 'delete')}
                                                        className="out-of-office-modal__delete-button"
                                                    />
                                                </div>
                                            ))}
                                            <Button
                                                buttonType={ButtonType.ICON_TEXT}
                                                icon={ICONS.ADD_24}
                                                onClick={() => handleAddNew(arrayProps)}
                                                dataId={createDataId(dataId, 'button', 'addNew')}
                                                className="out-of-office-modal__button out-of-office-modal__add-new-btn"
                                            >
                                                {t('views.home.dashboard.outOfOffice.modal.addNew')}
                                            </Button>
                                        </>
                                    )}
                                />
                            </div>
                        </Form>
                        <div className="out-of-office-modal__footer">
                            <Button
                                dataId={createDataId(dataId, 'button', 'cancel')}
                                buttonType={ButtonType.SECONDARY}
                                className="out-of-office-modal__button out-of-office-modal__cancel-button"
                                onClick={handleCloseModal}
                            >
                                {t('views.global.Cancel')}
                            </Button>
                            <Button onClick={formik.submitForm} dataId={createDataId(dataId, 'button', 'save')} className="out-of-office-modal__button">
                                {t('views.global.save')}
                            </Button>
                        </div>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default OutOfOfficeModal;
