import { TFunction } from 'i18next';

import { InvoiceFieldType, SearchType, SortDirection } from '../../../services/types/ApiTypes';
import { HeaderExtensionsSearchParams } from './HeaderExtensionsReducer';
import { TagSelectItem } from '../../../components/TagSelect/TagSelect';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

export const createRequest = (searchValue = '', sortColumn = 'Code', page = 1, count = 15): HeaderExtensionsSearchParams => ({
    PagingOptions: {
        Count: count,
        Page: page,
    },
    Restrictions:
        searchValue && searchValue.length > 0
            ? [
                  {
                      Field: '',
                      FieldSearchType: SearchType.NotSelected,
                      Value: searchValue || null,
                      Values: null,
                  },
              ]
            : [],
    SortItems: [
        {
            SortColumn: sortColumn,
            SortDirection: SortDirection.Asc,
        },
    ],
    filters: {},
});

export const INVOICE_CUSTOM_FIELD_TYPE_NAME = {
    [InvoiceFieldType.Text]: 'views.InvoiceCustomFields.Text',
    [InvoiceFieldType.Dropdown]: 'views.InvoiceCustomFields.List',
    [InvoiceFieldType.DateTime]: 'views.InvoiceCustomFields.DateTime',
};

export const getHeaderExtensionTypesList = (t: TFunction): Array<TagSelectItem<any>> => {
    return Object.keys(INVOICE_CUSTOM_FIELD_TYPE_NAME).map((key) => ({
        text: t(INVOICE_CUSTOM_FIELD_TYPE_NAME[key]),
        value: key,
    }));
};
