import * as React from 'react';

import { createDataId } from '../../common/utils/dataId';
import { Button, ButtonType } from '../Buttons/Button';
import ModalTitle from './ModalTitle';
import classNames from 'classnames';
import Icon, { ICONS } from '../Icon/Icon';
import Checkbox from '../Checkbox/Checkbox';
import Modal from './Modal';

import './ModalConfirm.scss';

export interface ModalConfirmProps {
    open: boolean;
    children: React.ReactNode;
    confirmText: string;
    cancelText?: string;
    handleActions: () => void;
    cancelActions?: () => void;
    onClose: () => void;
    title?: React.ReactNode;
    dataId: string;
    confirmButtonStyle?: ConfirmButtonStyle;
    cancelButtonStyles?: ConfirmButtonStyle;
    confirmButtonIcon?: string;
    cancelButtonIcon?: string;
    confirmWithCheckBox?: boolean;
    checkBoxLabel?: string;
    noCloseButton?: boolean;
    disableConfirmButton?: boolean;
    className?: string;
    isLoading?: boolean;
    fullScreenElementId?: string;
}

export enum ConfirmButtonStyle {
    PRIMARY = 'PRIMARY',
    DANGER = 'DANGER',
}

const ModalConfirm: React.StatelessComponent<ModalConfirmProps> = ({
    open,
    onClose,
    title,
    confirmText,
    cancelText,
    children,
    handleActions,
    dataId,
    cancelActions,
    confirmButtonStyle = ConfirmButtonStyle.DANGER,
    cancelButtonStyles = ConfirmButtonStyle.DANGER,
    confirmButtonIcon,
    cancelButtonIcon,
    confirmWithCheckBox,
    checkBoxLabel,
    noCloseButton,
    disableConfirmButton,
    className,
    isLoading,
    fullScreenElementId,
}: ModalConfirmProps) => {
    const [isChecked, setIsChecked] = React.useState(null);

    React.useEffect(() => {
        confirmWithCheckBox && !open && setIsChecked(false);
    }, [open]);

    const getConfirmButtonStyle = (type: ConfirmButtonStyle) => {
        const notificationType = {
            [ConfirmButtonStyle.PRIMARY]: 'modal-confirm__btn--primary',
            [ConfirmButtonStyle.DANGER]: 'modal-confirm__btn--danger',
        };
        return notificationType[type];
    };

    const confirmButtonClasses = classNames('modal-confirm__btn', getConfirmButtonStyle(confirmButtonStyle));
    const cancelButtonClasses = classNames('modal-confirm__btn', getConfirmButtonStyle(cancelButtonStyles));

    return (
        <Modal className={className || ''} isOpen={open} onClose={onClose} noCloseButton={noCloseButton} fullScreenElementId={fullScreenElementId || null} dataId="modalConfirm">
            <div className="modal-confirm__container" data-id={createDataId(dataId, 'modalContainer')}>
                <ModalTitle>{title}</ModalTitle>
                <div className="modal-confirm__content">{children}</div>
                {confirmWithCheckBox && (
                    <div className="modal-confirm__wrapper-checkBox">
                        <Checkbox
                            className="modal-confirm__checkbox"
                            data-id={createDataId(dataId, 'checkbox')}
                            name="modal.confirm.checkbox"
                            onChange={() => {
                                setIsChecked(!isChecked);
                            }}
                            label={checkBoxLabel}
                        />
                    </div>
                )}
            </div>
            <div className="modal-confirm__wrapper-btn" data-id={createDataId(dataId, 'confirmButton.modal')}>
                {cancelActions && (
                    <Button className={cancelButtonClasses} buttonType={ButtonType.ACTION} onClick={cancelActions} data-id={'confirmModal.button.cancel'} disabled={isLoading}>
                        <Icon iconName={cancelButtonIcon || ICONS.CLOSE_24} />
                        <span>{cancelText}</span>
                    </Button>
                )}
                <Button
                    disabled={(!isChecked && confirmWithCheckBox) || disableConfirmButton}
                    loading={isLoading}
                    className={confirmButtonClasses}
                    buttonType={ButtonType.PRIMARY}
                    onClick={handleActions}
                    data-id={'confirmModal.button'}
                >
                    <Icon iconName={confirmButtonIcon || ICONS.CHECK_24} />
                    <span>{confirmText}</span>
                </Button>
            </div>
        </Modal>
    );
};

export default ModalConfirm;
