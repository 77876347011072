import { selectPdfToOpen } from '../../../src/components/PDFViewer/FileViewReducer';

(function() {
    'use strict';

    angular
        .module('dstreamApp.views.archive-details')
        .component('dsArchiveDetails', {
            templateUrl: 'app/views/archive-details/archive-details.html',
            controller: ArchiveDetails
        });

    ArchiveDetails.$inject = ['$scope','$rootScope', 'webServices', '$ngRedux', 'localStorageService', '$routeParams', 'utilityService', '$location', 'invoiceService'];

    function ArchiveDetails($scope, $rootScope, webServices, $ngRedux, localStorageService, $routeParams, utilityService, $location, invoiceService) {
        var ctrl = this;
        /* Controller variables */
        ctrl.invoice = null;
        ctrl.invoiceId = null;
        ctrl.invoiceLoading = false;
        $scope.isPdfOpen = false;

        /* CONTROLLER METHODS */
        ctrl.getInvoiceData = getInvoiceData;

        ctrl.$onInit = function() {
            ctrl.invoiceId = $routeParams.id;
            getInvoiceData();
        };

        /*
            Get archive invoice data
         */
        function getInvoiceData(id) {
            ctrl.invoiceLoading = true;
            id = id || ctrl.invoiceId;

            // if new id isn't the same one as in url, then update it
            // useful when invoiceRows/accountingRows components watch this value to know when to fetch new data
            if(ctrl.invoiceId !== id) {
                ctrl.invoiceId = id;
            }

            // fetch data
            return webServices.getArchiveInvoiceData(id).then(function (response) {
                if (!response.data.Invoice) {
                    $location.path('/dashboard');
                }
                ctrl.invoiceLoading = false;

                response.data.Invoice.InvoiceRowsCount = response.data.InvoiceRowsCount;
                response.data.Invoice.InvoiceRowsPrice = response.data.InvoiceRowsPrice;
                response.data.Invoice.InvoiceRowsVAT = response.data.InvoiceRowsVAT;
                response.data.Invoice.InvoiceRowsTotal = response.data.InvoiceRowsTotal;
                response.data.Invoice.InvoiceAccountingRowsTotal = response.data.InvoiceAccountingRowsTotal;

                response.data.Invoice.InvoiceDate = utilityService.removeTimeZone(response.data.Invoice.InvoiceDate);
                response.data.Invoice.AccountingDate = utilityService.removeTimeZone(response.data.Invoice.AccountingDate);
                response.data.Invoice.DueDate = utilityService.removeTimeZone(response.data.Invoice.DueDate);
                response.data.Invoice.ImportedDate = utilityService.removeTimeZone(response.data.Invoice.ImportedDate);

                if (!response.data.Invoice.AccountingDate) {
                    response.data.Invoice.AccountingDate = response.data.Invoice.InvoiceDate;
                }

                if (!response.data.Invoice.Beneficiary) {
                    response.data.Invoice.Beneficiary = response.data.Invoice.SupplierName;
                }

                $rootScope.$emit('invoiceLoaded', ctrl.invoice);
                return ctrl.invoice = angular.copy(response.data.Invoice);
            });
        }

        $ngRedux.subscribe(() => {
            const state = $ngRedux.getState();
            const pdfToOpen = selectPdfToOpen(state);
            if (!!pdfToOpen) {
              $scope.isPdfOpen = true;
            } else if ($scope.isPdfOpen) {
              $scope.isPdfOpen = false;
            }
        })
      
        let unsubscribeReduxPdf = $ngRedux.connect((state) => ({pdfToOpen: selectPdfToOpen(state)}))($scope);
      
        $scope.$on("$destroy", function () {
            unsubscribeReduxPdf();
        });
    }
})();