import api from '../../services/ApiServices';
import { notify } from '../../common/utils/notify';
import i18nInstance from '../../i18n';

export const sendEmailVerificationLink = async (userGuid: string, email: string, showSuccessNotify?: boolean) => {
    try {
        const result = await api.boUsers.sendVerificationEmailToUser(userGuid, email);
        if (result.data.Success) {
            if (showSuccessNotify) {
                notify.success(i18nInstance.t('views.global.emailVerification.linkSent'));
            }
            return true;
        } else {
            notify.error(i18nInstance.t(result.data.Message));
            return false;
        }
    } catch (e) {
        console.error('Request rejected due to:', e);
        return false;
    }
};
