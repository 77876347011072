import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { BackOfficeReportDTO } from '../../../services/types/BoApiTypes';
import { BaseSearch, PagedListContainerOf, SearchType, SortDirection } from '../../../services/types/ApiTypes';
import { getBoReportingListLoadable } from './BOReportingListViewActions';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

class State {
    boReportingListLoadable = new LoadableData<PagedListContainerOf<BackOfficeReportDTO>, BaseSearch>();
    searchParams: BaseSearch = {
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: '',
                Values: undefined,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        SortItems: [
            {
                SortColumn: 'FileName',
                SortDirection: SortDirection.Desc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 15,
        },
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        getBoReportingListLoadable.request,
        (state, action): State => {
            return {
                ...state,
                boReportingListLoadable: LoadableData.loading(action.payload),
            };
        },
    )
    .addReducer(
        getBoReportingListLoadable.success,
        (state, action): State => {
            return {
                ...state,
                boReportingListLoadable: state.boReportingListLoadable.withPayloadIfRequestEquals(action.payload),
                searchParams: action.payload.request,
            };
        },
    )
    .addReducer(
        getBoReportingListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                boReportingListLoadable: state.boReportingListLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'bo/boReporting',
    whitelist: ['searchParams'],
};
export { State as BOReportingViewState };

export default persistReducer(persistConfig, reducer);

export const selectBOReportingListLoadable = (state: GlobalState) => state.boReporting.boReportingListLoadable;
export const selectBOReportingSearchParams = (state: GlobalState) => state.boReporting.searchParams;
