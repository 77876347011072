import { saveAs } from 'file-saver';
import { DropResult } from 'react-beautiful-dnd';

import api from '../../../services/ApiServices';
import { formatDate } from '../../../common/utils/formatters';
import { loadableDataActions } from '../../../common/utils/LoadableData';
import { ApiResultOfListOfCustomCostObjectiveDTO, BaseSearch } from '../../../services/types/ApiTypes';
import { DispatchThunk } from '../../../storeConfig';

const ns = 'settings/accounting/';

export const loadCostObjectivesLoadableActions = loadableDataActions<BaseSearch, ApiResultOfListOfCustomCostObjectiveDTO>(`${ns}LOAD_COST_OBJECTIVES`);

export function loadCostObjectives() {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            dispatch(loadCostObjectivesLoadableActions.request(undefined));
            response = await api.customCostObjective.getCompanyCustomCostObjectivesWithoutDimensions();
            dispatch(loadCostObjectivesLoadableActions.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(loadCostObjectivesLoadableActions.error(e));
        }
    };
}

export const reorderCostObjectiveLoadableActions = loadableDataActions<DropResult, any>(`${ns}REORDER_COST_OBJECTIVE`);

export function reorderCostObjective(result: DropResult) {
    const costObjectiveId = Number(result.draggableId);
    const newOrderNo = result.destination.index + 1;

    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            dispatch(reorderCostObjectiveLoadableActions.request(result));
            response = await api.customCostObjective.updateCustomCostObjectiveOrderNumber(costObjectiveId, newOrderNo);
            dispatch(reorderCostObjectiveLoadableActions.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(reorderCostObjectiveLoadableActions.error(e));
            dispatch(loadCostObjectives());
        }
    };
}

export const exportAccountsToCSVLoadableActions = loadableDataActions(`${ns}EXPORT_ACCOUNTS_TO_CSV`);

export function exportAccountsToCSV() {
    return async (dispatch: DispatchThunk) => {
        dispatch(exportAccountsToCSVLoadableActions.request(null));

        try {
            const response = await api.customCostObjective.exportAccountsToCSV();
            const fileName = `Accounting_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.csv`;
            const file = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            saveAs(file, fileName);
            dispatch(exportAccountsToCSVLoadableActions.success(null));
        } catch (e) {
            console.error(e);
            dispatch(exportAccountsToCSVLoadableActions.error(e));
        }
    };
}

export const exportAccountsToXLSXLoadableActions = loadableDataActions(`${ns}EXPORT_ACCOUNTS_TO_XLSX`);

export function exportAccountsToXLS() {
    return async (dispatch: DispatchThunk) => {
        dispatch(exportAccountsToXLSXLoadableActions.request(null));
        try {
            const response = await api.customCostObjective.exportAccountsToXLSX();
            const fileName = `Accounting_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`;
            const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            saveAs(file, fileName);
            dispatch(exportAccountsToXLSXLoadableActions.success(null));
        } catch (e) {
            console.error(e);
            dispatch(exportAccountsToXLSXLoadableActions.error(e));
        }
    };
}
