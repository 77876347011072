import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { TableFilter } from '../../../components/Table/components/filter/TableFilters';
import { GlobalState } from '../../../rootReducer';
import { BaseSearchWithTableFilters, InvoiceCustomFieldDTO, PagedListContainer } from '../../../services/types/ApiTypes';
import { getHeaderExtensionsListLoadable } from './HeaderExtensionsActions';
import { createRequest } from './HeaderExtensionsHelpers';

export type HeaderExtensionsSearchParams = BaseSearchWithTableFilters<TableFilter<any>, InvoiceCustomFieldDTO>;

class State {
    headerExtensionsListLoadable = new LoadableData<PagedListContainer<InvoiceCustomFieldDTO>, HeaderExtensionsSearchParams>();
    searchParams: HeaderExtensionsSearchParams = {
        ...createRequest(''),
        filters: {},
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        getHeaderExtensionsListLoadable.request,
        (state, action): State => {
            const headerExtensionsListLoadable = state.headerExtensionsListLoadable.withLoading(action.payload);
            return {
                ...state,
                searchParams: action.payload,
                headerExtensionsListLoadable,
            };
        },
    )
    .addReducer(
        getHeaderExtensionsListLoadable.success,
        (state, action): State => {
            const headerExtensionsListLoadable = state.headerExtensionsListLoadable.withPayloadIfRequestEquals(action.payload);
            return {
                ...state,
                searchParams: action.payload.request,
                headerExtensionsListLoadable,
            };
        },
    )
    .addReducer(
        getHeaderExtensionsListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                headerExtensionsListLoadable: state.headerExtensionsListLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state, action: Action<RouterState>): State => {
            if (/\/headerextensions/.test(action.payload.location.pathname)) {
                return {
                    ...state,
                };
            }
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'settings/header-extensions',
    whitelist: ['searchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as HeaderExtensionsViewState };

export const selectHeaderExtensionsListLoadable = (state: GlobalState) => state.headerExtensions.headerExtensionsListLoadable;
export const selectHeaderExtensionsListSearchParams = (state: GlobalState) => state.headerExtensions.searchParams;
