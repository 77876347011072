import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { BackOfficeResellerDTO } from '../../../services/types/BoApiTypes';
import { BaseSearch, PagedListContainerOf, SearchType, SortDirection } from '../../../services/types/ApiTypes';

import { getBoResellerListLoadable } from './BOResellerListViewActions';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

class State {
    boResellerListLoadable = new LoadableData<PagedListContainerOf<BackOfficeResellerDTO>, BaseSearch>();
    searchParams: BaseSearch = {
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: '',
                Values: undefined,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        SortItems: [
            {
                SortColumn: 'ResellerName',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 15,
        },
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        getBoResellerListLoadable.request,
        (state, action): State => {
            return {
                ...state,
                boResellerListLoadable: LoadableData.loading(action.payload),
            };
        },
    )
    .addReducer(
        getBoResellerListLoadable.success,
        (state, action): State => {
            return {
                ...state,
                boResellerListLoadable: state.boResellerListLoadable.withPayloadIfRequestEquals(action.payload),
                searchParams: action.payload.request,
            };
        },
    )
    .addReducer(
        getBoResellerListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                boResellerListLoadable: state.boResellerListLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'bo/boReseller',
    whitelist: ['searchParams'],
};
export { State as BOResellerManagementViewState };

export default persistReducer(persistConfig, reducer);

export const selectBOResellerListLoadable = (state: GlobalState) => state.boResellerManagement.boResellerListLoadable;
export const selectBOResellerSearchParams = (state: GlobalState) => state.boResellerManagement.searchParams;
