import { BaseSearchWithTableFilters, PagedListContainerOf, Restriction, SearchType, SortDirection } from '../../../services/types/ApiTypes';
import { createAction } from 'redux-actions';
import { loadableDataActions, loadableDataActionsWithRequest } from '../../../common/utils/LoadableData';
import { BackOfficeDomainDTO } from '../../../services/types/BoApiTypes';
import { TableFilter } from '../../../components/Table/components/filter/TableFilters';
import { DispatchThunk } from '../../../storeConfig';
import { GlobalState } from '../../../rootReducer';
import { notify } from '../../../common/utils/notify';
import i18n from '../../../i18n';
import api from '../../../services/ApiServices';

import { createRequest } from './boDomainListHelpers';

export type BoDomainListSearchParams = BaseSearchWithTableFilters<Array<TableFilter<any>>, BackOfficeDomainDTO>;

export const DEFAULT_RESTRICTION = 'GeneralSearch';

const ns = 'back-office-domains-list/';
export const getBoDomainListLoadable = loadableDataActionsWithRequest<BoDomainListSearchParams, PagedListContainerOf<BackOfficeDomainDTO>>(`${ns}BO_DOMAINS`);
export const getAllBoDomainListLoadable = loadableDataActions<undefined, Array<BackOfficeDomainDTO>>(`${ns}ALL_BO_DOMAINS`);
export const addOrEditBoDomainAction = loadableDataActions<BackOfficeDomainDTO, BackOfficeDomainDTO>(`${ns}ADD_OR_EDIT_BO_DOMAIN`);
export const showAddOrEditBoDomainModal = createAction<BackOfficeDomainDTO | boolean>(`${ns}SHOW_ADD_OR_EDIT_BO_DOMAIN_MODAL`);

export const getBoDomainList = (searchParams?: BoDomainListSearchParams) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const { boDomainsManagement } = state;
        const stateSearchParams = boDomainsManagement.searchParams;
        searchParams = searchParams || stateSearchParams || createRequest('', 1, 15, DEFAULT_RESTRICTION);

        let response;
        try {
            dispatch(getBoDomainListLoadable.request(searchParams));
            response = await api.boDomains.getDomains(searchParams);
            dispatch(
                getBoDomainListLoadable.success({
                    result: response.data,
                    request: searchParams,
                }),
            );
        } catch (e) {
            dispatch(
                getBoDomainListLoadable.error({
                    result: e,
                    request: searchParams,
                }),
            );
        }
    };
};

export const setPagingOptions = (page: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { boDomainsManagement } = getState();
        const params = boDomainsManagement?.searchParams;
        const paging = params?.PagingOptions;
        const searchParams: BoDomainListSearchParams = {
            ...params,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        dispatch(
            getBoDomainList({
                ...searchParams,
                PagingOptions: {
                    Page: page || searchParams.PagingOptions.Page,
                    Count: pageSize || searchParams.PagingOptions.Count,
                },
            }),
        );
    };
};

export const searchBoDomains = (searchString: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const { boDomainsManagement } = getState();
            let searchParams: BoDomainListSearchParams = boDomainsManagement.searchParams;
            const paging = searchParams.PagingOptions;

            const otherRestrictions = searchParams.Restrictions.filter((r: Restriction) => DEFAULT_RESTRICTION !== r.Field);
            let restrictions: Restriction[] = [];
            if (searchString) {
                restrictions.push({
                    Field: DEFAULT_RESTRICTION,
                    Value: searchString,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                });
            }
            restrictions = [...restrictions, ...otherRestrictions];
            searchParams = {
                ...searchParams,
                PagingOptions: {
                    ...paging,
                    Page: 1, //reset to first page when searching
                },
                Restrictions: [...restrictions],
            };
            dispatch(getBoDomainList(searchParams));
        } catch (e) {
            console.error(e);
        }
    };
};

export const sortBoDomains = (column: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const { boDomainsManagement } = getState();
            const params = boDomainsManagement.searchParams;
            const sorting = params?.SortItems[0];

            const searchParams: BoDomainListSearchParams = {
                ...params,
                SortItems: [
                    {
                        SortColumn: column,
                        SortDirection: sorting && sorting.SortColumn === column ? (sorting && sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                    },
                ],
            };
            dispatch(getBoDomainList(searchParams));
        } catch (e) {
            console.error(e);
        }
    };
};

export const addOrEditBoDomain = (domain: BackOfficeDomainDTO) => {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            dispatch(addOrEditBoDomainAction.request(domain));
            response = await api.boDomains.saveDomain(domain);
            dispatch(addOrEditBoDomainAction.success(response.data));
            dispatch(setPagingOptions(1));
            notify.success(i18n.t('view.backOffice.domains.editingActionSuccess'));
        } catch (e) {
            console.error(e);
            notify.error(i18n.t('view.backOffice.domains.editingActionError'));
        }
    };
};

export function getAllBoDomainList() {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(getAllBoDomainListLoadable.request(undefined));
            const response = await api.boDomains.getAllDomains();
            dispatch(getAllBoDomainListLoadable.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(getAllBoDomainListLoadable.error(e));
        }
    };
}
