(function() {
    'use strict';

    angular
        .module('blocks.utility')
        .factory('utilityService', utilityService);

    utilityService.$inject = ['$rootScope'];

    /*
        Utilities methods. General methods that are used all over the app. Only handles small data parsing
     */
    function utilityService($rootScope) {
        var service = {
            isSystemSetting: isSystemSetting,
            removeTimeZone: removeTimeZone,
            copy: copy,
            cloneObject: cloneObject,
            hardCopy: hardCopy
        };

        function copy (data) {
            return _.map(data, _.clone)
        }

        function hardCopy (data) {
            return JSON.parse(JSON.stringify(data, function (key, value) {
                return value === undefined ? null: value
            }));
        }

        function cloneObject (o){
            const gdcc = "__getDeepCircularCopy__";
            if (o !== Object(o)) {
                return o; // primitive value
            }

            var set = gdcc in o,
                cache = o[gdcc],
                result;
            if (set && typeof cache == "function") {
                return cache();
            }
            // else
            o[gdcc] = function() { return result; }; // overwrite
            if (o instanceof Array) {
                result = [];
                for (var i=0; i<o.length; i++) {
                    result[i] = this.cloneObject(o[i]);
                }
            } else {
                result = {};
                for (var prop in o)
                    if (prop != gdcc)
                        result[prop] = this.cloneObject(o[prop]);
                    else if (set)
                        result[prop] = this.cloneObject(cache);
            }
            if (set) {
                o[gdcc] = cache; // reset
            } else {
                delete o[gdcc]; // unset again
            }
            return result;
        }

        /*
            Remove timezone from all the dates. Used because different timezones were used on client side that were dependant on client location.
         */
        function removeTimeZone(dateTime) {
            // split Time part
            if (!dateTime)
                return dateTime;
            var dtSplit = dateTime.split('T');
            if (dtSplit.length == 2) {
                var delimiter = dtSplit[1].indexOf('+') > -1 ? '+' : '-';
                var spl = dateTime.split(delimiter);
                if (spl.length == 2) {
                    var retVal = spl[0];
                    return retVal;
                }
            }
            return dateTime;
        }

        /*
            Check if a system setting is set or not
         */
        function isSystemSetting(settingName) {
            if ($rootScope.userData && $rootScope.userData.CompanySettings) {
                var setting = _.find($rootScope.userData.CompanySettings, function (s) {
                    return s === settingName;
                }) != null;
                if (setting)
                    return true;
            }
            return false;
        }

        return service;
    }
})();