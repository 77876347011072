'use strict';

angular
    .module('dstreamApp.components')
    .component('dsCalendar', {
        templateUrl: 'app/components/calendar/ds-calendar.html',
        controller: DsCalendarController
    });

/* @ngInject */
function DsCalendarController($scope, $timeout){
    $("#zabuto-calendar").zabuto_calendar({
        language: "en",
        today:true
    });

    setup();

    function setup() {
        $scope.clock = "loading clock...";
        $scope.tickInterval = 1000;

        var tick = function () {
            $scope.clock = Date.now();
            $timeout(tick, $scope.tickInterval);
        };

        $timeout(tick, $scope.tickInterval);
    }
}
