import { isEmpty } from 'lodash-es';

import { notify } from '../../../common/utils/notify';
import i18nInstance from '../../../i18n';
import { loadableDataActionsWithRequest } from '../../../common/utils/LoadableData';
import { DispatchThunk } from '../../../storeConfig';
import api from '../../../services/ApiServices';
import { BackOfficeReportDTO } from '../../../services/types/BoApiTypes';
import { BaseSearch, PagedListContainerOf, SortDirection, UserSettingName } from '../../../services/types/ApiTypes';
import { GlobalState } from '../../../rootReducer';
import { getCurrentUserGroupMember } from '../../../common/user/UserActions';
import { getViewUserSearchParams } from '../../../common/user/userSettingUtil';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../common/utils/baseSearchHelpers';
import { selectBOReportingSearchParams } from './BOReportingListViewReducer';
import { updateBoUserSettingsAction } from '../../../common/middlewares/userSettings';

const ns = 'back-office-Reporting-list/';

const listViewConfig = {
    sortDir: UserSettingName.BACK_OFFICE_REPORTING_SORT_DIRECTION,
    sortCol: UserSettingName.BACK_OFFICE_REPORTING_SORT_COLUMN,
    pageSize: UserSettingName.BACK_OFFICE_REPORTING_PAGE_SIZE,
};

export const getBoReportingListLoadable = loadableDataActionsWithRequest<BaseSearch, PagedListContainerOf<BackOfficeReportDTO>>(`${ns}ALL_BO_REPORTINGS`);

export const getBOReportingList = (searchParams: BaseSearch, onMount?: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        let response;
        try {
            if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
                await dispatch(getCurrentUserGroupMember());
            }
            const boReportingSearchParams = selectBOReportingSearchParams(getState());
            searchParams = searchParams || boReportingSearchParams;

            const groupMember = getState().user.groupMemberCommonLoadable.payload;

            const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember, true);
            viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
            viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'FileName', SortDirection.Desc);
            searchParams = { ...searchParams, ...viewSearchParams };
            if (onMount) {
                searchParams.PagingOptions.Page = 1;
            }
            dispatch(getBoReportingListLoadable.request(searchParams));
            response = await api.boUsers.getUsersReportFiles(searchParams);
            dispatch(
                getBoReportingListLoadable.success({
                    result: response.data,
                    request: searchParams,
                }),
            );
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            dispatch(getBoReportingListLoadable.error(e));
        }
    };
};

export function setPagingOptions(page?: number, pageSize?: number) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState): Promise<any> => {
        let searchParams = selectBOReportingSearchParams(getState());
        const paging = searchParams.PagingOptions;

        searchParams = {
            ...searchParams,
            PagingOptions: {
                Page: !pageSize || pageSize === paging.Count ? page : 1,
                Count: pageSize || paging.Count,
            },
        };
        await dispatch(
            updateBoUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        return dispatch(getBOReportingList(searchParams));
    };
}
