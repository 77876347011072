import { ReducerFactory } from 'redux-actions-ts-reducer';
import { cloneDeep } from 'lodash-es';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { BackOfficeIntegratorDTO } from '../../../services/types/BoApiTypes';
import { BaseSearch, PagedListContainer, PagedListContainerOf } from '../../../services/types/ApiTypes';

import { getBoIntegratorListLoadable, BoIntegratorListSearchParams, addOrEditBoIntegratorAction, showAddOrEditBoIntegratorModal } from './BOIntegratorViewActions';

class State {
    boIntegratorListLoadable = new LoadableData<PagedListContainerOf<BackOfficeIntegratorDTO>, BaseSearch>();
    addOrEditBoIntegratorAction = new LoadableData<BackOfficeIntegratorDTO, BackOfficeIntegratorDTO>();
    searchParams: BoIntegratorListSearchParams;
    isEditBoIntegratorModalOpen: boolean;
    integratorToEdit: BackOfficeIntegratorDTO | null;
}

export default new ReducerFactory(new State())
    .addReducer(
        getBoIntegratorListLoadable.request,
        (state, action): State => {
            return {
                ...state,
                boIntegratorListLoadable: LoadableData.loading(action.payload),
                searchParams: action.payload,
            };
        },
    )
    .addReducer(
        getBoIntegratorListLoadable.success,
        (state, action): State => {
            return {
                ...state,
                boIntegratorListLoadable: LoadableData.payload(action.payload.result),
                searchParams: action.payload.request,
            };
        },
    )
    .addReducer(
        getBoIntegratorListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                boIntegratorListLoadable: LoadableData.error(action.payload.result),
            };
        },
    )
    .addReducer(
        addOrEditBoIntegratorAction.request,
        (state, action): State => {
            return {
                ...state,
                addOrEditBoIntegratorAction: LoadableData.loading(action.payload),
            };
        },
    )
    .addReducer(
        addOrEditBoIntegratorAction.success,
        (state, action): State => {
            const integratorList: BackOfficeIntegratorDTO[] = state.boIntegratorListLoadable.payload.Items.map((v) => {
                if (v.IntegratorGuid === action.payload.IntegratorGuid) {
                    v.IsEnabled = action.payload.IsEnabled;
                }
                return v;
            });
            const boIntegratorListLoadable: LoadableData<PagedListContainer<BackOfficeIntegratorDTO>, BoIntegratorListSearchParams> = cloneDeep(state.boIntegratorListLoadable);
            boIntegratorListLoadable.payload = {
                ...state.boIntegratorListLoadable.payload,
                Items: [...integratorList],
            };
            return {
                ...state,
                boIntegratorListLoadable,
            };
        },
    )
    .addReducer(
        addOrEditBoIntegratorAction.error,
        (state, action): State => {
            return {
                ...state,
                addOrEditBoIntegratorAction: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        showAddOrEditBoIntegratorModal,
        (state, action): State => {
            let newState: State;
            // payload === false                => modal closes, integrator -> null
            // payload === integrator object    => modal opens, integrator data can be edited
            // payload === undefined            => modal opens, new integrator (null) to be created
            if (action.payload === false) {
                newState = {
                    ...state,
                    integratorToEdit: null,
                    isEditBoIntegratorModalOpen: false,
                };
            } else {
                newState = {
                    ...state,
                    isEditBoIntegratorModalOpen: true,
                    integratorToEdit: typeof action.payload === 'object' ? action.payload : null,
                };
            }
            return newState;
        },
    )
    .toReducer();

export { State as BoIntegratorManagementViewState };

export const selectBOIntegratorListLoadable = (state: GlobalState) => state.boIntegratorsManagement.boIntegratorListLoadable;
export const selectBoIntegratorListSearchParams = (state: GlobalState) => state.boIntegratorsManagement.searchParams;
export const selectIsEditBoIntegratorModalOpen = (state: GlobalState) => state.boIntegratorsManagement.isEditBoIntegratorModalOpen;
export const selectIntegratorToEdit = (state: GlobalState) => state.boIntegratorsManagement.integratorToEdit;
