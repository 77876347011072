import { ReducerFactory } from 'redux-actions-ts-reducer';
import { LoadableData } from '../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../rootReducer';
import { PurchaseOrderHistoryDTO } from '../../../../services/types/ApiTypes';
import { getPurchaseOrderHistoryLoadable } from './PurchaseOrderHistoryActions';

class State {
    purchaseOrderHistoryLoadable = new LoadableData<PurchaseOrderHistoryDTO[], string>();
}

export { State as PurchaseOrderHistoryState };

export default new ReducerFactory(new State())
    .addReducer(
        getPurchaseOrderHistoryLoadable.request,
        (state): State => {
            return {
                ...state,
                purchaseOrderHistoryLoadable: state.purchaseOrderHistoryLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        getPurchaseOrderHistoryLoadable.success,
        (state, action): State => {
            return {
                ...state,
                purchaseOrderHistoryLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getPurchaseOrderHistoryLoadable.error,
        (state): State => {
            return {
                ...state,
                purchaseOrderHistoryLoadable: LoadableData.payload(undefined),
            };
        },
    )
    .toReducer();

export const selectPurchaseOrderHistory = (state: GlobalState) => state.purchaseOrderHistory.purchaseOrderHistoryLoadable;
