angular
  .module("dstreamApp.directives")
  .directive("removeNonNumbers", [
    "$filter",
    "$interval",
    "$q",
    function ($filter, $interval, $q) {
      return {
        restrict: "A",
        link: function (scope, element, attrs) {

          function reFormat (value) {
            var originalValue = angular.copy(value);
            originalValue = originalValue.replace(/[^0-9.]/g, "");
            if (!originalValue) {
              originalValue = 0;
            }
            if (originalValue > 100) {
              originalValue = 100;
            } else if (originalValue < 0) {
              originalValue = 0;
            }
            originalValue = $filter("number")(parseFloat(originalValue), 2);
            return angular.copy(originalValue);
          }

          var deferred = $q.defer();

          function getValue () {
            return deferred.promise;
          }

          var interval = $interval(function () {
            if (element[0].value || element[0].value === 0) {
              deferred.resolve(element[0].value);
              $interval.cancel(interval);
            }
          }, 100);

          getValue(element[0].value).then(
            function (response) {
              if (response) {
                element[0].value = reFormat(response);
              }
            }
          );

          element.bind("blur keyup change", function (event) {
            event.target.value = reFormat(event.target.value);
          });
        }
      };
    }
  ]);