'use strict';

angular
    .module('dstreamApp.components')
    .controller('DsChangedVersionReminderController', function($rootScope, webServices, $translate, $scope, $filter, $timeout, $window, $uibModalInstance, authenticationService, authenticationInterceptor) {
        
        $scope.cancel = function (e) {
            e.preventDefault();
            $uibModalInstance.close(true);
        };

        $scope.reloadPage = function (e) {
            e.preventDefault();
            $window.location.reload(true);
        };
    });