import { AxiosInstance, AxiosResponse } from 'axios';
import { BackOfficeIntegratorDTO } from '../types/BoApiTypes';
import { BaseSearch, PagedListContainerOf } from '../types/ApiTypes';

export default class BoIntegratorsService {
    constructor(private axios: AxiosInstance) {}

    getIntegrators = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainerOf<BackOfficeIntegratorDTO>>> => {
        return this.axios.post('/Integrator/GetIntegrators', searchParams);
    };

    saveIntegrator = async (integrator: BackOfficeIntegratorDTO): Promise<AxiosResponse<BackOfficeIntegratorDTO>> => {
        return this.axios.post('/Integrator/SaveIntegrator', integrator);
    };
}
