import classNames from 'classnames';
import * as React from 'react';

export interface ContentBlockBodyProps {
    loading?: boolean;
    children?: React.ReactNode;
    className?: string;
    type?: ContentBlockBodyType;
    dataId: string;
}

export enum ContentBlockBodyType {
    DEFAULT = 'DEFAULT',
}

export const ContentBlockBody: React.StatelessComponent<ContentBlockBodyProps> = ({ children, className, type, loading, dataId }: ContentBlockBodyProps) => {
    const classes = classNames(className, 'content-block__body', `content-block__body--${type.toString().toLowerCase()}`, { 'content-block__body--loading': loading });
    return (
        <div className={classes} data-id={dataId}>
            {children}
        </div>
    );
};

ContentBlockBody.defaultProps = {
    type: ContentBlockBodyType.DEFAULT,
};
