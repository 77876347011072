import { ReducerFactory } from 'redux-actions-ts-reducer';

import { GlobalState } from '../../rootReducer';
import { FileDTO } from '../../services/types/ApiTypes';
import { setPdfToOpenAction } from './FileViewActions';

class State {
    currentFile: FileDTO = null;
}

export { State as FileViewState };

export default new ReducerFactory(new State())
    .addReducer(
        setPdfToOpenAction,
        (state, action): State => {
            return {
                ...state,
                currentFile: action.payload,
            };
        },
    )
    .toReducer();

export const selectPdfToOpen = (state: GlobalState) => state.fileViewData.currentFile;
