import React, { useState } from 'react';
import classNames from 'classnames';

import { createDataId } from '../../common/utils/dataId';
import Icon, { ICONS, IconSize } from '../Icon/Icon';

import './CollapsibleInfoBlockWithHeader.scss';

interface Props {
    dataId: string;
    headerText: string;
    isCollapsible?: boolean;
}

const CollapsibleInfoBlockWithHeader: React.FunctionComponent<Props> = (props) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const toggleCollapsed = () => {
        if (props.isCollapsible) {
            setIsCollapsed(!isCollapsed);
        }
    };

    return (
        <div className={classNames('info-block', { 'info-block--collapsed': isCollapsed })}>
            <div
                className={classNames('info-block__header', { 'info-block__header--collapsible': props.isCollapsible })}
                onClick={toggleCollapsed}
                data-id={createDataId(props.dataId, 'info-block', 'header')}
            >
                <h4>{props.headerText}</h4>
                {props.isCollapsible && (
                    <Icon
                        size={IconSize.SM}
                        name={ICONS.CHEVRON_UP_24}
                        className={classNames('header-icon', { 'header-icon--collapsed': isCollapsed })}
                        data-id={createDataId(props.dataId, 'collapse-icon')}
                    ></Icon>
                )}
            </div>
            <div className={classNames('children', { 'children--collapsed': isCollapsed })}>{props.children}</div>
        </div>
    );
};

export default CollapsibleInfoBlockWithHeader;
