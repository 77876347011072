'use strict';

angular.module('dstreamApp.services').service('signalrSvc', ['$', '$rootScope', function ($, $rootScope) {
	var proxy = null;
	var connectionId = null;

	var initialize = function () {

		// fetch connection object and create proxy
		var connection = $.hubConnection();
		connection.url = "../WebApi/signalr";
		this.proxy = connection.createHubProxy('widgets');

		this.proxy.on('broadcastMessage', function (data) {
		    $rootScope.$emit("newWidget", data);
		});

		// start connection
		connection.start().done(function () {
			console.log('Now connected, connection ID=' + connection.id);
		});
	};

	return {
		initialize: initialize
	};
}
]);