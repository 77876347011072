import { isEmpty } from 'lodash-es';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { Dispatchable1, Dispatchable2, Dispatchable3 } from 'redux-dispatchers';

import { InvoiceStatus } from '../../../../common/constants/appConstants';
import { isAuthorized, Role } from '../../../../common/user/userPermissionUtil';
import { addDataId } from '../../../../common/utils/dataId';
import { BaseStatefulComponent } from '../../../../components/BaseStatefulComponent';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { Switch } from '../../../../components/Switch/Switch';
import { ConfirmationPopup } from '../../../../components/ConfirmationPopup/ConfirmationPopup';
import { ToggleContent, ToggleContentType } from '../../../../components/ToggleContent/ToggleContent';
import { Typography } from '../../../../components/Typography';
import { InvoiceDTO } from '../../../../services/types/ApiTypes';
import classNames from 'classnames';

import './InvoiceExportManagement.scss';

export interface Props {
    invoice: InvoiceDTO;
    invoiceLoading: boolean;
    reloadInvoiceData: (invoiceId: number, callbackEmit: string, invoiceRowPage: number) => void;
    setInvoiceExportAfterApproval: (exportValue: boolean) => void;
}

export interface DispatchProps {
    getInvoiceData: Dispatchable1<string>;
    exportStatusReset: Dispatchable2<string, () => void>;
    updateInvoiceExportState: Dispatchable3<string, boolean, () => void>;
}

export type InvoiceExportManagementProps = Props & DispatchProps & WithTranslation;

export interface InvoiceExportManagementState {
    exportEnabled: boolean;
    isConfirmVisible: boolean;
}

class InvoiceExportManagement extends BaseStatefulComponent<InvoiceExportManagementProps, InvoiceExportManagementState> {
    constructor(props: InvoiceExportManagementProps) {
        super(props);

        this.state = {
            exportEnabled: true,
            isConfirmVisible: false,
        };
    }

    isCheckboxDisabled = () => {
        return (
            !this.props.invoice ||
            !this.canChangeExportSetting() ||
            [InvoiceStatus.Exported, InvoiceStatus.PendingExport, InvoiceStatus.Archived, InvoiceStatus.Deleted, InvoiceStatus.NotForExport].includes(this.props.invoice.Status)
        );
    };
    showResendToExport = () => {
        if (this.props.invoice) {
            return this.canChangeExportSetting() && [InvoiceStatus.Exported, InvoiceStatus.Archived].includes(this.props.invoice.Status);
        }
        return false;
    };

    canChangeExportSetting = () => isAuthorized(Role.CanChangeExportSettings);
    handleReloadInvoiceData = () => {
        this.props.reloadInvoiceData(this.props.invoice.Id, '', 1);
    };
    handleExportStateChange = () => {
        const exportEnabled = !this.state.exportEnabled;
        const isConfirmVisible = exportEnabled !== !!this.props.invoice.IsExportAfterApprovalEnabled;
        this.setState({ exportEnabled, isConfirmVisible });
    };
    handleSaveExportStateChange = () => {
        const { Id: invoiceId } = this.props.invoice;
        if (!invoiceId) {
            // if we don't have invoiceId, we are creating a new Invoice and have to sync the invoice value to the angularJS
            this.props.setInvoiceExportAfterApproval(this.state.exportEnabled);
        } else {
            this.props.updateInvoiceExportState(invoiceId.toString(10), this.state.exportEnabled, this.handleReloadInvoiceData);
        }
        this.setState({ isConfirmVisible: false });
    };
    handleExportStatusReset = () => {
        const { Id: invoiceId } = this.props.invoice;
        if (invoiceId !== undefined) {
            this.props.exportStatusReset(this.props.invoice.Id.toString(10), this.handleReloadInvoiceData);
        }
    };

    componentDidMount(): void {
        if (this.props.invoice !== undefined) {
            if (this.props.invoice.IsExportAfterApprovalEnabled !== undefined) {
                this.setState({
                    exportEnabled: this.props.invoice.IsExportAfterApprovalEnabled,
                });
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<Props & DispatchProps & WithTranslation>): void {
        if (this.props.invoice) {
            if (!prevProps.invoice || (!!prevProps.invoice && prevProps.invoice.IsExportAfterApprovalEnabled !== this.props.invoice.IsExportAfterApprovalEnabled)) {
                this.setState({
                    exportEnabled: !!this.props.invoice.IsExportAfterApprovalEnabled,
                });
            }
        }
    }

    render() {
        const { t, invoice } = this.props;
        const isContentReadOnly = !this.canChangeExportSetting();
        const contentClasses = classNames('export-management__content', { 'export-management__content--read-only': isContentReadOnly });
        return (
            !isEmpty(invoice) && (
                <ToggleContent
                    className="export-management__container"
                    type={ToggleContentType.CONTENT_BLOCK}
                    toggleContent={
                        <Typography variant="h2" element="span">
                            {t('component.exportManagement.heading')}
                        </Typography>
                    }
                    defaultOpen={true}
                    isContentReadOnly={isContentReadOnly}
                >
                    <section className={'export-management'}>
                        <div className={contentClasses}>
                            {isContentReadOnly ? (
                                t('component.exportManagement.error.noPermissions')
                            ) : (
                                <Switch
                                    dataId="ExportAfterApprovalEnabled.switch"
                                    name={'ExportAfterApprovalEnabled'}
                                    label={t('component.exportManagement.ExportAfterApproval')}
                                    disabled={this.isCheckboxDisabled()}
                                    value={this.state.exportEnabled}
                                    onChange={this.handleExportStateChange}
                                />
                            )}
                        </div>
                        {(this.state.isConfirmVisible || this.showResendToExport()) && !isContentReadOnly && (
                            <div className={'export-management__action-wrap'}>
                                {this.state.isConfirmVisible && (
                                    <Button dataId={addDataId('ExportAfterApprovalEnabled', '.confirm')} expand={true} buttonType={ButtonType.SECONDARY} onClick={this.handleSaveExportStateChange}>
                                        {t('component.exportManagement.confirm')}
                                    </Button>
                                )}
                                {this.showResendToExport() && (
                                    <ConfirmationPopup onConfirm={this.handleExportStatusReset} content={t('component.ExportManagement.ResetExportStatus.Confirmation')}>
                                        <Button buttonType={ButtonType.SECONDARY} expand={true} dataId={addDataId('ResetInvoiceExportStatus', '.button')}>
                                            {t('component.exportManagement.exportStatusReset')}
                                        </Button>
                                    </ConfirmationPopup>
                                )}
                            </div>
                        )}
                    </section>
                </ToggleContent>
            )
        );
    }
}

export default InvoiceExportManagement;
