import classNames from 'classnames';
import * as React from 'react';
import { TFunction } from 'i18next';

import { BaseComponent } from '../BaseComponent';
import Icon, { ICONS } from '../Icon/Icon';
import ContentBlockSearch, { ContentBlockSearchProps } from './ContentBlockSearch';
import TableFilters, { TableFiltersProps } from '../../components/Table/components/filter/TableFilters';
import ContentBlockHeading, { ContentBlockHeadingType } from './ContentBlockHeading';
import { createDataId, DataId } from '../../common/utils/dataId';
import { DateRangeValues } from '../CalendarDateRangeModal/CalendarDateRangeModal';
import { SelectOptionValue } from '../Select/SelectOption';
import DateRangeFilter from './DateRangeFilter';
import Tooltip from '../Tooltip/Tooltip';

export interface ContentBlockHeaderProps {
    className?: string;
    contentClassName?: string;
    hasStaticFilters?: boolean; // advanced filters under search input, activated by filter button
    hasBackButton?: boolean;
    backButtonTooltipContent?: string;
    hasSearch?: boolean;
    onBackClick?: () => void;
    type?: ContentBlockHeaderType;
    t?: TFunction;
    hasDateFilter?: boolean;
    dateRange?: [Date, Date];
    datePickerActiveScope?: string;
    datePickerScopeList?: SelectOptionValue<string>[];
    datePickerLabel?: string;
    onDateRangeChange?: (val: DateRangeValues) => void;
    dataId: DataId;
    filterable?: boolean;
    fullScreenElementId?: string;
    searchOptions: Pick<
        ContentBlockSearchProps,
        | 'className'
        | 'onChange'
        | 'onDateRangeChange'
        | 'value'
        | 'dateRange'
        | 'datePickerActiveScope'
        | 'datePickerScopeList'
        | 'dataId'
        | 'disabled'
        | 'hasDateFilter'
        | 'withSearchButton'
        | 'withFilterButton'
        | 'onClick'
        | 'quickFilterMenu'
        | 'quickFilterMenuBeforeSearch'
        | 'quickFilterLabel'
    >;
    filterOptions: TableFiltersProps;
    title?: React.ReactNode;
}

export enum ContentBlockHeaderType {
    DEFAULT = 'DEFAULT',
    COMPACT = 'COMPACT',
    TABLE_HEADER = 'TABLE_HEADER',
}

export class ContentBlockHeader extends BaseComponent<ContentBlockHeaderProps> {
    state = {
        areFiltersOpen: false,
    };

    static defaultProps: Partial<ContentBlockHeaderProps> = {
        type: ContentBlockHeaderType.DEFAULT,
    };

    handleBackClick = () => {
        if (this.props.onBackClick) {
            this.props.onBackClick();
        } else {
            history.back();
        }
    };

    toggleFiltersOpen = () => this.setState({ areFiltersOpen: !this.state.areFiltersOpen });

    render() {
        const {
            hasBackButton,
            hasSearch,
            hasDateFilter,
            onDateRangeChange,
            dateRange,
            datePickerActiveScope,
            datePickerScopeList,
            datePickerLabel,
            className,
            contentClassName,
            children,
            hasStaticFilters,
            type,
            t,
            dataId,
            searchOptions,
            filterable,
            filterOptions,
            title,
            backButtonTooltipContent,
            fullScreenElementId,
        } = this.props;
        const classes = classNames(
            'content-block__header',
            { 'content-block__header--has-back': hasBackButton },
            { 'content-block__header--has-search': hasSearch },
            { 'content-block__header--compact': type === ContentBlockHeaderType.COMPACT },
            { 'content-block__header--table': type === ContentBlockHeaderType.TABLE_HEADER },
            className,
        );
        const contentClasses = classNames('content-block__header-content', contentClassName);
        const filterClasses = classNames('content-block__header-filters', { 'static-filters': hasStaticFilters });
        return (
            <>
                <div className={classes} data-id={dataId}>
                    <div className={contentClasses}>
                        {hasBackButton &&
                            (backButtonTooltipContent ? (
                                <Tooltip appendTo={document.getElementById(fullScreenElementId)} content={backButtonTooltipContent}>
                                    <button type="button" className="content-block__back" onClick={this.handleBackClick} data-id="contentBlockBackButton">
                                        <Icon name={ICONS.ARROW_LEFT_24} />
                                    </button>
                                </Tooltip>
                            ) : (
                                <button type="button" className="content-block__back" onClick={this.handleBackClick} data-id="contentBlockBackButton">
                                    <Icon name={ICONS.ARROW_LEFT_24} />
                                </button>
                            ))}
                        {type === ContentBlockHeaderType.TABLE_HEADER ? (
                            <>
                                <ContentBlockHeading dataId={createDataId(dataId, 'title')} type={ContentBlockHeadingType.BOLD}>
                                    {title}
                                </ContentBlockHeading>
                                <div className="content-block__header-item">{children}</div>
                            </>
                        ) : (
                            children
                        )}
                    </div>
                </div>
                {(hasSearch || filterable) && (
                    <div className={'content-block__header-extends'}>
                        <div className="content-block__extends-contents">
                            {hasSearch && (
                                <ContentBlockSearch
                                    {...searchOptions}
                                    datePickerLabel={datePickerLabel}
                                    datePickerScopeList={datePickerScopeList}
                                    datePickerActiveScope={datePickerActiveScope}
                                    hasActiveFilters={filterOptions?.appliedFilters?.some((f) => f.values?.length)}
                                    onFiltersClick={this.toggleFiltersOpen}
                                />
                            )}

                            {filterable && filterOptions.appliedFilters && filterOptions.appliedFilters.length > 0 && (
                                <div className={filterClasses} data-id="po-filters">
                                    <TableFilters {...filterOptions} hasStaticFilters={hasStaticFilters} areOpen={this.state.areFiltersOpen} />
                                </div>
                            )}
                            {hasDateFilter && this.state.areFiltersOpen && (
                                <DateRangeFilter
                                    wrapperClass={classNames(filterClasses, 'date-picker')}
                                    dataId={createDataId(dataId, 'date-range')}
                                    label={datePickerLabel}
                                    datePickerActiveScope={datePickerActiveScope}
                                    datePickerScopeList={datePickerScopeList}
                                    dateRange={dateRange}
                                    placeholder={t ? t('view.PurchaseOrders.Invoices.Filter.Any') : null}
                                    onChange={onDateRangeChange}
                                />
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    }
}
