import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import startOfWeek from 'date-fns/startOfWeek';
import lastOfWeek from 'date-fns/lastDayOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import lastOfMonth from 'date-fns/lastDayOfMonth';
import startOfYear from 'date-fns/startOfYear';
import lastOfYear from 'date-fns/lastDayOfYear';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInDays from 'date-fns/differenceInDays';
import subYears from 'date-fns/subYears';

import { formatDate } from './formatters';

export const addDaysFromToday = (numberOfDays: number): Date => {
    return addDays(new Date(), numberOfDays);
};

export const addDaysFromDate = (date: Date, numberOfDays: number): Date => {
    return addDays(date, numberOfDays);
};

export const subtractDaysFromToday = (numberOfDays: number): Date => {
    return subDays(new Date(), numberOfDays);
};

export const subtractDaysFromDate = (date: Date, numberOfDays: number): Date => {
    return subDays(date, numberOfDays);
};

export const addMonthsFromToday = (numberOfMonths: number): Date => {
    return addMonths(new Date(), numberOfMonths);
};

export const subtractMonthsFromToday = (numberOfMonths: number): Date => {
    return subMonths(new Date(), numberOfMonths);
};

export const subtractMonthsFromDate = (date: Date, numberOfMonths: number): Date => {
    return subMonths(date, numberOfMonths);
};

export const getFirstDateOfWeek = (date: Date): Date => {
    return startOfWeek(date, { weekStartsOn: 1 });
};

export const getLastDateOfWeek = (date: Date): Date => {
    return lastOfWeek(date, { weekStartsOn: 1 });
};

export const getFirstDateOfMonth = (date: Date): Date => {
    return startOfMonth(date);
};

export const getLastDateOfMonth = (date: Date): Date => {
    return lastOfMonth(date);
};

export const getFirstDateOfYear = (date: Date): Date => {
    return startOfYear(date);
};

export const getLastDateOfYear = (date: Date): Date => {
    return lastOfYear(date);
};

export const subtractYearFromDate = (date: Date, numberOfYears: number): Date => {
    return subYears(date, numberOfYears);
};

export const getDifferenceInMonths = (date1: Date, date2: Date) => {
    return differenceInMonths(date1, date2);
};

export const getDifferenceInDays = (date1: Date, date2: Date) => {
    return differenceInDays(date1, date2);
};

export const getNoTimeDate = (d: Date): Date => new Date(d.setHours(0, 0, 0, 0));

export const getEndTimeDate = (d: Date): Date => new Date(d.setHours(23, 59, 59));

export enum DefaultTimeRange {
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY',
    TOMORROW = 'TOMORROW',
    THIS_WEEK = 'THIS_WEEK',
    LAST_WEEK = 'LAST_WEEK',
    NEXT_WEEK = 'NEXT_WEEK',
    THIS_MONTH = 'THIS_MONTH',
    LAST_MONTH = 'LAST_MONTH',
    NEXT_MONTH = 'NEXT_MONTH',
    LAST_90_DAYS = 'LAST_90_DAYS',
    LAST_180_DAYS = 'LAST_180_DAYS',
    THIS_YEAR = 'THIS_YEAR',
    LAST_YEAR = 'LAST_YEAR',
    CUSTOM = 'CUSTOM',
}

export const getDefaultRangeValues = (r: DefaultTimeRange) => {
    let rangeValues: [Date, Date];
    let date = new Date();
    let date2;
    let year;
    switch (r) {
        case DefaultTimeRange.YESTERDAY:
            date = subtractDaysFromToday(1);
            rangeValues = [date, date];
            break;
        case DefaultTimeRange.TOMORROW:
            date = addDaysFromToday(1);
            rangeValues = [date, date];
            break;
        case DefaultTimeRange.THIS_WEEK:
            rangeValues = [getFirstDateOfWeek(date), getLastDateOfWeek(date)];
            break;
        case DefaultTimeRange.LAST_WEEK:
            date = subtractDaysFromToday(7);
            rangeValues = [getFirstDateOfWeek(date), getLastDateOfWeek(date)];
            break;
        case DefaultTimeRange.NEXT_WEEK:
            date = addDaysFromToday(7);
            rangeValues = [getFirstDateOfWeek(date), getLastDateOfWeek(date)];
            break;
        case DefaultTimeRange.THIS_MONTH:
            rangeValues = [getFirstDateOfMonth(date), getLastDateOfMonth(date)];
            break;
        case DefaultTimeRange.LAST_MONTH:
            date = subtractMonthsFromToday(1);
            rangeValues = [getFirstDateOfMonth(date), getLastDateOfMonth(date)];
            break;
        case DefaultTimeRange.NEXT_MONTH:
            date = addMonthsFromToday(1);
            rangeValues = [getFirstDateOfMonth(date), getLastDateOfMonth(date)];
            break;
        case DefaultTimeRange.LAST_90_DAYS:
            date2 = subtractDaysFromToday(90);
            rangeValues = [date2, date];
            break;
        case DefaultTimeRange.LAST_180_DAYS:
            date2 = subtractDaysFromToday(180);
            rangeValues = [date2, date];
            break;
        case DefaultTimeRange.THIS_YEAR:
            rangeValues = [getFirstDateOfYear(date), getLastDateOfYear(date)];
            break;
        case DefaultTimeRange.LAST_YEAR:
            year = date.getFullYear();
            rangeValues = [new Date(getFirstDateOfYear(date).setFullYear(year - 1)), new Date(getLastDateOfYear(date).setFullYear(year - 1))];
            break;
        default:
            rangeValues = [date, date];
    }
    return rangeValues;
};

export const isDate = (obj: any): boolean => {
    return obj instanceof Date && !isNaN(obj.valueOf());
};

export const removeTimeZone = (date: Date) => {
    if (isDate(date)) {
        return new Date(formatDate(date, 'yyyy-MM-dd'))?.toISOString().slice(0, -1);
    }
    return null;
};

export const getRangeEnumFromDates = (startDate: Date, endDate: Date): DefaultTimeRange => {
    if (!startDate || !endDate) {
        return DefaultTimeRange.CUSTOM;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const dateRanges: Record<Exclude<DefaultTimeRange, DefaultTimeRange.CUSTOM>, [Date, Date]> = {
        [DefaultTimeRange.TODAY]: [today, today],
        [DefaultTimeRange.YESTERDAY]: [subtractDaysFromDate(today, 1), subtractDaysFromDate(today, 1)],
        [DefaultTimeRange.TOMORROW]: [addDays(today, 1), addDays(today, 1)],
        [DefaultTimeRange.THIS_WEEK]: [getFirstDateOfWeek(today), getLastDateOfWeek(today)],
        [DefaultTimeRange.LAST_WEEK]: [getFirstDateOfWeek(subtractDaysFromDate(today, 7)), getLastDateOfWeek(subtractDaysFromDate(today, 7))],
        [DefaultTimeRange.NEXT_WEEK]: [getFirstDateOfWeek(addDays(today, 7)), getLastDateOfWeek(addDays(today, 7))],
        [DefaultTimeRange.THIS_MONTH]: [getFirstDateOfMonth(today), getLastDateOfMonth(today)],
        [DefaultTimeRange.LAST_MONTH]: [getFirstDateOfMonth(subtractMonthsFromDate(today, 1)), getLastDateOfMonth(subtractMonthsFromDate(today, 1))],
        [DefaultTimeRange.NEXT_MONTH]: [getFirstDateOfMonth(addMonths(today, 1)), getLastDateOfMonth(addMonths(today, 1))],
        [DefaultTimeRange.LAST_90_DAYS]: [subtractDaysFromDate(today, 90), today],
        [DefaultTimeRange.LAST_180_DAYS]: [subtractDaysFromDate(today, 180), today],
        [DefaultTimeRange.THIS_YEAR]: [getFirstDateOfYear(today), getLastDateOfYear(today)],
        [DefaultTimeRange.LAST_YEAR]: [getFirstDateOfYear(subtractYearFromDate(today, 1)), getLastDateOfYear(subtractYearFromDate(today, 1))],
    };

    for (const range in dateRanges) {
        if (range) {
            const [start, end] = dateRanges[range as DefaultTimeRange];
            const isRangeExists = startDate.getTime() === start.getTime() && endDate.getTime() === end.getTime();
            if (isRangeExists) {
                return range as DefaultTimeRange;
            }
        }
    }

    return DefaultTimeRange.CUSTOM;
};

export const isISOString = (date: string): boolean => {
    const ISO_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?([+-]\d{2}:\d{2}|Z)?$/;
    return ISO_REGEX.test(date);
};
