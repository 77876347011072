import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState, ReactElement } from 'react';

import { isAuthorized, Role } from '../../../common/user/userPermissionUtil';
import { measureSubmenu } from '../../../common/utils/measureDomNode';
import { OutsideEventListener } from '../../../common/utils/OutsideEventListener';
import Icon, { ICONS } from '../../Icon/Icon';
import { CurrentUserContext } from '../MainMenu';
import { createDataId } from '../../../common/utils/dataId';
import { menuLockStatusSelector, setPromptDialogShow, setConfirmLocation } from '../../../common/menu-lock/MenuLockReducer';

export interface MainMenuItemProps {
    children?: React.ReactNode;
    icon?: string;
    href?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    type?: MainMenuItemType;
    className?: string;
    onSubMenuOpen?: (isOpen: boolean) => void;
    isSubMenuOpen?: boolean;
    currentPath?: string;
    isMenuOpen?: boolean;
    toggleSubMenuOpen?: (isOpen: boolean) => void;
    visibleForRole?: Role;
    dataId: string;
}

export enum MainMenuItemType {
    DEFAULT = 'DEFAULT',
    WITH_SUBMENU = 'WITH_SUBMENU',
    TOGGLE = 'TOGGLE',
}

function isPageOrSubPage(currentPath: string, href: string) {
    let path = currentPath;
    let formattedHref = href && href.replace('#', '');
    if (path && !path.endsWith('/')) {
        path = path + '/';
    }
    if (formattedHref && !formattedHref.endsWith('/')) {
        formattedHref = formattedHref + '/';
    }
    return path && formattedHref && path.includes(formattedHref);
}

function useHookWithRefCallback<T>(): [T, (node: any) => void] {
    const ref = useRef<T>();
    const [currentNode, setCurrentNode] = useState<T>(undefined);
    const setRef = useCallback((node) => {
        ref.current = node;
        setCurrentNode(node);
    }, []);

    return [currentNode, setRef];
}

export const MainMenuItem: React.FunctionComponent<MainMenuItemProps> = ({
    children,
    href,
    icon,
    onClick,
    type,
    className,
    onSubMenuOpen,
    currentPath,
    isMenuOpen,
    toggleSubMenuOpen,
    visibleForRole,
    dataId,
    ...props
}: MainMenuItemProps) => {
    const isMenuLocked = useSelector(menuLockStatusSelector);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);
    const [subMenuNode, setSubMenuRef] = useHookWithRefCallback<HTMLUListElement>();
    const itemRef = useRef<HTMLElement>();
    const isMenuItemActive = isPageOrSubPage(currentPath, href);
    let outsideEventListener: OutsideEventListener;
    const hasSubMenu = type === MainMenuItemType.WITH_SUBMENU;
    const currentUser = useContext(CurrentUserContext);
    const dispatch = useDispatch();

    useEffect(() => {
        // register event listeners to close sub-menu when clicked outside of item containing the submenu
        if (hasSubMenu) {
            if (outsideEventListener) {
                outsideEventListener.stop();
            }
            outsideEventListener = new OutsideEventListener(itemRef, () => {
                if (!isMenuOpen) {
                    window.setTimeout(() => {
                        toggleSubMenu(false);
                    }, 200);
                }
            });
            outsideEventListener.start();
        }

        return () => {
            if (outsideEventListener) {
                outsideEventListener.stop();
            }
        };
    }, [itemRef.current, isMenuOpen, hasSubMenu]);

    useLayoutEffect(() => {
        // update the submenu height according to the isSubMenuOpen status
        if (subMenuNode) {
            const { height } = measureSubmenu(subMenuNode);
            if (isSubMenuOpen) {
                subMenuNode.style.height = height + 'px';
            } else {
                subMenuNode.style.height = '0px';
            }
        }
    }, [isSubMenuOpen, subMenuNode]);

    useEffect(() => {
        // open the sub menu when sub-item is active and we have the menu opened, close otherwise
        if (isMenuOpen && isMenuItemActive && !isSubMenuOpen && toggleSubMenuOpen) {
            toggleSubMenuOpen(true);
        } else if (toggleSubMenuOpen && isMenuItemActive && !isMenuOpen) {
            toggleSubMenuOpen(false);
        }
    }, [isMenuOpen]);

    useEffect(() => {
        toggleSubMenu(props.isSubMenuOpen);
    }, [props.isSubMenuOpen]);

    useEffect(() => {
        toggleSubMenu(false);
    }, [isMenuOpen]);

    function toggleSubMenu(isOpen: boolean) {
        setSubMenuOpen(isOpen);
        if (onSubMenuOpen) {
            onSubMenuOpen(isOpen);
        }
        if (itemRef.current) {
            // trigger resize event after animation to update scrollbar visibility
            const event = document.createEvent('HTMLEvents');
            event.initEvent('scroll', true, false);
            window.setTimeout(() => {
                if (itemRef.current) {
                    itemRef.current.dispatchEvent(event);
                }
            }, 500);
        }
    }

    function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
        if (hasSubMenu) {
            toggleSubMenu(!isSubMenuOpen);
        }

        if (isMenuLocked && !hasSubMenu) {
            dispatch(setPromptDialogShow());
            dispatch(setConfirmLocation(href));
        }

        if (onClick) {
            onClick(e);
        }
    }

    const classes = classNames('main-menu__list-item', className, {
        'main-menu__list-item--toggle': type === MainMenuItemType.TOGGLE,
        'main-menu__list-item--has-submenu': type === MainMenuItemType.WITH_SUBMENU,
        'main-menu__list-item--is-open': isSubMenuOpen,
        'main-menu__list-item--is-active': isMenuItemActive,
    });
    const classesItemText = classNames('main-menu__item-text', className, {
        'main-menu__item-text--toggle': type === MainMenuItemType.TOGGLE,
    });
    const Tag = type === MainMenuItemType.TOGGLE ? 'span' : 'li';
    // seems like we are unable to preventDefault the navigation event on the hrefs if component is included with react2angular, so let's use button instead
    const ClickableTag: any = isMenuLocked ? 'button' : href ? 'a' : type === MainMenuItemType.TOGGLE ? 'div' : 'button';

    if (visibleForRole && !isAuthorized(visibleForRole, currentUser)) {
        return null;
    }
    return (
        <Tag className={classes} ref={itemRef as any}>
            <ClickableTag className="main-menu__item" href={href} onClick={type !== MainMenuItemType.TOGGLE ? handleClick : undefined} data-id={dataId}>
                {icon && type !== MainMenuItemType.TOGGLE && <Icon className="main-menu__item-icon" name={icon} />}{' '}
                {React.Children.map<ReactElement<any, any>, any>(children, (e) => {
                    if (e && e.type !== MainMenuItem) {
                        return <span className={classesItemText}>{e}</span>;
                    }
                    return null;
                })}
                {hasSubMenu && (
                    <span className={`main-menu__item-toggle ${isSubMenuOpen ? 'main-menu__item-toggle--is-open' : ''}`}>
                        <Icon name={ICONS.ARROW_DOWN_SMALL} />
                    </span>
                )}
            </ClickableTag>
            {hasSubMenu && (
                <ul ref={setSubMenuRef} className={`main-menu__sub-list ${isSubMenuOpen ? 'main-menu__sub-list--is-visible' : ''}`} data-id={createDataId('mainMenuSubMenu', dataId)}>
                    {React.Children.map(children, (e: ReactElement<MainMenuItemProps, any>) => {
                        if (e && e.type === MainMenuItem) {
                            return React.cloneElement(e, { currentPath, isMenuOpen, toggleSubMenuOpen: toggleSubMenu });
                        }
                        return null;
                    })}
                </ul>
            )}
        </Tag>
    );
};

MainMenuItem.defaultProps = {
    href: '',
};
