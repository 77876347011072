import { selectedLanguageKey, SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from '../../views/login/loginHelper';

export default function currentLanguage(): string {
    return storedLanguage() || DEFAULT_LANGUAGE;
}

export function storedLanguage() {
    try {
        const locale = localStorage.getItem(selectedLanguageKey);
        if (SUPPORTED_LANGUAGES.find((l) => l.value === locale)) {
            return locale;
        }
        return undefined;
    } catch (e) {
        return undefined;
    }
}
