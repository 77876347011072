(function() {
    'use strict';

    angular
        .module('dstreamApp.directives')
        .directive('onIframeLoad', onIframeLoad);

    onIframeLoad.$inject = [];

    function onIframeLoad() {
        return {
            restrict: 'A',
            scope: {
                onIframeLoad: '&'
            },
            link: linkFunc
        };

        function linkFunc(scope, element, attrs, ctrl) {
            element.on('load', loadEvent);

            function loadEvent() {
                // needs to have element inside element to bypass https://stackoverflow.com/questions/26909402/how-to-send-a-dom-function-to-a-directive-in-angularjs
                return scope.onIframeLoad({element: {element: element[0]}});
            }

            scope.$on('$destroy', function() {
               element.off('load', loadEvent);
            });
        }
    }
}());
