import { TFunction } from 'i18next';
import { Restriction, SearchType, SortDirection } from '../../../services/types/ApiTypes';

export const DEFAULT_RESTRICTION = 'IsEnabled';

export const createRequest = (searchValue = '', page = 1, count = 15, columnName = DEFAULT_RESTRICTION) => {
    const restrictions: Restriction[] = [];
    if (searchValue && searchValue.length > 0) {
        restrictions.unshift({
            Field: columnName,
            FieldSearchType: SearchType.NotSelected,
            Value: searchValue || null,
            Values: null,
        } as Restriction);
    }
    return {
        PagingOptions: {
            Count: count,
            Page: page,
        },
        Restrictions: [...restrictions],
        SortItems: [
            {
                SortColumn: DEFAULT_RESTRICTION,
                SortDirection: SortDirection.Asc,
            },
        ],
    };
};

export function getBoIntegratorListColumnName(columnName: string, translate?: TFunction): string {
    const columnNames = {
        IsEnabled: 'view.backOffice.integrators.column.enabled',
        IntegratorName: 'view.backOffice.integrators.column.name',
        IntegratorGuid: 'view.backOffice.integrators.column.id',
        ContactPerson: 'view.backOffice.integrators.column.contactPerson',
        Email: 'view.backOffice.integrators.column.email',
        Phone: 'view.backOffice.integrators.column.phone',
        LastActive: 'view.backOffice.integrators.column.lastActive',
        Notes: 'view.backOffice.integrators.column.notes',
    };
    if (translate) {
        return translate(columnNames[columnName]);
    }
    return columnNames[columnName];
}

export const ExportApiList = ['CLIENT_EXPORT', 'FILE_EXPORT', 'INVOICE_EXPORT', 'SUPPLIER_EXPORT', 'USER_EXPORT', 'TRANSACTION_ROWS_EXPORT'];

export const ImportApiList = [
    'INVOICE_IMPORT',
    'ACCOUNT_IMPORT',
    'CONFIRMATION_FLOW_ACTION_IMPORT',
    'DIMENSION_IMPORT',
    'COMPANY_IMPORT',
    'INVOICE_UPDATE_IMPORT',
    'INVOICE_HEADER_EXTENSION_LIST_VALUE_IMPORT',
    'PRODUCT_ITEM_IMPORT',
    'SUPPLIERS_IMPORT',
    'USER_IMPORT',
    'VAT_CODE_IMPORT',
    'TRANSACTION_ROWS_IMPORT',
];
