(function() {
    'use strict';

    angular
        .module('dstreamApp.directives')
        .directive('popOver', popOver);

    /* @ngInject */
    function popOver($compile, $templateCache, $parse) {
        var directive = {
            restrict: 'A',
            scope: {
                templateName: '=',
                placement: '=',
                onCancel: '&?onCancel',
                onConfirm: '&?onConfirm'
            },
            link: linkFunc
        }

        return directive;


        function linkFunc(scope, element, attrs) {

            scope.close = function () {
                if (scope.onCancel) {
                    scope.onCancel()
                }
                else {
                    $(element).popover("hide");
                }
            };

            scope.confirm = function () {
                if (scope.onConfirm) {
                    $(element).popover("hide");
                    scope.onConfirm();
                }
            };

            if (scope.templateName) {
                var popOverContent;
                //at moment popup is created : popover-inner --> popover-contents but it must be vice-versa
                var popOverHTML = "<div class='popover-inner' style='max-width:280px; padding: 20px'>" + $templateCache.get(scope.templateName) + '</div>';
                popOverContent =  $compile(popOverHTML)(scope) ;
                var options = {
                    content: popOverContent,
                    placement: scope.placement,
                    container: 'body',
                    trigger: 'outsideClick',
                    html: true,
                };
                $(element).popover(options);
            }
        }
    }
}());
