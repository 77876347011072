import { LOCATION_CHANGE } from 'connected-react-router';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { BackOfficeUserDTO } from '../../../../../services/types/BoApiTypes';
import { LoadableData } from '../../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../../rootReducer';

import { addBoUserActions, setActiveBoUser, showEditBoUserModal } from './EditBoUserModalActions';

class State {
    activeBoUser: BackOfficeUserDTO = null;
    isEditBoUserModalOpen = false;
    addBoUserLoadable = new LoadableData<BackOfficeUserDTO, BackOfficeUserDTO>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        setActiveBoUser,
        (state, action): State => {
            return {
                ...state,
                activeBoUser: action.payload,
            };
        },
    )
    .addReducer(
        showEditBoUserModal,
        (state, action): State => {
            let newState = {
                ...state,
                isEditBoUserModalOpen: action.payload,
            };
            if (!action.payload) {
                newState = {
                    ...newState,
                    activeBoUser: null,
                };
            }
            return newState;
        },
    )
    .addReducer(
        addBoUserActions.request,
        (state): State => {
            return {
                ...state,
                addBoUserLoadable: state.addBoUserLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        addBoUserActions.success,
        (state, action): State => {
            return {
                ...state,
                addBoUserLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        addBoUserActions.error,
        (state, action): State => {
            return {
                ...state,
                addBoUserLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'new-BoUser-modal',
    whitelist: [],
};

export default persistReducer(persistConfig, reducer);

export { State as EditBoUserModalState };

export const selectActiveBoUser = (state: GlobalState) => state.editBoUserModal.activeBoUser;
export const selectIsEditBoUserModalOpen = (state: GlobalState) => state.editBoUserModal.isEditBoUserModalOpen;
export const selectAddBoUserLoadable = (state: GlobalState) => state.editBoUserModal.addBoUserLoadable;
export const selectIsAllUsersView = (state: GlobalState) => state.boUserManagement.isAllUsersView;
