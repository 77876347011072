import {notify} from "../../src/common/utils/notify";

"use strict";

angular.module("dstreamApp.services").factory("notifyService", [
  "$filter",
  function ($filter) {
    var loadingCount = 0;

    return {
      success: function (text, title, doClear) {
        if (doClear) notify.dismiss();
        notify.success($filter("translate")(text), $filter("translate")(title) );
      },
      error: function (text, title, doClear) {
        if (doClear) notify.dismiss();
        if (text.constructor === Array) {
          var combinedText = "";
          _.each(text, function (msg) {
            combinedText += $filter("translate")(msg) + "<br />";
          });
          notify.error(combinedText, $filter("translate")(title));
        } else {
          notify.error($filter("translate")(text), $filter("translate")(title));
        }
      },
      warning: function (text, title, doClear, options) {
        if (doClear) notify.dismiss();
        notify.warning(text, $filter("translate")(title), options);
      },
      info: function (text, title, doClear) {
        if (doClear) notify.dismiss();
        notify.info($filter("translate")(text), $filter("translate")(title));
      },
      loading: function (text, title) {
        if (loadingCount === 0) {
          notify.info($filter("translate")(text), $filter("translate")(title));
        }
        loadingCount++;
      },
      loadingClear: function () {
        loadingCount--;
        if (loadingCount <= 0) {
          // notify.remove();
          if (loadingCount < 0) {
            loadingCount = 0;
          }
        }
      }
    };
  }
]);
