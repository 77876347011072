import classNames from 'classnames';
import * as React from 'react';

import { BaseComponent } from '../BaseComponent';

export interface MainComponentContentProps {
    children?: React.ReactNode;
    className?: string;
}

export class MainComponentContent extends BaseComponent<MainComponentContentProps> {
    render() {
        const { children, className, ...rest } = this.props;
        const classes = classNames('table', 'table-main', className);
        return (
            <div className={classes} {...rest}>
                {children}
            </div>
        );
    }
}

export default MainComponentContent;
