import { isNumber, round, get, set, cloneDeep, isEmpty } from 'lodash-es';
import Big from 'big.js';

import storeAndPersistor from '../../../../storeConfig';
import { InvoiceDTO, InvoiceRowDTO, InvoiceRowDiscountDTO, InvoiceRowDiscountType, ItemDetailInfoDTO } from '../../../../services/types/ApiTypes';
import api from '../../../../services/ApiServices';
import { TypeaheadItem } from '../../../../components/Typeahead/TypeaheadAsync';
import { StampType } from '../../../../components/StampLabel/StampLabel';
import { selectCurrentCompanySettings } from '../../../../common/company/CompanySelectors';
import { DECIMAL_ROWS_2_REGEXP, DECIMAL_ROWS_4_REGEXP, DECIMAL_ROWS_7_REGEXP } from '../../../../common/utils/validators';
import { areTwoNumsEqualWithPrecision } from '../invoice-header/utils';
import { CompanySettingStatus } from '../../../../views/settings/company-settings/components/Settings/CompanySettingsListHelper';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

const store = storeAndPersistor.store;

export interface InvoiceRowFields {
    InvoiceId: number;
    Id: number;
    InvoiceCountInUse: number;
    Description: string;
    IsNew: boolean;
    SellerProductId: string;
    SerialNumber: number;
    SumWithoutVat: number;
    BuyerProductId: string;
    CustomerRef: string;
    Total: number;
    VAT: number;
    VatRate: TypeaheadItem<number>;
    TaricCode: number;
    ItemDetailInfo: ItemDetailInfoDTO[];
}

export const rowsSumsWrong = (invoice: InvoiceDTO) => {
    return (invoice?.InvoiceRowsPrice || invoice?.InvoiceRowsPrice === 0) && !areTwoNumsEqualWithPrecision(invoice?.InvoiceRowsPrice, invoice?.SumWithoutVat);
};

export const rowsVatSumsWrong = (invoice: InvoiceDTO) => {
    return (invoice?.InvoiceRowsVAT || invoice?.InvoiceRowsVAT === 0) && !areTwoNumsEqualWithPrecision(invoice?.InvoiceRowsVAT, invoice?.Vat);
};

export const rowsTotalWrong = (invoice: InvoiceDTO) => {
    return (invoice?.InvoiceRowsTotal || invoice?.InvoiceRowsTotal === 0) && !areTwoNumsEqualWithPrecision(invoice?.InvoiceRowsTotal, invoice?.TotalAmountWithVat);
};

export const areInvoiceRowsSumsNotMatching = (invoice: InvoiceDTO) => {
    if (!invoice) {
        return false;
    }
    const rowsSumsWrong = (invoice.InvoiceRowsPrice || invoice.InvoiceRowsPrice === 0) && !areTwoNumsEqualWithPrecision(invoice.InvoiceRowsPrice, invoice.SumWithoutVat);
    const rowsVatSumsWrong = (invoice.InvoiceRowsVAT || invoice.InvoiceRowsVAT === 0) && !areTwoNumsEqualWithPrecision(invoice.InvoiceRowsVAT, invoice.Vat);
    const rowsTotalWrong = (invoice.InvoiceRowsTotal || invoice.InvoiceRowsTotal === 0) && !areTwoNumsEqualWithPrecision(invoice.InvoiceRowsTotal, invoice.TotalAmountWithVat);

    const areSumsNotMatching = rowsSumsWrong || rowsVatSumsWrong || rowsTotalWrong;
    return areSumsNotMatching;
};

export const getProductItems = async (name: string) => {
    const response = await api.invoice.getProductItems(name);
    return response.data.map((e) => {
        return {
            value: { FullName: e.FullName, Code: e.Code },
            text: e.FullName,
        };
    });
};

export const getQtyPrecision = () => {
    const companySettings = selectCurrentCompanySettings(store.getState());
    const companySetting = companySettings.find((c) => c.Name === 'MonetaryAmountsQuantityPrecision');
    if (companySetting?.Value) {
        return Number(companySetting?.Value);
    } else {
        return 7;
    }
};

export const getPricePrecision = () => {
    const companySettings = selectCurrentCompanySettings(store.getState());
    const companySetting = companySettings.find((c) => c.Name === 'MonetaryAmountsItemPricePrecision');
    if (companySetting?.Value) {
        return Number(companySetting?.Value);
    } else {
        return 7;
    }
};

export const getInputRegExp = (value: number) => {
    switch (value) {
        case 2:
            return DECIMAL_ROWS_2_REGEXP;
        case 4:
            return DECIMAL_ROWS_4_REGEXP;
        case 7:
            return DECIMAL_ROWS_7_REGEXP;
        default:
            return DECIMAL_ROWS_7_REGEXP;
    }
};

function roundBig(value: Big, dp = 2, toNegative?: boolean) {
    if (toNegative) {
        return String(round(-Math.abs(Number(value)), dp));
    }
    return String(round(Number(value), dp));
}

export const getDiscountBeforeIcon = (totalAmount: number, discount: InvoiceRowDiscountDTO) => {
    if (discount.Type === InvoiceRowDiscountType.DSC && totalAmount < 0) {
        return '+';
    } else if (discount.Type === InvoiceRowDiscountType.DSC) {
        return '-';
    } else if (InvoiceRowDiscountType.CHR === discount.Type && totalAmount < 0) {
        return '-';
    } else {
        return '+';
    }
};

export const calcSumWithoutVATAfterDiscount = (discounts: InvoiceRowDiscountDTO[], itemSum: string) => {
    let newSum = Big(itemSum);
    if (Number(itemSum)) {
        discounts?.forEach((e) => {
            if (getDiscountBeforeIcon(Number(itemSum), e) === '+') {
                newSum = newSum.plus(e.Amount || 0);
            } else {
                newSum = newSum.minus(e.Amount || 0);
            }
        });
        return roundBig(newSum, 4);
    }
    return 0;
};

export const calcDiscountsSum = (discounts: InvoiceRowDiscountDTO[], itemSum: string) => {
    const newSum = Big(itemSum?.replace('-', '') || '0');
    let newDiscount;
    if (isNumber(Number(itemSum))) {
        newDiscount = discounts?.map((e) => {
            if (e?.Rate) {
                e.Amount = Number(roundBig(newSum.times(Math.abs(e.Rate)).div(100), 4));
            }
            return e;
        });
    }

    return newDiscount || discounts;
};

export const rowValueCalculationFns = {
    ['ItemDetailInfo[0].ItemAmount']: (rowValues: Partial<InvoiceRowDTO>) => {
        const value = get(rowValues, 'ItemDetailInfo[0].ItemAmount');
        // if (Number(value) && Number(rowValues.SumWithoutVat) && !Number(rowValues.ItemDetailInfo[0].ItemPrice)) {
        //     const newFieldValue = Big(rowValues.SumWithoutVat)
        //         .div(value)
        //         .toFixed(getPricePrecision());
        //     setFieldValue('ItemDetailInfo[0].ItemPrice', newFieldValue, rowValues);
        // }
        if (Number(value) && Number(rowValues.ItemDetailInfo[0].ItemPrice)) {
            const newFieldValue = roundBig(Big(rowValues.ItemDetailInfo[0].ItemPrice).times(value), 4, Number(rowValues.ItemDetailInfo[0].ItemPrice) < 0 && value < 0);
            if (rowValues?.InvoiceRowDiscounts?.length > 0) {
                setFieldValue('ItemSum', newFieldValue, rowValues);
            } else {
                setFieldValue('SumWithoutVat', newFieldValue, rowValues);
            }
        }
    },
    ['ItemDetailInfo[0].ItemPrice']: (rowValues: Partial<InvoiceRowDTO>) => {
        const value = get(rowValues, 'ItemDetailInfo[0].ItemPrice');
        if (Number(value) && Number(rowValues.ItemDetailInfo[0].ItemAmount)) {
            if (rowValues?.InvoiceRowDiscounts?.length > 0) {
                setFieldValue('ItemSum', roundBig(Big(rowValues.ItemDetailInfo[0].ItemAmount).times(value), 4, Number(rowValues.ItemDetailInfo[0].ItemAmount) < 0 && value < 0), rowValues);
            } else {
                setFieldValue('SumWithoutVat', roundBig(Big(rowValues.ItemDetailInfo[0].ItemAmount).times(value), 4, Number(rowValues.ItemDetailInfo[0].ItemAmount) < 0 && value < 0), rowValues);
            }
        }
    },
    ['InvoiceRowDiscounts']: (rowValues: Partial<InvoiceRowDTO>) => {
        setFieldValue('SumWithoutVat', calcSumWithoutVATAfterDiscount(rowValues.InvoiceRowDiscounts, Number(rowValues.ItemSum) ? rowValues.ItemSum : rowValues.SumWithoutVat), rowValues);
    },
    ['ItemSum']: (rowValues: Partial<InvoiceRowDTO>) => {
        const value = get(rowValues, 'ItemSum');
        if (isNumber(Number(value)) && rowValues.InvoiceRowDiscounts?.length > 0) {
            setFieldValue('InvoiceRowDiscounts', calcDiscountsSum(rowValues.InvoiceRowDiscounts, value), rowValues);
        }
    },
    ['SumWithoutVat']: (rowValues: Partial<InvoiceRowDTO>) => {
        const value = Number(rowValues['SumWithoutVat']);
        // if (isNumber(value) && !Number(rowValues.ItemDetailInfo[0].ItemAmount) && Number(rowValues.ItemDetailInfo[0].ItemPrice)) {
        //     setFieldValue(
        //         'ItemDetailInfo[0].ItemAmount',
        //         Big(value)
        //             .div(rowValues.ItemDetailInfo[0].ItemPrice)
        //             .toFixed(getQtyPrecision()),
        //         rowValues,
        //     );
        // }
        // if (isNumber(value) && Number(rowValues.ItemDetailInfo[0].ItemAmount) && !rowValues.ItemDetailInfo[0].ItemPrice) {
        //     setFieldValue(
        //         'ItemDetailInfo[0].ItemPrice',
        //         Big(value)
        //             .div(rowValues.ItemDetailInfo[0].ItemAmount)
        //             .toFixed(getPricePrecision()),
        //         rowValues,
        //     );
        // }
        //if (isNumber(value) && !Number(rowValues.VatRate) && Number(rowValues.VAT)) {
        //    setFieldValue('VatRate', roundBig(Big(rowValues.VAT).div(value)) * 100, rowValues);
        //}

        if (isNumber(value) && isNumber(Number(rowValues.VatRate))) {
            setFieldValue(
                'VAT',
                roundBig(
                    Big(value)
                        .times(rowValues.VatRate)
                        .div(100),
                    4,
                ),
                rowValues,
            );
        }
        if (isNumber(value)) {
            setFieldValue('Total', roundBig(Big(value).add(rowValues.VAT || 0), 4), rowValues);
        }
    },
    ['VatRate']: (rowValues: Partial<InvoiceRowDTO>) => {
        const value = Number(rowValues['VatRate']);
        if (isNumber(value) && isNumber(Number(rowValues.SumWithoutVat))) {
            setFieldValue(
                'VAT',
                roundBig(
                    Big(rowValues.SumWithoutVat)
                        .times(value)
                        .div(100),
                    4,
                ),
                rowValues,
            );
        }
    },
    ['VAT']: (rowValues: Partial<InvoiceRowDTO>) => {
        const value = Number(rowValues['VAT']);
        //if (isNumber(value) && Number(rowValues.SumWithoutVat)) {
        //    setFieldValue('VatRate', roundBig(Big(value).div(rowValues.SumWithoutVat)) * 100, rowValues, true);
        //}
        if (isNumber(value) && isNumber(Number(rowValues.Total)) && !rowValues.SumWithoutVat && !(rowValues?.InvoiceRowDiscounts?.length > 0 && !Number(rowValues?.ItemSum))) {
            setFieldValue('SumWithoutVat', roundBig(Big(rowValues.Total).minus(value), 4), rowValues, true);
        }
        if (isNumber(value)) {
            setFieldValue('Total', roundBig(Big(rowValues.SumWithoutVat || 0).add(value), 4), rowValues, true);
        }
    },
    ['Total']: (rowValues: Partial<InvoiceRowDTO>) => {
        const value = Number(rowValues['Total']);
        if (isNumber(value) && isNumber(Number(rowValues.VAT))) {
            if (!(rowValues?.InvoiceRowDiscounts?.length > 0 && !Number(rowValues?.ItemSum))) {
                setFieldValue('SumWithoutVat', roundBig(Big(value).div(1 + Number(rowValues.VatRate) / 100), 4), rowValues, true);
            }
            setFieldValue('VAT', roundBig(Big(value).minus(Big(value).div(1 + Number(rowValues.VatRate) / 100)), 4), rowValues, true);
        }

        //if (isNumber(value) && !Number(rowValues.VatRate) && Number(rowValues.VAT)) {
        //    setFieldValue(
        //        'VatRate',
        //        roundBig(
        //            Big(rowValues.VAT)
        //                .times(100)
        //                .div(Big(value).minus(rowValues.VAT)),
        //            0,
        //        ),
        //        rowValues,
        //        true,
        //    );
        //}
    },
};

function setFieldValue(fieldName: string, newFieldValue: any, rowValues: Partial<InvoiceRowDTO>, disableUpdateTrigger = false) {
    //since we recursively call field changes, then do not call other changes anymore when value is already same
    if (newFieldValue === get(rowValues, fieldName)) {
        return;
    }
    set(rowValues, fieldName, newFieldValue);
    if (disableUpdateTrigger) {
        return;
    }
    const rowCalculationFn = rowValueCalculationFns[fieldName];
    if (rowCalculationFn) {
        try {
            rowCalculationFn(rowValues);
        } catch (e) {
            console.warn(e);
        }
    }
}

export const trimAfterComma = (value: string, symbolsCap: number, trailingZerosNumber?: number) => {
    if (!value || !symbolsCap) {
        return trailingZerosNumber ? new Big(0).toFixed(trailingZerosNumber) : '0';
    }
    const val = value.replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1');
    const array = val.toString().split('.');
    const len = array.length;
    if (len === 1) {
        return trailingZerosNumber ? new Big(array[0] || '0').toFixed(trailingZerosNumber) : array[0];
    }
    if (len >= 2) {
        let newVal = array[0] + '.' + array[1].substring(0, symbolsCap);
        if (newVal?.split('.')[1].length < trailingZerosNumber) {
            newVal = new Big(newVal).toFixed(trailingZerosNumber);
        }
        return newVal;
    }
    return null;
};

export const getInitialFields = (row: InvoiceRowDTO) => {
    const newRow = cloneDeep(row);
    if (!isEmpty(newRow?.ItemDetailInfo)) {
        if (Number(newRow.ItemDetailInfo[0]?.ItemPrice)) {
            newRow.ItemDetailInfo[0].ItemPrice = trimAfterComma(newRow.ItemDetailInfo[0].ItemPrice, getPricePrecision(), 2);
        } else {
            newRow.ItemDetailInfo[0].ItemPrice = '0.00';
        }
        if (Number(newRow.ItemDetailInfo[0]?.ItemAmount)) {
            newRow.ItemDetailInfo[0].ItemAmount = trimAfterComma(newRow.ItemDetailInfo[0].ItemAmount, getQtyPrecision());
        } else {
            newRow.ItemDetailInfo[0].ItemAmount = '0';
        }

        if (!Number(newRow.ItemSum) && Number(newRow.ItemDetailInfo[0]?.ItemAmount) && Number(newRow.ItemDetailInfo[0]?.ItemPrice)) {
            newRow.ItemSum = roundBig(
                Big(newRow.ItemDetailInfo[0].ItemPrice).times(newRow.ItemDetailInfo[0].ItemAmount),
                4,
                Number(newRow.ItemDetailInfo[0].ItemPrice) < 0 && Number(newRow.ItemDetailInfo[0].ItemAmount) < 0,
            );
        }
    }

    if (newRow.ItemSum) {
        newRow.ItemSum = trimAfterComma(newRow.ItemSum, 4, 2);
    }

    if (!Number(newRow.ItemSum) && Number(newRow.SumWithoutVat)) {
        newRow.ItemSum = trimAfterComma(newRow.SumWithoutVat, 4, 2);
    }
    if (!Number(newRow.ItemSum) && !Number(newRow.SumWithoutVat)) {
        newRow.ItemSum = '0.00';
    }
    if (newRow.SumWithoutVat) {
        newRow.SumWithoutVat = trimAfterComma(newRow.SumWithoutVat, 4, 2);
    }
    if (newRow.VatRate) {
        newRow.VatRate = Number(trimAfterComma(newRow.VatRate.toString(), 2));
    }
    if (newRow.VAT) {
        newRow.VAT = trimAfterComma(newRow.VAT, 4, 2);
    }
    if (newRow.Total) {
        newRow.Total = trimAfterComma(newRow.Total, 4, 2);
    }
    return newRow;
};

export enum InvoiceRowsBulkActions {
    All,
    None,
    ThisPage,
    Invert,
}

export enum BulkActionModifier {
    Exact = 1,
    Except = 2,
    All = 3,
}

export const invoiceRowsBulkActionTranslations = {
    [InvoiceRowsBulkActions.All]: 'component.invoiceRows.bulkActions.All',
    [InvoiceRowsBulkActions.None]: 'component.invoiceRows.bulkActions.None',
    [InvoiceRowsBulkActions.ThisPage]: 'component.invoiceRows.bulkActions.ThisPage',
    [InvoiceRowsBulkActions.Invert]: 'component.invoiceRows.bulkActions.Invert',
};

export const newDiscountObject = (row: InvoiceRowDTO, net: string): InvoiceRowDiscountDTO => {
    return { InvoiceRowId: row.Id, Type: InvoiceRowDiscountType.DSC, Description: '', Rate: 5, Amount: net ? (Number(net) * 5) / 100 : 0, Id: 0, IsNew: true };
};

export const getDiscountLabelTranslateKey = (type: InvoiceRowDiscountType) => {
    switch (type) {
        case InvoiceRowDiscountType.DSC:
            return 'component.invoiceRows.discount';
        case InvoiceRowDiscountType.CHR:
            return 'component.invoiceRows.extraCharge';
        default:
            return '';
    }
};

export const getDiscountLabelType = (type: InvoiceRowDiscountType) => {
    switch (type) {
        case InvoiceRowDiscountType.DSC:
            return StampType.DISCOUNT;
        case InvoiceRowDiscountType.CHR:
            return StampType.EXTRA_CHARGE;
        default:
            return StampType.DISCOUNT;
    }
};

export const checkDiscountSumType = (discounts: InvoiceRowDiscountDTO[]) => {
    let discountSum = 0;
    let extraChargeSum = 0;

    discounts.forEach((e) => {
        if (e.Type === InvoiceRowDiscountType.CHR) {
            extraChargeSum = extraChargeSum + e.Amount;
        } else {
            discountSum = discountSum + e.Amount;
        }
    });

    if (discountSum >= extraChargeSum) {
        return InvoiceRowDiscountType.DSC;
    }

    return InvoiceRowDiscountType.CHR;
};

export const isMonetaryAmountCheckEnabled = (totalSum: number): boolean => {
    const companySetting = selectCurrentCompanySettings(store.getState()).find((c) => c.Name === 'AllowProcessingZeroInvoices');
    return !(companySetting?.Value === CompanySettingStatus.Disabled && Big(totalSum).eq(0));
};
