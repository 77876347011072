import { LOCATION_CHANGE } from 'connected-react-router';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { BackOfficeCompanyDTO } from '../../../../../services/types/BoApiTypes';
import { LoadableData } from '../../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../../rootReducer';

import { addBoCompanyActions, setActiveBoCompany, showEditBoCompanyModal } from './AddBoCompanyModalActions';

class State {
    activeBoCompany: BackOfficeCompanyDTO = null;
    isEditBoCompanyModalOpen = false;
    addBoCompanyLoadable = new LoadableData<BackOfficeCompanyDTO, BackOfficeCompanyDTO>();
    // TODO @tonister: Add this in 20.5.1
    // boCompanyUsersLoadable = new LoadableData<BackOfficeUserDTO[], string>();
}

const reducer = new ReducerFactory(new State())
    // TODO @tonister: Add this in 20.5.1
    // .addReducer(getCompanyUsersLoadableActions.request, (state, action) => {
    //     return {
    //         ...state,
    //         boCompanyUsersLoadable: state.boCompanyUsersLoadable.withLoading(),
    //     };
    // })
    // .addReducer(getCompanyUsersLoadableActions.success, (state, action) => {
    //     return {
    //         ...state,
    //         boCompanyUsersLoadable: LoadableData.payload(action.payload),
    //     };
    // })
    .addReducer(
        setActiveBoCompany,
        (state, action): State => {
            return {
                ...state,
                activeBoCompany: action.payload,
            };
        },
    )
    .addReducer(
        showEditBoCompanyModal,
        (state, action): State => {
            return {
                ...state,
                isEditBoCompanyModalOpen: action.payload,
            };
        },
    )
    .addReducer(
        addBoCompanyActions.request,
        (state): State => {
            return {
                ...state,
                addBoCompanyLoadable: state.addBoCompanyLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        addBoCompanyActions.success,
        (state, action): State => {
            return {
                ...state,
                addBoCompanyLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        addBoCompanyActions.error,
        (state, action): State => {
            return {
                ...state,
                addBoCompanyLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'new-BoCompany-modal',
    whitelist: [],
};

export default persistReducer(persistConfig, reducer);

export { State as EditBoCompanyModalState };

export const selectActiveBoCompany = (state: GlobalState) => state.editBoCompanyModal.activeBoCompany;
export const selectIsEditBoCompanyModalOpen = (state: GlobalState) => state.editBoCompanyModal.isEditBoCompanyModalOpen;
export const selectAddBoCompanyLoadable = (state: GlobalState) => state.editBoCompanyModal.addBoCompanyLoadable;
