import classNames from 'classnames';
import * as React from 'react';
import { Button, ButtonType } from '../Buttons/Button';
import { ICONS } from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import { withTranslation, WithTranslation } from 'react-i18next';

import './DefaultCurrency.scss';

export interface DefaultCurrencyProps extends WithTranslation {
    className?: string;
    children?: React.ReactNode;
    DefaultCurrencyRate: number;
    CurrencyRateDate: string;
}

const DefaultCurrency: React.FunctionComponent<DefaultCurrencyProps> = ({ DefaultCurrencyRate, CurrencyRateDate, t, children }: DefaultCurrencyProps) => {
    const classes = classNames('default-currency');
    return (
        <div className={classes} data-id="default-currency">
            <span className={'default-currency__text'} data-id="default-currency__test">
                {children}
            </span>
            <Tooltip
                content={
                    <div className={'default-currency__tooltip-content'}>
                        <p>{`${t('component.invoiceHeader.defaultCurrency.exchangeRate')}: ${DefaultCurrencyRate}`}</p>
                        <p>{`${t('component.invoiceHeader.defaultCurrency.dateTaken')}: ${CurrencyRateDate}`}</p>
                    </div>
                }
                placement={'bottom-end'}
            >
                <Button buttonType={ButtonType.ICON} icon={ICONS.INFO} />
            </Tooltip>
        </div>
    );
};

export default withTranslation()(DefaultCurrency);
