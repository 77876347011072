import { isEmpty, find } from 'lodash-es';
import { setInvoiceFilter } from '../../src/common/history/InvoiceFilterActions';
import api from '../../src/services/ApiServices';
import { parseInvoiceFilterObjectPaging, parseInvoiceFilterObjectSorting } from '../../src/common/utils/baseSearchHelpers';
import { setCurrentCompanySettings, setCurrentCompanySettingsLoading } from '../../src/common/company/CompanyActions';
import { formatDate } from '../../src/common/utils/formatters';
import { parseExportSetting } from '../../src/views/invoice-details/components/invoice-header/utils';

"use strict";
angular.module("dstreamApp").factory("invoiceRegisterService", [
    "$rootScope",
    "webServices",
    "invoiceService",
    "notifyService",
    "utils",
    "$filter",
    "localStorageService",
    "authenticationService",
    "utilityService",
    "companyDataService",
    "$ngRedux",
    "$location",
    function ($rootScope, webServices, invoiceService, notifyService, utils, $filter, localStorageService, authenticationService, utilityService, companyDataService, $ngRedux, $location) {
        var service = {};
        var availableFilterTemplates = [];

        /*
            Return default filter object. Used mainly to set initial values in components
         */
        service.createDefaultFilterObject = function (isArchive) {
            let filterObject = {
                searchValue: "",
                statusFilter: [],
                usersFilter: [],
                invoiceTypesFilter: [],
                dimensionFilter: [],
                accountsFilter: [],
                objectsFilter: [],
                moreFilter: [],
                timeType: 'lastXDays', // default show only last 90 days invoices
                timeTypeLabel: "component.invoiceFilter.customDates",
                invoiceType: 0,
                isIncludeWorkflows: true,
                lastXDaysModel: "90",
                lastSixMonths: "180",
                fromModel: "fromBeginningOfTime",
                toModel: "untilEndOfTime",
                sortItems: [
                    {
                        SortColumn: "InvoiceDate",
                        SortDirection: "desc"
                    }
                ],
                pagingOptions: {
                    page: 1,
                    count: 10,
                    total: 0
                },
                customRestrictions: [],
                searchDateBy: {
                    text: "component.invoiceFilter.filterTime_invoiceDate",
                    value: "invoice"
                },
                usersSortByList: [
                    { Name: "component.invoiceFilter.handledBy", id: isArchive ? "HandledByIdList" : "HandledByGuidList", isTag: true },
                    { Name: "component.invoiceFilter.waitingForConfirmation", id: "WaitingForConfirmationGuidList" },
                    { Name: "component.invoiceFilter.headingTowards", id: "HeadingTowardsGuidList" }
                ]
            };
            const calculatedTimes = calculateTimes(filterObject);
            filterObject = {
                ...filterObject,
                fromModel: calculatedTimes.from,
                toModel: calculatedTimes.to
            };
            return filterObject;
        };

        /*
            =============== PUBLIC FUNCTIONS ===============
         */
        service.calculateTimes = calculateTimes;

        /*
            Save filter to local storage so we can load it on next reload
         */
        service.saveFilterToLocalStorage = function (filterObject, skipFilterSave, skipApplyDefaultFilter, localStoragePrefix) {
            skipApplyDefaultFilter = skipApplyDefaultFilter !== undefined ? skipApplyDefaultFilter : false;
            const { invoiceFilterHistory } = $ngRedux.getState();
            const localStorageKey = localStoragePrefix ? localStoragePrefix + ".filterObject" : "filterObject";
            const fromReducer = (
                !!invoiceFilterHistory.previous &&
                !!invoiceFilterHistory.previous.location &&
                ['invoices', 'invoiceconfirmation', 'transactions'].includes(invoiceFilterHistory.previous.location.split('/')[1]) &&
                ['invoices', 'invoiceconfirmation', 'transactions'].includes(invoiceFilterHistory.current.location.split('/')[1]) &&
                ['invoices', 'invoiceconfirmation', 'transactions'].includes($location.path().split('/')[1])
            ) &&
                invoiceFilterHistory.previous.filter;
            let selectedFilter;

            if (!skipApplyDefaultFilter) {
                selectedFilter = find(availableFilterTemplates, function (f) { return f.IsDefault; });
            }

            let currentFilter = {
                ...service.createDefaultFilterObject(), // the default filter
                ...localStorageService.get(localStorageKey), // from local storage
                ...filterObject // the provided filter
            };

            if (!isEmpty(selectedFilter)) {
                currentFilter = {
                    ...currentFilter,
                    ...JSON.parse(selectedFilter.JSON) // apply default filter template
                }
            }

            if (!isEmpty(fromReducer)) {
                currentFilter = {
                    ...currentFilter,
                    ...fromReducer
                }
            }
            // if we don't purposely want to apply the user predefined default filter
            // if we don't have a filterObject in local storage, then check if any of the availableFilterTemplates is set as default
            // if it is, then apply that template instead of the default one.

            // in some cases we want to skip saving the filter to local storage, like when the invoice register component load
            // we also want to save the filter when we have a user predefined default filter set
            if (!skipFilterSave || !skipApplyDefaultFilter) {
                const currentLocation = {
                    location: $location.path().split('/')[1],
                    filter: currentFilter,
                };
                $ngRedux.dispatch(setInvoiceFilter(currentLocation));
                localStorageService.set(localStorageKey, currentFilter);
            }
            return { ...currentFilter };
        };

        service.simpleSaveFilterToLocalStorage = (filterObject, localStoragePrefix) => {
            const localStorageKey = localStoragePrefix ? localStoragePrefix + ".filterObject" : "filterObject";
            localStorageService.set(localStorageKey, filterObject);
            return { ...filterObject };
        };

        /*
            Remove filters from local storage
         */
        service.removeFilterFromLocalStorage = function (localStoragePrefix) {
            const localStorageKey = localStoragePrefix ? localStoragePrefix + ".filterObject" : "filterObject";
            localStorageService.remove(localStorageKey);
            $rootScope.$emit("invoiceRegisterFilterCleared", service.createDefaultFilterObject());
        };

        /*
            Return filter state from local storage or if it doesn't exist, then the default filter object.
         */
        service.getFilterFromLocalStorage = function (localStoragePrefix) {
            const localStorageKey = localStoragePrefix ? localStoragePrefix + ".filterObject" : "filterObject";
            return localStorageService.get(localStorageKey);
        };

        /*
            Get previously saved filter templates
         */
        service.getAvailableFilterTemplates = function () {
            return webServices.getFilterTemplates().then(function (response) {
                if (response.data) {
                    availableFilterTemplates = response.data;
                }
                return response;
            }, function (data) {
                console.log(data);
            });
        };

        /*
            Get total amount of all the rows sums in invoice register table
         */
        service.getTotalAmounts = function (searchObject) {
            webServices.GetTotalAmounts(searchObject).then(function (response) {
                if (response && response.data) {
                    $rootScope.$emit("invoiceRegisterTotalAmounts", response.data);
                }
            });
        };

        /*
            Save selected filters as a template
         */
        service.saveFilterTemplate = function (availableTemplates, name, isDefault) {
            var filter = _.find(availableTemplates, function (f) { return f.Name === name; });
            var template = JSON.stringify(service.getFilterFromLocalStorage() || service.createDefaultFilterObject());

            if (!filter) {
                filter = { Name: name, JSON: "{}" };
            }
            filter.IsDefault = isDefault;
            filter.JSON = template;
            $rootScope.$emit("savingTemplate");

            if (filter && filter.Name) {
                webServices.saveFilterTemplate(filter).then(function (response) {
                    if (response.data) {
                        $rootScope.$emit("templateSaved");
                        notifyService.success("views.invoice.partials.invoiceRows.Saving_successful", "views.invoice.partials.invoiceRows.Success", true);
                    }
                }, function (data) {
                    console.log(data);
                });
            }
        };

        /*
            Export currently filtered invoices
         */
        service.exportInvoicesToCSV = function (filterObject) {
            if (authenticationService.isAuthorized("CanExportInvoice")) {
                webServices.exportInvoicesToCSV(createApiRequestObject(filterObject)).then(function (response) {
                    var file = new Blob([response.data], { type: "text/csv;charset=utf-8" });
                    saveAs(file, `Invoices_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.csv`);
                }, function (data) {
                    console.log(data);
                });
            }
            else {
                notifyService.error("controller.invoiceListController.Status_unknown", "controller.invoiceListController.ERROR_1", true);
            }
        };

        service.exportInvoicesToXls = function (filterObject) {
            if (authenticationService.isAuthorized("CanExportInvoice")) {
                webServices.exportInvoicesToXls(createApiRequestObject(filterObject)).then(function (response) {
                    const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    saveAs(file, `Invoices_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`);
                }, function (data) {
                    console.log(data);
                });
            }
            else {
                notifyService.error("controller.invoiceListController.Status_unknown", "controller.invoiceListController.ERROR_1", true);
            }
        };

        /*
            Save filters to local storage, parse request object and do API call
         */
        service.doSearch = function (filterObject, skipFilterSave, skipApplyDefaultFilter, getTotalAmounts, isArchive, queue, lsPrefix) {
            return loadInvoices(filterObject, skipFilterSave, skipApplyDefaultFilter, getTotalAmounts, isArchive, queue, lsPrefix);
        };
        service.doSearchWithQueue = function (filterObject, skipFilterSave, skipApplyDefaultFilter, getTotalAmounts, isArchive, lsPrefix) {
            return loadInvoices(filterObject, skipFilterSave, skipApplyDefaultFilter, getTotalAmounts, isArchive, true, lsPrefix);
        };

        /*
            Clear filters from local storage, parse request object and do API call
         */
        service.clearFilters = function (clearForArchive, loadTotalAmounts = false) {
            service.removeFilterFromLocalStorage(); // remove filter from local storage
            let defaultFilterObjectCopy = {
                ...service.createDefaultFilterObject(clearForArchive),
                timeType: 'forever'   // reset last 90 days only filter too (default is 'lastXDays')
            };

            if (clearForArchive) {
                defaultFilterObjectCopy.statusFilter = [{ Name: "Archived", Value: 6 }];
                defaultFilterObjectCopy.timeType = "forever";
                defaultFilterObjectCopy.timeTypeLabel = "component.invoiceFilter.customDates";
            }
            const calculatedTimes = calculateTimes(defaultFilterObjectCopy);
            defaultFilterObjectCopy = {
                ...defaultFilterObjectCopy,
                fromModel: calculatedTimes.from,
                toModel: calculatedTimes.to
            };
            return loadInvoices(defaultFilterObjectCopy, true, true, !clearForArchive && loadTotalAmounts, clearForArchive);
        };

        /*
            Get settings for new supplier visibility and ERP visibility setting
         */
        service.getExportSetting = function () {
            const { company: { currentCompanySettings } } = $ngRedux.getState();
            if (currentCompanySettings && currentCompanySettings.length) {
                return new Promise(res => res(parseExportSetting(currentCompanySettings)));
            } else {
                $ngRedux.dispatch(setCurrentCompanySettingsLoading(true));
                return companyDataService.getCurrentCompanySettings().then(function (response) {
                    $ngRedux.dispatch(setCurrentCompanySettings(response.data));
                    $ngRedux.dispatch(setCurrentCompanySettingsLoading(false));
                    return parseExportSetting(response.data);
                }, function (data) {
                    $ngRedux.dispatch(setCurrentCompanySettingsLoading(false));
                    console.log(data);
                });
            }
        };

        /*
            =============== INTERNAL FUNCTIONS ===============
         */

        /*
            Get invoices array from the API and send it out into the universe
         */

        function loadInvoices(filterObject, skipFilterSave, skipApplyDefaultFilter, getTotalAmounts, isArchive, queue, lsPrefix) {
            $rootScope.$emit("invoiceRegisterLoading");
            $rootScope.isPDFVisible = false;
            var updatedFilterObject = service.saveFilterToLocalStorage(filterObject, skipFilterSave, skipApplyDefaultFilter, lsPrefix); // save filter to local storage before invoice array API request
            var searchObject = createApiRequestObject(updatedFilterObject); // build actual API request object
            if (getTotalAmounts) {
                service.getTotalAmounts(searchObject); // get total amount
            }

            let dataRequest = function () { };
            if (isArchive) {
                dataRequest = webServices.getArchiveInvoicesSmall;
            } else {
                dataRequest = api.invoice.getRegistryInvoicesMin;
            }

            return dataRequest(searchObject).then(
                function (result) {
                    if (result) {
                        for (var i = 0; i < result.data.Items.length; i++) {
                            result.data.Items[i].currentStatus = invoiceService.setInvoiceStatus(result.data.Items[i]);
                            result.data.Items[i].InvoiceDate = utilityService.removeTimeZone(result.data.Items[i].InvoiceDate);
                            result.data.Items[i].AccountingDate = utilityService.removeTimeZone(result.data.Items[i].AccountingDate);
                            result.data.Items[i].DueDate = utilityService.removeTimeZone(result.data.Items[i].DueDate);
                            result.data.Items[i].ImportedDate = utilityService.removeTimeZone(result.data.Items[i].ImportedDate);
                        }
                        var data = parseCurrentWorkflowUsers(result.data.Items);

                        updatedFilterObject.pagingOptions.total = result.data.TotalCount; // set total invoices

                        // emit invoice array to the universe. Used only in invoice register view and to communicate between the filter and register table
                        return {
                            result: data,
                            filterObject: { ...updatedFilterObject }
                        }; // return values to promise
                    }
                }, function () {
                    notifyService.error("controller.dashboardController.Error", "controller.dashboardController.Error", true);
                    $rootScope.$emit("invoiceRegisterLoaded", {
                        result: [],
                        filterObject: service.createDefaultFilterObject(isArchive)
                    });
                    return {
                        result: [],
                        filterObject: service.createDefaultFilterObject(isArchive)
                    };
                });
        }

        /*
            Creates actual object that we send to API to receive invoices
         */
        function createApiRequestObject(filterObject) {
            const { isIncludeWorkflows } = filterObject;

            /**
             * parse the filterObject to extract paging options
             * @type {{Page: number, Count: number}}
             */
            const pagingOptions = parseInvoiceFilterObjectPaging(filterObject);

            /**
             * parse the filterObject to extract sorting data
             * @type {[SortItem]}
             */
            const sortItems = parseInvoiceFilterObjectSorting(filterObject);

            /**
             * parse the filterObject to extract search restrictions
             * @type {[Restriction]}
             */
            const restrictions = parseFilters(filterObject);

            /**
             * Compose the searchParams from parsed Restrictions, SortItems and PagingOptions
             * @type {{PagingOptions: PagingOptions, SortItems: SortItem[], isIncludeWorkflows: boolean, Restrictions: Restriction[]}}
             */
            const searchParams = {
                SortItems: [...sortItems],
                PagingOptions: { ...pagingOptions },
                Restrictions: [...restrictions],
                isIncludeWorkflows: true,
            };
            if (isIncludeWorkflows !== undefined) {
                searchParams.isIncludeWorkflows = isIncludeWorkflows;
            }
            return searchParams;
        }

        service.createApiRequestObject = createApiRequestObject;

        /*
            Takes in all the invoices and adds a list of Approvers for current workflow step
         */
        function parseCurrentWorkflowUsers(invoices) {
            return invoices.map(function (invoice) {
                if (invoice.CurrentWorkflowUsers) {
                    invoice.currentWorkflowUsersNameString = invoice.CurrentWorkflowUsers.join("<br />");
                }
                return invoice;
            });
        }

        /*
            Parse filter object into correct format for the API call
         */
        function parseFilters(filterObject) {
            var filterObj = [],
                calculatedTimes,
                timeFrame = { from: "", to: "" };

            // set timeFrame
            switch (filterObject.searchDateBy.value) {
                case "invoice":
                    timeFrame.from = "InvoiceDateFrom";
                    timeFrame.to = "InvoiceDateTo";
                    break;
                case "coding":
                    timeFrame.from = "AccountingDateFrom";
                    timeFrame.to = "AccountingDateTo";
                    break;
                case "import":
                    timeFrame.from = "ImportedDateFrom";
                    timeFrame.to = "ImportedDateTo";
                    break;
                case "export":
                    timeFrame.from = "ExportedDateFrom";
                    timeFrame.to = "ExportedDateTo";
                    break;
                case "due":
                    timeFrame.from = "DueDateFrom";
                    timeFrame.to = "DueDateTo";
                    break;
                default:
                    break;
            }

            //set timeType
            calculatedTimes = calculateTimes(filterObject);
            //if time frame still missing -> setting defaults
            if (utils.isEmpty(calculatedTimes.from) || utils.isEmpty(calculatedTimes.to)) {
                calculatedTimes = calculateTimes(filterObject);
            }

            // if our time frame is "forever", then we don't need to set time constraint
            if (calculatedTimes.from !== "fromBeginningOfTime" && calculatedTimes.to !== "untilEndOfTime") {
                filterObject.fromModel = calculatedTimes.from;
                filterObj.push({ Field: timeFrame.from, Value: calculatedTimes.from.format("yyyy-mm-dd") });
                filterObject.toModel = calculatedTimes.to;
                filterObj.push({ Field: timeFrame.to, Value: calculatedTimes.to.format("yyyy-mm-dd") });
            }
            filterObj.timeTypeLabel = filterObject.timeTypeLabel = calculatedTimes.label;

            // status filter
            if (filterObject.statusFilter && filterObject.statusFilter.length) {
                var tempStatus = { Field: "Status", Values: [] };
                angular.forEach(filterObject.statusFilter, function (value) {
                    if (value.Name && value.Value !== undefined) {
                        tempStatus.Values.push(value.Value);
                    }
                });
                filterObj.push(tempStatus);
            }

            // users value
            if (filterObject.usersFilter && filterObject.usersFilter.length) {
                var tempConfirmed = [];
                for (var i = 0; i < filterObject.usersFilter.length; i++) {
                    tempConfirmed.push(filterObject.usersFilter[i].Value);
                }

                filterObj.push({
                    Field: _.find(filterObject.usersSortByList, function (sort) {
                        return sort.isTag;
                    }).id, Values: tempConfirmed
                });
            }

            // invoiceTypes value
            if (filterObject.invoiceTypesFilter && filterObject.invoiceTypesFilter.length) {
                var tempConfirmed = [];
                for (var i = 0; i < filterObject.invoiceTypesFilter.length; i++) {
                    tempConfirmed.push(filterObject.invoiceTypesFilter[i].Value);
                }

                filterObj.push({
                    Field: 'InvoiceTypeId',
                    Values: tempConfirmed
                });
            }


            // more filter
            if (filterObject.moreFilter && filterObject.moreFilter.length) {
                angular.forEach(filterObject.moreFilter, function (value) {
                    if (value.TranslateKey === "component.invoiceFilter.relatedCompanies") {
                        filterObj.push({ Field: "Invoice.IncludeRelatedCompanies", Value: 1 });
                    }
                    if (value.TranslateKey === "component.invoiceFilter.OnlyCreditInvoice") {
                        filterObj.push({ Field: "IsCredit", Value: true });
                    }
                    if (value.TranslateKey === "component.invoiceFilter.OnlyDebitInvoice") {
                        filterObj.push({ Field: "IsDebit", Value: true });
                    }
                    if (value.TranslateKey === "component.invoiceFilter.InvoiceCustomFields") {
                        filterObj.push({ Field: "SearchInCustomFields", Value: true });
                    }
                });
            }
            // dimensions filter
            if ((filterObject.accountsFilter && filterObject.accountsFilter.length)
                || (filterObject.dimensionFilter && filterObject.dimensionFilter.length)
                || (filterObject.objectsFilter && filterObject.objectsFilter.length)) {
                var dimensions = [];
                var accounts = [];
                var objects = [];
                filterObject.accountsFilter.forEach(function (account) {
                    accounts.push(account.Value);
                });
                filterObject.dimensionFilter.forEach(function (dimension) {
                    dimensions.push(dimension.Value);
                });
                filterObject.objectsFilter.forEach(function (object) {
                    objects.push(object.Value);
                });
                if (accounts.length)
                    filterObj.push({ Field: "Accounts", Values: accounts });
                if (dimensions.length)
                    filterObj.push({ Field: "Dimensions", Values: dimensions });
                if (objects.length)
                    filterObj.push({ Field: "Objects", Values: objects });
            }

            // search value
            if (filterObject.searchValue) {
                filterObj.push({ Field: "GeneralSearch", Value: filterObject.searchValue });
            }

            if (filterObject.customRestrictions.length) {
                filterObject.customRestrictions.forEach(function (restriction) {
                    filterObj.push(restriction);
                });
            }

            return filterObj;
        }

        /*
            Calculate date ranges based on the current timeType
        */
        function calculateTimes(filterObject) {
            var calculatedTimes = { from: "", to: "", label: "" };

            switch (filterObject.timeType) {
                case "today":
                    calculatedTimes.from = utils.getToday();
                    calculatedTimes.to = utils.getToday();
                    calculatedTimes.label = "component.invoiceFilter.today";
                    break;
                case "yesterday":
                    calculatedTimes.from = utils.getYesterday();
                    calculatedTimes.to = utils.getYesterday();
                    calculatedTimes.label = "component.invoiceFilter.yesterday";
                    break;
                case "thisWeek":
                    calculatedTimes.from = utils.getFirstDateOfWeek(new Date());
                    calculatedTimes.to = utils.getLastDateOfWeek(new Date());
                    calculatedTimes.label = "component.invoiceFilter.thisWeek";
                    break;
                case "lastWeek":
                    calculatedTimes.from = utils.getFirstDateOfWeek(utils.getSevenDaysAgo());
                    calculatedTimes.to = utils.getLastDateOfWeek(utils.getSevenDaysAgo());
                    calculatedTimes.label = "component.invoiceFilter.lastWeek";
                    break;
                case "currentMonth":
                    calculatedTimes.from = utils.getFirstDateOfMonth(new Date());
                    calculatedTimes.to = utils.getLastDateOfMonth(new Date());
                    calculatedTimes.label = "component.invoiceFilter.currentMonth";
                    break;
                case "lastMonth":
                    calculatedTimes.from = utils.getFirstDateOfMonth(utils.getFirstDateOfMonth(new Date()).addDays(-1));
                    calculatedTimes.to = utils.getLastDateOfMonth(utils.getFirstDateOfMonth(new Date()).addDays(-1));
                    calculatedTimes.label = "component.invoiceFilter.lastMonth";
                    break;
                case "thisYear":
                    calculatedTimes.from = utils.getFirstDateOfYear(new Date());
                    calculatedTimes.to = utils.getLastDateOfYear(new Date());
                    calculatedTimes.label = "component.invoiceFilter.thisYear";
                    break;
                case "customDates":
                    calculatedTimes.from = new Date(filterObject.fromModel);
                    calculatedTimes.to = new Date(filterObject.toModel);
                    calculatedTimes.label = "component.invoiceFilter.customDates";
                    break;
                case "shiftedCustomMonth":
                    calculatedTimes.from = new Date(filterObject.fromModel);
                    calculatedTimes.to = new Date(filterObject.toModel);
                    calculatedTimes.label = "component.invoiceFilter.customDates";
                    break;
                case "shiftedCustomYear":
                    calculatedTimes.from = new Date(filterObject.fromModel);
                    calculatedTimes.to = new Date(filterObject.toModel);
                    calculatedTimes.label = "component.invoiceFilter.customDates";
                    break;
                case "lastSixMonths":
                    calculatedTimes.from = utils.getXDaysAgo(filterObject.lastSixMonths);
                    calculatedTimes.to = utils.getToday();
                    calculatedTimes.label = filterObject.lastSixMonths === 1 ? "component.invoiceFilter.lastXDay" : "component.invoiceFilter.lastXDays";
                    break;
                case "lastXDays":
                    calculatedTimes.from = utils.getXDaysAgo(filterObject.lastXDaysModel);
                    calculatedTimes.to = utils.getToday();
                    calculatedTimes.label = filterObject.lastXDaysModel === 1 ? "component.invoiceFilter.lastXDay" : "component.invoiceFilter.lastXDays";
                    break;
                case "forever":
                default:
                    calculatedTimes.from = "fromBeginningOfTime";
                    calculatedTimes.to = "untilEndOfTime";
                    calculatedTimes.label = "component.invoiceFilter.customDates";
                    break;
            }
            return calculatedTimes;
        }
        return service;
    }
]);