import { withTranslation } from 'react-i18next';

import { selectIsCurrentCompanySettingsLoading } from '../../common/company/CompanySelectors';
import withSuspense from '../../common/hocs/withSuspense';
import { getCurrentPath } from '../../common/router/routerSelectors';
import { getCurrentUser, showEmailVerifiedNotification } from '../../common/user/UserSelectors';
import { GlobalState } from '../../rootReducer';
import { connect } from '../../storeConfig';

import { DispatchProps, MainMenu, Props } from './MainMenu';
import { toggleMainMenuOpen } from './MainMenuActions';
import { selectBoCurrentUserLoadable, selectAskToVerifyEmail } from '../../common/user/UserSelectors';
import { selectIsMainMenuOpen } from './MainMenuReducer';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        boCurrentUserLoadable: selectBoCurrentUserLoadable(state),
        currentUser: getCurrentUser(state),
        currentPath: getCurrentPath(state),
        isLoading: selectIsCurrentCompanySettingsLoading(state),
        isOpen: selectIsMainMenuOpen(state),
        willAskToVerifyEmail: selectAskToVerifyEmail(state),
        emailVerificationCompleted: showEmailVerifiedNotification(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    toggleMainMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(withTranslation()(MainMenu)));
