import { AxiosInstance, AxiosResponse } from 'axios';

import { SessionDTO } from '../types/ApiTypes';

export class SessionsService {
    constructor(private axios: AxiosInstance) {}

    getMySessions = async (): Promise<AxiosResponse<SessionDTO[]>> => {
        return this.axios.get('/Session/GetMySessions');
    };

    terminateSessions = async (sessionIds: number[]): Promise<AxiosResponse<number[]>> => {
        return this.axios.post('/Session/TerminateSessions', sessionIds);
    };
}

export default SessionsService;
