import React, { Suspense } from 'react';

/**
 * HOC to be used when component is not in our normal React component tree, where we have Suspense already defined.
 * Component is not in our normal tree when we inject it into angular pages for example
 * @param WrappedComponent
 */
function withSuspense<T>(WrappedComponent: React.ComponentType<T>): React.ComponentClass<T> {
    return class SuspenseComponent extends React.Component<T> {
        render() {
            return (
                <Suspense fallback={null}>
                    <WrappedComponent {...(this.props as T)} />
                </Suspense>
            );
        }
    };
}

export default withSuspense;
