import * as React from 'react';
import { useEffect, useState } from 'react';
import { Form, Formik, FormikErrors, yupToFormErrors } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable1, Dispatchable2 } from 'redux-dispatchers';
import * as Yup from 'yup';
import { debounce } from 'lodash-es';

import { BackOfficeUserDTO } from '../../../../services/types/BoApiTypes';
import createFieldNameConstants from '../../../../common/utils/fieldNames';
import { createDataId } from '../../../../common/utils/dataId';
import FormikField from '../../../../common/utils/FormikField';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { ICONS } from '../../../Icon/Icon';
import { Select } from '../../../Select/Select';
import { TextInputField, TextInputType } from '../../../TextInput/TextInput';
import { SelectOptionValue } from '../../../Select/SelectOption';
import { getSupportedCountriesWithSquareFlags, getSupportedPersonalCodeCountriesWithSquareFlags } from '../../../../views/login/loginHelper';
import CollapsibleInfoBlockWithHeader from '../../../../components/CollapsibleInfoBlockWithHeader/CollapsibleInfoBlockWithHeader';
import withSuspense from '../../../../common/hocs/withSuspense';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { getPersonalCodeTooltip, validateEmailField, validateEmailFieldAllCompanies, validatePersonalCodeField } from '../../../../views/user-list/components/EditUserModal/EditUserModalHelpers';

import './PersonalDataForm.scss';

export interface EditBoUserFields {
    FirstName: string;
    LastName: string;
    Email: string;
    Country: SelectOptionValue<string>;
    PersonalCode: string;
    EmailLastValidated: string;
}

export interface Props {
    activeBoUser: BackOfficeUserDTO;
    dataId: string;
    isLoading: boolean;
    isSendingEmailLoading: boolean;
    autoFocusOnEmail: boolean;
    showUserProfile: (state: boolean) => void;
}

export interface DispatchProps {
    saveBoUser: Dispatchable2<BackOfficeUserDTO, boolean>;
    sendVerificationCodeToEmail: Dispatchable1<string>;
}

export type EditBoUserProps = Props & DispatchProps & WithTranslation;

const initialValues: EditBoUserFields = {
    FirstName: '',
    LastName: '',
    Email: '',
    Country: {
        text: 'N/A',
        value: undefined,
    },
    PersonalCode: '',
    EmailLastValidated: '',
};

export const PersonalDataForm = ({ activeBoUser, autoFocusOnEmail, dataId, isLoading, saveBoUser, showUserProfile, t }: EditBoUserProps) => {
    const [values, setValues] = useState<EditBoUserFields>(initialValues);
    const [userCountry, setUserCountry] = useState<SelectOptionValue<string & React.ReactElement>>({ value: '', text: '' });
    const [countryItems, setCountryItems] = useState<SelectOptionValue<string & React.ReactElement>[]>();

    const initCountrySelection = () => {
        // init country options for select
        const getCountriesWithFlags =
            !activeBoUser?.Country ||
            getSupportedPersonalCodeCountriesWithSquareFlags().find((e) => {
                return e.value === activeBoUser?.Country;
            })
                ? getSupportedPersonalCodeCountriesWithSquareFlags
                : getSupportedCountriesWithSquareFlags;
        const countryOptions = getCountriesWithFlags()
            .map((country) => ({
                ...country,
                text: t(country.text),
            }))
            .sort((c1, c2) => (c1.text > c2.text ? 1 : -1));
        countryOptions.push({ text: 'N/A', value: 'N/A' });
        setCountryItems(countryOptions);

        // init user country to show
        if (activeBoUser?.Country) {
            const countryToShow = countryOptions.find((c) => c.value === activeBoUser.Country);
            setUserCountry(countryToShow);
        } else {
            setUserCountry({ text: 'N/A', value: 'N/A' });
        }
    };

    const { FirstName: FirstNameField, LastName: LastNameField, Email: EmailField, Country: CountryField, PersonalCode: PersonalCodeField } = createFieldNameConstants(initialValues);
    const validationSchema = Yup.object<Pick<BackOfficeUserDTO, 'FirstName' | 'LastName' | 'Email' | 'Country' | 'PersonalCode'>>().shape({
        [FirstNameField]: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.backOffice.general.mandatoryField', { fieldName: t('view.backOffice.users.column.name') })),
        [LastNameField]: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.backOffice.general.mandatoryField', { fieldName: t('view.backOffice.users.column.surname') })),
        [EmailField]: Yup.string()
            .ensure()
            .nullable(true)
            .test('mandatory-personalCode', t('view.UserList.NewUserPopover.Error.EmailOrPersonalCode.MandatoryFields'), function(value) {
                if (!value && !this.parent.PersonalCode) {
                    return false;
                }
                return true;
            })
            .email(t('global.email.InvalidFormat')),
        [CountryField]: Yup.string()
            .nullable(true)
            .required(t('view.backOffice.companies.edit.mandatoryField', { fieldName: t('view.backOffice.companies.column.country') })),
        [PersonalCodeField]: Yup.string()
            .ensure()
            .nullable(true)
            .max(16, t('view.userProfile.personalData.codeMustBeNotMoreThan16'))
            .test('mandatory-personalCode', t('view.UserList.NewUserPopover.Error.EmailOrPersonalCode.MandatoryFields'), function(value) {
                if (!value && !this.parent.Email && userCountry.value !== 'N/A') {
                    return false;
                }
                return true;
            })
            .matches(/^[a-zA-Z0-9.\-/]*$/, t('views.groupMember.detail.personalCode.InvalidFormat')),
    });

    const validationOnSubmitSchema = Yup.object<Pick<BackOfficeUserDTO, 'FirstName' | 'LastName' | 'Email' | 'Country' | 'PersonalCode'>>().shape({
        [PersonalCodeField]: Yup.string()
            .nullable(true)
            .test('unique-personalCode', t('view.UserList.NewUserPopover.Error.PersonalCode.ExistsInCompany'), function(value) {
                if (!value || (value?.toLowerCase() === values?.PersonalCode?.toLowerCase() && userCountry.value === activeBoUser?.Country)) {
                    return true;
                }
                return new Promise((resolve) => {
                    validatePersonalCodeField(value, userCountry.value, resolve, activeBoUser?.UserGuid);
                });
            }),
        [LastNameField]: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.backOffice.general.mandatoryField', { fieldName: t('view.backOffice.users.column.surname') })),
        [EmailField]: Yup.string()
            .nullable(true)
            .test('unique-email', t('view.UserList.NewUserPopover.Error.Email.ExistsInCompany'), function(value) {
                if (!value || value?.toLowerCase() === activeBoUser?.Email?.toLowerCase()) {
                    return true;
                }
                return new Promise((resolve) => {
                    validateEmailField(value, resolve, activeBoUser?.UserGuid);
                }).then((result) => {
                    if (!result) {
                        return false;
                    }
                    if (!this.parent.PersonalCode) {
                        return true;
                    }
                    return new Promise((resolve) => {
                        validateEmailFieldAllCompanies(value, userCountry.value, this.parent.PersonalCode, resolve, activeBoUser?.UserGuid);
                    }).then((r) => {
                        if (!r) {
                            return this.createError({
                                message: t('view.UserList.NewUserPopover.Error.Email.ExistsInAnotherCompany'),
                            });
                        }
                        return true;
                    });
                });
            }),
    });

    useEffect(() => {
        initCountrySelection();
        if (activeBoUser) {
            const { FirstName, LastName, Email, PersonalCode, EmailLastValidated } = activeBoUser;
            setValues({
                FirstName,
                LastName,
                Email,
                Country: userCountry,
                PersonalCode,
                EmailLastValidated,
            });
        } else {
            setValues(initialValues);
        }
    }, [activeBoUser]);

    const handleSave = async (values: EditBoUserFields) => {
        const boUser: BackOfficeUserDTO = {
            ...activeBoUser,
            FirstName: values.FirstName,
            LastName: values.LastName,
            Email: values.Email,
            Country: userCountry.value === 'N/A' ? null : userCountry.value || null,
            PersonalCode: values.PersonalCode?.toUpperCase(),
        };
        const emailNotEqual = values?.Email && values?.Email?.toLowerCase() !== activeBoUser?.Email?.toLowerCase();
        saveBoUser(boUser, emailNotEqual);
        showUserProfile(false);
    };

    const validatePersonalCode = (value: string) => {
        const myRegEx = /[^a-z\d\-/]/i;
        if (myRegEx.test(value)) {
            return t('view.userProfile.personalData.codeMustBeAlphanumerical');
        }
        return undefined;
    };

    const validationOnSubmit = (values: EditBoUserFields, setErrors: (errors: FormikErrors<unknown>) => void) => {
        validationOnSubmitSchema
            .validate(values, { abortEarly: false })
            .then(() => {
                setErrors({});
                handleSave(values);
            })
            .catch((e) => {
                setErrors(yupToFormErrors(e));
            });
    };

    const validationOnSubmitDebounced = debounce(validationOnSubmit, 400);

    return (
        <Formik
            onSubmit={(values: EditBoUserFields, { setErrors }) => {
                validationOnSubmitDebounced(values, setErrors);
            }}
            initialValues={values}
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={validationSchema}
        >
            {(formik) => (
                <Form className="edit-user-data__container">
                    <div className="edit-user-data__form">
                        <CollapsibleInfoBlockWithHeader isCollapsible={true} dataId={createDataId('edit-user-data', 'GeneralInfo')} headerText={t('component.personalDataForm.generalInformation')}>
                            <FormikField
                                maxLength={64}
                                component={TextInputField}
                                name={FirstNameField}
                                dataId={createDataId(dataId, 'edit-user-data', 'name')}
                                type={TextInputType.BORDERED}
                                label={t('view.UserList.NewUserPopover.InputLabel.firstName')}
                            />
                            <FormikField
                                maxLength={64}
                                dataId={createDataId(dataId, 'edit-user-data', 'surname')}
                                component={TextInputField}
                                name={LastNameField}
                                type={TextInputType.BORDERED}
                                label={t('view.UserList.NewUserPopover.InputLabel.lastName')}
                            />
                        </CollapsibleInfoBlockWithHeader>
                        <CollapsibleInfoBlockWithHeader
                            isCollapsible={true}
                            dataId={createDataId('edit-user-data', 'LoginInfo')}
                            headerText={t('view.UserList.NewUserPopover.GroupLabel.LoginInformation')}
                        >
                            <div className="personal-country-code">
                                <Tooltip content={activeBoUser?.PersonalCodeLastValidated ? t('views.global.UserProfileModal.lockedPersonalCode.tooltip') : null} followCursor>
                                    <Select
                                        className="country-select"
                                        dataId={createDataId(dataId, 'edit-user-data', 'country')}
                                        disabled={!!activeBoUser?.PersonalCodeLastValidated}
                                        items={countryItems}
                                        value={userCountry}
                                        label={t('view.backOffice.companies.column.country')}
                                        triggerIcon={ICONS.ARROW_DOWN_SMALL}
                                        onChange={(country) => {
                                            setUserCountry(country);
                                            formik.setFieldValue(CountryField, country);
                                            formik.setFieldTouched(CountryField, true);
                                        }}
                                        error={formik.errors[CountryField]}
                                    />
                                </Tooltip>
                                <div className="personal-code">
                                    <Tooltip content={t(getPersonalCodeTooltip(userCountry.value, activeBoUser?.PersonalCodeLastValidated))} followCursor>
                                        <FormikField
                                            maxLength={16}
                                            className="personal-code"
                                            dataId={createDataId(dataId, 'edit-user-data', 'code')}
                                            component={TextInputField}
                                            validate={validatePersonalCode}
                                            name={PersonalCodeField}
                                            type={TextInputType.BORDERED}
                                            label={t('views.global.UserProfileModal.personalCode')}
                                            disabled={userCountry.value === 'N/A' || !!activeBoUser?.PersonalCodeLastValidated}
                                        />
                                    </Tooltip>
                                    {!!activeBoUser?.PersonalCodeLastValidated && (
                                        <Tooltip content={t('views.global.UserProfileModal.lockedPersonalCode.tooltip')}>
                                            <Button className="verified-tooltip" buttonType={ButtonType.ICON} icon={ICONS.LOCK} dataId={`${dataId}.personalCode.verificationIcon`} />
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                            <FormikField
                                maxLength={254}
                                dataId={createDataId(dataId, 'edit-user-data', 'email')}
                                component={TextInputField}
                                name={EmailField}
                                type={TextInputType.BORDERED}
                                label={t('view.UserList.NewUserPopover.InputLabel.Email')}
                                autofocus={autoFocusOnEmail}
                            />
                        </CollapsibleInfoBlockWithHeader>
                    </div>
                    <div className="user-profile__footer" data-id={createDataId(dataId, 'edit-user-data', 'footer')}>
                        <Button type="submit" loading={isLoading} className="edit-user-data__button" dataId={createDataId(dataId, 'edit-user-data', 'button.save')}>
                            {t('view.Settings.InvoiceTypes.EditModal.SaveAndClose')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default withSuspense(withTranslation()(PersonalDataForm));
