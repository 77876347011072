import { ReducerFactory } from 'redux-actions-ts-reducer';

import { GlobalState } from '../../rootReducer';
import { showChangeLanguage, setCurrentLanguage } from './ChangeLanguageActions';
import { selectedLanguageKey } from '../../i18n';

class State {
    isModalOpen = false;
    currentLanguage: string = localStorage.getItem(selectedLanguageKey);
}

export default new ReducerFactory(new State())
    .addReducer(
        showChangeLanguage,
        (state, action): State => {
            return {
                ...state,
                isModalOpen: action.payload,
            };
        },
    )
    .addReducer(
        setCurrentLanguage,
        (state, action): State => {
            return {
                ...state,
                currentLanguage: action.payload,
            };
        },
    )
    .toReducer();

export { State as ChangeLanguageState };

export const selectIsModalOpen = (state: GlobalState) => state.changeLanguage.isModalOpen;
export const selectCurrentLanguage = (state: GlobalState) => state.changeLanguage.currentLanguage;
