import { Restriction, SearchType, SortDirection } from '../../../services/types/ApiTypes';

export const DEFAULT_RESTRICTION = 'IsEnabled';

export const createRequest = (searchValue = '', page = 1, count = 15, columnName = DEFAULT_RESTRICTION) => {
    const restrictions: Restriction[] = [];
    if (searchValue && searchValue.length > 0) {
        restrictions.unshift({
            Field: columnName,
            FieldSearchType: SearchType.NotSelected,
            Value: searchValue || null,
            Values: null,
        } as Restriction);
    }
    return {
        PagingOptions: {
            Count: count,
            Page: page,
        },
        Restrictions: [...restrictions],
        SortItems: [
            {
                SortColumn: DEFAULT_RESTRICTION,
                SortDirection: SortDirection.Asc,
            },
        ],
    };
};
