import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable1 } from 'redux-dispatchers';
import { formatDate, formatMoneyAndCurrency } from '../../../common/utils/formatters';
import { BaseStatefulComponent } from '../../../components/BaseStatefulComponent';
import MainComponent from '../../../components/MainComponent/MainComponent';
import MainComponentContent from '../../../components/MainComponent/MainComponentContent';
import MainComponentHeader from '../../../components/MainComponent/MainComponentHeader';
import MainComponentMessage from '../../../components/MainComponent/MainComponentMessage';
import MainComponentRow from '../../../components/MainComponent/MainComponentRow';
import MainComponentRowActions from '../../../components/MainComponent/MainComponentRowActions';
import MainComponentRowAmount from '../../../components/MainComponent/MainComponentRowAmount';
import MainComponentRowContent from '../../../components/MainComponent/MainComponentRowContent';
import Pager from '../../../components/Pager/Pager';
import { BaseSearch, InvoiceRegistryMinDTO } from '../../../services/types/ApiTypes';
import withSuspense from '../../../common/hocs/withSuspense';
import { DashboardRejectedInvoicesState } from './DashboardRejectedInvoicesReducer';
import { Typography } from '../../../components/Typography';
import { createRequest } from './DashboardRejectedInvoicesHelper';
import { eventTrack } from '../../../common/analytics/gtm';

import './DashboardRejectedInvoices.scss';

export interface Props extends Pick<DashboardRejectedInvoicesState, 'dashboardRejectedInvoicesLoadable'> {}

export interface DispatchProps {
    getRejectedInvoices: Dispatchable1<BaseSearch>;
}

export type DashboardRejectedInvoicesProps = Props & DispatchProps & WithTranslation;

export class DashboardRejectedInvoices extends BaseStatefulComponent<DashboardRejectedInvoicesProps, DashboardRejectedInvoicesState> {
    componentDidMount() {
        const searchParams = createRequest();
        this.props.getRejectedInvoices(searchParams);
    }

    handlePageChange = (page: number) => {
        const { payload } = this.props.dashboardRejectedInvoicesLoadable;
        const searchParams = createRequest('', page, payload.Take);
        this.props.getRejectedInvoices(searchParams);
    };

    handleClick = (invoice: InvoiceRegistryMinDTO) => {
        eventTrack({
            event: 'dashboard_rejected_invoices',
            label: 'Open invoice',
        });
        window.location.href = `#/invoiceconfirmation/${invoice.Id}?status=${invoice.Status}`;
    };

    render() {
        const { dashboardRejectedInvoicesLoadable, t } = this.props;
        return (
            <MainComponent
                loading={dashboardRejectedInvoicesLoadable.loading}
                empty={!dashboardRejectedInvoicesLoadable.loading && dashboardRejectedInvoicesLoadable.payload && !dashboardRejectedInvoicesLoadable.payload.Items.length}
            >
                <MainComponentHeader>
                    <Typography element="span" variant="h1">
                        {t('views.dashboard.rejectedinvoices.title')} ({dashboardRejectedInvoicesLoadable.payload && dashboardRejectedInvoicesLoadable.payload.TotalCount})
                    </Typography>
                </MainComponentHeader>
                <MainComponentContent>
                    {dashboardRejectedInvoicesLoadable.payload && dashboardRejectedInvoicesLoadable.payload.Items.length ? (
                        dashboardRejectedInvoicesLoadable.payload.Items.map((ri, i) => {
                            return (
                                <MainComponentRow key={`${ri.Id}+${i}`}>
                                    <MainComponentRowContent>
                                        <span className="table-main__row-supplier">{ri.SupplierName || t('view.Dashboard.PurchaseOrders.NoSupplier')}</span>
                                        <span className="table-main__row-description">{ri.Number + (ri.Description ? ' - ' + t(ri.Description) : '')}</span>
                                        <span className="table-main__row-date">
                                            {formatDate(ri.InvoiceDate)} | {formatDate(ri.DueDate)}
                                        </span>
                                    </MainComponentRowContent>
                                    <MainComponentRowAmount>
                                        <span>{formatMoneyAndCurrency(ri.TotalAmountWithVat || 0, ri.Currency)}</span>
                                    </MainComponentRowAmount>
                                    <MainComponentRowActions>
                                        <button className={'btn btn--md btn--primary'} onClick={() => this.handleClick(ri)}>
                                            {t('views.invoice.IvoiceFastApprove')}
                                        </button>
                                    </MainComponentRowActions>
                                </MainComponentRow>
                            );
                        })
                    ) : (
                        <MainComponentMessage>{t('view.Dashboard.RejectedInvoices.NoRejectedInvoices')}</MainComponentMessage>
                    )}
                </MainComponentContent>
                <div className="pagination__wrap">
                    <Pager showExtremes={false} closedArrows={false} pages={dashboardRejectedInvoicesLoadable.payload} onPageChange={this.handlePageChange} hidePageSize={true} />
                </div>
            </MainComponent>
        );
    }
}

export default withSuspense(withTranslation()(DashboardRejectedInvoices));
