'use strict';

import { InvoiceStatus } from '../../../src/common/constants/appConstants';
import api from '../../../src/services/ApiServices';
import { encodeUriFilterObject } from "../../../src/views/invoice-details/components/invoice-slider/InvoiceSliderHelpers";
import {setPdfToOpenAction} from '../../../src/components/PDFViewer/FileViewActions';
import { setExternalLinkToOpen } from '../../../src/components/OpenExternalLinkConfirm/OpenExternalLinkConfirmModalActions';
import { CompanySettingStatus } from '../../../src/views/settings/company-settings/components/Settings/CompanySettingsListHelper';
import { eventTrack } from '../../../src/common/analytics/gtm';

angular
    .module('dstreamApp.components')
    .component('dsApproversDashboardInvoices', {
        templateUrl: 'app/components/approversDashboardInvoices/ds-approvers-dashboard-invoices.html',
        controller: DsApproversDashboardInvoicesController,
    });

const NEW_INVOICES_CUSTOM_RESTRICTION_FIELD = 'WaitingForConfirmationGuidList';

/* @ngInject */
function DsApproversDashboardInvoicesController(
    $scope,
    $rootScope,
    $timeout,
    $filter,
    $q,
    webServices,
    notifyService,
    invoiceService,
    constants,
    companyDataService,
    invoiceRegisterService,
    $location,
    $ngRedux
) {
    const ctrl = this;

    /**
     * CONTROLLER VARIABLES
     */
    ctrl.DATE_EE_FORMAT = constants.DATE_EE_FORMAT;
    ctrl.getTaskStatusTranslateKey = constants.getTaskStatusTranslateKey;
    ctrl.getGroupMemberFullNameById = $scope.$parent.$parent.getGroupMemberFullNameById;
    ctrl.FileUrl = constants.FileUrl;
    ctrl.DownloadUrl = constants.DownloadUrl;
    ctrl.localStoragePrefix = 'approvers';
    /**
     * filterObject that ensures that we get correct invoices in the new invoices table.
     * Because we have three tables in the same view that use the filter service, then we need to override all the params other tables might set
     */
    ctrl.filterObject = {
        pagingOptions: {
            page: 1,
            count: 15,
            total: 0,
        },
        isIncludeWorkflows: false,
        statusFilter: [],
        timeType: 'forever',
        invoiceType: 0,
        customRestrictions: [
            {
                Field: NEW_INVOICES_CUSTOM_RESTRICTION_FIELD,
                Values: [$ngRedux.getState().user.currentUser.BOGuid],
            },
        ],
        sortItems: [
            {
                SortColumn: 'InvoiceDate',
                SortDirection: 'desc',
            },
        ],
    };

    /**
     * SCOPE VARIABLES
     */
    $scope.invoices = [];
    $scope.searchParams = {};
    $scope.unConfirmedInvoices = 0;
    $scope.invoicesTotalCount = 0;
    $scope.invoicesLoading = true;
    $scope.quickConfirmAllowed = false;
    $scope.allConfirmAllowed = false;
    $scope.allConfirmed = false; // has clicked confirm but actual approval hasn't happened
    $scope.allApproved = false; // actual approval has happened
    $scope.confirmTime = 5000; // time when invoice confirm undo is possible
    $scope.isNewSupplierVisible = false;

    /**
     * LOCAL WATCHER VARIABLES
     */

    var authorizationDataLoadedEvent = $rootScope.$on('authorizationDataLoaded', function () {
        ctrl.filterObject.isIncludeWorkflows = false;
        loadInvoices();
    });

    var confirmDashboardInvoiceEvent = $rootScope.$on('confirmDashboardInvoice', function (e, invoice) {
        $scope.confirmInvoice(invoice, e);
    });

    var cancelDashboardApprovalEvent = $rootScope.$on('cancelDashboardApproval', function (e, invoice) {
        $scope.cancelApproval(invoice);
    });

    /**
     * CONTROLLER FUNCTION DECLARATIONS
     */
    ctrl.showSubstituteName = showSubstituteName;
    ctrl.getSubstituteText = getSubstituteText;
    ctrl.getCompletedTasks = getCompletedTasks;
    ctrl.pageChanged = pageChanged;
    ctrl.$onInit = init;
    ctrl.$onDestroy = destroy;

    /**
     * SCOPE FUNCTION DECLARATIONS
     */
    $scope.displayInvoice = displayInvoice;
    $scope.expandRow = expandRow;
    $scope.confirmInvoice = confirmInvoice;
    $scope.confirmAllInvoices = confirmAllInvoices;
    $scope.openFile = openFile;

    /**
     * Save filter before navigation to invoice detail view
     */
    function displayInvoice(invoice, e) {
        e.preventDefault();
        eventTrack({
            event: 'dashboard_invoices_to_confirm',
            label: 'Open invoice',
        });
        invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, false, true);
        $location.path("/invoiceconfirmation/" + invoice.Id);
    }

    function expandRow($event, invoice) {
        $event.preventDefault();
        const eventLabel = angular.element($event.currentTarget).hasClass("btn") ? 'Preview' : 
            angular.element($event.currentTarget).hasClass("open") ? 'Shrink row' : 'Expand row';
            eventTrack({
                event: 'dashboard_invoices_to_confirm',
                label: eventLabel
            });
        
        if (!invoice.isLoading) {
            expandRowInner($event); // Row can only be expanded if no info isn't being fetched
        }

        if (!invoice.isLoaded) {
            invoice.isLoading = true; // Is info being fetched from the web service?
            invoice.isLoaded = true;  // Optimistic condition.
            api.invoice.getDashboardInvoiceExt(invoice.Id).then(function ({ data }) {
                if (data) {
                    var status = invoice.currentStatus;
                    invoice.DocumentFiles = data.DocumentFiles;
                    invoice.Workflow = data.Workflow;
                    invoice.currentStatus = status;
                }
            }).catch(function () {
                notifyService.error('controller.dashboardController.Error', 'controller.dashboardController.Error', true);
            }).finally(function () {
                invoice.isLoading = false; // The parameters can be lost because angular.copy, so we assign booleans here.
                invoice.isLoaded = true;   // Invoice already has all the information. We will not require it again.
            });
        }
    }

    function getCompletedTasks(invoice) {
        const { Workflow } = invoice;
        if (Workflow && Workflow.Tasks && Workflow.Tasks.length) {
            return Workflow.Tasks.filter((task) => task.Completed).sort((a, b) => a.OrderNo - b.OrderNo);
        }
        return [];
    }

    /**
     * Set timer and approve the invoice when it runs out
     */
    function confirmInvoice(invoice, e, validateSilently) {
        e.preventDefault();
        eventTrack({
            event: 'dashboard_invoices_to_confirm',
            label: 'Confirm invoice',
        });
        $scope.invoicesLoading = true;
        return invoiceService.validateInvoiceDataForConfirmation(invoice.Id)
            .then((result) => {
                const { validations, errors } = result;
                if (validations.accessValid && validations.dataValid) {
                    approveInvoice(createInvoiceAction('Approved', invoice.Id), invoice);
                    return {
                        validated: true,
                        invoice
                    };
                } else {
                    $scope.invoicesLoading = false;
                    if (!validateSilently) {
                        invoiceService.showValidationFailedModal(validations, errors, invoice.Id);
                    }
                    return {
                        validated: false,
                        invoice
                    };
                }
            }).catch(function () {
                $scope.invoicesLoading = false;
            });
    }

    function confirmAllInvoices(e) {
        e.preventDefault();
        eventTrack({
            event: 'dashboard_invoices_to_confirm',
            label: 'Confirm all invoices',
        });
        /**
         * create new filterObject to select exactly all confirmable invoices in the list
         */
        const filterObject = {
            ...ctrl.filterObject,
            pagingOptions: {
                ...ctrl.filterObject.pagingOptions,
                page: 1,
                count: ctrl.filterObject.pagingOptions.total,
            }
        };

        /**
         * fetch all confirmable invoices and try to confirm them
         */
        invoiceRegisterService
            .doSearchWithQueue(filterObject, true, true, false, false, ctrl.localStoragePrefix)
            .then((response) => {
                return response.result;
            })
            .then((invoices) => {
                return invoices.map(invoice => confirmInvoice(invoice, e, true));
            })
            .then((invoicesToConfirm) => {
                $q.all(invoicesToConfirm).then((responseArray) => {
                    let confirmedInvoices = [];
                    let failedConfirmations = [];
                    responseArray.forEach((confirmationResult) => {
                        if (confirmationResult.validated) {
                            confirmedInvoices.push(confirmationResult.invoice);
                        } else {
                            failedConfirmations.push(confirmationResult.invoice);
                        }
                    });
                    return failedConfirmations;
                }).then((failedResults) => {
                    if (failedResults.length === 0) {
                        // If all invoices confirmed successfully, display notification
                        $scope.invoices = []
                        $scope.invoicesTotalCount = 0
                        $scope.allConfirmed = true;
                    } else {
                        // If some invoices could not be confirmed, display warning
                        invoiceService.showValidationFailedModal({}, [
                            {
                                confirmAll: true,
                                invoices: failedResults,
                                message: {
                                    title: $filter('translate')('component.DashboardConfirmation.ConfirmAll.Fail.Title'),
                                    content: $filter('translate')(
                                        'component.DashboardConfirmation.ConfirmAll.Fail.Content',
                                    ),
                                },
                            },
                        ],
                            failedResults[0].Id,
                        );
                    }
                });
            });
    }

    function openFile(file, invoice, e) {
        e.preventDefault();

        eventTrack({
            event: 'dashboard_invoices_to_confirm',
            label: 'Open invoice attachment',
        });
        
        if(!file.IsPdf && !file.FileUrl)
        {
            webServices.downloadInvoiceFileByUrl(ctrl.DownloadUrl + file.Id).then(function (response) {
                var downloadedFile = new Blob([response.data], { type: "application/octet-stream" });
                saveAs(downloadedFile, file.FileName);
            });
            return;
        }

        if (file.IsPdf) {
            file.FileUrl = constants.FileUrl.concat(file.Id);
            file.DownloadUrl = constants.FileUrl.concat(file.Id);
            setTimeout(function () {
                $rootScope.$emit('file.open', file, invoice);
            });

            $ngRedux.dispatch(setPdfToOpenAction(file));
        } else if (!file.FileUrl) {
            setTimeout(function () {
                $rootScope.$emit('file.open', file);
                $ngRedux.dispatch(setPdfToOpenAction(file));
            });
        } else {
            if (!file.FileUrl.match(/^https?:\/\//i)) {
                file.FileUrl = 'http://' + file.FileUrl;
            }
            $ngRedux.dispatch(setExternalLinkToOpen(file.FileUrl));
        }
    }

    /**
     * Pagination page change
     */
    function pageChanged(value) {
        ctrl.filterObject.pagingOptions.page = value;
        ctrl.filterObject.isIncludeWorkflows = false;
        ctrl.filterObject = invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 0, 1, ctrl.localStoragePrefix);
        loadInvoices();
    }

    /**
     * NON-Scope Functions
     */
    function expandRowInner($event) {
        // hide other rows
        $('.row--collapsible .row__content').stop().slideUp(200);
        $('.table-main__row-toggler').not($event.currentTarget).removeClass('open');
        // toggle row
        $($event.currentTarget).toggleClass('open');
        $($event.currentTarget).closest('.table-main__row').next('.row--collapsible').find('.row__content').stop().slideToggle(200);
    }

    function createInvoiceAction(action, invoiceId) {
        return invoiceService.createInvoiceAction(action, invoiceId, $scope.approveInvoiceComment, null);
    }

    function approveInvoice(invoiceAction, invoice) {
        invoice.confirmationInProgress = true;
        invoiceService.approveInvoice(invoiceAction).then(() => {
            invoice.approved = true;
            invoice.assigned = false;
            invoice.confirmationInProgress = false;
            $scope.unConfirmedInvoices -= 1;
            loadInvoices();
        });
    }

    //Load and Init functions
    const loadInvoices = () => {
        if ($rootScope.userData) {
            $scope.invoicesLoading = true;
            ctrl.filterObject.isIncludeWorkflows = false;
            
            /*
                avoid accidentally using Receipt (1) InvoiceType from ds-dashboard-receipts' component
                EMR-5126 REGRESSION
            */
            ctrl.filterObject.invoiceType = 0;

            const filterObject = invoiceRegisterService
                .saveFilterToLocalStorage(
                    ctrl.filterObject,
                    true,
                    true,
                    ctrl.localStoragePrefix
                );
            api.invoice
                .getDashboardInvoicesMin(invoiceRegisterService.createApiRequestObject(filterObject))
                .then(({ data }) => {
                    let invoices = [];
                    if (data && data.Items && data.Items.length > 0) {
                        invoices = data.Items;
                    }
                    $scope.invoicesLoading = false;
                    if (invoices.length > 0) {
                        invoices.forEach((invoice) => {
                            invoice.currentStatus = invoiceService.setInvoiceStatus(invoice);
                            invoice.assigned = [InvoiceStatus.InApproval].includes(invoice.Status);
                            invoice.approved = [InvoiceStatus.PendingExport, InvoiceStatus.Exported].includes(invoice.Status);
                        });

                        $scope.invoices = [...invoices];
                        $scope.unConfirmedInvoices = $scope.invoices.length;
                    }

                    // update the filterObject
                    ctrl.filterObject.pagingOptions = {
                        page: data.Skip / data.Take + 1,
                        count: data.Take,
                        total: data.TotalCount,
                    };
                    $scope.invoicesTotalCount = ctrl.filterObject.pagingOptions.total;
                }).catch((e) => {
                    console.error(e);
                    $scope.invoicesLoading = false;
                });
        }
    };

    function showSubstituteName(user) {
        return user.Name !== user.SubstituterUserFullName && !!user.SubstituterUserFullName;
    }

    function getSubstituteText(task) {
        if (task.hasOwnProperty('task')) {
        }
        var text = '';
        if (task.hasOwnProperty('completed')) {
            text += $filter('date')(task.completed, ctrl.DATE_EE_FORMAT);
        }
        if (task.hasOwnProperty('substitute')) {
            text += ' ' + task.substitute;
            text += '&nbsp;<span class=\'confirmation-flow__comment\'>' + $filter('translate')('component.transactionRows.onbehalf');
        }
        if (task.hasOwnProperty('user')) {
            text += '&nbsp;' + task.user;
            text += task.hasOwnProperty('substitute') ? '</span>' : '';
        }
        if (task.hasOwnProperty('status')) {
            text += ' ' + $filter('translate')(ctrl.getTaskStatusTranslateKey(task.status));
        }
        return text;
    }

    function setCompanySettings(currentCompanySettings = []) {
        angular.forEach(currentCompanySettings, function (setting) {
            if (setting.Name === 'IsConfirmAllAllowed') {
                $scope.allConfirmAllowed = setting.Value === CompanySettingStatus.Enabled;
            } else if (setting.Name === 'IsQuickConfirmAllowed') {
                $scope.quickConfirmAllowed = setting.Value === CompanySettingStatus.Enabled;
            } else if (setting.Name === 'IsExportWithUnregisteredSupplierDenied') {
                $scope.isNewSupplierVisible = setting.Value === CompanySettingStatus.Enabled;
            }
        });
    }

    var companySettingsLoadEventListener = $rootScope.$on('ds.currentCompany.companySettingsLoaded', function (event, value) {
        const {company : {currentCompanySettings}} = $ngRedux.getState();
        setCompanySettings(currentCompanySettings);
    });

    ctrl.getUriFilterObject = encodeUriFilterObject(invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 0, 1));

    /**
     * ============= ANGULAR FUNCTIONS ================
     */

    function init() {
        ctrl.filterObject.isIncludeWorkflows = false;
        loadInvoices();

        const {company : {isCurrentCompanySettingsLoading, currentCompanySettings}} = $ngRedux.getState();
        if (!isCurrentCompanySettingsLoading) {
            setCompanySettings(currentCompanySettings);
        }
    }

    function destroy() {
        authorizationDataLoadedEvent();
        confirmDashboardInvoiceEvent();
        cancelDashboardApprovalEvent();
        companySettingsLoadEventListener();
    }
}
