import { mapUserRoles, UserRole } from '../../common/constants/appConstants';
import { SearchType, SortDirection, UserDTO } from '../../services/types/ApiTypes';
import { formatDate } from '../../common/utils/formatters';
import { UserListSearchParams } from './UserListViewReducer';
import { StatusType } from '../../components/Status/Status';

export const DEFAULT_RESTRICTION = 'GeneralSearchEmr'; // GeneralSearchEmr includes search by Username, as opposed to just GeneralSearch https://tickets.unifiedpost.com/browse/EMR-6113

export const createRequest = (searchValue = '', sortColumn = 'Email', page = 1, count = 15): UserListSearchParams => ({
    PagingOptions: {
        Count: count,
        Page: page,
    },
    Restrictions:
        searchValue && searchValue.length > 0
            ? [
                  {
                      Field: 'FullName',
                      FieldSearchType: SearchType.NotSelected,
                      Value: searchValue || null,
                      Values: null,
                  },
              ]
            : [],
    SortItems: [
        {
            SortColumn: sortColumn,
            SortDirection: SortDirection.Asc,
        },
    ],
    filters: {},
});

export function getUserListColumnName(columnName: string): string {
    const columnNames = {
        OrderNumber: 'view.PurchaseOrders.Number',
        Supplier: 'view.PurchaseOrders.Supplier',
        Description: 'view.PurchaseOrders.Description',
        DateCreated: 'view.PurchaseOrders.DateCreated',
        OrderStatus: 'view.PurchaseOrders.Status',
        OrderType: 'view.PurchaseOrders.Type',
        NetTotal: 'view.PurchaseOrders.Rows.NetTotal',
        Total: 'view.PurchaseOrders.SumTotal',
    };
    return columnNames[columnName];
}

export const getRoles = () => mapUserRoles;

export const getUserRole = (role: UserRole) => mapUserRoles[role];

export const getFileNameFromHeader = (contentDispositionHeader: string, extension: string): string => {
    // default, avoid empty
    let fileName = `Users_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.${extension}`;

    // if BE gave us filename - use it
    // supposed to be according to spec: https://fitekgroup.atlassian.net/wiki/spaces/PDP/pages/2600730685/Users+Export#Export-file-naming
    if (contentDispositionHeader) {
        const match = new RegExp('filename[^;\\n=*]*=(([\'"]).*?\\2)').exec(contentDispositionHeader);
        if (match && match.length > 1) {
            fileName = match[1].split('"').join('');
        }
    }
    return fileName;
};

export const getEmailValidationStatusType = (user: UserDTO) => {
    if (user?.EmailLastValidated && user?.Email) {
        return StatusType.GREEN;
    }
    return StatusType.YELLOW;
};
