import { ReducerFactory } from 'redux-actions-ts-reducer';
import { Action } from 'redux-actions';
import produce from 'immer';

import { GlobalState } from '../../rootReducer';
import { setActiveCompanyGuid, showChangeCompanyAction, getcompanySwitcherListLoadable, updateWaitingInvoicesCountAction, clearCompanySwitcherListLoadable } from './ChangeCompanyActions';
import { BaseSearchWithTableFilters, PagedListContainer } from '../../services/types/ApiTypes';
import { TableFilter } from '../Table/components/filter/TableFilters';
import { BackOfficeCompanyDTOExtended } from '../../services/types/BoApiTypes';
import { LoadableData } from '../../common/utils/LoadableData';
import { createRequest } from '../../common/user/userSettingUtil';

export type CompanySwitcherSearchParams = BaseSearchWithTableFilters<TableFilter<any>, BackOfficeCompanyDTOExtended>;

class State {
    isModalOpen = false;
    activeCompanyGuid: string = undefined;
    companySwitcherListLoadable = new LoadableData<PagedListContainer<BackOfficeCompanyDTOExtended>, CompanySwitcherSearchParams>();
    searchParams: CompanySwitcherSearchParams = {
        ...createRequest(''),
        filters: {},
    };
}

export default new ReducerFactory(new State())
    .addReducer(
        showChangeCompanyAction,
        (state, action): State => {
            return {
                ...state,
                isModalOpen: action.payload,
            };
        },
    )
    .addReducer(
        setActiveCompanyGuid,
        (state, action): State => {
            return {
                ...state,
                activeCompanyGuid: action.payload,
            };
        },
    )
    .addReducer(
        getcompanySwitcherListLoadable.request,
        (state, action): State => {
            const companySwitcherListLoadable = state.companySwitcherListLoadable.withLoading(action.payload);
            return {
                ...state,
                searchParams: action.payload,
                companySwitcherListLoadable,
            };
        },
    )
    .addReducer(
        getcompanySwitcherListLoadable.success,
        (state, action): State => {
            const companySwitcherListLoadable = state.companySwitcherListLoadable.withPayloadIfRequestEquals(action.payload);
            return {
                ...state,
                searchParams: action.payload.request,
                companySwitcherListLoadable,
            };
        },
    )
    .addReducer(
        getcompanySwitcherListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                companySwitcherListLoadable: state.companySwitcherListLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        updateWaitingInvoicesCountAction,
        produce((draft: State, action: Action<{ companyGuid: string; count: number }>) => {
            const index = draft.companySwitcherListLoadable?.payload?.Items?.findIndex((c) => c.CompanyGuid === action.payload.companyGuid);
            if (index !== -1) {
                draft.companySwitcherListLoadable.payload.Items[index].WaitingInvoiceCount = action.payload.count;
            }
        }),
    )
    .addReducer(
        clearCompanySwitcherListLoadable,
        (state): State => {
            return {
                ...new State(),
                activeCompanyGuid: state.activeCompanyGuid,
            };
        },
    )
    .toReducer();

export { State as ChangeCompanyState };

export const selectIsChangeCompanyOpen = (state: GlobalState) => state.changeCompany.isModalOpen;
export const selectActiveCompanyGuid = (state: GlobalState) => state.changeCompany.activeCompanyGuid;
export const selectCompanySwithcerListLoadable = (state: GlobalState) => state.changeCompany.companySwitcherListLoadable;
export const selectCompanySwithcerListSearchParams = (state: GlobalState) => state.changeCompany.searchParams;
