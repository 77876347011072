import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import produce from 'immer';

import { LoadableData } from '../../common/utils/LoadableData';
import {
    PurchaseOrdersDTO,
    PurchaseOrderFileAttachmentDTO,
    PurchaseOrderTaskDTO,
    PurchaseOrdersRowsDTO,
    PagedListContainer,
    PurchaseOrderHeaderHistoryDTO,
    BaseSearchWithTableFilters,
} from '../../services/types/ApiTypes';

import {
    downloadFileActions,
    retrievePOActions,
    retrievePORowsActions,
    retrievePOTaskItemsActions,
    uploadFileActions,
    uploadFileProgress,
    updateExtraStatusActions,
    retrievePOFilesActions,
    updateRow,
    updateSums,
    removeRow,
    getPurchaseOrderHistoryLoadable,
} from './PurchaseOrdersAddViewActions';
import { TableFilter } from '../../components/Table/components/filter/TableFilters';
import { createRequest } from './PurchaseOrdersAddViewHelper';

export type HistorySearchParams = BaseSearchWithTableFilters<TableFilter<any>, PurchaseOrderHeaderHistoryDTO>;

class State {
    retrievePOLoadable = new LoadableData<PurchaseOrdersDTO, string>();
    purchaseOrder: PurchaseOrdersDTO = undefined; // used for updating
    poTaskItemsLoadable = new LoadableData<PurchaseOrderTaskDTO[], PurchaseOrdersDTO>();
    poRowsLoadable = new LoadableData<PurchaseOrdersRowsDTO[], string>();
    poFilesLoadable = new LoadableData<PurchaseOrderFileAttachmentDTO[], string>();
    uploadFileLoadable = new LoadableData<string, string>();
    uploadFileProgress = 0;
    downloadFileLoadable = new LoadableData<any, PurchaseOrderFileAttachmentDTO>();
    updateExtraStatusLoadable = new LoadableData<number, boolean>();
    searchParams: HistorySearchParams = {
        ...createRequest('', 1),
    };
    purchaseOrderHistoryLoadable = new LoadableData<PagedListContainer<PurchaseOrderHeaderHistoryDTO>, HistorySearchParams>();
}

export default new ReducerFactory(new State())
    .addReducer(
        retrievePOActions.request,
        (state): State => {
            return {
                ...state,
                retrievePOLoadable: state.retrievePOLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        retrievePOActions.success,
        (state, action): State => {
            return {
                ...state,
                retrievePOLoadable: LoadableData.payload(action.payload),
                purchaseOrder: action.payload,
            };
        },
    )
    .addReducer(
        retrievePOActions.error,
        (state, action): State => {
            return {
                ...state,
                retrievePOLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        updateSums,
        produce((draft, action: Action<{ totalSum: number; totalSumWithoutVat: number }>) => {
            draft.retrievePOLoadable.payload.Total = action.payload.totalSum;
            draft.retrievePOLoadable.payload.TotalWithoutVat = action.payload.totalSumWithoutVat;
        }),
    )
    .addReducer(
        retrievePORowsActions.request,
        (state): State => {
            return {
                ...state,
                poRowsLoadable: state.poRowsLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        retrievePORowsActions.success,
        (state, action): State => {
            return {
                ...state,
                poRowsLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        retrievePORowsActions.error,
        (state, action): State => {
            return {
                ...state,
                poRowsLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        updateRow,
        produce((draft, action: Action<{ result: PurchaseOrdersRowsDTO; itemToUpdate: number }>) => {
            if (!action.payload.itemToUpdate) {
                draft.poRowsLoadable.payload = [...draft.poRowsLoadable.payload, action.payload.result];
            } else {
                const index = draft.poRowsLoadable.payload.findIndex((i: PurchaseOrdersRowsDTO) => i.Id === action.payload.itemToUpdate);
                if (index !== -1) {
                    draft.poRowsLoadable.payload[index] = {
                        ...action.payload.result,
                    };
                }
            }
        }),
    )
    .addReducer(
        removeRow,
        produce((draft, action) => {
            draft.poRowsLoadable.payload = draft.poRowsLoadable.payload.filter((i: PurchaseOrdersRowsDTO) => i.Id !== action.payload);
        }),
    )
    .addReducer(
        retrievePOFilesActions.request,
        (state): State => {
            return {
                ...state,
                poFilesLoadable: state.poFilesLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        retrievePOFilesActions.success,
        (state, action): State => {
            return {
                ...state,
                poFilesLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        retrievePOFilesActions.error,
        (state, action): State => {
            return {
                ...state,
                poFilesLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        retrievePOTaskItemsActions.request,
        (state): State => {
            return {
                ...state,
                poTaskItemsLoadable: state.poTaskItemsLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        retrievePOTaskItemsActions.success,
        (state, action): State => {
            return {
                ...state,
                poTaskItemsLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        retrievePOTaskItemsActions.error,
        (state, action): State => {
            return {
                ...state,
                poTaskItemsLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        uploadFileActions.request,
        (state): State => {
            return {
                ...state,
                uploadFileLoadable: state.uploadFileLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        uploadFileActions.success,
        (state, action): State => {
            return {
                ...state,
                uploadFileLoadable: LoadableData.payload(action.payload),
                uploadFileProgress: 0,
            };
        },
    )
    .addReducer(
        uploadFileActions.error,
        (state, action): State => {
            return {
                ...state,
                uploadFileLoadable: LoadableData.error(action.payload),
                uploadFileProgress: 0,
            };
        },
    )
    .addReducer(
        uploadFileProgress,
        (state, action): State => {
            return {
                ...state,
                uploadFileProgress: action.payload,
            };
        },
    )
    .addReducer(
        downloadFileActions.request,
        (state): State => {
            return {
                ...state,
                downloadFileLoadable: state.downloadFileLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        downloadFileActions.success,
        (state, action): State => {
            return {
                ...state,
                downloadFileLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        downloadFileActions.error,
        (state, action): State => {
            return {
                ...state,
                downloadFileLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state, action: Action<RouterState>): State => {
            // since we redirect user from /add to /details/:id once we get the PO id, we do not want to reset the state
            if (/\/purchase-orders\/details/.test(action.payload.location.pathname)) {
                return {
                    ...state,
                };
            }
            return new State();
        },
    )
    .addReducer(
        updateExtraStatusActions.request,
        (state): State => {
            return {
                ...state,
                updateExtraStatusLoadable: state.updateExtraStatusLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        updateExtraStatusActions.success,
        (state, action): State => {
            return {
                ...state,
                updateExtraStatusLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        updateExtraStatusActions.error,
        (state, action): State => {
            return {
                ...state,
                updateExtraStatusLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        getPurchaseOrderHistoryLoadable.request,
        (state, action): State => {
            const purchaseOrderHistoryLoadable = state.purchaseOrderHistoryLoadable.withLoading(action.payload);
            return {
                ...state,
                searchParams: action.payload,
                purchaseOrderHistoryLoadable,
            };
        },
    )
    .addReducer(
        getPurchaseOrderHistoryLoadable.success,
        (state, action): State => {
            const purchaseOrderHistoryLoadable = state.purchaseOrderHistoryLoadable.withPayloadIfRequestEquals(action.payload);
            return {
                ...state,
                searchParams: action.payload.request,
                purchaseOrderHistoryLoadable,
            };
        },
    )
    .addReducer(
        getPurchaseOrderHistoryLoadable.error,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                purchaseOrderHistoryLoadable: LoadableData.error(action.payload.result),
            };
        },
    )
    .toReducer();

export { State as PurchaseOrdersAddViewState };
