"use strict";

angular
  .module("dstreamApp.components")
  .controller("DsAutoTransactionConfirmationController", DsAutoTransactionConfirmationController);

function DsAutoTransactionConfirmationController (
  $scope,
  $uibModalInstance,
  webServices,
  activeInvoiceService,
  companyDataService,
  unPostedAmount,
  invoiceCurrency,
  emptyMandatoryFields,
  existingDuplicate
) {
  var ctrl = this;

  ctrl.$onInit = init;
  ctrl.close = close;
  ctrl.seeErrors = seeErrors;
  ctrl.proceedWithSnapshotSaving = proceedWithSnapshotSaving;
  ctrl.hasUnPostedAmount = hasUnPostedAmount;
  ctrl.getUnPostedAmount = getUnPostedAmount;
  ctrl.getInvoiceCurrency = getInvoiceCurrency;
  ctrl.getEmptyMandatoryFields = getEmptyMandatoryFields;
  ctrl.getEmptyMandatoryFieldsCount = getEmptyMandatoryFieldsCount;
  ctrl.hasEmptyMandatoryFields = hasEmptyMandatoryFields;
  ctrl.hasExistingDuplicate = hasExistingDuplicate;

  function init () {
    if (!unPostedAmount || _.isEmpty(emptyMandatoryFields) || !existingDuplicate) {
      $uibModalInstance.close({passedValidation: true});
    }
  }

  function close () {
    $uibModalInstance.dismiss("user close");
  }

  function proceedWithSnapshotSaving () {
    $uibModalInstance.close({accepted: true});
  }

  function seeErrors () {
    $uibModalInstance.close({emptyMandatoryFields: emptyMandatoryFields});
  }

  function hasUnPostedAmount () {
    return !!unPostedAmount;
  }

  function hasEmptyMandatoryFields () {
    return !!emptyMandatoryFields.length;
  }

  function hasExistingDuplicate () {
    return !!existingDuplicate;
  }

  function getUnPostedAmount () {
    return unPostedAmount.toFixed(2);
  }

  function getInvoiceCurrency () {
    return invoiceCurrency;
  }

  function getEmptyMandatoryFieldsCount () {
    return emptyMandatoryFields.length;
  }

  function getEmptyMandatoryFields () {
    return emptyMandatoryFields;
  }
}
