import { withTranslation } from 'react-i18next';
import withSuspense from '../../common/hocs/withSuspense';

import { GlobalState } from '../../rootReducer';
import { connect } from '../../storeConfig';

import { DashboardPurchaseOrders, DispatchProps, Props } from './DashboardPurchaseOrders';
import { getPurchaseOrdersToConfirm } from './DashboardPurchaseOrdersActions';
import { selectDashboardPurchaseOrdersLoadable } from './DashboardPurchaseOrdersReducers';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        dashboardPurchaseOrdersLoadable: selectDashboardPurchaseOrdersLoadable(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    getPurchaseOrdersToConfirm,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(withTranslation()(DashboardPurchaseOrders)));
