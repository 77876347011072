import { ReducerFactory } from 'redux-actions-ts-reducer';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { BackOfficeUserDTO } from '../../../services/types/BoApiTypes';
import { BaseSearch, PagedListContainerOf } from '../../../services/types/ApiTypes';

import { BoUserListSearchParams, getBoUserListLoadable, getAllBoUserListLoadable, setUserTouchedBoRoleFilter, setIsAllUsersView } from './BOUserListViewActions';

class State {
    userTouchedBoRoleFilter = false;
    boUsersListLoadable = new LoadableData<PagedListContainerOf<BackOfficeUserDTO>, BaseSearch>();
    boAllUsersListLoadable = new LoadableData<PagedListContainerOf<BackOfficeUserDTO>, BaseSearch>();
    searchParams: BoUserListSearchParams;
    searchParamsAllUsers: BoUserListSearchParams;
    isAllUsersView: boolean;
}

export default new ReducerFactory(new State())
    .addReducer(
        setUserTouchedBoRoleFilter,
        (state, action): State => {
            return {
                ...state,
                userTouchedBoRoleFilter: action.payload,
            };
        },
    )
    .addReducer(
        getBoUserListLoadable.request,
        (state, action): State => {
            return {
                ...state,
                boUsersListLoadable: LoadableData.loading(action.payload),
                searchParams: action.payload,
            };
        },
    )
    .addReducer(
        getBoUserListLoadable.success,
        (state, action): State => {
            return {
                ...state,
                boUsersListLoadable: LoadableData.payload(action.payload.result),
                searchParams: action.payload.request,
            };
        },
    )
    .addReducer(
        getBoUserListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                boUsersListLoadable: LoadableData.error(action.payload.result),
            };
        },
    )
    .addReducer(
        getAllBoUserListLoadable.request,
        (state, action): State => {
            return {
                ...state,
                boAllUsersListLoadable: LoadableData.loading(action.payload),
                searchParamsAllUsers: action.payload,
            };
        },
    )
    .addReducer(
        getAllBoUserListLoadable.success,
        (state, action): State => {
            return {
                ...state,
                boAllUsersListLoadable: LoadableData.payload(action.payload.result),
                searchParamsAllUsers: action.payload.request,
            };
        },
    )
    .addReducer(
        getAllBoUserListLoadable.error,
        (state, action): State => {
            return {
                ...state,
                boAllUsersListLoadable: LoadableData.error(action.payload.result),
            };
        },
    )
    .addReducer(
        setIsAllUsersView,
        (state, action): State => {
            return {
                ...state,
                isAllUsersView: action.payload || false,
            };
        },
    )
    .toReducer();

export { State as BoUserManagementViewState };

export const selectBOUserListLoadable = (state: GlobalState) => state.boUserManagement.boUsersListLoadable;
export const selectBOAllUserListLoadable = (state: GlobalState) => state.boUserManagement.boAllUsersListLoadable;
export const selectBoUserListSearchParams = (state: GlobalState) => state.boUserManagement.searchParams;
export const selectBoAllUsersListSearchParams = (state: GlobalState) => state.boUserManagement.searchParamsAllUsers;
export const selectIsAllUsersView = (state: GlobalState) => state.boUserManagement.isAllUsersView;
