import { LOCATION_CHANGE } from 'connected-react-router';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { PaymentMethodDTO } from '../../../../services/types/ApiTypes';
import { LoadableData } from '../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../rootReducer';

import { savePaymentMethodActions, setActivePaymentMethod, showEditPaymentMethodModalAction } from './EditPaymentMethodModalActions';

class State {
    activePaymentMethod: PaymentMethodDTO | null;
    isEditPaymentMethodModalOpen = false;
    savePaymentMethodActionLoadable = new LoadableData<PaymentMethodDTO, PaymentMethodDTO>();
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        setActivePaymentMethod,
        (state, action): State => {
            return {
                ...state,
                activePaymentMethod: action.payload,
            };
        },
    )
    .addReducer(
        showEditPaymentMethodModalAction,
        (state, action): State => {
            return {
                ...state,
                isEditPaymentMethodModalOpen: action.payload,
                activePaymentMethod: !action.payload ? null : state.activePaymentMethod,
            };
        },
    )
    .addReducer(
        savePaymentMethodActions.request,
        (state): State => {
            return {
                ...state,
                savePaymentMethodActionLoadable: state.savePaymentMethodActionLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        savePaymentMethodActions.success,
        (state, action): State => {
            return {
                ...state,
                savePaymentMethodActionLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        savePaymentMethodActions.error,
        (state, action): State => {
            return {
                ...state,
                savePaymentMethodActionLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (): State => {
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'new-PaymentMethod-modal',
    whitelist: [],
};

export default persistReducer(persistConfig, reducer);

export { State as EditPaymentMethodModalState };

export const selectActivePaymentMethod = (state: GlobalState) => state.editPaymentMethodModal.activePaymentMethod;
export const selectIsEditPaymentMethodModalOpen = (state: GlobalState) => state.editPaymentMethodModal.isEditPaymentMethodModalOpen;
