(function() {
    'use strict';

    angular
        .module('dstreamApp.views.archive-details')
        .component('dsStaticInvoiceRows', {
            templateUrl: 'app/views/archive-details/components/static-invoice-rows/static-invoice-rows.html',
            controller: StaticInvoiceRows,
            bindings: {
                invoice: '<',
                invoiceId: '<'
            }
        });

    StaticInvoiceRows.$inject = ['$scope', '$timeout', 'localStorageService', 'webServices'];

    function StaticInvoiceRows($scope, $timeout, localStorageService, webServices) {
        var ctrl = this;
        var slideTime = 200;
        /* CONTROLLER VARIABLES */
        ctrl.visible = true;
        ctrl.loading = false;
        ctrl.invoiceRows = [];
        ctrl.expandedRows = 0;
        ctrl.detailRowPresent = false;
        ctrl.isAllOpen = false;
        ctrl.detailRowPresent = false;
        ctrl.paginator = {
            currentPage: 1,
            takeRows: localStorageService.get('staticInvoiceRowsPageSize') || 5,
            total: 0
        };

        /* CONTROLLER METHODS */
        ctrl.toggleComponent = toggleComponent;
        ctrl.expandRow = expandRow;
        ctrl.expandAllRows = expandAllRows;
        ctrl.rowHasDetails = rowHasDetails;
        ctrl.numbersAfterComma = numbersAfterComma;
        ctrl.changePageSize = changePageSize;

        ctrl.$onInit = function() {
            getInvoiceRowsData();
        };

        /* EVENTS & WATHCERS */
        var invoiceWatcher = $scope.$watch(function() {return ctrl.invoice}, function(newVal, oldVal) {
            if(newVal) {
                ctrl.paginator.total = newVal.InvoiceRowsCount;
            }
        });

        /*
            On page change load new invoiceRows data
         */
        var currentPageWatcher = $scope.$watch(function() {return ctrl.paginator.currentPage}, function(newVal, oldVal) {
            if(newVal !== oldVal) {
                getInvoiceRowsData(ctrl.invoiceId, newVal);
            }
        });

        /*
            Watch for the invoiceId change. If new invoiceData is loaded then the id changes and we should fetch new invoiceRows data
         */
        var invoiceIdWatcher = $scope.$watch(function() {return ctrl.invoiceId}, function(newVal, oldVal) {
            if(newVal !== oldVal) {
                getInvoiceRowsData(newVal, 1);
            }
        });

        /*
            Expand/Collapse element
         */
        function toggleComponent() {
            ctrl.visible = !ctrl.visible;
        }

        /*
            Expand/collapse a single row
         */
        function expandRow($event) {
            e.preventDefault();
            if (angular.element($event.currentTarget).parent().parent().hasClass('open')) {
                closeRow($event.currentTarget);
                ctrl.expandedRows--;
            } else {
                openRow($event.currentTarget);
                ctrl.expandedRows++;
            }
            checkIsAllOpen();
        }

        /*
            Expand/collapse all rows
         */
        function expandAllRows(e) {
            e.preventDefault();
            $timeout(function () {
                if (ctrl.expandedRows == angular.element('.table-invoice-rows .parent-row.expandable').length) {
                    angular.element('.table-invoice-rows .parent-row.expandable').removeClass('open');
                    angular.element('.table-invoice-rows .parent-row.expandable').next('.parent-row--collapsible').find('.parent-row__container').stop().slideUp(slideTime);
                    ctrl.expandedRows = 0;
                } else {
                    angular.element('.table-invoice-rows .parent-row.expandable').addClass('open');
                    angular.element('.table-invoice-rows .parent-row.expandable').next('.parent-row--collapsible').find('.parent-row__container').stop().slideDown(slideTime);
                    ctrl.expandedRows = angular.element('.table-invoice-rows .parent-row.expandable').length;
                }
                checkIsAllOpen();
            });
        }

        /*
            Check if row has any of the detail info. If so, then also set detailRowPresent, so the component knows,
            that we have at least one row with detail info
         */
        function rowHasDetails(row) {
            if (row.SerialNumber || row.SellerProductId || row.BuyerProductName || row.GroupId || row.TaricCode || row.CustomerRef || row.EAN || (row.ItemReserve && row.ItemReserve.length)) {
                ctrl.detailRowPresent = true;
                return true;
            }
            return false;
        }

        /*
            If value has a comma then display only two decimal points,
            if it doesn't then display none
         */
        function numbersAfterComma(value) {
            if(value) {
                var array = value.toString().split('.');
                if (array.length != 2)
                    return 2;
                var length = array[1].length;
                if (length > 2)
                    return length;
            }
            return 2;
        }

        /*
            Change how many invoice rows to display
         */
        function changePageSize(size) {
            ctrl.paginator.takeRows = size;
            ctrl.paginator.currentPage = 1;
            localStorageService.set('staticInvoiceRowsPageSize', ctrl.paginator.takeRows); // save rowsCount to localstorage
            getInvoiceRowsData(ctrl.invoiceId, 1); // get new invoiceRowsData
        }

        /* INTERNAL METHODS */
        /*
            Check if all the rows have been opened
         */
        function checkIsAllOpen() {
            var expandable = 0;
            ctrl.detailRowPresent = false;
            ctrl.isAllOpen = false;
            if(ctrl.invoiceRows.length) {
                for (var i = 0; i < ctrl.invoiceRows.length; i++) {
                    expandable++;
                }
            }

            ctrl.isAllOpen = (ctrl.expandedRows === expandable);
        }

        /*
            Close row
         */
        function closeRow(target) {
            angular.element(target).parent().parent().removeClass('open');
            angular.element(target).parent().parent().next('.parent-row--collapsible').find('.parent-row__container').stop().slideUp(slideTime);
        }

        /*
            Open row
         */
        function openRow(target) {
            angular.element(target).parent().parent().addClass('open');
            angular.element(target).parent().parent().next('.parent-row--collapsible').find('.parent-row__container').stop().slideDown(slideTime);
        }

        /*
            Get invoice rows data from API
         */
        function getInvoiceRowsData(id, page) {
            id = id || ctrl.invoiceId;
            page = page || ctrl.paginator.currentPage;
            ctrl.loading = true;

            return webServices.getArchiveInvoiceRowsWithPaging(id, page - 1, ctrl.paginator.takeRows).then(function(res) {
                ctrl.loading = false;

                ctrl.invoiceRows = res.data.map(function(row) {
                    // if no detailInfo is set, then set empty values
                    if(!row.ItemDetailInfo || row.ItemDetailInfo.length === 0) {
                        row.ItemDetailInfo = [{ ItemAmount: 0, ItemPrice: 0, ItemUnit: '' }];
                    }
                    return row;
                });

                checkIsAllOpen();
            }, function(err) {
                ctrl.loading = false;
            });
        }

        ctrl.$onDestroy = function () {
            if(invoiceWatcher) invoiceWatcher();
            if(currentPageWatcher) currentPageWatcher();
            if(invoiceIdWatcher) invoiceIdWatcher();
        };
    }
})();