import { MAX_SYMBOLS_IN_PASSWORD, MIN_SYMBOLS_IN_PASSWORD } from '../../../../common/constants/appConstants';

export const hasDigit = (password: string) => {
    return /\d/.test(password);
};

export const hasUpperCase = (password: string) => {
    return password.toLowerCase() !== password;
};

export const hasLowerCase = (password: string) => {
    return password.toUpperCase() !== password;
};

export const hasEnoughSymbols = (password: string) => {
    return password.length >= MIN_SYMBOLS_IN_PASSWORD;
};

export const maxLengthCheck = (password: string) => {
    return password.length > 0 && password.length <= MAX_SYMBOLS_IN_PASSWORD;
};

export const passwordMatch = (password: string, confirmPassword: string) => {
    return password.length > 0 && password === confirmPassword;
};
