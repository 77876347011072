import { cloneDeep, isEmpty, isNumber } from 'lodash-es';

import i18n from '../../../../../../i18n';
import api from '../../../../../../services/ApiServices';
import { GlobalState } from '../../../../../../rootReducer';
import { DispatchThunk } from '../../../../../../storeConfig';
import { getCurrentUserGroupMember, updateUserSettings } from '../../../../../../common/user/UserActions';
import { getViewUserSearchParams, getViewUserSettings, ListViewUserConfig, setUserSettingValue } from '../../../../../../common/user/userSettingUtil';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../../../../common/utils/baseSearchHelpers';
import { loadableDataActions, loadableDataActionsWithRequest } from '../../../../../../common/utils/LoadableData';
import { notify } from '../../../../../../common/utils/notify';
import { updateAppUserSettingsAction } from '../../../../../../common/middlewares/userSettings';
import { TableFilter } from '../../../../../../components/Table/components/filter/TableFilters';
import {
    BaseSearch,
    PagedListContainer,
    InvoicePurchaseOrdersSearch,
    InvoiceHeaderPurchaseOrdersDTO,
    LinkedCountDTO,
    PurchaseOrderInvoicesFilter,
    Restriction,
    SearchType,
    SortDirection,
    UserSettingName,
} from '../../../../../../services/types/ApiTypes';
import { DEFAULT_RESTRICTION, DEFAULT_SORT_COLUMN } from './PurchaseOrdersListHelper';
import { InvoicesSearchParams, selectInvoicePurchaseOrdersFilterType, selectInvoicePurchaseOrdersSearchParams } from './PurchaseOrdersListReducers';

const ns = 'invoicePurchaseOrders/';

const invoicesListViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.INVOICES_PURCHASE_ORDERS_SORT_DIRECTION,
    sortCol: UserSettingName.INVOICES_PURCHASE_ORDERS_SORT_COLUMN,
    pageSize: UserSettingName.INVOICES_PURCHASE_ORDERS_PAGE_SIZE,
};

export const getInvoicePurchaseOrdersLoadable = loadableDataActionsWithRequest<InvoicePurchaseOrdersSearch, PagedListContainer<InvoiceHeaderPurchaseOrdersDTO>>(`${ns}ALL_INVOICES`);

export const getInvoicesList = (searchParams: InvoicesSearchParams, poId: number, type?: PurchaseOrderInvoicesFilter, openFirstPage?: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const invoicesSearchParams = searchParams || selectInvoicePurchaseOrdersSearchParams(getState());
        const groupMember = getState().user.groupMemberCommonLoadable.payload;

        const viewSearchParams = getViewUserSearchParams(invoicesSearchParams, invoicesListViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, 'DateCreated', SortDirection.Asc);

        if (type || openFirstPage) {
            invoicesSearchParams.PagingOptions.Page = 1;
        }

        const apiSearchParams: InvoicePurchaseOrdersSearch = {
            SearchParams: invoicesSearchParams,
            InvoiceId: poId,
            Filter: isNumber(type) ? type : selectInvoicePurchaseOrdersFilterType(getState()),
        };

        const searchParamsWithoutFilter = cloneDeep(apiSearchParams);

        delete searchParamsWithoutFilter.SearchParams.filters;

        let response;
        try {
            dispatch(getInvoicePurchaseOrdersLoadable.request(apiSearchParams));
            response = await api.purchaseOrder.getPurchaseOrdersForInvoice(searchParamsWithoutFilter);
            if (response?.status === 200) {
                dispatch(
                    getInvoicePurchaseOrdersLoadable.success({
                        request: apiSearchParams,
                        result: response.data,
                    }),
                );
            } else {
                notify.error(i18n.t('interceptorsFactory.ErrorWhileProcessingData'), i18n.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            dispatch(
                getInvoicePurchaseOrdersLoadable.error({
                    request: apiSearchParams,
                    result: e,
                }),
            );
        }
    };
};

export const getPurchaseOrderInvoicesLinkedCount = loadableDataActions<number, LinkedCountDTO>(`${ns}INVOICES_LINKED_COUNT`);

export function getLinkedPurchaseOrdersCount(id: number, listRestricted?: boolean, onMount?: boolean) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        let response;
        try {
            dispatch(getPurchaseOrderInvoicesLinkedCount.request(id));
            if (id) {
                response = await api.purchaseOrder.getLinkedPurchaseOrdersCount(id);
            }
            if (response.data) {
                dispatch(getPurchaseOrderInvoicesLinkedCount.success(response.data));
                if (!listRestricted || response?.data?.LinkedObjectsCount > 0) {
                    const filterType = selectInvoicePurchaseOrdersFilterType(getState());
                    const quickFilterType = isNumber(filterType) && !onMount ? filterType : response.data.LinkedObjectsCount > 0 ? PurchaseOrderInvoicesFilter.Linked : PurchaseOrderInvoicesFilter.Any;

                    dispatch(getInvoicesList(undefined, id, quickFilterType, onMount));
                }
            } else {
                notify.info(i18n.t('view.PurchaseOrders.Error.General'));
            }
        } catch (e) {
            console.error(e);
            if (e.response?.data?.ErrorCode) {
                notify.error(i18n.t(e.response.data.ErrorCode));
            }
            dispatch(getPurchaseOrderInvoicesLinkedCount.error(e));
        }
    };
}

export const setPoInvoicesPagingOptions = (poId: number, page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            invoicePurchaseOrders,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const paging = invoicePurchaseOrders.searchParams.PagingOptions;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(invoicesListViewConfig, groupMember);
            if (!viewConfig.pageSize || parseInt(viewConfig.pageSize, 10) !== pageSize) {
                groupMember.UserSettings = setUserSettingValue(invoicesListViewConfig.pageSize, pageSize, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: InvoicesSearchParams = {
            ...invoicePurchaseOrders.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        dispatch(getInvoicesList(searchParams, poId));
    };
};

export const sortPoInvoicesList = (columnName: string, poId: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            invoicePurchaseOrders,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const sorting = invoicePurchaseOrders.searchParams.SortItems[0];
        const sortingDirection = sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(invoicesListViewConfig, groupMember);

            if (!viewConfig.sortCol || viewConfig.sortCol !== columnName) {
                groupMember.UserSettings = setUserSettingValue(invoicesListViewConfig.sortCol, columnName, groupMember.UserSettings);
            }
            if (!viewConfig.sortDir || parseInt(viewConfig.sortDir, 10) !== sortingDirection) {
                groupMember.UserSettings = setUserSettingValue(invoicesListViewConfig.sortDir, sortingDirection, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: BaseSearch = {
            ...invoicePurchaseOrders.searchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };
        dispatch(getInvoicesList(searchParams, poId));
    };
};

export const searchPoInvoicesList = (searchString: string, poId: number, resetFilters?: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.invoicePurchaseOrders.searchParams.PagingOptions;
        let searchRestriction = state.invoicePurchaseOrders.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        const otherRestrictions = state.invoicePurchaseOrders.searchParams.Restrictions.filter((r) => r.Field !== DEFAULT_RESTRICTION);
        let restrictions = [];

        if (!isEmpty(searchString)) {
            if (!searchRestriction) {
                searchRestriction = {
                    Field: DEFAULT_RESTRICTION,
                    Value: searchString,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                };
            } else {
                searchRestriction.Value = searchString;
            }
            restrictions.push(searchRestriction);
        }

        if (!isEmpty(otherRestrictions) && !resetFilters) {
            restrictions = [...restrictions, ...otherRestrictions];
        }
        const searchParams: InvoicesSearchParams = {
            ...state.invoicePurchaseOrders.searchParams,
            SortItems: resetFilters
                ? [
                      {
                          SortColumn: DEFAULT_SORT_COLUMN,
                          SortDirection: SortDirection.Asc,
                      },
                  ]
                : state.invoicePurchaseOrders.searchParams.SortItems,
            PagingOptions: {
                ...paging,
                Page: 1,
            },
            Restrictions: [...restrictions],
        };

        if (resetFilters) {
            await dispatch(
                updateAppUserSettingsAction({
                    listViewConfig: invoicesListViewConfig,
                    searchParams,
                }),
            );
        }
        dispatch(getInvoicesList(searchParams, poId, resetFilters ? 0 : null));
    };
};

export const filterInvoicePurchaseOrders = (id: number, restriction?: Restriction, filter?: TableFilter<any>) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.invoicePurchaseOrders.searchParams.PagingOptions;
        // keep the old restrictions when we filter
        let restrictions = state.invoicePurchaseOrders.searchParams.Restrictions.filter((r) => {
            if (r.Field === restriction.Field) {
                return !!restriction.Values || !!restriction.Value;
            }
            return !!r.Value || !!r.Values;
        }).map((r) => {
            if (r.Field === restriction?.Field) {
                return restriction;
            }
            return r;
        });
        if (
            !!restriction &&
            !restrictions.includes(restriction) &&
            ((typeof restriction.Value === 'number' ? restriction.Value !== 0 : !isEmpty(restriction.Value)) || !isEmpty(restriction.Values)) // don't include empty restrictions
        ) {
            restrictions.push(restriction);
        }
        if (filter?.childColumnName) {
            restrictions = restrictions.filter((p) => p.Field !== filter.childColumnName);
            let children = Array<any>();
            filter.values.forEach((value) => {
                if (value.children) {
                    children = [...children, ...value.children];
                }
            });
            if (children.length) {
                restrictions.push({
                    Field: filter.childColumnName,
                    Value: null,
                    Values: children.map((val) => val.value),
                    FieldSearchType: SearchType.NotSelected,
                });
            }
        }
        const searchParams: InvoicesSearchParams = {
            ...state.invoicePurchaseOrders.searchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: restrictions,
            filters: {
                ...state.invoicePurchaseOrders.searchParams.filters,
                [restriction?.Field]: filter,
            },
        };
        dispatch(getInvoicesList(searchParams, id));
    };
};

export const linkPurchaseOrderToInvoice = (invoiceId: number, purchaseOrderId: number) => {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            response = await api.purchaseOrder.linkPurchaseOrderToInvoice(invoiceId, purchaseOrderId);
            if (response.status === 200) {
                notify.success(i18n.t('views.invoiceHeader.purchaseOrderSuccessfullyLinked'));
                dispatch(getLinkedPurchaseOrdersCount(invoiceId));
            } else {
                console.error(response.statusText);
                notify.error(i18n.t('views.invoiceHeader.errorLinkingPurchaseOrder'));
            }
        } catch (e) {
            console.error(e);
            notify.error(i18n.t('views.invoiceHeader.errorLinkingPurchaseOrder'));
        }
    };
};

export const unlinkPurchaseOrderFromInvoice = (invoiceId: number, purchaseOrderId: number) => {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            response = await api.purchaseOrder.unlinkPurchaseOrderFromInvoice(invoiceId, purchaseOrderId);
            if (response.status === 200) {
                notify.success(i18n.t('views.invoiceHeader.purchaseOrderSuccessfullyUnlinked'));
                dispatch(getLinkedPurchaseOrdersCount(invoiceId));
            } else {
                console.error(response.statusText);
                notify.error(i18n.t('views.invoiceHeader.errorUnlinkingPurchaseOrder'));
            }
        } catch (e) {
            console.error(e);
            notify.error(i18n.t('views.invoiceHeader.errorUnlinkingPurchaseOrder'));
        }
    };
};
