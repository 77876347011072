import { createAction } from 'redux-actions';

import { BackOfficeUserDTO } from '../../../../../services/types/BoApiTypes';
import { loadableDataActions } from '../../../../../common/utils/LoadableData';
import { DispatchThunk } from '../../../../../storeConfig';
import api from '../../../../../services/ApiServices';
import { notify } from '../../../../../common/utils/notify';
import i18nInstance from '../../../../../i18n';

const ns = 'new-BoUser-modal/';

export const setActiveBoUser = createAction<BackOfficeUserDTO>(`${ns}SET_ACTIVE_COMPANY`);
export const addBoUserActions = loadableDataActions<BackOfficeUserDTO, BackOfficeUserDTO>(`${ns}ADD_BoUser`);

export const addOrUpdateBoUser = (boUser: BackOfficeUserDTO, isAllUsersView?: boolean) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(addBoUserActions.request(boUser));
            const response = await api.boUsers.saveUser(boUser);
            dispatch(addBoUserActions.success(response.data));
            // import the actions lazily, otherwise it seems we get circular dependencies and page crashes, probably the solution would be to move GlobalState out of the rootReducer file
            import('../../BOUserListViewActions').then((actions) => {
                dispatch(actions.setPagingOptions(1, undefined, isAllUsersView));
            });
        } catch (e) {
            console.error(e);
            dispatch(addBoUserActions.error(e));
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const showEditBoUserModal = createAction<boolean>(`${ns}SHOW_NEW_BO_USER_MODAL`);

export function showAddOrEditBoUserModal(boUser?: BackOfficeUserDTO) {
    return async (dispatch: DispatchThunk) => {
        try {
            // load the boUser data if Id is provided. If it is not provided, then we are creating from scratch
            if (boUser) {
                dispatch(setActiveBoUser(boUser));
            } else {
                dispatch(setActiveBoUser(undefined));
            }
            dispatch(showEditBoUserModal(true));
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileSendingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
}
