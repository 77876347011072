angular.module("dstreamApp.directives").directive('ngEnter', function () {
  return {
    restrict: 'A',
    scope: {
      handleEnter: '&'
    },
    link: function (scope, element, attrs) {
      element.bind('keydown', function (event) {
        if (event.which === 13) {
          scope.handleEnter({event: event})
          element[0].blur()
          event.preventDefault()
        }
      })
    }
  }
})
