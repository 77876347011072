import classNames from 'classnames';
import { ReactNode } from 'react';
import * as React from 'react';

import './Table.scss';
export interface TableHeaderProps {
    label?: ReactNode;
}
const TableHeader: React.StatelessComponent<TableHeaderProps> = ({ label }: TableHeaderProps) => {
    return <span className="table__header">{label}</span>;
};

export interface DataTableHeaderProps {
    className?: string;
    children: ReactNode;
    'data-id'?: string;
}

export const DataTableHeader = (props: DataTableHeaderProps) => {
    const { children, className, ...rest } = props;
    const classes = classNames('data-table__header', className);
    return (
        <thead className={classes} {...rest}>
            {children}
        </thead>
    );
};

export default TableHeader;
