import React from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import { TFunction } from 'i18next';
import { Dispatchable1 } from 'redux-dispatchers';
import { notify } from '../../../../../common/utils/notify';

import { Button, ButtonIconPlacement, ButtonType } from '../../../../../components/Buttons/Button';
import { InvoiceStatus, UserRole } from '../../../../../common/constants/appConstants';
import i18nInstance from '../../../../../i18n';
import { InvoiceDTO } from '../../../../../services/types/ApiTypes';
import { User } from '../../../../../services/ApiClient';
import { ConfirmationFlowActions } from '../../../../../common/constants/invoiceConstants';
import { eventTrack } from '../../../../../common/analytics/gtm';
import { TextInput, TextInputType } from '../../../../../components/TextInput/TextInput';
import { TypeaheadAsync, TypeaheadItem } from '../../../../../components/Typeahead/TypeaheadAsync';
import { DropdownMenu, DropdownMenuItem } from '../../../../../components/DropdownMenu/DropdownMenu';
import { ICONS, IconSize } from '../../../../../components/Icon/Icon';
import { checkUserOnlyRoleExpenseCreator, isAuthorized, Role, userHasOnlyProvidedConfirmationsRoles } from '../../../../../common/user/userPermissionUtil';
import { createDataId } from '../../../../../common/utils/dataId';
import { WithDataId } from 'src/common/utils/dataId';

import {
    createInvoiceAction,
    getApproveAllConfirmersByNamePart,
    getInvoiceActionsForAdmin,
    getInvoiceActionsForApprover,
    getInvoiceActionsForApproverAssigner,
    getInvoiceActionsForAssigner,
    IActionLabel,
    validateForConfirmationInvoice,
    validateInvoiceConfirm,
} from '../InvoiceConfirmationsHelper';

interface ActionBlockProps extends WithDataId {
    canUserSkipConfirmation: boolean;
    invoice: InvoiceDTO;
    t: TFunction;
    editVisible?: boolean;
    userData: User;
    isUserInsideCurrentStep?: boolean;
    isUserInsideCompletedStep?: boolean;
    isUserInsideUpcomingStep?: boolean;
    setLoading: Dispatchable1<boolean>;
    isTransactionRowsCheckEnabled: boolean;

    // TODO: remove this when rewrite whole invoice detail page
    $rootScope: any;
    approveInvoice: any;
}

export const ActionBlock = ({
    canUserSkipConfirmation,
    dataId,
    invoice,
    t,
    editVisible,
    userData,
    approveInvoice,
    $rootScope,
    isUserInsideCurrentStep,
    isUserInsideCompletedStep,
    isUserInsideUpcomingStep,
    setLoading,
}: ActionBlockProps): React.ReactElement => {
    const [comment, changeComment] = React.useState('');
    const [nextConfirmer, updateNextConfirmer] = React.useState<TypeaheadItem<any>>(undefined);

    const [errors, setErrors] = React.useState({
        comment: undefined,
        approver: undefined,
    });

    const maxCommentLength = 500;

    const isOnlyExpenseCreatorRole = checkUserOnlyRoleExpenseCreator();

    const resetNextconfirmer = () => {
        updateNextConfirmer({
            value: undefined,
            text: undefined,
        });
    };

    React.useEffect(() => {
        changeComment('');
        resetNextconfirmer();
    }, [invoice]);

    if (!userData || !userData.MemberRoles || isOnlyExpenseCreatorRole) {
        return null;
    }
    if (invoice.Status === InvoiceStatus.PendingExport) {
        return t('component.mainActions.approvedMessage');
    }
    if (invoice.Status === InvoiceStatus.Exported) {
        return t('component.mainActions.exportedMessage');
    }
    if (invoice.Status === InvoiceStatus.Deleted) {
        return t('component.mainActions.deletedMessage');
    }
    if (!invoice.canConfirmInvoiceProp && invoice.CanEditInvoice) {
        return t('component.mainActions.notNextConfirmer');
    }

    if (invoice.Status === InvoiceStatus.InApproval) {
        const isOnlyAssigner = userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner]);
        const isNotAssigner = userHasOnlyProvidedConfirmationsRoles([UserRole.Processor]) || userHasOnlyProvidedConfirmationsRoles([UserRole.Processor, UserRole.CompletingWorkflow]);
        if (isNotAssigner && !isUserInsideCurrentStep) {
            return t('component.mainActions.notNextConfirmer');
        }
        if (isOnlyAssigner && !isUserInsideCompletedStep && isUserInsideCurrentStep && !invoice.isLastTask) {
            return null;
        }
    }

    if (invoice.Status === InvoiceStatus.New) {
        if (userHasOnlyProvidedConfirmationsRoles([UserRole.Processor]) || userHasOnlyProvidedConfirmationsRoles([UserRole.Processor, UserRole.CompletingWorkflow])) {
            return t('component.mainActions.notAssigner');
        }
    }

    /**
     * Check if invoice has transaction rows present and show a notification if it doesn't
     */
    const checkForTransactionsRows = () => {
        if (invoice.InvoiceAccountingRowsTotal === 0) {
            notify.error(i18nInstance.t('component.ConfirmationFlow.message.NoTransactionRows'), null, {
                toastOptions: {
                    autoClose: 5000,
                },
            });
            return false;
        }

        return true;
    };

    const onConfirm = async () => {
        try {
            const isValid = await validateForConfirmationInvoice(invoice, $rootScope, t);
            const canConfirm = validateInvoiceConfirm(invoice, ConfirmationFlowActions.Confirm);
            if (isValid && canConfirm) {
                $rootScope.$emit('invoiceLoading');
                approveInvoice(createInvoiceAction(ConfirmationFlowActions.Confirm, invoice.Id, comment, nextConfirmer?.value));
                eventTrack({
                    event: 'confirmations',
                    label: ConfirmationFlowActions.Confirm,
                });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const getError = (action: ConfirmationFlowActions) => {
        if (action === ConfirmationFlowActions.Skip) {
            return t('component.confirmationFlow.action.skip.commentMissing');
        }
        if (action === ConfirmationFlowActions.Reject) {
            return t('component.confirmationFlow.action.reject.commentMissing');
        }
        if (action === ConfirmationFlowActions.ConfirmAndAssign) {
            return t('component.confirmationFlow.action.ConfirmAndAssign.approveMissing');
        }
        if (action === ConfirmationFlowActions.Assign) {
            return t('component.confirmationFlow.action.Assign.approveMissing');
        }
        return '';
    };

    const handleActions = (action: ConfirmationFlowActions) => {
        switch (action) {
            case ConfirmationFlowActions.Confirm:
                // when no transaction rows are present then confirming will fail and we should show a notification to the user
                if (!checkForTransactionsRows()) {
                    return;
                }

                setLoading(true);
                onConfirm();
                break;
            case ConfirmationFlowActions.ConfirmAndAssign:
                // when no transaction rows are present then confirm and assign should fail
                if (!checkForTransactionsRows()) {
                    return;
                }

                // Here "action" needs to be substituted with ConfirmationFlowActions.Confirm due to faulty logic in switch structure.
                if (!nextConfirmer.value) {
                    return setErrors({
                        ...errors,
                        approver: getError(ConfirmationFlowActions.ConfirmAndAssign),
                    });
                }
                if (validateInvoiceConfirm()) {
                    setLoading(true);
                    approveInvoice(createInvoiceAction(ConfirmationFlowActions.Confirm, invoice.Id, comment, nextConfirmer?.value));
                    eventTrack({
                        event: 'confirmations',
                        label: ConfirmationFlowActions.Confirm,
                    });
                }
                break;
            case ConfirmationFlowActions.Assign:
                // when no transaction rows are present then assigning should fail
                if (!checkForTransactionsRows()) {
                    return;
                }

                if (!nextConfirmer.value) {
                    return setErrors({
                        ...errors,
                        approver: getError(ConfirmationFlowActions.Assign),
                    });
                }
                let stepNumber: number;
                if (validateInvoiceConfirm(invoice, action, nextConfirmer?.value)) {
                    setLoading(true);
                    approveInvoice(createInvoiceAction(action, invoice.Id, comment, nextConfirmer?.value), stepNumber);
                    eventTrack({
                        event: 'confirmations',
                        label: 'Assign',
                    });
                }
                break;
            case ConfirmationFlowActions.Skip:
                if (!comment) {
                    return setErrors({
                        ...errors,
                        comment: getError(ConfirmationFlowActions.Skip),
                    });
                }
                setLoading(true);
                return approveInvoice(createInvoiceAction(action, invoice.Id, comment, nextConfirmer?.value));
            case ConfirmationFlowActions.Reject:
                if (!comment) {
                    return setErrors({
                        ...errors,
                        comment: getError(ConfirmationFlowActions.Reject),
                    });
                }
                if (validateInvoiceConfirm()) {
                    setLoading(true);
                    approveInvoice(createInvoiceAction(action, invoice.Id, comment, nextConfirmer?.value));
                }
                break;
            default:
                break;
        }
        resetNextconfirmer();
    };
    let mainAction: IActionLabel;
    let moreActions: IActionLabel[];
    if (isAuthorized(Role.CanApproveAndViewAnyInvoice)) {
        const actions = getInvoiceActionsForAdmin(invoice, userData, isUserInsideCurrentStep, isUserInsideCompletedStep);
        mainAction = actions.mainAction;
        moreActions = actions.moreActions;
    } else if (
        userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner, UserRole.Processor]) ||
        userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner, UserRole.Processor, UserRole.CompletingWorkflow])
    ) {
        const actions = getInvoiceActionsForApproverAssigner(invoice, userData, isUserInsideCurrentStep, isUserInsideCompletedStep, isUserInsideUpcomingStep);
        mainAction = actions.mainAction;
        moreActions = actions.moreActions;
    } else if (userHasOnlyProvidedConfirmationsRoles([UserRole.Processor]) || userHasOnlyProvidedConfirmationsRoles([UserRole.Processor, UserRole.CompletingWorkflow])) {
        const actions = getInvoiceActionsForApprover(invoice, userData, isUserInsideCurrentStep);
        mainAction = actions.mainAction;
        moreActions = actions.moreActions;
    } else if (userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner])) {
        const actions = getInvoiceActionsForAssigner(invoice, userData, isUserInsideCurrentStep);
        mainAction = actions.mainAction;
        moreActions = actions.moreActions;
    }

    if (!canUserSkipConfirmation) {
        moreActions = moreActions.filter((a) => a.type !== ConfirmationFlowActions.Skip);
    }
    const classes = classNames('confirmation__wrapper-actions', {
        'confirmation__only-button': isEqual(userData.MemberRoles, [UserRole.Processor]) && !isUserInsideCurrentStep,
    });

    const updateComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        if (value.length >= maxCommentLength) {
            value = value.substring(0, maxCommentLength);
            notify.info(t('General.Notification.Message.DataTrimmed'));
        }
        changeComment(value);
    };

    const trimSpacesOnBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const trimmed = value.trim();
        if (value !== trimmed) {
            changeComment(trimmed);
            notify.info(t('General.Notification.Message.DataTrimmed'));
        }
    };

    return (
        <>
            <TextInput
                dataId={createDataId(dataId, 'comment')}
                type={TextInputType.BORDERED}
                placeholder={t('component.transactionRows.addComment')}
                value={comment}
                textarea={true}
                onFocus={() => setErrors({ ...errors, comment: undefined })}
                onChange={updateComment}
                error={errors.comment}
                maxLength={maxCommentLength}
                addonAfter={<span className="action-block__length-comment">{`${comment?.length || 0}/${maxCommentLength}`}</span>}
                onBlur={trimSpacesOnBlur}
            />
            <TypeaheadAsync
                wrapperClass="confirmation__choose-confirmation"
                dataId={createDataId(dataId, 'choose-confirmation')}
                loadItems={(name?: string) => {
                    return getApproveAllConfirmersByNamePart(name, undefined, false, invoice.Status, editVisible);
                }}
                error={errors.approver}
                inputProps={{
                    autofocus: false,
                    showClear: false,
                    type: TextInputType.BORDERED,
                    onFocus: () => setErrors({ ...errors, approver: undefined }),
                }}
                onBlur={() => setErrors({ ...errors, approver: undefined })}
                placeholder={
                    [InvoiceStatus.New, InvoiceStatus.Rejected].includes(invoice.Status)
                        ? t('component.confirmationFlow.chooseConfirmation.placeholder')
                        : t('component.confirmationFlow.chooseConfirmationOrUser.placeholder')
                }
                searchOnFocus={true}
                toggleVisible={true}
                value={nextConfirmer}
                onChange={(item) => {
                    item ? updateNextConfirmer(item) : resetNextconfirmer();
                }}
            />
            <div className={classes}>
                <Button
                    dataId={createDataId(dataId, 'confirm')}
                    buttonType={ButtonType.PRIMARY}
                    className={`confirmation__button-assign ${!moreActions?.length && 'confirmation__button-full'}`}
                    onClick={() => handleActions(mainAction?.type)}
                >
                    {t(mainAction?.label)}
                </Button>
                {!!moreActions?.length && (!isEqual(userData.MemberRoles, [UserRole.Processor]) || isUserInsideCurrentStep) && (
                    <DropdownMenu
                        dataId={createDataId(dataId, 'assign-dropdown')}
                        hasArrow={true}
                        items={
                            <>
                                {moreActions.map((action: { label: string; type: ConfirmationFlowActions; icon: string }) => (
                                    <DropdownMenuItem key={action.label} dataId={createDataId(dataId, 'more-actions', action.type)} icon={action.icon} onClick={() => handleActions(action.type)}>
                                        {t(action.label)}
                                    </DropdownMenuItem>
                                ))}
                            </>
                        }
                    >
                        <Button
                            dataId={createDataId(dataId, 'more-actions')}
                            buttonType={ButtonType.ICON}
                            className="confirmation__button-more-actions button--secondary"
                            iconPlacement={ButtonIconPlacement.RIGHT}
                            icon={ICONS.ARROW_DOWN}
                            iconSize={IconSize.XS}
                        >
                            {t('component.confirmationFlow.action.more')}
                        </Button>
                    </DropdownMenu>
                )}
            </div>
        </>
    );
};
