import { connect } from 'react-redux';

import { GlobalState } from '../../../../../../rootReducer';
import {
    filterInvoicePurchaseOrders,
    getInvoicesList,
    getLinkedPurchaseOrdersCount,
    linkPurchaseOrderToInvoice,
    searchPoInvoicesList,
    setPoInvoicesPagingOptions,
    sortPoInvoicesList,
    unlinkPurchaseOrderFromInvoice,
} from './PurchaseOrdersListActions';
import withSuspense from '../../../../../../common/hocs/withSuspense';
import PurchaseOrdersList, { DispatchProps, Props } from './PurchaseOrdersList';
import {
    selectInvoiceLinkedPurchaseOrdersCount,
    selectInvoicePurchaseOrdersLoadable,
    selectInvoicePurchaseOrdersSearchParams,
    selectInvoicePurchaseOrdersFilterType,
} from './PurchaseOrdersListReducers';

const mapStateToProps = (state: GlobalState): Pick<Props, 'linkedPurchaseOrdersCount' | 'invoicePurchaseOrdersLoadable' | 'searchParams' | 'filterType'> => ({
    linkedPurchaseOrdersCount: selectInvoiceLinkedPurchaseOrdersCount(state),
    invoicePurchaseOrdersLoadable: selectInvoicePurchaseOrdersLoadable(state),
    searchParams: selectInvoicePurchaseOrdersSearchParams(state),
    filterType: selectInvoicePurchaseOrdersFilterType(state),
});

const mapDispatchToProps: DispatchProps = {
    getInvoicesList,
    getLinkedPurchaseOrdersCount,
    searchPoInvoicesList,
    setPoInvoicesPagingOptions,
    sortPoInvoicesList,
    filterInvoicePurchaseOrders,
    linkPurchaseOrderToInvoice,
    unlinkPurchaseOrderFromInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(PurchaseOrdersList));
