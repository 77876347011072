import { TFunction } from 'i18next';
import { isEmpty } from 'lodash-es';

import { mapUserRoles, UserRole } from '../../../common/constants/appConstants';
import { BaseSearch, Restriction, SearchType, SortDirection } from '../../../services/types/ApiTypes';
import { TableFilter } from '../../../components/Table/components/filter/TableFilters';
import { getSupportedCountriesOfWorld } from '../../login/loginHelper';
import { TagSelectItem } from '../../../components/TagSelect/TagSelect';
import { BackOfficeCompanyStatus, BackOfficeOrganizationDTO, BackOfficeResellerDTO, BackOfficeUserDTO } from '../../../services/types/BoApiTypes';

import { BoCompanyListSearchParams } from './BOCompanyListViewActions';
import api from '../../../services/ApiServices';
import { StatusType } from '../../../components/Status/Status';
import { TypeaheadItem } from '../../../components/Typeahead/TypeaheadAsync';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

export const BACK_OFFICE_STATUSES = {
    [BackOfficeCompanyStatus.UNDEFINED]: 'view.backOffice.companies.status.undefined',
    [BackOfficeCompanyStatus.DEMO]: 'view.backOffice.companies.status.demo',
    [BackOfficeCompanyStatus.TEST]: 'view.backOffice.companies.status.test',
    [BackOfficeCompanyStatus.ACTIVE]: 'view.backOffice.companies.status.active',
    [BackOfficeCompanyStatus.DEACTIVATED]: 'view.backOffice.companies.status.deactivated',
    [BackOfficeCompanyStatus.ARCHIVED]: 'view.backOffice.companies.status.archived',
};

export const BACK_OFFICE_STATUSES_LIST = [
    BackOfficeCompanyStatus.UNDEFINED,
    BackOfficeCompanyStatus.ACTIVE,
    BackOfficeCompanyStatus.ARCHIVED,
    BackOfficeCompanyStatus.DEMO,
    BackOfficeCompanyStatus.DEACTIVATED,
    BackOfficeCompanyStatus.TEST,
];

export enum CompanyFilterableColumns {
    COUNTRY = 'Country',
    STATUS = 'Status',
    MANAGERS = 'Managers',
    RESELLER = 'Reseller',
    ORGANIZATION = 'Organization',
}

export const createRequest = (searchValue = '', page = 1, count = 15, sortColumn = 'CompanyName') => ({
    PagingOptions: {
        Count: count,
        Page: page,
    },
    Restrictions:
        searchValue && searchValue.length > 0
            ? [
                  {
                      Field: 'FullName',
                      FieldSearchType: SearchType.NotSelected,
                      Value: searchValue || null,
                      Values: null,
                  } as Restriction,
              ]
            : [],
    SortItems: [
        {
            SortColumn: sortColumn,
            SortDirection: SortDirection.Asc,
        },
    ],
    filters: {},
});

// Parse applied Company filter restrictions to TagSelectItems understood by Table components
export const getSelectedFilterRestrictionValuesFromItems = (columnName: string, value: any, values: any[], items: TagSelectItem<any>[]): TagSelectItem<any>[] => {
    const selected: TagSelectItem<any>[] = [];

    if (columnName === CompanyFilterableColumns.MANAGERS) {
        // we are dealing with Manager(User) DTOs here, need to get UI filter selected values
        if (value) {
            selected.push({ text: `${value.FirstName} ${value.LastName}`, value });
        } else if (values?.length) {
            values.map((v) => {
                selected.push({ text: `${v.FirstName} ${v.LastName}`, value: v });
            });
        }
    } else if (columnName === CompanyFilterableColumns.RESELLER || columnName === CompanyFilterableColumns.ORGANIZATION) {
        // we are dealing with Reseller or Organization DTOs here, need to get UI filter selected values
        if (value) {
            selected.push({ text: value[`${columnName}Name`], value });
        } else if (values?.length) {
            values.map((v) => {
                selected.push({ text: v[`${columnName}Name`], value: v });
            });
        }
    } else if (value && items) {
        return items.filter((i: TagSelectItem<any>) => {
            return i.value === value;
        });
    } else if (values?.length && items) {
        for (const v of values) {
            items.map((i: TagSelectItem<any>) => {
                if (i.value === v) {
                    selected.push(i);
                }
            });
        }
    }
    return selected;
};

export const getBoCompanyCountryLabel = (country: string) => {
    return getSupportedCountriesOfWorld().find((c) => c.value === country)?.text;
};

export const getCountryList = (): Array<TagSelectItem<any>> => {
    return getSupportedCountriesOfWorld().map((country) => {
        return {
            text: country.text,
            value: country.value,
        };
    });
};

export const getBoCompanyStatusLabel = (status: BackOfficeCompanyStatus) => {
    return BACK_OFFICE_STATUSES[status];
};

export const getBoCompanyStatusList = (t: TFunction): Array<TagSelectItem<any>> => {
    return BACK_OFFICE_STATUSES_LIST.map((key) => ({
        text: t(BACK_OFFICE_STATUSES[key]),
        value: key,
    }));
};

export const getUserLabelText = (user: BackOfficeUserDTO): string => {
    return `${user.FirstName} ${user.LastName}`;
};

export const getBoManagersList = async (searchString: string): Promise<Array<BackOfficeUserDTO>> => {
    const searchParams: BaseSearch = {
        SortItems: [
            {
                SortColumn: 'UserName',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 25,
        },
        Restrictions: [
            {
                Field: 'GeneralSearch',
                Value: searchString || '',
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
    };

    // as agreed in https://fitekgroup.atlassian.net/browse/EMR-4703, we are using all BO users as potential managers (not just BOManagerDTO[])
    const usersResponse = await api.boUsers.getUsers(searchParams);

    return Promise.resolve(usersResponse.data.Items);
};

export const getBoResellersList = async (searchString: string): Promise<Array<BackOfficeResellerDTO>> => {
    const searchParams: BaseSearch = {
        SortItems: [
            {
                SortColumn: 'ResellerName',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 25,
        },
        Restrictions: [
            {
                Field: 'ResellerName',
                Value: searchString || '',
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
    };
    const resellersResponse = await api.boResellers.getResellers(searchParams);
    return Promise.resolve(resellersResponse.data.Items);
};

export const getBoResellersTagSelectItems = async (searchString: string): Promise<Array<TagSelectItem<BackOfficeResellerDTO>>> => {
    const organizations = await getBoResellersList(searchString);
    return Promise.resolve(
        organizations.map(
            (r): TagSelectItem<BackOfficeResellerDTO> => ({
                text: r.ResellerName,
                value: r,
            }),
        ),
    );
};

export const getBoResellersTypeaheadItems = async (searchString: string): Promise<Array<TypeaheadItem<BackOfficeResellerDTO>>> => {
    const organizations = await getBoResellersList(searchString);
    return Promise.resolve(
        organizations.map(
            (r): TypeaheadItem<BackOfficeResellerDTO> => ({
                text: r.ResellerName,
                value: r,
            }),
        ),
    );
};

export const getBoOrganizationList = async (searchString: string): Promise<Array<BackOfficeOrganizationDTO>> => {
    const searchParams: BaseSearch = {
        SortItems: [
            {
                SortColumn: 'OrganizationName',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 25,
        },
        Restrictions: [
            {
                Field: 'OrganizationName',
                Value: searchString || '',
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
    };
    const organizationsResponse = await api.boOrganizations.getOrganizations(searchParams);
    return Promise.resolve(organizationsResponse.data.Items);
};

export const getBoOrganizationsTagSelectItems = async (searchString: string): Promise<Array<TagSelectItem<BackOfficeOrganizationDTO>>> => {
    const organizations = await getBoOrganizationList(searchString);
    return Promise.resolve(
        organizations.map(
            (r): TagSelectItem<BackOfficeOrganizationDTO> => ({
                text: r.OrganizationName,
                value: r,
            }),
        ),
    );
};

export const getBoOrganizationsTypeaheadItems = async (searchString: string): Promise<Array<TypeaheadItem<BackOfficeOrganizationDTO>>> => {
    const organizations = await getBoOrganizationList(searchString);
    return Promise.resolve(
        organizations.map(
            (r): TypeaheadItem<BackOfficeOrganizationDTO> => ({
                text: r.OrganizationName,
                value: r,
            }),
        ),
    );
};

export const getManagersTypeaheadItems = async (searchString: string): Promise<Array<TypeaheadItem<BackOfficeUserDTO>>> => {
    const managers = await getBoManagersList(searchString);
    if (managers?.length > 0) {
        managers.forEach((e) => (e.Settings = []));
    }
    return Promise.resolve(
        managers.map(
            (m): TypeaheadItem<BackOfficeUserDTO> => ({
                text: `${m.FirstName} ${m.LastName}`,
                value: m,
            }),
        ),
    );
};

export function getBoCompanyListColumnName(columnName: string, translate?: TFunction): string {
    const columnNames = {
        CompanyName: 'view.backOffice.companies.column.name',
        Country: 'view.backOffice.companies.column.country',
        RegistrationCode: 'view.backOffice.companies.column.registrationCode',
        VatCode: 'view.backOffice.companies.column.vatNumber',
        Status: 'view.backOffice.companies.column.status',
        CompanyNotes: 'view.backOffice.companies.column.notes',
        Managers: 'view.backOffice.companies.column.managers',
        Reseller: 'view.backOffice.companies.column.reseller',
        Organization: 'view.backOffice.companies.column.organization',
        Affiliates: 'view.backOffice.companies.column.affiliates',
        Users: 'view.backOffice.companies.column.users',
    };
    if (translate) {
        return translate(columnNames[columnName]);
    }
    return columnNames[columnName];
}

export const getRoles = () => mapUserRoles;

export const getBOCompanyRole = (role: UserRole) => mapUserRoles[role];

export const parseRestrictions = (searchParams: BoCompanyListSearchParams, translate: TFunction) => {
    const appliedFilters: Array<TableFilter<any>> = [];
    searchParams.Restrictions.forEach((restriction) => {
        if (restriction.Field !== DEFAULT_RESTRICTION) {
            const newAppliedFilter: TableFilter<any> = {
                label: getBoCompanyListColumnName(restriction.Field, translate),
                columnName: restriction.Field,
                items: searchParams.filters[restriction.Field].items,
                loadItems: searchParams.filters[restriction.Field].loadItems,
                values: searchParams.filters[restriction.Field].values,
                tagSelectType: searchParams.filters[restriction.Field].tagSelectType,
                onSelectChangeCallback: searchParams.filters[restriction.Field].onSelectChangeCallback,
                placeholder: searchParams.filters[restriction.Field].placeholder,
                searchOnFocus: searchParams.filters[restriction.Field].searchOnFocus,
            };
            if (!!newAppliedFilter.values && !isEmpty(newAppliedFilter.values)) {
                appliedFilters.push(newAppliedFilter);
            }
        }
    });
    return appliedFilters;
};

// General function to get valid Restriction from either a UI filter or saved userSetting filter Name and Values
export const parseDTOValues = (columnName: string, values: any[]): Restriction => {
    const restriction: Restriction = {
        Field: columnName,
        Value: null,
        Values: null,
        FieldSearchType: SearchType.NotSelected,
    };

    // As a general rule, if we filter by several values within a restriction => Value = null, Values = [...guids]
    // If we filter by one value within a restriction => Value = 'FieldName', Values = null
    // Also need to check for simple values vs DTOs

    if (values?.length > 1) {
        if (columnName === CompanyFilterableColumns.MANAGERS) {
            // we are using BackOfficeUserDTO as Managers
            restriction.Values = values.map((val) => val['UserGuid']);
        } else {
            restriction.Values = ([CompanyFilterableColumns.RESELLER, CompanyFilterableColumns.ORGANIZATION] as string[]).includes(columnName)
                ? values.map((val) => val[`${columnName}Guid`])
                : values.map((val) => val);
        }
    } else if (values?.length === 1) {
        if (columnName === CompanyFilterableColumns.MANAGERS) {
            restriction.Value = values[0][`FullName`];
        } else {
            restriction.Value = ([CompanyFilterableColumns.RESELLER, CompanyFilterableColumns.ORGANIZATION] as string[]).includes(columnName) ? values[0][`${columnName}Name`] : values[0];
        }
    }

    return restriction;
};

// export const parseFilters = (filter: TableFilter<any>): Restriction => parseDTOValues(filter.columnName, filter.values);
export const parseFilters = (filter: TableFilter<any>): Restriction =>
    parseDTOValues(
        filter.columnName,
        filter.values.map((v) => v.value),
    );

export const getBoCompanyStatusType = (status: BackOfficeCompanyStatus) => {
    const statusMap = {
        [BackOfficeCompanyStatus.ACTIVE]: StatusType.GREEN,
        [BackOfficeCompanyStatus.DEACTIVATED]: StatusType.GRAY,
        [BackOfficeCompanyStatus.TEST]: StatusType.YELLOW,
        [BackOfficeCompanyStatus.DEMO]: StatusType.RED,
        [BackOfficeCompanyStatus.UNDEFINED]: StatusType.BLUE,
        [BackOfficeCompanyStatus.ARCHIVED]: StatusType.PURPLE,
    };
    return statusMap[status];
};

export const getItemsForAColumnFilter = (colName: CompanyFilterableColumns, t: TFunction) => {
    switch (colName) {
        case CompanyFilterableColumns.COUNTRY:
            return getCountryList();
        case CompanyFilterableColumns.STATUS:
            return getBoCompanyStatusList(t);
        default:
            return undefined;
    }
};

export const getItemsLoaderForAColumnFilter = (colName: CompanyFilterableColumns) => {
    switch (colName) {
        case CompanyFilterableColumns.MANAGERS:
            return getManagersTypeaheadItems;
        case CompanyFilterableColumns.RESELLER:
            return getBoResellersTagSelectItems;
        case CompanyFilterableColumns.ORGANIZATION:
            return getBoOrganizationsTagSelectItems;
        default:
            return undefined;
    }
};
