import React, { ReactElement, useState, useEffect } from 'react';
import { TFunction } from 'i18next';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { cloneDeep, max } from 'lodash-es';

import { Button, ButtonType } from '../../../../../components/Buttons/Button';
import { getApproveAllConfirmersByNamePart } from '../InvoiceConfirmationsHelper';
import { TypeaheadAsync, TypeaheadItem } from '../../../../../components/Typeahead/TypeaheadAsync';
import { GroupTasksDTO, TaskType } from '../InvoiceConfirmations';
import { ApproverPerStep, InvoiceDTO, GroupMemberDTO, TaskDTO } from '../../../../../services/types/ApiTypes';
import { ItemEditWorkFlow } from './ItemEditWorkFlow';
import Icon, { ICONS } from '../../../../../components/Icon/Icon';
import ToggleItems from '../../../../settings/workflow-details/components/ToggleItems';
import Link from '../../../../../components/Link/Link';
import { User } from '../../../../../services/ApiClient';
import { getStaticStepsCounterForGroupTask, reorderGroupTasks } from './utils';
import { InvoiceStatus } from '../../../../../common/constants/appConstants';

interface ConfirmationEditModeProps {
    approversPerSteps: ApproverPerStep[];
    invoice: InvoiceDTO;
    userData: User;
    t: TFunction;
    groupTasksEdit: GroupTasksDTO[];
    numberOfCompletedTasks: number;
    updateGroupTaskEditing: (gts: GroupTasksDTO[]) => void;
    saveWorkflowChanged: (approversPerSteps?: ApproverPerStep[]) => void;
    $rootScope: any;
}
export const ConfirmationEditMode = ({
    approversPerSteps,
    invoice,
    userData,
    groupTasksEdit,
    numberOfCompletedTasks,
    t,
    updateGroupTaskEditing,
    $rootScope,
    saveWorkflowChanged,
}: ConfirmationEditModeProps): ReactElement => {
    const [approvers, setApprovers] = useState<ApproverPerStep[]>(approversPerSteps);

    useEffect(() => {
        setApprovers(approversPerSteps);
    }, [approversPerSteps]);

    // This function accepts the order and oldOrder indecies from Task (index inconsistency to be handled in here)
    const updateApproversPerSteps = (count: number, order: number, oldOrder?: number) => {
        const orderNoToReplace = oldOrder ? oldOrder - 1 : order - 1; // if oldOrder is there, we are re-arranging the steps order
        if (!approvers) {
            return;
        }

        const idx = approvers.findIndex((a) => a.OrderNo === orderNoToReplace);

        if (idx > -1) {
            let newApproversPerSteps = [...approvers];
            if (count === null) {
                // changing workflow to Parallel approvers (dynamic) => remove item from ApproversPerSteps
                newApproversPerSteps = newApproversPerSteps.filter((a) => a.OrderNo !== order - 1);
            } else {
                newApproversPerSteps[idx] = { OrderNo: order - 1, Count: count };
            }
            setApprovers(newApproversPerSteps);
        } else {
            // add new ApproversPerSteps item as it was not there
            count && setApprovers([...approvers, { OrderNo: orderNoToReplace, Count: count }]);
        }
    };

    // group tasks re-arrangement demands re-calculating all OrderNo in approversPerSteps
    const checkAndUpdateApproversPerSteps = (src: number, dest: number) => {
        if (src === dest) {
            return;
        }
        const srcOrderNo = numberOfCompletedTasks + src;
        const destOrderNo = numberOfCompletedTasks + dest;
        const movingDown = srcOrderNo < destOrderNo;
        const newApproversPerSteps: ApproverPerStep[] = [];

        // const i = newApproversPerSteps.findIndex(a=> a.OrderNo === srcOrderNo);
        if (movingDown) {
            approvers.map((a: ApproverPerStep) => {
                if (a.OrderNo === srcOrderNo) {
                    newApproversPerSteps.push({ OrderNo: destOrderNo, Count: a.Count });
                } else if (a.OrderNo > srcOrderNo && a.OrderNo <= destOrderNo) {
                    newApproversPerSteps.push({ OrderNo: a.OrderNo - 1, Count: a.Count });
                } else {
                    newApproversPerSteps.push(a);
                }
            });
        } else {
            approvers.map((a: ApproverPerStep) => {
                if (a.OrderNo >= destOrderNo && a.OrderNo < srcOrderNo) {
                    newApproversPerSteps.push({ OrderNo: a.OrderNo + 1, Count: a.Count });
                } else if (a.OrderNo === srcOrderNo) {
                    newApproversPerSteps.push({ OrderNo: destOrderNo, Count: a.Count });
                } else {
                    newApproversPerSteps.push(a);
                }
            });
        }
        setApprovers(newApproversPerSteps);
    };

    const addNextApprover = async (groupMember: GroupMemberDTO) => {
        const newGroupTask = cloneDeep(groupTasksEdit);
        const newTaskOrderNo = max(newGroupTask.map((gt) => gt.group[0].OrderNo)) + 1;
        let newTask: TaskDTO = {
            OrderNo: newTaskOrderNo,
            Completed: false,
            Id: groupMember.Id,
            CompletedDate: null,
            Comment: null,
            ProcessedBy: null,
            GroupMemberId: groupMember.Id,
            GroupMember: undefined,
            WorkflowId: undefined,
            IsNew: undefined,
            isCurrentConfirmer: undefined,
            displayAddBeforeMeProp: undefined,
            displayAddAfterMeProp: undefined,
            StatusLookupId: undefined,
            ToSubstituteName: undefined,
            CreatorName: null,
        };
        newTask = {
            ...newTask,
            GroupMember: {
                ...Object(newTask.GroupMember),
                Name: groupMember.Name,
                Id: groupMember.Name,
            },
        };
        newGroupTask.push({
            type: TaskType.Default,
            group: [newTask],
        });

        updateGroupTaskEditing(newGroupTask);
    };

    const removeGroup = (index: number) => {
        const groupTasksEditClone = [...groupTasksEdit];

        groupTasksEditClone.splice(index, 1);
        // shift orderNr up for all the groups after the deleted group
        groupTasksEditClone.forEach((gt, i) => {
            if (i >= index) {
                gt.group = gt.group.map((g) => ({ ...g, OrderNo: g.OrderNo - 1 }));
            }
        });

        const newApproversPerSteps = approvers.map((a) => {
            if (a.OrderNo > index + numberOfCompletedTasks) {
                return { ...a, OrderNo: a.OrderNo - 1 };
            }
            return a;
        });
        setApprovers(newApproversPerSteps);

        updateGroupTaskEditing(groupTasksEditClone);
    };

    const updateTask = (groupTaskUpdated: TaskDTO[], index: number) => {
        groupTasksEdit[index].group = groupTaskUpdated;
        updateGroupTaskEditing([...groupTasksEdit]);
    };

    const onDragEnd = (result: DropResult) => {
        const { destination, source } = result;
        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }
        const srcOrderNo = source.index + numberOfCompletedTasks + 1;
        const destOrderNo = destination.index + numberOfCompletedTasks + 1;
        const groupTasksEditClone = reorderGroupTasks(srcOrderNo, destOrderNo, [...groupTasksEdit]);

        checkAndUpdateApproversPerSteps(source.index, destination.index);
        updateGroupTaskEditing(groupTasksEditClone);
    };

    const saveWorkflow = () => saveWorkflowChanged(approvers);

    return (
        <>
            {groupTasksEdit.length > 0 && (
                <div className="confirmation__edit-block">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={`droppable`}>
                            {(provided) => (
                                <div className="confirmation__task--item" {...provided.droppableProps} ref={provided.innerRef}>
                                    {groupTasksEdit.map((item, index) => {
                                        return (
                                            <ItemEditWorkFlow
                                                deleteEnabled={!(groupTasksEdit.length < 2 && invoice.Status === InvoiceStatus.InApproval)}
                                                groupTask={item.group}
                                                staticStepsNo={getStaticStepsCounterForGroupTask(approvers, item)}
                                                t={t}
                                                key={index}
                                                index={index}
                                                $rootScope={$rootScope}
                                                invoice={invoice}
                                                userData={userData}
                                                updateGroupTaskEditing={(groupTaskUpdated) => updateTask(groupTaskUpdated, index)}
                                                removeGroup={() => removeGroup(index)}
                                                updateApproversPerSteps={updateApproversPerSteps}
                                            />
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            )}
            <div className="confirmation__edit-block confirmation__add-next-approver">
                <div className="confirmation__task--item-edit">
                    <div className="confirmation__task-icon">
                        <Icon iconName={ICONS.PLUS_THIN} />
                    </div>
                    <div className="confirmation__task-content confirmation__task-content--upcoming">
                        <ToggleItems
                            firstItem={
                                <Link button={true} className={'confirmation__add-next-button'}>
                                    {t('view.Workflows.Details.Approvers.AddNext')}
                                </Link>
                            }
                            secondItem={
                                <TypeaheadAsync
                                    wrapperClass="confirmation__choose-confirmation"
                                    dataId={'choose-confirmation-flow'}
                                    loadItems={(name?: string) => {
                                        return getApproveAllConfirmersByNamePart(name, undefined, false, invoice.Status, true);
                                    }}
                                    inputProps={{
                                        autofocus: true,
                                        showClear: true,
                                    }}
                                    placeholder={t('component.confirmationFlow.chooseUser.placeholder')}
                                    searchOnFocus={true}
                                    onChange={(item: TypeaheadItem<GroupMemberDTO>) => {
                                        item && addNextApprover(item.value);
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="confirmation__only-button confirmation__save-changes">
                <Button buttonType={ButtonType.PRIMARY} className="button button--primary confirmation__button--edit-item" onClick={saveWorkflow}>
                    {t('views.global.saveChanges')}
                </Button>
            </div>
        </>
    );
};
