import { isArray, find, filter } from 'lodash-es';
import { Currency, InvoiceDTO, InvoiceDataResponseDTO, SystemConfigurationDTO, InvoiceCustomizationDTO, PurchaseOrderTaskDTO } from '../../../../services/types/ApiTypes';
import { StampType } from '../../../../components/StampLabel/StampLabel';
import { SelectOptionValue } from '../../../../components/Select/SelectOption';
import { CompanySetting } from '../../../../common/user/userCompanySettingsUtil';
import { hasUserARole, getCurrentUserData } from '../../../../common/user/userPermissionUtil';
import { UserRole } from '../../../../common/constants/appConstants';
import { formatThousands } from '../../../../common/utils/formatters';
import { routerHistory as history } from '../../../../storeConfig';
import { CompanySettingStatus } from '../../../settings/company-settings/components/Settings/CompanySettingsListHelper';

export const getStatusStamptype = (invoice: InvoiceDTO): StampType => {
    switch (invoice?.Status) {
        case 0:
            return StampType.INFO;
        case 1:
        case 2:
            return StampType.ACTIVE;
        case 3:
        case 4:
        case 7:
            return StampType.APPROVED;
        case 9:
            return StampType.ALARM;
        default:
            return StampType.NEUTRAL;
    }
};

export const getInvoiceStatusAllowsEditting = (status?: number): boolean => {
    switch (status) {
        case 3: // PendingExport
        case 4: // Exported
        case 5: // Deleted
        case 7: // NotForExport
            return false;
        default:
            return true;
    }
};

export const filterCurrenciesByName = (name: string, list: Currency[]): Currency[] => {
    if (find(list, (cur) => cur.Code === name.toLowerCase() || cur.Description === name.toLowerCase())) {
        return list;
    }
    return filter(list, (c) => {
        const regex = new RegExp((name || '').toLowerCase());
        return regex.test(c.Code.toLowerCase()) || regex.test(c.Description.toLowerCase());
    });
};

export const getInvoiceDocTypeToDisplay = (invoice?: InvoiceDTO & InvoiceDataResponseDTO): SelectOptionValue<string> => {
    const docType = invoice?.DocumentType;
    // for some reason, it can be an array passed down from InvoiceConfirmationController (to be updated later)
    return isArray(docType) ? { text: docType[0], value: docType[0] } : { text: docType, value: docType };
};

export const getDocTypeName = (item: string[]) => {
    if (!item) {
        return item;
    }
    return item[1];
};

export const getDocumentTypeOptions = (settings: SystemConfigurationDTO[], defaultValueElement: React.ReactNode): SelectOptionValue<string>[] => {
    const setting = settings.filter((i) => i.Name === 'DocumentTypes');
    const typeOptions: SelectOptionValue<string>[] = [{ text: '', value: null, element: defaultValueElement }];
    if (setting.length > 0 && setting[0].Value) {
        // separate options are separated by ';', code/value - by ','
        const fields = setting[0].Value.split(';').map((i) => i.split(','));
        fields.map((f) => ({ value: f[0], text: f[1] }));
        fields.map((f) => typeOptions.push({ value: f[0], text: f[1] }));
    }
    return typeOptions;
};

export const getInvoiceDocTypeValue = (docType: string | string[]): string => {
    if (isArray(docType) && docType.length) {
        return docType[0];
    }
    return docType as string;
};

export const changeValueOfCustomizationChunkItem = (chunks: InvoiceCustomizationDTO[][], newVal: string, customizationId: number): InvoiceCustomizationDTO[][] => {
    chunks.forEach((c, i) =>
        c.forEach((f, j) => {
            if (f.CustomizationId === customizationId) {
                chunks[i][j].Value = newVal;
            }
        }),
    );
    return chunks;
};

export const getDocTypeOptionValue = (invoice: InvoiceDTO, docTypeOptions: SelectOptionValue<string>[]) => {
    let val: SelectOptionValue<string>;
    if (isArray(invoice?.DocumentType)) {
        val = docTypeOptions?.find((o) => o.value === invoice?.DocumentType[0]);
        return val;
    }
    val = docTypeOptions?.find((o) => o.value === invoice?.DocumentType);
    return val;
};

export const getMaxItemsPerChunk = (fullSize: number, maxPerChunk: number) => {
    return Math.ceil(fullSize / maxPerChunk);
};

export const isErpVisible = (currentCompanySettings: SystemConfigurationDTO[]): boolean => {
    const setting = currentCompanySettings.filter((i) => i.Name === CompanySetting.IsERPIdEnabled);
    return setting[0] && setting[0].Value === CompanySettingStatus.Enabled;
};

export const sortCustomFields = (customFields: InvoiceCustomizationDTO[]) => customFields.sort((a, b) => a.CustomizationId - b.CustomizationId);

/**
 * '12345234' => '12,345,234.00'
 */

export const formatMoneyToShowSeparators = (n: string | number, isBigNumber?: boolean) => {
    if (isBigNumber) {
        const mainString = n?.toString().split('.')[0];
        const decimalString = n?.toString().split('.')[1];
        const decimalFormat = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        const decimalFullString = `0.${decimalString}`;
        const decimalFullNumber = Number.parseFloat(decimalFullString);
        const decimalFullFinal = decimalFormat.format(decimalFullNumber);
        const decimalFinal = decimalFullFinal.slice(1);
        const mainFormat = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 });
        let mainBigInt = BigInt(mainString || 0);
        if (decimalFullFinal[0] === '1') {
            mainBigInt += BigInt(1);
        }
        let mainFinal;
        if (mainString === '-0') {
            mainFinal = mainString;
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            mainFinal = mainFormat.format(mainBigInt);
        }
        const amountFinal = `${mainFinal}${decimalFinal}`;
        return amountFinal ? formatThousands(amountFinal) : '00.00';
    } else {
        const str = n?.toString();
        return str ? formatThousands(new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number.parseFloat(str))) : '00.00';
    }
};

/**
 * '12,345,234.00  EU' -> '12345234.00'
 */
export const formatMoneyString = (n: string | number) => {
    const str = n?.toString();
    const formated = str
        ? str
              .replace(/,/g, '.') // replace all , by .
              .replace(/[.](?=.*[.])/g, '') // remove all . except the last one
              .replace(/[^\d.-]/g, '') // remove all other symbols, but digits and a .
        : '0.00';
    return formated;
};

export type ExportSettings = {
    isERPIdEnabled: boolean;
    isNewSupplierVisible: boolean;
};

export const parseExportSetting = (settings: SystemConfigurationDTO[]): ExportSettings => {
    if (settings) {
        const companySetting = find(settings, (setting) => setting.Name === CompanySetting.IsExportWithUnregisteredSupplierDenied);
        let isNewSupplierVisible = false;

        if (companySetting) {
            isNewSupplierVisible = companySetting.Value === CompanySettingStatus.Enabled;
        }
        const isERPIdEnabledSetting = find(settings, (setting) => setting.Name === CompanySetting.IsERPIdEnabled);
        const isERPIdEnabled = isERPIdEnabledSetting && isERPIdEnabledSetting.Value === CompanySettingStatus.Enabled;

        return { isERPIdEnabled, isNewSupplierVisible };
    }

    return { isERPIdEnabled: false, isNewSupplierVisible: false };
};

export const roundNumber = (n: number, k = 2): number => {
    const precisionOrder = Math.pow(10, k);
    return Math.round((n + Number.EPSILON) * precisionOrder) / precisionOrder;
};

export const areTwoNumsEqualWithPrecision = (n1: number | string, n2: number | string, k?: number): boolean => {
    const num1 = roundNumber(parseFloat(n1.toString()), k);
    const num2 = roundNumber(parseFloat(n2.toString()), k);
    return num1 === num2;
};

export const canUserOpenPurchaseOrder = (poCreatedById: number, poTasks: PurchaseOrderTaskDTO[]) => {
    const isPOCreator = hasUserARole(UserRole.PurchaseOrdersCreator);
    const groupMemberId = getCurrentUserData().GroupMemberId;
    if (isPOCreator && groupMemberId === poCreatedById) {
        return true;
    }

    const isApprover = hasUserARole(UserRole.Processor);
    if (isApprover && poTasks.some((t) => t.GroupMemberId === groupMemberId || t.ToSubstituteId === groupMemberId)) {
        return true;
    }
    return false;
};

export const handleBackClick = () => {
    history.push('/invoices');
};

export const typesList = [
    {
        text: 'view.AutoTransactions.Details.Triggers.ExtensionField.Invoice.Type.Debit',
        value: false,
    },
    {
        text: 'view.AutoTransactions.Details.Triggers.ExtensionField.Invoice.Type.Credit',
        value: true,
    },
];

export const canUserDownloadPurchaseOrder = canUserOpenPurchaseOrder;
