import classNames from 'classnames';
import { TFunction } from 'i18next';
import { isEmpty, size } from 'lodash-es';
import React from 'react';

import { createDataId, WithDataId } from '../../../../common/utils/dataId';
import LabelWrapper, { LabelAlignment, LabelNamePlacement } from '../../../LabelWrapper/LabelWrapper';
import Tag from '../../../Tag/Tag';
import { TagSelect, TagSelectItem, TagSelectType } from '../../../TagSelect/TagSelect';
import Icon, { ICONS } from '../../../Icon/Icon';

export interface TableFilter<T> {
    columnName: string;
    childColumnName?: string;
    label?: string;
    placeholder?: string;
    tagSelectType: TagSelectType;
    items?: Array<TagSelectItem<T>>;
    loadItems?: (...args: any) => Promise<Array<TagSelectItem<T>>>;
    values: Array<TagSelectItem<T>>;
    onSelectChangeCallback: (filter: TableFilter<T>) => void;
    searchOnFocus?: boolean;
    renderInput?: (props: any) => React.ReactNode;
}

export interface TableFiltersProps extends WithDataId {
    appliedFilters: Array<TableFilter<any>>;
    areOpen?: boolean;
    className?: string;
    hasStaticFilters?: boolean;
    t: TFunction;
    onRemove: (index: number, filter: TableFilter<any>) => void;
}

const TableFilters = (props: TableFiltersProps) => {
    const { appliedFilters, areOpen, className, dataId, hasStaticFilters, t } = props;
    const classes = classNames('table-filter', className);

    const removeFilter = (index: number, filter: TableFilter<any>) => {
        props.onRemove(index, filter);
    };

    const getFilterSelect = (f: TableFilter<any>) => {
        return (
            <div className="filter-select-wrapper">
                {size(f.values) > 1 && <span>{`${f.values.length} ${t('component.TableFilter.MultipleSelected')}`}</span>}
                {size(f.values) === 1 && <span>{`${f.values[0].text}`}</span>}
                {size(f.values) === 0 && <span>{t(f.placeholder)}</span>}
                <Icon name={ICONS.CHEVRON_DOWN_24} />
            </div>
        );
    };

    if ((hasStaticFilters && !areOpen) || size(appliedFilters) === 0) {
        return null;
    }
    return (
        <>
            {appliedFilters.map((filter, index) => {
                if (isEmpty(filter.values) && !hasStaticFilters) {
                    return null;
                }
                return (
                    <LabelWrapper className={classes} label={filter.label} key={index} alignment={LabelAlignment.LEFT} namePlacement={LabelNamePlacement.TOP}>
                        <TagSelect
                            dataId={createDataId(dataId || 'tableFilters', 'tagSelect', filter.columnName)}
                            type={filter.tagSelectType}
                            values={filter.values}
                            items={filter.items}
                            loadItems={filter.loadItems}
                            onChange={(items: Array<TagSelectItem<any>>) =>
                                filter.onSelectChangeCallback({
                                    ...filter,
                                    values: items,
                                })
                            }
                            placeholder={t(filter.placeholder || 'component.Typeahead.Placeholder.Generic')}
                            searchOnFocus={!!filter.searchOnFocus}
                            render={filter.renderInput}
                        >
                            {hasStaticFilters ? (
                                getFilterSelect(filter)
                            ) : (
                                // To be removed after all pages adopt static filters in search
                                <Tag dataId={createDataId(`filter-tag_${filter.columnName}`)} hasArrow={true} onRemove={() => removeFilter(index, filter)}>
                                    {size(filter.values) > 1 && <span>{`${filter.values.length} ${t('component.TableFilter.MultipleSelected')}`}</span>}
                                    {size(filter.values) === 1 && <span>{`${filter.values[0].text}`}</span>}
                                </Tag>
                            )}
                        </TagSelect>
                    </LabelWrapper>
                );
            })}
        </>
    );
};

export default TableFilters;
