import classNames from 'classnames';
import { debounce } from 'lodash-es';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Manager, Popper, Reference } from 'react-popper';
import { CSSTransition } from 'react-transition-group';

import { OutsideEventListener } from '../../common/utils/OutsideEventListener';
import { BaseComponent } from '../BaseComponent';
import { BaseStatefulComponent } from '../BaseStatefulComponent';
import withSuspense from '../../common/hocs/withSuspense';

import './ConfirmationPopup.scss';

export interface Props<T> {
    onConfirm?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children?: React.ReactNode;
    content?: string;
    confirmText?: string;
    cancelText?: string;
    className?: string;
    positionFixed?: boolean;
    type?: ConfirmationPopupType;
}

export type ConfirmationPopupProps<T> = Props<T> & WithTranslation;

export interface ConfirmationPopupState<T> {
    isOpen: boolean;
}

interface ConfirmationPopupPositionerProps {
    scheduleUpdate: () => void;
}

export enum ConfirmationPopupType {
    LEGACY = 'LEGACY',
}

export const ConfirmationPopupRepositionerContext = React.createContext<any>(undefined);

class ConfirmationPopupPositioner extends BaseComponent<ConfirmationPopupPositionerProps> {
    render() {
        return (
            <ConfirmationPopupRepositionerContext.Consumer>
                {(value) => {
                    if (this.props.scheduleUpdate && value) {
                        this.props.scheduleUpdate();
                    }
                    return null;
                }}
            </ConfirmationPopupRepositionerContext.Consumer>
        );
    }
}

class ConfirmationPopupInternal<T> extends BaseStatefulComponent<ConfirmationPopupProps<T>, ConfirmationPopupState<T>> {
    private componentRootElement = React.createRef<HTMLDivElement>();
    private outsideEventListener: OutsideEventListener;

    static defaultProps: Partial<ConfirmationPopupProps<any>> = {
        type: ConfirmationPopupType.LEGACY,
    };

    constructor(props: ConfirmationPopupProps<T>) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    componentWillUnmount() {
        if (this.outsideEventListener) {
            this.outsideEventListener.stop();
        }
    }

    componentDidUpdate(prevProps: ConfirmationPopupProps<T>, prevState: ConfirmationPopupState<T>) {
        if (this.state.isOpen && !prevState.isOpen) {
            if (this.outsideEventListener) {
                this.outsideEventListener.stop();
            }
            this.outsideEventListener = new OutsideEventListener(this.componentRootElement, this.debounceCloseDropdown, false);
            this.outsideEventListener.start();
        }
    }

    preventPropagation = (e: any) => {
        e.stopPropagation();
    };

    closeDropdown = (closed = true) => {
        this.setState({
            isOpen: !closed,
        });
    };

    debounceCloseDropdown = debounce(this.closeDropdown, 100);

    handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onConfirm(event);
    };

    handleCancel = () => {
        this.closeDropdown();
    };

    render() {
        const { className, t, content, confirmText, cancelText, children, type } = this.props;
        const classes = classNames('confirmation-popup', className, { 'confirmation-popup--legacy': type === ConfirmationPopupType.LEGACY });
        return (
            <>
                <Manager>
                    <Reference>
                        {({ ref }) => (
                            <span
                                className={'confirmation-popup-trigger'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.bubbles = false;
                                    if (this.state.isOpen) {
                                        return;
                                    }
                                    this.closeDropdown(false);
                                }}
                                ref={ref}
                            >
                                {children}
                            </span>
                        )}
                    </Reference>

                    {ReactDOM.createPortal(
                        <CSSTransition unmountOnExit={true} classNames="fade" in={this.state.isOpen} timeout={150}>
                            <Popper
                                innerRef={(el) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                    // @ts-ignore
                                    this.componentRootElement.current = el;
                                }}
                                positionFixed={!!this.props.positionFixed}
                                placement={'bottom'}
                                modifiers={{
                                    offset: {
                                        offset: '0, 12px',
                                    },
                                }}
                            >
                                {({ ref, style, placement, scheduleUpdate, arrowProps }) => (
                                    <div ref={ref} style={style} data-placement={placement} className={classes} onClick={this.preventPropagation}>
                                        <span className="confirmation-popup__arrow" ref={arrowProps.ref} style={arrowProps.style} />
                                        <ConfirmationPopupPositioner scheduleUpdate={scheduleUpdate} />
                                        <div className={'confirmation-popup__content'}>
                                            <div className={'confirmation-popup__content-text'}>{content || t('component.ConfirmationPopup.DefaultContent')}</div>
                                            <div className="confirmation-popup__bottom-content">
                                                <button className="button confirmation-popup__confirm" onClick={this.handleConfirm}>
                                                    {confirmText || t('component.ConfirmationPopup.DefaultConfirm')}
                                                </button>
                                                <button className="button confirmation-popup__cancel" onClick={this.handleCancel}>
                                                    {cancelText || t('component.ConfirmationPopup.DefaultCancel')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popper>
                        </CSSTransition>,
                        document.body,
                    )}
                </Manager>
            </>
        );
    }
}

export const ConfirmationPopup = withSuspense(withTranslation()(ConfirmationPopupInternal));
