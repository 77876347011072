export interface FileReaderResult {
    base64Content: string;
    fileName: string;
}

export function readFileContentAsBase64(file: File): Promise<FileReaderResult> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = function() {
            try {
                let binaryString = '';
                const bytes = new Uint8Array(reader.result as ArrayBuffer);
                for (let i = 0; i < bytes.byteLength; i++) {
                    binaryString += String.fromCharCode(bytes[i]);
                }
                resolve({
                    base64Content: window.btoa(binaryString),
                    fileName: file.name,
                });
            } catch (e) {
                reject(e);
            }
        };
        reader.readAsArrayBuffer(file);
    });
}
export interface FileReaderByteResult {
    fileByteArray: Uint8Array;
}
export function readFileContentAsBytes(file: File | Blob): Promise<FileReaderByteResult> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = function() {
            try {
                resolve({
                    fileByteArray: new Uint8Array(reader.result as ArrayBuffer),
                });
            } catch (e) {
                reject(e);
            }
        };
        reader.readAsArrayBuffer(file);
    });
}
