'use strict';

angular.module('dstreamApp.components').component('dsMobileSideMenu', {
    templateUrl: 'app/views/side-menus/components/mobile-side-menu/ds-mobile-side-menu.tpl.html',
    controller: DsMobileSideMenuController,
    bindings: {
        showMenu: '&',
        header: '<',
        utility: '<',
        clearSearchFilter: '<',
        userData: '<',
        company: '<',
        companies: '<',
        localesDisplayNames: '<',
        changeCompany: '&',
        signOut: '&',
        openChangePassword: '&',
        changeLanguage: '&',
    },
});

DsMobileSideMenuController.$inject = [
    "$scope",
    "$rootScope",
    "$location"
];
function DsMobileSideMenuController($scope, $rootScope, $location) {
    var ctrl = this;

    ctrl.navigate = (path, evt) => {
        if (!!evt) {
            evt.preventDefault();
        }
        ctrl.clearSearchFilter();
        $location.path(path);
    };

    ctrl.filterActiveCompany = function(value) {
        const srcExp = !!ctrl.companyName ? new RegExp(ctrl.companyName.toLowerCase()) : new RegExp('');
        if (value.hasOwnProperty('CompanyName')) {
            return value.Name !== ctrl.company && srcExp.test(value.CompanyName.toLowerCase());
        }
        return value.Name !== ctrl.company && srcExp.test(value.Name.toLowerCase());
    };

    ctrl.changeActiveCompany = function(id, event) {
        ctrl.companyName = '';
        ctrl.header.toggle('sidebar', event);
        ctrl.changeCompany({id: id})
    };
}
