import React, { useState, useEffect } from 'react';
import { Dispatchable0, Dispatchable1 } from 'redux-dispatchers';
import { withTranslation, WithTranslation } from 'react-i18next';

import { LoadableData } from '../../../../common/utils/LoadableData';
import { SessionDTO } from 'src/services/types/ApiTypes';
import { getIsMobileOS } from './utils';
import { createDataId } from '../../../../common/utils/dataId';
import { ContentBlockBody } from '../../../../components/ContentBlock/ContentBlockBody';
import UserSessionInfo from './UserSessionInfo';
import { Button } from '../../../Buttons/Button';

import './ManageSessions.scss';

type Props = {
    getUserSessions: Dispatchable0;
    terminateSessions: Dispatchable1<number[]>;
    userSessionsLoadable: LoadableData<SessionDTO[]>;
};

const dataId = 'manage-sessions';

const ManageSessions = ({ getUserSessions, terminateSessions, userSessionsLoadable, t }: Props & WithTranslation) => {
    const [currentSession, setCurrentSession] = useState<SessionDTO>();
    const [webSessions, setWebSessions] = useState<SessionDTO[]>([]);
    const [mobileSessions, setMobileSessions] = useState<SessionDTO[]>([]);

    useEffect(() => {
        getUserSessions();
    }, []);

    useEffect(() => {
        if (userSessionsLoadable.payload) {
            setCurrentSession(userSessionsLoadable.payload.find((s) => s.IsCurrent));
            const otherSessions = userSessionsLoadable.payload.filter((s) => !s.IsCurrent);
            setWebSessions(otherSessions.filter((s) => !getIsMobileOS(s.OSName)));
            setMobileSessions(otherSessions.filter((s) => getIsMobileOS(s.OSName)));
        }
    }, [userSessionsLoadable.payload]);

    const terminateUserSessions = async (id?: number) => {
        await terminateSessions(id ? [id] : [...webSessions.map((s) => s.Id), ...mobileSessions.map((s) => s.Id)]);
        getUserSessions();
    };

    if (!userSessionsLoadable.payload) {
        return null;
    }

    return (
        <ContentBlockBody dataId={createDataId(dataId, 'sessions')} loading={userSessionsLoadable.loading} className="block-body">
            <section className="all-sessions">
                <div className="all-sessions__info">
                    <h4 className="sessions-header">{t('views.global.UserProfileModal.Sessions.CurrentSession')}</h4>
                    <UserSessionInfo dataId={dataId} session={currentSession} terminateSession={terminateUserSessions} />
                    {!!webSessions.length && (
                        <>
                            <h4 className="sessions-header">{t('views.global.UserProfileModal.Sessions.ActiveSessionsWeb')}</h4>
                            {webSessions.map((s) => (
                                <UserSessionInfo key={`web-session-${s.Id}`} dataId={dataId} session={s} terminateSession={terminateUserSessions} />
                            ))}
                        </>
                    )}
                    {!!mobileSessions.length && (
                        <>
                            <h4 className="sessions-header">{t('views.global.UserProfileModal.Sessions.ActiveSessionsMobile')}</h4>
                            {mobileSessions.map((s) => (
                                <UserSessionInfo key={`mobile-session-${s.Id}`} dataId={dataId} session={s} terminateSession={terminateUserSessions} />
                            ))}
                        </>
                    )}
                </div>

                <div className="user-profile__footer" data-id={createDataId(dataId, 'footer')}>
                    <Button
                        loading={userSessionsLoadable.loading}
                        disabled={!userSessionsLoadable.payload || userSessionsLoadable.payload.length < 2}
                        onClick={() => terminateUserSessions()}
                        data-id={createDataId(dataId, 'button.terminateAllOtherSessions')}
                        className="terminate-all-button"
                    >
                        {t('views.global.UserProfileModal.Sessions.TerminateAllOtherSessions')}
                    </Button>
                </div>
            </section>
        </ContentBlockBody>
    );
};

export default withTranslation()(ManageSessions);
