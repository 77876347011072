import i18nInstance from '../../../i18n';
import { GlobalState } from '../../../rootReducer';
import { BackOfficeCompanyDTO } from '../../../services/types/BoApiTypes';
import { loadableDataActions } from '../../../common/utils/LoadableData';
import api from '../../../services/ApiServices';
import { DispatchThunk } from '../../../storeConfig';
import { notify } from '../../../common/utils/notify';
import { getCurrentCompanyData } from '../../../common/user/UserActions';

const ns = 'company-settings/';

export const getCompanyDataLoadable = loadableDataActions<string, BackOfficeCompanyDTO>(`${ns}GET_COMPANY_DATA`);

export const getSameOrganizationCompaniesData = loadableDataActions<string, BackOfficeCompanyDTO[]>(`${ns}GET_ORGANIZATION_COMPANIES`);

export function getCompanyData(companyGuid: string) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(getCompanyDataLoadable.request(companyGuid));
            const response = await api.boCompanies.getCompanyByGuid(companyGuid);
            dispatch(getCompanyDataLoadable.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(getCompanyDataLoadable.error(e));
        }
    };
}

export function saveCompany(company: BackOfficeCompanyDTO) {
    return async (dispatch: DispatchThunk) => {
        try {
            company.ModifiedBy = null;
            dispatch(getCompanyDataLoadable.request(company?.CompanyGuid));
            const response = await api.boCompanies.saveCompany(company);
            dispatch(getCompanyDataLoadable.success(response.data));
            notify.success(i18nInstance.t('views.CompanySettings.Update.SuccessText'));
            dispatch(getCurrentCompanyData());
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'));
            dispatch(getCompanyDataLoadable.error(e));
        }
    };
}

export function getSameOrganizationCompanies() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const companyGuid = getState().user.currentCompany.CompanyGuid;
            dispatch(getSameOrganizationCompaniesData.request(companyGuid));
            const response = await api.boCompanies.getSameOrganizationCompanies(companyGuid);
            dispatch(getSameOrganizationCompaniesData.success(response.data));
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('views.groupMember.detail.UserAdded'));
            dispatch(getSameOrganizationCompaniesData.error(e));
        }
    };
}
