export interface DataId extends String {}

export interface WithDataId {
    dataId: DataId;
}

/**
 * @deprecated
 * @param parent
 * @param dataId
 */
export function addDataId(parent: DataId, dataId: string): DataId {
    return parent ? parent + dataId : dataId;
}

export function createDataId(...args: any[]) {
    if (args && args.length) {
        return args.join('.');
    }
    return '';
}
