import * as React from 'react';

import './Modal.scss';

export interface ModalFooterProps {
    children?: React.ReactNode;
}

const ModalFooter: React.StatelessComponent<ModalFooterProps> = ({ children }: ModalFooterProps) => {
    return <div className="dialog__footer">{children}</div>;
};

export default ModalFooter;
