import Big from 'big.js';
import * as Yup from 'yup';
import { isNumber, map, round, sum } from 'lodash-es';
import { TFunction } from 'i18next';

import api from '../../services/ApiServices';
import { formatMoneyAndCurrency } from '../../common/utils/formatters';
import { PurchaseOrdersRowsDTO, PurchaseOrderType, SearchType, SortDirection } from '../../services/types/ApiTypes';
import { PURCHASE_ORDER_TYPE } from '../../common/constants/purchaseOrderConstants';
import { PurchaseOrdersAddViewEmptyRowFields, rowFieldNames } from './purchaseOrderAddViewFields';
import { HistorySearchParams } from './PurchaseOrdersAddViewReducer';
import { TypeaheadItem } from '../../components/Typeahead/Typeahead';

export const defaultCurrency = 'EUR';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

/**
 * use lodash round here to preserve decimal points only when they exist
 * also use Big.js to handle floating point precision
 */
function roundBig(value: Big, dp = 2) {
    return round(Number(value), dp);
}

export const rowValueCalculationFns = {
    [rowFieldNames.Quantity]: (rowValues: Partial<PurchaseOrdersRowsDTO>) => {
        const value = Number(rowValues[rowFieldNames.Quantity]);
        if (isNumber(value) && Number(rowValues.SumWithoutVat) && !Number(rowValues.UnitPrice)) {
            const newFieldValue = roundBig(Big(rowValues.SumWithoutVat).div(value), 4);
            setFieldValue(rowFieldNames.UnitPrice, newFieldValue, rowValues);
        }
        if (isNumber(value) && Number(rowValues.UnitPrice)) {
            const newFieldValue = roundBig(Big(rowValues.UnitPrice).times(value));
            setFieldValue(rowFieldNames.SumWithoutVat, newFieldValue, rowValues);
        }
    },
    [rowFieldNames.UnitPrice]: (rowValues: Partial<PurchaseOrdersRowsDTO>) => {
        const value = Number(rowValues[rowFieldNames.UnitPrice]);
        if (isNumber(value) && Number(rowValues.Quantity)) {
            setFieldValue(rowFieldNames.SumWithoutVat, roundBig(Big(rowValues.Quantity).times(value)), rowValues);
        }
        if (isNumber(value) && Number(rowValues.SumWithoutVat) && !Number(rowValues.Quantity)) {
            setFieldValue(rowFieldNames.Quantity, roundBig(Big(rowValues.SumWithoutVat).div(value), 4), rowValues);
        }
    },
    [rowFieldNames.SumWithoutVat]: (rowValues: Partial<PurchaseOrdersRowsDTO>) => {
        const value = Number(rowValues[rowFieldNames.SumWithoutVat]);
        if (isNumber(value) && !Number(rowValues.Quantity) && Number(rowValues.UnitPrice)) {
            setFieldValue(rowFieldNames.Quantity, roundBig(Big(value).div(rowValues.UnitPrice), 4), rowValues);
        }
        if (isNumber(value) && Number(rowValues.Quantity) && !rowValues.UnitPrice) {
            setFieldValue(rowFieldNames.UnitPrice, roundBig(Big(value).div(rowValues.Quantity), 4), rowValues);
        }
        if (isNumber(value) && !Number(rowValues.Vat) && Number(rowValues.VatAmount)) {
            setFieldValue(rowFieldNames.Vat, roundBig(Big(rowValues.VatAmount).div(value)) * 100, rowValues);
        }
        if (isNumber(value) && Number(rowValues.Vat)) {
            setFieldValue(
                rowFieldNames.VatAmount,
                roundBig(
                    Big(value)
                        .times(rowValues.Vat)
                        .div(100),
                ),
                rowValues,
            );
        }
        if (isNumber(value)) {
            setFieldValue(rowFieldNames.Total, roundBig(Big(value).add(rowValues.VatAmount || 0)), rowValues);
        }
    },
    [rowFieldNames.Vat]: (rowValues: Partial<PurchaseOrdersRowsDTO>) => {
        const value = Number(rowValues[rowFieldNames.Vat]);
        if (isNumber(value) && Number(rowValues.SumWithoutVat)) {
            setFieldValue(
                rowFieldNames.VatAmount,
                roundBig(
                    Big(rowValues.SumWithoutVat)
                        .times(value)
                        .div(100),
                ),
                rowValues,
            );
        }
    },
    [rowFieldNames.VatAmount]: (rowValues: Partial<PurchaseOrdersRowsDTO>) => {
        const value = Number(rowValues[rowFieldNames.VatAmount]);
        if (isNumber(value) && Number(rowValues.SumWithoutVat)) {
            setFieldValue(rowFieldNames.Vat, roundBig(Big(value).div(rowValues.SumWithoutVat)) * 100, rowValues, true);
        }
        if (isNumber(value) && Number(rowValues.Total) && !rowValues.SumWithoutVat) {
            setFieldValue(rowFieldNames.SumWithoutVat, roundBig(Big(rowValues.Total).minus(value)), rowValues, true);
        }
        if (isNumber(value)) {
            setFieldValue(rowFieldNames.Total, roundBig(Big(rowValues.SumWithoutVat || 0).add(value)), rowValues);
        }
    },
    [rowFieldNames.Total]: (rowValues: Partial<PurchaseOrdersRowsDTO>) => {
        const value = Number(rowValues[rowFieldNames.Total]);
        if (isNumber(value) && Number(rowValues.VatAmount)) {
            setFieldValue(rowFieldNames.SumWithoutVat, roundBig(Big(value).minus(rowValues.VatAmount)), rowValues, true);
        }

        if (isNumber(value) && !Number(rowValues.Vat) && Number(rowValues.VatAmount)) {
            setFieldValue(
                rowFieldNames.Vat,
                roundBig(
                    Big(rowValues.VatAmount)
                        .times(100)
                        .div(Big(value).minus(rowValues.VatAmount)),
                    0,
                ),
                rowValues,
                true,
            );
        }
    },
};

function setFieldValue(fieldName: string, newFieldValue: any, rowValues: Partial<PurchaseOrdersRowsDTO>, disableUpdateTrigger = false) {
    // since we recursively call field changes, then do not call other changes anymore when value is already same
    if (newFieldValue === rowValues[fieldName]) {
        return;
    }
    rowValues[fieldName] = newFieldValue;
    if (disableUpdateTrigger) {
        return;
    }
    const rowCalculationFn = rowValueCalculationFns[fieldName];
    if (rowCalculationFn) {
        try {
            rowCalculationFn(rowValues);
        } catch (e) {
            console.warn(e);
        }
    }
}

export const getNetSum = (fields: PurchaseOrdersRowsDTO[], currency?: string) => {
    const netSum = sum(map(fields, ({ SumWithoutVat }) => Number(SumWithoutVat) || 0));
    return formatMoneyAndCurrency(netSum, currency);
};

export const getTotalSum = (fields: PurchaseOrdersRowsDTO[], currency?: string) => {
    const totalSum = sum(map(fields, ({ Total }) => Number(Total) || 0));
    return formatMoneyAndCurrency(totalSum, currency);
};

export const orderTypesList = [
    {
        text: PURCHASE_ORDER_TYPE.OPEX,
        value: PurchaseOrderType.Opex,
    },
    {
        text: PURCHASE_ORDER_TYPE.CAPEX,
        value: PurchaseOrderType.Capex,
    },
];

export const emptyRowObject: PurchaseOrdersAddViewEmptyRowFields = {
    Description: '',
    Quantity: 0,
    Unit: '',
    UnitPrice: 0,
    SumWithoutVat: 0,
    Vat: 0,
    VatAmount: 0,
    Total: 0,
};

export enum PurchaseOrderHeaderBlocks {
    INVOICES = 'Invoices',
    HISTORY = 'History',
}

export function createRequest(searchValue = '', page = 1, count = 15, columnName = 'AuditDate') {
    const search: HistorySearchParams = {
        SortItems: [
            {
                SortColumn: columnName,
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Count: count,
            Page: page,
        },
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: searchValue || '',
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
    };
    return search;
}

const translateExtraStatus = (status: TypeaheadItem<number>, t: TFunction) => {
    return {
        ...status,
        text: t(`view.PurchaseOrders.ExtraStatus.${status.text}`),
    };
};

export const translateExtraStatuses = (statuses: Array<TypeaheadItem<number>>, t: TFunction) => {
    return statuses.map((element) => translateExtraStatus(element, t));
};

export const validatePurchaseOrderNumberField = async (value: string, resolve: (value?: boolean | Yup.ValidationError | PromiseLike<boolean | Yup.ValidationError>) => void) => {
    try {
        const result = await api.purchaseOrder.isOrderNumberDuplicate(value);
        resolve(!result.data);
    } catch (error) {
        console.error(error);
        resolve(false);
    }
};
