import classNames from 'classnames';
import * as React from 'react';
import { noop } from 'lodash-es';

import Icon, { ICONS } from '../Icon/Icon';

import './Counter.scss';

export interface CounterProps {
    children?: React.ReactNode;
    clickHandler?: (e: React.MouseEvent<HTMLSpanElement>) => void;
    loading?: boolean;
    dataId: string;
    className?: string;
    isRound?: boolean;
}

const Counter: React.FunctionComponent<CounterProps> = ({ children, clickHandler, loading, dataId, className, isRound }: CounterProps) => {
    const classes = classNames('counter', className, { 'counter--loading': loading, 'counter--round': isRound });
    return (
        <span className={classes} data-id={dataId} onClick={loading || !clickHandler ? noop : clickHandler}>
            <span className={classNames('counter__count', { 'counter__count--hover': !!clickHandler })}>{children}</span>
            {!!clickHandler && <Icon className="counter__refresh" name={ICONS.REFRESH} />}
        </span>
    );
};

export default Counter;
