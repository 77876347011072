import React from 'react';
import { TFunction } from 'i18next';

import { InvoiceCustomizationDTO, InvoiceFieldType } from '../../../../../services/types/ApiTypes';
import { Button, ButtonType, ButtonIconPlacement } from '../../../../../components/Buttons/Button';
import { ICONS } from '../../../../../components/Icon/Icon';
import { createDataId } from '../../../../../common/utils/dataId';
import PropertyListItem from '../../../../../components/PropertyListItem/PropertyListItem';
import useDataChunks from '../../../../../common/hooks/useDataChunks';
import { getMaxItemsPerChunk, sortCustomFields } from '../utils';
import { formatDate } from '../../../../../common/utils/formatters';
import { isAuthorized, Role } from '../../../../../common/user/userPermissionUtil';

const localDataId = 'custom-fields';

export const MAX_COLUMNS = 2;

interface CustomFieldsViewModeProps {
    customFields: InvoiceCustomizationDTO[];
    dataId: string;
    isReadOnly: boolean;
    onEdit: () => void | null;
    t: TFunction;
}

const CustomFieldsViewMode = ({ customFields, dataId, isReadOnly, onEdit, t }: CustomFieldsViewModeProps) => {
    const [fieldsChunks] = useDataChunks<InvoiceCustomizationDTO>(sortCustomFields(customFields), getMaxItemsPerChunk(customFields.length, MAX_COLUMNS));

    return (
        <>
            <div className="invoice-header__fields">
                {fieldsChunks.length &&
                    Array.from('l'.repeat(fieldsChunks.length)).map((l, i) => (
                        <ul key={`${localDataId}-column-${i}`}>
                            {fieldsChunks[i].map((f) => (
                                <PropertyListItem
                                    key={`${localDataId}-${f.CustomizationId}`}
                                    dataId={createDataId(dataId, localDataId, f.CustomizationId)}
                                    label={f.Customization.Description}
                                    value={f.Customization.FieldType === InvoiceFieldType.DateTime ? formatDate(f.Value) : f.Value}
                                    hideOverflow={true}
                                />
                            ))}
                        </ul>
                    ))}
            </div>
            {!isAuthorized(Role.CanOnlyViewInvoices) && !!onEdit && !isReadOnly && (
                <div className="text-button-wrapper edit-button">
                    <Button buttonType={ButtonType.ICON_TEXT} dataId="invoice-header.editAdditionalInfo" icon={ICONS.EDIT} iconPlacement={ButtonIconPlacement.LEFT} onClick={onEdit}>
                        {t('component.invoiceHeader.editCustomFields')}
                    </Button>
                </div>
            )}
        </>
    );
};

export default CustomFieldsViewMode;
