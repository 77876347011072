import * as _ from 'lodash-es';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable2 } from 'redux-dispatchers';
import classNames from 'classnames';
import constants from '../../common/constants/appConstants';

import { createDataId } from '../../common/utils/dataId';
import { PagedListContainer } from '../../services/types/ApiTypes';
import { BaseComponent } from '../BaseComponent';
import { Button, ButtonIconPlacement, ButtonType } from '../Buttons/Button';
import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu/DropdownMenu';
import Icon, { IconSize, ICONS } from '../Icon/Icon';
import withSuspense from '../../common/hocs/withSuspense';
import Tooltip from '../Tooltip/Tooltip';

import './Pager.scss';

export interface PagerProps<T> extends WithTranslation {
    pages: PagedListContainer<T>;
    showExtremes?: boolean;
    closedArrows?: boolean;
    onPageChange: Dispatchable2<number, number>;
    hidePageSize?: boolean;
    appendTo?: Element | ((ref: Element) => Element);
    disabled?: boolean;
    tooltipContent?: string;
}

export class Pager<T> extends BaseComponent<PagerProps<T>> {
    render() {
        if (!this.props.pages) {
            return null;
        }
        const padding = 1;
        const currentPage: number = this.props.pages.Skip / this.props.pages.Take;
        const totalPages = Math.ceil(this.props.pages.TotalCount / this.props.pages.Take);

        const startPages = [0];
        const midPages = [];
        const endPages = [totalPages - 1];
        if (currentPage >= startPages.length && currentPage < totalPages - padding) {
            midPages.push(currentPage);
        }
        for (let p = 1; p <= padding; p += 1) {
            if (currentPage - p >= padding && currentPage - p < totalPages - padding) {
                midPages.unshift(currentPage - p);
            }

            if (currentPage + p >= padding && currentPage + p < totalPages - padding) {
                midPages.push(currentPage + p);
            }
        }
        return this.isPagerHidden() ? (
            <Tooltip content={this.props.disabled && this.props.tooltipContent ? this.props.tooltipContent : null} appendTo={this.props.appendTo}>
                <div>{this.renderPageSizeSelect()}</div>
            </Tooltip>
        ) : (
            <Tooltip content={this.props.disabled && this.props.tooltipContent ? this.props.tooltipContent : null} appendTo={this.props.appendTo}>
                <div className={'pager'}>
                    <ul className={classNames('pager__paging', { 'pager--disabled': this.props.disabled })}>
                        {this.props.showExtremes && (
                            <li className="pager__item">
                                <button data-id="pagerBtn.first" className="pager__btn pager__btn--first btn--icon" onClick={this.firstPage} disabled={this.isPreviousDisabled()}>
                                    <Icon name={ICONS.PAGINATOR_ARROW} rotation={180} size={IconSize.SM} />
                                </button>
                            </li>
                        )}
                        <li className="pager__item">
                            <button data-id="pagerBtn.prev" className="pager__btn pager__btn--prev btn--icon" onClick={this.previousPage} disabled={this.isPreviousDisabled()}>
                                {this.props.closedArrows ? <Icon name={ICONS.PAGINATOR_ARROW} rotation={180} size={IconSize.SM} /> : <Icon name={ICONS.BACK} size={IconSize.SM} />}
                            </button>
                        </li>
                        {this.isBigDataSet() ? this.renderBigDataSetPager(startPages, midPages, endPages, currentPage, padding) : _.times(totalPages, (i) => this.renderPagerItem(i, currentPage))}
                        <li className="pager__item">
                            <button data-id="pagerBtn.next" className="pager__btn pager__btn--next btn--icon" onClick={this.nextPage} disabled={this.isNextDisabled()}>
                                {this.props.closedArrows ? <Icon name={ICONS.PAGINATOR_ARROW} size={IconSize.SM} /> : <Icon name={ICONS.BACK} rotation={180} size={IconSize.SM} />}
                            </button>
                        </li>
                        {this.props.showExtremes && (
                            <li className="pager__item">
                                <button data-id="pagerBtn.last" className="pager__btn pager__btn--last btn--icon" onClick={this.lastPage} disabled={this.isNextDisabled()}>
                                    <Icon name={ICONS.PAGINATOR_ARROW} size={IconSize.SM} />
                                </button>
                            </li>
                        )}
                    </ul>
                    {this.renderPageSizeSelect()}
                </div>
            </Tooltip>
        );
    }

    renderPageSizeSelect() {
        const options: number[] = constants.DEFAULT_PAGE_SIZES;
        if (this.props.hidePageSize) {
            return null;
        }
        return (
            <DropdownMenu
                dataId={'pager-per-page-options'}
                appendTo={this.props.appendTo}
                items={
                    <>
                        {options.map((o) => (
                            <DropdownMenuItem
                                key={o}
                                dataId={createDataId('pagerSize', o)}
                                onClick={() => {
                                    this.props.onPageChange(1, o);
                                }}
                            >
                                {o}
                            </DropdownMenuItem>
                        ))}
                    </>
                }
            >
                <Button
                    className={classNames('pager__page-size-select', { 'pager--disabled': this.props.disabled })}
                    dataId="pager-per-page"
                    icon={ICONS.ARROW_DOWN_SMALL}
                    buttonType={ButtonType.ICON}
                    iconPlacement={ButtonIconPlacement.RIGHT}
                >
                    {this.props.pages.Take} {this.props.t('views.global.itemsPerPage')}
                </Button>
            </DropdownMenu>
        );
    }

    renderBigDataSetPager(startPages: number[], midPages: number[], endPages: number[], activePage: number, padding: number) {
        const start = _.map(startPages, (i) => this.renderPagerItem(i, activePage));
        const mid = _.map(midPages, (i) => this.renderPagerItem(i, activePage));
        const end = _.map(endPages, (i) => this.renderPagerItem(i, activePage));
        const result = [];

        result.push(start);
        if (midPages[0] > padding) {
            result.push(this.renderDisabledPagerItem('start'));
        }

        if (activePage > endPages[endPages.length - 1] - 3) {
            const midPagesBonus = [];
            for (let p = activePage - 2; p >= endPages[endPages.length - 1] - 4; p -= 1) {
                midPagesBonus.unshift(p);
            }
            const midBonus = _.map(midPagesBonus, (i) => this.renderPagerItem(i, activePage));
            result.push(midBonus);
        }

        result.push(mid);

        if (activePage < startPages[0] + 3) {
            const midPagesBonus = [];
            for (let p = activePage + 2; p <= startPages[0] + 4; p += 1) {
                midPagesBonus.push(p);
            }
            const midBonus = _.map(midPagesBonus, (i) => this.renderPagerItem(i, activePage));
            result.push(midBonus);
        }

        if (midPages.length && midPages[midPages.length - 1] + padding < endPages[endPages.length - 1]) {
            result.push(this.renderDisabledPagerItem('end'));
        }

        result.push(end);
        return result;
    }

    renderDisabledPagerItem(key: string) {
        return (
            <li className="pager__item" key={key}>
                <button disabled={true} className="pager__btn" data-id="pagerBtn.disable">
                    ...
                </button>
            </li>
        );
    }

    renderPagerItem(index: number, activePage: number) {
        const classes = classNames('pager__btn', {
            'pager__btn--active': activePage === index,
        });
        return (
            <li className="pager__item" key={index}>
                <button onClick={() => this.props.onPageChange(index + 1, this.props.pages.Take)} data-id="pagerBtn.active" className={classes}>
                    {index + 1}
                </button>
            </li>
        );
    }

    isPagerHidden() {
        const totalPages = Math.ceil(this.props.pages.TotalCount / this.props.pages.Take);
        return totalPages < 2;
    }

    isBigDataSet() {
        return Math.ceil(this.props.pages.TotalCount / this.props.pages.Take) > 7;
    }

    isPreviousDisabled() {
        return this.props.pages.Skip / this.props.pages.Take === 0;
    }

    isNextDisabled() {
        const currentPage = this.props.pages.Skip / this.props.pages.Take;
        const totalPages = Math.ceil(this.props.pages.TotalCount / this.props.pages.Take);
        return totalPages === currentPage + 1;
    }

    lastPage = () => {
        const currentPage = Math.ceil(this.props.pages.TotalCount / this.props.pages.Take);
        this.props.onPageChange(currentPage, this.props.pages.Take);
    };

    firstPage = () => {
        const currentPage = 1;
        this.props.onPageChange(currentPage, this.props.pages.Take);
    };

    nextPage = () => {
        const currentPage = this.props.pages.Skip / this.props.pages.Take;
        this.props.onPageChange(currentPage + 2, this.props.pages.Take);
    };

    previousPage = () => {
        const currentPage = this.props.pages.Skip / this.props.pages.Take;
        this.props.onPageChange(currentPage, this.props.pages.Take);
    };
}

export default withSuspense(withTranslation()(Pager));
