(function() {
    'use strict';

    angular
        .module('dstreamApp.directives')
        .directive('doOnInputCancel', doOnInputCancel);

    doOnInputCancel.$inject = [];

    function doOnInputCancel() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: linkFunc
        };

        function linkFunc(scope, element, attrs, ctrl) {
            angular.element(element).on('keydown', onEscKey);

            /*
                Cancel on esc key press
             */
            function onEscKey(event) {
                if(event.keyCode === 27) {
                    scope.$eval(attrs.doOnInputCancel);
                    event.stopPropagation();
                }
            }

            scope.$on('$destroy', function() {
                angular.element(element).off('keydown', onEscKey);
            });
        }
    }
}());
