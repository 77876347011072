'use strict';

import {resetInvoiceFilter} from "../../../src/common/history/InvoiceFilterActions";

angular.module('dstreamApp.components').component('dsSideMenus', {
    templateUrl: 'app/views/side-menus/ds-side-menus.tpl.html',
    controller: DsSideMenusController,
    bindings: {
        showSideMenu: '<',
        header: '<',
        utility: '<',
        userData: '<',
        clearSearchFilter: '<',
        company: '<',
        companies: '<',
        changeCompany: '&',
        localesDisplayNames: '<',
        signOut: '&',
        openChangePassword: '&',
        changeLanguage: '&',
    },
});

DsSideMenusController.$inject = [
    'localStorageService',
    'authenticationService',
    '$ngRedux',
];

function DsSideMenusController(localStorageService, authService, $ngRedux) {
    var ctrl = this;

    ctrl.$onChanges = function (changes) {
        if (changes.company) {
            const { companies, companyGuid } = localStorageService.get('authorizationData');
            ctrl.userCompanies = companies;
            ctrl.currentCompany = ctrl.userCompanies.find((c) => {
                if (c.hasOwnProperty('Guid')) {
                    return c.Guid === companyGuid;
                }
                return c.CompanyGuid === companyGuid;
            });

            ctrl.filteredCompanies = ctrl.userCompanies
                .filter((c) => {
                    if (c.hasOwnProperty('Guid')) {
                        return c.Guid !== ctrl.currentCompany.CompanyGuid && c.Guid !== ctrl.currentCompany.Guid
                    }
                    return c.CompanyGuid !== ctrl.currentCompany.CompanyGuid && c.CompanyGuid !== ctrl.currentCompany.Guid;
                })
                .sort((a, b) => {
                    if (a.hasOwnProperty('Name')) {
                        return a.Name.localeCompare(b.Name);
                    }
                    return a.CompanyName.localeCompare(b.CompanyName);
                });
                
                ctrl.companyName = ctrl.currentCompany.Name || ctrl.currentCompany.CompanyName;
        }
    };

    ctrl.changeActiveCompany = function(id) {
        return ctrl.changeCompany({ id: id });
    };

    ctrl.setActiveCompany = function(companyId) {
        ctrl.changeCompany({id: companyId});
    };

    ctrl.clearFilters = function(path, localStoragePrefix) {
        ctrl.clearSearchFilter(localStoragePrefix ? localStoragePrefix : '');
        $ngRedux.dispatch(resetInvoiceFilter());
    }
}
