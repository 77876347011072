import { TFunction } from 'i18next';
import { isEmpty, maxBy } from 'lodash-es';

import api from '../../services/ApiServices';
import { DefaultTimeRange } from '../../common/utils/datetime';
import { PurchaseOrderStatus, PurchaseOrdersDTO, PurchaseOrderType, Restriction, SearchType, SortDirection, PurchaseOrderExtraStatus, SupplierDTO } from '../../services/types/ApiTypes';
import { TableColumnsConfigurableItem } from '../../components/Table/components/TableColumnsConfigurable/TableColumnsConfigurableContext';
import { TableSettingsTranslations } from '../../components/Table/components/TableColumnsConfigurable/components/TableColumnsSettingsModal/TableSettingsModalView';
import { TableFilter } from '../../components/Table/components/filter/TableFilters';
import { TagSelectItem } from '../../components/TagSelect/TagSelect';
import { StampType } from '../../components/StampLabel/StampLabel';
import { formatDate } from '../../common/utils/formatters';

import { POSearchParams } from './PurchaseOrdersViewReducer';

export const DEFAULT_RESTRICTION = 'GeneralSearch';
export const DATE_RESTRICTION = 'DateCreatedRange';
export const DEFAULT_SORT_COLUMN = 'DateCreated';
export const MIN_TABLE_COLUMN_WIDTH = 71;

export const getColumnName = (columnName: string) => (columnName === 'SupplierId' ? 'Supplier.Name' : columnName);

export function getPurchaseOrdersColumnName(columnName: string, translate?: TFunction): string {
    const columnNames = {
        OrderNumber: 'view.PurchaseOrders.Number',
        Supplier: 'view.PurchaseOrders.Supplier',
        SupplierId: 'view.PurchaseOrders.Supplier',
        SupplierName: 'view.PurchaseOrders.Supplier',
        ['Supplier.Name']: 'view.PurchaseOrders.Supplier',
        Description: 'view.PurchaseOrders.Description',
        DateCreated: 'view.PurchaseOrders.DateCreated',
        OrderStatus: 'view.PurchaseOrders.Status',
        OrderType: 'view.PurchaseOrders.Type',
        NetTotal: 'view.PurchaseOrders.Rows.NetTotal',
        Total: 'view.PurchaseOrders.SumTotal',
    };
    if (translate) {
        return translate(columnNames[columnName]);
    }
    return columnNames[columnName];
}

export function createRequest(searchValue = '', page = 1, count = 15, columnName = DEFAULT_SORT_COLUMN) {
    const search: POSearchParams = {
        SortItems: [
            {
                SortColumn: columnName,
                SortDirection: SortDirection.Desc,
            },
        ],
        PagingOptions: {
            Count: count,
            Page: page,
        },
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: searchValue || '',
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        filters: {},
    };
    return search;
}

export function getStatusKey(status: PurchaseOrderStatus) {
    const statusMap = {
        [PurchaseOrderStatus.New]: 'view.PurchaseOrders.Status.New',
        [PurchaseOrderStatus.Rejected]: 'view.PurchaseOrders.Status.Rejected',
        [PurchaseOrderStatus.Assigned]: 'view.PurchaseOrders.Status.Assigned',
        [PurchaseOrderStatus.Deleted]: 'view.PurchaseOrders.Status.Deleted',
        [PurchaseOrderStatus.Confirmed]: 'view.PurchaseOrders.Status.Confirmed',
    };
    return statusMap[status];
}

export function getStatuses() {
    const statuses: [keyof typeof PurchaseOrderStatus | string, PurchaseOrderStatus | string][] | string = [
        ['New', PurchaseOrderStatus.New],
        ['Assigned', PurchaseOrderStatus.Assigned],
        ['Rejected', PurchaseOrderStatus.Rejected],
        ['Deleted', PurchaseOrderStatus.Deleted],
        ['ExtraStatus.Canceled', `${PurchaseOrderExtraStatus.Canceled}-extra`],
        ['Confirmed', PurchaseOrderStatus.Confirmed],
    ];
    return statuses;
}

export const getStatusStamptype = (status: PurchaseOrderStatus): StampType => {
    switch (status) {
        case PurchaseOrderStatus.New:
            return StampType.INFO;
        case PurchaseOrderStatus.Assigned:
            return StampType.ACTIVE;
        case PurchaseOrderStatus.Confirmed:
            return StampType.APPROVED;
        case PurchaseOrderStatus.Rejected:
            return StampType.ALARM;
        default:
            return StampType.NEUTRAL;
    }
};

export const getSupplierList = async (name: string, currenSupplier?: SupplierDTO) => {
    if (name) {
        const response = await api.suppliers.getSuppliersByName(name);

        if (currenSupplier) {
            const currentSupplierIndex = response?.data?.findIndex((e) => e.Id === currenSupplier.Id);
            if (currentSupplierIndex !== -1) {
                response?.data?.unshift(response?.data?.splice(currentSupplierIndex, 1)[0]);
            }
        }

        return response.data.map(
            (supplier): TagSelectItem<number> => {
                return {
                    value: supplier.Id,
                    text: `${supplier.Name} (${supplier.RegistrationCode})`,
                };
            },
        );
    }
    const response = await api.suppliers.getSuppliers({
        SortItems: [
            {
                SortDirection: SortDirection.Asc,
                SortColumn: 'Name',
            },
        ],
        Restrictions: [],
        PagingOptions: {
            Page: 1,
            Count: 25,
        },
    });
    //display current invoice supplier on top of the list
    if (currenSupplier) {
        const currentSupplierIndex = response?.data?.Items?.findIndex((e) => e.Id === currenSupplier.Id);
        if (currentSupplierIndex !== -1) {
            response?.data?.Items.unshift(response?.data?.Items?.splice(currentSupplierIndex, 1)[0]);
        } else {
            response.data.Items = [currenSupplier, ...response?.data?.Items];
        }
    }
    return Promise.resolve(
        response.data.Items.map(
            (supplier): TagSelectItem<number> => {
                return {
                    value: supplier.Id,
                    text: `${supplier.Name} (${supplier.RegistrationCode})`,
                };
            },
        ),
    );
};

export const getPurchaseOrderExtraStatusOptions = (): Array<TagSelectItem<any>> => {
    const extraStatuses: [keyof typeof PurchaseOrderExtraStatus, PurchaseOrderExtraStatus][] = [
        ['AwaitingFulfillment', PurchaseOrderExtraStatus.AwaitingFulfillment],
        ['PartiallyFulfilled', PurchaseOrderExtraStatus.PartiallyFulfilled],
        ['Fulfilled', PurchaseOrderExtraStatus.Fulfilled],
        ['OverFulfilled', PurchaseOrderExtraStatus.OverFulfilled],
    ];
    return extraStatuses.map(([text, value]) => ({
        value,
        text,
    }));
};

export const getStatusItemsArray = (t: TFunction): Array<TagSelectItem<any>> => {
    const statuses = getStatuses();

    return statuses.map(([key, value]) => {
        const isExtraStatus = key.includes('ExtraStatus');
        const text = isExtraStatus ? `view.PurchaseOrders.ExtraStatus.${key.split('.')[1]}` : `view.PurchaseOrders.Status.${key}`;
        const parent = {
            text: t(text),
            value: value.toString(),
        };
        return {
            ...parent,
            children:
                value === PurchaseOrderStatus.Confirmed
                    ? getPurchaseOrderExtraStatusOptions().map((k) => ({
                          ...k,
                          text: t(`view.PurchaseOrders.ExtraStatus.${k.text}`),
                          parent,
                      }))
                    : null,
        };
    });
};

export const parseRestrictions = (searchParams: POSearchParams, translate: TFunction) => {
    const appliedFilters: Array<TableFilter<any>> = [];
    searchParams.Restrictions.forEach((restriction) => {
        if (restriction.Field !== DEFAULT_RESTRICTION && searchParams.filters[restriction.Field]) {
            const newAppliedFilter: TableFilter<any> = {
                label: getPurchaseOrdersColumnName(restriction.Field, translate),
                columnName: restriction.Field,
                items: searchParams.filters[restriction.Field].items,
                loadItems: searchParams.filters[restriction.Field].loadItems,
                values: searchParams.filters[restriction.Field].values,
                tagSelectType: searchParams.filters[restriction.Field].tagSelectType,
                onSelectChangeCallback: searchParams.filters[restriction.Field].onSelectChangeCallback,
                placeholder: searchParams.filters[restriction.Field].placeholder,
                searchOnFocus: searchParams.filters[restriction.Field].searchOnFocus,
                childColumnName: searchParams.filters[restriction.Field]?.childColumnName,
            };
            if (!!newAppliedFilter.values && !isEmpty(newAppliedFilter.values)) {
                appliedFilters.push(newAppliedFilter);
            }
        }
    });
    return appliedFilters;
};

export const parseFilters = (filter: Omit<TableFilter<any>, 'onSelectChangeCallback' | 'tagSelectType'>): Restriction => {
    const appliedFilter: Restriction = {
        Field: filter.columnName,
        Value: null,
        Values: null,
        FieldSearchType: SearchType.NotSelected,
    };
    if (filter && filter.values.length > 1) {
        appliedFilter.Value = null;
        appliedFilter.Values = filter.values.map((val) => val.value);
    }
    if (filter && filter.values.length === 1) {
        appliedFilter.Values = null;
        appliedFilter.Value = filter.values[0].value;
    }
    return appliedFilter;
};

export const getTypeKey = (status: PurchaseOrderType) => {
    const statusMap = {
        [PurchaseOrderType.Capex]: 'view.PurchaseOrders.Type.Capex',
        [PurchaseOrderType.Opex]: 'view.PurchaseOrders.Type.Opex',
        [PurchaseOrderType.Unknown]: 'view.PurchaseOrders.Type.Unknown',
    };
    return statusMap[status];
};

export const getStatusData = (po: PurchaseOrdersDTO) => {
    const assignees = po.CurrentlyAssignedUsers ? po.CurrentlyAssignedUsers.map((u) => u.FirstName + ' ' + u.LastName).join(', ') : '';
    const statusDataMap = {
        [PurchaseOrderStatus.New]: '',
        [PurchaseOrderStatus.Assigned]: `(${assignees})`,
        [PurchaseOrderStatus.Rejected]: `(${formatDate(maxBy(po.PurchaseOrderTasks, (taskItem) => taskItem?.OrderNo)?.ModifiedDate || po.DateCreated)})`, // should return po.RejectedDate, but this data does not exist yet
        [PurchaseOrderStatus.Confirmed]: `(${formatDate(maxBy(po.PurchaseOrderTasks, (taskItem) => taskItem?.OrderNo)?.ModifiedDate || po.DateCreated)})`, // should return po.ApprovedDate, but this data does not exist yet
        [PurchaseOrderStatus.Deleted]: `(${formatDate(maxBy(po.PurchaseOrderTasks, (taskItem) => taskItem?.OrderNo)?.ModifiedDate || po.DateCreated)})`, // should return po.DeletedDate, but this data does not exist yet
    };
    return statusDataMap[po.OrderStatus];
};

export const getDecisionDate = (po: PurchaseOrdersDTO) => {
    const modifiedDate = formatDate(po.DecisionDate);
    const emptyCell = '-';
    const statusDataMap = {
        [PurchaseOrderStatus.New]: emptyCell,
        [PurchaseOrderStatus.Assigned]: emptyCell,
        [PurchaseOrderStatus.Rejected]: modifiedDate,
        [PurchaseOrderStatus.Confirmed]: modifiedDate,
        [PurchaseOrderStatus.Deleted]: modifiedDate,
    };
    return statusDataMap[po.OrderStatus];
};

export const getApprovers = (po: PurchaseOrdersDTO): string => {
    const assignedUsers = po.CurrentlyAssignedUsers;
    if (!assignedUsers?.length) {
        return '-';
    }
    return assignedUsers.map((user) => `${user.FirstName} ${user.LastName}`).join(', ');
};

export const PO_COLUMN_ID = {
    ORDER_NUMBER: 'OrderNumber',
    SUPPLIER_NAME: 'Supplier.Name',
    ORDER_STATUS: 'OrderStatus',
    DECISION_DATE: 'DecisionDate',
    APPROVER: 'Approver',
    DESCRIPTION: 'Description',
    DATE_CREATED: 'DateCreated',
    REMAINING_SUM: 'RemainingSum',
    TYPE: 'OrderType',
    NET_TOTAL: 'TotalWithoutVat',
    TOTAL: 'Total',
    LINKED_INVOICES: 'LinkedInvoices',
    FULFILMENT: 'Fulfilment',
    CREATED_BY: 'CreatedBy',
} as const;

export const translationsKeys: Record<typeof PO_COLUMN_ID[keyof typeof PO_COLUMN_ID], TableSettingsTranslations> = {
    [PO_COLUMN_ID.ORDER_NUMBER]: { title: 'view.PurchaseOrders.Number' },
    [PO_COLUMN_ID.SUPPLIER_NAME]: { title: 'view.PurchaseOrders.Supplier' },
    [PO_COLUMN_ID.ORDER_STATUS]: { title: 'view.PurchaseOrders.Status' },
    [PO_COLUMN_ID.DECISION_DATE]: { title: 'view.PurchaseOrders.DecisionDate', description: 'view.PurchaseOrders.TableSettings.DecisionDate.Description' },
    [PO_COLUMN_ID.APPROVER]: { title: 'view.PurchaseOrders.Approver', description: 'view.PurchaseOrders.TableSettings.Approver.Description' },
    [PO_COLUMN_ID.DESCRIPTION]: { title: 'view.PurchaseOrders.Description' },
    [PO_COLUMN_ID.DATE_CREATED]: { title: 'view.PurchaseOrders.DateCreated', description: 'view.PurchaseOrders.TableSettings.CreatedDate.Description' },
    [PO_COLUMN_ID.REMAINING_SUM]: { title: 'view.PurchaseOrders.RemainingSum', description: 'view.PurchaseOrders.TableSettings.RemainingSum.Description' },
    [PO_COLUMN_ID.TYPE]: { title: 'view.PurchaseOrders.Type' },
    [PO_COLUMN_ID.NET_TOTAL]: { title: 'view.PurchaseOrders.Rows.NetTotal' },
    [PO_COLUMN_ID.TOTAL]: { title: 'view.PurchaseOrders.SumTotal' },
    [PO_COLUMN_ID.LINKED_INVOICES]: { title: 'view.PurchaseOrders.LinkedInvoices', description: 'view.PurchaseOrders.TableSettings.LinkedInvoices.Description' },
    [PO_COLUMN_ID.FULFILMENT]: { title: 'view.PurchaseOrders.Fulfilment', description: 'view.PurchaseOrders.TableSettings.Fulfilment.Description' },
    [PO_COLUMN_ID.CREATED_BY]: { title: 'view.PurchaseOrders.CreatedBy', description: 'view.PurchaseOrders.TableSettings.CreatedBy.Description' },
};

export const defaultPOColumns: TableColumnsConfigurableItem[] = [
    {
        id: PO_COLUMN_ID.ORDER_NUMBER,
        isVisible: true,
        isFrozen: true,
        width: 120,
    },
    {
        id: PO_COLUMN_ID.SUPPLIER_NAME,
        isVisible: true,
        isFrozen: false,
        width: 200,
    },
    {
        id: PO_COLUMN_ID.DESCRIPTION,
        isVisible: true,
        isFrozen: false,
        width: 400,
    },
    {
        id: PO_COLUMN_ID.DATE_CREATED,
        isVisible: true,
        isFrozen: false,
        width: 105,
    },
    {
        id: PO_COLUMN_ID.CREATED_BY,
        isVisible: true,
        isFrozen: false,
        width: 120,
    },
    {
        id: PO_COLUMN_ID.ORDER_STATUS,
        isVisible: true,
        isFrozen: false,
        width: 95,
    },
    {
        id: PO_COLUMN_ID.DECISION_DATE,
        isVisible: true,
        isFrozen: false,
        width: 123,
    },
    {
        id: PO_COLUMN_ID.APPROVER,
        isVisible: true,
        isFrozen: false,
        width: 120,
    },
    {
        id: PO_COLUMN_ID.FULFILMENT,
        isVisible: true,
        isFrozen: false,
        width: 175,
    },
    {
        id: PO_COLUMN_ID.REMAINING_SUM,
        isVisible: true,
        isFrozen: false,
        width: 120,
    },
    {
        id: PO_COLUMN_ID.NET_TOTAL,
        isVisible: true,
        isFrozen: false,
        width: 120,
    },
    {
        id: PO_COLUMN_ID.TOTAL,
        isVisible: true,
        isFrozen: false,
        width: 120,
    },
    {
        id: PO_COLUMN_ID.LINKED_INVOICES,
        isVisible: true,
        isFrozen: false,
        width: 109,
    },
    {
        id: PO_COLUMN_ID.TYPE,
        isVisible: true,
        isFrozen: false,
        width: 69,
    },
];

type DefaultTimeRangeLabels = Record<DefaultTimeRange, string>;

export const getRangeLabel = (range: DefaultTimeRange, t: TFunction) => {
    const defaultTimeRangeLabels: DefaultTimeRangeLabels = {
        [DefaultTimeRange.TODAY]: t('component.invoiceFilter.today'),
        [DefaultTimeRange.YESTERDAY]: t('component.invoiceFilter.yesterday'),
        [DefaultTimeRange.TOMORROW]: t('component.invoiceFilter.tomorrow'),
        [DefaultTimeRange.THIS_WEEK]: t('component.invoiceFilter.thisWeek'),
        [DefaultTimeRange.LAST_WEEK]: t('component.invoiceFilter.lastWeek'),
        [DefaultTimeRange.NEXT_WEEK]: t('component.invoiceFilter.nextWeek'),
        [DefaultTimeRange.THIS_MONTH]: t('component.invoiceFilter.currentMonth'),
        [DefaultTimeRange.LAST_MONTH]: t('component.invoiceFilter.lastMonth'),
        [DefaultTimeRange.NEXT_MONTH]: t('component.invoiceFilter.nextMonth'),
        [DefaultTimeRange.LAST_90_DAYS]: `${t('component.invoiceFilter.last')} 90 ${t('component.invoiceFilter.days')}`,
        [DefaultTimeRange.LAST_180_DAYS]: `${t('component.invoiceFilter.last')} 180 ${t('component.invoiceFilter.days')}`,
        [DefaultTimeRange.THIS_YEAR]: t('component.invoiceFilter.thisYear'),
        [DefaultTimeRange.LAST_YEAR]: t('component.invoiceFilter.lastYear'),
        [DefaultTimeRange.CUSTOM]: t('component.invoiceFilter.customRange'),
    };
    return defaultTimeRangeLabels?.[range];
};
