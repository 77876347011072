import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Icon, { ICONS } from '../Icon/Icon';
import withSuspense from '../../common/hocs/withSuspense';

import './RequiredFieldsMessage.scss';

export interface RequiredFieldsMessageProps extends WithTranslation {
    children?: React.ReactNode;
}

const RequiredFieldsMessage: React.StatelessComponent<RequiredFieldsMessageProps> = ({ t }: RequiredFieldsMessageProps) => {
    return (
        <p className="required-fields-message">
            <Icon className="required-fields-message__icon" name={ICONS.INFO} />
            <span>{t('component.RequiredFieldsMessage.Message')}</span>
        </p>
    );
};

export default withSuspense(withTranslation()(RequiredFieldsMessage));
