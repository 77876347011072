import { replace } from 'connected-react-router';
import { createAction } from 'redux-actions';

import { paths, resolvePathParams } from '../../common/router/routePaths';
import { notify } from '../../common/utils/notify';
import { loadableDataActions, loadableDataActionsWithRequest } from '../../common/utils/LoadableData';
import i18nInstance from '../../i18n';
import { GlobalState } from '../../rootReducer';
import { BaseSearch, CostObjectiveItemsCountForUserDTO, CostObjectiveType, DimensionItemUnion, GroupMemberDTO, PagedListContainer, SearchType, SortDirection } from '../../services/types/ApiTypes';
import api from '../../services/ApiServices';
import { DispatchThunk } from '../../storeConfig';
import { getCurrentUserGroupMember } from '../../common/user/UserActions';
import { selectActiveCostObjective, selectCostObjectivesLoadable, selectDimensionsLoadable } from './UserAddViewReducer';

const ns = 'user-add/';

export const getUserDataLoadableActions = loadableDataActions<string, GroupMemberDTO>(`${ns}GET_USER_DATA`);
export const saveUserLoadableActions = loadableDataActions<boolean, boolean>(`${ns}SAVE_USER`);

export function getUserData(userGuid: string) {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            dispatch(getUserDataLoadableActions.request(userGuid));
            response = await api.user.getGroupMember(userGuid);
            dispatch(getUserDataLoadableActions.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(getUserDataLoadableActions.error(e));
        }
    };
}
export const getCostObjectivesLoadableActions = loadableDataActions<string, CostObjectiveItemsCountForUserDTO[]>(`${ns}GET_COST_OBJECTIVES`);

export function getCostObjectives(userGuid: string) {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            dispatch(getCostObjectivesLoadableActions.request(userGuid));
            response = await api.user.getCostObjectiveItemsCountForUser(userGuid);
            const account = response.data.find((r) => r.ObjectType === CostObjectiveType.Account);
            if (account) {
                account.Name = i18nInstance.t('controller.accountListController.Account');
            }
            const vatCode = response.data.find((r) => r.ObjectType === CostObjectiveType.VatCode);
            if (vatCode) {
                vatCode.Name = i18nInstance.t('controller.accountListController.VAT_code');
            }
            dispatch(getCostObjectivesLoadableActions.success(response.data.sort((a, b) => a.OrderNo - b.OrderNo)));
        } catch (e) {
            console.error(e);
            dispatch(getCostObjectivesLoadableActions.error(e));
        }
    };
}

export const getDimensionsLoadableActions = loadableDataActionsWithRequest<BaseSearch, PagedListContainer<DimensionItemUnion>>(`${ns}GET_DIMENSIONS`);

export const setPagingOptions = (page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState): Promise<any> => {
        const state = getState();
        const paging = state.userAdd.searchParams.PagingOptions;
        const searchParams: BaseSearch = {
            ...state.userAdd.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        return dispatch(searchDimensions(searchParams));
    };
};

export function searchDimensions(searchParams?: BaseSearch | undefined) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const activeCostObjectiveType = selectActiveCostObjective(state);
        searchParams = searchParams || state.userAdd.searchParams;
        const searchRestriction = searchParams.Restrictions[0];

        const restrictions = [];
        restrictions.push(searchRestriction);
        restrictions.push({
            Field: 'UserGuid',
            Value: state.userAdd.userGuid,
            FieldSearchType: SearchType.NotSelected,
            Values: undefined,
        });
        restrictions.push({
            Field: 'ObjectType',
            Value: activeCostObjectiveType.ObjectType.toString(),
            FieldSearchType: SearchType.NotSelected,
            Values: undefined,
        });

        if (activeCostObjectiveType.ObjectType === CostObjectiveType.CustomCostObjective) {
            restrictions.push({
                Field: 'CustomCostObjectiveId',
                Value: activeCostObjectiveType.Id,
                FieldSearchType: SearchType.NotSelected,
                Values: undefined,
            });
        }
        searchParams = {
            ...searchParams,
            Restrictions: restrictions,
        };
        let response;
        try {
            dispatch(getDimensionsLoadableActions.request(searchParams));
            response = await api.user.getCostObjectiveItemsForUser(searchParams);
            dispatch(
                getDimensionsLoadableActions.success({
                    request: searchParams,
                    result: response.data,
                }),
            );
            // if our page for some reason is empty, but there is data on previous pages, then load previous pages until we have some data to display
            if (response.data.Items.length === 0 && response.data.HasCount) {
                const currentPage = state.userAdd.searchParams.PagingOptions.Page;
                if (currentPage > 1) {
                    return dispatch(setPagingOptions(currentPage - 1));
                }
            }
            return Promise.resolve();
        } catch (e) {
            console.error(e);
            dispatch(
                getDimensionsLoadableActions.error({
                    request: searchParams,
                    result: e,
                }),
            );
            return Promise.resolve();
        }
    };
}

export const filterDimensions = (searchString: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.userAdd.searchParams.PagingOptions;
        const searchRestriction = state.userAdd.searchParams.Restrictions[0];
        const searchParams: BaseSearch = {
            ...state.userAdd.searchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: [
                {
                    ...searchRestriction,
                    Value: searchString,
                },
            ],
        };
        dispatch(searchDimensions(searchParams));
    };
};

export const sortDimensions = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const sorting = state.userAdd.searchParams.SortItems[0];
        const searchParams: BaseSearch = {
            ...state.userAdd.searchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };
        dispatch(searchDimensions(searchParams));
    };
};

export const save = (user: GroupMemberDTO, forceFetchUser?: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            let result;
            if (user.Id) {
                dispatch(saveUserLoadableActions.request(true));
                result = await api.user.editGroupMember(user);
                if (result.data && result.data && result.data.Error) {
                    dispatch(saveUserLoadableActions.error(Error(i18nInstance.t(result.data.Error))));
                    notify.error(i18nInstance.t(result.data.Error));
                } else {
                    dispatch(saveUserLoadableActions.success(true));
                    if (forceFetchUser) {
                        dispatch(getUserData(result.data.BOGuid));
                    }
                    if (getState().user.groupMemberCommonLoadable.payload.Id === user.Id) {
                        dispatch(getCurrentUserGroupMember(true));
                    }
                }
            } else {
                result = await api.user.addGroupMember(user);
                if (result.data.Error) {
                    notify.error(i18nInstance.t(result.data.Error));
                } else {
                    notify.success(i18nInstance.t('views.groupMember.detail.UserAdded'));
                    dispatch(replace(resolvePathParams(paths.app.userDetails, { id: result.data.Id })));
                }
            }
        } catch (e) {
            console.error(e);
            dispatch(saveUserLoadableActions.error(Error(i18nInstance.t('interceptorsFactory.ErrorWhileSendingData'))));
        }
    };
};

export const deleteSubstitute = (id: number) => {
    return async () => {
        try {
            await api.user.deleteSubstitute(id);
        } catch (e) {
            console.error(e);
        }
    };
};

export const toggleCostObjectiveItemForUserAction = loadableDataActions<number, number>(`${ns}TOGGLE_COST_OBJECTIVE_ITEM`);

export const toggleCostObjectiveItemForUser = (objectId: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        let response;
        try {
            const userGuid = state.userAdd.userGuid;
            const objectType = selectActiveCostObjective(state).ObjectType;
            dispatch(toggleCostObjectiveItemForUserAction.request(objectId));
            response = await api.user.toggleCostObjectiveItemForUser(userGuid, objectType, objectId);
            dispatch(toggleCostObjectiveItemForUserAction.success(response.data));
        } catch (e) {
            console.error(e);
        }
    };
};

export const setAllCostObjectiveItemsOnPageForUser = (value: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        try {
            const userGuid = state.userAdd.userGuid;
            const activeCostObjective = selectActiveCostObjective(state);
            const dimensionIds = selectDimensionsLoadable(state)
                .payload.Items.filter((item) => {
                    return item.AssignedCurrentToUser !== value;
                })
                .map((item) => item.Id);
            const ccoId = activeCostObjective.ObjectType === CostObjectiveType.CustomCostObjective ? activeCostObjective.Id : null;
            // unlike single toggle, this response contains amount of changed items
            const response = await api.user.bulkToggleCostObjectiveItemsForUser(value, userGuid, activeCostObjective.ObjectType, dimensionIds, ccoId);
            const lastCount = selectCostObjectivesLoadable(state).payload.find((co) => co.Id === activeCostObjective.Id && co.ObjectType === activeCostObjective.ObjectType).Count;
            const newCount = value ? lastCount + response.data : lastCount - response.data;
            dispatch(toggleCostObjectiveItemForUserAction.success(newCount));
            dispatch(searchDimensions());
        } catch (e) {
            console.error(e);
        }
    };
};

export const setAllCostObjectiveItemsForUser = (value: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        try {
            const userGuid = state.userAdd.userGuid;
            const activeCostObjective = selectActiveCostObjective(state);
            const ccoId = activeCostObjective.ObjectType === CostObjectiveType.CustomCostObjective ? activeCostObjective.Id : null;
            // unlike single toggle, this response contains amount of changed items
            const response = await api.user.bulkToggleCostObjectiveItemsForUser(value, userGuid, activeCostObjective.ObjectType, undefined, ccoId);
            const lastCount = selectCostObjectivesLoadable(state).payload.find((co) => co.Id === activeCostObjective.Id && co.ObjectType === activeCostObjective.ObjectType).Count;
            const newCount = value ? lastCount + response.data : lastCount - response.data;
            dispatch(toggleCostObjectiveItemForUserAction.success(newCount));
            dispatch(searchDimensions());
        } catch (e) {
            console.error(e);
        }
    };
};

export const setActiveCostObjective = createAction<CostObjectiveItemsCountForUserDTO>(`${ns}SET_ACTIVE_COST_OBJECTIVE`);
