(function() {
    'use strict';

    angular
        .module('dstreamApp.views.archive-details')
        .component('dsStaticInvoiceInformation', {
            templateUrl: 'app/views/archive-details/components/static-invoice-information/static-invoice-information.html',
            controller: StaticInvoiceInformation,
            bindings: {
                invoice: '<',
                invoiceLoading: '<'
            }
        });

    StaticInvoiceInformation.$inject = ['constants'];

    function StaticInvoiceInformation(constants) {
        var ctrl = this;
        /* Controller variables */
        ctrl.visible = false;
        ctrl.getInvoiceStatusTranslateKey = constants.getInvoiceStatusTranslateKey;

        /* Controller methods */
        ctrl.toggleComponent = toggleComponent;

        /*
            Expand/Collapse element
         */
        function toggleComponent() {
            ctrl.visible = !ctrl.visible;
        }
    }
})();