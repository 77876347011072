import ngRedux from 'ng-redux';
import storeAndPersistor from "../src/storeConfig";
import { paths } from '../src/common/router/routePaths';

(function () {
  "use strict";

  var app = angular.module("dstreamApp", [
    "dstreamApp.core",
    "dstreamApp.controllers",
    "dstreamApp.filters",
    "dstreamApp.services",
    "dstreamApp.directives",
    "dstreamApp.components",
    "dstreamApp.views",
    "pascalprecht.translate",
    "dstreamApp.reactBridge",
    ngRedux
  ]).config([
    "$analyticsProvider",
    "$locationProvider",
    "$cssProvider",
    "customRouteProvider",
    "$ngReduxProvider",
    function ($analyticsProvider, $locationProvider, $cssProvider, customRouteProvider, $ngReduxProvider) {
      $("input").attr("autocomplete", "off");
      angular.extend($cssProvider.defaults, {
        method: "prepend"
      });
      $locationProvider.hashPrefix("");
      $ngReduxProvider.provideStore(storeAndPersistor.store);
      customRouteProvider.when(paths.backOffice.default, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.backOffice.boUsers, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.backOffice.integrators, {
        access: {
          requiresLogin: true
        }
      });
      customRouteProvider.when(paths.backOffice.domains, {
        access: {
          requiresLogin: true
        }
      });
      customRouteProvider.when(paths.backOffice.resellers, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.backOffice.organizations, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.backOffice.companies, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.backOffice.company, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.backOffice.users, {
        access: {
            requiresLogin: true
        }
      });
      customRouteProvider.when(paths.backOffice.reports, {
        access: {
            requiresLogin: true
        }
      });
      customRouteProvider.when(paths.backOffice.mailboxDetails, {
        access: {
            requiresLogin: true
        }
      });
      customRouteProvider.when(paths.app.dashboard, {
        templateUrl: "Views/Home/Dashboard.html",
        controller: "dashboardController",
        reloadOnSearch: false,
        access: {
          requiresLogin: true
        }
      });
      customRouteProvider.when("/invoiceconfirmation", {
        templateUrl: "Views/Invoice/InvoiceConfirmation.html",
        controller: "invoiceConfirmationController",
        css: [
          PublicUrl + "/app/css/bootstrap.3.2.0.css?" + CurrentVersion,
          PublicUrl + "/app/css/green-blue.css?" + CurrentVersion,
          PublicUrl + "/app/css/Site.css?" + CurrentVersion
        ],
        access: {
          requiresLogin: true
        }
      });
      customRouteProvider.when("/invoiceconfirmation/:id", {
        templateUrl: "Views/Invoice/InvoiceConfirmation.html",
        controller: "invoiceConfirmationController",
        css: [
          PublicUrl + "/app/css/bootstrap.3.2.0.css?" + CurrentVersion,
          PublicUrl + "/app/css/green-blue.css?" + CurrentVersion,
          PublicUrl + "/app/css/Site.css?" + CurrentVersion
        ],
        access: {
          requiresLogin: true
        }
      });
      customRouteProvider.when("/invoicecustomfields", {
        templateUrl: "app/components/invoiceCustomFields/InvoiceCustomFieldsList.html",
        controller: "invoiceCustomFieldsListController",
        css: [
          PublicUrl + "/app/css/bootstrap.3.2.0.css?" + CurrentVersion,
          PublicUrl + "/app/css/green-blue.css?" + CurrentVersion,
          PublicUrl + "/app/css/Site.css?" + CurrentVersion
        ],
        access: {
          requiresLogin: true
        }
      });

      customRouteProvider.when("/relateddimensions", {
        access: {
          requiresLogin: true
        }
      });

      customRouteProvider.when("/purchase-orders", {
        access: {
          requiresLogin: true
        }
      });

      customRouteProvider.when("/purchase-orders/add", {
        access: {
          requiresLogin: true
        }
      });
      customRouteProvider.when("/purchase-orders/details/:id", {
        access: {
          requiresLogin: true
        }
      });
      customRouteProvider
        .when("/automation", {
          access: {
            requiresLogin: true
          }
        })
        .when("/auto-transactions/add/", {
          access: {
            requiresLogin: true
          }
        })
        .when("/auto-transactions/add/:id", {
          access: {
            requiresLogin: true
          }
        })
        .when("/auto-transactions/details/:id", {
        access: {
          requiresLogin: true
        }
      });

      customRouteProvider.when(paths.app.accounting, {
        access: {
          requiresLogin: true
        }
      });
      customRouteProvider.when(paths.app.costObjectiveDetails, {
        access: {
          requiresLogin: true
        }
      });
      customRouteProvider.when(paths.app.companySettings, {
        access: {
            requiresLogin: true
        }
      });
      customRouteProvider.when(paths.app.vatCodeDetails, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.app.accountDetails, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.app.workflows, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.app.workflowsAdd, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.app.workflowDetails, {
          access: {
              requiresLogin: true
          }
      });

      customRouteProvider.when(paths.app.productItems, {
          access: {
              requiresLogin: true
          }
      });

      customRouteProvider.when(paths.app.documentTypes, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.app.paymentMethods, {
        access: {
            requiresLogin: true
        }
      });
      customRouteProvider.when(paths.app.headerExtensions, {
        access: {
            requiresLogin: true
        }
      });
      customRouteProvider.when(paths.app.userManagement, {
          access: {
              requiresLogin: true
          }
      });

      customRouteProvider.when(paths.app.addUser, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.app.userDetails, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when(paths.app.suppliers, {
          access: {
              requiresLogin: true
          }
      });
      customRouteProvider.when("/", {
        access: {
            requiresLogin: true
        },
        redirectTo: "dashboard"
      });

      // if development, then don't track analytics
      if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
        $analyticsProvider.developerMode(true);
      }
    }
  ])
    .config(["localStorageServiceProvider", function (localStorageServiceProvider) {
    }])
    .config([
      "$translateProvider",
      function ($translateProvider) {
        $translateProvider.useStaticFilesLoader({
          prefix: `${window.publicUrl}/app/resources/locale-`,        // path to translations files
          suffix: ".json?v=" + BUILD_TIME                                     // suffix, currently- extension of the translations
        });
        $translateProvider.use("en_US");
        $translateProvider.fallbackLanguage([
          "en_US",
        ]);// set fallback languages to use when no key is found in translation file
        $translateProvider.useLocalStorage();                   // saves selected language to localStorage
        //$translateProvider.useMissingTranslationHandlerLog(); //To get warnings in the developer console, regarding forgotten IDs in translations
        $translateProvider.useSanitizeValueStrategy("escapeParameters");
      }
    ])
    .config([
      "$httpProvider",
      function ($httpProvider) {
        $httpProvider.interceptors.push("interceptorsFactory");         // adding inteceptor to show query loading notification and error notification
        $httpProvider.interceptors.push("authenticationInterceptor");   // adding authentication interceptor for adding token into each request header
      }
    ])
    .constant("LOCALES", {
      "locales": {
        "cs_CZ": "Česky",
        "de_DE": "Deutsch",
        "et_EE": "Eesti",
        "en_US": "English",
        "fi_FI": "Suomi",
        "fr_FR": "Français",
        "hr_HR": "Hrvatski",
        "lv_LV": "Latviešu",
        "lt_LT": "Lietuvių",
        "hu_HU": "Magyar",
        "nl_NL": "Nederlands",
        "pl_PL": "Polski",
        "ro_RO": "Română",
        "sr_SL": "Srpski",
        "sk_SK": "Slovenčina",
        "bg_BG": 'Български',
        "ru_RU": "Русский",
        "sr_SC": "Српски",
      },
      "preferredLocale": "et_EE"
    });

  angular.module("dstreamApp").value("$", $);
  angular.module("dstreamApp").factory("_", function () {
    return window._; // assumes underscore has already been loaded on the page
  });
})();