import {SortDirection} from "../../src/services/types/ApiTypes";

(function () {
    'use strict';

    angular
        .module('dstreamApp.directives')
        .directive('sort', sort);

    /* @ngInject */
    function sort() {
        return {
            restrict: 'A',
            scope: {
                sortings: '=',
                order: '@',
                onSearch: '&',
            },
            link: linkFunc
        };

        function linkFunc(scope, element) {
            var currentSorting = [];
            var sortingsWatcher;

            if (scope.order) {
                currentSorting = _.find(scope.sortings, function (t) {
                    return t.SortColumn === scope.order
                });

                // add classes and click event
                element.addClass('d-sortable');
                element.bind('click', onColumnClick);

                // set initial class on active sorting column
                if (currentSorting && scope.order === currentSorting.SortColumn) {
                    setSortClass(element, currentSorting.SortDirection)
                }

                // listen to changes in filterObject.sortItems
                sortingsWatcher = scope.$watch('sortings', function (newVal, oldVal) {
                    currentSorting = _.find(newVal, function (t) {
                        return t.SortColumn === scope.order
                    });
                    if (currentSorting && scope.order === currentSorting.SortColumn) {
                        setSortClass(element, currentSorting.SortDirection)
                    } else {
                        removeSortClass(element);
                    }
                }, true);
            }

            function removeSortClass(element) {
                element[0].classList.remove('desc', 'asc');
            }

            /*
                      Change sortBy class
                   */
            function setSortClass(element, direction) {
                removeSortClass(element);
                let sortDirection = direction === SortDirection.Asc ? 'asc' : 'desc';
                element[0].classList.add(sortDirection);
            }

            /*
                      Function to run on column sort click. Selects the column as currently sortable or changes the sorting direction on the same column
                   */
            function onColumnClick() {
                scope.$apply(function () {
                    var newDirection = SortDirection.Asc;

                    if (currentSorting && currentSorting.SortColumn === scope.order) {
                        newDirection = currentSorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
                    }

                    setSortClass(element, newDirection);

                    scope.sortings[0].SortColumn = angular.copy(scope.order);
                    scope.sortings[0].SortDirection = angular.copy(newDirection);

                    scope.onSearch({
                        sortColumn: scope.order,
                        sortDirection: newDirection,
                    });
                })
            }

            /*
                      On component destroy
                   */
            scope.$on('$destroy', function () {
                element.unbind('click');
                if (sortingsWatcher) sortingsWatcher()
            })
        }
    }
})();
