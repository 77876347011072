import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionDTO } from 'src/services/types/ApiTypes';
import Icon, { ICONS, IconSize } from '../../../../components/Icon/Icon';
import { getBrowserIconName } from './utils';

interface Props {
    dataId: string;
    session: SessionDTO;
    terminateSession: (i: number) => void;
}

type SessionInfo = {
    device: string;
    appVersion: string | null;
    ipAddress: string | null;
    location: string | null;
};

const UserSessionInfo = (props: Props) => {
    const { t } = useTranslation();
    const { dataId, terminateSession, session } = props;

    const sessionInfo: SessionInfo | null = useMemo(() => {
        if (!session) {
            return null;
        }
        const { AppVersion, BrowserVersion, BrowserName, LoginIP, Location, DeviceName, OSName } = session;
        const { Country, Region } = Location;
        return {
            device: !DeviceName && !OSName ? null : DeviceName || '' + OSName || '',
            appVersion: AppVersion ? `v${AppVersion}` : BrowserName && BrowserVersion ? BrowserName + ' ' + BrowserVersion : BrowserName ? BrowserName : BrowserVersion ? BrowserVersion : null,
            ipAddress: LoginIP,
            location: Region && Country ? '| ' + Region + ', ' + Country : '' || Country ? '| ' + Country : null,
        };
    }, [session]);

    return (
        !!session && (
            <div key={`session-${session.Id}`} className="session-container">
                <div className="session-icon">
                    <Icon name={getBrowserIconName(session.BrowserName, session.OSName)} size={IconSize.MD} />
                </div>
                <div className="session-body">
                    <span className="session-body__header">{sessionInfo.device || t('views.global.UserProfileModal.Sessions.NoData')}</span>
                    <span className="session-body__info">
                        <span>{sessionInfo?.appVersion}</span>
                        <span>
                            {sessionInfo?.ipAddress}
                            {sessionInfo?.location}
                        </span>
                    </span>
                </div>
                {!session.IsCurrent && (
                    <div className="session-action" onClick={() => terminateSession(session.Id)}>
                        <Icon name={ICONS.DELETE} data-id={`${dataId}.icon`} />
                    </div>
                )}
            </div>
        )
    );
};

export default UserSessionInfo;
