import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import withSuspense from '../../../../../common/hocs/withSuspense';
import { createDataId } from '../../../../../common/utils/dataId';
import { Button, ButtonIconPlacement, ButtonType } from '../../../../../components/Buttons/Button';
import { ICONS } from '../../../../../components/Icon/Icon';
import { TextInput, TextInputType } from '../../../../../components/TextInput/TextInput';
import { TypeaheadAsync, TypeaheadItem } from '../../../../../components/Typeahead/TypeaheadAsync';
import { InvoiceRowDTO, ProductItemDTO } from '../../../../../services/types/ApiTypes';
import { getProductItems } from '../InvoiceRowsHelper';

export interface CollapseContentProps extends WithTranslation {
    invoiceRow: InvoiceRowDTO;
    saveProductId: (invoiceRow: InvoiceRowDTO) => void;
    canSaveInvoice: boolean;
    activeEditRowId: number;
}

export interface CollapseContentFields {
    FirstName: string;
    LastName: string;
    Country: string;
    RegistrationNumber: string;
    CompanyName: string;
    VATCode: string;
    Email: string;
}

const CollapseContent = (props: CollapseContentProps) => {
    const { invoiceRow, canSaveInvoice, saveProductId, activeEditRowId, t } = props;

    const [isSellerIdEditting, setIsSellerIdEditting] = React.useState(false);
    const [sellerProductId, setSellerProductId] = React.useState<string>(null);
    const [isBuyerIdEditting, setIsBuyerIdEditting] = React.useState(false);
    const [buyerProductId, setBuyerProductId] = React.useState<TypeaheadItem<{ FullName: string; Code: string }>>(null);

    const dataId = 'invoiceRowCollapseContent';

    useEffect(() => {
        if (invoiceRow?.SellerProductId !== sellerProductId) {
            setSellerProductId(invoiceRow?.SellerProductId || null);
        }
        if (invoiceRow?.BuyerProductId !== buyerProductId?.value?.Code) {
            setBuyerProductId({
                value: { FullName: invoiceRow?.BuyerProductName, Code: invoiceRow?.BuyerProductId },
                text: invoiceRow?.BuyerProductName || null,
            });
        }
    }, [invoiceRow]);

    useEffect(() => {
        if (isSellerIdEditting) {
            setIsSellerIdEditting(false);
        }
        if (isBuyerIdEditting) {
            setIsBuyerIdEditting(false);
        }
    }, [activeEditRowId]);

    return (
        <div className="invoice-rows__collapse-content">
            <table className="invoice-rows__collapse-content-rows">
                <tbody>
                    <tr>
                        <td>{`${t('views.invoice.partials.invoiceRows.SellerProductId')}: `}</td>
                        <td>
                            {isSellerIdEditting ? (
                                <TextInput
                                    onChange={(val) => {
                                        setSellerProductId(val.target.value);
                                    }}
                                    maxLength={50}
                                    dataId={createDataId(dataId, 'Input', 'SellerProductId')}
                                    value={sellerProductId}
                                    type={TextInputType.BORDERED}
                                    placeholder={props.t('views.CompanySettings.Value.Placeholder')}
                                    autofocus={true}
                                />
                            ) : (
                                sellerProductId
                            )}
                            {canSaveInvoice &&
                                (!isSellerIdEditting ? (
                                    <Button
                                        iconPlacement={ButtonIconPlacement.LEFT}
                                        onClick={() => setIsSellerIdEditting(true)}
                                        dataId={createDataId(dataId, 'Button', 'Edit')}
                                        icon={ICONS.EDIT_24}
                                        className="icon-button invoice-rows__table-body-edit"
                                        buttonType={ButtonType.ICON}
                                        disabled={activeEditRowId !== null}
                                    />
                                ) : (
                                    <>
                                        <Button
                                            iconPlacement={ButtonIconPlacement.LEFT}
                                            onClick={() => {
                                                setIsSellerIdEditting(false);
                                                saveProductId({ ...invoiceRow, SellerProductId: sellerProductId });
                                            }}
                                            dataId={createDataId(dataId, 'Button', 'Save')}
                                            icon={ICONS.SAVE_24}
                                            className="icon-button invoice-rows__table-body-save-button"
                                            buttonType={ButtonType.ICON}
                                        />
                                        <Button
                                            iconPlacement={ButtonIconPlacement.LEFT}
                                            onClick={() => {
                                                setIsSellerIdEditting(false);
                                                if (invoiceRow?.SellerProductId !== sellerProductId) {
                                                    setSellerProductId(invoiceRow?.SellerProductId || null);
                                                }
                                            }}
                                            dataId={createDataId(dataId, 'Button', 'Discard')}
                                            icon={ICONS.CANCEL_CHANGES_24}
                                            className="icon-button invoice-rows__table-body-discard-button"
                                            buttonType={ButtonType.ICON}
                                        />
                                    </>
                                ))}
                        </td>
                    </tr>
                    <tr>
                        <td>{`${t('views.invoice.partials.invoiceRows.BuyerProductId')}: `}</td>
                        <td>
                            {isBuyerIdEditting ? (
                                <TypeaheadAsync
                                    dataId={createDataId(dataId, 'BuyerId')}
                                    value={buyerProductId}
                                    searchOnFocus
                                    toggleVisible
                                    onChange={(e: TypeaheadItem<ProductItemDTO>) => {
                                        setBuyerProductId(e);
                                    }}
                                    positionFixed
                                    inputProps={{ autofocus: true, type: TextInputType.BORDERED }}
                                    loadItems={getProductItems}
                                    wrapperClass="fixed-height fixed-width"
                                />
                            ) : (
                                buyerProductId?.value?.Code
                            )}
                            {canSaveInvoice &&
                                (!isBuyerIdEditting ? (
                                    <Button
                                        iconPlacement={ButtonIconPlacement.LEFT}
                                        onClick={() => setIsBuyerIdEditting(true)}
                                        dataId={createDataId(dataId, 'Button', 'Edit')}
                                        icon={ICONS.EDIT_24}
                                        className="icon-button invoice-rows__table-body-edit"
                                        buttonType={ButtonType.ICON}
                                        disabled={activeEditRowId !== null}
                                    />
                                ) : (
                                    <>
                                        <Button
                                            iconPlacement={ButtonIconPlacement.LEFT}
                                            onClick={() => {
                                                setIsBuyerIdEditting(false);
                                                saveProductId({ ...invoiceRow, BuyerProductName: buyerProductId?.value?.FullName, BuyerProductId: buyerProductId?.value?.Code });
                                            }}
                                            dataId={createDataId(dataId, 'Button', 'Save')}
                                            icon={ICONS.SAVE_24}
                                            className="icon-button invoice-rows__table-body-save-button"
                                            buttonType={ButtonType.ICON}
                                        />
                                        <Button
                                            iconPlacement={ButtonIconPlacement.LEFT}
                                            onClick={() => {
                                                setIsBuyerIdEditting(false);
                                                if (invoiceRow?.BuyerProductId !== buyerProductId?.value?.Code) {
                                                    setBuyerProductId({
                                                        value: { FullName: invoiceRow?.BuyerProductName, Code: invoiceRow?.BuyerProductId },
                                                        text: invoiceRow?.BuyerProductName || null,
                                                    });
                                                }
                                            }}
                                            dataId={createDataId(dataId, 'Button', 'Discard')}
                                            icon={ICONS.CANCEL_CHANGES_24}
                                            className="icon-button invoice-rows__table-body-discard-button"
                                            buttonType={ButtonType.ICON}
                                        />
                                    </>
                                ))}
                        </td>
                    </tr>
                    <tr>
                        <td>{`${t('views.invoice.partials.invoiceRows.SerialNumber')}: `}</td>
                        <td>{invoiceRow.SerialNumber}</td>
                    </tr>
                    <tr>
                        <td>{`${t('views.invoice.partials.invoiceRows.EAN')}: `}</td>
                        <td>{invoiceRow.EAN}</td>
                    </tr>
                    <tr>
                        <td>{`${t('views.invoice.partials.invoiceRows.CustomerRef')}: `}</td>
                        <td>{invoiceRow.CustomerRef}</td>
                    </tr>
                    <tr>
                        <td>{`${t('views.invoice.partials.invoiceRows.GroupId')}: `}</td>
                        <td>{invoiceRow.GroupId}</td>
                    </tr>
                    <tr>
                        <td>{`${t('views.invoice.partials.invoiceRows.TaricCode')}: `}</td>
                        <td>{invoiceRow.TaricCode}</td>
                    </tr>
                    {invoiceRow?.ItemReserve?.map((e, i) => (
                        <tr key={e?.Id || i}>
                            <td>{`${e?.InformationName || e?.ExtensionId}: `}</td>
                            <td>{e.InformationContent}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default withSuspense(withTranslation()(CollapseContent));
