import { ReducerFactory } from 'redux-actions-ts-reducer';

import { BackOfficeCompanyDTO } from '../../../services/types/BoApiTypes';
import { LoadableData } from '../../../common/utils/LoadableData';
import { getCompanyDataLoadable, getSameOrganizationCompaniesData } from './CompanySettingsViewActions';

class State {
    companyDataLoadable = new LoadableData<BackOfficeCompanyDTO, boolean>();
    organizationSameCompaniesDataLoadable = new LoadableData<BackOfficeCompanyDTO[], boolean>();
}

export default new ReducerFactory(new State())
    .addReducer(
        getCompanyDataLoadable.request,
        (state): State => {
            return {
                ...state,
                companyDataLoadable: state.companyDataLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        getCompanyDataLoadable.success,
        (state, action): State => {
            return {
                ...state,
                companyDataLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getCompanyDataLoadable.error,
        (state, action): State => {
            return {
                ...state,
                companyDataLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        getSameOrganizationCompaniesData.request,
        (state): State => {
            return {
                ...state,
                organizationSameCompaniesDataLoadable: state.organizationSameCompaniesDataLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        getSameOrganizationCompaniesData.success,
        (state, action): State => {
            return {
                ...state,
                organizationSameCompaniesDataLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getSameOrganizationCompaniesData.error,
        (state, action): State => {
            return {
                ...state,
                organizationSameCompaniesDataLoadable: LoadableData.error(action.payload),
            };
        },
    )

    .toReducer();

export { State as CompanySettingsViewState };
