import i18nInstance from '../../i18n';
import api from '../../services/ApiServices';
import { DispatchThunk } from '../../storeConfig';
import { getCurrentUserGroupMember } from '../../common/user/UserActions';
import { notify } from '../../common/utils/notify';
import { SubstituteDTO } from '../../services/types/ApiTypes';

export const updateSubstitutes = (substitutes: Omit<SubstituteDTO, 'GroupMemberId' | 'GroupMemberName' | 'Id' | 'IsNew'>[], $rootScope: any) => {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.user.updateSubstitutes(substitutes);
            if (response?.status === 200) {
                dispatch(getCurrentUserGroupMember(true));
                if ($rootScope) {
                    $rootScope.$emit('app.performUpdateSubstitute');
                }
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            console.error(e);
        }
    };
};
