import classNames from 'classnames';
import React, { ReactNode, StatelessComponent } from 'react';

import { Button, ButtonType } from '../Buttons/Button';
import Icon, { IconSize, ICONS } from '../Icon/Icon';

import './Notification.scss';

export interface NotificationProps {
    onClose?: () => void;
    visible: boolean;
    type: NotificationType;
    theme?: NotificationTheme;
    className?: string;
    status?: NotificationStatus;
    title?: ReactNode;
    children?: ReactNode;
    dataId: string;
}

export enum NotificationStatus {
    SUCCESS = 'SUCCESS',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    LEGACY_ERROR = 'LEGACY_ERROR',
}

export enum NotificationType {
    MINIMAL = 'MINIMAL',
    COMPONENT = 'COMPONENT',
    CARD = 'CARD',
    GLOBAL = 'GLOBAL',
}

export enum NotificationTheme {
    LIGHT = 'LIGHT',
    DARK = 'DARK',
}

const getNotificationType = (type: NotificationType) => {
    const notificationType = {
        [NotificationType.MINIMAL]: 'notification--minimal',
        [NotificationType.COMPONENT]: 'notification--component',
        [NotificationType.CARD]: 'notification--card',
        [NotificationType.GLOBAL]: 'notification--global',
    };
    return notificationType[type];
};

const getNotificationTheme = (theme: NotificationTheme) => {
    const notificationTheme = {
        [NotificationTheme.LIGHT]: 'notification--light',
        [NotificationTheme.DARK]: 'notification--dark',
    };
    return notificationTheme[theme];
};

const getNotificationStatus = (status: NotificationStatus) => {
    const notificationStatus = {
        [NotificationStatus.SUCCESS]: 'notification--success',
        [NotificationStatus.INFO]: 'notification--info',
        [NotificationStatus.WARNING]: 'notification--warning',
        [NotificationStatus.ERROR]: 'notification--error',
        [NotificationStatus.LEGACY_ERROR]: 'notification--legacy-error',
    };
    return notificationStatus[status];
};

const getNotificationIcon = (status: NotificationStatus, type: NotificationType) => {
    let notificationIcon = {
        [NotificationStatus.SUCCESS]: ICONS.NOTIFICATION_SUCCESS,
        [NotificationStatus.INFO]: ICONS.NOTIFICATION_INFO,
        [NotificationStatus.WARNING]: ICONS.NOTIFICATION_WARNING,
        [NotificationStatus.ERROR]: ICONS.NOTIFICATION_ERROR,
        [NotificationStatus.LEGACY_ERROR]: ICONS.NOTIFICATION_ERROR,
    };

    if (type === NotificationType.MINIMAL) {
        notificationIcon = {
            [NotificationStatus.SUCCESS]: ICONS.NOTIFICATION_MINIMAL_SUCCESS,
            [NotificationStatus.INFO]: ICONS.NOTIFICATION_MINIMAL_INFO,
            [NotificationStatus.WARNING]: ICONS.NOTIFICATION_MINIMAL_WARNING,
            [NotificationStatus.ERROR]: ICONS.NOTIFICATION_MINIMAL_ERROR,
            [NotificationStatus.LEGACY_ERROR]: ICONS.NOTIFICATION_MINIMAL_ERROR,
        };
    }
    return notificationIcon[status];
};

const Notification: StatelessComponent<NotificationProps> = (props) => {
    const { visible, dataId, className, type, status = NotificationStatus.SUCCESS, theme = NotificationTheme.LIGHT, title = '', children, onClose } = props;
    const classes = classNames('notification', className, getNotificationStatus(status), getNotificationType(type), getNotificationTheme(theme));
    const icon = getNotificationIcon(status, type);

    return (
        visible && (
            <div className={classes} data-id={dataId}>
                <div className={'notification__icon'}>
                    <Icon name={icon} size={IconSize.SM} />
                </div>
                <div className={'notification__body'}>
                    <div className={'notification__line'} />
                    <div className={'notification__wrapper'}>
                        <span className={'notification__title'}> {title} </span>
                        <span className={'notification__content'}>{children}</span>
                    </div>
                    {onClose && (
                        <Button
                            dataId="notification.closeButton"
                            id="notification-closeButton"
                            buttonType={ButtonType.ICON}
                            icon={ICONS.CLOSE_SMALL}
                            className={'notification__close'}
                            onClick={onClose}
                        />
                    )}
                </div>
            </div>
        )
    );
};

export default Notification;
