import { areIntervalsOverlapping, isDate } from 'date-fns';
import { uniqueId } from 'lodash-es';

import api from '../../../services/ApiServices';
import { TypeaheadItem } from '../../../components/Typeahead/Typeahead';
import { BaseSearch, SearchType, SortDirection, SubstituteDTO } from '../../../services/types/ApiTypes';
import { OutOfOfficeModalValues } from './OutOfOfficeModalTypes';
import { getEndTimeDate, getNoTimeDate } from '../../../common/utils/datetime';

const idPrefix = 'KEY_';

export const getUniqueId = () => uniqueId(idPrefix);

export const handleSearchSubstitutes = async (searchString: string): Promise<Array<TypeaheadItem<{ id: number; name: string }>>> => {
    const searchParams: BaseSearch = {
        SortItems: [
            {
                SortColumn: 'UserFullName',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 25,
        },
        Restrictions: [
            {
                Field: 'GeneralSearch',
                Value: searchString || '',
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            },
            {
                Field: 'IsActive',
                Value: true,
                Values: null,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
    };
    const response = await api.company.getGroupMembersMicro(searchParams);

    return response.data.Items.map((sub) => ({
        value: { id: sub.Id, name: sub.Name },
        text: sub.Name,
    }));
};

export const initialOutOfOfficeValues = (): OutOfOfficeModalValues => ({ Key: getUniqueId(), From: null, To: null, Substitute: null });

export const isEndDateBeforeToday = (endDate: Date): boolean => getNoTimeDate(new Date(endDate)).getTime() < getNoTimeDate(new Date()).getTime();

const isDateOverlapping = (firstSubstitute: OutOfOfficeModalValues, secondSubstitute: OutOfOfficeModalValues): boolean => {
    const firstFromDate = firstSubstitute?.From ? getNoTimeDate(new Date(firstSubstitute.From)) : null;
    const firstToDate = firstSubstitute?.To ? getEndTimeDate(new Date(firstSubstitute.To)) : null;
    const secondFromDate = secondSubstitute?.From ? getNoTimeDate(new Date(secondSubstitute.From)) : null;
    const secondToDate = secondSubstitute?.To ? getEndTimeDate(new Date(secondSubstitute.To)) : null;
    const isAllDatesValid = isDate(firstFromDate) && isDate(firstToDate) && isDate(secondFromDate) && isDate(secondToDate);

    if (!isAllDatesValid) {
        return false;
    }
    const isDateRangesValid = firstFromDate.getTime() < firstToDate.getTime() && secondFromDate.getTime() < secondToDate.getTime();

    if (!isDateRangesValid) {
        return false;
    }
    return areIntervalsOverlapping({ start: firstFromDate, end: firstToDate }, { start: secondFromDate, end: secondToDate });
};

export const checkIsOverlapping = (substitute: OutOfOfficeModalValues, substitutes: OutOfOfficeModalValues[]): boolean => {
    const isOverlapping = substitutes.some((v) => {
        if (substitute.Key !== v.Key) {
            const checkDate = isDateOverlapping(substitute, v);
            return checkDate;
        }
        return false;
    });
    return isOverlapping;
};

export const parseSubstituteList = (substitutes: OutOfOfficeModalValues[]): Omit<SubstituteDTO, 'GroupMemberId' | 'GroupMemberName' | 'Id' | 'IsNew'>[] =>
    substitutes.map((e) => {
        return {
            From: e.From,
            To: e.To,
            ToSubstituteId: e.Substitute?.value?.id,
            ToSubstituteName: e.Substitute?.value?.name,
        };
    });
