import { useEffect, useState } from 'react';
import { chunk, cloneDeep } from 'lodash-es';

/**
 * takes array of data and returns array of data chunks array, each containing not more than given number of items
 * @param data array of all items
 * @param itemsPerChunk number of items per chunk
 * @return array of data chunks
 */
const useDataChunks = <T>(data: T[], itemsPerChunk: number): [T[][], (c: T[][]) => void] => {
    const [chunks, setChunks] = useState<T[][]>([]);

    useEffect(() => {
        data && setChunks(chunk(cloneDeep(data), itemsPerChunk));
    }, [data, itemsPerChunk]);

    return [chunks, setChunks];
};

export default useDataChunks;
