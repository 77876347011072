import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { GlobalState } from '../../rootReducer';
import { getCurrentCompany } from '../../common/user/UserSelectors';
import { setActiveCompanyGuid, showChangeCompany, searchUserCompanies, updateAllWaitingInvoiceCounts, updateSingleCompanyWaitingInvoiceCount, clearUserCompanies } from './ChangeCompanyActions';
import { selectIsChangeCompanyOpen, selectCompanySwithcerListLoadable } from './ChangeCompanyReducer';
import { ChangeCompany, DispatchProps, Props } from './ChangeCompany';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        isModalOpen: selectIsChangeCompanyOpen(state),
        userCompanies: selectCompanySwithcerListLoadable(state),
        currentCompany: getCurrentCompany(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    changeCompany: setActiveCompanyGuid,
    searchUserCompanies,
    showChangeCompany,
    updateAllWaitingInvoiceCounts,
    updateSingleCompanyWaitingInvoiceCount,
    clearUserCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChangeCompany));
