import classNames from 'classnames';
import * as React from 'react';

import { BaseComponent } from '../BaseComponent';

import './ContentBlockStrip.scss';

export interface ContentBlockStripProps {
    className?: string;
}

const noNotch = true;
export class ContentBlockStrip extends BaseComponent<ContentBlockStripProps> {
    render() {
        const { className } = this.props;
        const classes = classNames('content-block-strip', { 'content-block-strip--no-notch': noNotch }, className);
        return (
            <div className={classes} data-id="content.block.strip">
                <div className={classNames('content-block-strip__background-fill', { 'content-block-strip__background-fill--no-notch': noNotch })} />
                {!noNotch && (
                    <>
                        <div className="content-block-strip__notch content-block-strip__notch--left" />
                        <div className="content-block-strip__notch content-block-strip__notch--right" />
                    </>
                )}
                {this.props.children}
            </div>
        );
    }
}
