import produce from 'immer';
import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';

import { AccountDTO, CompanyDataDTO, CompanyInvoiceCountDTO, Currency, CustomCostObjectiveFullDTO, GroupMemberCommonDTO, GroupMemberDTO, VatCodeDTO } from '../../services/types/ApiTypes';
import { User } from '../../services/ApiClient';
import { GlobalState } from '../../rootReducer';
import {
    getCurrenciesActions,
    getGroupMemberActions,
    setAccountsAction,
    setCurrentCompanyAction,
    setCurrentUserAction,
    setCustomCostObjectivesAction,
    setUserActiveVatCodesAction,
    setUserCompaniesAction,
    setUserCompaniesSearchResultAction,
    setNoMoreCompaniesSearchResultsAction,
    updateWaitingInvoicesCountAction,
    removeUserCompanyAction,
    getAllInvoiceCountsAction,
    areWaitingInvoicesCountUpdatingAction,
    updateCostObjectivesFilterLastDateAction,
    updateCustomCostObjectivesFilterLastDateAction,
    getCommonGroupMemberActions,
} from './UserActions';
import { BackOfficeCompanyDTOExtended, BackOfficeUserDTO } from '../../services/types/BoApiTypes';
import { LoadableData } from '../utils/LoadableData';

class State {
    currentUser: User = undefined;
    currentUserLoaded = false;
    currentCompany: CompanyDataDTO = undefined;
    currencies: Currency[] = [];
    vatCodes: VatCodeDTO[] = [];
    costObjectivesFilterLastDate: string = undefined;
    customCostObjectivesFilterLastDate: string = undefined;
    accounts: AccountDTO[] = [];
    customCostObjectives: CustomCostObjectiveFullDTO[] = [];
    userCompanies: BackOfficeCompanyDTOExtended[] = [];
    userCompaniesSearchResult: BackOfficeCompanyDTOExtended[] = [];
    noMoreUserCompaniesSearchResults = false;
    groupMemberLoadable = new LoadableData<GroupMemberDTO>();
    groupMemberCommonLoadable = new LoadableData<GroupMemberCommonDTO>();
    boCurrentUserLoadable = new LoadableData<BackOfficeUserDTO>();
    areWaitingInvoicesCountsUpdating: boolean;
    allInvoiceCounts: CompanyInvoiceCountDTO[] = [];
}

export default new ReducerFactory(new State())
    .addReducer(
        setCurrentUserAction,
        (state, action): State => {
            return {
                ...state,
                currentUser: action.payload,
                currentUserLoaded: action.payload ? true : false,
            };
        },
    )
    .addReducer(
        setCurrentCompanyAction,
        (state, action): State => {
            return {
                ...state,
                currentCompany: action.payload,
            };
        },
    )
    .addReducer(
        setUserCompaniesAction,
        (state, action): State => {
            return {
                ...state,
                userCompanies: action.payload,
            };
        },
    )
    .addReducer(
        setUserCompaniesSearchResultAction,
        (state, action): State => {
            return {
                ...state,
                userCompaniesSearchResult: action.payload,
            };
        },
    )
    .addReducer(
        setNoMoreCompaniesSearchResultsAction,
        (state, action): State => {
            return {
                ...state,
                noMoreUserCompaniesSearchResults: action.payload,
            };
        },
    )
    .addReducer(
        getCurrenciesActions.success,
        (state, action): State => {
            return {
                ...state,
                currencies: action.payload,
            };
        },
    )
    .addReducer(
        setAccountsAction,
        (state, action): State => {
            return {
                ...state,
                accounts: action.payload,
            };
        },
    )
    .addReducer(
        setCustomCostObjectivesAction,
        (state, action): State => {
            return {
                ...state,
                customCostObjectives: action.payload,
            };
        },
    )
    .addReducer(
        setUserActiveVatCodesAction,
        (state, action): State => {
            return {
                ...state,
                vatCodes: action.payload,
            };
        },
    )
    .addReducer(
        getGroupMemberActions.request,
        (state): State => {
            return {
                ...state,
                groupMemberLoadable: state.groupMemberLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        getGroupMemberActions.success,
        (state, action): State => {
            return {
                ...state,
                groupMemberLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getGroupMemberActions.error,
        (state, action): State => {
            return {
                ...state,
                groupMemberLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        getCommonGroupMemberActions.request,
        (state): State => {
            return {
                ...state,
                groupMemberCommonLoadable: state.groupMemberCommonLoadable.withLoading(),
            };
        },
    )
    .addReducer(
        getCommonGroupMemberActions.success,
        (state, action): State => {
            return {
                ...state,
                groupMemberCommonLoadable: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getCommonGroupMemberActions.error,
        (state, action): State => {
            return {
                ...state,
                groupMemberCommonLoadable: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        updateWaitingInvoicesCountAction,
        produce((draft: State, action: Action<{ companyGuid: string; count: number }>) => {
            const index = draft.userCompanies.findIndex((c) => c.CompanyGuid === action.payload.companyGuid);
            if (index !== -1) {
                draft.userCompanies[index].WaitingInvoiceCount = action.payload.count;
            }
            const indexForSearchResult = draft.userCompaniesSearchResult.findIndex((c) => c.CompanyGuid === action.payload.companyGuid);
            if (indexForSearchResult !== -1) {
                draft.userCompaniesSearchResult[indexForSearchResult].WaitingInvoiceCount = action.payload.count;
            }
        }),
    )
    .addReducer(
        removeUserCompanyAction,
        (state, action): State => {
            const userCompanies = [...state.userCompanies];
            const companyIndex = userCompanies.findIndex((c) => c.CompanyGuid === action.payload.companyGuid);

            if (companyIndex !== -1) {
                userCompanies.splice(companyIndex, 1);
            }
            return {
                ...state,
                userCompanies,
            };
        },
    )
    .addReducer(
        getAllInvoiceCountsAction,
        (state, action): State => {
            return {
                ...state,
                allInvoiceCounts: action.payload,
            };
        },
    )
    .addReducer(
        areWaitingInvoicesCountUpdatingAction,
        (state, action): State => {
            return {
                ...state,
                areWaitingInvoicesCountsUpdating: action.payload.areWaitingInvoicesCountsUpdating,
            };
        },
    )
    .addReducer(
        updateCostObjectivesFilterLastDateAction,
        (state, action): State => {
            return {
                ...state,
                costObjectivesFilterLastDate: action.payload.costObjectivesFilterLastDate,
            };
        },
    )
    .addReducer(
        updateCustomCostObjectivesFilterLastDateAction,
        (state, action): State => {
            return {
                ...state,
                customCostObjectivesFilterLastDate: action.payload.customCostObjectivesFilterLastDate,
            };
        },
    )
    .toReducer();

export const selectGroupMemberCommonLoadable = (state: GlobalState) => state.user.groupMemberCommonLoadable;

export { State as UserState };
