import { cloneDeep } from 'lodash-es';

angular.module('dstreamApp.controllers').controller('invoiceCustomFieldsDetailController', [
    '$scope',
    '$uibModalInstance',
    '$filter',
    'notifyService',
    '$translate',
    '$uibModal',
    'webServices',
    'field',
    function ($scope, $uibModalInstance, $filter, notifyService, $translate, $uibModal, webServices, field) {

        const initialField = {
            Description: field.Description,
            Code: field.Code,
            IsMandatory: field.IsMandatory,
            IncludeInExport: field.IncludeInExport,
            FieldType: field.FieldType,
        }

        var tempItem;

        function loadItems() {
            if (field.Id && field.FieldType.Value === 1) {
                $scope.itemsLoading = true;
                webServices.getInvoiceCustomFieldItems(field).then(function (response) {
                    $scope.itemsLoading = false;
                    field.InvoiceCustomFieldItems = response.data;
                });
            }
        }

        $scope.fieldChanged = function () {
            return field.Description && field.Code && field.FieldType && (initialField.Code !== field.Code || initialField.Description !== field.Description || initialField.IsMandatory !== field.IsMandatory
                || initialField.IncludeInExport !== field.IncludeInExport || initialField.FieldType !== field.FieldType.Value);
        }


        $scope.fieldTypeRanges = [
            { Value: 0, Name: $filter('translate')("views.InvoiceCustomFields.Text") },
            { Value: 1, Name: $filter('translate')("views.InvoiceCustomFields.List") },
            { Value: 2, Name: $filter('translate')("views.InvoiceCustomFields.DateTime") }
        ];

        $scope.isValid = false;
        $scope.field = field;
        $scope.field.FieldType = $scope.fieldTypeRanges.filter(function (item) {
            return $scope.field.FieldType === item.Value;
        })[0];

        $scope.getFieldTypeRanges = function (name) {
            var namePart = name || "";
            var searchRegexp = new RegExp(namePart);
            const result = $scope.fieldTypeRanges.filter((ftp) => searchRegexp.test(ftp.Name));
            if (result.length === 0) {
                return $scope.fieldTypeRanges;
            }
            return result;
        };

        $scope.save = save;
        $scope.close = close;

        $scope.addRow = function () {
            if (!$scope.field.InvoiceCustomFieldItems) {
                $scope.field.InvoiceCustomFieldItems = [];
            }
            $scope.field.InvoiceCustomFieldItems.push({ edit: true });
        };


        $scope.update = function (item) {
            if (item.Description && item.Code) {
                var copy = cloneDeep(item);
                copy.InvoiceCustomFieldId = field.Id;
                $scope.itemsLoading = true;
                item.edit = false;
                webServices.saveInvoiceCustomFieldItem(copy).then(function (response) {
                    field.InvoiceCustomFieldItems = response.data.InvoiceCustomFieldItems;
                    $scope.itemsLoading = false;
                });
            }
            return true;
        };

        $scope.edit = function (index, item, edit) {
            if (edit) {
                tempItem = cloneDeep(item);
            }
            else {
                if (item.Id) {
                    item.Code = tempItem.Code;
                    item.Description = tempItem.Description;
                }
                else {
                    field.InvoiceCustomFieldItems.splice(index, 1);
                }
            }
            item.edit = edit;
        }

        $scope.updatefieldTypeRanges = function (item) {
            $scope.field.FieldType = item;
        };

        function save() {
            if (field.Description && field.Code && field.FieldType) {
                var copy = cloneDeep(field);
                copy.FieldType = field.FieldType.Value;
                if (!field.InvoiceCustomFieldItems || copy.FieldType !== 1) {
                    copy.InvoiceCustomFieldItems = null;
                }
                $scope.loading = true;
                webServices.saveInvoiceCustomField(copy).then(function (resp) {
                    $scope.loading = false;
                    if (resp.data.ErrorCode) {
                        notifyService.error(resp.data.ErrorCode);
                    } else {
                        field.FieldType = field.FieldType.Value;
                        $uibModalInstance.close();
                        $uibModalInstance.dismiss();
                    }
                });
            }
        }

        function close() {

            field.Description = initialField.Description;
            field.Code = initialField.Code;
            field.IsMandatory = initialField.IsMandatory;
            field.IncludeInExport = initialField.IncludeInExport;
            field.FieldType = initialField.FieldType;

            $uibModalInstance.close();
            $uibModalInstance.dismiss();
        }

        loadItems();
    }
]);