import classNames from 'classnames';
import * as React from 'react';
import { ReactElement } from 'react';

import Logo from '../../logo/Logo';
import { BaseStatefulComponent } from '../../BaseStatefulComponent';
import Scrollbars from '../../Scrollbars/Scrollbars';
import { MainMenuProps } from '../MainMenu';

import { MainMenuItem, MainMenuItemProps } from './MainMenuItem';
import LogoBo from '../../logo/LogoBo';
import { ICONS } from '../../Icon/Icon';
import { isBackOffice } from '../MainMenuHelper';
import { paths } from '../../../common/router/routePaths';
import checkAccessToBO from '../../../common/utils/backOfficeAccess';
import { getWhitelabelPageTitle } from '../../../common/utils/whitelabelHelper';
import { Button, ButtonType } from '../../Buttons/Button';

import '../MainMenu.scss';

export interface MainMenuBodyProps extends MainMenuProps {}

interface State {
    isOpen: boolean;
    isSubMenuOpen: boolean;
    scrollbarRerenderTrigger: number;
    isPrinting: boolean;
    canAccessBO: boolean;
    menuToggleClass: string;
}

export class MainMenuBody extends BaseStatefulComponent<MainMenuBodyProps, State> {
    private componentRootElement = React.createRef<HTMLDivElement>();

    constructor(props: MainMenuBodyProps) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen,
            isSubMenuOpen: false,
            scrollbarRerenderTrigger: new Date().getTime(),
            isPrinting: false,
            canAccessBO: false,
            menuToggleClass: '',
        };
    }

    static defaultProps: Partial<MainMenuBodyProps> = {};

    componentDidUpdate(prevProps: Readonly<MainMenuBodyProps>): void {
        if (prevProps.isLoading !== this.props.isLoading && !this.props.isLoading) {
            this.setState({
                scrollbarRerenderTrigger: new Date().getTime(),
            });
        }
        if (this.state.isOpen !== this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
            this.toggleOpen(this.props.isOpen);
        }

        if (this.props.boCurrentUserLoadable.loaded) {
            const canAccessBO = checkAccessToBO(this.props.boCurrentUserLoadable.payload);
            this.setState({ canAccessBO });
        }
    }

    componentDidMount(): void {
        window.addEventListener('resize', this.handleResize);
        if (window.matchMedia) {
            const mediaQueryList = window.matchMedia('print');
            mediaQueryList.addListener(this.handlePrint);
        }
        this.handleResize();
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
        if (window.matchMedia) {
            const mediaQueryList = window.matchMedia('print');
            mediaQueryList.removeListener(this.handlePrint);
        }
    }

    handleResize = () => {
        const windowWidth = window.innerWidth;
        // ignore resize event that re-opens the menu when printing
        if (this.state.isPrinting) {
            return;
        }
        if (windowWidth < 1366 && this.state.isOpen) {
            this.toggleOpen();
        }
        if (windowWidth >= 1366 && !this.state.isOpen) {
            this.toggleOpen();
        }
        this.setState({
            scrollbarRerenderTrigger: new Date().getTime(),
        });
    };

    handlePrint = (event: MediaQueryListEvent) => {
        if (event.matches) {
            this.setState({
                isPrinting: true,
            });
        } else {
            this.setState({
                isPrinting: false,
            });
        }
    };

    toggleOpen = (willBeOpen = !this.state.isOpen) => {
        if (!willBeOpen) {
            document.body.classList.add('sidebar-collapsed');
        } else {
            document.body.classList.remove('sidebar-collapsed');
        }

        this.setState(
            {
                isOpen: willBeOpen,
            },
            () => {
                this.props.toggleMainMenuOpen(willBeOpen);
                this.setState({ menuToggleClass: 'main-menu__btn-wrap--hide' });
                setTimeout(() => {
                    this.setState({ menuToggleClass: willBeOpen ? '' : 'main-menu__btn-wrap--is-compact' });
                }, 200);
            },
        );
    };

    handleSubMenuOpen = (isOpen: boolean) => {
        this.setState({
            isSubMenuOpen: isOpen,
        });
    };

    render() {
        const { isOpen, isSubMenuOpen, scrollbarRerenderTrigger, canAccessBO, menuToggleClass } = this.state;
        const { currentPath, willAskToVerifyEmail, emailVerificationCompleted } = this.props;
        const classes = classNames(
            'main-menu',
            { 'main-menu--is-top-padded-292': willAskToVerifyEmail || emailVerificationCompleted },
            { 'main-menu--is-closed': !isOpen },
            { 'main-menu--is-submenu-open': isSubMenuOpen },
            { 'main-menu--is-loading': this.props.isLoading },
        );
        const CurrentLogo = isBackOffice(currentPath) ? LogoBo : Logo;
        const href = isBackOffice(currentPath) ? '#/dashboard' : `#${paths.backOffice.default}`;

        return (
            <div ref={this.componentRootElement} className={classes} data-id="mainMenu">
                <a href={isBackOffice(currentPath) ? '#/backoffice' : '#/'} className={`main-menu__logo-wrap ${isOpen ? '' : 'main-menu__logo-wrap--is-compact'}`}>
                    <CurrentLogo compact={!isOpen} className="main-menu__logo" wrapperClassName="main-menu__logo-wrapper" />
                </a>
                <div className={`main-menu__btn-wrap ${menuToggleClass}`}>
                    <Button
                        className={`main-menu__collapse-toggle ${isOpen ? '' : 'main-menu__collapse-toggle--is-compact'}`}
                        onClick={() => this.toggleOpen()}
                        data-id="button-toggle-menu"
                        buttonType={ButtonType.ICON}
                        icon={isOpen ? ICONS.COLLAPS : ICONS.EXPAND}
                    ></Button>
                </div>
                <Scrollbars
                    autoHeight={false}
                    autoHeightMin={undefined}
                    autoHeightMax={undefined}
                    verticalLeft={!isOpen}
                    isScrollTrackHidden={true}
                    hideTracksWhenNotNeeded={true}
                    name={String(scrollbarRerenderTrigger)}
                >
                    <nav className="main-menu__content">
                        <ul className="main-menu__list">
                            {React.Children.map(this.props.children, (e: ReactElement<MainMenuItemProps, any>) => {
                                if (React.isValidElement(e)) {
                                    return React.cloneElement(e, { onSubMenuOpen: this.handleSubMenuOpen, currentPath: this.props.currentPath, isMenuOpen: isOpen });
                                }
                                return null;
                            })}
                        </ul>
                    </nav>
                </Scrollbars>
                {canAccessBO && (
                    <ul className="main-menu__list main-menu__switch-portal">
                        <MainMenuItem dataId="switch-portal-menu-item" icon={ICONS.BO_ICON} href={href}>
                            {isBackOffice(currentPath) ? getWhitelabelPageTitle() : this.props.t('view.backOffice')}
                        </MainMenuItem>
                    </ul>
                )}
            </div>
        );
    }
}

export default MainMenuBody;
