import React from 'react';
import classNames from 'classnames';

import { useClasses, getHtmlElement } from './TypographyHelper';
import { TypographyProps } from './TypographyTypes';

import './Typography.scss';

export const Typography: React.FC<React.PropsWithChildren<TypographyProps>> = (props) => {
    const { variant = 'body-md', element, className, dataId, ...restProps } = props;

    const elementType = getHtmlElement(variant, element);
    const classes = useClasses(variant);

    return React.createElement(elementType, { ...restProps, className: classNames(classes.root, classes.variant, className), 'data-id': dataId });
};
