import { AxiosInstance, AxiosResponse } from 'axios';

import { InvoiceCustomFieldDTO, PagedListContainer, BaseSearch } from '../types/ApiTypes';

export class HeaderExtensionsService {
    constructor(private axios: AxiosInstance) {}

    getInvoiceCustomFieldsWithoutItems = async (search: BaseSearch): Promise<AxiosResponse<PagedListContainer<InvoiceCustomFieldDTO>>> => {
        return this.axios.post('/company/GetInvoiceCustomFieldsWithoutItems', search);
    };

    deleteInvoiceCustomField = async (id: number): Promise<AxiosResponse<boolean>> => {
        return this.axios.get(`/company/DeleteInvoiceCustomField/${id}`);
    };

    addInvoiceCustomField = async (item: InvoiceCustomFieldDTO): Promise<AxiosResponse<InvoiceCustomFieldDTO>> => {
        return this.axios.post('/company/AddInvoiceCustomField', item);
    };
}

export default HeaderExtensionsService;
