import { withTranslation } from 'react-i18next';
import withSuspense from '../../../../common/hocs/withSuspense';
import { GlobalState } from '../../../../rootReducer';
import { connect } from '../../../../storeConfig';
import InvoiceSlider from './InvoiceSlider';
import { loadInvoice } from './InvoiceSliderActions';
import { setIsConfirmationFlowEditing } from '../invoice-confirmations/InvoiceConfirmationsAction';
import { selectIsConfirmationFlowEditing } from '../invoice-confirmations/InvoiceConfirmationsReducer';
import { selectPdfToOpen } from '../../../../components/PDFViewer/FileViewReducer';
import { setPdfToOpenAction } from '../../../../components/PDFViewer/FileViewActions';

import { selectInvoiceDataLoadable } from './InvoiceSliderReducers';
import { SlickProps, DispatchProps } from './InvoiceSlider';

const mapStateToProps = (state: GlobalState): SlickProps => {
    return {
        invoiceSliderDataLoadable: selectInvoiceDataLoadable(state),
        isConfirmationFlowEditing: selectIsConfirmationFlowEditing(state),
        pdfToOpen: selectPdfToOpen(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    loadInvoice,
    setIsConfirmationFlowEditing,
    setPdfToOpen: setPdfToOpenAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(withTranslation()(InvoiceSlider)));
