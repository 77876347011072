import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { connect } from '../../storeConfig';
import { GlobalState } from '../../rootReducer';

import { BackOfficeUserDTO } from '../../services/types/BoApiTypes';
import { LoadableData } from '../../common/utils/LoadableData';
import withSuspense from '../../common/hocs/withSuspense';
import { Button, ButtonType, ButtonSize } from '../Buttons/Button';
import { is1to1, isGrantThornton, isMaxima, isSuf, isSwedbank } from '../../common/utils/whitelabelHelper';
import Modal from '../Modal/Modal';
import ModalTitle from '../Modal/ModalTitle';
import ModalFooter from '../Modal/ModalFooter';
import { createDataId } from '../../common/utils/dataId';
import { UserRole } from '../../common/constants/appConstants';

import './SurveyMonkeyFeedback.scss';

interface Props {
    $rootScope: any;
    boCurrentUserLoadable: LoadableData<BackOfficeUserDTO>;
    currentLanguage: string;
}

type FeedbackProps = Props & WithTranslation;

const dataId = 'feedback-survey';

const getSurveyLink = (userCountry: string, userRole: string, userLanguage: string) => {
    const role = `?Userrole=${userRole}`;
    const country = userCountry ? `&Country=${userCountry}` : '';
    const lang = userLanguage ? `&lang=${userLanguage}` : '';
    return `https://www.surveymonkey.com/r/customerfeedback2021${role}${country}${lang}`;
};

const getUserRoleString = (userMemberRoles: number[] = []): string => {
    if (userMemberRoles.includes(UserRole.Administrator)) {
        return 'Admin';
    } else if (userMemberRoles.includes(UserRole.Processor)) {
        return 'Approver';
    }
    return 'Other';
};

const SurveyMonkeyFeedback = ({ boCurrentUserLoadable, currentLanguage, $rootScope, t }: FeedbackProps) => {
    const [isSurveyAvailable, setIsSurveyAvailable] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [userRole, setUserRole] = useState<string>();
    const [userCountry, setUserCountry] = useState<string>();
    const [userLanguage, setUserLanguage] = useState<string>();

    useEffect(() => {
        if (is1to1() || isGrantThornton() || isSuf() || isMaxima() || isSwedbank()) {
            setIsSurveyAvailable(false);
        } else {
            setIsSurveyAvailable(true);
        }
    }, []);

    useEffect(() => setUserRole(getUserRoleString($rootScope.userData?.MemberRoles)), [$rootScope.userData]);
    useEffect(() => currentLanguage && setUserLanguage(currentLanguage.split('_')[0]), [currentLanguage]);

    useEffect(() => {
        if (boCurrentUserLoadable.payload) {
            setUserCountry(boCurrentUserLoadable.payload.Country);
        }
    }, [boCurrentUserLoadable.payload]);

    if (!isSurveyAvailable) {
        return null;
    }

    return (
        <>
            {/* <Button className="survey-button" dataId={createDataId(dataId, 'open-button')} onClick={() => setIsOpen(true)} buttonType={ButtonType.PRIMARY} buttonSize={ButtonSize.SMALL}>
                {t('component.FeedbackSurvey.OpenFeedbackButton')}
            </Button> */}
            {isOpen && (
                <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} className="survey-modal" dataId={createDataId(dataId, 'modal')}>
                    <>
                        <ModalTitle>{t('component.FeedbackSurvey.Modal.ModalTitle')}</ModalTitle>
                        <span className="modal-text">{t('component.FeedbackSurvey.Modal.ModalText1')}</span>
                        <span className="modal-text">{t('component.FeedbackSurvey.Modal.ModalText2')}</span>
                        <span className="modal-text">
                            {t('component.FeedbackSurvey.Modal.DoYouHave')}
                            <strong> {t('component.FeedbackSurvey.Modal.1Minute')} </strong>
                            {t('component.FeedbackSurvey.Modal.ToGiveUsYourOpinion')}
                        </span>
                        <ModalFooter>
                            <Button
                                dataId={createDataId(dataId, 'later-survey')}
                                className="modal-button"
                                onClick={() => setIsOpen(false)}
                                buttonType={ButtonType.SECONDARY}
                                buttonSize={ButtonSize.SMALL}
                            >
                                {t('component.FeedbackSurvey.MaybeLater')}
                            </Button>
                            <Button
                                dataId={createDataId(dataId, 'start-survey')}
                                className="modal-button"
                                onClick={() => {
                                    setIsOpen(false);
                                    window.open(getSurveyLink(userCountry, userRole, userLanguage), '_blank');
                                }}
                                buttonType={ButtonType.PRIMARY}
                                buttonSize={ButtonSize.SMALL}
                            >
                                {t('component.FeedbackSurvey.StartSurvey')}
                            </Button>
                        </ModalFooter>
                    </>
                </Modal>
            )}
        </>
    );
};

export default connect((state: GlobalState) => ({ boCurrentUserLoadable: state.boUser.boCurrentUserLoadable, currentLanguage: state.changeLanguage.currentLanguage }))(
    withSuspense(withTranslation()(SurveyMonkeyFeedback)),
);
