angular.module("dstreamApp.directives")
  .directive("ngEnterAsTab", function () {
    return {
      restrict: "A",
      link: linkFn
    };
  });

function linkFn (scope, element, attrs) {
  element.bind("keydown keypress", function (event) {
    if (event.which === 13) {
      event.preventDefault();
      var fields = element.parents("div").find("input, button, select, textarea");
      var index = fields.index(element);
      if (index > -1 && (index + 1) < fields.length) {
        fields.eq(index + 1).focus();
      } else {
        element[0].blur();
      }
    }
  });
}