import { FieldProps } from 'formik';
import { get } from 'lodash-es';
import * as React from 'react';

interface Props extends FieldProps {
    onChange?: (e: React.ChangeEvent<any> | any) => void;
    onBlur?: (e: any) => void;
    onFocus?: (e: React.FocusEvent<any>) => void;
}

type WrappedComponentType<P> = React.ComponentClass<P> | React.StatelessComponent<P>;

const withFormikField = <P extends {}>(WrappedComponent: WrappedComponentType<P>): React.ComponentClass<P & Props> => {
    return class WrapperComponent extends React.Component<P & Props> {
        render() {
            // workaround for "Rest types may only be created from object types error" when as any is not specified
            const { form, field, error, ...inputProps } = this.props as any;
            // make it possible to hook into onChange and onBlur methods to trigger side-effects
            // also make it possible to support custom values dispatched as onChange parameters, by default formik supports only Events as arguments
            // make it possible to supply custom error message provider
            let errorMessage = this.props.hasOwnProperty('error') ? error : get(form.touched, field.name) && get(form.errors, field.name);
            if (errorMessage && errorMessage.value) {
                // in some cases yup sets error into value key for some reason
                errorMessage = errorMessage.value;
            }
            const overrideProps = {
                onBlur: (e: any) => {
                    if (this.props.onBlur) {
                        this.props.onBlur(e);
                    }
                    if (e && e.nativeEvent && e.nativeEvent instanceof Event) {
                        field.onBlur(e);
                    } else {
                        form.setFieldTouched(this.props.field.name, true);
                    }
                },
                onChange: (e: React.ChangeEvent<any> | any) => {
                    if (this.props.onChange) {
                        this.props.onChange(e);
                    }
                    if (e && e.nativeEvent && e.nativeEvent instanceof Event) {
                        field.onChange(e as any);
                    } else {
                        form.setFieldValue(this.props.field.name, e);
                    }
                },
                onFocus: (e: React.FocusEvent<any> | any) => {
                    if (this.props.onFocus) {
                        this.props.onFocus(e);
                    }
                },
            };
            return <WrappedComponent {...inputProps} {...this.props.field} {...overrideProps} error={errorMessage} />;
        }
    };
};

export default withFormikField;
