import * as React from 'react';

import { is1to1, isGrantThornton, isSuf, isRiga, isMaxima } from '../../common/utils/whitelabelHelper';

import GrantThorntonLogo from './components/GrantThorntonLogo';
import OnetoOneLogo from './components/OnetoOneLogo';
import RigaLogo from './components/RigaLogo';
import SufLogo from './components/SufLogo';
import MaximaLogo from './components/MaximaLogo';
import FitekInLogo from './components/FitekInLogo';

export interface LogoProps {
    children?: React.ReactNode;
    className?: string;
    wrapperClassName?: string;
    compact?: boolean;
    onLogin?: boolean;
}

const Logo: React.FunctionComponent<LogoProps> = (props: LogoProps) => {
    if (isGrantThornton()) {
        return <GrantThorntonLogo {...props} />;
    }
    if (is1to1()) {
        return <OnetoOneLogo {...props} />;
    }
    if (isSuf()) {
        return <SufLogo {...props} />;
    }
    if (isRiga()) {
        return <RigaLogo {...props} />;
    }
    if (isMaxima()) {
        return <MaximaLogo {...props} />;
    }
    return <FitekInLogo {...props} />;
};

export default Logo;
