import { isEmpty } from 'lodash-es';

(function () {
  "use strict";
  // angular.module('dstreamApp.components')
  //   .component('dsAutoTransactionApply', {
  //     templateUrl: 'app/components/autoTransactions/ds-auto-transaction-apply.tpl.html',
  //     controller: DsAutoTransactionApplyController,
  //     bindings: {
  //     }
  //   });
  angular.module("dstreamApp.components")
    .controller("DsAutoTransactionApplyController", DsAutoTransactionApplyController);

  function DsAutoTransactionApplyController (activeInvoiceService, authenticationService, webServices, $uibModalInstance) {
    var ctrl = this;
    ctrl.template = {
      Name: "",
      Open: true
    };
    ctrl.selectedTemplate = {};
    ctrl.$onInit = init;
    ctrl.apply = apply;
    ctrl.cancel = cancel;
    ctrl.canAddAutoConvertTemplate = canAddAutoConvertTemplate;
    ctrl.disableTemplateSelection = disableTemplateSelection;
    ctrl.getTemplatesByName = getTemplatesByName;
    ctrl.setTemplate = setTemplate;
    ctrl.isTemplateSelected = false;
    ctrl.focusInput = focusInput;

    function init () {
      activeInvoiceService.activeInvoice().then(
        function (response) {
          ctrl.invoice = response;
        },
        function (error) {
          console.log("Request rejected due to:", error);
        }
      );
    }

    function setFocus () {
      angular.element(document.getElementById("select-automation-template-input")).focus();
    }

    setFocus();

    function apply () {
      $uibModalInstance.close({template: ctrl.selectedTemplate});
    }

    function focusInput ($event) {
      if (ctrl.template.Open) {
        return;
      }
      angular.element($event.currentTarget).parent().find("input").focus();
    }

    function cancel () {
      $uibModalInstance.dismiss("User selected cancel");
    }

    function canAddAutoConvertTemplate () {
      return (authenticationService.isAuthorized("CanAddAutoConvertTemplate") &&
        !!ctrl.invoice && ctrl.invoice.Status !== 5);
    }

    function disableTemplateSelection () {
      ctrl.confirmableRows = [];
      ctrl.rowsCountString = "";
      if (ctrl.invoice && ctrl.transactionRows) {
        var i;
        for (i = 0; i < ctrl.transactionRows.length; i++) {
          if (ctrl.isUserNextConfirmerInRow(ctrl.transactionRows[i])) {
            ctrl.confirmableRows.push(i + 1);
          }
        }
        for (i = 0; i < ctrl.confirmableRows.length; i++) {
          if (i === ctrl.confirmableRows.length - 1 && ctrl.confirmableRows.length >= 2) {
            ctrl.rowsCountString += " & ";
          } else if (i !== 0 && ctrl.confirmableRows.length >= 2) {
            ctrl.rowsCountString += ", ";
          }
          ctrl.rowsCountString += ctrl.confirmableRows[i];
        }
      }
      if (ctrl.invoice && ctrl.transactionRows && ctrl.transactionRows.length === ctrl.confirmableRows.length) {
        return false;
      } else {
        return !!ctrl.confirmableRows.length;
      }
    }

    function getTemplatesByName (name) {
      // var urlEnc = encodeURIComponent(name)
      return webServices.getAutoTransactionByNamePart(name, 100).then(function (response) {
        return response.data;
      }, function (data) {
        console.log(data);
      });
    }

    function setTemplate (item, model, label, event) {
      ctrl.selectedTemplate = item;
      ctrl.isTemplateSelected = !isEmpty(ctrl.selectedTemplate);
    }
  }
})();
