(function() {
    'use strict';

    angular
        .module('dstreamApp.views.archive-details')
        .component('dsStaticTransactionRows', {
            templateUrl: 'app/views/archive-details/components/static-transaction-rows/static-transaction-rows.html',
            controller: StaticTransactionRows,
            bindings: {
                invoice: '<',
                invoiceId: '<'
            }
        });

    StaticTransactionRows.$inject = ['$scope', 'localStorageService', 'webServices', 'constants'];

    function StaticTransactionRows($scope, localStorageService, webServices, constants) {
        var ctrl = this;
        var slideTime = 200;
        /* CONTROLLER VARIABLES */
        ctrl.visible = true;
        ctrl.loading = false;
        ctrl.transactionRows = [];
        ctrl.expandedRows = 0;
        ctrl.DATE_EE_FORMAT = constants.DATE_EE_FORMAT;
        ctrl.DATE_EE_FORMAT_TIME_ONLY = constants.DATE_EE_FORMAT_TIME_ONLY;
        ctrl.getTaskStatusTranslateKey = constants.getTaskStatusTranslateKey;
        ctrl.paginator = {
            currentPage: 1,
            takeRows: localStorageService.get('staticTransactionRowsPageSize') || 5,
            total: 0
        };

        /* CONTROLLER METHODS */
        ctrl.toggleComponent = toggleComponent;
        ctrl.toggleHistory = toggleHistory;
        ctrl.getTransactionRows = getTransactionRows;
        ctrl.expandParentRow = expandParentRow;
        ctrl.expandChildRow = expandChildRow;
        ctrl.expandAllRows = expandAllRows;
        ctrl.changePageSize = changePageSize;

        ctrl.$onInit = function() {
            getTransactionRows();
        };

        /* EVENTS & WATHCERS */
        var invoiceWatcher = $scope.$watch(function() {return ctrl.invoice}, function(newVal, oldVal) {
            if(newVal) {
                ctrl.paginator.total = newVal.InvoiceAccountingRowsTotal;
            }
        });

        /*
            On page change load new invoiceRows data
         */
        var currentPageWatcher = $scope.$watch(function() {return ctrl.paginator.currentPage}, function(newVal, oldVal) {
            if(newVal !== oldVal) {
                getTransactionRows(ctrl.invoiceId, newVal);
            }
        });

        /*
            Watch for the invoiceId change. If new invoiceData is loaded then the id changes and we should fetch new invoiceRows data
         */
        var invoiceIdWatcher = $scope.$watch(function() {return ctrl.invoiceId}, function(newVal, oldVal) {
            if(newVal !== oldVal) {
                getTransactionRows(newVal, 1);
            }
        });

        /*
            Expand/Collapse element
         */
        function toggleComponent() {
            ctrl.visible = !ctrl.visible;
        }

        /*
            Show/hide history for row
         */
        function toggleHistory(row) {
            row.historyVisible = !row.historyVisible;
        }

        /*
            Expand top level row
         */
        function expandParentRow($event, parentRow) {
            if (angular.element($event.target).closest('.table-transactions__checkbox').length ||
                angular.element($event.target).closest('.checkbox__label').length) {
                return;
            }

            if (parentRow.expanded) {
                parentRow.expanded = false;
                ctrl.expandedRows--;
            } else {
                parentRow.expanded = true;

                ctrl.expandedRows++;
            }
        }

        /*
            Expand lower level row
         */
        function expandChildRow($event, row) {
            if (angular.element($event.target).closest('input').length) {
                $event.stopPropagation();
                return;
            }
            row.expanded = !row.expanded;
        }

        /*
            Expand/collapse all rows
         */
        function expandAllRows(e) {
            e.preventDefault();
            if (ctrl.expandedRows === ctrl.transactionRows.length) {
                for (var i = 0; i < ctrl.transactionRows.length; i++) {
                    ctrl.transactionRows[i].expanded = false;
                }
                ctrl.expandedRows = 0;
            } else {
                for (var i = 0; i < ctrl.transactionRows.length; i++) {
                    ctrl.transactionRows[i].expanded = true;
                }
                ctrl.expandedRows = ctrl.transactionRows.length;
            }
        }

        /*
            Change how many invoice rows to display
         */
        function changePageSize(size) {
            ctrl.paginator.takeRows = size;
            ctrl.paginator.currentPage = 1;
            localStorageService.set('staticTransactionRowsPageSize', ctrl.paginator.takeRows); // save rowsCount to localstorage
            getTransactionRows(ctrl.invoiceId, 1); // get new transactionRowsData
        }

        /*
            Get transaction rows data from API
         */
        function getTransactionRows(id, page) {
            id = id || ctrl.invoiceId;
            page = page || ctrl.paginator.currentPage;
            ctrl.loading = true;

            return webServices.getArchiveAccountingRowsWithPaging(id, page - 1, ctrl.paginator.takeRows).then(function (res) {
                ctrl.loading = false;

                // expand all parent and child rows
                ctrl.transactionRows = res.data.map(function(parentRow) {
                    parentRow.expanded = true;
                    // if we have less than 10 child rows then expand them as well
                    return parentRow;
                });
                ctrl.expandedRows = ctrl.transactionRows.length;
            }, function(err) {
                ctrl.loading = false;
            });
        }

        ctrl.$onDestroy = function () {
            if(invoiceIdWatcher) invoiceIdWatcher();
            if(currentPageWatcher) currentPageWatcher();
            if(invoiceWatcher) invoiceWatcher();
        };
    }
})();