import { cloneDeep } from 'lodash-es';
"use strict";

angular
  .module("dstreamApp.components")
  .controller("DsTransactionRowsCombineController", DsTransactionRowsCombineController);

function DsTransactionRowsCombineController ($rootScope, webServices, $translate, $scope, $uibModalInstance, notifyService, param, $q, $filter) {
  var ctrl = this;
  ctrl.transactionRows = param.rows;
  ctrl.invoiceId = param.invoiceId;
  ctrl.methods = {};
  ctrl.selectedMethod = {};
  ctrl.listScrollable = 0;
  ctrl.modal = {};
  ctrl.setSelectedCombineMethod = setSelectedCombineMethod;
  ctrl.getSelectedCombineMethod = getSelectedCombineMethod;
  ctrl.combineRows = combineRows;

  init();

  // init
  $scope.cancelChange = function () {
    $uibModalInstance.close();
  };

  function init () {
    webServices.getTransactionRowsExtendedInfoList(ctrl.invoiceId).then(
      function (response) {
        if (response && response.data) {
          ctrl.methods = cloneDeep(response.data);
          ctrl.modal.body = angular.element(".modal__body");
          ctrl.modal.list = angular.element(".transactions-combine-modal__container");
          ctrl.modal.topFader = angular.element(".top--fader");
          ctrl.modal.botFader = angular.element(".bottom--fader");

          ctrl.modal.body.scroll(function () {
            if (ctrl.modal.body.hasClass("custom-scrollbar") && parseInt(ctrl.modal.body.scrollTop()) === 0) {
              ctrl.modal.topFader.addClass("hidden");
            } else if (ctrl.modal.body.hasClass("custom-scrollbar") && ctrl.modal.body.scrollTop() === (ctrl.modal.list.height() - ctrl.modal.body.height())) {
              ctrl.modal.botFader.addClass("hidden");
            } else {
              ctrl.modal.topFader.removeClass("hidden");
              ctrl.modal.botFader.removeClass("hidden");
            }
          });
        }
      }, function (error) {
        if (error) {
          console.error("Error fetching invoice details from the server:", error);
        }
      });
  }

  function setSelectedCombineMethod (method) {
    ctrl.selectedMethod = cloneDeep(method);
  }

  function getSelectedCombineMethod () {
    return ctrl.selectedMethod;
  }

  function combineRows (method) {

    if (!method.InformationName && !method.ContentCount) return;

    $rootScope.$emit("transactionRowsLoading");
    var result = {
      informationName: cloneDeep(method.InformationName),
      contentCount: cloneDeep(method.ContentCount),
      rowsCount: cloneDeep(method.RowsCount)
    };
    $uibModalInstance.close({invoiceId: ctrl.invoiceId, apiObject: result});
  }
}