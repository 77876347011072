import i18n from '../../src/i18n';
import { ICONS } from "../../src/components/Icon/Icon";
import { showUserProfile } from "../../src/components/UserProfile/UserProfileActions";
import { getCurrentUserGroupMember, setCurrentUserAction } from '../../src/common/user/UserActions';
import { setActiveCompanyGuid, showChangeCompany } from "../../src/components/ChangeCompany/ChangeCompanyActions";
import { selectActiveCompanyGuid } from "../../src/components/ChangeCompany/ChangeCompanyReducer";
import { showChangeLanguage } from "../../src/components/ChangeLanguage/ChangeLanguageActions";
import { selectCurrentLanguage } from "../../src/components/ChangeLanguage/ChangeLanguageReducer";
import { getCurrentCompanyName, selectAskToVerifyEmail, showEmailVerifiedNotification } from "../../src/common/user/UserSelectors";
import storeConfig from "../../src/storeConfig";
import { toggleMainMenuOpen } from "../../src/components/MainMenu/MainMenuActions";
import { cloneDeep } from "lodash-es";
import {AUTOFOCUS_ON_EMAIL_VERIFICATION_STRING} from '../../src/components/UserProfile/UserProfile';
import {isBackOffice} from '../../src/components/MainMenu/MainMenuHelper'


(function () {
    "use strict";

    angular
        .module("dstreamApp.controllers")
        .controller("rootController", RootController);

    RootController.$inject = [
        "$rootScope",
        "$scope",
        "$location",
        "localStorageService",
        "authenticationService",
        "templateService",
        "$filter",
        "uibPaginationConfig",
        "$window",
        "$uibModal",
        "_",
        "$route",
        "LocaleService",
        "$timeout",
        "webServices",
        "LOCALES",
        "$translate",
        "constants",
        "invoiceRegisterService",
        "websocketService",
        "utilityService",
        "companyDataService",
        "$ngRedux",
    ];

    function RootController($rootScope, $scope, $location, localStorageService, authenticationService, templateService, $filter, uibPaginationConfig, $window, $uibModal, _, $route, LocaleService, $timeout, webServices, LOCALES, $translate, constants, invoiceRegisterService, websocketService, utilityService, companyDataService, $ngRedux) {
        var ctrl = this;
        $scope.CurrentVersion = constants.CurrentVersion;
        $rootScope.CurrentVersion = constants.CurrentVersion;
        $rootScope.PublicUrl = constants.PublicUrl;
        $scope.utilityService = utilityService;
        $rootScope.ICONS = ICONS;
        $scope.headerStyleBeforeMenuCollapse = undefined;
        $scope.hasNewMessages = false;
        $rootScope.showOverlay = "";
        $scope.companySelectOpen = false;
        $rootScope.modalInstance = $uibModal;
        $rootScope.sessionModalInstance = $uibModal;
        $rootScope.changePasswordInstance = $uibModal;
        $rootScope.userDataLoading = false;
        $rootScope.isNewInvoice = false;
        $rootScope.isPDFVisible = false;
        $rootScope.pdfAutoOpen = false;
        $rootScope.companyData = {};
        $scope.header = {
            form: false,
            menu_style: "",
            settings_menu: "",
            top_menu_style: "",
            profile_menu: "",
            company_menu: "",
            boxed: "",
            header_topbar: "static",
            toggle: function (k, e) {
                e.preventDefault();
                switch (k) {
                    case "form":
                        $scope.header.form = !$scope.header.form;
                        break;
                    case "sidebar":
                        if (!$scope.header.menu_style) {
                            $scope.header.settings_menu = "";
                            $scope.header.profile_menu = "";
                            $scope.header.company_menu = "";
                        }
                        if ($scope.header.menu_style === "") {
                            $scope.header.menu_style = "sidebar-collapsed";
                        } else {
                            $scope.header.menu_style = "";
                        }
                        break;
                    case "settings":
                        $scope.header.settings_menu = $scope.header.settings_menu ? "" : "open";
                        $scope.header.profile_menu = "";
                        $scope.header.company_menu = "";
                        break;
                    case "profile":
                        $scope.header.profile_menu = $scope.header.profile_menu ? "" : "open";
                        $scope.header.settings_menu = "";
                        $scope.header.company_menu = "";
                        break;
                    case "company":
                        $scope.header.company_menu = $scope.header.company_menu ? "" : "open";
                        $scope.header.profile_menu = "";
                        $scope.header.settings_menu = "";
                        break;
                    case "chat":
                        $scope.header.chat = !$scope.header.chat;
                        $scope.hasNewMessages = false;
                        break;
                }
            },
            currentPage: function (k) {
                var url = window.location.href.split("?")[0];
                var parts = url.split("/");
                if (url.lastIndexOf("/") !== url.length - 1) {
                    var page = parts[parts.length - 1];
                } else {
                    var page = parts[parts.length - 2];
                }
                if (page == k) {
                    return "active";
                }
                return "";
            }
        };

        $scope.updateHeader = function (key, value) {
            $scope.header[key] = value;
        };

        // set default config for pagination
        uibPaginationConfig.boundaryLinkNumbers = true;
        $translate([
            "views.global.prev",
            "views.global.next"
        ]).then(function (translation) {
            uibPaginationConfig.previousText = translation["views.global.prev"];
            uibPaginationConfig.nextText = translation["views.global.next"];
        });

        ctrl.$onInit = function () {
            initBindings();
            initEventListeners();
            SetLocalisation();
            templateService.init(); // initialize custom templates(mostly for tooltips/popovers and modals)
            //websocketService.connect(); // connect websocket
            // hide the sidebar when everything won't fit on the screen at once
            if ($window.innerWidth < 1366) {
                $scope.header.menu_style = "sidebar-collapsed";
            }
        };

        // Clear search filter on this button click. We use this specific button instead of the route check, because in some cases we want to keep the filterObject when route changes
        $scope.clearSearchFilter = function (localStoragePrefix) {
            invoiceRegisterService.removeFilterFromLocalStorage(localStoragePrefix);
        };

        $scope.toggleOverlay = function (state) {
            $rootScope.showOverlay = state ? "showOverlay" : "";
        };

        $scope.showBetaNotification = function () {
            var path = $location.$$absUrl;
            if (path.indexOf("betaWebUI") > 0) {
                $scope.showBetaStyle = { "display": "block" };
                return true;
            }
            return false;
        };

        $scope.signOut = function (e, isUserLogout) {
            e.preventDefault();
            authenticationService.logOut(false, isUserLogout);
        };

        $scope.signOutId = function (e) {
            e.preventDefault();
            authenticationService.logOutId();
        };

        $scope.openTsekk = function () {
            var authData = authenticationService.getAuthData();
            $window.open("https://who.wizard.finance/api/partner/dstream/" + authData.token, "_blank");
        };

        $scope.openChangePassword = function () {
            $ngRedux.dispatch(showUserProfile(true));
        };

        $scope.showUserProfile = function () {
            $ngRedux.dispatch(showUserProfile(AUTOFOCUS_ON_EMAIL_VERIFICATION_STRING));
        };

        $scope.openChangeCompany = function () {
            $ngRedux.dispatch(showChangeCompany(true));
        };

        $scope.changeView = function (view) {
            $location.path(view);
        };

        const bodyClassWatcher = $rootScope.$watch(() => document.body.className, (bodyClassList) => {
            if (bodyClassList.contains('sidebar-collapsed')) {
                $scope.header.menu_style = 'sidebar-collapsed';
            } else {
                $scope.header.menu_style = '';
            }
        });

        const redirectToInvoice = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const invoiceId = urlParams.get('InvoiceId')
            const companyGuid = urlParams.get('CompanyGuid')
            if (invoiceId) {
                window.location.href = `/app/#/invoiceconfirmation/${invoiceId}?CompanyGuid=${companyGuid}`;
            }
        }

        /*
            Initialize event listeners
         */
        function initEventListeners() {
            // reset reset pagination when language changes
            $rootScope.$on("$translateChangeSuccess", function () {
                uibPaginationConfig.previousText = $filter("translate")("views.global.prev");
                uibPaginationConfig.nextText = $filter("translate")("views.global.next");
            });

            // close company select modal
            $rootScope.$on("application.closeCompanySelect", function (e) {
                $scope.companySelectOpen = false;
            });

            // collapse submenu when side menu collapses
            $rootScope.$on("application.collapseMenu", function (e) {
                $timeout(function () {
                    $scope.headerStyleBeforeMenuCollapse = cloneDeep($scope.header.menu_style);
                    $scope.header.menu_style = "sidebar-collapsed";
                    $ngRedux.dispatch(toggleMainMenuOpen(false));
                    $scope.$digest();
                });
            });

            // restore side menu collapse state
            $rootScope.$on("application.restoreMenuCollapse", function (e) {
                $timeout(function () {
                    if ($scope.headerStyleBeforeMenuCollapse === 'sidebar-collapsed') {
                        $ngRedux.dispatch(toggleMainMenuOpen(false));
                        $scope.header.menu_style = 'sidebar-collapsed';
                    } else if ($scope.headerStyleBeforeMenuCollapse === '') {
                        $ngRedux.dispatch(toggleMainMenuOpen(true));
                        $scope.header.menu_style = '';
                    }
                    delete $scope.headerStyleBeforeMenuCollapse;
                    $scope.$digest();
                });
            });

            // when route is about to change
            // here "next" is actually the event object
            $scope.$on("$routeChangeStart", function (next, current) { 
                if (current.$$route.originalPath === "/" && window.location.href.includes('/invoice/')) { // TODO: rewrite this with angular routing
                    redirectToInvoice();
                }
                if(isBackOffice(next.currentScope.pathname) && !isBackOffice(current.$$route.originalPath)) {
                    companyDataService.getCompanyData(true);
                }
                $scope.routeLoading = true;
                $scope.routerNotFound = !current;
                // if we are not navigating to certain routes (invoice register or invoice details) then clear the filter values
                if (current && current.$$route &&
                    current.$$route.originalPath !== "/invoices/" &&
                    current.$$route.originalPath !== "/invoices" &&
                    current.$$route.originalPath !== "/archive/" &&
                    current.$$route.originalPath !== "/archive" &&
                    current.$$route.originalPath !== "/invoiceconfirmation/:id" &&
                    current.$$route.originalPath !== "/archivedetails/:id" &&
                    current.$$route.originalPath !== "/invoiceconfirmation/" &&
                    current.$$route.originalPath !== "/transactions/:id/:archive?" &&
                    current.$$route.originalPath !== "/invoiceconfirmation") {
                    invoiceRegisterService.removeFilterFromLocalStorage();
                }
            });

            // when route change is successful
            $scope.$on("$routeChangeSuccess", function () {
                $scope.routeLoading = false;
            });
            $scope.$on("$locationChangeSuccess", function () {
                $scope.routeLoading = false;
                if ($scope.routerNotFound === undefined) {
                    $location.path("dashboard")
                }

                if ($scope.routerNotFound) {
                    window.location.href = '../Error/404.html';
                }
            });
        }

        /*
            Initialize binding events
         */
        function initBindings() {
            // hide the sidebar automatically when resizing the window and everything won't fit on screen at once
            angular.element($window).bind("resize", function () {
                if ($window.innerWidth < 1366 && !$scope.header.menu_style) {
                    if (document.querySelector("[data-id='mobileSideMenu.companySelect.input']") !== document.activeElement) {
                        $scope.header.menu_style = "sidebar-collapsed";
                        $scope.$digest();
                    }
                } else if ($window.innerWidth >= 1366 && $scope.header.menu_style) {
                    $scope.header.menu_style = "";
                    $scope.$digest();
                }
            });

            // Close all submenus when sidebar is collapsed and clicked elsewhere
            angular.element($window).bind("click", function (element) {
                if ($window.innerWidth <= 800 &&
                    !$(element.target).closest(".mobile-side-menu__element--has-submenu .sub-menu").length &&
                    !$(element.target).closest(".mobile-side-menu__element--has-submenu .mobile-side-menu__link").length &&
                    !$(element.target).closest(".mobile-side-menu__toggler a").length &&
                    !$(element.target).closest(".mobile-side-menu__spacer").length &&
                    !$(element.target).closest(".mobile-side-menu__languages").length) {
                    $scope.header.settings_menu = "";
                    $scope.header.profile_menu = "";
                    $scope.header.company_menu = "";
                    $scope.header.menu_style = "sidebar-collapsed";
                    return;
                }

                if ($("body").hasClass("sidebar-collapsed") &&
                    !$(element.target).closest(".side-menu__element--has-submenu a").length &&
                    !$(element.target).closest(".side-menu__element--has-submenu .sub-menu").length) {
                    $scope.header.settings_menu = "";
                }
            });
        }

        /*
            Set initial localisation(language)
         */
        function SetLocalisation() {
            $scope.currentLocaleDisplayName = LocaleService.getLocaleDisplayName();
            $scope.localesDisplayNames = LocaleService.getLocalesDisplayNames();
            $scope.visible = $scope.localesDisplayNames && $scope.localesDisplayNames.length > 1;

            $scope.changeLanguage = function (locale) {
                var localeArray = Object.keys(LOCALES.locales);
                var loc = _.find(localeArray, function (r) {
                    return LOCALES.locales[r] === locale;
                });
                webServices.changeUserLanguage(loc).then(function (result) {
                    $rootScope.$emit("languageChange");
                });
                LocaleService.setLocaleByDisplayName(locale);
                i18n.changeLanguage(loc);
            };
        }

        $scope.changeCompany = function (companyGuid) {
            $ngRedux.dispatch(setActiveCompanyGuid(companyGuid));
        };

        function changeCompany(companyGuid) {
            storeConfig.persistor.purge(); // purges persist data from local storage
            $rootScope.companyGuid = companyGuid;
            //websocketService.reconnect();
            authenticationService.changeCompany(companyGuid).then(function () {
                webServices.getCurrentUser().then(function(userResponse){
                    if (userResponse.status === 200 && userResponse.data) {
                        $ngRedux.dispatch(setCurrentUserAction(userResponse.data));
                        companyDataService.getCompanyData(true);
                        companyDataService.getCustomCostObjectives(false, true);
                        companyDataService.getAccounts(true);
                        $ngRedux.dispatch(getCurrentUserGroupMember(true));
                    } else {
                        throw new Error('User fetching failed.');
                    }
                }).catch(function(e) {
                    console.error(e);
                });

                if (($location.path().indexOf('dashboard') > -1)) {
                    $route.reload();
                } else {
                    $location.path('/dashboard/');
                }
            });
        }

        let currentActiveCompanyGuid;
        let currentLocaleLanguage;
        $ngRedux.subscribe(() => {
            let state = $ngRedux.getState();
            let previousActiveCompanyGuid = currentActiveCompanyGuid;
            let previousLocaleLanguage = currentLocaleLanguage;
            currentActiveCompanyGuid = selectActiveCompanyGuid(state);
            currentLocaleLanguage = selectCurrentLanguage(state);
            
            $scope.showTopWarning = selectAskToVerifyEmail(state) || showEmailVerifiedNotification(state);

            if (state.changeLanguage.isModalOpen && previousLocaleLanguage && previousLocaleLanguage !== currentLocaleLanguage) {
                $ngRedux.dispatch(showChangeLanguage(false));
                webServices.changeUserLanguage(currentLocaleLanguage).then(function (result) {
                    $rootScope.$emit("languageChange");
                });
                LocaleService.setLocaleByDisplayName(currentLocaleLanguage);
                $translate.use(currentLocaleLanguage);
                i18n.changeLanguage(currentLocaleLanguage);
            }

            // if company wasn't changed manually from CompanyChange component or mobile side menu, then do not force rootController to exec changeCompany() which redirects to dashboard
            const mobileSideMenu = document.querySelector(".mobile-side-menu__company-select");
            const isMobileSideMenuOpen = mobileSideMenu && mobileSideMenu.classList.contains("open");
            if (!state.changeCompany.isModalOpen && !isMobileSideMenuOpen) {
                return;
            }

            if (previousActiveCompanyGuid && previousActiveCompanyGuid !== currentActiveCompanyGuid) {
                // our active company changed
                $ngRedux.dispatch(showChangeCompany(false));
                changeCompany(currentActiveCompanyGuid);
            }
        });

        let unsubscribe = $ngRedux.connect((state) => ({ pathname: state.router.location.pathname, loggedInCompanyName: getCurrentCompanyName(state) }))($scope);

        $scope.$on('$destroy', () => {
            unsubscribe();
            bodyClassWatcher();
        });
        // log route change errors for future debugging purposes
        $rootScope.$on('$routeChangeError', function (event, current, previous, rejection) {
            console.error(rejection);
        });
    }
})();