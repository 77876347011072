import { createSelector } from 'reselect';

import { selectCompanyGroupMembers } from '../../common/company/CompanySelectors';
import { UserRole } from '../../common/constants/appConstants';
import { getCurrentUser } from '../../common/user/UserSelectors';
import { TypeaheadItem } from '../../components/Typeahead/TypeaheadAsync';
import { GlobalState } from '../../rootReducer';
import { GroupMemberDTO, PurchaseOrderStatus } from '../../services/types/ApiTypes';

export const selectRetrievePOLoadable = (state: GlobalState) => state.purchaseOrdersAdd.retrievePOLoadable;
export const selectPoTaskItemsLoadable = (state: GlobalState) => state.purchaseOrdersAdd.poTaskItemsLoadable;
export const selectPoRowsLoadable = (state: GlobalState) => state.purchaseOrdersAdd.poRowsLoadable;
export const selectPoFilesLoadable = (state: GlobalState) => state.purchaseOrdersAdd.poFilesLoadable;
export const selectUpdateExtraStatusLoadable = (state: GlobalState) => state.purchaseOrdersAdd.updateExtraStatusLoadable;
export const selectUploadFileLoadable = (state: GlobalState) => state.purchaseOrdersAdd.uploadFileLoadable;
export const selectUploadFileProgress = (state: GlobalState) => state.purchaseOrdersAdd.uploadFileProgress;
export const selectDownloadFileLoadable = (state: GlobalState) => state.purchaseOrdersAdd.downloadFileLoadable;
export const selectPurchaseOrderHistoryLoadable = (state: GlobalState) => state.purchaseOrdersAdd.purchaseOrderHistoryLoadable;
export const selectPurchaseOrderHistorySearchParams = (state: GlobalState) => state.purchaseOrdersAdd.searchParams;

export const selectPotentialApprovers = createSelector(
    selectCompanyGroupMembers,
    (groupMembers): Array<TypeaheadItem<GroupMemberDTO>> => {
        let potentialApprovers = groupMembers.filter((member) => {
            return member.IsActive && member.MemberRoles.find((role) => role.Role === UserRole.Processor);
        });
        potentialApprovers = potentialApprovers.sort((a, b) => {
            const firstNameComparison = a.User.FirstName.localeCompare(b.User.FirstName);
            if (firstNameComparison) {
                return firstNameComparison;
            }
            return a.User.LastName.localeCompare(b.User.LastName);
        });
        return potentialApprovers.map((approver) => {
            return {
                value: approver,
                text: approver.User.FullName,
            };
        });
    },
);

export const selectIsApproverAddingAvailable = createSelector(selectRetrievePOLoadable, getCurrentUser, selectPoTaskItemsLoadable, (poLoadable, currentUser, taskItemsLoadable): boolean => {
    return (
        currentUser &&
        poLoadable.payload &&
        poLoadable.payload.OrderStatus === PurchaseOrderStatus.Assigned &&
        (currentUser.MemberRoles.includes(UserRole.Processor) || currentUser.MemberRoles.includes(UserRole.PurchaseOrdersCreator)) &&
        taskItemsLoadable.payload &&
        !!taskItemsLoadable.payload.find((task) => task.GroupMemberId === currentUser.GroupMemberId)
    );
});
