import React from 'react';
import { InvoiceDTO, CompanyDTO, SupplierDTO, InvoiceTypeDTO, PaymentMethodDTO } from '../../../../../services/types/ApiTypes';
import { TypeaheadItem } from '../../../../../components/Typeahead/TypeaheadAsync';
import { SelectOptionValue } from '../../../../../components/Select/SelectOption';

export type InvoiceEditValues = Pick<
    InvoiceDTO,
    | 'AccountingDate'
    | 'ContractNumber'
    | 'Description'
    | 'DeleteTransactionRowsDuringSave'
    | 'DueDate'
    | 'InvoiceDate'
    | 'IsCredit'
    | 'Number'
    | 'PayToAccount'
    | 'ReferenceNumber'
    | 'SumWithoutVat'
    | 'TotalAmountWithVat'
    | 'Vat'
> & {
    Beneficiary: TypeaheadItem<string>;
    Currency: TypeaheadItem<string>;
    Supplier: TypeaheadItem<SupplierDTO>;
};

export type InvoiceEditAdditionalValues = Pick<InvoiceDTO, 'PurchaseOrder' | 'ContactName'> & {
    Buyer: TypeaheadItem<CompanyDTO>;
    DocumentType: SelectOptionValue<string>;
    SupplierContactPersonName: SupplierDTO['ContactPersonName'];
    SupplierEmail: SupplierDTO['Email'];
    InvoiceType: TypeaheadItem<InvoiceTypeDTO>;
    PaymentMethod: TypeaheadItem<PaymentMethodDTO>;
};

export enum InvoiceHeaderBlocks {
    BASIC_INFO = 'BasicInfo',
    ADDITIONAL_INFO = 'AdditionalInfo',
    CUSTOM_FIELDS = 'CustomFields',
    PURCHASE_ORDERS = 'PurchaseOrders',
    HISTORY = 'History',
}

export type PropertyListItemContainerProps = React.PropsWithChildren<{ isLoading?: boolean; label: string; dataId: string; alignRight?: boolean }>;
