import { cloneDeep } from 'lodash-es';
import {SearchType, SortDirection} from "../../../src/services/types/ApiTypes";
'use strict';

angular
    .module('dstreamApp.components')
    .controller('DsTransactionRowSplitController', DsTransactionRowSplitController);

function DsTransactionRowSplitController($rootScope, webServices, companyDataService, $translate, $scope, $uibModalInstance, notifyService, param, $filter, $timeout) {
    var ctrl = this;

    ctrl.$onInit = init;

    function init() {
        $scope.accountsLoading = true;
        loadAccounts();
    }

    $scope.transactionRow = param.row;
    // init
    $scope.staticCustomFields = [
        { Description: $filter('translate')('controller.accountListController.Account'), Code: '-', Id: '-1', Type: '1' }
    ];

    $scope.splitBy = {};
    $scope.splitParts = 2;
    $scope.allCustomCostObjectives = [];
    $scope.splitRows = [];

    $scope.accountsLoading = false;
    $scope.okTooltip = $filter('translate')('component.transactionRow.split.adjustValues');

    // scope functions
    $scope.rowHeader = function () {
        if ($scope.transactionRow.Description)
            return $scope.transactionRow.Description;
    };

    $scope.cancelChange = function () {
        $uibModalInstance.close();
    };

    $scope.saveSplit = function () {
        if (zeroValuesFound()) {
            return;
        }

        if ($scope.okTooltip) {
            return;
        }

        if (Math.abs(parseFloat($scope.getTotalSum())) !== 0) {
            $scope.okTooltip = $filter('translate')('component.transactionRow.split.adjustValues');
            return false;
        }

        for (var i = 0; i < $scope.splitRows.length; i++) {
            $scope.splitRows[i].sumMoney = parseFloat($scope.splitRows[i].sumMoney);
            $scope.splitRows[i].sumPercent = parseFloat($scope.splitRows[i].sumPercent);
        }

        $rootScope.$emit('transactionRowsLoading');
        var result = {
            transactionRow: $scope.transactionRow,
            splitBy: $scope.splitBy,
            splitRows: $scope.splitRows
        };
        $uibModalInstance.close(result);
    };

    $scope.doCreateRows = function () {
        if ($scope.checkMinMaxRows() != null) {
            return;
        }
        manageRows();
    };

    $scope.doDistributeEqually = function () {
        if ($scope.checkMinMaxRows() != null) {
            return;
        }
        manageRows();
        // recalculate values equally
        var total = 0;
        for (var j = 0; j < $scope.splitRows.length; j++) {
            $scope.splitRows[j].sumMoney = ($scope.transactionRow.SumWithoutVat / $scope.splitParts).toFixed(2);
            total = total + parseFloat($scope.splitRows[j].sumMoney);
            $scope.splitRows[j].sumPercent = (100 / $scope.splitParts).toFixed(3);
            $scope.splitRows[j].hasError = false;
            $scope.splitRows[j].error = "";
        }
        var remaining = $scope.transactionRow.SumWithoutVat - total;
        var lastValue = parseFloat($scope.splitRows[$scope.splitRows.length - 1].sumMoney);
        var result = lastValue + remaining;
        $scope.splitRows[$scope.splitRows.length - 1].sumMoney = result.toFixed(2);
    };

    $scope.updateField = function (item, row) {
        var found = _.find($scope.splitRows, function (r) {
            return r.id === row.id;
        });
        if (found) {
            found.customCostObjectiveId = item.CustomCostObjectiveId;
            found.dimensionId = item.Id;
        }
    };

    $scope.updateSplitBy = function (item) {
        // TODO: fix angular.copy for post-1.6.9
        $scope.splitBy = cloneDeep(item);
        for (var i = 0; i < $scope.splitRows.length; i++) {
            $scope.splitRows[i].fieldName = '';
            $scope.splitRows[i].fieldId = -1;
            $scope.splitRows[i].customCostObjectiveId = -1;
        }
    };

    $scope.getSplitByFieldByName = function (val) {
        val = val || '';
        const searchParams = {
            Restrictions: [
                {
                    Field: 'GeneralSearch',
                    Value: val,
                    Values: undefined,
                    FieldSearchType: SearchType.NotSelected,
                },
            ],
            SortItems: [
                {
                    SortColumn: 'Code',
                    SortDirection: SortDirection.Asc,
                },
            ],
            PagingOptions: {
                Page: 1,
                Count: 25,
            },
        };
        return webServices.getAccounts(searchParams, param.invoice.AccountingDate).then(
            (response) => response.data.Items.map((account) => {
                account.CodeName = `${account.Code} - ${account.Description}`;
                return account;
            })
        );
    };

    $scope.getAllCustomCostObjectivesByName = function (val = '') {
        const regex = new RegExp(val.toLowerCase());
        return _.filter($scope.allCustomCostObjectives, function (r) {
            return regex.test(r.Description.toLowerCase());
        });
    };

    $scope.recalculate = function (row, position) {
        // position == 1 -> SUM EUR
        // position == 2 -> %%

        if (position === 1) {
            if ($scope.transactionRow.SumWithoutVat !== 0) {
                row.sumPercent = (row.sumMoney * 100 / $scope.transactionRow.SumWithoutVat).toFixed(3);
            }
        } else {
            row.sumMoney = ($scope.transactionRow.SumWithoutVat * row.sumPercent / 100).toFixed(2);
        }

        row.error = "";
        row.hasError = false;
        if (isNaN(row.sumMoney)) {
            row.error = $filter('translate')('component.transactionRow.split.errorValueMustBeNumeric');
            row.hasError = true;
        }
        if (!isNaN(row.sumMoney) && row.sumMoney === 0) {
            row.error = $filter('translate')('component.transactionRow.split.errorValueMustBeGreaterThan0');
            row.hasError = true;
        }

        validate();
    };

    $scope.getTotalSum = function () {
        var totalSum = 0;
        for (var i = 0; i < $scope.splitRows.length; i++) {
            var currentValue = $scope.splitRows[i].sumMoney;
            totalSum += ((currentValue && !isNaN(currentValue)) ? parseFloat(currentValue) : 0);
        }
        totalSum = totalSum - $scope.transactionRow.SumWithoutVat;
        if (totalSum.toFixed(2) !== '0.00') {
            $scope.okTooltip = $filter('translate')('component.transactionRow.split.adjustValues');
        } else {
            $scope.okTooltip = null;
        }
        return totalSum.toFixed(2);
    };

    $scope.formatPercentCommaPlaces = function (row) {
        row.sumPercent = parseFloat(row.sumPercent).toFixed(3);
    };

    // internal functions
    function zeroValuesFound() {
        var result = false;
        for (var i = 0; i < $scope.splitRows.length; i++) {
            if ($scope.splitRows[i].sumMoney === 0) {
                $scope.splitRows[i].hasError = true;
                result = true;
            }
        }
        validate();
        return result;
    }

    function manageRows() {
        $scope.okTooltip = null;
        if ($scope.splitRows.length > 0 && $scope.splitParts < $scope.splitRows.length) {
            for (var i = $scope.splitRows.length - 1; i >= $scope.splitParts; i--) {
                $scope.splitRows.splice(i);
            }
        }
        var startPos = $scope.splitRows.length === 0 ? 0 : $scope.splitRows.length;
        for (var i = startPos; i < $scope.splitParts; i++) {
            var splitRow = {
                id: i + 1,
                fieldId: -1,
                customCostObjectiveId: -1,
                fieldName: '',
                sumMoney: 0,
                sumPercent: 0.0,
                error: $filter('translate')('component.transactionRow.split.errorValueMustBeGreaterThan0'),
                hasError: false
            };
            $scope.splitRows.push(splitRow);
        }
    }

    $scope.checkMinMaxRows = function () {
        if ($scope.splitParts < 2) {
            return 'component.transactionRow.split.minSplitRows';
        }
        if ($scope.splitParts > 100) {
            return 'component.transactionRow.split.maxSplitRows';
        }
        return null;
    };

    function setSelectedDimension() {
        // try to detect which dimensions are already set and if one does not have value, set it as selected
        var noValues = _.filter($scope.transactionRow.TransactionRowsDimensions, function (cf) {
            return !cf.DimensionId
        });

        if (noValues && noValues.length === 1 && $scope.transactionRow.AccountId) { // if Account already set
            var splitBy = _.find($scope.allCustomCostObjectives, function (ft) {
                return ft.Id === noValues[0].CustomCostObjective.Id
            });
            if (splitBy) {
                // TODO: fix angular.copy for post-1.6.9
                $scope.updateSplitBy(cloneDeep(splitBy));
            }
        } else if (noValues && noValues.length === 0 && !$scope.transactionRow.AccountId) { // if all other fields are set, except Account
            // TODO: fix angular.copy for post-1.6.9
            $scope.updateSplitBy(cloneDeep($scope.allCustomCostObjectives[0]));
        }
    }

    function loadCustomCostObjectives() {
        companyDataService.getCustomCostObjectives(true).then(
            function (response) {
                for (var i = 0; i < response.length; i++) {
                    _.each(response[i].Dimensions, function (f) {
                        f.CodeName = f.Code + ' - ' + f.Description;
                    });
                    $scope.allCustomCostObjectives.push(response[i]);
                }

                setSelectedDimension();
                $scope.accountsLoading = false;
            }
        );
    }

    $scope.getDimensions = function(name) {
        // if we have split by Account selected, use different method
        if($scope.splitBy && $scope.splitBy.Id === '-1') {
            return $scope.getSplitByFieldByName(name)
        }

        var namePart = name || "";
        var dimensions = [];
        var itemWithFullName = {};
        var searchParams = {
            SortItems: [
                {
                    SortColumn: "Code",
                    SortDirection: 0
                }
            ],
            PagingOptions: {
                Count: 25,
                Page: 0
            },
            Restrictions: [
                {
                    Field: "GeneralSearch",
                    Value: namePart,
                    FieldSearchType: 0
                }
            ],
        };
        if (ctrl.debounce) {
            $timeout.cancel(ctrl.debounce);
        }
        ctrl.debounce = $timeout(function () {
            const accountingDate = param.invoice.AccountingDate ? param.invoice.AccountingDate : undefined;
            dimensions = webServices.getDimensions($scope.splitBy.Id, searchParams, accountingDate).then(
                function (response) {
                    itemWithFullName = response.data.Items.map(function (item) {
                        item.CodeName = findCustomFieldItem(item);
                        return item;
                    });
                    return itemWithFullName;
                }
            );
            return dimensions;
        }, 200);
        return ctrl.debounce;
    }

    function findCustomFieldItem(dimension) {
        if (!dimension) {
            return null;
        }
        return dimension.Code + " - " + dimension.Description;
    }


    function loadAccounts() {
        const accountingDate = param.invoice.AccountingDate ? param.invoice.AccountingDate : undefined;
        webServices.getAccounts(undefined, accountingDate).then(function (response) {
            var accountField = $scope.staticCustomFields[0];
            accountField.Dimensions = [];
            for (var i = 0; i < response.data.Items.length; i++) {
                var item = {
                    'Id': response.data.Items[i].Id,
                    'Code': response.data.Items[i].Code,
                    'Name': response.data.Items[i].Number,
                    'CodeName': response.data.Items[i].Code + ' - ' + (response.data.Items[i].Number || response.data.Items[i].Description)
                };
                accountField.Dimensions.push(item);
            }

            $scope.allCustomCostObjectives.push(accountField);
            loadCustomCostObjectives();
        });
    }

    function validate() {
        $scope.okTooltip = null;

        var anyZeroMoney = _.some($scope.splitRows, function (row) {
            return row.hasError;
        });

        if (anyZeroMoney) {
            $scope.okTooltip = $filter('translate')('component.transactionRow.split.errorValueMustBeGreaterThan0');
        }
    }
}