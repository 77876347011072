(function () {
  "use strict";

  angular
    .module("dstreamApp.views.detailed-transactions")
    .component("dsDetailedTransactionsTableHeader", {
      templateUrl: "app/views/detailed-transactions/components/detailed-transactions-table-header/detailed-transactions-table-header.html",
      controller: DetailedTransactionsTableHeader,
      bindings: {
        gridApi: "=",
        tableOptions: "<",
        invoice: "<",
        showCombinePopover: "<",
        showApplyTemplate: "<",
        rowsToBeMerged: "<",
        isMergeDisabled: "<",
        openApplyModal: "&",
        openSaveModal: "&",
        openCombineModal: "&",
        setTableLoading: "&",
        calculateScrollbars: "&",
        getTransactionRowsData: "&",
        transactionRowsCount: "<",
        invoiceConfirmed: "<",
      }
    });

  DetailedTransactionsTableHeader.$inject = [
    "$rootScope",
    "$scope",
    "$filter",
    "$routeParams",
    "invoiceService",
    "activeInvoiceService",
    "detailedTransactionsTableService",
    "authenticationService",
    "companyDataService",
    "webServices",
    "$q"
  ];

  function DetailedTransactionsTableHeader (
    $rootScope,
    $scope,
    $filter,
    $routeParams,
    invoiceService,
    activeInvoiceService,
    detailedTransactionsTableService,
    authenticationService,
    companyDataService,
    webServices,
    $q
  ) {
    var ctrl = this;
    var xlsImportedEvent;

    ctrl.metainfos = [];
    ctrl.MergeOpen = false;
    ctrl.isMergeDisabled = false;
    ctrl.accountDistributionItemCustomCostObjectives = [];
    ctrl.allTransactionRowsVatRates = [];
    ctrl.autoCompleteTransactionTemplate = null;
    ctrl.autoCompleteTransactionTemplateOpen = false;
    ctrl.importXLSModalOpen = false;
    ctrl.transactionRowsCount = $scope.transactionRowsCount;
    ctrl.invoiceConfirmed = $scope.invoiceConfirmed;

    ctrl.authenticationService = authenticationService;

    ctrl.mergeRows = mergeRows;
    ctrl.onXlsImported = onXlsImported;
    ctrl.openImportXLSModal = openImportXLSModal;
    ctrl.openSaveAccountDistTemplateModal = openSaveAccountDistTemplateModal;

    ctrl.$onInit = function () {
      xlsImportedEvent = $rootScope.$on("xlsImported", onXlsImported);
      $q.all([
          webServices.getTransactionRowsExtendedInfoList($routeParams.id),
          webServices.getInvoiceTransactionRowsVatRates($routeParams.id),
      ]).then((responses) => {
          ctrl.metainfos = responses[0] && responses[0].data;
          ctrl.metainfoLoading = false;
          ctrl.allTransactionRowsVatRates = responses && responses[1].data || [];
      }).catch((e) => {
          ctrl.metainfoLoading = false;
      });
    };

    ctrl.$onDestroy = function () {
      if (xlsImportedEvent) xlsImportedEvent();
    };

    /*
        Merge selected rows
     */
    function mergeRows (mergeAll) {
      var selectedRowsArray = mergeAll ? ctrl.tableOptions.data : ctrl.gridApi.selection.getSelectedRows();

      if (selectedRowsArray.length > 1) {
        var rowsIdArray = _.map(selectedRowsArray, function (row) {
          return row.Id;
        });
        ctrl.setTableLoading({loading: true});

        invoiceService.mergeAccountingRows(rowsIdArray).then(function (res) {
          $scope.$emit("ds-detailed-transactions.reload", res);
          ctrl.getTransactionRowsData();
          // TODO shouldn't we already return combined rows in previous request
          // invoiceService.getTransactionRowsData(null, 10000).then(function (data) {
          //   ctrl.tableOptions.data = detailedTransactionsTableService.parseAccountantRows(data, ctrl.tableOptions.columnDefs);
          //   setTimeout(function () {
          //     ctrl.calculateScrollbars();
          //     ctrl.setTableLoading({loading: false});
          //     ctrl.gridApi.selection.clearSelectedRows();
          //   });
          // });
        });
      }
    }

    $scope.$on("ds-detailed-transactions.reloaded", function (event) {
      setTimeout(function () {
        ctrl.calculateScrollbars();
        ctrl.setTableLoading({loading: false});
        ctrl.gridApi.selection.clearSelectedRows();
      });
    });

    /*
        Open template save modal
     */
    function openSaveAccountDistTemplateModal () {
      invoiceService.openSaveAccountDistTemplateDialog(ctrl.invoice, {
        accountDistributionItemCustomCostObjectives: ctrl.accountDistributionItemCustomCostObjectives
      });
    }

    /*
        Open XSL import modal
     */
    function openImportXLSModal () {
      ctrl.importXLSModalOpen = false;
      $rootScope.modalInstance.open({
        templateUrl: "app/components/transactionRows/ds-import-xls-modal.html",
        controller: "DsImportXlsController",
        windowClass: "ds-import-xls-modal",
        scope: $scope
      });
    }

    /*
        Get whole transaction rows data again after the XLS import is complete
     */
    function onXlsImported () {
      ctrl.setTableLoading({loading: true});
      invoiceService.getTransactionRowsData(null, 10000).then(function (data) {
        ctrl.tableOptions.data = detailedTransactionsTableService.parseAccountantRows(data, ctrl.tableOptions.columnDefs);
        setTimeout(function () {
          ctrl.setTableLoading({loading: false});
        });
      });
    }
  }
})();