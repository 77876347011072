import classNames from 'classnames';
import * as React from 'react';

import Icon, { ICONS } from '../Icon/Icon';

import './Status.scss';

export enum StatusType {
    BLUE,
    YELLOW,
    GREEN,
    RED,
    GRAY,
    PURPLE,
}

export interface StatusProps {
    className?: string;
    type: StatusType;
    children?: React.ReactNode;
}

const Status: React.FunctionComponent<StatusProps> = (props: StatusProps) => {
    const statusClasses = {
        [StatusType.BLUE]: 'status--blue',
        [StatusType.YELLOW]: 'status--yellow',
        [StatusType.GREEN]: 'status--green',
        [StatusType.RED]: 'status--red',
        [StatusType.GRAY]: 'status--gray',
        [StatusType.PURPLE]: 'status--purple',
    };
    const classes = classNames('status', statusClasses[props.type]);
    return (
        <div className={classes} data-id="status">
            <Icon className={'status__icon'} name={ICONS.STATUS} />
            <span className={'status__text'}>{props.children}</span>
        </div>
    );
};

export default Status;
