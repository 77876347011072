import * as React from 'react';
import { WithTranslation } from 'react-i18next';
import { Dispatchable1 } from 'redux-dispatchers';
import ContentBlockHeading, { ContentBlockHeadingType } from '../ContentBlock/ContentBlockHeading';
import { createDataId } from '../../common/utils/dataId';
import { Button, ButtonType } from '../Buttons/Button';
import Modal, { ModalType } from '../Modal/Modal';
import { languages } from '../../i18n';

import './ChangeLanguage.scss';
import { Typography } from '../Typography';

export interface Props {
    isModalOpen: boolean;
    currentLanguage: string;
}

export interface DispatchProps {
    showChangeLanguage: Dispatchable1<boolean>;
    changeLanguage: Dispatchable1<string>;
}

export type ChangeLanguageProps = DispatchProps & Props & WithTranslation;

export const ChangeLanguage: React.FC<ChangeLanguageProps> = ({ t, currentLanguage, isModalOpen, showChangeLanguage, changeLanguage }): React.ReactElement => {
    const handleChangeLanguage = (lang: string) => {
        changeLanguage(lang);
    };

    return (
        <Modal
            isOpen={isModalOpen}
            type={ModalType.FIXED_RIGHT}
            onClose={() => {
                showChangeLanguage(false);
            }}
            className={'change-language'}
            dataId="changeLanguageModal"
        >
            <ContentBlockHeading type={ContentBlockHeadingType.BOLD} dataId="ChangeLanguageHeader">
                <Typography variant="h1" element="span">
                    {t('component.changeLanguage.chooseLanguage')}
                </Typography>
            </ContentBlockHeading>
            <div className="change-language__languages">
                {languages.map((language) => (
                    <Button
                        isActive={language.locale === currentLanguage}
                        className="change-language__support-language"
                        dataId={createDataId('languageButton', language.name)}
                        buttonType={ButtonType.FLUID}
                        key={language.locale}
                        onClick={() => {
                            handleChangeLanguage(language.locale);
                        }}
                        icon={language.icon}
                    >
                        {t(language.name)}
                    </Button>
                ))}
            </div>
        </Modal>
    );
};
