import {Role} from "../../../../../src/common/user/userPermissionUtil";

(function () {
  "use strict";

  angular
    .module("dstreamApp.components")
    .component("dsAutoTransactionsPopover", {
      templateUrl: "app/components/transactionRows/components/autoTransactionsPopover/ds-auto-transactions-popover.tpl.html",
      bindings: {
        canAdd: '<',
        canApply: '<',
        activeInvoice: "<",
        alignmentClass: "<",
        applyTemplate: "&",
        saveNewTemplate: "&"
      },
      controller: DsAutoTransactionsPopoverController
    });

  function DsAutoTransactionsPopoverController (activeInvoiceService, authenticationService) {
    var ctrl = this;
    //

    ctrl.$onInit = init;
    ctrl.canAddAutoConvertTemplate = canAddAutoConvertTemplate;
    ctrl.canUpdateAutoConvertTemplate = canUpdateAutoConvertTemplate;
    ctrl.isInvoiceConfirmed = isInvoiceConfirmed;
    ctrl.isTemplateApplyDisabled = isTemplateApplyDisabled;

    function init () {
      // activeInvoiceService.activeInvoice().then(
      //   function (response) {
      //     ctrl.invoice = response;
      //   },
      //   function (error) {
      //     console.log("Request rejected due to:", error);
      //   }
      // );
      //
    }

    function canAddAutoConvertTemplate () {
      return (authenticationService.isAuthorized("canAddAutoConvertTemplate") && !isInvoiceConfirmed());
    }

    function canUpdateAutoConvertTemplate () {
      return (authenticationService.isAuthorized(Role.CanUpdateAutoConvertTemplate) && !isInvoiceConfirmed());
    }

    function isInvoiceConfirmed () {
      if (!ctrl.activeInvoice) {
        return false;
      } else {
        return ctrl.activeInvoice.Status > 2;
      }
    }

    function isTemplateApplyDisabled() {
        return !ctrl.canUpdateAutoConvertTemplate();
    }
  }
})();
