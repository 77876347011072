(function() {
    'use strict';

    angular
        .module('dstreamApp.views.archive-details')
        .config(ArchiveDetailsRoute);

    ArchiveDetailsRoute.$inject = ['customRouteProvider'];

    function ArchiveDetailsRoute(customRouteProvider) {
        customRouteProvider
            .when('/archivedetails/:id', {
                template: '<ds-archive-details></ds-archive-details>',
                access: {
                    requiresLogin: true
                },
                resolve: {
                }
            });
    }
})();