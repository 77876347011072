import { LOCATION_CHANGE } from 'connected-react-router';
import produce from 'immer';
import { cloneDeep } from 'lodash-es';
import { Action } from 'redux-actions';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LoadableData } from '../../../common/utils/LoadableData';
import { GlobalState } from '../../../rootReducer';
import { AccountDTO, BaseSearch, PagedListContainer, SearchType, SortDirection } from '../../../services/types/ApiTypes';

import { addNewRow, loadAccountsLoadableActions, removeRow, updateRow } from './AccountDetailsViewActions';

export const DEFAULT_RESTRICTION = 'GeneralSearch';

class State {
    accountsLoadable = new LoadableData<PagedListContainer<AccountDTO>, BaseSearch>();

    accountsSearchParams: BaseSearch = {
        Restrictions: [
            {
                Field: DEFAULT_RESTRICTION,
                Value: '',
                Values: undefined,
                FieldSearchType: SearchType.NotSelected,
            },
        ],
        SortItems: [
            {
                SortColumn: 'Code',
                SortDirection: SortDirection.Asc,
            },
        ],
        PagingOptions: {
            Page: 1,
            Count: 15,
        },
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        loadAccountsLoadableActions.request,
        (state, action): State => {
            return {
                ...state,
                accountsSearchParams: action.payload,
                accountsLoadable: state.accountsLoadable.withLoading(action.payload),
            };
        },
    )
    .addReducer(
        loadAccountsLoadableActions.success,
        (state, action): State => {
            return {
                ...state,
                accountsSearchParams: action.payload.request,
                accountsLoadable: state.accountsLoadable.withPayloadIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        loadAccountsLoadableActions.error,
        (state, action): State => {
            return {
                ...state,
                accountsLoadable: state.accountsLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        addNewRow,
        produce((draft) => {
            draft.accountsLoadable.payload.Items.push({
                Id: 0, // NEW_ROW_ID,
                EndDate: undefined,
                StartDate: undefined,
                Description: undefined,
                Code: undefined,
                InvoiceCountInUse: 0,
                IsNew: false,
                AdditionalFields: [],
                AutoTransactionCountInUse: undefined,
                AssignedCurrentToUser: undefined,
            });
            draft.accountsLoadable.payload.TotalCount++;
        }),
    )
    .addReducer(
        updateRow,
        produce((draft, action: Action<{ result: AccountDTO; itemToUpdate: number }>) => {
            const index = draft.accountsLoadable.payload.Items.findIndex((i: AccountDTO) => i.Id === action.payload.itemToUpdate);
            const result = action.payload.result;
            // reduce autosave updates because of different field values that do not affect FE
            result.AdditionalFields = result.AdditionalFields.map((f) => {
                f.Id = undefined; // ignore Id in FE, because the update will always create new fields and this causes change loop in FE
                f.IsNew = undefined;
                return f;
            });
            const previousValue = draft.accountsLoadable.payload.Items[index];
            draft.accountsLoadable.payload.Items[index] = {
                ...result,
                InvoiceCountInUse: previousValue.InvoiceCountInUse,
                AutoTransactionCountInUse: previousValue.AutoTransactionCountInUse,
            };
        }),
    )
    .addReducer(
        removeRow,
        produce((draft, action) => {
            draft.accountsLoadable.payload.Items = draft.accountsLoadable.payload.Items.filter((i: AccountDTO) => i.Id !== action.payload);
        }),
    )
    .addReducer(
        LOCATION_CHANGE,
        (state): State => {
            const searchRestriction = cloneDeep(state.accountsSearchParams.Restrictions[0]);
            searchRestriction.Value = '';
            return {
                ...new State(),
                accountsSearchParams: {
                    ...state.accountsSearchParams,
                    Restrictions: [searchRestriction],
                },
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'accounting/accounts',
    whitelist: ['accountsSearchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as AccountDetailsViewState };

export const selectAccountsLoadable = (state: GlobalState) => state.accountDetails.accountsLoadable;
export const selectAccountsSearchParams = (state: GlobalState) => state.accountDetails.accountsSearchParams;
