import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Dispatchable1 } from 'redux-dispatchers';

import i18nInstance from '../../i18n';
import api from '../../services/ApiServices';
import { FileDTO } from '../../services/types/ApiTypes';
import { notify } from '../../common/utils/notify';

import './PDFViewer.scss';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const publicUrl = window.publicUrl;

const VIEWER_URL = `${publicUrl}/app/pdf/web/viewer.html?`;

export interface PDFViewerProps {
    isNewInvoice?: boolean;
    pdfToOpen: FileDTO | null;

    isFromDashboard?: boolean;
    inline?: boolean;
    $rootScope?: any;
}

export interface DispatchProps {
    setPdfToOpen: Dispatchable1<FileDTO | null>;
}

type Props = PDFViewerProps & DispatchProps;

type PdfViewerStyles = {
    left: string;
    right: string;
    top: string;
    width: string;
    height: string;
    display?: string;
    minWidth?: string;
    position?: string | any;
};

const PDFViewer = (props: Props) => {
    const { pdfToOpen, inline, isFromDashboard, setPdfToOpen, $rootScope } = props;
    const [viewerStyle, setViewerStyle] = useState<PdfViewerStyles>();

    const warningElement = document.getElementById('top-warning');
    const transactionsHeader = document.getElementsByClassName('transactions__header')[0];
    const transactionsHeaderHeight = transactionsHeader ? transactionsHeader.getBoundingClientRect().height : 0;

    const calculatePosition = () => {
        const isHalfWidthed = location.href?.includes('invoiceconfirmation') || location.href?.includes('dashboard') || location.href?.includes('archivedetails');
        let left: string;

        if (isHalfWidthed) {
            left = '50%';
        } else {
            const elemensToGetOffset = document.getElementsByClassName(inline ? 'file-viewer' : isFromDashboard ? 'approvers-dash' : 'invoice-header');
            let width = 0;
            let leftOffset = 0;
            if (elemensToGetOffset.length) {
                const elemBoundingRect = elemensToGetOffset[0].getBoundingClientRect();
                const leftEdge = elemBoundingRect.left;
                width = elemBoundingRect.width;
                leftOffset = inline ? leftEdge : leftEdge + width;
            }
            left = window.innerWidth < 767 ? '0' : leftOffset + 'px';
        }

        const position: PdfViewerStyles = {
            left,
            height: '100%',
            right: '0',
            width: window.innerWidth < 767 ? '100vw' : 'auto',
            top: warningElement ? `${warningElement?.clientHeight + transactionsHeaderHeight}px` : `${transactionsHeaderHeight}px`,
            minWidth: '100px',
        };

        setViewerStyle(position);
    };

    const openFile = async (file: FileDTO) => {
        if (file.IsPdf) {
            if (!file.FileUrl) {
                if (file.Base64Content) {
                    $rootScope.isPDFVisible = true;
                    const pdfjsframe = document.getElementById('file-viewer__frame') as HTMLIFrameElement;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    pdfjsframe.contentWindow.postMessage({ base64: file.Base64Content, fileName: file.FileName }, publicUrl || window.location.origin);
                    calculatePosition();
                } else {
                    console.warn('File URL is missing!');
                }
                return;
            }

            const fileNameEncoded = encodeURIComponent(file.FileName);
            const fromArchive = location.href.includes('archivedetails') ? '_true' : '';
            await api.invoice
                .displayFileById(file.Id, fromArchive)
                .then((response) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onloadend = function() {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                        // @ts-ignore
                        const base64data = reader.result.split(',')[1];
                        const pdfjsframe = document.getElementById('file-viewer__frame') as HTMLIFrameElement;
                        pdfjsframe.contentWindow.postMessage({ base64: base64data, fileName: fileNameEncoded }, publicUrl || window.location.origin);
                    };
                })
                .catch((e) => {
                    console.error(e);
                    notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
                });

            // TODO: remove if not needed anymore
            // if (deviceDetector.isMobile() && $location.path() === '/dashboard') {
            //     // A trick to handle issue click mobile back button https://fitekgroup.atlassian.net/browse/EMR-4137
            //     $location.search('pdfView', 'open');

            //     // needs to be in timeout so it doesn't get fired when location.search fires, since it's async
            //     setTimeout(() => {
            //         window.addEventListener('popstate', $scope.handleMobilePdfFromDashboard);
            //     })
            // }
            $rootScope.$emit('application.collapseMenu');
            $rootScope.isPDFVisible = true;
            calculatePosition();
        }
    };

    const closeFile = () => {
        setPdfToOpen(null);
        setViewerStyle({
            ...viewerStyle,
            width: '0',
            height: '100vh',
            position: 'absolute',
            left: '-10000px',
            top: '-10000px',
        });
        $rootScope.isPDFVisible = false;
        $rootScope.$emit('application.restoreMenuCollapse');
    };

    useEffect(() => {
        return closeFile;
    }, []);

    useEffect(() => {
        if (pdfToOpen) {
            const pdfjsframe = document.getElementById('file-viewer__frame') as HTMLIFrameElement;
            pdfjsframe.contentWindow.postMessage({ base64: null, fileName: null }, publicUrl || window.location.origin);
            openFile(pdfToOpen);
        } else {
            closeFile();
        }
    }, [pdfToOpen]);

    const bubbleIframeMouseEvent = (iframe: HTMLIFrameElement, eventType: string) => {
        // Save any previous onmousemove handler
        const existingOnMouseEvent = iframe.contentWindow[eventType];

        // Attach a new onmousemove listener
        iframe.contentWindow[eventType] = function(e: MouseEvent) {
            // Fire any existing onmousemove listener
            if (existingOnMouseEvent) {
                existingOnMouseEvent(e);
            }
            let mouseEvent = '';
            switch (eventType) {
                case 'onmousemove':
                    mouseEvent = 'mousemove';
                    break;
                case 'onclick':
                    mouseEvent = 'click';
                    break;
                case 'onmouseup':
                    mouseEvent = 'mouseup';
                    break;
                default:
                    break;
            }

            // We'll need this to offset the mouse move appropriately
            const boundingClientRect = iframe.getBoundingClientRect();

            // Initialize the event, copying exiting event values for the most part
            let evt = null;
            try {
                evt = new MouseEvent(mouseEvent, {
                    bubbles: true,
                    cancelable: false,
                    view: window,
                    detail: e.detail,
                    screenX: e.screenX,
                    screenY: e.screenY,
                    clientX: e.clientX + boundingClientRect.left,
                    clientY: e.clientY + boundingClientRect.top,
                    ctrlKey: e.ctrlKey,
                    altKey: e.altKey,
                    shiftKey: e.shiftKey,
                    metaKey: e.metaKey,
                    button: e.button,
                    relatedTarget: null,
                });
            } catch (e) {
                // polyfill
                evt = document.createEvent('MouseEvent');
                evt.initMouseEvent(
                    mouseEvent,
                    true,
                    false,
                    window,
                    e.detail,
                    e.screenX,
                    e.screenY,
                    e.clientX + boundingClientRect.left,
                    e.clientY + boundingClientRect.top,
                    e.ctrlKey,
                    e.altKey,
                    e.shiftKey,
                    e.metaKey,
                    e.button,
                    e.relatedTarget,
                );
            }

            // Dispatch the mousemove event on the iframe element
            iframe.dispatchEvent(evt);
        };
    };

    const iframeLoaded = (element: HTMLIFrameElement) => {
        if (!publicUrl || publicUrl.includes(window.location.origin)) {
            bubbleIframeMouseEvent(element, 'onmouseup');
            bubbleIframeMouseEvent(element, 'onmousemove');
            bubbleIframeMouseEvent(element, 'onclick');
        }
    };

    return (
        <div className={`file-viewer ${inline ? 'file-viewer--inline' : ''}`} style={inline ? {} : viewerStyle}>
            <div className={`file-viewer__wrap ${pdfToOpen?.FileName ? 'full' : ''}`} style={warningElement ? { height: `calc(100vh - ${warningElement.clientHeight}px)` } : {}}>
                <p className="file-viewer__actions clearfix">
                    <button
                        data-uib-tooltip='{{"component.relatedDocuments.close" | translate}}'
                        data-tooltip-append-to-body="true"
                        data-tooltip-placement="auto"
                        className="btn btn--alt file-viewer__close"
                        data-id="closeFilePreview"
                        onClick={closeFile}
                    >
                        <svg className="icon">
                            <use xlinkHref="#icon-close" />
                        </svg>
                    </button>
                </p>
                <iframe id="file-viewer__frame" onLoad={(e: SyntheticEvent<HTMLIFrameElement, Event>) => iframeLoaded(e.currentTarget)} src={VIEWER_URL} className="pdfIframe"></iframe>
            </div>
        </div>
    );
};

export default PDFViewer;
