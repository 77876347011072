import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { GlobalState } from '../../rootReducer';
import { setExternalLinkToOpen } from './OpenExternalLinkConfirmModalActions';
import { OpenExternalLinkConfirmModal, OpenExternalLinkConfirmModalProps, DispatchProps } from './OpenExternalLinkConfirmModal';
import { selectExternalLinkToOpen } from './OpenExternalLinkConfirmModalReducer';

const mapStateToProps = (state: GlobalState): OpenExternalLinkConfirmModalProps => {
    return {
        linkToOpen: selectExternalLinkToOpen(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    setExternalLinkToOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OpenExternalLinkConfirmModal));
