import React from 'react';
import { GroupTasksDTO, TaskType } from '../InvoiceConfirmations';
import Icon from '../../../../../components/Icon/Icon';
import { getGroupTaskIconProgressBar, getTaskContentStyles } from '../InvoiceConfirmationsHelper';
import { AssignBeforeAfter } from './AssignBeforeAfter';
import { ApproverPerStep, InvoiceDTO, TaskDTO, UserDataDTO } from '../../../../../services/types/ApiTypes';
import { useTranslation } from 'react-i18next';
import TaskItem from './TaskItem';
import { createDataId } from '../../../../../common/utils/dataId';
import { WithDataId } from 'src/common/utils/dataId';
import { getStaticStepsCounterForGroupTask, hasCurrentConfirmerAndComment } from './utils';
import CommentInfo from './CommentInfo';

export interface GroupTasksProps extends WithDataId {
    approversPerSteps: ApproverPerStep[]; // current temporary version of ApproversPerSteps (may be different from that in an invoice)
    invoice: InvoiceDTO;
    userData: UserDataDTO;
    groupTasks: GroupTasksDTO[];
    hasAddBefore: (i: number) => boolean;
    hasAddAfter: (i: number) => boolean;
    addApprover: (taskItem: TaskDTO, type: TaskType) => void;
    cancelAddApproverBefore: (task: GroupTasksDTO) => void;
    approveInvoice: any;
}

/**
 * Render the InvoiceConfirmations grouped tasks
 * Loop through the grouped tasks and render content accordingly
 * If user clicks on AddBefore or AddAfter then render the AssignBeforeAfter component instead of the GroupTask
 */
const GroupTasks = (props: GroupTasksProps) => {
    const { approversPerSteps, dataId, invoice, userData, groupTasks, hasAddAfter, hasAddBefore, addApprover, cancelAddApproverBefore, approveInvoice } = props;
    const { t } = useTranslation();

    return (
        <>
            {groupTasks.map((groupTask, i) => {
                const isDefaultTask = groupTask.type === TaskType.Default; // is it a valid saved task (not one just added by user)
                const XofY = getStaticStepsCounterForGroupTask(approversPerSteps, groupTask);
                const isParallel = isDefaultTask && !XofY && groupTask.group.length > 1;
                const isStatic = isDefaultTask && !!XofY && groupTask.group.length > 1;
                const showCommentInfo = hasCurrentConfirmerAndComment(groupTask);

                return (
                    <div className="confirmation__task" key={i}>
                        <div className="confirmation__task-icon">
                            <Icon iconName={getGroupTaskIconProgressBar(groupTask.group, XofY)} />
                        </div>
                        <div data-id={createDataId(dataId, 'task', i)} className={getTaskContentStyles(groupTask.group)}>
                            {isStatic && (
                                <span>
                                    {XofY} {t('component.confirmationFlow.of')} {groupTask.group.length}
                                </span>
                            )}
                            {isParallel && <span>Parallel</span>}
                            {isDefaultTask ? (
                                groupTask.group.map((taskItem, id) => (
                                    <TaskItem
                                        dataId={createDataId(dataId, 'task', i, 'item', id)}
                                        taskItem={taskItem}
                                        key={taskItem.Id}
                                        hasAddAfter={hasAddAfter(i)}
                                        hasAddBefore={hasAddBefore(i)}
                                        userData={userData}
                                        isShowIcon={groupTask.group.length > 1}
                                        addApprover={addApprover}
                                    />
                                ))
                            ) : (
                                <AssignBeforeAfter groupTask={groupTask} invoice={invoice} t={t} approveInvoice={approveInvoice} onCancel={(task) => cancelAddApproverBefore(task)} />
                            )}
                        </div>
                        {showCommentInfo && <CommentInfo content={t('component.commentInfo.deletedAfterYourAction')} />}
                    </div>
                );
            })}
        </>
    );
};

export default GroupTasks;
