import { ReducerFactory } from 'redux-actions-ts-reducer';

import { TableFilter } from '../../../../../../components/Table/components/filter/TableFilters';
import { BaseSearchWithTableFilters, PagedListContainer, InvoiceHeaderHistoryDTO } from '../../../../../../services/types/ApiTypes';
import { LoadableData } from '../../../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../../../rootReducer';
import { getInvoiceHeaderHistoryLoadable } from './HistoryListActions';
import { createRequest } from './HistoryListHelper';

export type HistorySearchParams = BaseSearchWithTableFilters<TableFilter<any>, InvoiceHeaderHistoryDTO>;

class State {
    invoiceHistoryLoadable = new LoadableData<PagedListContainer<InvoiceHeaderHistoryDTO>, HistorySearchParams>();
    searchParams: HistorySearchParams = {
        ...createRequest('', 1),
    };
}

export { State as InvoiceHeaderHistoryListState };

export default new ReducerFactory(new State())
    .addReducer(
        getInvoiceHeaderHistoryLoadable.request,
        (state, action): State => {
            const invoiceHistoryLoadable = state.invoiceHistoryLoadable.withLoading(action.payload);
            return {
                ...state,
                searchParams: action.payload,
                invoiceHistoryLoadable,
            };
        },
    )
    .addReducer(
        getInvoiceHeaderHistoryLoadable.success,
        (state, action): State => {
            const invoiceHistoryLoadable = state.invoiceHistoryLoadable.withPayloadIfRequestEquals(action.payload);
            return {
                ...state,
                searchParams: action.payload.request,
                invoiceHistoryLoadable,
            };
        },
    )
    .addReducer(
        getInvoiceHeaderHistoryLoadable.error,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                invoiceHistoryLoadable: LoadableData.error(action.payload.result),
            };
        },
    )
    .toReducer();

export const selectInvoiceHeaderHistoryListLoadable = (state: GlobalState) => state.invoiceHeaderHistoryList.invoiceHistoryLoadable;
export const selectInvoiceHeaderHistoryListSearchParams = (state: GlobalState) => state.invoiceHeaderHistoryList.searchParams;
