import { loadableDataActions } from '../../common/utils/LoadableData';
import { GlobalState } from '../../rootReducer';
import { BaseSearch, PagedListContainer, PurchaseOrdersDTO } from '../../services/types/ApiTypes';
import api from '../../services/ApiServices';
import { DispatchThunk } from '../../storeConfig';

const ns = 'dashboard/';

export const dashboardPurchaseOrdersLoadable = loadableDataActions<BaseSearch, PagedListContainer<PurchaseOrdersDTO>>(`${ns}GET_PURCHASE_ORDERS_TO_CONFIRM`);

export function getPurchaseOrdersToConfirm(searchParams: BaseSearch | undefined) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        searchParams = searchParams || state.dashboardPurchaseOrders.searchParams;
        let response;
        try {
            dispatch(dashboardPurchaseOrdersLoadable.request(searchParams));
            response = await api.purchaseOrder.getListForDashboard(searchParams);
            if (!response.data.Items.length && !!response.data.Skip && searchParams.PagingOptions.Page !== 1) {
                const paging = {
                    ...searchParams.PagingOptions,
                    Page: searchParams.PagingOptions.Page - 1,
                };
                dispatch(
                    getPurchaseOrdersToConfirm({
                        ...searchParams,
                        PagingOptions: paging,
                    }),
                );
            }
            dispatch(dashboardPurchaseOrdersLoadable.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(dashboardPurchaseOrdersLoadable.error(e));
        }
    };
}
