import React from 'react';
import { isEmpty, cloneDeep } from 'lodash-es';
import { createAction } from 'redux-actions';
import { saveAs } from 'file-saver';

import { getViewUserSearchParams, ListViewUserConfig, getViewUserSettings, setUserSettingValue } from '../../../../common/user/userSettingUtil';
import { notify } from '../../../../common/utils/notify';
import { loadableDataActionsWithRequest, loadableDataActions } from '../../../../common/utils/LoadableData';
import { getCurrentUserGroupMember, updateUserSettings } from '../../../../common/user/UserActions';
import { validateAndFixPagingOptions } from '../../../../common/utils/baseSearchHelpers';
import { formatDate } from '../../../../common/utils/formatters';
import { FileReaderResult } from '../../../../common/utils/fileReader';
import i18nInstance from '../../../../i18n';
import { GlobalState } from '../../../../rootReducer';
import { DispatchThunk } from '../../../../storeConfig';
import {
    PagedListContainer,
    UserSettingName,
    TransactionRowDTO,
    TransactionRowDimensionDTO,
    FileDTO,
    TransactionRowsExportType,
    AutoTransactionRuleSimpleDTO,
    TransactionRowsExtendedInfoDTO,
    TransactionRowsSplitDTO,
} from '../../../../services/types/ApiTypes';
import api from '../../../../services/ApiServices';
import { TransactionRowsSearchParams, selectTransactionRowsListSearchParams } from './TransactionRowsReducers';

const ns = 'invoice/transaction-rows/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.TRANSACTION_ROWS_SORT_DIRECTION,
    sortCol: UserSettingName.TRANSACTION_ROWS_SORT_COLUMN,
    pageSize: UserSettingName.TRANSACTION_ROWS_PAGE_SIZE,
};

export const getTransactionRowsLoadable = loadableDataActionsWithRequest<TransactionRowsSearchParams, PagedListContainer<TransactionRowDTO>>(`${ns}ALL_TRANSACTION_ROWS`);

export const getTransactionRowsList = (id: number, searchParams?: TransactionRowsSearchParams, openFirstPage?: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
            await dispatch(getCurrentUserGroupMember());
        }
        const transactionRowsSearchParams = selectTransactionRowsListSearchParams(getState());
        const groupMember = getState().user.groupMemberCommonLoadable.payload;
        searchParams = searchParams || transactionRowsSearchParams;

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember, true);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        const apiSearchParams = { ...searchParams };
        if (openFirstPage) {
            apiSearchParams.PagingOptions.Page = 1;
        }
        let response;
        try {
            dispatch(getTransactionRowsLoadable.request(searchParams));
            response = await api.invoice.getInvoiceTransactionRowsByInvoiceId(id, apiSearchParams);
            if (response?.status === 200) {
                dispatch(
                    getTransactionRowsLoadable.success({
                        request: searchParams,
                        result: response.data,
                    }),
                );
                dispatch(getInvoiceTransactionRowsVatRates(id));
                dispatch(getInvoiceTransactionRowsExtendedInfoList(id));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            dispatch(
                getTransactionRowsLoadable.error({
                    request: searchParams,
                    result: e,
                }),
            );
        }
    };
};

export const setTransactionRowsListPagingOptions = (id: number, page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const {
            transactionRows,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        const paging = transactionRows.searchParams.PagingOptions;

        if (!isEmpty(groupMember)) {
            const viewConfig = getViewUserSettings(listViewConfig, groupMember);
            if (!viewConfig.pageSize || parseInt(viewConfig.pageSize, 10) !== pageSize) {
                groupMember.UserSettings = setUserSettingValue(listViewConfig.pageSize, pageSize, groupMember.UserSettings);
            }
            dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
        }

        const searchParams: TransactionRowsSearchParams = {
            ...transactionRows.searchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };
        await dispatch(getTransactionRowsList(id, searchParams));
    };
};

export const addNewRow = createAction(`${ns}ADD_NEW_ROW`);

export const gotoLastPageAndAddNewRow = (invoiceId: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const payload = state.transactionRows.transactionRowsLoadable.payload;
        const lastPage = Math.ceil(payload.TotalCount / payload.Take) || 1;
        const currentPage = payload.Skip / payload.Take + 1;
        if (lastPage !== currentPage) {
            await dispatch(setTransactionRowsListPagingOptions(payload.Items[0]?.InvoiceId, lastPage, payload.Take));
        }
        dispatch(addNewRow(invoiceId));
    };
};

export const removeRow = createAction(`${ns}REMOVE_NEW_ROW`);

export const removeNewRow = (invoiceId: number) => {
    return async (dispatch: DispatchThunk) => {
        dispatch(removeRow(0));
        dispatch(getTransactionRowsList(invoiceId)); // lets reload to keep the item count on page up-to-date
    };
};

export const saveRow = (item: TransactionRowDTO, reloadInvoiceData: (invoiceId: number) => void, saveAndAddNewRow?: boolean) => {
    return async (dispatch: DispatchThunk) => {
        try {
            let result;
            if (item.Id > 0) {
                result = await api.invoice.updateTransactionRow(item);
            } else {
                result = await api.invoice.addTransactionRow(item);
            }
            if (result.status === 200 && result.data) {
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                if (saveAndAddNewRow) {
                    await dispatch(getTransactionRowsList(item.InvoiceId));
                    dispatch(gotoLastPageAndAddNewRow(item.InvoiceId));
                } else {
                    dispatch(getTransactionRowsList(item.InvoiceId));
                }
                reloadInvoiceData(item.InvoiceId);
            }
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.ErrorCode) {
                notify.error(i18nInstance.t(e.response.data.ErrorCode));
            } else {
                notify.error(i18nInstance.t('view.Accounting.RowSavingFailed'));
            }
        }
    };
};

export const deleteRow = (item: TransactionRowDTO, reloadInvoiceData?: (invoiceId: number) => void) => {
    return async (dispatch: DispatchThunk) => {
        try {
            const result = await api.invoice.deleteTransactionRow([item.Id]);
            if (result.status === 200) {
                dispatch(getTransactionRowsList(item.InvoiceId));
                notify.success(i18nInstance.t('views.invoice.partials.transactionRows.deleted'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                reloadInvoiceData(item.InvoiceId);
            }
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.ErrorCode) {
                notify.error(i18nInstance.t(e.response.data.ErrorCode));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'));
            }
        }
    };
};

export const updateCustomField = (dimensionId: number, customFieldItem: TransactionRowDimensionDTO, row: TransactionRowDTO, fullyDelete?: boolean) => {
    return async (dispatch: DispatchThunk) => {
        const item = cloneDeep(customFieldItem);
        if (customFieldItem) {
            if (dimensionId) {
                item.CustomCostObjectiveId = customFieldItem.CustomCostObjective.Id;
                item.DimensionId = dimensionId;
                try {
                    let response;
                    if (customFieldItem?.DimensionId) {
                        response = await api.invoice.updateCustomFieldWithRelations(row.Id, item);
                    } else {
                        response = await api.invoice.updateCustomField(row.Id, item);
                    }
                    if (response?.status === 200) {
                        notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                        dispatch(getTransactionRowsList(row.InvoiceId));
                    } else {
                        notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
                    }
                } catch (e) {
                    notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
                }
            } else {
                try {
                    const customField = cloneDeep(customFieldItem);
                    customField.DimensionId = null;
                    customField.Dimension = null;
                    customField.CustomCostObjective = null;
                    customField.Id = null;
                    customField.TransactionRowId = null;
                    let response;
                    if (fullyDelete) {
                        response = await api.invoice.deleteField(customFieldItem?.Id);
                    } else {
                        response = await api.invoice.updateCustomField(row.Id, customField);
                    }
                    if (response?.status === 200 || response?.status === 204) {
                        dispatch(getTransactionRowsList(row.InvoiceId));
                        notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                    } else {
                        notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
                    }
                } catch (e) {
                    notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
                }
            }
        }
    };
};

export const updateAccount = (dimensionId: number, row: TransactionRowDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.invoice.updateAccount(dimensionId, row.Id);
            if (response?.status === 200) {
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                dispatch(getTransactionRowsList(row.InvoiceId));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const updateTransactionRowProductItemBuyer = (productItemCode: string, row: TransactionRowDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.invoice.updateTransactionRowProductItemBuyerId(row.Id, productItemCode);
            if (response?.status === 200) {
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                dispatch(getTransactionRowsList(row.InvoiceId));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const updateVatCode = (row: TransactionRowDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.invoice.updateVatCode(row.InvoiceId, row);
            if (response?.status === 200) {
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                dispatch(getTransactionRowsList(row.InvoiceId));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const importFromInvoiceRows = (invoiceId: number, reloadInvoiceData?: (invoiceId: number) => void, createFromAutoTransactions?: boolean) => {
    return async () => {
        try {
            let result;
            if (createFromAutoTransactions) {
                result = result = await api.invoice.createTransactionRowsWithAT(invoiceId);
            } else {
                result = result = await api.invoice.createAccountingRows(invoiceId);
            }

            if (result.status === 200) {
                //dispatch(getTransactionRowsList(invoiceId));
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                reloadInvoiceData(invoiceId);
            }
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.ErrorCode) {
                notify.error(i18nInstance.t(e.response.data.ErrorCode));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'));
            }
        }
    };
};

export function uploadFile(fileReaderResult: FileReaderResult, invoiceId: number, reloadInvoiceData?: (invoiceId: number) => void) {
    return async () => {
        try {
            const file: FileDTO = {
                Base64Content: fileReaderResult.base64Content,
                FileName: fileReaderResult.fileName,
                InvoiceId: invoiceId,
                WorkflowDocumentId: undefined,
                DownloadUrl: undefined,
                FileUrl: undefined,
                ForceImport: undefined,
                HubType: undefined,
                Id: undefined,
                IsImported: undefined,
                IsNew: undefined,
                IsPdf: undefined,
                PathToFile: undefined,
            };

            const response = await api.invoice.uploadTransactionRowsFile(invoiceId, file);

            if (response.status === 200) {
                notify.success(i18nInstance.t('component.transactionRows.importXLS.Success'), i18nInstance.t('component.transactionRows.importXLS.SuccessHeader'));
                if (response.data && response.data?.CustomCostObjectives?.length > 0) {
                    notify.warning(
                        <>
                            <p>{i18nInstance.t('component.transactionRows.importXLS.Warning.CcoCodes', { codes: response.data.CustomCostObjectives.join(', ') })}</p>
                            <p>{i18nInstance.t('component.transactionRows.importXLS.Warning.ToResolve')}:</p>
                            <ul>
                                <li>&bull; {i18nInstance.t('component.transactionRows.importXLS.Warning.CcoCodes.Item1')}</li>
                                <li>&bull; {i18nInstance.t('component.transactionRows.importXLS.Warning.CcoCodes.Item2')}</li>
                                <li>&bull; {i18nInstance.t('component.transactionRows.importXLS.Warning.CcoCodes.Item3')}</li>
                            </ul>
                            <p>{i18nInstance.t('component.transactionRows.importXLS.Warning.AskSupport')}</p>
                        </>,
                        i18nInstance.t('component.transactionRows.importXLS.Warning.CompletedPartially'),
                        {
                            toastOptions: {
                                autoClose: 15000,
                            },
                        },
                    );
                }

                if (response.data && response.data?.Dimensions?.length > 0) {
                    notify.warning(
                        i18nInstance.t('component.transactionRows.importXLS.Warning.DimensionCodes', { dimensionCodes: response.data.Dimensions.join(', ') }),
                        i18nInstance.t('component.transactionRows.importXLS.Warning.CompletedPartially'),
                        {
                            toastOptions: {
                                autoClose: 15000,
                            },
                        },
                    );
                }
            } else if (response.status === 400 && response.data) {
                notify.error(response.data.Message || response.data.ErrorCode);
            }

            //const pageSize = getState().transactionRows.transactionRowsLoadable.payload?.Take;

            //dispatch(setTransactionRowsListPagingOptions(invoiceId, 1, pageSize));
            reloadInvoiceData(invoiceId);
        } catch (e) {
            notify.error(i18nInstance.t('views.global.ErrorUploadingFile'), i18nInstance.t('interceptorsFactory.Error'));
            console.error(e);
        }
    };
}

export const exportTransactionRowsActions = loadableDataActions(`${ns}EXPORT_TRANSACTION_ROWS`);

export function exportTransactionRows(invoiceId: number, exportType: TransactionRowsExportType) {
    return async (dispatch: DispatchThunk) => {
        dispatch(exportTransactionRowsActions.request(undefined));
        try {
            const response =
                exportType === TransactionRowsExportType.TabSeparated
                    ? await api.invoice.exportTransactionRowsToCSV(invoiceId)
                    : exportType === TransactionRowsExportType.WithFormulas
                    ? await api.invoice.exportTransactionRowsWithFormulasToXls(invoiceId)
                    : await api.invoice.exportTransactionRowsToXls(invoiceId);
            if (response.status === 200) {
                let fileName;
                if (exportType !== TransactionRowsExportType.TabSeparated) {
                    const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    fileName = `TransactionRows_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`;
                    saveAs(file, fileName);
                } else {
                    const file = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
                    fileName = `TransactionRows_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.csv`;
                    saveAs(file, fileName);
                }
                dispatch(exportTransactionRowsActions.success(fileName));
            } else if (response.status === 400 && response.data) {
                if (exportType === 2) {
                    notify.error(response.data.ErrorCode ? i18nInstance.t(response.data.ErrorCode.replace('{0}', response.data.FieldName || '')) : response.data.Message);
                } else {
                    response.data.text().then(function(response: any) {
                        const data = JSON.parse(response);
                        notify.error(data.ErrorCode ? i18nInstance.t(data.ErrorCode.replace('{0}', data.FieldName || '')) : data.Message);
                    });
                }
            }
        } catch (e) {
            console.error(e);
            dispatch(exportTransactionRowsActions.error(e));
        }
    };
}

export function applyAutoTransactionToInvoice(autoTransaction: AutoTransactionRuleSimpleDTO, invoiceId: number, reloadInvoiceData: (invoiceId: number) => void) {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.autoTransaction.applyAutoTransactionToInvoice(autoTransaction.Id, invoiceId, autoTransaction.RecreateTransactionRows);
            if (response?.status === 200) {
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                dispatch(getTransactionRowsList(invoiceId));
                reloadInvoiceData(invoiceId);
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
            }
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
        }
    };
}

export const getTransactionRowsVatRates = loadableDataActions<{ invoiceId: number }, { Item1: number; Item2: number }[]>(`${ns}GET_ALL_TRANSACTION_ROWS_VAT_RATES`);

export function getInvoiceTransactionRowsVatRates(invoiceId: number) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(getTransactionRowsVatRates.request({ invoiceId }));
            const response = await api.invoice.getInvoiceTransactionRowsVatRates(invoiceId);
            dispatch(getTransactionRowsVatRates.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(getTransactionRowsVatRates.error(e));
        }
    };
}

export function mergeAllAccountingRows(invoiceId: number, reloadInvoiceData: (invoiceId: number) => void) {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.invoice.mergeAllAccountingRows(invoiceId);
            if (response?.status === 200) {
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                dispatch(getTransactionRowsList(invoiceId, null, true));
                reloadInvoiceData(invoiceId);
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
            }
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
        }
    };
}

export function mergeAccountingRowsByTransactionRowExtension(invoiceId: number, metaInfo: TransactionRowsExtendedInfoDTO, reloadInvoiceData: (invoiceId: number) => void) {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.invoice.mergeAccountingRowsByTransactionRowExtension(invoiceId, metaInfo);
            if (response?.status === 200) {
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                dispatch(getTransactionRowsList(invoiceId, null, true));
                reloadInvoiceData(invoiceId);
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
            }
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
        }
    };
}

export const getTransactionRowsExtendedInfoList = loadableDataActions<{ invoiceId: number }, TransactionRowsExtendedInfoDTO[]>(`${ns}GET_ALL_TRANSACTION_ROWS_EXT`);

export function getInvoiceTransactionRowsExtendedInfoList(invoiceId: number) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(getTransactionRowsExtendedInfoList.request({ invoiceId }));
            const response = await api.invoice.getTransactionRowsExtendedInfoList(invoiceId);
            dispatch(getTransactionRowsExtendedInfoList.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(getTransactionRowsExtendedInfoList.error(e));
        }
    };
}

export const transactionRowsCopyDownLoadableActions = loadableDataActions<boolean, boolean>(`${ns}COPY_DOWN`);

export function copyDown(invoiceId: number, transactionRowId: number, reloadInvoiceData: (invoiceId: number) => void) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(transactionRowsCopyDownLoadableActions.request(true));
            const response = await api.invoice.copyDown(transactionRowId);
            if (response?.status === 200) {
                dispatch(transactionRowsCopyDownLoadableActions.success(true));
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                dispatch(getTransactionRowsList(invoiceId, null, true));
                reloadInvoiceData(invoiceId);
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
            }
        } catch (e) {
            dispatch(transactionRowsCopyDownLoadableActions.error(e));
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
        }
    };
}

export function updateComment(comment: string, invoiceId: number, transactionRowId: number) {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(transactionRowsCopyDownLoadableActions.request(true));
            const response = await api.invoice.updateComment(transactionRowId, comment);
            if (response?.status === 200) {
                dispatch(transactionRowsCopyDownLoadableActions.success(true));
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                dispatch(getTransactionRowsList(invoiceId, null, true));
                //reloadInvoiceData(invoiceId);
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
            }
        } catch (e) {
            dispatch(transactionRowsCopyDownLoadableActions.error(e));
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
        }
    };
}

export function setIsTransactionRowsCalculationActive(value: boolean) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
                await dispatch(getCurrentUserGroupMember());
            }
            const state = getState();

            const {
                groupMemberCommonLoadable: { payload: groupMember },
            } = state.user;

            if (!isEmpty(groupMember)) {
                groupMember.UserSettings = setUserSettingValue(UserSettingName.IS_TRANSACTION_ROWS_CALCULATION_ACTIVE, value, groupMember.UserSettings);

                await dispatch(updateUserSettings(groupMember.Id, groupMember.UserSettings));
                await dispatch(getCurrentUserGroupMember(true));
            }
        } catch (e) {
            console.error(e);
        }
    };
}

export function splitTransactionRow(invoiceId: number, splitObject: TransactionRowsSplitDTO) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            splitObject.SplitBy = splitObject?.SplitBy || {};
            splitObject.AccountDistributionItem.TransactionRowsDimensions = getState().transactionRows.transactionRowsLoadable?.payload.Items.find(
                (e) => e.Id === splitObject.AccountDistributionItem.Id,
            ).TransactionRowsDimensions;

            const response = await api.invoice.splitTransactionRow(splitObject);
            if (response?.status === 200 && response?.data) {
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                dispatch(getTransactionRowsList(invoiceId, null, true));
                //reloadInvoiceData(invoiceId);
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
            }
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
        }
    };
}
