(function() {
    'use strict';

    angular
        .module('dstreamApp.directives')
        .directive('debounceMousehover', debounceMousehover);

    debounceMousehover.$inject = ['$timeout'];

    function debounceMousehover($timeout) {
        return {
            restrict: 'EA',
            link: linkFunc,
            transclude: true,
            template: '<div data-ng-mouseenter="mouseEnter(col, $event)" data-ng-mouseleave="mouseLeave(col)"><ng-transclude></ng-transclude></div>'
        };

        function linkFunc(scope, element, attrs, ctrl) {
            var timer;
            var stayAtLeast = attrs.stayAtLeast || 100;

            scope.mouseEnter = function(col, $event) {
                timer = $timeout(function() {
                    col.hovering = true;
                }, stayAtLeast);
            };

            scope.mouseLeave = function(col) {
                col.hovering = false;
                $timeout.cancel(timer);
            };

            scope.$on('$destroy', function() {
                $timeout.cancel(timer);
            });
        }
    }
}());
