'use strict';

angular
    .module('dstreamApp.components')
    .component('dsPaginatorDropdown', {
        templateUrl: 'app/components/paginatorDropdown/ds-paginator-dropdown.tpl.html',
        controller: DsPaginatorDropdownController,
        bindings: {
            currentSize: '<',
            pageSizes: '<',
            showPaginationDropdown: '<',
            setPageSize: '&'
        }
    });

/* @ngInject */
function DsPaginatorDropdownController() {
}
