import classNames from 'classnames';
import Downshift, { StateChangeOptions } from 'downshift';
import { debounce, isBoolean } from 'lodash-es';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Manager, Popper, Reference } from 'react-popper';
import { CSSTransition } from 'react-transition-group';

import withFormikField from '../../common/utils/withFormikField';
import { OutsideEventListener } from '../../common/utils/OutsideEventListener';
import { BaseStatefulComponent } from '../BaseStatefulComponent';
import { Button, ButtonType } from '../Buttons/Button';
import { IconSize, ICONS } from '../Icon/Icon';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import Scrollbars from '../Scrollbars/Scrollbars';
import { TextInput, TextInputProps, TextInputType } from '../TextInput/TextInput';
import { WithDataId, createDataId } from '../../common/utils/dataId';

import { TypeaheadMenuWrapper } from './Typeahead';
import Tooltip from '../Tooltip/Tooltip';

import './Typeahead.scss';

export interface Props<T> {
    placeholder?: string;
    onChange?: (item: TypeaheadItem<T>) => void;
    onBlur?: (item: TypeaheadItem<T>) => void;
    value?: TypeaheadItem<T>;
    inputProps?: Pick<TextInputProps, Exclude<keyof TextInputProps, 'onChange'>>;
    toggleVisible?: boolean;
    clearInput?: boolean;
    debounceInterval?: number;
    itemToText?: (item: T) => string;
    loadData?: (input: string) => Promise<T[]>;
    positionFixed?: boolean;
    wrapperClass?: string;
    listWrapClass?: string;
    /*
        use when you do not want to use itemToText fn separately
     */
    loadItems?: (input: string) => Promise<Array<TypeaheadItem<T>>>;
    noResultsText?: React.ReactNode;
    searchingText?: React.ReactNode;
    searchOnFocus?: boolean;
    disableSearchOnSelectedFocus?: boolean;
    onMenuClose?: () => void;
    limitTo?: number;
    limitToText?: React.ReactNode;
    error?: React.ReactNode;
    bottomContent?: React.ReactNode;
    onTextInputChange?: (value: string) => void;
    isTooltipError?: boolean;
    ignoreInvalidValue?: boolean;
    preItemsListElement?: React.ReactElement;
}

export type TypeaheadAsyncProps<T> = Props<T> & WithTranslation & WithDataId;

export interface TypeaheadAsyncState<T> {
    selectedItem: TypeaheadItem<T>;
    shouldFilter: boolean;
    items: Array<TypeaheadItem<T>>;
    loading: boolean;
    error: boolean;
    latestQueryText: string;
    isResultsLimited: boolean;
}

export interface TypeaheadItem<T> {
    value: T;
    text: string;
}

const IGNORED_VALUE: any = 'IGNORED_VALUE';

class TypeaheadAsyncInternal<T> extends BaseStatefulComponent<TypeaheadAsyncProps<T>, TypeaheadAsyncState<T>> {
    private componentRootElement = React.createRef<HTMLDivElement>();
    private inputRef = React.createRef<HTMLInputElement>();
    private readonly outsideEventListener: OutsideEventListener;

    static defaultProps: Partial<TypeaheadAsyncProps<any>> = {
        debounceInterval: 300,
    };

    constructor(props: TypeaheadAsyncProps<T>) {
        super(props);
        this.state = {
            selectedItem: this.props.value || null,
            shouldFilter: !this.props.value,
            items: [],
            loading: false,
            error: undefined,
            latestQueryText: undefined,
            isResultsLimited: false,
        };
        this.outsideEventListener = new OutsideEventListener(this.componentRootElement, this.closeDropdown);
    }

    componentDidMount() {
        this.outsideEventListener.start();
    }

    componentWillUnmount() {
        this.outsideEventListener.stop();
    }

    componentDidUpdate(prevProps: TypeaheadAsyncProps<T>) {
        if (this.props.value !== prevProps.value) {
            this.setState({ selectedItem: this.props.value });
        }
    }

    handleStateChange = (changes: StateChangeOptions<TypeaheadItem<T>>) => {
        if (isBoolean(changes.isOpen) && !changes.isOpen) {
            if (this.props.onMenuClose) {
                this.props.onMenuClose();
            }
        }
        if (changes.hasOwnProperty('selectedItem')) {
            this.setState({ selectedItem: changes.selectedItem, shouldFilter: false }, () => {
                if (this.inputRef && this.inputRef.current) {
                    this.inputRef.current.blur();
                }
            });
            this.props.onChange(changes.selectedItem);
            if (this.props.onTextInputChange) {
                this.props.onTextInputChange('');
            }
        } else if (
            changes.hasOwnProperty('inputValue') &&
            changes.type !== '__autocomplete_controlled_prop_updated_selected_item__' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            changes.type !== 13
        ) {
            this.setState({ selectedItem: { text: changes.inputValue, value: IGNORED_VALUE }, shouldFilter: true });
            if (this.state.items.length > 0 && !changes.inputValue) {
                this.setState({ items: [], latestQueryText: '' });
            }
        }
    };

    handleBlur = () => {
        // allow the click handler to be executed inside the bottom content with the timeout
        setTimeout(
            () => {
                if (this.state.selectedItem && this.state.selectedItem.value === IGNORED_VALUE) {
                    if (this.props.ignoreInvalidValue && this.state.selectedItem.text) {
                        this.setState({ selectedItem: this.props.value });
                    } else {
                        this.setState({ selectedItem: null, items: [], latestQueryText: '' });
                        this.props.onChange(null);
                    }
                }
                if (this.props.onBlur) {
                    const value = this.state.selectedItem && this.state.selectedItem.value === IGNORED_VALUE ? null : this.state.selectedItem;
                    this.props.onBlur(value);
                }
            },
            this.props.bottomContent ? 150 : 0,
        );
    };

    handleFocus = (cb?: () => void) => {
        if (this.props.searchOnFocus) {
            this.setState((prevState) => ({ ...prevState, loading: true }));
            this.handleInputChange(this.state.latestQueryText || '');
        } else if (this.props.value && this.props.value.text && !this.props.disableSearchOnSelectedFocus) {
            this.setState((prevState) => ({ ...prevState, loading: true }));
            this.handleInputChange(this.props.value.text);
        }
        if (cb) {
            cb();
        }
    };

    handleToggleClick = (isOpen: boolean, toggleMenu: () => void) => {
        if (this.inputRef && this.inputRef.current) {
            if (!isOpen) {
                this.handleFocus();
            }
        }
        toggleMenu();
    };

    closeDropdown = () => {
        if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.blur();
        }
    };

    isToggleVisible() {
        const { toggleVisible, inputProps } = this.props;
        return toggleVisible || (inputProps && inputProps.type === TextInputType.BORDERED);
    }

    handleInputChange = async (input: string) => {
        let inputChanged = false;
        if (input !== this.state.latestQueryText) {
            inputChanged = true;
            this.setState({
                latestQueryText: input,
            });
        }
        if (!this.props.searchOnFocus && (!input || input.length === 0)) {
            this.setState({
                items: [],
                loading: false,
                isResultsLimited: false,
            });
            return;
        }
        try {
            let items: Array<TypeaheadItem<any>> = [];
            if (this.props.loadData) {
                const response = await this.props.loadData(input);
                if (response && response.length) {
                    items = response.map((item) => {
                        return {
                            text: this.props.itemToText(item),
                            value: item,
                        };
                    });
                }
            } else if (this.props.loadItems) {
                items = !inputChanged && this.state.items.length > 0 ? this.state.items : await this.props.loadItems(input);
                if (!items) {
                    items = [];
                }
            }
            const isResultsLimited = this.props.limitTo && items.length > this.props.limitTo;
            if (isResultsLimited) {
                items = items.slice(0, this.props.limitTo);
            }
            // only set the response when the query string matches current input, otherwise it is outdated response
            if (input === this.state.latestQueryText) {
                this.setState({
                    items,
                    loading: false,
                    isResultsLimited,
                });
            } else {
                this.setState({
                    loading: false,
                    isResultsLimited,
                });
            }
        } catch (e) {
            console.error(e);
            this.setState({
                loading: false,
                error: e,
                isResultsLimited: false,
            });
        }
    };

    debouncedHandleInputChange = debounce(this.handleInputChange, this.props.debounceInterval);

    render() {
        const { dataId, placeholder, inputProps, searchOnFocus, wrapperClass, t, listWrapClass, isTooltipError } = this.props;
        const classes = classNames('typeahead', wrapperClass, { 'typeahead--has-label': inputProps && inputProps.label });
        const { items } = this.state;
        const listWrapClasses = classNames('typeahead__list-wrap', listWrapClass, { 'typeahead__list-wrap--wide': inputProps && inputProps.type === TextInputType.LARGE });
        return (
            <Downshift defaultHighlightedIndex={0} onStateChange={this.handleStateChange} itemToString={(item) => (item ? item.text : '')} selectedItem={this.state.selectedItem}>
                {({ getInputProps, getItemProps, getMenuProps, isOpen, inputValue, highlightedIndex, selectedItem, getToggleButtonProps, openMenu, toggleMenu }) => (
                    <div className={classes} data-id={dataId || 'typeahead'}>
                        <Manager>
                            <Reference>
                                {({ ref }) => (
                                    <>
                                        <div className="typeahead__input-wrap" ref={ref}>
                                            <TextInput
                                                {...this.props.inputProps}
                                                dataId={createDataId(dataId || 'typeahead', 'input')}
                                                error={this.props.error}
                                                hideError={true}
                                                forwardRef={this.inputRef}
                                                placeholder={placeholder}
                                                isToggleVisible={this.isToggleVisible()}
                                                {...getInputProps()}
                                                onFocus={() => {
                                                    this.handleFocus(openMenu);
                                                }}
                                                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                    this.handleBlur();
                                                    getInputProps().onBlur(e);
                                                }}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    this.debouncedHandleInputChange(e.target.value);
                                                    getInputProps().onChange(e);
                                                    this.setState((prevState) => ({ ...prevState, loading: true }));
                                                    if (this.props.onTextInputChange) {
                                                        this.props.onTextInputChange(e.target.value);
                                                    }
                                                }}
                                            />
                                            {this.isToggleVisible() && (
                                                <Button
                                                    dataId={createDataId(dataId || 'typeahead', 'inputToggle')}
                                                    tabIndex={-1}
                                                    disabled={inputProps?.disabled}
                                                    iconSize={IconSize.XS}
                                                    className="typeahead__toggle"
                                                    buttonType={ButtonType.ICON}
                                                    icon={ICONS.CHEVRON_DOWN_24}
                                                    iconRotation={isOpen ? 180 : 0}
                                                    {...getToggleButtonProps()}
                                                    onClick={() => {
                                                        this.handleToggleClick(isOpen, toggleMenu);
                                                    }}
                                                />
                                            )}
                                            {this.props.error && !this.isToggleVisible() && isTooltipError && (
                                                <Tooltip content={this.props.error as React.ReactElement}>
                                                    <Button buttonType={ButtonType.ICON} className="text-input__alert-icon" icon={ICONS.ALERT} tabIndex={-1} />
                                                </Tooltip>
                                            )}
                                        </div>
                                        {this.props.error && !isTooltipError && <InputErrorMessage dataId={dataId || 'typeahead.inputError'}>{this.props.error}</InputErrorMessage>}
                                    </>
                                )}
                            </Reference>
                            <CSSTransition
                                unmountOnExit={true}
                                classNames="fade"
                                in={isOpen && (((inputValue || searchOnFocus) && items.length === 0) || items.length > 0) && !this.state.loading}
                                timeout={150}
                            >
                                <Popper positionFixed={!!this.props.positionFixed} placement={this.props.inputProps && this.props.inputProps.pullRight ? 'bottom-end' : 'bottom-start'}>
                                    {({ ref, style, placement, scheduleUpdate }) => (
                                        <div ref={ref} style={style} data-placement={placement} className={listWrapClasses}>
                                            <Scrollbars hideTracksWhenNotNeeded={true}>
                                                <TypeaheadMenuWrapper scheduleUpdate={scheduleUpdate} itemsOrSearchString={items} loading={this.state.loading}>
                                                    <ul className="typeahead__list" data-id={createDataId(dataId || 'typeahead', 'list')} role="menu" {...getMenuProps()}>
                                                        {!!this.props.preItemsListElement && this.props.preItemsListElement}
                                                        {items.map((item, index) => (
                                                            <li
                                                                data-id={createDataId(dataId || 'typeahead', 'list', index)}
                                                                className={`typeahead__list-item ${highlightedIndex === index ? 'typeahead__list-item--active' : ''}`}
                                                                key={index}
                                                                {...getItemProps({
                                                                    key: index,
                                                                    index,
                                                                    item,
                                                                })}
                                                            >
                                                                <span className="typeahead__list-item-text" tabIndex={-1} data-id={createDataId(dataId || 'typeahead', 'list', index, 'a')}>
                                                                    {selectedItem && selectedItem.text === item.text && (
                                                                        <strong data-id={createDataId(dataId || 'typeahead', 'list', index, 'a', 'strong')}>{item.text}</strong>
                                                                    )}
                                                                    {!(selectedItem && selectedItem.text === item.text) && item.text}
                                                                </span>
                                                            </li>
                                                        ))}
                                                        {(inputValue || searchOnFocus) && items.length === 0 && !this.state.loading && (
                                                            <li data-id={createDataId(dataId || 'typeahead', 'list', 'noResults')} className={`typeahead__list-item`}>
                                                                <span className="typeahead__list-item-text">{this.props.noResultsText || t('component.Typeahead.NoResults')}</span>
                                                            </li>
                                                        )}
                                                        {(inputValue || searchOnFocus) && items.length === 0 && this.state.loading && (
                                                            <li data-id={createDataId(dataId || 'typeahead', 'list', 'searching')} className={`typeahead__list-item`}>
                                                                <span className="typeahead__list-item-text">{this.props.searchingText || t('component.Typeahead.Searching')}</span>
                                                            </li>
                                                        )}
                                                        {this.state.isResultsLimited && !this.state.loading && (
                                                            <li data-id={createDataId(dataId || 'typeahead', 'list', 'specifySearch')} className={`typeahead__list-item`}>
                                                                <span className="typeahead__list-item-text typeahead__list-item-text--results-limited">
                                                                    {this.props.limitToText || t('component.Typeahead.SpecifySearch')}
                                                                </span>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </TypeaheadMenuWrapper>
                                            </Scrollbars>
                                            {this.props.bottomContent && (
                                                <div
                                                    className="typeahead__bottom-content"
                                                    data-id={createDataId(dataId || 'typeahead', 'bottomToggle')}
                                                    onClick={() => {
                                                        toggleMenu();
                                                    }}
                                                >
                                                    {this.props.bottomContent}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Popper>
                            </CSSTransition>
                        </Manager>
                    </div>
                )}
            </Downshift>
        );
    }
}

export const TypeaheadAsync = withTranslation()(TypeaheadAsyncInternal);
export const TypeaheadAsyncField = withFormikField(TypeaheadAsync);
