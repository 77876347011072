import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Dispatchable1 } from 'redux-dispatchers';

import {
    CompanyDTO,
    FileDTO,
    InvoiceDTO,
    InvoiceDataResponseDTO,
    InvoiceSliderMinDTO,
    PagedListContainer,
    SupplierDTO,
    SystemConfigurationDTO,
    BaseSearch,
    InvoiceType,
} from '../../../../services/types/ApiTypes';
import { createDataId } from '../../../../common/utils/dataId';
import api from '../../../../services/ApiServices';
import { Button, ButtonIconPlacement, ButtonType } from '../../../../components/Buttons/Button';
import BasicInfoViewMode from './components/BasicInfoViewMode';
import AdditionalInfoViewMode from './components/AdditionalInfoViewMode';
import AdditionalInfoEditMode from './components/AdditionalInfoEditMode';
import { InvoiceHeaderBlocks } from './components/InvoiveHeaderTypes';
import BasicInfoEditMode from './components/BasicInfoEditMode';
import { ICONS } from '../../../../components/Icon/Icon';
import ModalConfirm, { ConfirmButtonStyle } from '../../../../components/Modal/ModalConfirm';
import { TextInput, TextInputType } from '../../../../components/TextInput/TextInput';
import { notify } from '../../../../common/utils/notify';
import { checkUserOnlyRoleExpenseCreator, isSystemSetting } from '../../../../common/user/userPermissionUtil';
import { isAuthorized, Role } from '../../../../common/user/userPermissionUtil';
import { LoadableData } from '../../../../common/utils/LoadableData';
import CustomFieldsViewMode from './components/CustomFieldsViewMode';
import CustomFieldsEditMode from './components/CustomFieldsEditMode';
import { getInvoiceDocTypeValue, getInvoiceStatusAllowsEditting, parseExportSetting, ExportSettings, handleBackClick } from './utils';
import { getDefaulInvoiceType } from '../../../settings/invoice-types/InvoiceTypesListHelpers';
import PurchaseOrdersList from './components/PurchaseOrdersList/PurchaseOrdersListWrapper';
import { HeaderPlaceholder } from '../../../purchase-orders-add/components/Header/HeaderPlaceholder';
import HistoryListWrapper from './components/HistoryList/HistoryListWrapper';

import './InvoiceHeader.scss';

export const dataId = 'invoice-details-header';

export interface Props {
    invoice: InvoiceDTO & InvoiceDataResponseDTO;
    // TODO: move api functions to Redux actions
    reloadInvoiceData: (id: number) => Promise<InvoiceDTO & InvoiceDataResponseDTO>;
    saveWorkflowInvoice: (i: InvoiceDTO & InvoiceDataResponseDTO, retainOldDocFiles?: boolean, onlySupplier?: boolean) => Promise<InvoiceDTO & InvoiceDataResponseDTO>;
    updateSupplierId: (suppId: string, invoiceId: string) => Promise<SupplierDTO>;
    getCompaniesFromTheSameOrganizationByName: (name: string) => Promise<CompanyDTO[]>;
    currentCompanyName: string;
    currentCompanySettings: SystemConfigurationDTO[];
    invoiceSliderDataLoadable: LoadableData<PagedListContainer<InvoiceSliderMinDTO>, BaseSearch>;
    addSupplierLoadable: LoadableData<SupplierDTO, SupplierDTO>;
    updateSupplierLoadable: LoadableData<SupplierDTO, SupplierDTO>;
    removeDuplicateFlag: () => void;
    isReadOnly: boolean;
    $rootScope: any;
}
export interface DispatchProps {
    showAddOrEditSupplierModal: Dispatchable1<SupplierDTO>;
}
export type InvoiceHeaderProps = Props & DispatchProps & WithTranslation;

type InvoiceHeaderBlock = { block: InvoiceHeaderBlocks; isEditting: boolean };

const InvoiceHeader = (props: InvoiceHeaderProps) => {
    const {
        currentCompanySettings,
        getCompaniesFromTheSameOrganizationByName: getCompaniesByName,
        t,
        saveWorkflowInvoice,
        invoiceSliderDataLoadable,
        addSupplierLoadable,
        updateSupplierLoadable,
        isReadOnly,
        $rootScope,
        removeDuplicateFlag,
        showAddOrEditSupplierModal,
        updateSupplierId,
    } = props;
    const [activeBlock, setActiveBlock] = useState<InvoiceHeaderBlock>({ block: InvoiceHeaderBlocks.BASIC_INFO, isEditting: false });
    const [isInvoiceLoading, setIsInvoiceLoading] = useState<boolean>(false);
    const [invoice, setInvoice] = useState<InvoiceDTO & InvoiceDataResponseDTO>(props.invoice);
    const [originalInvoiceId, setOriginalInvoiceId] = useState<number>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [invoiceMovedTo, setInvoiceMovedTo] = useState<Pick<InvoiceDTO, 'Id' | 'CompanyGuid'>>(null);
    const [newInvoiceDocs, setNewInvoiceDocs] = useState<FileDTO[]>(null);
    const [exportSettings, setExportSettings] = useState<ExportSettings>(null);
    const [deletionInvoiceComment, setDeletionInvoiceComment] = useState<string>(null);

    const maxCommentLength = 500;

    const updateDefaultInvoiceType = async () => {
        const invoiceType = checkUserOnlyRoleExpenseCreator() ? InvoiceType.Expense : InvoiceType.PurchaseInvoice;
        const defaultInvoiceType = await getDefaulInvoiceType(invoiceType);
        if (defaultInvoiceType) {
            setInvoice({ ...props.invoice, InvoiceType: defaultInvoiceType, InvoiceTypeId: defaultInvoiceType.Id });
        }
    };

    useEffect(() => {
        setActiveBlock({ block: InvoiceHeaderBlocks.BASIC_INFO, isEditting: props.invoice?.IsNew });
        setInvoice({ ...props.invoice, DocumentType: getInvoiceDocTypeValue(props.invoice?.DocumentType) });
        setInvoiceMovedTo(null);
        props.invoice?.IsNew && !props.invoice?.InvoiceType && updateDefaultInvoiceType();
        props.invoice?.IsNew && setActiveBlock({ block: InvoiceHeaderBlocks.BASIC_INFO, isEditting: true });
    }, [props.invoice]);

    let activeInvoiceLoadingEvent: any, activeInvoiceLoadedEvent: any, invoiceLoadingEvent: any, invoiceLoadedEvent: any, invoiceMovedEvent: any, relatedDocumentEventWatcher: any;
    useEffect(() => {
        // TODO: remove when the whole page is in React
        activeInvoiceLoadingEvent = $rootScope.$on('activeInvoiceLoading', function() {
            setIsInvoiceLoading(true);
        });
        activeInvoiceLoadedEvent = $rootScope.$on('activeInvoiceLoaded', function() {
            setIsInvoiceLoading(false);
        });
        invoiceLoadingEvent = $rootScope.$on('invoiceLoading', function() {
            setIsInvoiceLoading(true);
        });
        invoiceLoadedEvent = $rootScope.$on('invoiceLoaded', function() {
            setIsInvoiceLoading(false);
        });
        invoiceMovedEvent = $rootScope.$on('invoiceMoved', function(event: any, newInvoiceId: number, companyGuid: string) {
            setInvoiceMovedTo({ Id: newInvoiceId, CompanyGuid: companyGuid });
        });
        relatedDocumentEventWatcher = $rootScope.$on('invoice.newRelatedDocument', (evt: any, docs: FileDTO[]) => {
            setNewInvoiceDocs(docs);
        });
    }, [$rootScope]);

    const cleanup = () => {
        // TODO: remove when the whole page is in React
        typeof activeInvoiceLoadingEvent === 'function' && activeInvoiceLoadingEvent();
        typeof activeInvoiceLoadedEvent === 'function' && activeInvoiceLoadedEvent();
        typeof invoiceLoadingEvent === 'function' && invoiceLoadingEvent();
        typeof invoiceLoadedEvent === 'function' && invoiceLoadedEvent();
        typeof invoiceMovedEvent === 'function' && invoiceMovedEvent();
        typeof relatedDocumentEventWatcher === 'function' && relatedDocumentEventWatcher();
    };

    useEffect(() => {
        return cleanup;
    }, []);

    useEffect(() => {
        setExportSettings(parseExportSetting(currentCompanySettings));
    }, [currentCompanySettings]);

    const saveInvoice = async (i: InvoiceDTO & InvoiceDataResponseDTO, onlySupplier?: boolean) => {
        if (originalInvoiceId) {
            // saving invoice duplicate
            const response = await api.invoice.saveInvoiceCopy(originalInvoiceId, { ...i, DocumentFiles: newInvoiceDocs });
            if (response.data) {
                setOriginalInvoiceId(null);
                window.location.href = `#/invoiceconfirmation/${response.data.Id.toString()}`;
            }
        } else {
            // as we are not re-fetching the updated invoice, use invoice returned from /invoice/Save API endpoint
            const inv = newInvoiceDocs ? { ...i, DocumentFiles: newInvoiceDocs } : i;
            const newInvoiceResponse = await saveWorkflowInvoice(inv, newInvoiceDocs ? false : true, onlySupplier);
            if (newInvoiceResponse) {
                setInvoice({ ...props.invoice, ...newInvoiceResponse });
                setActiveBlock({ ...activeBlock, isEditting: false });
            }
        }
    };

    const createNewInvoice = () => {
        const originalInvoiceId = invoice.Id;
        const newInvoice: Partial<InvoiceDTO> = {
            IsNew: true,
            PayToAccount: invoice.PayToAccount,
            SupplierName: invoice.SupplierName,
            SupplierRegCode: invoice.SupplierRegCode,
            SupplierAddress: invoice.SupplierAddress,
            SupplierRepresentative: invoice.SupplierRepresentative,
            SupplierEmail: invoice.SupplierEmail,
            SupplierVatCodeId: invoice.SupplierVatCodeId,
            SupplierCode: invoice.SupplierCode,
            ReferenceNumber: invoice.ReferenceNumber,
            TotalAmountWithVat: invoice.TotalAmountWithVat,
            SumWithoutVat: invoice.SumWithoutVat,
            Vat: invoice.Vat,
            InvoiceType: invoice.InvoiceType,
            InvoiceTypeId: invoice.InvoiceTypeId,
            IsCredit: invoice.IsCredit,
            IsDuplicate: invoice.IsDuplicate,
            Description: invoice.Description,
            NextManagerName: invoice.NextManagerName,
            VatCodeId: invoice.VatCodeId,
            SupplierId: invoice.SupplierId,
            Currency: invoice.Currency,
            Beneficiary: invoice.Beneficiary,
            PurchaseOrder: invoice.PurchaseOrder,
            ContractNumber: invoice.ContractNumber,
            Supplier: invoice.Supplier,
            DocumentType: invoice.DocumentType,
            Company: invoice.Company,
            InvoiceCustomization: invoice.InvoiceCustomization,
            DocumentFiles: [],
            Number: null,
            DueDate: null,
            InvoiceDate: null,
            AccountingDate: null,
        };

        setNewInvoiceDocs(null);
        setOriginalInvoiceId(originalInvoiceId);
        setInvoice(newInvoice as InvoiceDTO & InvoiceDataResponseDTO);
        setActiveBlock({ block: InvoiceHeaderBlocks.BASIC_INFO, isEditting: true });
        $rootScope.$emit('newInvoice', true);
    };

    const deleteInvoice = () => setIsDeleteModalOpen(true);

    const onConfirmInvoiceDelete = async (comment?: string) => {
        setIsDeleteModalOpen(false);
        setDeletionInvoiceComment(null);
        const response = await api.invoice.setInvoiceDeleted(invoice.Id, comment);
        if (response?.status === 200) {
            props.reloadInvoiceData(invoice.Id);
        } else {
            notify.error(t('controller.dashboardController.Error'), t('controller.invoiceConfirmationController.Error'));
        }
    };

    const onCancelEditting = () => {
        setActiveBlock({ ...activeBlock, isEditting: false });
        if (originalInvoiceId) {
            setInvoice(props.invoice);
            setOriginalInvoiceId(null);
            $rootScope.$emit('newInvoice', false);
        }
    };

    const checkIsDuplicateAndRestore = () => {
        const invoiceData = {
            InvoiceDate: invoice.InvoiceDate,
            TotalAmountWithVat: invoice.TotalAmountWithVat,
            SupplierId: invoice.SupplierId,
            Number: invoice.Number,
        };
        api.invoice
            .checkIsDuplicate(invoiceData)
            .then((response) => {
                const result = response.data;
                if (!result) {
                    api.invoice
                        .restoreDeletedInvoice(invoice.Id)
                        .then((response) => {
                            const result = response.data;
                            if (result) {
                                setInvoice(result as InvoiceDTO & InvoiceDataResponseDTO);
                                props.reloadInvoiceData(result?.Id);
                            } else {
                                notify.error(t('interceptorsFactory.ErrorWhileProcessingData'), t('controller.invoiceConfirmationController.Error'));
                            }
                        })
                        .catch((e) => {
                            notify.error(t('interceptorsFactory.ErrorWhileProcessingData'), t('controller.invoiceConfirmationController.Error'));
                            console.error(e);
                        });
                } else {
                    notify.error(t('views.invoice.partials.invoiceInformation.Error.DuplicateFound'), t('controller.invoiceConfirmationController.Error'));
                }
            })
            .catch((e) => {
                notify.error(t('interceptorsFactory.ErrorWhileProcessingData'), t('controller.invoiceConfirmationController.Error'));
                console.error(e);
            });
    };

    return (
        <section className={classNames('main-component main-component--small invoice-header')}>
            <div className="invoice-header__row">
                {!activeBlock.isEditting && (
                    <div className="invoice-header__back-button">
                        <Button onClick={() => handleBackClick()} dataId={createDataId(dataId, 'backButton')} icon={ICONS.ARROW_LEFT_24} className="icon-button" buttonType={ButtonType.ICON_TEXT}>
                            {t('component.invoiceHeader.backToInvoices')}
                        </Button>
                    </div>
                )}
                {!activeBlock.isEditting &&
                    (!isInvoiceLoading && invoice?.Id ? (
                        <div className="invoice-header__icon-buttons">
                            {!isReadOnly && (
                                <>
                                    {isAuthorized(Role.CanAddInvoice) && invoice?.Status !== 5 && invoice?.IsDuplicate && (
                                        <Button
                                            onClick={() => removeDuplicateFlag()}
                                            dataId={createDataId(dataId, 'set-as-new')}
                                            icon={ICONS.ADD_24}
                                            className="icon-button"
                                            buttonType={ButtonType.ICON_TEXT}
                                        >
                                            {t('component.additionalInfo.setAsNew')}
                                        </Button>
                                    )}
                                    {getInvoiceStatusAllowsEditting(invoice?.Status) && !isAuthorized(Role.CanOnlyViewInvoices) && (
                                        <Button
                                            iconPlacement={ButtonIconPlacement.LEFT}
                                            onClick={() => setActiveBlock({ block: InvoiceHeaderBlocks.BASIC_INFO, isEditting: true })}
                                            dataId={createDataId(dataId, 'editBasicInfo')}
                                            icon={ICONS.EDIT_24}
                                            className="icon-button"
                                            buttonType={ButtonType.ICON_TEXT}
                                        >
                                            {t('views.global.edit')}
                                        </Button>
                                    )}
                                    {isAuthorized(Role.CanAddInvoice) && invoice?.Status === 5 && (
                                        <Button
                                            onClick={checkIsDuplicateAndRestore}
                                            dataId={createDataId(dataId, 'delete')}
                                            icon={ICONS.UNDO_24}
                                            className="icon-button"
                                            buttonType={ButtonType.ICON_TEXT}
                                        >
                                            {t('component.invoiceHeader.restore')}
                                        </Button>
                                    )}
                                    {isAuthorized(Role.CanAddInvoice) && (
                                        <Button onClick={createNewInvoice} dataId={createDataId(dataId, 'duplicate')} icon={ICONS.COPY_24} className="icon-button" buttonType={ButtonType.ICON_TEXT}>
                                            {t('component.invoiceHeader.clone')}
                                        </Button>
                                    )}
                                    {isAuthorized(Role.CanDeleteInvoice) && invoice?.Status !== 5 && (
                                        <Button onClick={deleteInvoice} dataId={createDataId(dataId, 'delete')} icon={ICONS.DELETE_24} className="icon-button delete" buttonType={ButtonType.ICON_TEXT}>
                                            {t('component.invoiceHeader.delete')}
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    ) : (
                        <div className={'invoice-header__icon-buttons--preloader'}>
                            <HeaderPlaceholder class={'invoice-header'} />
                        </div>
                    ))}
            </div>
            {activeBlock.block === InvoiceHeaderBlocks.BASIC_INFO && activeBlock.isEditting ? (
                <BasicInfoEditMode
                    invoice={invoice}
                    t={t}
                    saveInvoice={saveInvoice}
                    showAddOrEditSupplierModal={showAddOrEditSupplierModal}
                    cancelEditting={onCancelEditting}
                    updateSupplierId={updateSupplierId}
                    addSupplierLoadable={addSupplierLoadable}
                    updateSupplierLoadable={updateSupplierLoadable}
                />
            ) : (
                <BasicInfoViewMode
                    dataId={dataId}
                    defaultCurrency={currentCompanySettings?.find((s) => s.Name === 'DefaultCurrency')?.Value || ''}
                    isErpVisible={exportSettings?.isERPIdEnabled && !!invoice?.ErpId}
                    isNewSupplier={!invoice?.SupplierCode && exportSettings?.isNewSupplierVisible && invoice?.Status !== 6}
                    isInvoiceLoading={isInvoiceLoading}
                    invoice={invoice}
                    t={t}
                />
            )}

            <div className="invoice-header__basic-info-separator"></div>
            <div className="invoice-header__info-blocks-links">
                <Button
                    dataId={createDataId(dataId, 'basic-info')}
                    className={classNames('link', { 'link--active': activeBlock.block === InvoiceHeaderBlocks.BASIC_INFO })}
                    buttonType={ButtonType.TEXT}
                    onClick={() => setActiveBlock({ block: InvoiceHeaderBlocks.BASIC_INFO, isEditting: false })}
                >
                    {t(`component.invoiceHeader.${InvoiceHeaderBlocks.BASIC_INFO}`)}
                </Button>
                <Button
                    dataId={createDataId(dataId, 'additional-info')}
                    className={classNames('link', { 'link--active': activeBlock.block === InvoiceHeaderBlocks.ADDITIONAL_INFO })}
                    buttonType={ButtonType.TEXT}
                    onClick={() => setActiveBlock({ block: InvoiceHeaderBlocks.ADDITIONAL_INFO, isEditting: false })}
                >
                    {t(`component.invoiceHeader.${InvoiceHeaderBlocks.ADDITIONAL_INFO}`)}
                </Button>
                {!!invoice?.InvoiceCustomization?.length && (
                    <Button
                        dataId={createDataId(dataId, 'custom-fields')}
                        className={classNames('link', { 'link--active': activeBlock.block === InvoiceHeaderBlocks.CUSTOM_FIELDS })}
                        buttonType={ButtonType.TEXT}
                        onClick={() => setActiveBlock({ block: InvoiceHeaderBlocks.CUSTOM_FIELDS, isEditting: false })}
                    >
                        {t(`component.invoiceHeader.${InvoiceHeaderBlocks.CUSTOM_FIELDS}`)}
                    </Button>
                )}
                {isSystemSetting('IsPurchaseOrdersModulActive') && (
                    <Button
                        dataId={createDataId(dataId, 'purchase-orders')}
                        className={classNames('link', { 'link--active': activeBlock.block === InvoiceHeaderBlocks.PURCHASE_ORDERS })}
                        buttonType={ButtonType.TEXT}
                        onClick={() => setActiveBlock({ block: InvoiceHeaderBlocks.PURCHASE_ORDERS, isEditting: false })}
                    >
                        {t(`component.invoiceHeader.${InvoiceHeaderBlocks.PURCHASE_ORDERS}`)}
                    </Button>
                )}
                <Button
                    dataId={createDataId(dataId, 'history')}
                    className={classNames('link', { 'link--active': activeBlock.block === InvoiceHeaderBlocks.HISTORY })}
                    buttonType={ButtonType.TEXT}
                    onClick={() => setActiveBlock({ block: InvoiceHeaderBlocks.HISTORY, isEditting: false })}
                >
                    {t(`component.invoiceHeader.${InvoiceHeaderBlocks.HISTORY}`)}
                </Button>
            </div>
            {activeBlock.block === InvoiceHeaderBlocks.ADDITIONAL_INFO &&
                (activeBlock.isEditting ? (
                    <AdditionalInfoEditMode
                        invoice={invoice}
                        t={t}
                        saveInvoice={saveInvoice}
                        cancelEditting={onCancelEditting}
                        getCompaniesByName={getCompaniesByName}
                        currentCompanyName={props.currentCompanyName}
                        invoiceSliderDataLoadable={invoiceSliderDataLoadable}
                        invoiceMovedTo={invoiceMovedTo}
                        isInvoiceLoading={isInvoiceLoading}
                    />
                ) : (
                    <AdditionalInfoViewMode
                        currentCompanyName={props.currentCompanyName}
                        dataId={dataId}
                        invoice={invoice}
                        isReadOnly={isReadOnly}
                        t={t}
                        onEdit={getInvoiceStatusAllowsEditting(invoice?.Status) ? () => setActiveBlock({ block: InvoiceHeaderBlocks.ADDITIONAL_INFO, isEditting: true }) : null}
                    />
                ))}

            {activeBlock.block === InvoiceHeaderBlocks.CUSTOM_FIELDS &&
                (activeBlock.isEditting ? (
                    <CustomFieldsEditMode
                        customFields={invoice.InvoiceCustomization}
                        invoice={invoice}
                        t={t}
                        saveInvoice={saveInvoice}
                        cancelEditting={onCancelEditting}
                        isInvoiceLoading={isInvoiceLoading}
                    />
                ) : (
                    <CustomFieldsViewMode
                        customFields={invoice.InvoiceCustomization}
                        dataId={dataId}
                        isReadOnly={isReadOnly}
                        t={t}
                        onEdit={getInvoiceStatusAllowsEditting(invoice?.Status) ? () => setActiveBlock({ block: InvoiceHeaderBlocks.CUSTOM_FIELDS, isEditting: true }) : null}
                    />
                ))}

            {activeBlock.block === InvoiceHeaderBlocks.PURCHASE_ORDERS && <PurchaseOrdersList invoice={invoice} />}
            {activeBlock.block === InvoiceHeaderBlocks.HISTORY && <HistoryListWrapper invoice={invoice} />}

            <ModalConfirm
                dataId={createDataId(dataId, 'delete-invoice-modal')}
                open={isDeleteModalOpen}
                onClose={() => {
                    setIsDeleteModalOpen(false);
                    setDeletionInvoiceComment(null);
                }}
                title={t('component.invoiceHeader.delete.modal.title')}
                confirmText={t('component.invoiceHeader.delete.modal.proceed')}
                handleActions={() => onConfirmInvoiceDelete(deletionInvoiceComment)}
                confirmButtonIcon={ICONS.DELETE_24}
                cancelActions={() => {
                    setIsDeleteModalOpen(false);
                    setDeletionInvoiceComment(null);
                }}
                cancelText={t('component.invoiceHeader.delete.modal.cancel')}
                confirmButtonStyle={ConfirmButtonStyle.PRIMARY}
                noCloseButton={true}
            >
                {
                    <div>
                        <div>{t('component.invoiceHeader.delete.modal.bodyText')}</div>
                        <div>
                            <TextInput
                                placeholder={t('component.invoiceHeader.delete.modal.palceholder')}
                                dataId={createDataId(dataId, 'delete-comment-input')}
                                autofocus={true}
                                textarea={true}
                                label={t('component.invoiceHeader.delete.modal.label')}
                                maxLength={500}
                                value={deletionInvoiceComment}
                                onChange={(e) => {
                                    setDeletionInvoiceComment(e.target.value);
                                }}
                                type={TextInputType.BORDERED}
                                wrapperClass={'invoice-header__input-comment'}
                                addonAfter={<span className="invoice-header__length-comment">{`${deletionInvoiceComment?.length || 0}/${maxCommentLength}`}</span>}
                            />
                        </div>
                    </div>
                }
            </ModalConfirm>
        </section>
    );
};

export default withTranslation()(InvoiceHeader);
