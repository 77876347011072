import { TFunction } from 'i18next';
import { cloneDeep, find, maxBy } from 'lodash-es';

import { ConfirmationFlowStatusLookupId, InvoiceStatus, UserRole } from '../../../../common/constants/appConstants';
import {
    ApproverDTO,
    ApproverPerStep,
    ApproversListDestination,
    GroupMemberApproverDTO,
    GroupMemberDTO,
    InvoiceDTO,
    SearchType,
    SortDirection,
    TaskDTO,
    UserMinDTO,
    WorkflowDTO,
} from '../../../../services/types/ApiTypes';
import { User } from '../../../../services/ApiClient';
import { ConfirmationFlowActions } from '../../../../common/constants/invoiceConstants';
import { isAuthorized, Role, userHasOnlyProvidedConfirmationsRoles } from '../../../../common/user/userPermissionUtil';
import { getStaticStepsCounterForGroupTask } from './components/utils';
import { notify } from '../../../../common/utils/notify';
import api from '../../../../services/ApiServices';
import storeAndPersistor from '../../../../storeConfig';
import { getCurrentCompany } from '../../../../common/user/UserSelectors';
import { ICONS } from '../../../../components/Icon/Icon';
import { GroupTasksDTO, TaskType } from './InvoiceConfirmations';
import { selectCurrentCompanySettings } from '../../../../common/company/CompanySelectors';
import { CompanySettingStatus } from '../../../settings/company-settings/components/Settings/CompanySettingsListHelper';
import i18nInstance from '../../../../i18n';
import classNames from 'classnames';

/**
 * sortWorkflows
 * @param workflows WorkflowDTO[]
 * @param responseArr WorkflowDTO[] || ApproverDTO
 */
export function sortWorkflows(workflows: WorkflowDTO[] | GroupMemberApproverDTO[], responseArr: WorkflowDTO[] | GroupMemberApproverDTO[]) {
    // sorting list of workflows
    workflows
        .sort((a: any, b: any) => a.Name.localeCompare(b.Name))
        .forEach((workflow: any) => {
            responseArr.push({
                ...workflow,
                IsTemplate: true,
            });
        });
}

/**
 * sortApprovers
 * @param approvers ApproverDTO[]
 * @param responseArr WorkflowDTO[] || ApproverDTO
 */
export function sortApprovers(approvers: WorkflowDTO[], responseArr: ApproverDTO[]) {
    // sorting list of approvers/completers
    approvers
        .sort((a: any, b: any) => a.Name.localeCompare(b.Name))
        .forEach((approver: any) => {
            responseArr.push({
                Id: approver.Id,
                Name: approver.Name,
                IsTemplate: false,
                MemberRoles: approver.IsWorkflowCompleter
                    ? [
                          {
                              Role: UserRole.Processor,
                              Id: undefined,
                              IsNew: false,
                          },
                          {
                              Role: UserRole.CompletingWorkflow,
                              Id: undefined,
                              IsNew: false,
                          },
                      ]
                    : [
                          {
                              Role: UserRole.Processor,
                              Id: undefined,
                              IsNew: false,
                          },
                      ],
            });
        });
}

export const getApproveAllConfirmersByNamePart = async function(
    namePart: string,
    task: TaskDTO[] | TaskDTO,
    completersOnly: boolean,
    invoiceStatus: InvoiceStatus,
    editVisible?: boolean,
): Promise<any> {
    /**
     * If edit mode is disabled AND task is NOT provided then offer a mixed list of active workflows and approvers/completers.
     * If edit mode is enabled OR task IS provided then offer a list of approvers/completers, it should be possible to add same user for every step, but only once per each step.
     */

    let excludeIds;

    if (task) {
        excludeIds = editVisible
            ? (task as TaskDTO[]).length &&
              (task as TaskDTO[]).reduce((ids, t) => {
                  t.GroupMemberId && ids.push(t.GroupMemberId);
                  return ids;
              }, [])
            : [(task as TaskDTO).GroupMemberId];
    }

    // if Invoice is Assigned (InApproval) or Confirmed (InProgress), but not exported yet, then add request to fetch Confirmation Flow Approvers and Completers
    const requestArray: Promise<any>[] = [];
    if ([InvoiceStatus.InApproval, InvoiceStatus.InProgress].includes(invoiceStatus) || editVisible || task || completersOnly) {
        requestArray.push(
            api.company.getApproversList({
                SearchString: namePart || '',
                ExcludeIds: excludeIds,
                ApproversListDestination: ApproversListDestination.Invoice,
            }),
        );
    }
    // if Invoice is New (un-processed, not yet Assigned) or Assigned (InApproval) or Confirmed (InProgress) or Rejected but not exported yet, then add request to fetch Confirmation Flows
    if ([InvoiceStatus.New, InvoiceStatus.InApproval, InvoiceStatus.InProgress, InvoiceStatus.Rejected].includes(invoiceStatus) && !editVisible && !task && !completersOnly) {
        const searchParams: any = {
            SortItems: [
                {
                    SortColumn: 'Name',
                    SortDirection: SortDirection.Asc,
                },
            ],
            PagingOptions: {
                Page: 1,
                Count: 25,
            },
            Restrictions: [
                {
                    Field: 'GeneralSearch',
                    Value: namePart || '',
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                },
                {
                    Field: 'IsActive',
                    Value: true,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                },
                {
                    Field: 'TemplateNameOnly',
                    Value: true,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                },
            ],
        };
        requestArray.unshift(api.workflowTemplate.getCompanyTemplates(searchParams));
    }

    const results = await Promise.all(requestArray);
    const response: any[] = [];

    for (const result of results) {
        /**
         * api/Workflow/GetCompanyTemplates returns PagedListContainer<WorkflowDTO>
         *     hence check if the API request response data has property 'Items'
         * api/GroupMember/GetApproversList returns Array<GroupMemberApproverDTO>
         *     thus the API request response data will not have the 'Items' property
         */
        if (result.data.hasOwnProperty('Items')) {
            // append sorted active workflows to typeahead list
            sortWorkflows(result.data.Items, response);
        } else {
            // append sorted active approvers and workflow completers to typeahead list
            // for use with POST api/GroupMember/GetApproversList
            let approvers = result.data;
            if (completersOnly) {
                approvers = approvers.filter((app: GroupMemberApproverDTO) => app.IsWorkflowCompleter);
            }
            sortApprovers(approvers, response);
        }
    }
    return Promise.resolve(
        response.map((res) => ({
            text: res.Name,
            value: res,
        })),
    );
};

export enum EActionLabel {
    Assign = 'Assign',
    ConfirmAndAssign = 'ConfirmAndAssign',
    Confirm = 'Confirm',
    Reject = 'Reject',
    Skip = 'Skip',
}

export interface IActionLabel {
    label: string;
    type: ConfirmationFlowActions;
    icon: string;
}

export const ActionLabel: {
    [key in EActionLabel]: IActionLabel;
} = {
    Assign: {
        label: 'component.mainActions.justAssign',
        type: ConfirmationFlowActions.Assign,
        icon: ICONS.ASSIGNED,
    },
    ConfirmAndAssign: {
        label: 'component.mainActions.confirmAndAssign',
        type: ConfirmationFlowActions.ConfirmAndAssign,
        icon: ICONS.CONFIRMED,
    },
    Confirm: {
        label: 'component.mainActions.confirm',
        type: ConfirmationFlowActions.Confirm,
        icon: ICONS.CONFIRMED,
    },
    Reject: {
        label: 'component.mainActions.reject',
        type: ConfirmationFlowActions.Reject,
        icon: ICONS.REJECT,
    },
    Skip: {
        label: 'component.mainActions.skip',
        type: ConfirmationFlowActions.Skip,
        icon: ICONS.SKIPPED,
    },
};

export function isInvoiceConfirmationEditable(invoiceStatus: InvoiceStatus) {
    return invoiceStatus !== undefined && [InvoiceStatus.New, InvoiceStatus.InApproval, InvoiceStatus.InProgress, InvoiceStatus.Rejected].includes(invoiceStatus);
}

export function canUserEditInvoiceConfirmation(invoice: InvoiceDTO, isUserInsideCurrentStep: boolean, isUserInsideCompletedStep: boolean) {
    const isAssigner = userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner]);
    const isApprover = userHasOnlyProvidedConfirmationsRoles([UserRole.Processor]);
    const isApproverConfirmer = userHasOnlyProvidedConfirmationsRoles([UserRole.Processor, UserRole.CompletingWorkflow]);
    const isEditable = isInvoiceConfirmationEditable(invoice?.Status);
    if (isEditable) {
        if (isAuthorized(Role.CanApproveAndViewAnyInvoice)) {
            return true;
        }
        if (isUserInsideCompletedStep || [InvoiceStatus.New, InvoiceStatus.Rejected].includes(invoice.Status) || invoice.isLastTask) {
            return !(isApprover || isApproverConfirmer) && isAuthorized(Role.CanInvoiceConfirmationFlowEdit);
        }
        return !(isAssigner || isApprover || isApproverConfirmer) && isAuthorized(Role.CanInvoiceConfirmationFlowEdit);
    }
    return false;
}

export function canUserAddBeforeOrAfter(task: TaskDTO, userData: User) {
    const isAssigner = userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner]);
    const isApprover = userHasOnlyProvidedConfirmationsRoles([UserRole.Processor]);
    const isApproverCompleter = userHasOnlyProvidedConfirmationsRoles([UserRole.Processor, UserRole.CompletingWorkflow]);
    const isUserInsideThisStep = task?.GroupMemberId === userData?.GroupMemberId;
    const isSubstituter = task?.ToSubstituteName === userData?.FullName && task?.isCurrentConfirmer;
    if (
        (!task.Completed && isUserInsideThisStep && !(isAssigner || isApprover || isApproverCompleter)) ||
        (!task.Completed && isUserInsideThisStep && task.isCurrentConfirmer && (isApproverCompleter || isApprover)) ||
        (!task.Completed && isSubstituter)
    ) {
        return isAuthorized(Role.CanInvoiceConfirmationFlowAddBeforeAfterCurrentStep) || isSubstituter;
    }
    return false;
}

export function canUserSeeActionBlock(invoice: InvoiceDTO, isUserInsideCurrentStep: boolean, isUserInsideCompletedStep: boolean) {
    const isAssigner = userHasOnlyProvidedConfirmationsRoles([UserRole.Assigner]);
    const isApprover = userHasOnlyProvidedConfirmationsRoles([UserRole.Processor]);
    const isApproverCompleter = userHasOnlyProvidedConfirmationsRoles([UserRole.Processor, UserRole.CompletingWorkflow]);
    const isLastTask = invoice?.isLastTask;
    if ([InvoiceStatus.New, InvoiceStatus.Rejected].includes(invoice?.Status)) {
        return !(isApprover || isApproverCompleter) && isAuthorized(Role.CanInvoiceConfirmationFlowAssign);
    } else if (invoice?.Status === InvoiceStatus.InApproval) {
        if (isLastTask) {
            return true;
        }
        if (isUserInsideCompletedStep && !isUserInsideCurrentStep) {
            return !(isApprover || isApproverCompleter);
        }
        if (isUserInsideCurrentStep) {
            return !isAssigner;
        }
        if ((isAssigner || isApprover || isApproverCompleter) && !isUserInsideCurrentStep) {
            return false;
        }
    } else if (invoice?.Status > InvoiceStatus.InApproval) {
        return false;
    }
    return true;
}

function getCanUserSkipConfirmation(currentGroupTask: GroupTasksDTO, approversPerSteps: ApproverPerStep[]): boolean {
    /*
        User can skip if it's a 'parallel confirmers' step (ApproverPerStep[] is empty or does not have info about current step),
        or the number of pending approvers is bigger than the number needed to complete the step minus the number of approvers already approved
    */
    let canUserSkipConfirmation = true;

    if (currentGroupTask?.group?.length > 1) {
        canUserSkipConfirmation = false;
        const approvesNeededToComplete = getStaticStepsCounterForGroupTask(approversPerSteps, currentGroupTask);
        if (approvesNeededToComplete) {
            const approversPending = currentGroupTask.group.filter((t) => !t.Completed).length;
            const approved = currentGroupTask.group.filter((t) => t.Completed && t.StatusLookupId === ConfirmationFlowStatusLookupId.ConfirmedAction).length;
            if (approversPending > approvesNeededToComplete - approved) {
                canUserSkipConfirmation = true;
            }
        } else {
            canUserSkipConfirmation = true;
        }
    }
    return canUserSkipConfirmation;
}

export function userStatusInConfirmationFlow(
    userData: User,
    groupTasks: GroupTasksDTO[],
    approversPerSteps: ApproverPerStep[],
): {
    isUserInsideCurrentStep: boolean;
    isUserInsideCompletedStep: boolean;
    isUserInsideUpcomingStep: boolean;
    canUserSkipConfirmation: boolean;
} {
    const userGMId = userData?.GroupMemberId;
    let isUserInsideCurrentStep = false;
    let isUserInsideCompletedStep = false;
    let isUserInsideUpcomingStep = false;
    let currentGroupTask: GroupTasksDTO;

    if (userGMId && groupTasks?.length) {
        isUserInsideCurrentStep = !!groupTasks.find((gt) =>
            gt.group.reduce((prev, curr) => {
                if (!curr.CompletedDate && curr.isCurrentConfirmer && (curr.GroupMemberId === userData.GroupMemberId || curr.ToSubstituteName === userData.FullName)) {
                    currentGroupTask = gt;
                    return true;
                }
                return prev;
            }, false),
        );
        isUserInsideCompletedStep = !!groupTasks.find((gt) =>
            gt.group.reduce((prev, curr) => {
                if (curr.GroupMemberId === userData.GroupMemberId && !!curr.CompletedDate) {
                    return true;
                }
                return prev;
            }, false),
        );
        isUserInsideUpcomingStep = !!groupTasks.find((gt) =>
            gt.group.reduce((prev, curr) => {
                if (!curr.CompletedDate && !curr.isCurrentConfirmer && (curr.GroupMemberId === userData.GroupMemberId || curr.ToSubstituteName === userData.FullName)) {
                    return true;
                }
                return prev;
            }, false),
        );
    }

    const canUserSkipConfirmation = getCanUserSkipConfirmation(currentGroupTask, approversPerSteps);

    return {
        isUserInsideCurrentStep,
        isUserInsideCompletedStep,
        isUserInsideUpcomingStep,
        canUserSkipConfirmation,
    };
}

export function getInvoiceActionsForAssigner(invoice: InvoiceDTO, userData: User, isUserInsideCurrentStep: boolean) {
    const mainAction: IActionLabel = ActionLabel.Assign;
    const moreActions: any[] = [];
    if ([InvoiceStatus.New, InvoiceStatus.Rejected].includes(invoice.Status)) {
        moreActions.push(ActionLabel.ConfirmAndAssign, ActionLabel.Reject);
    } else if (invoice.Status === InvoiceStatus.InApproval) {
        if (isUserInsideCurrentStep) {
            if (invoice.isLastTask) {
                moreActions.push(ActionLabel.ConfirmAndAssign, ActionLabel.Reject);
            }
        }
    }

    return {
        mainAction,
        moreActions,
    };
}

export function getInvoiceActionsForApprover(invoice: InvoiceDTO, userData: User, isUserInsideCurrentStep: boolean) {
    const mainAction: IActionLabel = ActionLabel.Confirm;
    const moreActions: any[] = [];
    if (invoice.Status === InvoiceStatus.InApproval) {
        if (isUserInsideCurrentStep) {
            if (invoice.isLastTask) {
                moreActions.push(ActionLabel.Assign, ActionLabel.ConfirmAndAssign, ActionLabel.Reject);
            } else {
                moreActions.push(ActionLabel.Assign, ActionLabel.ConfirmAndAssign, ActionLabel.Reject, ActionLabel.Skip);
            }
        }
    }

    return {
        mainAction,
        moreActions,
    };
}

export function getInvoiceActionsForApproverAssigner(invoice: InvoiceDTO, userData: User, isUserInsideCurrentStep: boolean, isUserInsideCompletedStep: boolean, isUserInsideUpcomingStep: boolean) {
    const isCompleter: boolean = userData.MemberRoles.includes(UserRole.CompletingWorkflow);
    let mainAction: IActionLabel = ActionLabel.Assign;
    const moreActions: any[] = [];
    if ([InvoiceStatus.New, InvoiceStatus.Rejected].includes(invoice.Status)) {
        mainAction = ActionLabel.Assign;
        moreActions.push(ActionLabel.ConfirmAndAssign, ActionLabel.Reject);
    } else if (invoice.Status === InvoiceStatus.InApproval) {
        if (invoice.isLastTask) {
            if (isUserInsideCurrentStep) {
                if (isCompleter) {
                    mainAction = ActionLabel.Confirm;
                    moreActions.push(ActionLabel.Assign, ActionLabel.ConfirmAndAssign, ActionLabel.Reject);
                } else {
                    mainAction = ActionLabel.Assign;
                    moreActions.push(ActionLabel.ConfirmAndAssign, ActionLabel.Reject);
                }
            } else if (isUserInsideCompletedStep) {
                mainAction = ActionLabel.Assign;
                moreActions.push(ActionLabel.Confirm, ActionLabel.ConfirmAndAssign, ActionLabel.Reject);
                if (isCompleter) {
                    moreActions.push(ActionLabel.Skip);
                }
            }
        } else {
            if (isUserInsideCurrentStep) {
                mainAction = ActionLabel.Confirm;
                moreActions.push(ActionLabel.Assign, ActionLabel.ConfirmAndAssign, ActionLabel.Reject, ActionLabel.Skip);
            } else {
                mainAction = ActionLabel.Assign;
                moreActions.push(ActionLabel.Confirm, ActionLabel.ConfirmAndAssign, ActionLabel.Reject);
                if (isCompleter || isUserInsideUpcomingStep) {
                    moreActions.push(ActionLabel.Skip);
                }
            }
        }
    }

    return {
        mainAction,
        moreActions,
    };
}

export function getInvoiceActionsForAdmin(invoice: InvoiceDTO, userData: User, isUserInsideCurrentStep: boolean, isUserInsideCompletedStep: boolean) {
    let mainAction: IActionLabel = ActionLabel.Assign;
    const moreActions: any[] = [];
    if ([InvoiceStatus.New, InvoiceStatus.Rejected].includes(invoice.Status)) {
        mainAction = ActionLabel.Assign;
        moreActions.push(ActionLabel.Confirm, ActionLabel.ConfirmAndAssign, ActionLabel.Reject);
    } else if (invoice.Status === InvoiceStatus.InApproval) {
        if (invoice.isLastTask) {
            if (isUserInsideCurrentStep) {
                mainAction = ActionLabel.Confirm;
                moreActions.push(ActionLabel.Assign, ActionLabel.ConfirmAndAssign, ActionLabel.Reject);
            } else if (isUserInsideCompletedStep) {
                mainAction = ActionLabel.Assign;
                moreActions.push(ActionLabel.Confirm, ActionLabel.ConfirmAndAssign, ActionLabel.Reject, ActionLabel.Skip);
            } else {
                mainAction = ActionLabel.Assign;
            }
        } else {
            if (isUserInsideCurrentStep) {
                mainAction = ActionLabel.Confirm;
                moreActions.push(ActionLabel.Assign, ActionLabel.ConfirmAndAssign, ActionLabel.Reject, ActionLabel.Skip);
            } else {
                mainAction = ActionLabel.Assign;
                moreActions.push(ActionLabel.Confirm, ActionLabel.ConfirmAndAssign, ActionLabel.Reject, ActionLabel.Skip);
            }
        }
    }

    return {
        mainAction,
        moreActions,
    };
}

export function validateInvoiceConfirm(invoice?: InvoiceDTO, action?: ConfirmationFlowActions, confirmer?: GroupMemberDTO[]) {
    if (!invoice?.isLastTask) {
        // skip all verifications
        return true;
    }
    let error = '';
    if (action === ConfirmationFlowActions.Confirm) {
        const canApproveInvoiceWithoutNextConfirmer = isAuthorized('CanApproveInvoiceWithoutNextConfirmer');
        if (!canApproveInvoiceWithoutNextConfirmer && !confirmer) {
            error += 'controller.invoiceConfirmationController.NotEnoughRights';
        } else if (!canApproveInvoiceWithoutNextConfirmer && !confirmer && !hasAnyCompletedTask(invoice)) {
            error += 'controller.invoiceConfirmationController.NotEnoughRights';
        }
    }
    if (error !== '') {
        notify.error(i18nInstance.t(error), i18nInstance.t('controller.invoiceConfirmationController.ActionDenied'));
    }
    console.log(error);
    return !error;
}

function hasAnyCompletedTask(item: InvoiceDTO) {
    if (item && item.Workflow && item.Workflow.Tasks && item.Workflow.Tasks.length > 0) {
        const task = find(item.Workflow.Tasks, function(t) {
            return t.Id;
        });
        return task;
    }
    return undefined;
}

export function createInvoiceAction(action: ConfirmationFlowActions, invoiceId: number, comment: string, nextConfirmer: any) {
    return {
        InvoiceId: invoiceId,
        Comment: comment,
        NextConfirmerGroupMemberOrWorkflowTemplateId: nextConfirmer ? nextConfirmer.Id : null,
        IsNextConfirmerWorkflowTemplate: nextConfirmer ? nextConfirmer.IsTemplate : null,
        Action: action,
    };
}

const store = storeAndPersistor.store;

export function areTransactionsValid(invoice: InvoiceDTO) {
    const companySetting = selectCurrentCompanySettings(store.getState());
    const isTransactionRowsCheckEnabled = companySetting.find((c) => c.Name === 'IsTransactionRowsCheckEnabled' && c.Value === CompanySettingStatus.Enabled);
    const isTransactionRowsPenultimateCheckEnabled = companySetting.find((c) => c.Name === 'IsTransactionRowsPenultimateCheckEnabled' && c.Value === CompanySettingStatus.Enabled);
    const skipTransactionRowsVerificationWhenPOSpecified = companySetting.find((c) => c.Name === 'SkipTransactionRowsVerificationWhenPOSpecified' && c.Value === CompanySettingStatus.Enabled);

    if (!isTransactionRowsCheckEnabled && !isTransactionRowsPenultimateCheckEnabled) {
        // skip all verifications
        return true;
    }
    if (isTransactionRowsCheckEnabled && skipTransactionRowsVerificationWhenPOSpecified && invoice.PurchaseOrder != null && invoice.PurchaseOrder !== '') {
        // skip completeness verification
        return true;
    }
    return invoice.InvoiceAccountingRowsTotal !== 0;
}

export function isPenultimateConfirmer(invoice: InvoiceDTO) {
    if (invoice) {
        if (invoice && invoice.Workflow && invoice.Workflow.Tasks && invoice.Workflow.Tasks.length > 0) {
            const lastTask = maxBy(invoice.Workflow.Tasks, (task) => {
                return task.OrderNo;
            });
            return lastTask && lastTask.OrderNo === invoice.Workflow.CurrentStep + 1;
        }
    }
    return false;
}

function isPenultimateCheckEnabled() {
    const companySetting = selectCurrentCompanySettings(store.getState());
    return companySetting.find((c) => c.Name === 'IsTransactionRowsPenultimateCheckEnabled' && c.Value === CompanySettingStatus.Enabled);
}

/*
  Confirm entire invoice
*/
export async function validateForConfirmationInvoice(invoice: InvoiceDTO, $rootScope: any, t: TFunction): Promise<boolean> {
    const companyData = getCurrentCompany(store.getState());
    if (areTransactionsValid(invoice)) {
        const isPenultimate = isPenultimateCheckEnabled() && isPenultimateConfirmer(invoice);
        const nextConfirmer = getNextApprover(invoice, companyData.Users);
        if (!nextConfirmer || isPenultimate) {
            if (!isAuthorized('CanApproveInvoiceWithoutNextConfirmer') && !isAuthorized('CanApproveAndViewAnyInvoice') && !isPenultimate) {
                // if user does not have workflow completer rights
                return false;
            } else {
                // if user does have workflow completer rights
                // check if all transaction rows are valid
                try {
                    const response = await api.invoice.areTransactionRowsValid(invoice.Id);
                    if (response.data.length > 0) {
                        $rootScope.$emit('mustValidateTransactionRows', response.data);
                        notify.error(t('component.mainActions.InvalidTransactionsMessage.confirm'));
                        return false;
                    }
                    const res = await api.invoice.checkTransactionRowsBudget(invoice.Id);
                    if (!!res.data && res.data.some((item) => !item.IsibResponse?.Succeeded)) {
                        notify.error(t('component.mainActions.UnderBudget.confirm'));
                        return false;
                    }
                    const resp = await api.invoice.areCustomFieldsValid(invoice.Id);
                    if (resp.data.length > 0) {
                        notify.error(t('component.mainActions.InvalidCustomFieldsMessage.confirm'));
                        return false;
                    }
                    return true;
                } catch (err) {
                    return false;
                }
            }
        } else {
            return true;
        }
    } else {
        return false;
    }
}

function getNextApprover(invoice: InvoiceDTO, users: UserMinDTO[]) {
    if (invoice) {
        const task = getNextUncompletedTask(invoice);
        if (task) {
            return getGroupMemberUser(task, users);
        }
    }
    return undefined;
}

function getNextUncompletedTask(invoice: InvoiceDTO) {
    if (invoice?.Workflow?.Tasks.length > 0) {
        const tasks = invoice.Workflow.Tasks.sort(function(a, b) {
            return a.OrderNo - b.OrderNo;
        });
        return find(tasks, function(t) {
            return !t.Completed && t.OrderNo > invoice.Workflow.CurrentStep;
        });
    }
    return undefined;
}

function getGroupMemberUser(task: TaskDTO, users: UserMinDTO[]) {
    return find(users, function(r) {
        return task.GroupMember && r.GroupMemberId === task.GroupMember.Id;
    });
}

export const getIconProgressBar = (item: TaskDTO): string => {
    if (item?.Completed) {
        switch (item.StatusLookupId) {
            case ConfirmationFlowStatusLookupId.AssignedAction:
                return ICONS.ASSIGNED;
            case ConfirmationFlowStatusLookupId.ConfirmedAction:
                return ICONS.CONFIRMED;
            case ConfirmationFlowStatusLookupId.RejectedAction:
                return ICONS.REJECT;
            case ConfirmationFlowStatusLookupId.Skipped:
                return ICONS.SKIPPED;
            case ConfirmationFlowStatusLookupId.ReOpenAction:
                return ICONS.ASSIGNED;
            case ConfirmationFlowStatusLookupId.ExternalApprovement:
                return ICONS.SKIPPED;
            case ConfirmationFlowStatusLookupId.NoActionTaken:
                return ICONS.AUTO_SKIP;
            default:
                return ICONS.SKIPPED;
        }
    }
    if (item?.isCurrentConfirmer) {
        return ICONS.CURRENT_CONFIRM;
    }
    return ICONS.UP_COMING_FLOW;
};

export const getGroupTaskIconProgressBar = (group: TaskDTO[], XofY?: number): string => {
    const length = group.length;
    const isCompleted = group.every((t) => t.Completed);
    const confirmedNo = group.filter((t) => t.StatusLookupId === ConfirmationFlowStatusLookupId.ConfirmedAction).length;
    if (isCompleted) {
        let groupStatus;
        if (length === 1) {
            groupStatus = group[0].StatusLookupId;
        } else {
            if (group.some((t) => t.StatusLookupId === ConfirmationFlowStatusLookupId.RejectedAction)) {
                groupStatus = ConfirmationFlowStatusLookupId.RejectedAction;
            } else if (XofY ? XofY === confirmedNo : length === confirmedNo) {
                groupStatus = ConfirmationFlowStatusLookupId.ConfirmedAction;
            } else {
                // might need some updates to consider special cases like ReOpenAction/ExternalApprovement...
                groupStatus = group[0].StatusLookupId;
            }
        }
        switch (groupStatus) {
            case ConfirmationFlowStatusLookupId.AssignedAction:
                return ICONS.ASSIGNED;
            case ConfirmationFlowStatusLookupId.ConfirmedAction:
                return ICONS.CONFIRMED;
            case ConfirmationFlowStatusLookupId.RejectedAction:
                return ICONS.REJECT;
            case ConfirmationFlowStatusLookupId.Skipped:
                return ICONS.SKIPPED;
            case ConfirmationFlowStatusLookupId.ReOpenAction:
                return ICONS.ASSIGNED;
            case ConfirmationFlowStatusLookupId.ExternalApprovement:
                return ICONS.SKIPPED;
            case ConfirmationFlowStatusLookupId.NoActionTaken:
                return ICONS.AUTO_SKIP;
            default:
                return ICONS.SKIPPED;
        }
    }
    const isCurrentConfirmer = group.some((t) => t.isCurrentConfirmer);
    if (isCurrentConfirmer) {
        return ICONS.CURRENT_CONFIRM;
    }
    return ICONS.UP_COMING_FLOW;
};

export const getTaskContentStyles = (group: TaskDTO[]): string => {
    const isCompleted = group.every((t) => t.Completed);
    const isCurrentConfirmer = group.some((t) => t.isCurrentConfirmer);
    return classNames(
        'confirmation__task-content',
        { 'confirmation__task-content--completed': isCompleted },
        { 'confirmation__task-content--upcoming': (!isCompleted && !isCurrentConfirmer) || !group.length },
        { 'confirmation__task-content--current': isCurrentConfirmer },
    );
};

export const showSubstituteName = (taskItem: TaskDTO, userData: User): boolean => {
    if (taskItem?.CompletedDate && taskItem?.GroupMember?.SubstituterUserFullName && taskItem?.GroupMember.Name !== taskItem.GroupMember.SubstituterUserFullName) {
        return true;
    }
    if (taskItem?.GroupMemberId === userData?.GroupMemberId) {
        return false;
    }
    const { GroupMember: user } = taskItem;
    if (user && user.SubstituterUserFullName) {
        return user.Name !== user.SubstituterUserFullName && user.SubstituterUserFullName.length > 0;
    }
    return false;
};

export const getConfirmerName = (taskItem: TaskDTO, userData: User) => {
    const shouldShowSubstituteName = showSubstituteName(taskItem, userData);
    let confirmerName;
    // if current user is substituting for someone load that substitute users full name
    if (shouldShowSubstituteName) {
        confirmerName = taskItem.GroupMember.SubstituterUserFullName;
    } else {
        /**
         * I'm really not sure what this prop stands for here...
         * but seems to be the case where user is marked as "out-of-office"
         * then the substitutes name should be displayed behind the original users name in parenthesis
         */
        if (taskItem.ToSubstituteName) {
            confirmerName = `${taskItem.GroupMember?.Name} (${taskItem.ToSubstituteName})`;
        } else {
            /**
             * send the Name attached to the taskItems GroupMember object.
             */
            confirmerName = taskItem.GroupMember?.Name;
        }
    }
    return confirmerName;
};

export function addCurrentUserAsFirst(editedTasks: GroupTasksDTO[], userData: User) {
    const newEditedTasks = cloneDeep(editedTasks);
    newEditedTasks.unshift({
        type: TaskType.Default,
        group: [
            {
                OrderNo: null,
                Completed: false,
                Id: null,
                CompletedDate: null,
                Comment: null,
                ProcessedBy: null,
                GroupMemberId: userData.GroupMemberId,
                GroupMember: undefined,
                ToSubstituteName: '',
                WorkflowId: undefined,
                StatusLookupId: undefined,
                displayAddAfterMeProp: undefined,
                displayAddBeforeMeProp: undefined,
                isCurrentConfirmer: undefined,
                IsNew: undefined,
                CreatorName: null,
            },
        ],
    });
    return newEditedTasks;
}
