import React, { useState, useEffect } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { isEmpty, isEqual } from 'lodash-es';
import * as Yup from 'yup';

import FormikField from '../../../../../common/utils/FormikField';
import { Button, ButtonType, ButtonIconPlacement } from '../../../../../components/Buttons/Button';
import { LabelStyle, TextInputField, TextInputType } from '../../../../../components/TextInput/TextInput';
import { InvoiceDTO, InvoiceDataResponseDTO, InvoiceSliderMinDTO, CompanyDTO, PagedListContainer, BaseSearch, InvoiceTypeDTO, PaymentMethodDTO } from '../../../../../services/types/ApiTypes';
import { createDataId } from '../../../../../common/utils/dataId';
import { ICONS } from '../../../../../components/Icon/Icon';
import createFieldNameConstants from '../../../../../common/utils/fieldNames';
import { InvoiceEditAdditionalValues } from './InvoiveHeaderTypes';
import { TypeaheadAsyncField, TypeaheadItem } from '../../../../../components/Typeahead/TypeaheadAsync';
import PropertyListItem from '../../../../../components/PropertyListItem/PropertyListItem';
import Modal from '../../../../../components/Modal/Modal';
import { LoadableData } from '../../../../../common/utils/LoadableData';
import { checkUserOnlyRoleExpenseCreator } from '../../../../../common/user/userPermissionUtil';
import { EXPENSE_TYPE } from '../../../../../common/constants/appConstants';
import usePreventUnload from '../../../../../common/hooks/usePreventUnload';
import { getInvoiceTypesTypeaheadItems } from '../../../../settings/invoice-types/InvoiceTypesListHelpers';
import { getPaymentMethodsTypeaheadItems } from '../../../../settings/payment-methods/PaymentMethodsHelpers';
import { WithTranslateFormErrors } from '../../../../../components/WithTranslateFormErrors/WithTranslateFormErrors';

export const dataId = 'invoice-header.basic-info-edit';

interface Props {
    currentCompanyName: string;
    invoice: InvoiceDataResponseDTO & InvoiceDTO;
    cancelEditting: () => void;
    t: TFunction;
    saveInvoice: (i: InvoiceDTO & InvoiceDataResponseDTO) => void;
    getCompaniesByName: (s: string) => Promise<CompanyDTO[]>;
    invoiceSliderDataLoadable: LoadableData<PagedListContainer<InvoiceSliderMinDTO>, BaseSearch>;
    invoiceMovedTo: Pick<InvoiceDTO, 'Id' | 'CompanyGuid'> | null;
    isInvoiceLoading: boolean;
}

const initialInvoiceAdditionalValues: InvoiceEditAdditionalValues = {
    Buyer: null,
    ContactName: null,
    PurchaseOrder: null,
    SupplierContactPersonName: null,
    SupplierEmail: null,
    DocumentType: null,
    InvoiceType: null,
    PaymentMethod: null,
};

const {
    Buyer: BuyerField,
    ContactName: ContactNameField,
    PurchaseOrder: PurchaseOrderField,
    SupplierContactPersonName: SupplierContactPersonNameField,
    SupplierEmail: SupplierEmailField,
    InvoiceType: InvoiceTypeField,
    PaymentMethod: PaymentMethodField,
} = createFieldNameConstants(initialInvoiceAdditionalValues);

const createInvoiceTypeField = (invoiceType: InvoiceTypeDTO) => ({
    text: invoiceType ? `${invoiceType.Code} - ${invoiceType.Description}` : null,
    value: invoiceType || null,
});

const AdditionalInfoEditMode = ({
    currentCompanyName,
    getCompaniesByName,
    invoice,
    invoiceMovedTo,
    invoiceSliderDataLoadable,
    isInvoiceLoading,
    cancelEditting,
    t,
    saveInvoice: saveUpdatedInvoice,
}: Props) => {
    const [invoiceFormValues, setInvoiceFormValues] = useState<InvoiceEditAdditionalValues>(initialInvoiceAdditionalValues);
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const [selectedInvoiceType, setSelectedInvoiceType] = useState<TypeaheadItem<InvoiceTypeDTO>>(createInvoiceTypeField(invoice?.InvoiceType));
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<TypeaheadItem<PaymentMethodDTO>>({
        text: invoice?.PaymentMethod ? `${invoice?.PaymentMethod?.Code} - ${invoice?.PaymentMethod?.Description}` : null,
        value: invoice?.PaymentMethod || null,
    });
    const [isUserExpenseCreatorOnly, setIsUserExpenseCreatorOnly] = useState<boolean>(false);

    // TODO: implement app inner route watcher to prevent loosing entered data
    usePreventUnload(isFormDirty);

    useEffect(() => {
        if (invoice) {
            const newInvoiceValues: InvoiceEditAdditionalValues = { ...initialInvoiceAdditionalValues };
            Object.keys(initialInvoiceAdditionalValues).map((key) => (newInvoiceValues[key] = invoice[key]));
            newInvoiceValues.Buyer = { text: currentCompanyName, value: { CompanyName: currentCompanyName } as CompanyDTO };
            newInvoiceValues.DocumentType = null;
            newInvoiceValues.SupplierContactPersonName = invoice.Supplier?.ContactPersonName;
            newInvoiceValues.SupplierEmail = invoice.Supplier?.Email;
            if (!isEqual(invoiceFormValues, newInvoiceValues) && isFormDirty) {
                // prevent showing warning message when a new invoice is loaded and user wants to navigates away before making changes
                setIsFormDirty(false);
            }
            setInvoiceFormValues(newInvoiceValues);
            if (invoice?.InvoiceType) {
                newInvoiceValues.InvoiceType = {
                    text: `${invoice?.InvoiceType?.Code} - ${invoice?.InvoiceType?.Description}`,
                    value: invoice?.InvoiceType,
                };
                setSelectedInvoiceType(newInvoiceValues.InvoiceType);
            }
            if (invoice?.PaymentMethod) {
                newInvoiceValues.PaymentMethod = {
                    text: `${invoice?.PaymentMethod?.Code} - ${invoice?.PaymentMethod?.Description}`,
                    value: invoice?.PaymentMethod,
                };
                setSelectedPaymentMethod(newInvoiceValues.PaymentMethod);
            }
        }
    }, [invoice]);

    const initExpenseInvoiceType = async () => {
        const expenseInvoiceTypes = await getInvoiceTypesTypeaheadItems(EXPENSE_TYPE);
        if (expenseInvoiceTypes.length) {
            setSelectedInvoiceType(createInvoiceTypeField(expenseInvoiceTypes[0]));
        }
    };

    useEffect(() => {
        const isExpenseCreatorOnly = checkUserOnlyRoleExpenseCreator();
        if (isExpenseCreatorOnly) {
            setIsUserExpenseCreatorOnly(isExpenseCreatorOnly);
            initExpenseInvoiceType();
        }
    }, []);

    const setInvoiceTypeAndCheckPaymentMethod = async (invoiceType: TypeaheadItem<InvoiceTypeDTO>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        setSelectedInvoiceType(invoiceType);
        if (selectedPaymentMethod) {
            const checkPaymentMethod = await getPaymentMethodsTypeaheadItems(selectedPaymentMethod?.value?.Code, invoiceType?.value?.Type);
            if (isEmpty(checkPaymentMethod)) {
                setSelectedPaymentMethod(null);
                setFieldValue(PaymentMethodField, null);
            }
        }
    };

    const validationSchema = Yup.object<InvoiceEditAdditionalValues>().shape({
        [BuyerField]: Yup.string()
            .required(t('view.general.mandatoryField'))
            .typeError(t('view.general.mandatoryField')),
    });

    const mapFormValuesToInvoiceDTO = (vals: InvoiceEditAdditionalValues): InvoiceDTO & InvoiceDataResponseDTO => {
        const { SupplierContactPersonName, DocumentType, SupplierEmail, ...rest } = vals;
        const isSupplierInfoSame = isEqual(invoice.Supplier.ContactPersonName, SupplierContactPersonName) && isEqual(invoice.Supplier.Email, SupplierEmail);
        let CompanyGuid: string;
        let buyerChanged = false;
        if (vals.Buyer?.value?.CompanyName !== currentCompanyName) {
            // we need to change invoice company (Buyer) after saving other invoice data
            CompanyGuid = vals.Buyer.value.CompanyGuid;
            buyerChanged = true;
        }

        return {
            ...invoice,
            ...rest,
            CompanyGuid,
            DocumentType: DocumentType?.value,
            InvoiceType: buyerChanged ? null : selectedInvoiceType?.value || null,
            InvoiceTypeId: buyerChanged ? null : selectedInvoiceType?.value?.Id || null,
            PaymentMethod: buyerChanged ? null : selectedPaymentMethod?.value || null,
            PaymentMethodId: buyerChanged ? null : selectedPaymentMethod?.value?.Id || null,
            // we send a null Supplier, if nothing changed in SupplierDTO
            Supplier: isSupplierInfoSame ? null : { ...invoice.Supplier, ContactPersonName: SupplierContactPersonName, Email: SupplierEmail },
        };
    };

    const saveInvoice = (values: InvoiceEditAdditionalValues) => saveUpdatedInvoice(mapFormValuesToInvoiceDTO(values));

    const onCompanyRedirectConfirm = () => {
        const redirectionUrl = `/app/#/invoiceconfirmation/${invoiceMovedTo.Id}?companyGuid=${invoiceMovedTo.CompanyGuid}`;
        window.location.href = redirectionUrl;
    };

    const onCompanyRedirectReject = () => {
        // redirect to next invoice from slider or invoice register (if no more slider invoices)
        const sliderInvoices = invoiceSliderDataLoadable.payload?.Items;
        const nextInvoiceIndex = sliderInvoices?.length ? sliderInvoices.findIndex((i) => i.Id === invoice.Id) + 1 : null;

        if (nextInvoiceIndex && sliderInvoices[nextInvoiceIndex]) {
            window.location.href = `/app/#/invoiceconfirmation/${sliderInvoices[nextInvoiceIndex].Id}`;
        } else {
            window.location.href = '/app/#/invoices/';
        }
    };

    return (
        <Formik onSubmit={saveInvoice} initialValues={invoiceFormValues} enableReinitialize={true} validateOnChange={false} validationSchema={validationSchema}>
            {(formik: FormikProps<InvoiceEditAdditionalValues>) => {
                return (
                    <WithTranslateFormErrors errors={formik.errors} setFieldTouched={formik.setFieldTouched} touched={formik.touched}>
                        <Form onChange={() => setIsFormDirty(true)}>
                            <div className="invoice-header__fields">
                                <ul>
                                    <FormikField
                                        component={TypeaheadAsyncField}
                                        dataId={createDataId(dataId, 'buyer')}
                                        name={BuyerField}
                                        searchOnFocus
                                        toggleVisible
                                        inputProps={{ label: t('component.additionalInfo.buyer'), labelStyle: LabelStyle.UPPERCASE, type: TextInputType.COMPACT }}
                                        loadData={getCompaniesByName}
                                        itemToText={(c: CompanyDTO) => c?.CompanyName}
                                        placeholder={t('component.additionalInfo.buyer')}
                                        wrapperClass="limit-width"
                                    />
                                    <FormikField
                                        component={TextInputField}
                                        dataId={createDataId(dataId, 'buyerContact')}
                                        name={ContactNameField}
                                        type={TextInputType.COMPACT}
                                        label={t('component.additionalInfo.buyerContact')}
                                        labelStyle={LabelStyle.UPPERCASE}
                                        placeholder={t('component.additionalInfo.buyerContact')}
                                        wrapperClass="fixed-height limit-width"
                                    />
                                    <FormikField
                                        component={TextInputField}
                                        dataId={createDataId(dataId, 'purchaseOrder')}
                                        name={PurchaseOrderField}
                                        type={TextInputType.COMPACT}
                                        label={t('component.additionalInfo.purchaseOrder')}
                                        labelStyle={LabelStyle.UPPERCASE}
                                        placeholder={t('component.additionalInfo.purchaseOrder')}
                                        wrapperClass="fixed-height limit-width"
                                    />
                                </ul>
                                <ul>
                                    <PropertyListItem dataId={dataId} label={t('component.additionalInfo.supplierAddress')} value={invoice?.SupplierAddress} className="fixed-height" />
                                    <FormikField
                                        component={TextInputField}
                                        dataId={createDataId(dataId, 'supplierContactName')}
                                        name={SupplierContactPersonNameField}
                                        type={TextInputType.COMPACT}
                                        label={t('component.additionalInfo.supplierContactName')}
                                        labelStyle={LabelStyle.UPPERCASE}
                                        placeholder={t('component.additionalInfo.supplierContactName')}
                                        wrapperClass="fixed-height"
                                    />
                                    <FormikField
                                        component={TextInputField}
                                        dataId={createDataId(dataId, 'supplierContactEmail')}
                                        name={SupplierEmailField}
                                        type={TextInputType.COMPACT}
                                        label={t('component.additionalInfo.supplierContactEmail')}
                                        labelStyle={LabelStyle.UPPERCASE}
                                        placeholder={t('component.additionalInfo.supplierContactEmail')}
                                        wrapperClass="fixed-height"
                                    />
                                    <FormikField
                                        component={TypeaheadAsyncField}
                                        dataId={createDataId(dataId, 'DocumentType')}
                                        name={InvoiceTypeField}
                                        searchOnFocus
                                        toggleVisible
                                        loadData={getInvoiceTypesTypeaheadItems}
                                        onChange={(invoiceType: TypeaheadItem<InvoiceTypeDTO>) => {
                                            setInvoiceTypeAndCheckPaymentMethod(invoiceType, formik.setFieldValue);
                                        }}
                                        value={selectedInvoiceType}
                                        inputProps={{
                                            disabled: isUserExpenseCreatorOnly,
                                            label: t('report.InvoiceFrontPage.InvoiceType'),
                                            labelStyle: LabelStyle.UPPERCASE,
                                            type: TextInputType.COMPACT,
                                        }}
                                        itemToText={(c: InvoiceTypeDTO) => `${c?.Code} - ${c?.Description}`}
                                        placeholder={t('report.InvoiceFrontPage.InvoiceType')}
                                    />
                                    <FormikField
                                        component={TypeaheadAsyncField}
                                        dataId={createDataId(dataId, 'PaymentMethod')}
                                        name={PaymentMethodField}
                                        searchOnFocus
                                        toggleVisible
                                        loadData={(s: string) => getPaymentMethodsTypeaheadItems(s, selectedInvoiceType?.value?.Type)}
                                        onChange={(paymentMethod: TypeaheadItem<PaymentMethodDTO>) => {
                                            setSelectedPaymentMethod(paymentMethod);
                                        }}
                                        value={selectedPaymentMethod}
                                        inputProps={{ label: t('component.additionalInfo.paymentMethod'), labelStyle: LabelStyle.UPPERCASE, type: TextInputType.COMPACT }}
                                        itemToText={(c: PaymentMethodDTO) => `${c?.Code} - ${c?.Description}`}
                                        placeholder={t('component.additionalInfo.paymentMethod')}
                                    />
                                </ul>
                            </div>
                            <div className="invoice-header__actions invoice-header__actions--save-cancel">
                                <div className="text-button-wrapper">
                                    <Button
                                        disabled={isInvoiceLoading}
                                        onClick={cancelEditting}
                                        dataId={createDataId(dataId, 'cancelEdittingButton')}
                                        buttonType={ButtonType.ICON_TEXT}
                                        icon={ICONS.CLOSE_SMALL}
                                        iconPlacement={ButtonIconPlacement.LEFT}
                                    >
                                        {t('component.invoiceHeader.cancelEditing')}
                                    </Button>
                                </div>
                                <div className="text-button-wrapper">
                                    <Button
                                        disabled={!formik.dirty || isInvoiceLoading}
                                        buttonType={ButtonType.ICON_TEXT}
                                        dataId={createDataId(dataId, 'submitButton')}
                                        icon={ICONS.SAVE}
                                        type="submit"
                                        iconPlacement={ButtonIconPlacement.LEFT}
                                    >
                                        {t('component.invoiceHeader.save')}
                                    </Button>
                                </div>
                            </div>
                            <Modal isOpen={!isEmpty(invoiceMovedTo)} onClose={onCompanyRedirectReject} dataId={createDataId(dataId, 'on-buyer-change-confirmal')}>
                                {t('component.additionalInfo.onBuyerChangeModal')}
                                <div className="on-buyer-change-confirmal__actions">
                                    <Button onClick={onCompanyRedirectConfirm} buttonType={ButtonType.SECONDARY} dataId={createDataId(dataId, 'modal-confirm')}>
                                        {t('component.additionalInfo.modal.redirectToCompany')}
                                    </Button>
                                    <Button onClick={onCompanyRedirectReject} buttonType={ButtonType.PRIMARY} dataId={createDataId(dataId, 'modal-reject')}>
                                        {t('component.additionalInfo.modal.proceedNextInvoice')}
                                    </Button>
                                </div>
                            </Modal>
                        </Form>
                    </WithTranslateFormErrors>
                );
            }}
        </Formik>
    );
};

export default AdditionalInfoEditMode;
