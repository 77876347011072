import classNames from 'classnames';
import { size } from 'lodash-es';
import * as React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import { addDataId, createDataId, DataId } from '../../common/utils/dataId';
import { BaseSearch, SortDirection } from '../../services/types/ApiTypes';
import { BaseComponent } from '../BaseComponent';
import Icon, { ICONS } from '../Icon/Icon';
import { TagSelect, TagSelectItem, TagSelectProps, TagSelectType } from '../TagSelect/TagSelect';

import './Table.scss';

type ThElem = React.ThHTMLAttributes<HTMLTableHeaderCellElement>;

export interface TableCellSnapshot {
    width: number;
    height: number;
}

export interface TableHeadProps extends Pick<ThElem, Exclude<keyof ThElem, 'onClick'>> {
    children: React.ReactNode;
    className?: string;
    columnName?: string;
    filterOptions?: Partial<TagSelectProps<any>>;
    filterable?: boolean;
    filterActive?: boolean;
    filterValues?: Array<{ value: string; text: string }>;
    filterItems?: Array<{ value: string; text: string }>;
    filterLoadItems?: (searchString: string) => Promise<Array<TagSelectItem<any>>>;
    filterPlaceholder?: string;
    hide?: boolean;
    sortable?: boolean;
    searchParams?: BaseSearch;
    dataId?: DataId;
    showPlaceholder?: boolean;
    tableHeight?: number;
    onFilter?(items: Array<TagSelectItem<any>>): void;
    onClick?(columnName: string): void;
    isDragOccurring?: boolean;
    dragHandleProps?: DraggableProvidedDragHandleProps;
    resizable?: boolean;
    columnWidth?: number;
    minColumnWidth?: number;
    handleColumnWidthChange?: (columnName: string, width: number) => void;
}

class TableHead extends BaseComponent<TableHeadProps> {
    private ref = React.createRef<HTMLTableDataCellElement>();
    private isDragging = false;
    private thStartOffset = 0;

    getSnapshotBeforeUpdate(prevProps: TableHeadProps): TableCellSnapshot {
        if (!this.ref || !this.ref.current) {
            return null;
        }

        const isDragStarting: boolean = this.props.isDragOccurring && !prevProps.isDragOccurring;

        if (!isDragStarting) {
            return null;
        }

        const { width, height } = this.ref.current.getBoundingClientRect();

        const snapshot: TableCellSnapshot = {
            width,
            height,
        };

        return snapshot;
    }

    handleWidthChange = (width: number) => {
        this.ref.current.style.maxWidth = `${width}px`;
        this.ref.current.style.minWidth = `${width}px`;
        this.ref.current.style.width = `${width}px`;
    };

    componentDidUpdate(prevProps: TableHeadProps, prevState: any, snapshot: TableCellSnapshot) {
        const ref: HTMLElement = this.ref.current;
        if (!ref) {
            return;
        }

        if (snapshot) {
            if (ref.style.width === snapshot.width.toString()) {
                return;
            }
            ref.style.width = `${snapshot.width}px`;
            ref.style.height = `${snapshot.height - 2}px`;
            return;
        }

        if (this.props.isDragOccurring) {
            return;
        }

        // inline styles not applied
        if (ref.style.width == null) {
            return;
        }

        // // no snapshot and drag is finished - clear the inline styles
        // ref.style.removeProperty('height');
        // ref.style.removeProperty('width');
    }

    componentDidMount(): void {
        if (this.props.resizable) {
            document.addEventListener('mousemove', this.handleResizeHandleMousemove);
            document.addEventListener('mouseup', this.handleResizeHandleMouseup);
            if (this.props.columnWidth) {
                this.handleWidthChange(this.props.columnWidth);
            }
        }
    }

    componentWillUnmount(): void {
        if (this.props.resizable) {
            document.removeEventListener('mousemove', this.handleResizeHandleMousemove);
            document.removeEventListener('mouseup', this.handleResizeHandleMouseup);
        }
    }

    handleOnClick = () => {
        const { onClick, columnName } = this.props;
        if (onClick) {
            onClick(columnName);
        }
    };

    getSortableHeaderIcon = () => {
        const { columnName, searchParams } = this.props;
        const sorting = searchParams?.SortItems[0];
        return sorting?.SortColumn === columnName ? ICONS.ARROW_DOWN_24 : ICONS.SORT_24;
    };

    handleOnFilter = (items: Array<TagSelectItem<any>>) => {
        const { onFilter } = this.props;
        if (onFilter) {
            onFilter(items);
        }
    };

    handleResizeHandleMousedown = (e: React.MouseEvent) => {
        if (this.props.resizable) {
            this.thStartOffset = this.ref.current.offsetWidth - e.pageX;
            this.isDragging = true;
        }
    };

    handleResizeHandleMouseup = () => {
        if (this.isDragging) {
            this.props.handleColumnWidthChange(this.props.columnName, this.ref.current.clientWidth);
        }
        this.isDragging = false;
    };

    handleResizeHandleMousemove = (e: MouseEvent) => {
        if (this.props.resizable && this.isDragging && this.ref && this.ref.current) {
            const widthSize = this.thStartOffset + e.pageX;
            if (widthSize >= this.props.minColumnWidth) {
                this.handleWidthChange(widthSize);
            }
        }
    };

    handleResizeClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
    };

    render() {
        const {
            /* eslint-disable @typescript-eslint/no-unused-vars */
            className,
            children,
            columnName,
            sortable,
            searchParams,
            showPlaceholder,
            dataId,
            filterable,
            filterOptions,
            filterActive,
            filterItems,
            filterLoadItems,
            filterPlaceholder,
            filterValues,
            hide,
            onClick,
            dragHandleProps,
            isDragOccurring,
            onFilter,
            resizable,
            columnWidth,
            minColumnWidth,
            tableHeight,
            handleColumnWidthChange,
            /* eslint-enable @typescript-eslint/no-unused-vars */
            ...rest
        } = this.props;

        if (hide) {
            return null;
        }

        const classes = classNames('data-table__head', className, {
            'data-table__head--sortable': sortable,
            'data-table__head--filterable': filterable,
            'data-table__head--filter-active': filterable && filterActive,
            'data-table__head--sortable-asc': sortable && searchParams ? searchParams.SortItems[0].SortColumn === columnName && searchParams.SortItems[0].SortDirection === SortDirection.Asc : false,
            'data-table__head--sortable-desc': sortable && searchParams ? searchParams.SortItems[0].SortColumn === columnName && searchParams.SortItems[0].SortDirection === SortDirection.Desc : false,
        });

        return (
            <th onClick={this.handleOnClick} className={classes} data-id={addDataId(dataId, '')} {...rest} ref={this.ref}>
                <div className={'head-content'}>
                    {showPlaceholder ? (
                        <TableHeadPlaceholder />
                    ) : sortable || filterable || resizable ? (
                        <>
                            <span className={'head-content__title'}>{children}</span>
                            {sortable && <Icon className="sorting" name={this.getSortableHeaderIcon()} />}
                            {filterable && filterOptions && (
                                <TagSelect {...filterOptions}>
                                    <span className={'icon-container'} data-id={createDataId(dataId, 'filter')}>
                                        <Icon className={'filtering'} name={ICONS.FILTER} />
                                        {filterActive && size(filterOptions.values)}
                                    </span>
                                </TagSelect>
                            )}
                            {filterable && filterItems && (
                                <TagSelect type={TagSelectType.COMPACT} items={filterItems} values={filterValues || []} onChange={this.handleOnFilter}>
                                    <Icon className={'filtering'} name={ICONS.FILTER} data-id={createDataId(dataId, 'filter')} />
                                </TagSelect>
                            )}
                            {filterable && typeof filterLoadItems === 'function' && (
                                <TagSelect type={TagSelectType.DEFAULT} loadItems={filterLoadItems} values={filterValues || []} onChange={this.handleOnFilter} placeholder={filterPlaceholder}>
                                    <Icon className={'filtering'} name={ICONS.FILTER} data-id={createDataId(dataId, 'filter')} />
                                </TagSelect>
                            )}
                            {resizable && (
                                <div
                                    style={{ height: `${this.props.tableHeight}px` }}
                                    className="data-table__resize-handle"
                                    onMouseDown={this.handleResizeHandleMousedown}
                                    onClick={this.handleResizeClick}
                                />
                            )}
                        </>
                    ) : (
                        children
                    )}
                </div>
            </th>
        );
    }
}

const TableHeadPlaceholder = () => (
    <div className="head-content--placeholder">
        <span className="placeholder-value"></span>
    </div>
);

export default TableHead;
