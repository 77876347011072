import { PagingOptions, SortDirection, SortItem } from '../../services/types/ApiTypes';
import { InvoiceFilterObjectDTO } from '../../views/invoice-details/components/invoice-slider/InvoiceSliderHelpers';
import constants from '../constants/appConstants';

export function validateAndFixSortItems(sortItems: SortItem[], defaultColumn = 'Id', defaultDirection = SortDirection.Desc, columnNames?: string[]): SortItem[] {
    const sorting = sortItems[0]; // is there ever more sortItems in use or why is this an array ???
    const isDefaultSorting = !sorting && defaultColumn && defaultDirection in SortDirection;

    if (isDefaultSorting) {
        const defaultSortItem: SortItem = {
            SortColumn: defaultColumn,
            SortDirection: defaultDirection,
        };
        return [defaultSortItem];
    }

    if (sorting.hasOwnProperty('SortColumn') && typeof sorting.SortColumn === 'string' && sorting.SortColumn === '') {
        sorting.SortColumn = defaultColumn;
        sorting.SortDirection = defaultDirection;
    }

    // let's check if SortColumn is legit, else make it default value
    if (columnNames && sorting.hasOwnProperty('SortColumn') && typeof sorting.SortColumn === 'string' && sorting.SortColumn !== '' && !columnNames.includes(sorting.SortColumn)) {
        sorting.SortColumn = defaultColumn;
    }

    return [sorting];
}

export function validateAndFixPagingOptions(paging: PagingOptions) {
    if (paging.hasOwnProperty('Page') && typeof paging.Page === 'number' && paging.Page === 0) {
        paging.Page = constants.DEFAULT_PAGINATOR.page;
    }

    if (paging.hasOwnProperty('Count') && typeof paging.Count === 'number' && paging.Count === 0) {
        paging.Count = constants.DEFAULT_PAGINATOR.count;
    }

    return paging;
}

export function parseInvoiceFilterObjectSorting(filterObject: InvoiceFilterObjectDTO): SortItem[] {
    const { sortItems } = filterObject;
    const sorting = sortItems[0]; // is there ever more sortItems in use or why is this an array ???
    if (sorting.hasOwnProperty('SortColumn') && typeof sorting.SortColumn === 'string' && sorting.SortColumn === '') {
        sorting.SortColumn = 'AccountingDate';
        sorting.SortDirection = SortDirection.Desc;
    }
    return [sorting];
}

export function parseInvoiceFilterObjectPaging(filterObject: InvoiceFilterObjectDTO): PagingOptions {
    const { pagingOptions } = filterObject;

    const paging = {
        Page: 1,
        Count: 15,
    };

    if (pagingOptions.hasOwnProperty('page') && typeof pagingOptions.page === 'number' && pagingOptions.page !== 0) {
        paging.Page = pagingOptions.page;
    }

    if (pagingOptions.hasOwnProperty('count') && typeof pagingOptions.count === 'number' && pagingOptions.count !== 0) {
        paging.Count = pagingOptions.count;
    }

    return paging;
}

/**
 * toggle the sorting direction dependant on the column to be sorted
 * @param sorting: SortItem - current sorting
 * @param sortColumn: string - column to be sorted
 */
export function toggleSortDirection(sorting: SortItem, sortColumn?: string) {
    if (sortColumn && sorting.SortColumn === sortColumn && sorting.SortDirection === SortDirection.Asc) {
        return SortDirection.Desc;
    }
    return SortDirection.Asc;
}
