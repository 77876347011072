import { Role } from "../../../src/common/user/userPermissionUtil";

(function() {
    'use strict';

    angular
        .module('dstreamApp.views.invoice-register')
        .component('dsInvoiceRegister', {
            templateUrl: 'app/views/invoice-register/invoice-register.html',
            controller: InvoiceRegister
        });

    InvoiceRegister.$inject = ["$location", "$rootScope", "authenticationService"];

    function InvoiceRegister($location, $rootScope, authenticationService) {
        var ctrl = this;

        $rootScope.totalAmountsWillLoad = false; // is button "Calculate Total" shown
        $rootScope.setTotalAmountsWillLoad = setTotalAmountsWillLoad;

        function setTotalAmountsWillLoad (willLoad) {
            $rootScope.totalAmountsWillLoad = willLoad;
        }

        ctrl.$onInit = function() {
            if(!authenticationService.isAuthorized(Role.CanViewInvoiceRegister))
            {
                $location.path("/dashboard");
            }
        }
    }
})();