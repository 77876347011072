(function() {
    'use strict';

    angular
        .module('dstreamApp.views.detailed-transactions')
        .component('dsDetailedTransactionsTableFooter', {
            templateUrl: 'app/views/detailed-transactions/components/detailed-transactions-table-footer/detailed-transactions-table-footer.html',
            controller: DetailedTransactionsTableFooter,
            bindings: {
                invoice: '<',
                tableOptions: '<',
                gridApi: '<',
                addNewRow: '&'
            }
        });

    DetailedTransactionsTableFooter.$inject = ['$rootScope', '$scope', 'detailedTransactionsTableService'];

    function DetailedTransactionsTableFooter($rootScope, $scope, detailedTransactionsTableService) {
        var ctrl = this;
        ctrl.mandatoryCellsLeft = 0;
        ctrl.watchers = [];
        ctrl.getRemainingSumWithVat = getRemainingSumWithVat;
        ctrl.getRowsTotalSum = getRowsTotalSum;
        ctrl.getRowsTotalVat = getRowsTotalVat;

        ctrl.$onInit = function() {
            ctrl.watchers.push($rootScope.$on('calculateEmptyMandatoryFields', findEmptyMandatoryCellsLeft));
        };

        ctrl.$onDestroy = function() {
            _.forEach(ctrl.watchers, function(watcher) {watcher();});
        };

        /*
            Get Remaining sum with Vat
         */
        function getRemainingSumWithVat(check) {
            if(!ctrl.invoice || !ctrl.tableOptions.data.length) return 0;
            var rowTotalSum = 0;

            _.forEach(ctrl.tableOptions.data, function(row) {
                rowTotalSum += parseFloat(row.Total.value);
            });
            if (check) {
                return !Number(ctrl.invoice.TotalAmountWithVat - rowTotalSum).toFixed(2);
            }
            return ((ctrl.invoice.TotalAmountWithVat - rowTotalSum).toFixed(4) > 0 ? '+' : '') + Number(ctrl.invoice.TotalAmountWithVat - rowTotalSum).toFixed(2);
        }

        /*
            Get total Sum of all the rows
         */
        function getRowsTotalSum() {
            if(!ctrl.invoice || !ctrl.tableOptions.data.length) return 0;
            var rowTotalSum = 0;

            _.forEach(ctrl.tableOptions.data, function(row) {
                rowTotalSum += parseFloat(row.SumWithoutVat.value)
            });

            return Number(rowTotalSum).toFixed(2);
        }

        /*
            Get total Vat of all the rows
         */
        function getRowsTotalVat() {
            if(!ctrl.invoice || !ctrl.tableOptions.data.length) return 0;
            var rowTotalSum = 0;

            _.forEach(ctrl.tableOptions.data, function(row) {
                rowTotalSum += parseFloat(row.VAT.value)
            });

            return Number(rowTotalSum).toFixed(2);
        }

        /*
            Count how many empty mandatory cells are left
         */
        function findEmptyMandatoryCellsLeft() {
            setTimeout(function() {
                $scope.$apply(function () {
                    var mandatoryCellsLeft = 0;

                    // loop through all the rows and columns
                    _.forEach(ctrl.gridApi.grid.rows, function (row) {
                        if (!row.entity.Account) {
                            mandatoryCellsLeft++;
                        }
                        if (!row.entity.VatCode) {
                            mandatoryCellsLeft++;
                        }
                        _.forEach(ctrl.gridApi.grid.columns, function (col) {
                            var cellValue = ctrl.gridApi.grid.getCellValue(row, col);
                            if (cellValue && !cellValue.DimensionId && isCellMandatory(col, row)) {
                                mandatoryCellsLeft++;
                            }
                        });
                    });

                    ctrl.mandatoryCellsLeft = mandatoryCellsLeft;
                });
            });
        }

        /*
            Check if cell is mandatory
         */
        function isCellMandatory(col, row) {
            var rowEntity = row.entity ? row.entity : row; // depends on the method, but in some cases the param row is already and entity
            if(_.find($rootScope.accountDistributionItemCustomCostObjectives, function(t) {return t.Id === col.colDef.customCostObjectiveId && t.IsMandatory})) {
                // if cell is part of the mandatory fields set in the company settings
                return true;
            } else if(rowEntity.Account && _.find(rowEntity.Account.AdditionalFields, function(a) {return a.CustomCostObjectiveId === col.colDef.customCostObjectiveId})) {
                // if cell is part of the mandatory fields set by the account selection
                return true;
            } else {
                return false;
            }
        }
    }
})();