import { ReducerFactory } from 'redux-actions-ts-reducer';

import { TableFilter } from '../../../../../../components/Table/components/filter/TableFilters';
import { BaseSearchWithTableFilters, PagedListContainer, InvoicePurchaseOrdersSearch, InvoiceHeaderPurchaseOrdersDTO, LinkedCountDTO } from '../../../../../../services/types/ApiTypes';
import { LoadableData } from '../../../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../../../rootReducer';
import { getPurchaseOrderInvoicesLinkedCount, getInvoicePurchaseOrdersLoadable } from './PurchaseOrdersListActions';
import { createRequest } from './PurchaseOrdersListHelper';

export type InvoicesSearchParams = BaseSearchWithTableFilters<TableFilter<any>, InvoiceHeaderPurchaseOrdersDTO>;

class State {
    linkedPurchaseOrdersCount: LoadableData<LinkedCountDTO>;
    searchParams: InvoicesSearchParams = {
        ...createRequest('', 1),
    };
    purchaseOrderInvoicesLoadable = new LoadableData<PagedListContainer<InvoiceHeaderPurchaseOrdersDTO>, InvoicePurchaseOrdersSearch>();
    filterType?: number;
}

export { State as InvoicePurchaseOrdersState };

export default new ReducerFactory(new State())
    .addReducer(
        getPurchaseOrderInvoicesLinkedCount.request,
        (state): State => {
            return {
                ...state,
                linkedPurchaseOrdersCount: LoadableData.loading(),
            };
        },
    )
    .addReducer(
        getPurchaseOrderInvoicesLinkedCount.success,
        (state, action): State => {
            return {
                ...state,
                linkedPurchaseOrdersCount: LoadableData.payload(action.payload),
            };
        },
    )
    .addReducer(
        getPurchaseOrderInvoicesLinkedCount.error,
        (state, action): State => {
            return {
                ...state,
                linkedPurchaseOrdersCount: LoadableData.error(action.payload),
            };
        },
    )
    .addReducer(
        getInvoicePurchaseOrdersLoadable.request,
        (state, action): State => {
            const purchaseOrderInvoicesLoadable = state.purchaseOrderInvoicesLoadable.withLoading(action.payload);
            return {
                ...state,
                searchParams: action.payload.SearchParams,
                filterType: action.payload.Filter,
                purchaseOrderInvoicesLoadable,
            };
        },
    )
    .addReducer(
        getInvoicePurchaseOrdersLoadable.success,
        (state, action): State => {
            const purchaseOrderInvoicesLoadable = state.purchaseOrderInvoicesLoadable.withPayloadIfRequestEquals(action.payload);
            return {
                ...state,
                searchParams: action.payload.request.SearchParams,
                filterType: action.payload.request.Filter,
                purchaseOrderInvoicesLoadable,
            };
        },
    )
    .addReducer(
        getInvoicePurchaseOrdersLoadable.error,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request.SearchParams,
                filterType: action.payload.request.Filter,
                purchaseOrderInvoicesLoadable: LoadableData.error(action.payload.result),
            };
        },
    )
    .toReducer();

export const selectInvoiceLinkedPurchaseOrdersCount = (state: GlobalState) => state.invoicePurchaseOrders.linkedPurchaseOrdersCount;
export const selectInvoicePurchaseOrdersLoadable = (state: GlobalState) => state.invoicePurchaseOrders.purchaseOrderInvoicesLoadable;
export const selectInvoicePurchaseOrdersSearchParams = (state: GlobalState) => state.invoicePurchaseOrders.searchParams;
export const selectInvoicePurchaseOrdersFilterType = (state: GlobalState) => state.invoicePurchaseOrders.filterType;
