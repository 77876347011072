import * as React from 'react';

interface HeaderPlaceholderProps {
    class: string;
}

export function HeaderPlaceholder(props: HeaderPlaceholderProps) {
    return (
        <div className={`${props.class}--placeholder`}>
            <span className={`${props.class}-value`}></span>
        </div>
    );
}
