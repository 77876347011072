function camelCased(s: string) {
    return s && s.replace(/-([a-z0-9])/g, (g) => g[1].toUpperCase());
}

export function bemify(bemClasses: {}, modifierPrefix = '$'): any {
    return Object.keys(bemClasses).reduce((acc, identifier) => {
        acc[camelCased(identifier.replace('--', modifierPrefix))] = bemClasses[identifier];
        return acc;
    }, {});
}

/**
 output:
 {
  "link": "_2hnwruUYVnt1YjfDY3JaDp",
  "link$iconLeft": "_1GaLumtkWCvhhQH1ONxHwr",
  "link$iconRight": "_2EEnQ-QNQ8ykNt4OavyckA",
  "link__icon": "_2A-ew9b2HOxU0eUv4agjOf"
}
 usage:
 className={styles.link__icon}
 className={styles.link$iconLeft}
 className={classNames(className, styles.link, { [styles.link$iconLeft]: !!iconLeft })

 */
