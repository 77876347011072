import { AxiosInstance, AxiosResponse } from 'axios';

import {
    BaseSearch,
    InvoiceDashboardExtDTO,
    InvoiceDashboardMinDTO,
    InvoiceDataResponseDTO,
    PagedListContainerOf,
    InvoiceDTO,
    InvoiceSearchOptions,
    InvoiceSliderMinDTO,
    PagedListContainer,
    HistoryDTO,
    ReplaceTasksInWorkflow,
    InvoiceCustomizationFieldsSearchParams,
    InvoiceCustomizationDTO,
    FileDTO,
    InvoiceRegistryMinDTO,
    AttachmentType,
    AttachmentFilesCountResponseDTO,
    Currency,
    IsibVerificationDTO,
    InvoiceHeaderHistoryDTO,
    InvoiceRowDTO,
    ProductItemDTO,
    TransactionRowDTO,
    TransactionRowDimensionDTO,
    TransactionRowsExtendedInfoDTO,
    TransactionRowHistoryDTO,
    TransactionRowsSplitDTO,
} from '../types/ApiTypes';

export const longsToStrings = (response: any) => {
    let newResponse = response.replace(new RegExp('"ItemPrice":(-?[0-9.]+),', 'g'), '"ItemPrice":"$1",');
    newResponse = newResponse.replace(new RegExp('"ItemAmount":(-?[0-9.]+),', 'g'), '"ItemAmount":"$1",');
    newResponse = newResponse.replace(new RegExp('"TotalAmountWithVat":(-?[0-9.]+),', 'g'), '"TotalAmountWithVat":"$1",');
    newResponse = newResponse.replace(new RegExp('"Total":(-?[0-9.]+),', 'g'), '"Total":"$1",');
    newResponse = newResponse.replace(new RegExp('"VAT":(-?[0-9.]+),', 'g'), '"VAT":"$1",');
    newResponse = newResponse.replace(new RegExp('"SumWithoutVat":(-?[0-9.]+),', 'g'), '"SumWithoutVat":"$1",');
    newResponse = newResponse.replace(new RegExp('"ItemSum":(-?[0-9.]+),', 'g'), '"ItemSum":"$1",');
    newResponse = newResponse.replace(new RegExp('"VatRate":(-?[0-9.]+),', 'g'), '"VatRate":"$1",');
    newResponse = newResponse.replace(new RegExp('"LinkedObjectsSumWithoutVat":(-?[0-9.]+),', 'g'), '"LinkedObjectsSumWithoutVat":"$1",');
    newResponse = newResponse.replace(new RegExp('"LinkedObjectsTotal":(-?[0-9.]+),', 'g'), '"LinkedObjectsTotal":"$1",');

    return JSON.parse(newResponse);
};

export const stringToNumber = (response: any) => {
    let newResponse = JSON.stringify(response);
    newResponse = newResponse.replace(new RegExp('"ItemPrice":"(-?[0-9.]+)",', 'g'), '"ItemPrice":$1,');
    newResponse = newResponse.replace(new RegExp('"ItemAmount":"(-?[0-9.]+)",', 'g'), '"ItemAmount":$1,');
    newResponse = newResponse.replace(new RegExp('"SumWithoutVat":"(-?[0-9.]+)",', 'g'), '"SumWithoutVat":$1,');
    newResponse = newResponse.replace(new RegExp('"TotalAmountWithVat":"(-?[0-9.]+)",', 'g'), '"TotalAmountWithVat":$1,');
    newResponse = newResponse.replace(new RegExp('"Total":"(-?[0-9.]+)",', 'g'), '"Total":$1,');
    newResponse = newResponse.replace(new RegExp('"VAT":"(-?[0-9.]+)",', 'g'), '"VAT":$1,');
    newResponse = newResponse.replace(new RegExp('"Vat":"(-?[0-9.]+)",', 'g'), '"Vat":$1,');
    newResponse = newResponse.replace(new RegExp('"ItemSum":"(-?[0-9.]+)",', 'g'), '"ItemSum":$1,');
    newResponse = newResponse.replace(new RegExp('"VatRate":"(-?[0-9.]+)",', 'g'), '"VatRate":$1,');
    newResponse = newResponse.replace(new RegExp('"Rate":"(-?[0-9.]+)",', 'g'), '"Rate":$1,');
    newResponse = newResponse.replace(new RegExp('"Amount":"(-?[0-9.]+)",', 'g'), '"Amount":$1,');
    newResponse = newResponse.replace(new RegExp('"SumMoney":"(-?[0-9.]+)"', 'g'), '"SumMoney":$1');
    return newResponse;
};

export default class InvoiceService {
    constructor(private axios: AxiosInstance) {}

    checkIsDuplicate = async (invoiceData: Partial<InvoiceDTO>) => {
        return this.axios.post('invoice/CheckIsDuplicate', invoiceData);
    };

    changeInvoiceCompany = async (companyId: string, invoiceId: number): Promise<AxiosResponse<InvoiceDTO>> => {
        return this.axios.get(`invoice/ChangeInvoiceCompany?companyReceiverGuid=${companyId}&invoiceId=${invoiceId}`);
    };

    getAvailableCustomizationFields = async (searchParams?: InvoiceCustomizationFieldsSearchParams): Promise<AxiosResponse<PagedListContainer<InvoiceCustomizationDTO>>> => {
        return this.axios.get('Invoice/GetAvailableCustomizationFields', { params: searchParams });
    };

    getCurrencyList = async (): Promise<AxiosResponse<Currency[]>> => {
        return this.axios.get('Currency/GetAll');
    };

    getSliderInvoicesMin = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainer<InvoiceSliderMinDTO>>> => {
        return this.axios.post(
            'Invoice/GetSliderInvoicesMin',
            {
                ...searchParams,
            },
            {
                transformResponse(value) {
                    return longsToStrings(value);
                },
            },
        );
    };

    getDashboardInvoicesMin = async (searchParams: InvoiceSearchOptions): Promise<AxiosResponse<PagedListContainer<InvoiceDashboardMinDTO>>> => {
        return this.axios.post(`Invoice/GetDashboardInvoicesMin`, {
            ...searchParams,
        });
    };

    getDashboardInvoiceExt = async (invoiceId: string): Promise<AxiosResponse<InvoiceDashboardExtDTO>> => {
        return this.axios.get(`Invoice/GetDashboardInvoiceExt?invoiceId=${invoiceId}`);
    };

    getRegistryInvoicesMin = async (searchParams: InvoiceSearchOptions): Promise<AxiosResponse<PagedListContainer<InvoiceRegistryMinDTO>>> => {
        return this.axios.post(
            `Invoice/GetRegistryInvoicesMin`,
            {
                ...searchParams,
            },
            {
                transformResponse(value) {
                    return longsToStrings(value);
                },
            },
        );
    };

    getInvoiceData = async (invoiceId: string): Promise<AxiosResponse<InvoiceDataResponseDTO>> => {
        return this.axios.get(`Invoice/GetInvoiceDataById/${invoiceId}`);
    };

    getInvoiceHeaderHistory = async (search: BaseSearch): Promise<AxiosResponse<PagedListContainer<InvoiceHeaderHistoryDTO>>> => {
        return this.axios.post('/Invoice/GetInvoiceHeaderHistory', search);
    };

    getPurchaseOrdersLinkingHistory = async (search: BaseSearch): Promise<AxiosResponse<PagedListContainer<InvoiceHeaderHistoryDTO>>> => {
        return this.axios.post('/Invoice/GetPurchaseOrdersLinkingHistory', search);
    };

    getInvoiceCustomFieldsHistory = async (search: BaseSearch): Promise<AxiosResponse<PagedListContainer<InvoiceHeaderHistoryDTO>>> => {
        return this.axios.post('/Invoice/GetInvoiceCustomFieldsHistory', search);
    };

    deleteInvoiceFile = async (file: FileDTO) => {
        return this.axios.post('Invoice/DeleteInvoiceFile', file);
    };

    downloadFileById = async (id: string, fromArchive: boolean, withFrontPage: boolean, language: string) => {
        const date = new Date();
        const offsetInHours = date.getTimezoneOffset() / 60;
        return this.axios.post(
            `File/DownloadFile/${id}?fromArchive=${fromArchive}&withFrontPage=${withFrontPage}&language=${language}&offsetInHours=${offsetInHours}`,
            {},
            {
                responseType: 'blob',
            },
        );
    };

    displayFileById = async (id: number, fromArchive?: string) => {
        return this.axios.get(`File/DisplayFileById/${id}${fromArchive ? `${fromArchive}` : ''}`, { responseType: 'blob' });
    };

    uploadInvoiceFile = async (file: FileDTO) => {
        return this.axios.post('Invoice/UploadInvoiceFile', file);
    };

    getAllAttachmentFilesSize = async (attachmentType: AttachmentType, objectId: number): Promise<AxiosResponse<AttachmentFilesCountResponseDTO>> => {
        return this.axios.get(`File/GetAllAttachmentFilesSize?attachmentType=${attachmentType}&objectId=${objectId}`);
    };

    exportStatusReset = async (invoiceId: string): Promise<AxiosResponse<boolean>> => {
        return this.axios.get(`Invoice/ExportStatusReset/${invoiceId}`);
    };

    updateInvoiceExportSetting = async (invoiceId: string, enabledExport: boolean): Promise<AxiosResponse<boolean>> => {
        return this.axios.get(`Invoice/UpdateInvoiceExportSetting/${invoiceId}?enabledExport=${enabledExport}`);
    };

    getInvoicesSmall = async (params: BaseSearch): Promise<AxiosResponse<PagedListContainerOf<InvoiceDTO>>> => {
        return this.axios.post('invoice/GetInvoicesSmall', params);
    };

    getInvoiceHistory = async (invoiceId: number): Promise<AxiosResponse<HistoryDTO[]>> => {
        return this.axios.get('Invoice/GetInvoiceHistory', {
            params: {
                invoiceId,
            },
        });
    };

    getInvoiceTransactionRowsVatRates = async (invoiceId: number): Promise<AxiosResponse<any[]>> => {
        return this.axios.get(`/Invoice/GetInvoiceTransactionRowsVatRates?invoiceId=${invoiceId}`);
    };

    areTransactionRowsValidPenultimate = async (id: number): Promise<AxiosResponse<any[]>> => {
        return this.axios.get('invoice/AreTransactionRowsValidPenultimate/' + id);
    };

    areTransactionRowsValid = async (id: number): Promise<AxiosResponse<any[]>> => {
        return this.axios.get('invoice/AreTransactionRowsValid/' + id);
    };

    areCustomFieldsValid = async (id: number): Promise<AxiosResponse<any[]>> => {
        return this.axios.get('invoice/AreCustomFieldsValid/' + id);
    };

    replaceTasks = async (workFlowObject: ReplaceTasksInWorkflow, confirmFirst: boolean): Promise<AxiosResponse<any>> => {
        return this.axios.post('invoice/ReplaceTasksInWorkflow?confirmFirst=' + confirmFirst, workFlowObject);
    };

    saveInvoiceCopy = async (originalId: number, invoice: InvoiceDTO): Promise<AxiosResponse<InvoiceDTO>> => {
        return this.axios.post('invoice/SaveCopy?originalId=' + originalId, invoice, {
            headers: { 'Content-Type': 'application/json' },
            transformRequest: [
                (data, headers) => {
                    headers['Content-Type'] = 'application/json';
                    return stringToNumber(data);
                },
            ],
        });
    };

    setInvoiceDeleted = async (id: number, comment?: string): Promise<AxiosResponse<any>> => {
        return this.axios.post('invoice/SetInvoiceToDeleted/' + (comment ? `?additionalInfo=${encodeURIComponent(comment)}` : ''), id, { headers: { 'Content-Type': 'application/json' } });
    };

    checkTransactionRowsBudget = async (invoiceId: number): Promise<AxiosResponse<IsibVerificationDTO[]>> => {
        return this.axios.get('TransactionRow/VerifyIsibFunds?invoiceId=' + invoiceId);
    };

    restoreDeletedInvoice = async (invoiceId: number): Promise<AxiosResponse<InvoiceDTO>> => {
        return this.axios.post('invoice/RestoreDeletedInvoice?invoiceId=' + invoiceId);
    };

    getProductItems = async (name: string): Promise<AxiosResponse<ProductItemDTO[]>> => {
        return this.axios.get(`InvoiceRow/GetProductItems?name=${encodeURIComponent(name || '')}`);
    };

    getInvoiceRowsByInvoiceId = async (id: number, search: BaseSearch): Promise<AxiosResponse<PagedListContainer<InvoiceRowDTO>>> => {
        return this.axios.post(`/InvoiceRow/GetInvoiceRowsByInvoiceId/${id}`, search, {
            transformResponse(value) {
                return longsToStrings(value);
            },
        });
    };

    updateInvoiceRow = async (row: InvoiceRowDTO): Promise<AxiosResponse<InvoiceRowDTO>> => {
        return this.axios.post('InvoiceRow/UpdateInvoiceRow?invoiceId=' + row?.InvoiceId, row, {
            transformRequest: [
                (data, headers) => {
                    headers['Content-Type'] = 'application/json';
                    return stringToNumber(data);
                },
            ],
        });
    };

    deleteInvoiceRow = async (row: InvoiceRowDTO): Promise<AxiosResponse<InvoiceRowDTO>> => {
        return this.axios.post('invoice/DeleteInvoiceRow?invoiceId=' + row.InvoiceId, row);
    };

    addInvoiceRow = async (row: InvoiceRowDTO): Promise<AxiosResponse<InvoiceRowDTO>> => {
        return this.axios.post('invoice/AddInvoiceRow?invoiceId=' + row?.InvoiceId, row, {
            transformRequest: [
                (data, headers) => {
                    headers['Content-Type'] = 'application/json';
                    return stringToNumber(data);
                },
            ],
        });
    };

    bulkDeleteInvoiceRow = async (invoiceId: number, type: number, invoiceRowIds: number[]): Promise<AxiosResponse<boolean>> => {
        return this.axios.post(`invoice/BulkDeleteInvoiceRow?invoiceId=${invoiceId}&actionModifier=${type}`, invoiceRowIds);
    };

    getInvoiceTransactionRowsByInvoiceId = async (id: number, search: BaseSearch): Promise<AxiosResponse<PagedListContainer<TransactionRowDTO>>> => {
        return this.axios.post(`invoice/GetInvoiceTransactionRowsByInvoiceId/${id}`, search, {
            transformResponse(value) {
                return longsToStrings(value);
            },
        });
    };

    updateCustomFieldWithRelations = async (id: number, dimension: TransactionRowDimensionDTO): Promise<AxiosResponse<TransactionRowDimensionDTO[]>> => {
        return this.axios.post(`invoice/UpdateCustomFieldWithRelations?rowId=${id}`, dimension);
    };

    updateCustomField = async (id: number, dimension: TransactionRowDimensionDTO): Promise<AxiosResponse<TransactionRowDimensionDTO[]>> => {
        return this.axios.post(`invoice/UpdateCustomField?id=${id}`, dimension);
    };

    deleteField = async (id: number): Promise<AxiosResponse<number>> => {
        return this.axios.get(`Invoice/Deletefield/${id}`);
    };

    updateAccount = async (accountId: number, distributionItemId: number): Promise<AxiosResponse<TransactionRowDTO>> => {
        return this.axios.post(`invoice/UpdateAccount?accountId=${accountId || ''}&id=${distributionItemId}`);
    };

    updateTransactionRowProductItemBuyerId = async (rowId: number, productItemCode: string): Promise<AxiosResponse<boolean>> => {
        return this.axios.post(`TransactionRow/UpdateTransactionRowProductItemBuyerId?transactionRowId=${rowId}&newProductItem=${productItemCode}`);
    };

    updateVatCode = async (id: number, row: TransactionRowDTO): Promise<AxiosResponse<TransactionRowDTO>> => {
        return this.axios.post(`TransactionRow/UpdateVatCode?invoiceid=${id}`, row, {
            transformRequest: [
                (data, headers) => {
                    headers['Content-Type'] = 'application/json';
                    return stringToNumber(data);
                },
            ],
        });
    };

    deleteTransactionRow = async (rowIds: number[]): Promise<AxiosResponse<any>> => {
        return this.axios.post(`TransactionRow/DeleteTransactionRow`, rowIds);
    };

    updateTransactionRow = async (row: TransactionRowDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post(`TransactionRow/UpdateTransactionRow`, row, {
            transformRequest: [
                (data, headers) => {
                    headers['Content-Type'] = 'application/json';
                    return stringToNumber(data);
                },
            ],
        });
    };

    addTransactionRow = async (row: TransactionRowDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post(`TransactionRow/AddTransactionRow`, row, {
            transformRequest: [
                (data, headers) => {
                    headers['Content-Type'] = 'application/json';
                    return stringToNumber(data);
                },
            ],
        });
    };

    createAccountingRows = async (invoiceId: number): Promise<AxiosResponse<any>> => {
        return this.axios.post(`invoice/CreateAccountingRows?invoiceId=${invoiceId}`);
    };

    createTransactionRowsWithAT = async (invoiceId: number): Promise<AxiosResponse<any>> => {
        return this.axios.post(`invoice/CreateTransactionRowsWithAT?invoiceId=${invoiceId}`);
    };

    getInvoiceTransactionRowsVatSum = async (invoiceId: number): Promise<AxiosResponse<number>> => {
        return this.axios.get(`Invoice/GetInvoiceTransactionRowsVatSum?invoiceId=${invoiceId}`);
    };

    uploadTransactionRowsFile = async (invoiceId: number, file: FileDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post(`TransactionRow/UploadTransactionRowsFile/${invoiceId}`, file);
    };

    exportTransactionRowsToXls = async (invoiceId: number): Promise<AxiosResponse<any>> => {
        return this.axios.post(`TransactionRow/ExportTransactionRowsToXls?invoiceId=${invoiceId}`, null, {
            responseType: 'blob',
        });
    };

    exportTransactionRowsToCSV = async (invoiceId: number): Promise<AxiosResponse<any>> => {
        return this.axios.post(`TransactionRow/ExportTransactionRowsToCSV?invoiceId=${invoiceId}`, null, {
            responseType: 'blob',
        });
    };

    exportTransactionRowsWithFormulasToXls = async (invoiceId: number): Promise<AxiosResponse<any>> => {
        return this.axios.post(`TransactionRow/ExportTransactionRowsWithFormulasToXls?invoiceId=${invoiceId}`, null, {
            responseType: 'blob',
        });
    };

    mergeAllAccountingRows = async (invoiceId: number): Promise<AxiosResponse<string>> => {
        return this.axios.get(`Invoice/MergeAllAccountingRows/${invoiceId}`);
    };

    getTransactionRowsExtendedInfoList = async (invoiceId: number): Promise<AxiosResponse<TransactionRowsExtendedInfoDTO[]>> => {
        return this.axios.get(`TransactionRow/GetTransactionRowsExtendedInfoList/${invoiceId}`);
    };

    mergeAccountingRowsByTransactionRowExtension = async (invoiceId: number, metaInfo: TransactionRowsExtendedInfoDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post(`Invoice/MergeAccountingRowsByTransactionRowExtension/${invoiceId}`, metaInfo);
    };

    copyDown = async (transactionRowId: number): Promise<AxiosResponse<any>> => {
        return this.axios.post(`TransactionRow/CopyDown/${transactionRowId}`);
    };

    getRowHistory = async (transactionRowId: number): Promise<AxiosResponse<TransactionRowHistoryDTO[]>> => {
        return this.axios.get(`TransactionRow/GetRowHistory/${transactionRowId}`);
    };

    updateComment = async (transactionRowId: number, comment: string): Promise<AxiosResponse<TransactionRowHistoryDTO[]>> => {
        return this.axios.post(`TransactionRow/UpdateComment/${transactionRowId}`, { Comment: comment });
    };

    splitTransactionRow = async (splitObj: TransactionRowsSplitDTO): Promise<AxiosResponse<boolean>> => {
        return this.axios.post('Invoice/SplitTransactionRow/', splitObj, {
            transformRequest: [
                (data, headers) => {
                    headers['Content-Type'] = 'application/json';
                    return stringToNumber(data);
                },
            ],
        });
    };
}
