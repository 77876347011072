import { withTranslation } from 'react-i18next';

import withSuspense from '../../../../common/hocs/withSuspense';
import { GlobalState } from '../../../../rootReducer';
import { connect } from '../../../../storeConfig';

import InvoiceConfirmations, { DispatchProps } from './InvoiceConfirmations';
import { selectInvoiceDataLoadable, selectIsConfirmationFlowLoading, selectIsConfirmationFlowEditing } from './InvoiceConfirmationsReducer';
import { getCurrentUser } from '../../../../common/user/UserSelectors';
import { setConfirmationFlowLoading, setIsConfirmationFlowEditing } from './InvoiceConfirmationsAction';
import { selectCompanyGroupMembers, selectCurrentCompanySettings } from '../../../../common/company/CompanySelectors';
import { getCompanyGroupMembers } from '../../../../common/company/CompanyActions';
import { selectInvoiceHistory } from '../invoice-history/InvoiceHistoryReducers';

const mapStateToProps = (state: GlobalState) => {
    return {
        invoiceDataLoadable: selectInvoiceDataLoadable(state),
        invoiceHistoryLoadable: selectInvoiceHistory(state),
        userData: getCurrentUser(state),
        groupMembers: selectCompanyGroupMembers(state),
        isLoading: selectIsConfirmationFlowLoading(state),
        companySetting: selectCurrentCompanySettings(state),
        isEditing: selectIsConfirmationFlowEditing(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    setConfirmationFlowLoading,
    setIsConfirmationFlowEditing,
    getCompanyGroupMembers,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(withTranslation()(InvoiceConfirmations)));
